const InfoCircle = ({ className }: { className?: string }) => {
    const style = `stroke-[#3E526E] ${className}`
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" className={style} fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2452_12732)">
                <path d="M8 15.0311C11.8833 15.0311 15.0313 11.8831 15.0313 7.99988C15.0313 4.11663 11.8833 0.968628 8 0.968628C4.11675 0.968628 0.96875 4.11663 0.96875 7.99988C0.96875 11.8831 4.11675 15.0311 8 15.0311Z" stroke="current" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M9.40625 10.8124H8.9375C8.68888 10.8124 8.45038 10.7136 8.27456 10.5378C8.09875 10.362 8 10.1235 8 9.87488V7.53113C8 7.40682 7.95063 7.28757 7.86269 7.19969C7.77481 7.11175 7.65556 7.06238 7.53125 7.06238H7.0625" stroke="current" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.76563 5.18738C7.63619 5.18738 7.53125 5.08245 7.53125 4.953C7.53125 4.82356 7.63619 4.71863 7.76563 4.71863" stroke="current" />
                <path d="M7.76562 5.18738C7.89506 5.18738 8 5.08245 8 4.953C8 4.82356 7.89506 4.71863 7.76562 4.71863" stroke="current" />
            </g>
            <defs>
                <clipPath id="clip0_2452_12732">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default InfoCircle