import './global.css'
import './index.css'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { UserProvider } from './contexts/UserContext.tsx'
import { Login } from './components/Login.tsx'
import { Layout } from './components/Layout.tsx'
import { DocumentListContainer } from './components/DocumentList.tsx'
import { IsAuthenticated } from './components/IsAuthenticated.tsx'
import { ThreadsContainer } from './components/Threads.tsx'
import { ErrorFallback } from './components/ErrorFallback.tsx'
import { WebSocketProvider } from './contexts/WebSocketContext.tsx'
import { initializeSentry } from './services/sentry.ts'
import { NewConversation } from './components/Assistant/NewConversation.tsx'
import { ExistingConversation } from './components/Assistant/ExistingConversation.tsx'
import { Ask } from './components/Assistant/Ask.tsx'
import { FeatureFlagProvider } from './contexts/FeatureFlagContext.tsx'
import { ResourceSearchPageContainer } from './components/Resources/ResourceSearchPage.tsx'
import { store } from './store/store'
import { Provider } from 'react-redux'
import { LayoutProvider } from './contexts/LayoutContext.tsx'
import { ReportsPageContainer } from './components/Reports/ReportsPage.tsx'
import { ReportWriterPage } from './components/Reports/ReportWriterPage.tsx'
import { ToastProvider } from './contexts/ToastContext.tsx'
import { IntegrationPageContainer } from './components/Integration/IntegrationPage.tsx'
import { IntegrationSetupPageContainer } from './components/Integration/IntegrationSetupPage.tsx'
import { IntegrationDetailPageContainer } from './components/Integration/IntegrationDetailPage.tsx'
import { DossierPageContainer } from './components/Dossier/DossierPage.tsx'
import { DossierDetailPageContainer } from './components/Dossier/DossierDetailPage.tsx'
import { HomePageContainer } from './components/Home/HomePage.tsx'
import { Signout } from './components/Signout/Signout.tsx'
import { reactErrorHandler } from '@sentry/react'
import { DocGenContainer } from './components/DocGen/DocGenPage.tsx'
import { MicrosoftIntegrationPage } from './components/Integration/MicrosoftIntegrationPage.tsx'
import { GuidePageContainer } from './components/Guides/GuidePage.tsx'
import { EditorPageContainer } from './components/Reports/EditorPage.tsx'
import { TemplatesPage } from './components/DocGen/TemplatesPage.tsx'
import { TemplateDetailPage } from './components/DocGen/TemplateDetailPage.tsx'
import { ThemeSettingsPage } from './components/Theme/ThemeSettingsPage.tsx'

initializeSentry()

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <Login />
          </IsAuthenticated>
        }
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/desk',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <></>
          </IsAuthenticated>
        }
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/home',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <HomePageContainer />
          </IsAuthenticated>
        }
        tall={true}
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/library',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <DocumentListContainer />
          </IsAuthenticated>
        }
        tall={true}
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/search',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <ResourceSearchPageContainer />
          </IsAuthenticated>
        }
        tall={true}
        scrollable={false}
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/reports',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <ReportsPageContainer />
          </IsAuthenticated>
        }
        tall={true}
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/report-editors',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <EditorPageContainer />
          </IsAuthenticated>
        }
        tall={true}
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/threads',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <ThreadsContainer />
          </IsAuthenticated>
        }
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/guide/:guide_name',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <GuidePageContainer />
          </IsAuthenticated>
        }
        tall={true}
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/assistant/ask/',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <Ask />
          </IsAuthenticated>
        }
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/dossier/:dossier_id/assistant/ask/',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <Ask />
          </IsAuthenticated>
        }
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/assistant/ask/new/',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <NewConversation />
          </IsAuthenticated>
        }
        tall={true}
        noPadding={true}
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/dossier/:dossier_id/assistant/ask/new/',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <NewConversation />
          </IsAuthenticated>
        }
        tall={true}
        noPadding={true}
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/assistant/conversation/:conversation_id',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <ExistingConversation />
          </IsAuthenticated>
        }
        tall={true}
        noPadding={true}
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/dossier/:dossier_id/assistant/conversation/:conversation_id',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <ExistingConversation />
          </IsAuthenticated>
        }
        tall={true}
        noPadding={true}
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/report/:report_id',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <ReportWriterPage />
          </IsAuthenticated>
        }
        tall={true}
        scrollable={false}
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/dossier/:dossier_id/report/:report_id',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <ReportWriterPage />
          </IsAuthenticated>
        }
        tall={true}
        scrollable={false}
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/integrations',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <IntegrationPageContainer />
          </IsAuthenticated>
        }
        tall
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/integrations/themes',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <ThemeSettingsPage />
          </IsAuthenticated>
        }
        tall
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/integrations/microsoft',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <MicrosoftIntegrationPage />
          </IsAuthenticated>
        }
        tall
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/integrations/microsoft/setup/:integration_code_name',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <IntegrationSetupPageContainer />
          </IsAuthenticated>
        }
        tall={true}
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/integrations/microsoft/:integration_code_name',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <IntegrationDetailPageContainer />
          </IsAuthenticated>
        }
        tall={true}
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/dossiers',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <DossierPageContainer />
          </IsAuthenticated>
        }
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/dossier/:dossier_id',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <DossierDetailPageContainer />
          </IsAuthenticated>
        }
        tall={true}
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/signout',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <Signout />
          </IsAuthenticated>
        }
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/docgen',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <DocGenContainer />
          </IsAuthenticated>
        }
        tall={true}
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/docgen/:session_id',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <DocGenContainer />
          </IsAuthenticated>
        }
        tall={true}
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/templates',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <TemplatesPage />
          </IsAuthenticated>
        }
        tall={true}
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/template/:template_id',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <TemplateDetailPage />
          </IsAuthenticated>
        }
        tall={true}
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
  {
    path: '/template/new',
    element: (
      <Layout
        component={
          <IsAuthenticated>
            <TemplateDetailPage />
          </IsAuthenticated>
        }
        tall={true}
      />
    ),
    errorElement: <Layout component={<ErrorFallback />} />,
  },
])

ReactDOM.createRoot(document.getElementById('root')!, {
  onRecoverableError: reactErrorHandler(),
}).render(
  // <React.StrictMode>
  <Provider store={store}>
    <ToastProvider>
      <WebSocketProvider>
        <UserProvider>
          <FeatureFlagProvider>
            <LayoutProvider>
              <RouterProvider router={router} />
            </LayoutProvider>
          </FeatureFlagProvider>
        </UserProvider>
      </WebSocketProvider>
    </ToastProvider>
  </Provider>
  // </React.StrictMode>,
)
