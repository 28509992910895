import { Playbook } from '@/types/types'
import { TypographyBody, TypographyLabel } from '../ui/Typography'
import { Badge } from '../ui/badge'
import { Button, secondaryStyle } from '../ui/button'
import { getPlaybookCardIcon } from '@/utils/components'
import InfoCircle from '@/assets/InfoCircle'
import { CustomTooltip } from '../CustomTooltip'

export const PlaybookQuestion = ({
  playbookId,
  playbook,
  variables,
  onClick,
}: {
  playbookId: string
  playbook: Playbook
  variables: string[]
  onClick: () => void
}) => {
  const playbookItem = playbook.items.find((v) => v.id === playbookId)

  return (
    <div
      className={`${secondaryStyle} flex flex-col gap-1 p-3 mb-4 rounded-[12px] bg-system-secondary border border-system-border-regular w-full cursor-pointer`}
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
    >
      <div className="flex gap-2 items-center">
        {getPlaybookCardIcon(playbookItem?.category || '')}

        <TypographyBody
          isStrong
        >{`${playbook.title} ${playbook.items.length > 1 ? `- ${playbookItem?.option || ''}` : ''}`}</TypographyBody>

        <Button
          variant="tertiary"
          data-tooltip-id={`playbook-card-${playbook?.items.at(0)?.id || ''}-tooltip`}
          data-tooltip-place="top"
          className="size-4 ml-auto mr-2"
        >
          <InfoCircle className="size-4 shrink-0 stroke-[1.5px] !stroke-system-placeholder" />
        </Button>

        <CustomTooltip
          id={`playbook-card-${playbook?.items.at(0)?.id || ''}-tooltip`}
          className="!py-1 !px-3 !rounded-sm"
          largeArrow={false}
        >
          <TypographyLabel className="text-system-body whitespace-pre-wrap max-w-[20rem] text-center">
            {playbook?.items.at(0)?.tooltip || ''}
          </TypographyLabel>
        </CustomTooltip>
      </div>

      {variables.length > 0 && (
        <div className="flex gap-2 items-center ml-10">
          {variables.map((variable, index) => {
            return (
              <Badge
                variant="purple"
                key={`playbook-variable-${index}`}
                className="w-fit"
              >
                {variable}
              </Badge>
            )
          })}
        </div>
      )}
    </div>
  )
}
