import { DedupedSearchQueryItem, SourceDocumentType } from '@/types/types'
import { Checkbox } from './ui/checkbox'
import { Button } from './ui/button'
import { ChevronDown, ChevronUp } from 'lucide-react'
import { TypographyBody, TypographyLabel } from './ui/Typography'
import { Badge } from './ui/badge'
import {
  checkSourceDocumentType,
  parseFilingDate,
  toPascalCase,
} from '@/utils/utils'
import { Accordion, AccordionContent, AccordionItem } from './ui/accordion'
import { useEffect, useState } from 'react'
import { SourceIcon } from './Source'

export const FileAnalysisCard = ({
  files,
  selectedFiles,
  onSelect,
}: {
  files: DedupedSearchQueryItem[]
  selectedFiles: DedupedSearchQueryItem[]
  onSelect: (files: DedupedSearchQueryItem[]) => void
}) => {
  const [expand, setExpand] = useState(false)

  const someFilesSelected = files.some((v) =>
    selectedFiles.some((f) => f.id === v.id)
  )
  const allFilesSelected = files.every((v) =>
    selectedFiles.some((f) => f.id === v.id)
  )

  useEffect(() => {
    if (files.length < 5) {
      setExpand(true)
    }
  }, [files])

  return (
    <div className="flex flex-col gap-2 bg-system-secondary p-2 rounded-[12px] border border-system-border-regular">
      <Accordion type="single" value={expand ? files[0].id : ''}>
        <AccordionItem value={files[0].id} className="border-none">
          <div
            className={`flex gap-0 items-center h-[5.5rem] ${expand ? 'border-b border-system-border-light' : ''}`}
          >
            <Checkbox
              className="mx-3"
              checked={someFilesSelected}
              partial={!allFilesSelected}
              onCheckedChange={() => {
                if (allFilesSelected) {
                  onSelect(
                    selectedFiles.filter(
                      (v) => !files.some((f) => f.id === v.id)
                    )
                  )
                } else {
                  onSelect([...selectedFiles, ...files])
                }
              }}
            />
            <div className="flex gap-3 items-center w-[50%] shrink-0">
              <Button variant="tertiary" onClick={() => setExpand(!expand)}>
                {expand ? (
                  <ChevronUp className="size-6 shrink-0 stroke-interactive" />
                ) : (
                  <ChevronDown className="size-6 shrink-0 stroke-interactive" />
                )}
              </Button>
              <div className="flex flex-col gap-2 w-full">
                <div className="flex gap-2 items-center w-full">
                  <span className="text-system-primary font-body-strong">
                    {toPascalCase(
                      files[0].doc_metadata?.element_name || files[0].title
                    )}

                    <span className="pl-2">
                      <Badge
                        variant={
                          files[0].doc_metadata?.element_category
                            ?.toLowerCase()
                            ?.includes('private')
                            ? 'blue'
                            : files[0].doc_metadata?.element_category
                                  ?.toLowerCase()
                                  ?.includes('public')
                              ? 'green'
                              : 'purple'
                        }
                      >
                        <TypographyLabel className="line-clamp-1 break-all">
                          {files[0].doc_metadata?.element_category || ''}
                        </TypographyLabel>
                      </Badge>
                    </span>
                  </span>
                </div>

                <TypographyLabel className="text-system-body">
                  {`${files[0].doc_metadata?.element_weburl ? `${files[0].doc_metadata?.element_weburl?.split('/').at(-1) || ''} · ` : ''}Incorporated on ${parseFilingDate(files[0].doc_metadata?.element_created_at || '')}`}
                </TypographyLabel>
              </div>
            </div>
            <TypographyLabel className="text-system-body px-4">
              {files[0].doc_metadata?.element_code?.split(' - ').at(-1) || ''}
            </TypographyLabel>
          </div>
          <AccordionContent>
            <div className="flex flex-col gap-0">
              {files.map((file, index) => {
                const sourceType = checkSourceDocumentType(
                  file.id,
                  file.doc_metadata
                )

                return (
                  <div
                    className="flex gap-0 items-center h-[2.875rem] border-b border-system-border-light last:border-none"
                    key={`file-analysis-card-files-${file.id}-${index}`}
                  >
                    <Checkbox
                      checked={selectedFiles.some((v) => v.id === file.id)}
                      onCheckedChange={() => {
                        if (selectedFiles.some((v) => v.id === file.id)) {
                          onSelect(
                            selectedFiles.filter((v) => v.id !== file.id)
                          )
                        } else {
                          onSelect([...selectedFiles, file])
                        }
                      }}
                      className="mx-3"
                    />

                    <div className="flex gap-2 items-center w-[60%] pl-10">
                      <div className="shrink-0">
                        <SourceIcon
                          id={file.id}
                          url={file.url}
                          title={file.title}
                          metadata={file.doc_metadata}
                        />
                      </div>
                      
                      {sourceType === SourceDocumentType.FILINGS && (
                        <TypographyBody className="text-system-body">
                          {`${file.doc_metadata?.filing_category} for FY ended ${parseFilingDate(file.doc_metadata?.filing_for_date || '')}`}
                        </TypographyBody>
                      )}

                      {sourceType === SourceDocumentType.TRANSCRIPTS && (
                        <TypographyBody className="text-system-body line-clamp-1 break-all">
                          {file.doc_metadata?.transcript_description || ''}
                        </TypographyBody>
                      )}

                      {sourceType === SourceDocumentType.COMPANIES_HOUSE && (
                        <TypographyBody className="text-system-body">
                          {`Full accounts made up to ${parseFilingDate(file.doc_metadata?.filing_for_date || '')}`}
                        </TypographyBody>
                      )}
                    </div>

                    <TypographyBody className="text-system-body px-3">
                      {parseFilingDate(
                        file.doc_metadata?.transcript_creation_date ||
                          file.doc_metadata?.filing_date ||
                          file.doc_metadata?.filing_first_available_date ||
                          file.document_created_at_source ||
                          ''
                      )}
                    </TypographyBody>
                  </div>
                )
              })}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  )
}
