import { WEB_SERVER_ENDPOINT } from '@/constants'
import { RootState } from '@/store/store'
import { RequestSearchQuery, ResponseSearchQuery } from '@/types/types'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const fetchSearchResults = createAsyncThunk<
  ResponseSearchQuery,
  RequestSearchQuery,
  { state: RootState }
>('search/fetchSearchResults', async (payload, { rejectWithValue }) => {
  try {
    const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/search`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })

    if (!response.ok) {
      throw new Error('Failed to search documents')
    }
    const data = await response.json()
    return data
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message)
    }
    return rejectWithValue('An unknown error occurred')
  }
})

export const fetchCompaniesHouseSearchResults = createAsyncThunk<
  ResponseSearchQuery,
  RequestSearchQuery,
  { state: RootState }
>(
  'search/fetchCompaniesHouseSearchResults',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${WEB_SERVER_ENDPOINT}/api/search/company-house`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      )

      if (!response.ok) {
        throw new Error('Failed to search documents')
      }
      const data = await response.json()
      return data
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

export const fetchAllSearchResults = createAsyncThunk<
  ResponseSearchQuery,
  RequestSearchQuery,
  { state: RootState }
>('search/fetchAllSearchResults', async (payload, { rejectWithValue }) => {
  try {
    const response = await fetch(`${WEB_SERVER_ENDPOINT}/api/search/all`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })

    if (!response.ok) {
      throw new Error('Failed to search documents')
    }
    const data = await response.json()
    return data
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message)
    }
    return rejectWithValue('An unknown error occurred')
  }
})

export const fetchCompanyResults = createAsyncThunk<
  ResponseSearchQuery,
  { company_name: string; sources: string[], size: number },
  { state: RootState }
>('search/fetchCompanyResults', async (payload, { rejectWithValue }) => {
  try {
    const response = await fetch(
      `${WEB_SERVER_ENDPOINT}/api/search/company_lookup`,
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }
    )

    if (!response.ok) {
      throw new Error('Failed to search documents')
    }
    const data = await response.json()
    return data
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message)
    }
    return rejectWithValue('An unknown error occurred')
  }
})
