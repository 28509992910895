import { Playbook, UserMessage } from '@/types/types'
import { Question } from '../Assistant/Question'
import { memo, ReactNode, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import { PlaybookQuestion } from './PlaybookQuestion'
import { processAdditionalContexts } from '@/utils/utils'
import { secondaryStyle } from '../ui/button'
import { TypographyBody, TypographyH4 } from '../ui/Typography'
import { getFileIcon } from '@/utils/components'

const UserChatMessage = memo(
  (props: {
    message: UserMessage
    compact?: boolean
    component?: ReactNode
    onPlaybookClick?: ({
      playbook,
      playbookId,
      variables,
    }: {
      playbook: Playbook
      playbookId: string
      variables: string[]
    }) => void
    onAdditionalContextClick?: (ac: string) => void
  }) => {
    const { message, additionalContexts } = useMemo(() => {
      return processAdditionalContexts(props.message.query)
    }, [props.message.query])

    const playbook = useSelector((state: RootState) =>
      state.playbook.playbooks.data.find((v) =>
        v.items.find((v) => v.id === props.message.playbook?.playbook_id)
      )
    )

    if (playbook) {
      return (
        <div className="max-w-[46.875rem] flex">
          <PlaybookQuestion
            playbookId={props.message.playbook?.playbook_id || ''}
            playbook={playbook}
            variables={props.message.playbook?.playbook_vars || []}
            onClick={() => {
              props.onPlaybookClick?.({
                playbook,
                playbookId: props.message.playbook?.playbook_id || '',
                variables: props.message.playbook?.playbook_vars || [],
              })
            }}
          />
        </div>
      )
    }

    return (
      <div className="max-w-[46.875rem] flex flex-col gap-6">
        <Question
          message={message}
          compact={props.compact}
          component={props.component}
        />
        {!!additionalContexts.length && (
          <div className="flex flex-col gap-2">
            {additionalContexts.map((ac, idx) => {
              return (
                <div
                  key={`additional_context_${idx}`}
                  className={`${secondaryStyle} flex flex-col gap-3 p-3 rounded-[12px] bg-system-secondary border border-system-border-regular w-full cursor-pointer`}
                  onClick={(e) => {
                    e.stopPropagation()
                    props.onAdditionalContextClick?.(ac)
                  }}
                >
                  <div className="flex gap-2 items-start">
                    {getFileIcon('text', '!size-7')}
                    {!!message.length ? (
                      <TypographyBody
                        className={`w-full line-clamp-2 text-system-body whitespace-pre-wrap`}
                      >
                        {ac}
                      </TypographyBody>
                    ) : (
                      <TypographyH4 className="line-clamp-2 whitespace-pre-wrap">
                        {ac}
                      </TypographyH4>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
)

export default UserChatMessage
