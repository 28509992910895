import { SourceDocument, SourceDocumentType } from '@/types/types'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import {
  getFileIcon,
  getGlobalUniqueDocuments,
  getIntegrationIcon,
} from '@/utils/components'
import { Source } from '../Source'
import { Checkbox } from '../ui/checkbox'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button } from '../ui/button'
import ChevronDown from '@/assets/ChevronDown'
import {
  checkSourceDocumentType,
  getHostname,
  getIconSrc,
  sortSourceDocuments,
} from '@/utils/utils'
import { TypographyBody } from '../ui/Typography'

interface IProps {
  documents: SourceDocument[]
  showTabs: boolean
  selectable?: boolean
  selectedDocuments?: SourceDocument[]
  previewable?: boolean
  setSelectedDocuments?: Dispatch<SetStateAction<SourceDocument[]>>
  onSourceClick?: (source: SourceDocument) => void
}

// todo: consider unique documents as prop
// todo: support tabbing through links
export function Sources({
  documents,
  showTabs,
  selectable,
  selectedDocuments = [],
  previewable,
  setSelectedDocuments,
  onSourceClick,
}: IProps) {
  const [selectedTab, setSelectedTab] = useState<
    'all' | 'internal' | 'webpages' | 'database' | 'communication'
  >('all')
  const [showAllSources, setShowAllSources] = useState(false)

  const uniqueDocuments = getGlobalUniqueDocuments(documents)
  const sortedUniqueDocuments = sortSourceDocuments(uniqueDocuments)

  const webpages = documents.filter((d) => {
    const sourceType = checkSourceDocumentType(d.document_id, d.doc_metadata)
    return sourceType === SourceDocumentType.WEB
  })

  const communicationDocuments = documents.filter((d) => {
    const sourceType = checkSourceDocumentType(d.document_id, d.doc_metadata)
    return (
      sourceType === SourceDocumentType.OUTLOOK ||
      sourceType === SourceDocumentType.TEAMS
    )
  })
  const internalDocuments = documents.filter((d) => {
    const sourceType = checkSourceDocumentType(d.document_id, d.doc_metadata)
    return (
      sourceType === SourceDocumentType.LIBRARY ||
      sourceType === SourceDocumentType.DESIA_LIBRARY ||
      sourceType === SourceDocumentType.SHAREPOINT ||
      sourceType === SourceDocumentType.ONEDRIVE
    )
  })

  const databaseDocuments = documents.filter((d) => {
    const sourceType = checkSourceDocumentType(d.document_id, d.doc_metadata)
    return (
      sourceType === SourceDocumentType.FINANCIAL_DATA ||
      sourceType === SourceDocumentType.COMPANIES_HOUSE ||
      sourceType === SourceDocumentType.FILINGS ||
      sourceType === SourceDocumentType.TRANSCRIPTS
    )
  })

  const hasWebpages = webpages.length > 0
  const hasInternalDocs = internalDocuments.length > 0
  const hasCommunicationDocs = communicationDocuments.length > 0
  const hasDatabaseDocs = databaseDocuments.length > 0

  const isAllWebSelected = webpages.every((v) => selectedDocuments?.includes(v))
  const isAllInternalSelected = internalDocuments.every((v) =>
    selectedDocuments?.includes(v)
  )
  const isAllCommunicationSelected = communicationDocuments.every((v) =>
    selectedDocuments?.includes(v)
  )
  const isAllDatabaseSelected = databaseDocuments.every((v) =>
    selectedDocuments?.includes(v)
  )

  const isSomeWebSelected = webpages.some((v) => selectedDocuments?.includes(v))
  const isSomeInternalSelected = internalDocuments.some((v) =>
    selectedDocuments?.includes(v)
  )

  const isSomeCommunicationSelected = communicationDocuments.some((v) =>
    selectedDocuments?.includes(v)
  )
  const isSomeDatabaseSelected = databaseDocuments.some((v) =>
    selectedDocuments?.includes(v)
  )

  const shownDocuments = showAllSources
    ? sortedUniqueDocuments
    : sortedUniqueDocuments.length > 5
      ? sortedUniqueDocuments.slice(0, 4)
      : sortedUniqueDocuments

  const toggleSource = (document: SourceDocument) => {
    if (selectedDocuments?.includes(document)) {
      setSelectedDocuments?.(selectedDocuments.filter((v) => v !== document))
    } else {
      setSelectedDocuments?.([...selectedDocuments, document])
    }
  }

  const toggleWebpages = () => {
    if (isAllWebSelected) {
      setSelectedDocuments?.(
        selectedDocuments.filter((v) => !webpages.includes(v))
      )
    } else {
      setSelectedDocuments?.([
        ...selectedDocuments.filter((v) => !webpages.includes(v)),
        ...webpages,
      ])
    }
  }

  const toggleInternalDocuments = () => {
    if (isAllInternalSelected) {
      setSelectedDocuments?.(
        selectedDocuments.filter((v) => !internalDocuments.includes(v))
      )
    } else {
      setSelectedDocuments?.([
        ...selectedDocuments.filter((v) => !internalDocuments.includes(v)),
        ...internalDocuments,
      ])
    }
  }

  const toggleCommunicationDocuments = () => {
    if (isAllCommunicationSelected) {
      setSelectedDocuments?.(
        selectedDocuments.filter((v) => !communicationDocuments.includes(v))
      )
    } else {
      setSelectedDocuments?.([
        ...selectedDocuments.filter((v) => !communicationDocuments.includes(v)),
        ...communicationDocuments,
      ])
    }
  }

  const toggleDatabaseDocuments = () => {
    if (isAllDatabaseSelected) {
      setSelectedDocuments?.(
        selectedDocuments.filter((v) => !databaseDocuments.includes(v))
      )
    } else {
      setSelectedDocuments?.([
        ...selectedDocuments.filter((v) => !databaseDocuments.includes(v)),
        ...databaseDocuments,
      ])
    }
  }

  const checkIfPreviewIsAvailable = (source: SourceDocument) => {
    const validTypes = ['pdf']

    const isValidFileType =
      validTypes.includes(
        source.doc_metadata?.document_type_friendly?.toLowerCase() || ''
      ) || source.title?.endsWith('.pdf')

    const sourceType = checkSourceDocumentType(
      source.document_id,
      source.doc_metadata
    )
    const isWeb = sourceType === SourceDocumentType.WEB
    const isFinancialData = sourceType === SourceDocumentType.FINANCIAL_DATA
    const isOutlook = sourceType === SourceDocumentType.OUTLOOK
    const isTeams = sourceType === SourceDocumentType.TEAMS
    return (
      (isValidFileType || isWeb || isOutlook || isTeams || isFinancialData) &&
      previewable
    )
  }

  useEffect(() => {
    setShowAllSources(false)
  }, [documents])

  return (
    <div className="flex flex-col gap-6">
      {showTabs ? (
        <Tabs defaultValue="all" value={selectedTab} className="rounded-md">
          <TabsList className="mb-6">
            <TabsTrigger value="all" onClick={() => setSelectedTab('all')}>
              All
            </TabsTrigger>

            {hasInternalDocs && (
              <TabsTrigger
                value="internal"
                onClick={() => setSelectedTab('internal')}
              >
                <div className="flex gap-2">
                  {selectable && (
                    <Checkbox
                      checked={isAllInternalSelected || isSomeInternalSelected}
                      partial={
                        (isSomeInternalSelected && !isAllInternalSelected) ||
                        undefined
                      }
                      onClick={(e) => {
                        e.stopPropagation()
                        toggleInternalDocuments()
                      }}
                    />
                  )}
                  Library
                </div>
              </TabsTrigger>
            )}
            {hasCommunicationDocs && (
              <TabsTrigger
                value="communication"
                onClick={() => setSelectedTab('communication')}
              >
                <div className="flex gap-2">
                  {selectable && (
                    <Checkbox
                      checked={
                        isAllCommunicationSelected ||
                        isSomeCommunicationSelected
                      }
                      partial={
                        (isSomeCommunicationSelected &&
                          !isAllCommunicationSelected) ||
                        undefined
                      }
                      onClick={(e) => {
                        e.stopPropagation()
                        toggleCommunicationDocuments()
                      }}
                    />
                  )}
                  Communication
                </div>
              </TabsTrigger>
            )}
            {hasDatabaseDocs && (
              <TabsTrigger
                value="database"
                onClick={() => setSelectedTab('database')}
              >
                <div className="flex gap-2">
                  {selectable && (
                    <Checkbox
                      checked={isAllDatabaseSelected || isSomeDatabaseSelected}
                      partial={
                        (isSomeDatabaseSelected && !isAllDatabaseSelected) ||
                        undefined
                      }
                      onClick={(e) => {
                        e.stopPropagation()
                        toggleDatabaseDocuments()
                      }}
                    />
                  )}
                  Database
                </div>
              </TabsTrigger>
            )}
            {hasWebpages && (
              <TabsTrigger
                value="webpages"
                onClick={() => setSelectedTab('webpages')}
              >
                <div className="flex gap-2">
                  {selectable && (
                    <Checkbox
                      checked={isAllWebSelected || isSomeWebSelected}
                      partial={
                        (isSomeWebSelected && !isAllWebSelected) || undefined
                      }
                      onClick={(e) => {
                        e.stopPropagation()
                        toggleWebpages()
                      }}
                    />
                  )}
                  Web
                </div>
              </TabsTrigger>
            )}
          </TabsList>

          <TabsContent
            value="all"
            className={`flex flex-col gap-4 overflow-y-auto`}
          >
            {sortedUniqueDocuments.map((d, index) => (
              <Source
                key={`${d.document_id}-${index}`}
                id={d.document_id}
                url={d.url || ''}
                title={d.title}
                text={d.text}
                showBorder={true}
                metadata={d.doc_metadata}
                showCheckbox={selectable}
                selected={selectedDocuments.includes(d)}
                onSelect={() => toggleSource(d)}
                onClick={() => onSourceClick?.(d)}
                previewable={checkIfPreviewIsAvailable(d)}
              />
            ))}
          </TabsContent>

          {hasWebpages && (
            <TabsContent
              value="webpages"
              className={`flex flex-col gap-4 overflow-y-auto`}
            >
              {webpages.map((d, index) => (
                <Source
                  key={`${d.document_id}-${index}`}
                  id={d.document_id}
                  url={d.url || ''}
                  title={d.title}
                  text={d.text}
                  showBorder={true}
                  metadata={d.doc_metadata}
                  showCheckbox={selectable}
                  selected={selectedDocuments.includes(d)}
                  onSelect={() => toggleSource(d)}
                  onClick={() => onSourceClick?.(d)}
                  previewable={checkIfPreviewIsAvailable(d)}
                />
              ))}
            </TabsContent>
          )}

          {hasInternalDocs && (
            <TabsContent
              value="internal"
              className={`flex flex-col gap-4 overflow-y-auto`}
            >
              {internalDocuments.map((d, index) => (
                <Source
                  key={`${d.document_id}-${index}`}
                  id={d.document_id}
                  url={d.url || ''}
                  title={d.title}
                  text={d.text}
                  showBorder={true}
                  metadata={d.doc_metadata}
                  showCheckbox={selectable}
                  selected={selectedDocuments.includes(d)}
                  onSelect={() => toggleSource(d)}
                  onClick={() => onSourceClick?.(d)}
                  previewable={checkIfPreviewIsAvailable(d)}
                />
              ))}
            </TabsContent>
          )}

          {hasCommunicationDocs && (
            <TabsContent
              value="communication"
              className={`flex flex-col gap-4 overflow-y-auto`}
            >
              {communicationDocuments.map((d, index) => (
                <Source
                  key={`${d.document_id}-${index}`}
                  id={d.document_id}
                  url={d.url || ''}
                  title={d.title}
                  text={d.text}
                  showBorder={true}
                  metadata={d.doc_metadata}
                  showCheckbox={selectable}
                  selected={selectedDocuments.includes(d)}
                  onSelect={() => toggleSource(d)}
                  onClick={() => onSourceClick?.(d)}
                  previewable={checkIfPreviewIsAvailable(d)}
                />
              ))}
            </TabsContent>
          )}

          {hasDatabaseDocs && (
            <TabsContent
              value="database"
              className={`flex flex-col gap-4 overflow-y-auto`}
            >
              {databaseDocuments.map((d, index) => (
                <Source
                  key={`${d.document_id}-${index}`}
                  id={d.document_id}
                  url={d.url || ''}
                  title={d.title}
                  text={d.text}
                  showBorder={true}
                  metadata={d.doc_metadata}
                  showCheckbox={selectable}
                  selected={selectedDocuments.includes(d)}
                  onSelect={() => toggleSource(d)}
                  onClick={() => onSourceClick?.(d)}
                  previewable={checkIfPreviewIsAvailable(d)}
                />
              ))}
            </TabsContent>
          )}
        </Tabs>
      ) : (
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            {shownDocuments.map((d, index) => (
              <Source
                key={`${d.document_id}-${index}`}
                id={d.document_id}
                url={d.url || ''}
                title={d.title}
                text={d.text}
                showBorder={true}
                metadata={d.doc_metadata}
                showCheckbox={selectable}
                selected={selectedDocuments.includes(d)}
                onSelect={() => toggleSource(d)}
                onClick={() => onSourceClick?.(d)}
                previewable={checkIfPreviewIsAvailable(d)}
              />
            ))}
          </div>

          {sortedUniqueDocuments.length > 5 && !showAllSources && (
            <div className="w-fit">
              <Button
                variant="secondary"
                onClick={(e) => {
                  e.stopPropagation()
                  setShowAllSources(true)
                }}
              >
                <div className="flex gap-2 items-center">
                  <TypographyBody
                    isStrong
                  >{`Show ${sortedUniqueDocuments.length - 4} more`}</TypographyBody>
                  {sortedUniqueDocuments.slice(4, 6).map((document, index) => {
                    const isDocument = !document.document_id.includes('web')
                    const isIntegration = Boolean(
                      document.doc_metadata?.document_source_details
                        ?.integration_code_name
                    )
                    const src = getIconSrc(getHostname(document.url || ''))
                    const iconStyle =
                      'w-4 h-4 border border-system-border-light rounded-[2px]'

                    return (
                      <div
                        key={`show-more-icon-${index}`}
                        className={`relative size-4 ${iconStyle} flex justify-center items-center`}
                      >
                        {isDocument &&
                          !isIntegration &&
                          getFileIcon(
                            document.doc_metadata?.document_type_friendly || '',
                            '!w-3 !h-3 rounded-[1px]'
                          )}

                        {isDocument &&
                          isIntegration &&
                          getIntegrationIcon(
                            document.doc_metadata?.document_source_details
                              ?.integration_code_name || '',
                            true,
                            'w-3 h-3 shrink-0 rounded-[1px]'
                          )}

                        {!isDocument && (
                          <img src={src} className="rounded-[1px]" />
                        )}
                      </div>
                    )
                  })}

                  <ChevronDown />
                </div>
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
