import {
  TableType,
  FinancialDataTableProps,
  DefaultTableProps,
} from '@/types/types'
import { DefaultTable } from './DefaultTable'
import { FinancialDataTable } from './FinancialDataTable'
import { ResponseTableError } from './ResponseTableError'
import { memo } from 'react'

type TableProps = DefaultTableProps | FinancialDataTableProps

const isDefaultTable = (props: TableProps): props is DefaultTableProps => {
  return (props as DefaultTableProps) !== undefined
}

const isFinancialDataTable = (
  props: TableProps
): props is FinancialDataTableProps => {
  return (props as FinancialDataTableProps) !== undefined
}

export const ResponseTable = memo((props: TableProps) => {
  try {
    if (props.type === TableType.DEFAULT && isDefaultTable(props)) {
      return (
        <DefaultTable
          id={props.id}
          data={props.data}
          documents={props.documents}
          citations={props.citations}
          openedCitation={props.openedCitation}
          compact={props.compact}
          overrides={props.overrides}
          showCopyButton={props.showCopyButton}
          isExpand={props.isExpand}
          isShouldShift={props.isShouldShift}
          isFormatted={props.isFormatted}
        />
      )
    }

    if (
      props.type === TableType.FINANCIAL_DATA &&
      isFinancialDataTable(props)
    ) {
      return (
        <FinancialDataTable
          id={props.id}
          data={props.data}
          highlightedText={props.data}
          isFormatted={props.isFormatted}
        />
      )
    }

    throw new Error('table type is invalid')
  } catch (e: any) {
    return <ResponseTableError />
  }
})
