import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResponseDocument, QueryStatus, DOCUMENT_STATUS } from '../../types/types';
import { listDocuments, uploadDocument } from './documentThunk';
import { sortDocumentsFn } from './utils';
import { handleError } from '@/utils/handleError';

interface DocumentState {
  files: ResponseDocument[]
  fileUpload: File[]
  timestamp: number
  uploadStatus: QueryStatus
  fetchStatus: QueryStatus
  processingFiles: Record<string, ResponseDocument>
}

const initialState: DocumentState = {
  files: [],
  timestamp: Date.now(),
  uploadStatus: QueryStatus.INITIALISED,
  fileUpload: [],
  fetchStatus: QueryStatus.INITIALISED,
  processingFiles: {}
}
export const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    statusUpdate: (state, action: PayloadAction<ResponseDocument>) => {
      const updatedDocument = action.payload;
      const index = state.files?.findIndex(
        (doc) => doc.document_id === updatedDocument.document_id
      );

      if (index >= 0) {
        state.files[index] = { ...state.files[index], ...updatedDocument };
      } else {
        state.files = state.files.concat([updatedDocument]).sort(sortDocumentsFn);
      }

      if (updatedDocument.document_processing_status !== DOCUMENT_STATUS.AVAILABLE || state.processingFiles[updatedDocument.document_id]) {
        state.processingFiles[updatedDocument.document_id] = updatedDocument
      }

      console.log(action.payload)
    },
    setList: (state, action: PayloadAction<ResponseDocument[]>) => {
      if (action.payload && action.payload.length)
        state.files = action.payload
    },
    deleteFile: (state, action: PayloadAction<ResponseDocument>) => {
      state.files = state.files.filter(
        (file) => file.document_id !== action.payload.document_id
      );
    },
    addFiles: (state, action: PayloadAction<ResponseDocument[]>) => {
      state.files = [...state.files, ...action.payload]
    },
    clearProcessingFiles: (state) => {
      state.processingFiles = {};
    },
    setProcessingFiles: (state, action: PayloadAction<Record<string, ResponseDocument>>) => {
      state.processingFiles = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listDocuments.pending, (state) => {
        state.fetchStatus = QueryStatus.FETCHING;
      })
      .addCase(listDocuments.fulfilled, (state, action) => {
        state.fetchStatus = QueryStatus.SUCCEEDED;
        state.files = action.payload.sort(sortDocumentsFn);
        state.timestamp = Date.now();
      })
      .addCase(listDocuments.rejected, (state, action) => {
        state.fetchStatus = QueryStatus.ERROR_FETCHING;
        state.files = [];
        handleError(action.error)
      })
      .addCase(uploadDocument.pending, (state, action) => {
        state.uploadStatus = QueryStatus.UPLOADING;
        state.fileUpload.push(action.meta.arg);
      })
      .addCase(uploadDocument.fulfilled, (state, action) => {
        state.uploadStatus = QueryStatus.IDLE;
        state.fileUpload = state.fileUpload.filter(
          (file) => file.name !== action.meta.arg.name
        );
      })
      .addCase(uploadDocument.rejected, (state, action) => {
        state.uploadStatus = QueryStatus.ERROR_UPLOADING;
        state.fileUpload = state.fileUpload.filter(
          (file) => file.name !== action.meta.arg.name
        );
        handleError(action.error)
      });
  },
});

export const actions = documentSlice.actions;
export default documentSlice.reducer;