import { LocalStorageKey, STREAM_RESPONSE_DEBOUNCE_MS } from '@/constants'
import type { AssistantStore, Conversation } from '@/types/types'

export function getLiveConversationById({
  requestId,
  conversationId,
  store,
}: {
  requestId: string
  conversationId: string
  store: AssistantStore
}): Conversation {
  const conversation = store.liveConversations?.find(
    (lc) =>
      lc?.conversationId === conversationId ||
      lc?.requestIds?.includes(requestId)
  )
  return conversation?.conversation || []
}

export function getDebouncedStreamResponseMS() {
  try {
    const storedValue = localStorage.getItem(LocalStorageKey?.STREAM_RESPONSE_DEBOUNCE_MS)
    if (storedValue) {
      return parseInt(storedValue)
    }
    return STREAM_RESPONSE_DEBOUNCE_MS
  } catch (e) {
    console.error(e)
    return STREAM_RESPONSE_DEBOUNCE_MS
  }
}

