import { DocgenSession, DocgenTemplate } from '@/types/types'
import { TypographyBody, TypographyH4 } from '../ui/Typography'
import { TemplateBadge } from './TemplateBadge'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import { Button } from '../ui/button'
import { ArrowLeft, ArrowRight, LayoutPanelTop, Loader2 } from 'lucide-react'
import { useEffect, useRef, useState } from 'react'
import Divider from '../ui/divider'
import { Link, useBlocker } from 'react-router-dom'
import { DocGenTemplateForm } from './DocGenTemplateForm'
import { createSession } from './docGenThunk'
import { isFulfilled } from '@reduxjs/toolkit'
import { DocGenCustomizationSaveDialog } from './DocGenCustomizationSaveDialog'
import { CustomAlert } from '../CustomAlert'
import { addSession } from './docGenSlice'

export const DocGenCustomization = ({
  template,
  onPrevClick,
  onNextClick,
  onSubmit,
  onSessionCreate,
}: {
  template: DocgenTemplate | null
  onPrevClick: () => void
  onNextClick: () => void
  onSubmit: (template: DocgenTemplate) => void
  onSessionCreate: (session: DocgenSession) => void
}) => {
  const templates = useSelector((state: RootState) => state.docGen.templates)
  const templateCategories = useSelector(
    (state: RootState) => state.docGen.templateCategories.data
  )
  const session = useSelector((state: RootState) => state.docGen.sessionInUse)

  const [showFloatingButton, setShowFloatingButton] = useState(false)
  const [loading, setLoading] = useState(false)
  const [templateError, setTemplateError] = useState(false)
  const [sessionError, setSessionError] = useState(false)
  const [hasEdited, setHasEdited] = useState(false)

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      hasEdited && currentLocation.pathname !== nextLocation.pathname
  )

  const formRef = useRef<HTMLFormElement>(null)

  const dispatch = useDispatch<AppDispatch>()

  const FormSubmitButton = () => {
    return (
      <>
        <div className="flex flex-col gap-4">
          {(templateError || sessionError) && (
            <CustomAlert
              variant="error"
              description="Failed to save changes. Please retry."
            />
          )}
          <div className="flex gap-2 mx-auto">
            <Button variant="secondary" onClick={onPrevClick}>
              <div className="flex gap-2 items-center">
                <ArrowLeft className="size-6 shrink-0 stroke-interactive" />
                Back
              </div>
            </Button>

            <Button
              onClick={() => {
                formRef.current?.requestSubmit()
                setLoading(true)
                setTemplateError(false)
              }}
            >
              <div className="flex gap-2 items-center">
                Continue
                {loading ? (
                  <Loader2 className="size-6 shrink-0 stroke-interactive animate-spin" />
                ) : (
                  <ArrowRight className="size-6 shrink-0 stroke-interactive" />
                )}
              </div>
            </Button>
          </div>
        </div>
      </>
    )
  }

  const handleSubmit = async (template: DocgenTemplate) => {
    setLoading(true)
    setSessionError(false)

    if (session) {
      onSubmit(template)
      handleSubmitSuccess()
    } else {
      const result = await dispatch(
        createSession({
          topic: '',
          template_id: template.id,
          title: template.title,
          files: [],
          sources: [],
        })
      )

      if (isFulfilled(createSession)(result)) {
        onSubmit(template)
        onSessionCreate(result.payload)
        dispatch(
          addSession({
            ...result.payload,
            template_id: undefined,
            template: {
              category_id: template.category_id,
              copy_of: template.copy_of || null,
              id: crypto.randomUUID(),
              name: template.name,
              title: template.title,
            },
          })
        )
        handleSubmitSuccess()
      } else {
        setSessionError(true)
      }
    }

    setLoading(false)
  }

  const handleSubmitSuccess = () => {
    if (blocker.state === 'blocked') {
      blocker?.proceed?.()

      // fixes "router allows one blocker at a time" error
      setTimeout(() => {
        blocker?.reset?.()
      }, 500)
    } else {
      onNextClick()
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const mainContainer = document.querySelector('.main-container-new')

      const scrolledDown =
        (mainContainer?.scrollHeight || 0) -
          (mainContainer?.scrollTop || 0) -
          96 <
        (mainContainer?.clientHeight || 0)
      setShowFloatingButton(!scrolledDown)
    }

    handleScroll()

    window.addEventListener('scroll', handleScroll, { capture: true })

    return () => {
      window.removeEventListener('scroll', handleScroll, { capture: true })
    }
  }, [templates])

  useEffect(() => {
    if (blocker.state === 'blocked') {
      formRef.current?.requestSubmit()
    }
  }, [blocker.state])

  return (
    <>
      <div className="flex flex-col gap-10 max-w-[79.5rem] mx-auto pb-10 w-full">
        <div className="flex flex-col gap-1 max-w-[47.625rem] mx-auto">
          <div className="flex gap-2 items-center mx-auto">
            <TypographyH4>Customize template</TypographyH4>

            {template && (
              <TemplateBadge
                template={template}
                categories={templateCategories}
              />
            )}
          </div>

          <TypographyBody className="text-system-body text-center">
            Adapt the template to fit this specific report
          </TypographyBody>
        </div>
        <div className="flex flex-col gap-3 p-4 max-w-[47.625rem] w-full mx-auto  border border-system-border-regular rounded-md">
          <div className="flex gap-3 items-center">
            <div className="flex flex-col gap-0">
              <TypographyBody isStrong className="text-system-primary">
                Changes made here only affect the report and not the template
              </TypographyBody>

              <TypographyBody className="text-system-body">
                To edit the template, go to Templates page
              </TypographyBody>
            </div>

            <Link to={'/templates'} className="ml-auto">
              <Button variant="secondary">
                <div className="flex gap-2 items-center">
                  <LayoutPanelTop className="size-6 shrink-0 stroke-interactive" />
                  Template gallery
                </div>
              </Button>
            </Link>
          </div>

          <Divider />

          <div className="flex gap-3 items-center">
            <TypographyBody className="text-system-body">
              No changes needed
            </TypographyBody>

            <Button
              variant="secondary"
              className="ml-auto"
              onClick={() => {
                if (template) {
                  handleSubmit(template)
                }
              }}
            >
              Skip customization
            </Button>
          </div>
        </div>

        <div className="max-w-[47.625rem] mx-auto w-full">
          <DocGenTemplateForm
            selectedTemplate={template}
            templates={templates}
            onSave={(v) => {
              handleSubmit(v)
            }}
            formRef={formRef}
            createDuplicate={!session}
            templateCustomization
            onEdit={() => setHasEdited(true)}
            onError={() => {
              setTemplateError(true)
              setLoading(false)
            }}
          />
        </div>

        <FormSubmitButton />
      </div>
      {showFloatingButton && (
        <div className="fixed flex flex-col gap-4 h-fit w-fit p-4 bg-system-surface rounded-lg border border-system-border-regular shadow-outline-dialog bottom-10 left-[50%] -translate-x-[50%]">
          <FormSubmitButton />
        </div>
      )}
      <DocGenCustomizationSaveDialog open={blocker.state === 'blocked'} />
    </>
  )
}
