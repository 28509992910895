import { TypographyBody, TypographyLabel } from '../ui/Typography'
import { EllipsisVertical, GripVertical, SlidersVertical } from 'lucide-react'
import { TemplateSection, TemplateSubsection } from '@/types/types'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DropResult,
} from '@hello-pangea/dnd'
import { Button } from '../ui/button'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import { CustomTooltip } from '../CustomTooltip'

export const TemplateSubsectionRow = ({
  section,
  sections,
  subsection,
  indexes,
  provided,
  snapshot,
  disabled = false,
  onSubsectionUpdate,
  onRemove,
  onEdit,
}: {
  section: TemplateSubsection
  sections: TemplateSection[]
  subsection: TemplateSubsection
  indexes: number[]
  provided: DraggableProvided
  snapshot: DraggableStateSnapshot
  disabled?: boolean
  onSubsectionUpdate: (section: TemplateSubsection) => void
  onRemove: ({
    section,
    subsection,
    indexes,
  }: {
    section?: TemplateSection
    subsection?: TemplateSubsection
    indexes?: number[]
  }) => void
  onEdit: (indexes: number[]) => void
}) => {
  const isThirdLevel = indexes.length >= 3

  const [hover, setHover] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const reorder = (
    list: TemplateSubsection[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    if (isThirdLevel) {
      const subsections = subsection.subsections
      if (!subsections) return

      const items = reorder(
        subsections,
        result.source.index,
        result.destination.index
      )

      const copy = [...(section.subsections || [])]

      if (indexes.at(-1) === undefined) return

      copy[indexes.at(-1) || 0] = {
        ...subsection,
        subsections: items,
      }

      onSubsectionUpdate({
        ...section,
        subsections: copy,
      })
    } else {
      const subsections = section.subsections
      if (!subsections) return

      const items = reorder(
        subsections,
        result.source.index,
        result.destination.index
      )

      onSubsectionUpdate({
        ...section,
        subsections: items,
      })
    }
  }

  const handleAddNewSubsection = () => {
    const id = (section.subsections || []).length > 0 ?  Math.max(...(section.subsections || []).map((v) => v.id)) + 1 : 0
    onSubsectionUpdate({
      ...section,
      subsections: [
        {
          id: id,
          title: '',
        },
        ...(section.subsections || []),
      ],
    })

    onEdit([...indexes, 0])
  }

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      className={`relative flex gap-3 ${isThirdLevel ? 'px-1 py-1' : 'px-2 py-2'} items-center border border-transparent ${snapshot.isDragging ? `${isThirdLevel ? 'bg-system-hover' : 'bg-system-surface-light'}  !border-system-border-light` : !isThirdLevel ? 'bg-system-secondary' : ''} rounded-lg ${isThirdLevel ? 'hover:bg-system-hover' : 'hover:bg-system-surface-light'} ${hover ? 'section-hover' : ''}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="flex flex-col gap-2 w-full">
        <div className="flex gap-2 w-full">
          <Button
            variant="tertiary"
            {...provided.dragHandleProps}
            className={`${hover || snapshot.isDragging ? 'opacity-100' : 'opacity-0'} h-fit`}
          >
            <GripVertical className="size-6 shrink-0 stroke-[1.5px]" />
          </Button>

          <div
            className={`flex gap-3 items-center w-full ${isThirdLevel ? 'ml-1' : ''}`}
          >
            {isThirdLevel ? (
              <TypographyLabel className="!font-label-strong">
                {subsection.title}
              </TypographyLabel>
            ) : (
              <TypographyBody isStrong>{subsection.title}</TypographyBody>
            )}
          </div>

          <Button
            variant="tertiary"
            className={`${hover || snapshot.isDragging || dropdownOpen ? '' : 'hidden'}`}
            data-tooltip-id={`subsection-edit-${indexes}`}
            onClick={() => {
              onEdit(indexes)
            }}
          >
            <SlidersVertical className={`size-6 shrink-0 stroke-[1.5px]`} />
          </Button>

          <CustomTooltip
            id={`subsection-edit-${indexes}`}
            className="!py-1 !px-3 !rounded-sm"
            largeArrow={false}
          >
            <TypographyLabel className="text-system-primary">
              View subsection configuration
            </TypographyLabel>
          </CustomTooltip>
          {!disabled && (
            <DropdownMenu
              onOpenChange={(v) => {
                if (v) {
                  setDropdownOpen(v)
                } else {
                  setTimeout(() => {
                    setDropdownOpen(v)
                  }, 100)
                }
              }}
            >
              <DropdownMenuTrigger className="flex items-center" asChild>
                <Button
                  variant="tertiary"
                  className={`${hover || snapshot.isDragging || dropdownOpen ? '' : 'hidden'}`}
                >
                  <EllipsisVertical
                    className={`size-6 shrink-0 stroke-[1.5px]`}
                  />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-[12rem]">
                {!isThirdLevel && (
                  <DropdownMenuItem
                    variant="tertiary"
                    onClick={(e) => {
                      e.stopPropagation()
                      handleAddNewSubsection()
                    }}
                  >
                    Add subsection
                  </DropdownMenuItem>
                )}

                <DropdownMenuItem
                  variant="tertiary-destructive"
                  onClick={(e) => {
                    e.stopPropagation()
                    onRemove({ subsection: subsection, indexes })
                  }}
                >
                  Remove
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>

        {(subsection.subsections || []).length > 0 && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className={`${snapshot.isDraggingOver ? 'pointer-events-none' : ''}`}
                >
                  {subsection.subsections?.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={`draggable-${item.id}`}
                      index={index}
                    >
                      {(provided, snapshot) =>
                        snapshot.isDragging ? (
                          createPortal(
                            <TemplateSubsectionRow
                              section={section}
                              sections={sections}
                              subsection={item}
                              indexes={[...indexes, index]}
                              provided={provided}
                              snapshot={snapshot}
                              onSubsectionUpdate={onSubsectionUpdate}
                              onRemove={onRemove}
                              onEdit={onEdit}
                            />,
                            document.body
                          )
                        ) : (
                          <TemplateSubsectionRow
                            section={section}
                            sections={sections}
                            subsection={item}
                            indexes={[...indexes, index]}
                            provided={provided}
                            snapshot={snapshot}
                            onSubsectionUpdate={onSubsectionUpdate}
                            onRemove={onRemove}
                            onEdit={onEdit}
                          />
                        )
                      }
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </div>
    </div>
  )
}
