import { DocgenTemplate } from '@/types/types'
import { Button } from '../ui/button'
import { TypographyBody, TypographyLabel } from '../ui/Typography'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import { EllipsisVertical } from 'lucide-react'
import { useState } from 'react'
import { DocGenMoveTemplateDialog } from './DocGenMoveTemplateDialog'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import { useNavigate } from 'react-router-dom'
import { DocGenTemplateDeleteDialog } from './DocGenTemplateDeleteDialog'

export const TemplateCard = ({
  template,
  selected = false,
  onClick,
}: {
  template: DocgenTemplate
  selected?: boolean
  onClick?: () => void
}) => {
  const templateCategories = useSelector(
    (state: RootState) => state.docGen.templateCategories.data
  )

  const [showMoveDialog, setShowMoveDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  const navigate = useNavigate()

  return (
    <>
      <Button
        key={`template-card-${template.id}`}
        variant="selection"
        size="selection-m"
        className={`w-full min-h-[6.25rem] max-h-[7rem] cursor-pointer ${selected ? 'bg-system-hover inner-border-2' : ''}`}
        asChild
        onClick={onClick}
      >
        <div className="flex gap-2 w-full h-full items-start">
          <div className="flex flex-col gap-1 items-start w-full h-full">
            <TypographyBody
              isStrong
              className="w-full text-left line-clamp-1 break-all text-ellipsis max-w-[20rem]"
            >
              {template.title}
            </TypographyBody>

            <TypographyLabel className="text-system-body w-full text-left whitespace-pre-wrap line-clamp-2">
              {template.description}
            </TypographyLabel>
          </div>

          {template.template_type === 'user' && (
            <div className="h-full">
              <DropdownMenu>
                <DropdownMenuTrigger className="flex items-center" asChild>
                  <Button variant="tertiary" className="h-fit -mr-4">
                    <EllipsisVertical
                      className={`size-6 shrink-0 stroke-[1.5px]`}
                    />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start" className="w-[12rem]">
                  <DropdownMenuItem
                    variant="tertiary"
                    onClick={(e) => {
                      e.stopPropagation()
                      navigate(`/template/${template.id}`)
                    }}
                  >
                    Edit template
                  </DropdownMenuItem>

                  <DropdownMenuItem
                    variant="tertiary"
                    onClick={(e) => {
                      e.stopPropagation()
                      setShowMoveDialog(true)
                    }}
                  >
                    Move to category
                  </DropdownMenuItem>

                  <DropdownMenuItem
                    variant="tertiary-destructive"
                    onClick={(e) => {
                      e.stopPropagation()
                      setShowDeleteDialog(true)
                    }}
                  >
                    Remove
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          )}
        </div>
      </Button>

      <DocGenMoveTemplateDialog
        open={showMoveDialog}
        setOpen={setShowMoveDialog}
        categories={templateCategories}
        template={template}
      />

      <DocGenTemplateDeleteDialog
        template={template}
        open={showDeleteDialog}
        setOpen={setShowDeleteDialog}
      />
    </>
  )
}
