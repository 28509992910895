import { formatDisplayNumber } from '@/utils/utils'
import { TypographyBody, TypographyLabel } from '../ui/Typography'
import {
  formatChartDate,
  getChartCitation,
  getChartColor,
  getIsDataPointSelected,
} from './utils'
import { Citation } from '@/types/types'
import { Button } from '../ui/button'
import { X } from 'lucide-react'
export const ChartTooltip = ({
  id,
  categories,
  series,
  seriesIndex,
  dataPointIndex,
  citations,
  openedCitation,
  withShadow = true,
  showOpenedCitation = false,
  isPieChart = false,
  onClose,
}: {
  id: string
  categories: string[][]
  series: ApexAxisChartSeries
  seriesIndex: number
  dataPointIndex: number
  citations: Citation[]
  openedCitation?: Citation | null
  withShadow?: boolean
  showOpenedCitation?: boolean
  isPieChart?: boolean
  onClose?: () => void
}) => {
  // @ts-expect-error
  const isSeriesShown = (idx: number) => {
    // fixme
    if (showOpenedCitation) {
      return isPieChart
        ? idx === openedCitation?.index_x
        : seriesIndex === openedCitation?.index_y
    }
    return true
  }

  const currentSeries = series[seriesIndex]

  const citation = getChartCitation(
    currentSeries.data[dataPointIndex] as number,
    citations,
    dataPointIndex,
    seriesIndex
  )

  const isSelected = getIsDataPointSelected(
    currentSeries.data[dataPointIndex] as number,
    openedCitation,
    dataPointIndex,
    seriesIndex
  )

  const text = isPieChart ? citation?.text : currentSeries?.data[dataPointIndex] //fixme
  const seriesName = isPieChart
    ? categories[dataPointIndex]?.join(' ')
    : currentSeries?.name
  const category = isPieChart
    ? currentSeries?.name
    : categories[dataPointIndex]?.join(' ')

  return (
    <div
      className={`flex flex-col gap-2 px-3 py-2 rounded-sm border border-system-border-regular ${withShadow ? 'shadow-soft' : ''} bg-system-secondary w-fit z-[50]`}
    >
      <div className="flex gap-2 items-center">
        <TypographyBody isStrong={true} className="text-system-primary">
          {formatChartDate(category as string)}
        </TypographyBody>

        {onClose && (
          <Button variant="tertiary" className="ml-auto" onClick={onClose}>
            <X className="size-6 shrink-0 stroke-interactive" />
          </Button>
        )}
      </div>
      <div className="flex flex-col gap-2">
        <div
          key={`chart-${id}-tooltip-${seriesIndex}`}
          className="flex gap-3 items-center"
        >
          <div
            className="size-4 rounded-[2px]"
            style={{
              backgroundColor: getChartColor(
                isPieChart ? dataPointIndex : seriesIndex
              ),
            }}
          ></div>

          <TypographyLabel className="text-system-body">
            {seriesName}
          </TypographyLabel>

          <TypographyLabel
            className={`px-[1px] !font-label-strong text-system-primary ml-auto 
              ${isSelected ? 'bg-citation-highlight border-b-[3px] border-t-[3px] border-t-transparent border-b-citation-accent rounded-t-sm' : ''}
              ${citation ? 'border-b-2 border-b-citation-border border-t-2 border-t-transparent' : ''}`}
          >
            {formatDisplayNumber(`${text}`)}
          </TypographyLabel>
        </div>
      </div>
    </div>
  )
}
