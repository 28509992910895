import { Button } from '../ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import { deleteTemplateCategory } from './docGenThunk'
import { TemplateCategory } from '@/types/types'
import { Loader2 } from 'lucide-react'
import { isFulfilled } from '@reduxjs/toolkit'
import { removeTemplateCategory, replaceTemplate } from './docGenSlice'

export const DocGenDeleteCategoryDialog = ({
  category,
  open,
  setOpen,
}: {
  category?: TemplateCategory
  open: boolean
  setOpen: (open: boolean) => void
}) => {
  const templates = useSelector((state: RootState) => state.docGen.templates)

  const [loading, setLoading] = useState(false)

  const categoryTemplates = templates.filter(
    (v) => v.category_id === category?.id
  )

  const dispatch = useDispatch<AppDispatch>()

  const handleDelete = async () => {
    if (!category?.id) return

    setLoading(true)
    const result = await dispatch(deleteTemplateCategory(category?.id))

    if (isFulfilled(deleteTemplateCategory)(result)) {
      dispatch(removeTemplateCategory(category.id))

      categoryTemplates.forEach((template) => {
        dispatch(
          replaceTemplate({
            ...template,
            category_id: null,
          })
        )
      })

      setOpen(false)
    }

    setLoading(false)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="!max-w-[420px]">
        <DialogHeader>
          <DialogTitle>{`Delete '${category?.title || ''}' category`}</DialogTitle>
        </DialogHeader>
        <DialogFooter>
          <Button variant="secondary-destructive" onClick={handleDelete}>
            <div className="flex gap-2 items-center">
              Delete
              {loading && (
                <Loader2 className="size-6 shrink-0 stroke-interactive animate-spin" />
              )}
            </div>
          </Button>
          <DialogClose asChild>
            <Button>Keep category</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
