import { FinalAnswer } from "@/components/Assistant/FinalAnswer";
import { PreviewSources } from "@/components/Assistant/PreviewSources";
import { ErrorMessage } from "@/components/ErrorMessage";
import { TypographyBody } from "@/components/ui/Typography";
import { X } from "lucide-react";
import { clearResearch, requestResearch, setToolComponent } from "../docGenSlice";
import { Button } from "@/components/ui/button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { WebSocketRequestWrapper, RequestAssistantAsk, Citation, SourceDocument } from "@/types/types";
import { getGlobalUniqueDocuments, filterDocumentsByCited } from "@/utils/components";
import { getTimestamp } from "@/utils/utils";
import { useEffect } from "react";
import shortid from "shortid";
import { ActionMenu } from "@/components/ActionMenu";
import { OutputData } from "@editorjs/editorjs";
import { convertReportToString } from "@/utils/docgen";

export function Research({ data, addToDocumentInline }: { data: OutputData, addToDocumentInline: (partialSystemMessage: { text: string, citations: Citation[], documents: SourceDocument[] }, replaceHighlight: boolean) => void }) {
    const dispatch = useDispatch();
    const research = useSelector((state: RootState) => state.docGen.research);
    const message = useSelector((state: RootState) => state.docGen.selectedText);
    const partialSystemMessage = useSelector((state: RootState) => state.docGen.research.data?.response);

    const isLoading = research.status === 'loading' && !partialSystemMessage?.text;
    const error = research.status === 'error';
    const isComplete = research.status === 'success';
    const filteredDocuments = getGlobalUniqueDocuments(filterDocumentsByCited(partialSystemMessage?.documents || [], partialSystemMessage?.citations || [], true));
    const reportContent = convertReportToString(data);

    useEffect(() => {
        if (research.status === 'idle' && message) {
            const request: WebSocketRequestWrapper<RequestAssistantAsk> = {
                requestId: shortid(),
                timestamp: getTimestamp(),
                params: {
                    message: message,
                    context: reportContent,
                }
            }
            dispatch(requestResearch(request));
        }
    }, [message])

    return (
        <div className="flex flex-col gap-2 flex-grow overflow-y-hidden">
            <div className="w-full p-4 flex flex-col flex-shrink gap-4 bg-system-surface border border-system-border-light rounded-lg max-h-[calc(100vh-5rem-2rem-3rem-8rem)]">
                <div className="flex flex-1 justify-between items-center">
                    <TypographyBody isStrong={true}>Research</TypographyBody>
                    <div className="flex flex-row justify-between items-center">
                        <Button variant={"tertiary"} onClick={() => {
                            dispatch(setToolComponent(null));
                            dispatch(clearResearch());
                        }}>
                            <X className="h-6 w-6 stroke-[1.5px]" />
                        </Button>
                    </div>
                </div>
                {(!isLoading && error) ? (
                    <div className="py-2">
                        <ErrorMessage message={"We failed to run Research, try again later"} />
                    </div>
                ) : (
                    <>
                        <PreviewSources documents={filteredDocuments} loading={isLoading} compact sourceType='report' />
                        <div className="overflow-y-auto ">
                            <FinalAnswer
                                isLoading={isLoading}
                                isComplete={research.status === 'success'}
                                text={research.data?.response.text || ""}
                                citations={research.data?.response.citations || []}
                                documents={filteredDocuments}
                                compact={true}
                            />
                        </div>
                    </>
                )}
            </div>
            <div className="flex-shrink-0">
                <ActionMenu
                    className={`ml-auto ${isComplete ? 'opacity-1' : 'opacity-0'}`}
                    buttons={[
                        {
                            variant: "tertiary",
                            children: 'Add below highlight',
                            onClick: () => {
                                if (partialSystemMessage) {
                                    addToDocumentInline(partialSystemMessage, false);
                                    dispatch(setToolComponent(null));
                                    dispatch(clearResearch());
                                }
                            },
                            disabled: !isComplete
                        }
                    ]}
                />
            </div>
        </div>
    )
}
