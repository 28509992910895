import { ApexChartsResponseChartProps } from '@/types/types'
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { useEffect, useRef, useState } from 'react'
import { ChartHeader } from './ChartHeader'
import { ChartTooltip } from './ChartTooltip'
import { getChartCitation } from './utils'

export const ResponseAreaChart = ({
  id,
  parsedData,
  series,
  options,
  compact,
  citations,
  documents,
  openedCitation,
  onCitationOpen,
  categories,
  isExportEnabled = true,
}: ApexChartsResponseChartProps) => {
  const [labelsRotated, setLabelsRotated] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const lineOptions: ApexOptions = {
    ...options,
    chart: {
      ...options.chart,
      type: 'area',
      events: {
        ...options.chart?.events,
        dataPointMouseEnter: undefined,
        mouseMove: function (_1, chartContext, _3) {
          chartContext.el.style.cursor = 'pointer'
        },
        mouseLeave: function (_1, chartContext, _3) {
          chartContext.el.style.cursor = 'default'
        },
        markerClick: (_, chartContext, config) => {
          const citation = getChartCitation(
            chartContext.w.globals.series[config.seriesIndex][
              config.dataPointIndex
            ],
            citations,
            config.dataPointIndex,
            config.seriesIndex
          )

          if (citation) {
            onCitationOpen?.(citation, 0)
          }
        },
      },
    },
    xaxis: {
      ...options.xaxis,
      labels: {
        ...options.xaxis?.labels,
        offsetY: labelsRotated ? 8 : 0,
        trim: false,
      },
      tickAmount: 5,
    },
    tooltip: {
      ...options.tooltip,
      shared: true,
    },
    fill: {
      ...options.fill,
      gradient: {
        shade: 'light',
        shadeIntensity: 0,
        opacityFrom: 0.5,
        opacityTo: 0.5,
        stops: [0, 100],
      },
    },
  }

  useEffect(() => {
    setTimeout(() => {
      const element = document.querySelector('.apexcharts-xaxis-label')
      if (element) {
        const style = window.getComputedStyle(element)
        const hasTransform = style.transform !== 'none'
        setLabelsRotated(hasTransform)
      }
    }, 1000)
  }, [])

  return (
    <div className={`p-4 ${compact ? 'mt-8' : 'mt-10'}`}>
      <div
        id={`chart-container-${id}`}
        className={`flex flex-col gap-4 relative group`}
      >
        <ChartHeader
          chartRef={ref}
          parsedData={parsedData}
          compact={compact}
          citations={citations}
          documents={documents}
          openedCitation={openedCitation}
          onCitationOpen={onCitationOpen}
          isExportEnabled={isExportEnabled}
        />

        {openedCitation?.chart_table_id === id &&
          !openedCitation.isChartHeaderCitation && (
            <ChartTooltip
              id={id}
              categories={categories}
              series={series}
              dataPointIndex={openedCitation!.index_x!}
              seriesIndex={openedCitation!.index_y!}
              citations={citations}
              openedCitation={openedCitation}
              withShadow={false}
              onClose={() => onCitationOpen?.(null, 0)}
              showOpenedCitation
            />
          )}

        <div
          ref={ref}
          id={`chart-${id}`}
          className="[&_.apexcharts-area]:mix-blend-plus-darker [&_.apexcharts-tooltip]:!border-none [&_.apexcharts-tooltip]:!rounded-sm [&_.apexcharts-tooltip]:!bg-system-secondary"
        >
          <Chart options={lineOptions} series={series} type="area" />
        </div>
      </div>
    </div>
  )
}
