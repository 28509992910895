import { Globe, LibraryBig } from 'lucide-react'
import { StateChip } from '../StateChip'

export const DocGenSourceSelector = ({
  sources,
  onSourceChange,
}: {
  sources: string[]
  onSourceChange: (sources: string[]) => void
}) => {
  const isSelectedFileSelected = sources.includes('selected_files')
  const isWebSelected = sources.includes('web')

  const toggleSource = (source: string) => {
    if (sources.includes(source)) {
      onSourceChange(sources.filter((v) => v !== source))
    } else {
      onSourceChange([...sources, source])
    }
  }

  return (
    <>
      <div
        className="flex gap-6 items-center"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-wrap gap-3">
          <StateChip
            label={'Selected Files'}
            active={isSelectedFileSelected}
            leftIcon={<LibraryBig className="size-5 shrink-0 stroke-[1.5px]" />}
            onClick={() => toggleSource('selected_files')}
          />

          <StateChip
            label="Web"
            active={isWebSelected}
            leftIcon={<Globe className="size-5 shrink-0 stroke-[1.5px]" />}
            onClick={() => toggleSource('web')}
          />
        </div>
      </div>
    </>
  )
}
