import { Playbook } from '@/types/types'
import { X } from 'lucide-react'
import { TypographyBody, TypographyLabel } from '../ui/Typography'
import { Button } from '../ui/button'
import Divider from '../ui/divider'
import { ResponseMarkdown } from './ResponseMarkdown'
import { replacePlaybookVariable } from '@/utils/utils'
import { Badge } from '../ui/badge'
import { getPlaybookCardIcon } from '@/utils/components'
import InfoCircle from '@/assets/InfoCircle'
import { CustomTooltip } from '../CustomTooltip'

export const PlaybookChatPreview = ({
  playbookId,
  playbook,
  variables,
  onClose,
}: {
  playbookId: string
  playbook: Playbook
  variables: string[]
  onClose: () => void
}) => {
  const playbookItem = playbook.items.find((v) => v.id === playbookId)

  return (
    <div className="flex flex-col gap-3 max-h-[calc(100vh-7.75rem)] border border-system-border-regular rounded-lg p-3 bg-system-secondary w-full">
      <div className="flex gap-2 items-center relative pr-6">
        {getPlaybookCardIcon(playbookItem?.category || '')}

        <TypographyBody
          isStrong
        >{`${playbook.title} ${playbook.items.length > 1 ? `- ${playbookItem?.option || ''}` : ''}`}</TypographyBody>

        <Button
          variant="tertiary"
          data-tooltip-id={`playbook-card-${playbook?.items.at(0)?.id || ''}-tooltip`}
          data-tooltip-place="top"
          className="size-4"
        >
          <InfoCircle className="size-4 shrink-0 stroke-[1.5px] !stroke-system-placeholder" />
        </Button>

        <CustomTooltip
          id={`playbook-card-${playbook?.items.at(0)?.id || ''}-tooltip`}
          className="!py-1 !px-3 !rounded-sm"
          largeArrow={false}
        >
          <TypographyLabel className="text-system-body whitespace-pre-wrap max-w-[20rem] text-center">
            {playbook?.items.at(0)?.tooltip || ''}
          </TypographyLabel>
        </CustomTooltip>

        <div className="absolute top-0 right-0">
          <Button variant="tertiary" onClick={onClose}>
            <X className="size-6 shrink-0 stroke-interactive" />
          </Button>
        </div>
      </div>

      <Divider />

      <TypographyBody className="text-system-body">
        Prompt details
      </TypographyBody>

      <div className="w-full p-6 bg-system-surface-light rounded-[12px] overflow-y-auto text-system-body">
        <ResponseMarkdown
          text={replacePlaybookVariable(
            playbookItem?.prompt || '',
            variables.at(0)
          )}
          documents={[]}
          citations={[]}
          overrides={{
            Badge: {
              component: ({ children }: { children: string }) => (
                <Badge variant="purple">{children}</Badge>
              ),
            },
          }}
          isTable={false}
          openedCitation={null}
        />
      </div>
    </div>
  )
}
