import { DotIcon } from 'lucide-react'
import Markdown, { MarkdownToJSX } from 'markdown-to-jsx'
import { ReactNode, useMemo } from 'react'
import { TypographyP } from '../ui/Typography'
import { StyledH4 } from './StyledH4'
import { tertiaryStyle } from '../ui/button'
import { Citation, SourceDocument, TableType } from '@/types/types'
import { ResponseChart, ResponseChartError } from '../Charts/ResponseChart'
import { ResponseTable } from '../Table/ResponseTable'
import { ResponseTableError } from '../Table/ResponseTableError'

interface CursorProps {
  show: boolean
}

function Cursor({ show }: CursorProps) {
  if (!show) return null
  return <DotIcon className="w-6 h-6 text-system-body inline" />
}

export const ResponseMarkdown = ({
  text,
  documents,
  citations,
  openedCitation,
  onCitationOpen,
  compact = false,
  overrides = {},
  isTable,
  isExpand,
  isShouldShift,
}: {
  text: string
  documents: SourceDocument[]
  citations: Citation[]
  openedCitation?: Citation | null
  onCitationOpen?: (citation: Citation | null, scrollTop: number) => void
  compact?: boolean
  overrides?: MarkdownToJSX.Overrides
  isTable: boolean
  isExpand?: boolean
  isShouldShift?: boolean
}) => {
  const chartDocuments = documents.filter((v) =>
    v.document_id.includes('chart')
  ).length
  const tableDocuments = documents.filter((v) =>
    v.document_id.includes('table')
  ).length

  const Chart = useMemo(
    () =>
      ({ id }: { id: string }) => {
        const document = documents.find((v) => v.document_id === id)
        if (!document) {
          return <ResponseChartError />
        }

        return (
          <ResponseChart
            key={`response-chart-container-${id}`}
            id={id}
            data={document.text}
            compact={compact}
            citations={citations}
            documents={documents}
            openedCitation={openedCitation}
            onCitationOpen={onCitationOpen}
          />
        )
      },
    [chartDocuments, openedCitation, citations]
  )

  const Table = useMemo(
    () =>
      ({ id }: { id: string }) => {
        const document = documents.find((v) => v.document_id === id)
        if (!document) {
          return <ResponseTableError />
        }
        return (
          <ResponseTable
            key={`response-table-container-${id}`}
            id={id}
            data={document.text}
            citations={citations}
            documents={documents}
            compact={compact}
            overrides={overrides}
            openedCitation={openedCitation}
            isExpand={isExpand}
            isShouldShift={isShouldShift}
            type={TableType.DEFAULT}
          />
        )
      },
    [tableDocuments, openedCitation, citations, isShouldShift]
  )

  const MemoizedP = useMemo(
    () =>
      ({ children }: { children: ReactNode }) => (
        <>
          <TypographyP
            className={`mt-4 first:!mt-0 ${compact ? 'font-label' : 'font-body'}`}
          >
            {children}
          </TypographyP>
        </>
      ),
    []
  )

  const MemoizedStyledH4 = useMemo(() => StyledH4, [])

  const MemoizedLi = useMemo(
    () =>
      ({ children }: { children: ReactNode }) => (
        <li
          className={`${compact ? 'font-label' : 'font-body'} ${isTable ? '' : 'ml-6'} mt-2`}
        >
          {children}
        </li>
      ),
    []
  )

  const MemoizedStrong = useMemo(
    () =>
      ({ children }: { children: ReactNode }) => (
        <span className={`text-system-primary font-medium mt-6`}>
          {children}
        </span>
      ),
    []
  )

  const MemoizedPre = useMemo(
    () =>
      ({ children }: { children: ReactNode }) => <pre>{children}</pre>,
    []
  )

  const MemoizedA = useMemo(
    () =>
      ({ href, children }: { href: string; children: ReactNode }) => {
        return (
          <a
            href={href}
            className={`cursor-pointer underline ${tertiaryStyle} !text-system-body whitespace-pre-wrap`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {children}
          </a>
        )
      },
    []
  )

  return (
    <Markdown
      options={{
        overrides: {
          Cursor: {
            component: Cursor,
          },
          p: {
            component: MemoizedP,
          },
          h1: {
            component: MemoizedStyledH4,
          },
          h2: {
            component: MemoizedStyledH4,
          },
          h3: {
            component: MemoizedStyledH4,
          },
          h4: {
            component: MemoizedStyledH4,
          },
          h5: {
            component: MemoizedStyledH4,
          },
          h6: {
            component: MemoizedStyledH4,
          },
          li: {
            component: MemoizedLi,
          },
          strong: {
            component: MemoizedStrong,
          },
          pre: {
            component: MemoizedPre,
          },
          Chart: {
            component: Chart,
          },
          a: {
            component: MemoizedA,
          },
          Table: {
            component: Table,
          },
          ...overrides,
        },
        wrapper: 'span',
        forceBlock: true,
      }}
    >
      {text}
    </Markdown>
  )
}
