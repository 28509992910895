import { SearchConnectors } from '@/types/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
interface SearchState {
  connectors: SearchConnectors
}

const initialState: SearchState = {
  connectors: {
    internalSearch: true,
    teamsSearch: false,
    outlookSearch: false,
    companiesHouseSearch: true,
    filingsSearch: true,
    transcriptsSearch: true,
  },
}
export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setConnectors: (state, action: PayloadAction<SearchConnectors>) => {
      state.connectors = action.payload
    },
  },
})

export const actions = searchSlice.actions
export default searchSlice.reducer
