import { Integration } from "@/types/types"
import { getIntegrationName } from "@/utils/utils"
import { Button } from "../ui/button"
import { TypographyBody } from "../ui/Typography"

export function ResourceFilter({ integrations, selectedFilter, handleFilterChange }: { integrations: Integration[], selectedFilter: string[], handleFilterChange: (filterName: string) => void }) {
    const filters: { name: string, value: string, isSelected: boolean }[] = [
        {
            name: 'Shared',
            value: 'shared',
            isSelected: selectedFilter.includes('shared'),
        },
        {
            name: 'Private',
            value: 'private',
            isSelected: selectedFilter.includes('private'),
        },
        {
            name: 'Desia library',
            value: 'desia library',
            isSelected: selectedFilter.includes('desia library'),
        },
        // {
        //     name: 'Private',
        //     value: 'private',
        //     isSelected: selectedFilter.includes('private'),
        // },
        ...integrations.filter((v) => v.integration_is_process).map((integration) => {
            return {
                name: getIntegrationName(integration.integration_code_name),
                value: integration.integration_code_name,
                isSelected: selectedFilter.includes(integration.integration_code_name),
            }
        }),
        
    ]
    return (
        <div className="flex gap-2 border-b border-system-border-regular pb-4 w-full justify-center">
            <div className="flex flex-wrap gap-2">
                <TypographyBody isStrong={true} className="whitespace-nowrap">
                    Show files from:
                </TypographyBody>

                {filters.map((f) => {
                    return (
                        <Button key={f.value} variant={f.isSelected ? 'tag-selected' : 'tag'} className="whitespace-nowrap h-[1.75rem] !font-body" onClick={() => {
                            handleFilterChange(f.value)
                        }}>
                            {f.name}
                        </Button>
                    )
                })}
            </div>
        </div>
    )
}
