import {
  Citation,
  DocgenCitation,
  DocgenTemplate,
  Section,
  SourceDocument,
  TemplateCategory,
} from '@/types/types'
import { getGlobalUniqueDocuments } from '@/utils/components'
import { getHostname } from '@/utils/utils'

export const findThemePosition = (
  sections: Section[],
  title: string,
  level: number = 0,
  path: number[] = []
): {
  level: number
  indexPath: number[]
} | null => {
  for (let i = 0; i < sections.length; i++) {
    const section = sections[i]
    const currentPath = [...path, i]

    if (section.title === title) {
      return { level, indexPath: currentPath }
    }

    if (section.subsections && section.subsections.length > 0) {
      const result = findThemePosition(
        section.subsections,
        title,
        level + 1,
        currentPath
      )
      if (result) {
        return result
      }
    }
  }

  return null
}

export const addHyphensToSectionText = (template: DocgenTemplate) => {
  const templateWithBulletPoints: DocgenTemplate = {
    ...template,
    style_guidelines: template.style_guidelines?.map((v) => `- ${v}`) || null,
    audience_characteristics:
      template.audience_characteristics?.map((v) => `- ${v}`) || null,
    sections: template.sections?.map((section) => {
      return {
        ...section,
        style_guidelines:
          section.style_guidelines?.map((v) => `- ${v}`) || null,
        semantic_guidelines:
          section.semantic_guidelines?.map((v) => `- ${v}`) || null,
        subsections: section.subsections.map((section) => {
          return {
            ...section,
            style_guidelines:
              section.style_guidelines?.map((v) => `- ${v}`) || null,
            semantic_guidelines:
              section.semantic_guidelines?.map((v) => `- ${v}`) || null,
            subsections: section.subsections.map((section) => {
              return {
                ...section,
                style_guidelines:
                  section.style_guidelines?.map((v) => `- ${v}`) || null,
                semantic_guidelines:
                  section.semantic_guidelines?.map((v) => `- ${v}`) || null,
              }
            }),
          }
        }),
      }
    }),
  }

  return templateWithBulletPoints
}

export const getTemplateColor = (
  templateCategoryId: string | undefined,
  categories: TemplateCategory[]
) => {
  if (!templateCategoryId) return 'blue'
  const index = categories.findIndex((v) => v.id === templateCategoryId)

  if (index === -1) {
    return 'green'
  }

  switch (index % 5) {
    case 0:
      return 'blue'
    case 1:
      return 'teal'
    case 2:
      return 'red'
    case 3:
      return 'orange'
    case 4:
      return 'purple'
  }
}

export const getDocGenSourceDocument = (
  citation: DocgenCitation | null,
  sourceDocuments: SourceDocument[]
) => {
  if (!citation) return null
  const document =
    sourceDocuments.find(
      (v) => v.document_id === citation?.document_id || v.url === citation.url
    ) || null

  if (document) return document

  if (getHostname(citation.document_id)) {
    return {
      document_id: `web_search-${citation.citation_uuid}`,
      title: citation.title,
      url: citation.document_id,
      text: citation.title,
    }
  }

  return null
}

export const getSourceDocumentPlaceholder = (
  citation: DocgenCitation | null
) => {
  if (!citation) return null
  const placeholderDoc: SourceDocument = {
    document_id: citation.url || citation.document_id || '',
    title: citation.title,
    text: citation.title,
    doc_metadata: {
      document_type_friendly: citation.title.split('.').at(-1),
    },
  }

  return placeholderDoc
}

export const getDocGenSourceDocuments = (
  citation: Citation | null,
  sourceDocuments: SourceDocument[]
) => {
  if (!citation) return []

  const documents = getGlobalUniqueDocuments(sourceDocuments, false)
  const internalDocs = documents.filter((v) =>
    citation.document_ids.includes(v.document_id)
  )
  const webDocs = documents.filter((v) =>
    citation.document_ids.includes(v.url || '')
  )

  const combinedDocs = [...internalDocs, ...webDocs]

  if (combinedDocs.length > 0) return combinedDocs

  const webDocuments: SourceDocument[] = []

  citation.document_ids.forEach((v) => {
    if (getHostname(v)) {
      webDocuments.push({
        document_id: `web_search-${citation.citation_uuid}`,
        title: citation.text,
        url: v,
        text: citation.text,
      })
    }
  })

  return getGlobalUniqueDocuments(webDocuments)
}

export const getSourceDocumentPlaceholders = (citation: Citation | null) => {
  if (!citation) return []
  const placeholderDocs: SourceDocument[] = citation.document_ids.map((v) => {
    return {
      document_id: v,
      title: citation.text,
      text: citation.text,
      doc_metadata: {
        document_type_friendly: citation.text.split('.').at(-1),
      },
    }
  })

  return placeholderDocs
}
