import { FinalAnswer } from "@/components/Assistant/FinalAnswer";
import { PreviewSources } from "@/components/Assistant/PreviewSources";
import { ErrorMessage } from "@/components/ErrorMessage";
import { TypographyBody } from "@/components/ui/Typography";
import { RootState } from "@/store/store";
import { WebSocketRequestWrapper, RequestAssistantAsk, Citation, SourceDocument, ResponseEntityExtraction } from "@/types/types";
import { getGlobalUniqueDocuments, filterDocumentsByCited } from "@/utils/components";
import { checkUserFlag, getTimestamp } from "@/utils/utils";
import { RotateCw, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import shortid from "shortid";
import { clearFactCheck, requestFactCheck, requestFactCheckV2, setToolComponent } from "../docGenSlice";
import { Button } from "@/components/ui/button";
import { ActionMenu } from "@/components/ActionMenu";
import { OutputData } from "@editorjs/editorjs";
import { convertReportToString } from "@/utils/docgen";

export function FactCheck({ data, extractedEntities, addToDocumentInline }: {
  data: OutputData,
  extractedEntities?: ResponseEntityExtraction,
  addToDocumentInline: (partialSystemMessage: { text: string, citations: Citation[], documents: SourceDocument[] }, replaceHighlight: boolean) => void
}
) {
  const dispatch = useDispatch();

  const factCheck = useSelector((state: RootState) => state.docGen.factCheck);
  const message = useSelector((state: RootState) => state.docGen.selectedText);
  const [regenCounter, setRegenCounter] = useState(0);
  const partialSystemMessage = useSelector((state: RootState) => state.docGen.factCheck.data?.response);

  const isLoading = factCheck.status === 'loading' && !factCheck.data?.response.text;
  const error = factCheck.status === 'error';
  const isComplete = factCheck.status === 'success';
  const filteredDocuments = getGlobalUniqueDocuments(filterDocumentsByCited(partialSystemMessage?.documents || [], partialSystemMessage?.citations || [], true));
  const reportContent = convertReportToString(data);
  const hasFactCheckV2 = checkUserFlag("docgen: factCheck primary entity context");
  const hasFactCheckRegen = checkUserFlag("docgen: factCheck regen answer");

  useEffect(() => {
    if ((factCheck.status !== 'loading') && message) {
      if (hasFactCheckV2) {
        let messageWithPrimaryEntity = message;
        if (extractedEntities) {
          const mainEntity = extractedEntities.entities.find(e => e.isMainEntity === true);
          if (mainEntity) {
            messageWithPrimaryEntity = `${message} ${regenCounter ? `(regenerate your answer again please, attempt #${regenCounter})`:""} ${JSON.stringify(mainEntity)}`;
          }
        }
        const request: WebSocketRequestWrapper<RequestAssistantAsk> = {
          requestId: shortid(),
          timestamp: getTimestamp(),
          params: {
            message: messageWithPrimaryEntity,
          }
        }
        dispatch(requestFactCheckV2(request));
        return;
      }


      const request: WebSocketRequestWrapper<RequestAssistantAsk> = {
        requestId: shortid(),
        timestamp: getTimestamp(),
        params: {
          message,
          context: reportContent,
        }
      }
      dispatch(requestFactCheck(request));
    }
  }, [message, regenCounter])

  return (
    <div className="flex flex-col gap-2 flex-grow overflow-y-hidden">
      <div className="w-full p-4 flex flex-col gap-4 bg-system-surface border border-system-border-light rounded-lg max-h-[calc(100vh-5rem-2rem-3rem-8rem)]">
        <div className="flex justify-between items-center">
          <TypographyBody isStrong={true}>FactCheck</TypographyBody>
          <div className="flex flex-row justify-between items-center gap-1">
            {hasFactCheckRegen && (
              <Button disabled={!isComplete} variant={"tertiary"}>
                <RotateCw className={`h-6 w-6 stroke-[1.5px] ${isLoading ? "animate-spin" : ""}`} onClick={() => {
                  setRegenCounter(prev => prev + 1);
                }} />
              </Button>
            )}
            <Button variant={"tertiary"} onClick={() => {
              dispatch(setToolComponent(null));
              dispatch(clearFactCheck());
            }}>
              <X className="h-6 w-6 stroke-[1.5px]" />
            </Button>
          </div>
        </div>
        {(!isLoading && error) ? (
          <div className="py-2">
            <ErrorMessage message={"We failed to run FactCheck, try again later"} />
          </div>
        ) : (
          <>
            {filteredDocuments.length > 0 && (
              <PreviewSources documents={filteredDocuments} maxIcons={3} compact={true} loading={isLoading} sourceType='report' />
            )}

            <div className="overflow-y-auto">
              <FinalAnswer
                isLoading={isLoading}
                isComplete={isComplete}
                text={factCheck.data?.response.text || ""}
                citations={factCheck.data?.response.citations || []}
                documents={filteredDocuments}
                compact={true}
              />
            </div>
          </>
        )}
      </div>
      <ActionMenu
        className={`ml-auto ${isComplete ? 'opacity-1' : 'opacity-0'}`}
        buttons={[
          {
            variant: "tertiary",
            children: 'Add below highlight',
            onClick: () => {
              if (partialSystemMessage) {
                addToDocumentInline(partialSystemMessage, false);
                dispatch(setToolComponent(null));
                dispatch(clearFactCheck());
              }
            },
            disabled: !isComplete
          },
          {
            variant: "tertiary",
            children: 'Replace highlight',
            onClick: () => {
              if (partialSystemMessage) {
                addToDocumentInline(partialSystemMessage, true);
                dispatch(setToolComponent(null));
                dispatch(clearFactCheck());
              }
            },
            disabled: !isComplete
          }
        ]}
      />
    </div>
  )
}
