import { Loader2 } from 'lucide-react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogBody,
} from '../ui/dialog'
import { TypographyBody } from '../ui/Typography'

export function DocGenCustomizationSaveDialog({ open }: { open: boolean }) {
  return (
    <Dialog open={open}>
      <DialogContent className="w-fit rounded-lg">
        <DialogHeader className="!text-center hidden">
          <DialogTitle asChild className="hidden">
            <span>Saving template changes</span>
          </DialogTitle>
        </DialogHeader>
        <DialogBody className="flex flex-col gap-4 items-center justify-center">
          <div className="flex gap-4 items-start">
            <Loader2 className="size-6 shrink-0 stroke-[1.5px] animate-spin mt-0.5" />
            <div className="flex flex-col gap-1">
              <TypographyBody isStrong>Saving template changes</TypographyBody>
            </div>
          </div>
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}
