import { Button } from '../ui/button'
import { Sparkles } from 'lucide-react'
import { TypographyBody, TypographyLabel } from '../ui/Typography'
import ArrowRight from '@/assets/ArrowRight'

const FollowUpQuestions = ({
  compact,
  questions,
  onFollowUpQuestionClick,
  isFinished,
}: {
  compact?: boolean
  questions: string[]
  onFollowUpQuestionClick: (question: string) => void
  isFinished?: boolean
}) => {
  return (
    <div
      className={`flex flex-col !border-system-border-light gap-6 rounded-lg ${compact ? 'mt-4 mb-3 border-none' : 'mt-4 mb-3 px-4 py-3 border-[1px] bg-system-surface'}`}
    >
      <div className="flex gap-2 items-center">
        <Sparkles className="w-6 h-6 shrink-0 stroke-[1px] stroke-system-body" />

        <TypographyLabel className="text-system-body">
          Suggestions
        </TypographyLabel>
      </div>
      <div className={`flex flex-col gap-2`}>
        {[...questions].splice(0, 3).map((question, index) => (
          <div key={`follow-up-question-${index}`}>
            {index != questions.length && index !== 0 && (
              <div className="w-full h-[1px] bg-system-border-light mb-2"></div>
            )}

            <Button
              variant="tertiary"
              size="fit"
              className="flex gap-2 cursor-pointer w-full"
              onClick={() => onFollowUpQuestionClick(question)}
              disabled={!isFinished}
            >
              <TypographyBody
                isStrong={true}
                className="text-system-primary whitespace-pre-wrap text-left text-pretty"
              >
                {question}
              </TypographyBody>

              <ArrowRight className="w-6 h-6 ml-auto shrink-0" />
            </Button>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FollowUpQuestions
