import { getPlaybookCardIcon } from '@/utils/components'
import { TypographyBody, TypographyLabel } from '../ui/Typography'
import { Button } from '../ui/button'
import InfoCircle from '@/assets/InfoCircle'
import { CustomTooltip } from '../CustomTooltip'
import { Playbook } from '@/types/types'
import { ChevronLeft } from 'lucide-react'

export const PlaybookOptionHeader = ({
  playbook,
  onBack,
}: {
  playbook?: Playbook
  onBack?: () => void
}) => {
  if (!playbook) {
    return (
      <div className="flex gap-2 items-center w-full pr-2">
        {getPlaybookCardIcon('')}

        <TypographyBody isStrong className="text-system-primary">
          New Playbook prompt
        </TypographyBody>
      </div>
    )
  }

  return (
    <div className="flex gap-2 items-center w-full pr-2">
      {getPlaybookCardIcon(playbook?.items.at(0)?.category || '')}

      <TypographyBody isStrong className="text-system-primary">
        {playbook?.title || ''}
      </TypographyBody>

      <Button
        variant="tertiary"
        data-tooltip-id={`playbook-card-${playbook?.items.at(0)?.id || ''}-tooltip`}
        data-tooltip-place="top"
        className="size-4"
      >
        <InfoCircle className="size-4 shrink-0 stroke-[1.5px] !stroke-system-placeholder" />
      </Button>

      <CustomTooltip
        id={`playbook-card-${playbook?.items.at(0)?.id || ''}-tooltip`}
        className="!py-1 !px-3 !rounded-sm"
        largeArrow={false}
      >
        <TypographyLabel className="text-system-body whitespace-pre-wrap max-w-[20rem] text-center">
          {playbook?.items.at(0)?.tooltip || ''}
        </TypographyLabel>
      </CustomTooltip>

      {onBack && (
        <Button
          variant="tertiary"
          className="ml-auto"
          onClick={() => {
            onBack()
          }}
        >
          <div className="flex gap-2 items-center">
            <ChevronLeft className="size-6 shrink-0 stroke-interactive" />
            <TypographyBody isStrong>Change playbook</TypographyBody>
          </div>
        </Button>
      )}
    </div>
  )
}
