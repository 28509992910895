import { useEffect, useState } from 'react'
import {
  ASYNC_STATUS,
  DatabaseConnector,
  IntegrationCode,
  PlaybookConnectors,
  UserIntegration,
} from '../../types/types'
import { checkUserFlag, getIntegrationName, plural } from '@/utils/utils'
import { OptionDropdownMenuItem } from '../ui/option-dropdown-menu'
import { Database, Globe, LibraryBig, MessageCircleMore, X } from 'lucide-react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import { StateChip } from '../StateChip'
import { Checkbox } from '../ui/checkbox'
import { getDatabaseIcon, getIntegrationIcon } from '@/utils/components'
import { TypographyBody, TypographyLabel } from '../ui/Typography'
import { fetchUserIntegrations } from '../Integration/integrationThunk'
import { Button } from '../ui/button'
import { CustomTooltip } from '../CustomTooltip'
import InfoCircle from '@/assets/InfoCircle'

const getDatabaseDropdownText = (settings: PlaybookConnectors) => {
  const selectedDatabaseSettings = [
    settings.companiesHouseSearch,
    settings.filingsSearch,
    settings.transcriptsSearch,
    settings.financialDataSearch,
  ].filter((v) => v)

  if (selectedDatabaseSettings.length === 4) {
    return 'Databases'
  }

  if (selectedDatabaseSettings.length > 0) {
    return `${selectedDatabaseSettings.length} ${plural('source', selectedDatabaseSettings.length)}`
  }

  return 'Databases'
}

export const PlaybookSourceSelector = ({
  connectors,
  setConnectors,
}: {
  connectors: PlaybookConnectors
  setConnectors: (connectors: PlaybookConnectors) => void
}) => {
  const userIntegrations = useSelector(
    (state: RootState) => state.integration.userIntegrations
  )

  const dispatch = useDispatch<AppDispatch>()

  const [showCommunicationDropdown, setShowCommunicationDropdown] =
    useState(false)

  const personalIntegrations = userIntegrations.data?.filter(
    (v) => v.user_level_integration && v.integration_enabled_by_user
  )

  const isInternalSearchSelected = connectors.internalSearch
  const isCommunicationSelected =
    connectors.outlookSearch || connectors.teamsSearch
  const allCommunicationSelected =
    connectors.outlookSearch && connectors.teamsSearch
  const isCompaniesHouseSelected = connectors.companiesHouseSearch
  const isFilingsSelected = connectors.filingsSearch
  const isTranscriptsSelected = connectors.transcriptsSearch
  const isFinancialDataSelected = connectors.financialDataSearch
  const isDatabaseSelected =
    isCompaniesHouseSelected || isFilingsSelected || isTranscriptsSelected || isFinancialDataSelected
  const isWebSelected = connectors.webSearch
  

  const forceShowIntegrationsConnectors = checkUserFlag(
    'debug: force show integration connectors'
  )

  const getIntegrationDescriptionText = (integration: UserIntegration) => {
    switch (integration.integration_code_name) {
      case IntegrationCode.OUTLOOK:
        return 'Emails including attachment files'
      case IntegrationCode.TEAMS:
        return 'Messages and call transcripts'
    }
  }

  const toggleCommunicationConnector = () => {
    const isTeamsEnabled = personalIntegrations?.some(
      (v) => v.integration_code_name === IntegrationCode.TEAMS
    )
    const isOutlookEnabled = personalIntegrations?.some(
      (v) => v.integration_code_name === IntegrationCode.OUTLOOK
    )
    setConnectors({
      ...connectors,
      teamsSearch: isTeamsEnabled ? !isCommunicationSelected : false,
      outlookSearch: isOutlookEnabled ? !isCommunicationSelected : false,
    })
  }

  const toggleLibraryConnector = () => {
    setConnectors({
      ...connectors,
      internalSearch: !isInternalSearchSelected,
    })
  }

  const toggleWebConnector = () => {
    setConnectors({
      ...connectors,
      webSearch: !isWebSelected,
    })
  }

  const toggleCompaniesHouseConnector = () => {
    setConnectors({
      ...connectors,
      companiesHouseSearch: !isCompaniesHouseSelected,
    })
  }

  const toggleFinancialDataConnector = () => {
    setConnectors({
      ...connectors,
      financialDataSearch: !isFinancialDataSelected,
    })
  }

  const toggleFilingsConnector = () => {
    setConnectors({
      ...connectors,
      filingsSearch: !isFilingsSelected,
    })
  }

  const toggleTranscriptsConnector = () => {
    setConnectors({
      ...connectors,
      transcriptsSearch: !isTranscriptsSelected,
    })
  }

  const toggleDatabaseConnector = () => {
    setConnectors({
      ...connectors,
      companiesHouseSearch: !isDatabaseSelected,
      filingsSearch: !isDatabaseSelected,
      transcriptsSearch: !isDatabaseSelected,
    })
  }

  useEffect(() => {
    if (userIntegrations.status === ASYNC_STATUS.idle) {
      dispatch(fetchUserIntegrations())
    }
  }, [dispatch, userIntegrations.status])

  return (
    <>
      <div
        className="flex gap-6 items-center"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-wrap gap-3">
          <StateChip
            label={'Library'}
            active={isInternalSearchSelected}
            leftIcon={<LibraryBig className="size-5 shrink-0 stroke-[1.5px]" />}
            onClick={toggleLibraryConnector}
          />

          {((personalIntegrations?.length || 0) > 0 ||
            forceShowIntegrationsConnectors) && (
            <StateChip
              label={
                isCommunicationSelected && !allCommunicationSelected
                  ? '1 source'
                  : 'Communications'
              }
              active={isCommunicationSelected}
              leftIcon={
                <MessageCircleMore className="size-5 shrink-0 stroke-[1.5px]" />
              }
              onClick={toggleCommunicationConnector}
              openDropdown={showCommunicationDropdown}
              onDropdownOpen={(v) => setShowCommunicationDropdown(v)}
              dropdownContent={
                <>
                  <div
                    className="relative"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="flex flex-col gap-0 py-1">
                      {personalIntegrations?.map((integration, index) => {
                        return (
                          <OptionDropdownMenuItem
                            selectable={false}
                            key={`integration-dropdown-item-${index}`}
                            className="[&>div]:hover:!bg-transparent [&>div]:hover:!border-transparent"
                          >
                            <div className="flex gap-2 py-2 items-start px-2 min-w-[17.5rem]">
                              <Checkbox
                                onClick={(e) => e.stopPropagation()}
                                checked={
                                  integration.integration_code_name ===
                                  IntegrationCode.TEAMS
                                    ? connectors.teamsSearch
                                    : connectors.outlookSearch
                                }
                                onCheckedChange={() => {
                                  if (
                                    integration.integration_code_name ===
                                    IntegrationCode.TEAMS
                                  ) {
                                    setConnectors({
                                      ...connectors,
                                      teamsSearch: !connectors.teamsSearch,
                                    })
                                  } else if (
                                    integration.integration_code_name ===
                                    IntegrationCode.OUTLOOK
                                  ) {
                                    setConnectors({
                                      ...connectors,
                                      outlookSearch: !connectors.outlookSearch,
                                    })
                                  }
                                }}
                              />

                              <div className="flex flex-col gap-1 items-center">
                                <div className="flex gap-2 items-center w-full">
                                  <div className="flex items-center justify-center size-6 shrink-0 border-[1.5px] border-system-border-light rounded-sm">
                                    {getIntegrationIcon(
                                      integration.integration_code_name,
                                      true,
                                      'w-4 shrink-0'
                                    )}
                                  </div>

                                  <TypographyBody>
                                    {getIntegrationName(
                                      integration.integration_code_name
                                    )}
                                  </TypographyBody>
                                </div>

                                <TypographyLabel className="text-system-body">
                                  {getIntegrationDescriptionText(integration)}
                                </TypographyLabel>
                              </div>
                            </div>
                          </OptionDropdownMenuItem>
                        )
                      })}
                    </div>
                    <div className="absolute right-0 top-1">
                      <Button
                        variant="tertiary"
                        onClick={() => setShowCommunicationDropdown(false)}
                      >
                        <X className="size-6 shrink-0 stroke-[1.5px]" />
                      </Button>
                    </div>
                  </div>
                </>
              }
            />
          )}

          <StateChip
            label={getDatabaseDropdownText(connectors)}
            active={isDatabaseSelected}
            leftIcon={<Database className="size-5 shrink-0 stroke-[1.5px]" />}
            onClick={toggleDatabaseConnector}
            dropdownContent={
              <>
                <div className="flex flex-col gap-0 px-2">
                  <OptionDropdownMenuItem
                    selectable={false}
                    className="[&>div]:hover:!bg-transparent [&>div]:hover:!border-transparent"
                  >
                    <div className="flex gap-2 py-2 items-center min-w-[17.5rem]">
                      <Checkbox
                        onClick={(e) => e.stopPropagation()}
                        checked={isFinancialDataSelected}
                        onCheckedChange={toggleFinancialDataConnector}
                      />

                      {getDatabaseIcon(
                        DatabaseConnector.FINANCIAL_DATA,
                        '!size-6 shrink-0 rounded-sm'
                      )}
                      <TypographyBody className="w-full">
                        Financial data
                      </TypographyBody>
                      <Button
                        variant="tertiary"
                        data-tooltip-id="financial-data-tooltip"
                        data-tooltip-place="right"
                      >
                        <InfoCircle className="size-4 shrink-0 stroke-[1.5px] !stroke-system-placeholder" />
                      </Button>
                    </div>
                  </OptionDropdownMenuItem>

                  <OptionDropdownMenuItem
                    selectable={false}
                    className="[&>div]:hover:!bg-transparent [&>div]:hover:!border-transparent"
                  >
                    <div className="flex gap-2 py-2 items-center min-w-[17.5rem]">
                      <Checkbox
                        onClick={(e) => e.stopPropagation()}
                        checked={isCompaniesHouseSelected}
                        onCheckedChange={toggleCompaniesHouseConnector}
                      />

                      {getDatabaseIcon(
                        DatabaseConnector.COMPANIES_HOUSE,
                        '!size-6 shrink-0 rounded-sm'
                      )}

                      <TypographyBody className="w-full">
                        Companies House UK
                      </TypographyBody>

                      <Button
                        variant="tertiary"
                        data-tooltip-id="companies-house-tooltip"
                        data-tooltip-place="right"
                      >
                        <InfoCircle className="size-4 shrink-0 stroke-[1.5px] !stroke-system-placeholder" />
                      </Button>
                    </div>
                  </OptionDropdownMenuItem>

                  <OptionDropdownMenuItem
                    selectable={false}
                    className="[&>div]:hover:!bg-transparent [&>div]:hover:!border-transparent"
                  >
                    <div className="flex gap-2 py-2 items-center min-w-[17.5rem]">
                      <Checkbox
                        onClick={(e) => e.stopPropagation()}
                        checked={isFilingsSelected}
                        onCheckedChange={toggleFilingsConnector}
                      />

                      {getDatabaseIcon(
                        DatabaseConnector.FILINGS,
                        '!size-6 shrink-0 rounded-sm'
                      )}

                      <TypographyBody className="w-full">
                        Global companies filings
                      </TypographyBody>

                      <Button
                        variant="tertiary"
                        data-tooltip-id="filings-tooltip"
                        data-tooltip-place="right"
                      >
                        <InfoCircle className="size-4 shrink-0 stroke-[1.5px] !stroke-system-placeholder" />
                      </Button>
                    </div>
                  </OptionDropdownMenuItem>

                  <OptionDropdownMenuItem
                    selectable={false}
                    className="[&>div]:hover:!bg-transparent [&>div]:hover:!border-transparent"
                  >
                    <div className="flex gap-2 py-2 items-center min-w-[17.5rem]">
                      <Checkbox
                        onClick={(e) => e.stopPropagation()}
                        checked={isTranscriptsSelected}
                        onCheckedChange={toggleTranscriptsConnector}
                      />

                      {getDatabaseIcon(
                        DatabaseConnector.TRANSCRIPTS,
                        '!size-6 shrink-0 rounded-sm'
                      )}

                      <TypographyBody className="w-full">
                        Earning call transcripts
                      </TypographyBody>

                      <Button
                        variant="tertiary"
                        data-tooltip-id="transcripts-tooltip"
                        data-tooltip-place="right"
                      >
                        <InfoCircle className="size-4 shrink-0 stroke-[1.5px] !stroke-system-placeholder" />
                      </Button>
                    </div>
                  </OptionDropdownMenuItem>

                  <CustomTooltip
                    id="financial-data-tooltip"
                    className="!py-1 !px-3 !rounded-sm"
                    largeArrow={false}
                  >
                    <TypographyLabel className="text-system-body whitespace-pre-wrap">
                      {`Financialmodelingprep.com provides financial data on\npublic companies`}
                    </TypographyLabel>
                  </CustomTooltip>

                  <CustomTooltip
                    id="companies-house-tooltip"
                    className="!py-1 !px-3 !rounded-sm"
                    largeArrow={false}
                  >
                    <TypographyLabel className="text-system-body whitespace-pre-wrap">
                      {`UK's official business registry containing mandatory\ncompany filings, accounts and ownership details`}
                    </TypographyLabel>
                  </CustomTooltip>

                  <CustomTooltip
                    id="filings-tooltip"
                    className="!py-1 !px-3 !rounded-sm"
                    largeArrow={false}
                  >
                    <TypographyLabel className="text-system-body whitespace-pre-wrap">
                      Collection of filings from public companies worldwide.
                    </TypographyLabel>
                  </CustomTooltip>

                  <CustomTooltip
                    id="transcripts-tooltip"
                    className="!py-1 !px-3 !rounded-sm"
                    largeArrow={false}
                  >
                    <TypographyLabel className="text-system-body whitespace-pre-wrap">
                      Quarterly earnings presentations from public companies.
                    </TypographyLabel>
                  </CustomTooltip>
                </div>
              </>
            }
          />

          <StateChip
            label={'Web'}
            active={isWebSelected}
            leftIcon={<Globe className="size-5 shrink-0 stroke-[1.5px]" />}
            onClick={toggleWebConnector}
          />
        </div>
      </div>
    </>
  )
}
