import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table'
import { Suspense } from 'react'
import { DedupedSearchQueryItem, SourceDocumentType } from '@/types/types'
import {
  checkSourceDocumentType,
  parseFilingDate,
} from '@/utils/utils'
import { getDatabaseIcon } from '@/utils/components'
import { TypographyBody } from './ui/Typography'
import { Checkbox } from './ui/checkbox'
import { SourceIcon } from './Source'

export interface FileTableProps {
  files: DedupedSearchQueryItem[]
  onCheckedChange: (file: DedupedSearchQueryItem) => void
}

export const SearchFileTable = ({ files, onCheckedChange }: FileTableProps) => {
  return (
    <>
      <Table>
        <TableBody className="[&_tr:first-child]:border-t">
          {files.map((file) => {
            const sourceType = checkSourceDocumentType(
              file.id,
              file.doc_metadata
            )

            return (
              <Suspense key={`suspense-${file.id}`}>
                <TableRow className="transition-all h-[2.875rem]" tabIndex={0}>
                  <TableCell className="font-body text-system-body !py-2 shrink-0 w-[2.875rem]">
                    <Checkbox
                      checked={true}
                      onCheckedChange={() => onCheckedChange(file)}
                    />
                  </TableCell>

                  <TableCell
                    className={`font-body text-system-body hover:underline hover:cursor-pointer !max-w-[30rem] !overflow-visible !py-2 shrink-0`}
                  >
                    <div className="flex gap-2 items-center">
                      <SourceIcon
                        id={file.id}
                        url={file.url}
                        title={file.title}
                        metadata={file.doc_metadata}
                      />

                      {sourceType === SourceDocumentType.FILINGS && (
                        <TypographyBody className="text-system-primary line-clamp-1 break-all">
                          {`${file.doc_metadata?.filing_category} for FY ended ${parseFilingDate(file.doc_metadata?.filing_for_date || '')}`}
                        </TypographyBody>
                      )}

                      {sourceType === SourceDocumentType.TRANSCRIPTS && (
                        <TypographyBody className="text-system-primary line-clamp-1 break-all">
                          {`${file.title}, ${file.doc_metadata?.element_created_at || ''} Earnings Call ${parseFilingDate(file.doc_metadata?.filing_for_date || '')}`}
                        </TypographyBody>
                      )}

                      {sourceType === SourceDocumentType.COMPANIES_HOUSE && (
                        <TypographyBody className="text-system-primary line-clamp-1 break-all">
                          {`Full accounts made up to ${parseFilingDate(file.doc_metadata?.filing_for_date || '')}`}
                        </TypographyBody>
                      )}
                    </div>
                  </TableCell>

                  <TableCell
                    className={`font-body text-system-body w-[15%] whitespace-nowrap shrink-0`}
                  >
                    <div className="flex gap-2 items-center">
                      {getDatabaseIcon(
                        sourceType,
                        `!size-5 shrink-0 rounded-[2px] p-0.5 ${sourceType === SourceDocumentType.COMPANIES_HOUSE ? 'bg-black' : ''}`
                      )}
                      {sourceType === SourceDocumentType.COMPANIES_HOUSE &&
                        'Companies House'}
                      {sourceType === SourceDocumentType.FILINGS && 'Filings'}
                      {sourceType === SourceDocumentType.TRANSCRIPTS &&
                        'Transcripts'}
                    </div>
                  </TableCell>
                  <TableCell
                    className={`font-body text-system-body w-[15%] whitespace-nowrap shrink-0`}
                  >
                    {parseFilingDate(file.doc_metadata?.filing_date || file.document_created_at_source || '')}
                  </TableCell>
                </TableRow>
              </Suspense>
            )
          })}
        </TableBody>
      </Table>
    </>
  )
}
