import { AppDispatch, RootState } from '@/store/store'
import { DocgenTemplate, QueryStatus } from '@/types/types'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { fetchTemplateCategories, fetchTemplates } from './docGenThunk'
import { Button } from '../ui/button'
import { ArrowLeft, Loader2 } from 'lucide-react'
import { TypographyBody, TypographyH4 } from '../ui/Typography'
import { DocGenTemplateForm } from './DocGenTemplateForm'
import { CustomAlert } from '../CustomAlert'
import { LocalStorageKey } from '@/constants'
import { getFileIcon } from '@/utils/components'

export const TemplateDetailPage = () => {
  const { template_id } = useParams()

  const templates = useSelector((state: RootState) => state.docGen.templates)
  const templateCategories = useSelector(
    (state: RootState) => state.docGen.templateCategories
  )
  const templateListStatus = useSelector(
    (state: RootState) => state.docGen.templateListStatus
  )

  const createTemplateStatus = useSelector(
    (state: RootState) => state.docGen.createTemplateStatus
  )

  const updateTemplateStatus = useSelector(
    (state: RootState) => state.docGen.updateTemplateStatus
  )

  const documents = useSelector((state: RootState) => state.document.files)

  const [template, setTemplate] = useState<DocgenTemplate | null>(null)
  const [showFloatingButton, setShowFloatingButton] = useState(false)

  const editable = template?.template_type !== 'system'
  const fileId =
    template?.file_id ||
    JSON.parse(localStorage.getItem(LocalStorageKey.TEMPLATE_FILE_IDS) || '{}')[
      template?.id || ''
    ]
  const templateFile = documents.find((v) => v.document_id === fileId)

  const formRef = useRef<HTMLFormElement>(null)

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const handleSubmit = () => {
    formRef.current?.requestSubmit()
  }

  const FormSubmitButton = () => {
    return (
      <>
        <div className="flex gap-2 mx-auto">
          <Button variant="secondary" onClick={() => navigate(-1)}>
            <div className="flex gap-2 items-center">Discard</div>
          </Button>

          <Button onClick={handleSubmit}>
            <div className="flex gap-2 items-center">
              {template_id && !template?.file_id
                ? 'Save changes'
                : `Create template`}
              {(createTemplateStatus === QueryStatus.FETCHING ||
                (template &&
                  updateTemplateStatus[template?.id] ===
                    QueryStatus.FETCHING)) && (
                <Loader2 className="size-6 shrink-0 stroke-interactive animate-spin" />
              )}
            </div>
          </Button>
        </div>
      </>
    )
  }

  useEffect(() => {
    if (templateListStatus === QueryStatus.INITIALISED) {
      dispatch(fetchTemplates())
    }
  }, [templateListStatus, dispatch])

  useEffect(() => {
    if (templateCategories.status === QueryStatus.INITIALISED) {
      dispatch(fetchTemplateCategories())
    }
  }, [templateCategories.status, dispatch])

  useEffect(() => {
    setTemplate(templates.find((v) => v.id === template_id) || null)
  }, [template_id, templates])

  useEffect(() => {
    const handleScroll = () => {
      const mainContainer = document.querySelector('.main-container-new')

      const scrolledDown =
        (mainContainer?.scrollHeight || 0) -
          (mainContainer?.scrollTop || 0) -
          96 <
        (mainContainer?.clientHeight || 0)
      setShowFloatingButton(!scrolledDown)
    }

    handleScroll()

    window.addEventListener('scroll', handleScroll, { capture: true })

    return () => {
      window.removeEventListener('scroll', handleScroll, { capture: true })
    }
  }, [templates])

  return (
    <>
      <Link to={`/templates`}>
        <Button variant="tertiary" className="absolute left-[7.5rem] top-[5.25rem]">
          <div className="flex gap-2 items-center">
            <ArrowLeft className="size-6 shrink-0 stroke-interactive" />
            DocGen templates
          </div>
        </Button>
      </Link>
      <div className="flex flex-col gap-10 mx-auto w-full">
        <div className="flex flex-col gap-1">
          {template?.template_type === 'system' && (
            <div className="flex gap-2 items-center mx-auto">
              <img
                className="size-4"
                src="https://cdn.prod.website-files.com/66bccd21862b191477dc8806/66bce0be1491cc6deb168141_symbol.svg"
              />

              <TypographyBody className="text-system-body">
                Desia template
              </TypographyBody>
            </div>
          )}

          <div className="flex gap-3 items-center w-fit mx-auto max-w-[47.625rem]">
            <TypographyH4 className="text-system-primary text-center">
              {template_id
                ? template?.file_id
                  ? `New template from`
                  : template?.title
                : 'New template'}
            </TypographyH4>

            {templateFile && (
              <div className="flex gap-3 items-center bg-system-secondary rounded-lg border border-system-border-light p-2">
                {getFileIcon(
                  templateFile?.document_type_friendly || '',
                  'min-w-7 min-h-7 shrink-0'
                )}

                <TypographyBody className="text-system-body">
                  {templateFile?.document_name || ''}
                </TypographyBody>
              </div>
            )}
          </div>
        </div>

        {template?.template_type === 'system' && (
          <div className="max-w-[47.625rem] mx-auto w-full">
            <CustomAlert
              variant="info"
              title="Desia templates cannot be edited"
              description="To customize this template, select it when creating a new report and adjust its guidelines"
            />
          </div>
        )}

        <div className="max-w-[47.625rem] mx-auto w-full">
          <DocGenTemplateForm
            selectedTemplate={template}
            templates={templates}
            onSave={() => {
              navigate('/templates')
            }}
            formRef={formRef}
            disabled={!editable}
          />
        </div>
        {editable && <FormSubmitButton />}
      </div>

      {showFloatingButton && editable && (
        <div className="fixed flex flex-col gap-4 h-fit w-fit p-4 bg-system-surface rounded-lg border border-system-border-regular shadow-outline-dialog bottom-10 left-[50%] -translate-x-[50%]">
          <FormSubmitButton />
        </div>
      )}

      {template_id && (
        <div className="fixed right-4 top-[4.25rem] flex flex-col gap-3 p-4 bg-system-surface border border-system-border-regular items-center rounded-lg max-w-[calc((100%-47.625rem)/2-1.5rem)] monitor:max-w-[31.5rem]">
          <TypographyBody isStrong className="text-system-primary w-full">
            Template customization
          </TypographyBody>
          <TypographyBody className="text-system-body whitespace-pre-wrap text-pretty">
            {`Templates should be generic to the type of report. When generating a draft, you will be able to customize all fields in case\nyou need refined specifications for particular reports.`}
          </TypographyBody>
        </div>
      )}
    </>
  )
}
