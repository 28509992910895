import { ArrowLeft, Plus } from 'lucide-react'
import { Button } from '../ui/button'
import { TypographyH3 } from '../ui/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import { useState } from 'react'
import { DocumentSelectorDialogType } from '@/types/types'
import { Input } from '../ui/input'
import { Link, useNavigate } from 'react-router-dom'
import DocGenHomeImage from '../../assets/img-home-doc-gen.svg'
import { DocGenCreateCategoryDialog } from './DocGenCreateCategoryDialog'
import { createTemplateFromDocument } from './docGenThunk'
import { DocGenCreateTemplateDialog } from './DocGenCreateTemplateDialog'
import { DocGenLoadingTemplateDialog } from './DocGenLoadingTemplateDialog'
import DocumentSelectorDialog from '../Assistant/DocumentSelectorDialog'
import { isFulfilled } from '@reduxjs/toolkit'
import { addTemplate } from './docGenSlice'
import { TemplateList } from './TemplateList'

export interface MockReport {
  title: string
  description: string
}

export const TemplatesPage = () => {
  const templates = useSelector((state: RootState) => state.docGen.templates)

  const [searchText, setSearchText] = useState('')
  const [showCreateCategoryDialog, setShowCreateCategoryDialog] =
    useState(false)
  const [showCreateTemplateDialog, setShowCreateTemplateDialog] =
    useState(false)
  const [loadingTemplate, setLoadingTemplate] = useState(false)
  const [showDocumentSelector, setShowDocumentSelector] = useState(false)

  const [generateTemplateError, setGenerateTemplateError] = useState(false)
  const [generateTemplateFileId, setGenerateTemplateFileId] = useState<
    string | null
  >(null)

  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const handleFileSelection = async (id: string) => {
    setGenerateTemplateFileId(id)
    setLoadingTemplate(true)
    setGenerateTemplateError(false)
    const result = await dispatch(createTemplateFromDocument(id))

    if (isFulfilled(createTemplateFromDocument)(result)) {
      const uuid = crypto.randomUUID()
      dispatch(addTemplate({ ...result.payload, id: uuid, file_id: id }))

      navigate(`/template/${uuid}`)
    } else {
      setGenerateTemplateError(true)
    }
    setLoadingTemplate(false)
  }

  const filteredTemplates = templates.filter(template =>
    template.title.toLowerCase().includes(searchText.toLowerCase())
  )

  return (
    <>
      <Link to={'/reports'} className="absolute top-[4.75rem] left-[7.5rem]">
        <Button variant="tertiary">
          <div className="flex gap-2 items-center">
            <ArrowLeft className="size-6 shrink-0 stroke-interactive" />
            Reports
          </div>
        </Button>
      </Link>
      <div className="flex flex-col gap-10 mt-10 sm:mt-0 mx-auto">
        <div className="flex flex-col gap-0">
          <div className="text-center mx-auto">
            <div className="flex items-center h-full mx-auto">
              <img className="w-[15rem]" src={DocGenHomeImage} />
            </div>

            <TypographyH3 className="-translate-y-[100%]">
              Template gallery
            </TypographyH3>
          </div>

          <div className={`flex flex-wrap justify-center gap-2 mx-auto w-full`}>
            <Input
              className="w-[20rem]"
              placeholder="Search by template name"
              isSearch={true}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value)
              }}
              isCloseVisible={!!searchText}
              onCloseClick={() => setSearchText('')}
            />

            <Button onClick={() => setShowCreateTemplateDialog(true)}>
              <div className="flex items-center gap-2">
                <Plus className="size-6 shrink-0 stroke-interactive" />
                Create template
              </div>
            </Button>
          </div>
        </div>

        <div className="flex flex-col gap-8 mx-auto">
          <Button
            variant="secondary"
            onClick={() => setShowCreateCategoryDialog(true)}
            className="w-fit"
          >
            <div className="flex gap-2 items-center">
              <Plus className="size-6 shrink-0 stroke-interactive" />
              Add template category
            </div>
          </Button>

          <div className="flex flex-col gap-6 laptop:w-[77rem] w-full">
            <TemplateList
              templates={filteredTemplates}
              onTemplateClick={(template) =>
                navigate(`/template/${template.id}`)
              }
            />
          </div>
        </div>
      </div>

      <DocGenCreateCategoryDialog
        open={showCreateCategoryDialog}
        setOpen={setShowCreateCategoryDialog}
      />

      <DocGenCreateTemplateDialog
        open={showCreateTemplateDialog}
        setOpen={setShowCreateTemplateDialog}
        onFileSelectClick={() => {
          setShowDocumentSelector(true)
          setShowCreateTemplateDialog(false)
        }}
      />

      <DocumentSelectorDialog
        sourceType={'ask'}
        open={showDocumentSelector}
        setOpen={(v) => {
          setShowDocumentSelector(v)
        }}
        onSave={(files, companiesHouseFiles) => {
          const ids = [...files.map((v) => v.id), ...companiesHouseFiles.map((v) => v.id)]
          if (ids.length > 0) {
            handleFileSelection(ids[0])
          }
          setShowDocumentSelector(false)
        }}
        type={DocumentSelectorDialogType.DOCGEN}
      />

      <DocGenLoadingTemplateDialog
        error={generateTemplateError}
        open={loadingTemplate}
        setOpen={() => { }}
        onRetry={() => {
          if (generateTemplateFileId) {
            handleFileSelection(generateTemplateFileId)
          }
        }}
      />
    </>
  )
}
