import { TemplateSection, TemplateSubsection } from '@/types/types'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { TypographyBody, TypographyH4 } from '../ui/Typography'
import { Button } from '../ui/button'
import { ChevronUp, X } from 'lucide-react'
import { Form, FormControl, FormField, FormItem, FormMessage } from '../ui/form'
import { useEffect, useRef, useState } from 'react'
import { Input } from '../ui/input'
import Checkmark from '@/assets/Checkmark'
import { Checkbox } from '../ui/checkbox'
import { ListInput } from '../ListInput/ListInput'
import ChevronDown from '@/assets/ChevronDown'
import { removeInitialHyphens } from '@/utils/utils'

export const TemplateSectionEditor = ({
  section,
  sections,
  parentSection,
  disabled = false,
  onClose,
  onSave,
}: {
  section: TemplateSection | TemplateSubsection
  sections: (TemplateSection | TemplateSubsection)[]
  parentSection?: TemplateSection | TemplateSubsection
  disabled?: boolean
  onClose: () => void
  onSave: (section: TemplateSection | TemplateSubsection) => void
}) => {
  const [contentTypes, setContentTypes] = useState<string[]>([])
  const [showStyleGuidelines, setShowStyleGuidelines] = useState(false)

  const formSchema = z.object({
    name: z
      .string()
      .min(1, 'Please enter a title')
      .refine(
        (name) =>
          !sections.find((v) => v.title === name && v.title !== section.title),
        () => ({
          message: `This name is already used by another section or subsection. Please enter a different name.`,
        })
      ),
    description: z.string(),
    semantic_guidelines: z.string(),
    style_guidelines: z.string(),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: section.title || '',
      description: section?.description || '',
      semantic_guidelines: removeInitialHyphens(
        section.semantic_guidelines?.join('\n') || ''
      ),
      style_guidelines: removeInitialHyphens(
        section.style_guidelines?.join('\n') || ''
      ),
    },
    disabled: disabled,
  })

  const sectionEdited =
    JSON.stringify(form.getValues()) !==
      JSON.stringify({
        name: section.title || '',
        description: section?.description || '',
        semantic_guidelines: removeInitialHyphens(
          section.semantic_guidelines?.join('\n') || ''
        ),
        style_guidelines: removeInitialHyphens(
          section.style_guidelines?.join('\n') || ''
        ),
      }) || contentTypes !== section.content_types

  const formRef = useRef<HTMLFormElement>(null)

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const updatedSection = {
      ...section,
      title: values.name,
      description: values.description,
      semantic_guidelines: values.semantic_guidelines.split('\n'),
      style_guidelines: values.style_guidelines.split('\n'),
      content_types: Array.from(contentTypes),
    }

    onSave(updatedSection)
  }

  useEffect(() => {
    setContentTypes(section.content_types || ['text'])
  }, [section.content_types])

  useEffect(() => {
    form.reset()
  }, [section, form])

  return (
    <div className="flex flex-col gap-6 bg-system-secondary rounded-[12px]">
      <div className="flex flex-col gap-2">
        <div className="flex gap-2">
          <TypographyH4 className="w-full">
            {`${parentSection ? 'Section guidelines - ' : ''}${section.title || 'New section'}`}
          </TypographyH4>

          <Button variant="tertiary" onClick={onClose}>
            <X className="size-6 shrink-0 stroke-[1.5px]" />
          </Button>
        </div>
        {parentSection && (
          <div className="px-3 py-[2px] bg-system-hover rounded-lg">
            <TypographyBody className="text-system-body">
              subsection of{' '}
              <span className="text-system-primary font-body-strong">
                {parentSection.title}
              </span>
            </TypographyBody>
          </div>
        )}
      </div>
      <Form {...form}>
        <form
          ref={formRef}
          onSubmit={form.handleSubmit(onSubmit)}
          className="space-y-2"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="mx-auto space-y-6 overflow-x-visible ">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem className="space-y-2">
                  <div className="flex flex-col gap-0">
                    <TypographyBody isStrong>Section name</TypographyBody>
                  </div>
                  <FormMessage className="w-fit min-w-[22.5rem]" />
                  <FormControl>
                    <Input
                      {...field}
                      className="w-[22.5rem]"
                      placeholder="e.g. Investment proposal"
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem className="space-y-2">
                  <div className="flex flex-col gap-0">
                    <TypographyBody isStrong>Description</TypographyBody>
                  </div>
                  <FormMessage />
                  <FormControl>
                    <Input
                      {...field}
                      placeholder="e.g. Template for seed round investment memos, focusing on key deal terms, company overview, market opportunity, and investment thesis"
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="semantic_guidelines"
              render={({ field }) => (
                <FormItem className="space-y-2">
                  <div className="flex flex-col gap-0">
                    <TypographyBody isStrong>Content guidelines</TypographyBody>
                  </div>
                  <FormMessage className="w-fit min-w-[22.5rem]" />
                  <FormControl>
                    <ListInput
                      values={field.value.split('\n')}
                      onChange={(v) =>
                        form.setValue('semantic_guidelines', v.join('\n'))
                      }
                      disabled={disabled}
                      placeholder={`e.g. Strategic mindset looking for market opportunity and competitive advantage, Time-constrained, requiring concise yet comprehensive information`}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <div className="flex flex-col gap-2">
              <Button
                variant="tertiary"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setShowStyleGuidelines(!showStyleGuidelines)
                }}
              >
                <div className="flex gap-2 items-center">
                  <TypographyBody isStrong className="text-system-primary">
                    Section style guidelines (Optional)
                  </TypographyBody>
                  {showStyleGuidelines ? (
                    <ChevronUp className="size-6 shrink-0 stroke-[1.5px]" />
                  ) : (
                    <ChevronDown className="size-6 shrink-0 stroke-[1.5px]" />
                  )}
                </div>
              </Button>
              {showStyleGuidelines && (
                <FormField
                  control={form.control}
                  name="style_guidelines"
                  render={({ field }) => (
                    <FormItem className="space-y-2">
                      <FormMessage className="w-fit min-w-[22.5rem]" />
                      <FormControl>
                        <ListInput
                          values={field.value.split('\n')}
                          onChange={(v) =>
                            form.setValue('style_guidelines', v.join('\n'))
                          }
                          disabled={disabled}
                          placeholder={`e.g. Strategic mindset looking for market opportunity and competitive advantage, Time-constrained, requiring concise yet comprehensive information`}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              )}
            </div>
          </div>
        </form>
      </Form>

      <div className="flex gap-6">
        <Checkbox
          label="Use charts"
          checked={contentTypes.includes('chart')}
          onCheckedChange={() => {
            if (contentTypes.includes('chart')) {
              setContentTypes([...contentTypes].filter((v) => v !== 'chart'))
            } else {
              setContentTypes([...contentTypes, 'chart'])
            }
          }}
          disabled={disabled}
        />

        <Checkbox
          label="Use tables"
          checked={contentTypes.includes('table')}
          onCheckedChange={() => {
            if (contentTypes.includes('table')) {
              setContentTypes([...contentTypes].filter((v) => v !== 'table'))
            } else {
              setContentTypes([...contentTypes, 'table'])
            }
          }}
          disabled={disabled}
        />
      </div>

      {sectionEdited && (
        <div className="flex gap-2 items-center ml-auto">
          <Button variant="secondary" onClick={onClose}>
            Discard
          </Button>

          <Button onClick={() => formRef.current?.requestSubmit()}>
            <div className="flex gap-2 items-center">
              <Checkmark />
              Save Changes
            </div>
          </Button>
        </div>
      )}
    </div>
  )
}
