import { useEffect, useState } from 'react'
import { TypographyBody, TypographyH3 } from '../ui/Typography'
import { ResourceFilter } from './ResourceFilter'
import {
  DOCUMENT_STATUS,
  QueryStatus,
  ResourceVisibility,
  UploadableFile,
} from '@/types/types'
import { CustomAlert } from '../CustomAlert'
import { MultiFileUpload } from './MultiFileUpload'
import { AppDispatch, RootState } from '@/store/store'
import { useDispatch, useSelector } from 'react-redux'
import { fetchIntegrations } from '../Integration/integrationSlice'
import { FileTable } from '../Integration/FileTable'
import { transformResponseDocuments } from '@/utils/transformResponseDocuments'
import { ProcessingFiles } from './ProcessingFiles'
import { sortDocumentsFn } from '../Document/utils'
import { listDocuments } from '../../components/Document/documentThunk'
import { useSearchParams } from 'react-router-dom'
import { actions as documentActions } from '@/components/Document/documentSlice'
import { LOCAL_STORAGE_FILES_KEY } from '@/constants'

export function ResourcesPage() {
  const [searchParams] = useSearchParams()

  const dispatch = useDispatch<AppDispatch>()
  const {
    fetchStatus,
    files: resources,
    uploadStatus,
    processingFiles,
  } = useSelector((state: RootState) => state.document)
  const integrations = useSelector(
    (state: RootState) => state.integration.integrations
  )

  const [resourceFilter, setResourceFilter] = useState<string[]>([
    'shared',
    'private',
    'desia library',
  ])
  const [loadedFilesFromLocalStorage, setLoadedFilesFromLocalStorage] =
    useState(false)

  const loading = fetchStatus === QueryStatus.FETCHING
  const errorFetching = fetchStatus === QueryStatus.ERROR_FETCHING
  const errorUploading = uploadStatus === QueryStatus.ERROR_UPLOADING

  const activeIntegrations =
    integrations.data?.filter(
      (v) => v.integration_is_enabled && v.integration_has_setup
    ) || []

  const filteredResources = resources.filter((r) => {
    const sharedFilter =
      resourceFilter.includes('shared') &&
      r.document_is_part_of_desia_library === false &&
      r.document_source !== 'integration' &&
      (r.document_visibility === 'organization' ||
        r.document_visibility === 'shared')
    const privateFilter =
      resourceFilter.includes('private') &&
      r.document_is_part_of_desia_library === false &&
      r.document_source !== 'integration' &&
      r.document_visibility === 'private'
    const integrationFilter = resourceFilter.includes(
      r.document_source_details?.integration_code_name
    )
    const desiaLibraryFilter =
      resourceFilter.includes('desia library') &&
      r.document_is_part_of_desia_library === true

    const isReady = r.document_is_ready_to_use

    return (
      (sharedFilter ||
        privateFilter ||
        integrationFilter ||
        desiaLibraryFilter) &&
      isReady
    )
  })
  const sortedResources = [...filteredResources].sort(sortDocumentsFn)

  useEffect(() => {
    dispatch(listDocuments())
    const filters = localStorage.getItem('library_filters')
    if (filters) {
      setResourceFilter(JSON.parse(filters))
    }
  }, [dispatch])

  useEffect(() => {
    localStorage.setItem('library_filters', JSON.stringify(resourceFilter))
  }, [resourceFilter])

  useEffect(() => {
    document.title = 'Desia AI - Library'
  }, [])

  useEffect(() => {
    const openedFolder = searchParams.get('open')
    setTimeout(() => {
      if (openedFolder) {
        const element = document.querySelector(
          `#file-row-${openedFolder}`
        ) as HTMLTableRowElement | null
        if (element) {
          element.focus()
          searchParams.delete('open')
        }
      }
    }, 500)
  }, [searchParams, loading])

  useEffect(() => {
    if (!resources || !resources.length) return
    if (loadedFilesFromLocalStorage) return
    setLoadedFilesFromLocalStorage(true)
    const storedFiles = localStorage.getItem(LOCAL_STORAGE_FILES_KEY) //fixme
    if (!storedFiles) return
    const parsedFiles = JSON.parse(storedFiles)

    //better performance retrieval in the reduce
    const resourceMap = new Map(resources.map((r) => [r.document_id, r]))
    const mergedFiles = parsedFiles.reduce((acc: any, file: any) => {
      const foundDocumentFromEndpoint = resourceMap.get(file.document_id)
      acc[file.document_id] = foundDocumentFromEndpoint ?? {
        document_id: file.document_id,
        document_name: file.document_name,
        document_is_ready_to_use: false,
        status: DOCUMENT_STATUS.UPLOADING,
        document_type_friendly: file.document_type_friendly,
        document_visibility: file.document_visibility,
      }
      return acc
    }, {})
    dispatch(documentActions.setProcessingFiles(mergedFiles))
  }, [resources])

  const handleFilterChange = (filterName: string) => {
    if (resourceFilter.includes(filterName)) {
      setResourceFilter(resourceFilter.filter((v) => v !== filterName))
    } else {
      setResourceFilter([...resourceFilter, filterName])
    }
  }

  const handleUploadSubmit = (
    uploadedFiles: UploadableFile[],
    visibility: ResourceVisibility
  ) => {
    const filesToProcess = uploadedFiles.map((file) => ({
      document_id: file.signedUpload?.headers['x-goog-meta-document_id'],
      document_name: file.file.name,
      document_type_friendly: file.file.type,
      status: DOCUMENT_STATUS.UPLOADING,
      document_is_ready_to_use: false,
      document_visibility: visibility,
    }))

    const existingFiles = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_FILES_KEY) || '[]'
    )
    const combinedFiles = [...existingFiles, ...filesToProcess]
    localStorage.setItem(LOCAL_STORAGE_FILES_KEY, JSON.stringify(combinedFiles))

    const newProcessingFiles = {
      ...processingFiles,
      ...Object.fromEntries(
        filesToProcess.map((file) => [file.document_id, file])
      ),
    }
    dispatch(documentActions.setProcessingFiles(newProcessingFiles))
  }

  const sortedElements = transformResponseDocuments(sortedResources)

  const isFilterAllDisabled = resourceFilter.length === 0
  const isEmpty = sortedElements.length === 0

  return (
    <div className="max-w-full mt-10 mobile:mt-0 sm:max-w-[75rem] mx-auto flex flex-col gap-[4.5rem]">
      <div className="flex flex-col gap-8">
        <div className="text-center" id="library-test">
          <TypographyH3>Library</TypographyH3>
        </div>

        <MultiFileUpload onSubmit={handleUploadSubmit} />
      </div>

      {errorUploading && (
        <CustomAlert
          variant="error"
          title="We could not process all the files"
          description="We failed to upload that file, please try again shortly"
        />
      )}

      <div className="flex flex-col gap-6">
        <ResourceFilter
          integrations={activeIntegrations}
          selectedFilter={resourceFilter}
          handleFilterChange={handleFilterChange}
        />

        {isFilterAllDisabled || (!isFilterAllDisabled && isEmpty) ? (
          <TypographyBody className="text-center text-system-body">
            No files to display. Enable filters to view more files.
          </TypographyBody>
        ) : (
          <FileTable
            elements={sortedElements}
            shownElements={sortedElements}
            resources={sortedResources}
            setElements={() => {}}
            showHeader
            showLibraryDropdown
            loading={loading}
            error={errorFetching}
          />
        )}
      </div>

      {Object.values(processingFiles).length > 0 && (
        <ProcessingFiles
          files={Object.values(processingFiles)}
          onClose={() => {
            dispatch(documentActions.clearProcessingFiles())
            localStorage.removeItem(LOCAL_STORAGE_FILES_KEY)
          }}
        />
      )}
    </div>
  )
}

export function ResourcesPageContainer() {
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(fetchIntegrations())
  }, [])

  return <ResourcesPage />
}
