import { WEB_SERVER_ENDPOINT } from '@/constants'
import { RequestDocumentRawResource } from '@/types/types'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const documentApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${WEB_SERVER_ENDPOINT}/api`,
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    fetchFn: async (url, options) => {
      const response = await fetch(url, options)
      if (!response.ok) {
        throw new Error(`error status: ${response.status}`)
      }
      return response
    },
  }),
  endpoints: (builder) => ({
    getResourceData: builder.query({
      query: (payload: RequestDocumentRawResource) => ({
        url: `/document/raw-resource/${payload.fileId}`,
        method: 'get',
        responseHandler: async (response) => {
          return URL.createObjectURL(await response.blob())
        },
        params: payload.use_central_storage ? {
          use_central_storage: payload.use_central_storage,
          central_storage_path: payload.central_storage_path
        } : undefined
      }),
    }),
  }),
})

export default documentApi
