import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  FollowUpAsk,
  MessageAgentChatBot,
  NewAsk,
  ResponseChatDetails,
  ResponseChatList,
  ResponseChatStream,
  ResponseExpertModeStream,
  SourceConnector,
  ChatMessage,
  ChatSummary,
  SystemMessage,
  UserMessage,
  AssistantStore,
  DataStreamedResponse,
  StreamStatus,
  QueryStatus,
} from '../../types/types'
import { getTimestamp, saveIdentifier, timeDifference } from '../../utils/utils'
import {
  STREAM_RESPONSE_TIMEOUT_MS,
} from '../../constants'
import {
  mergeIncomingStreamWithSystemMessage,
  mergeIncomingExpertStreamWithSystemMessage,
} from '@/utils/components'
import { stopConversation } from './assistantThunk'
import { handleError } from '@/utils/handleError'
import { WritableDraft } from 'immer'
import { getDebouncedStreamResponseMS } from './utils'

const initialState: AssistantStore = {
  newAsk: [],
  liveConversations: [],
  list: [],
  state: {
    streamStatus: StreamStatus.Ready,
  },
  stopConversationStatus: QueryStatus.IDLE,
  lastResponseTimestamp: null,
}

const DEBOUNCE_MS = getDebouncedStreamResponseMS()

export const assistantSlice = createSlice({
  name: 'assistant',
  initialState,
  reducers: {
    newAsk: (state, action: PayloadAction<NewAsk>) => {
      state.list.push({
        requestId: action.payload.requestId,
        query: action.payload.question,
        created_at: action.payload.timestamp,
        updated_at: action.payload.timestamp,
        conversationId: '',
      })
      state.newAsk.push(action.payload)
      state.liveConversations.push({
        requestIds: [action.payload.requestId],
        conversation: [
          {
            conversationId: '',
            requestId: action.payload.requestId,
            query: action.payload.question,
            timestamp: action.payload.timestamp,
            mode: action.payload.mode,
            role: 'user',
            playbook: action.payload.playbook,
            tool_events: null,
          },
          {
            conversationId: '',
            requestId: action.payload.requestId,
            timestamp: action.payload.timestamp,
            data: {
              ...(action.payload.mode === 'expert' && {
                plan: {
                  id: '',
                  conversation_id: '',
                  steps: [],
                },
              }),
              isFinished: false,
              tool_events: action.payload.tool_events,
            },
            role: 'system',
          },
        ],
      })
      state.state.streamStatus = StreamStatus.Thinking
    },
    followUpAsk: (state, action: PayloadAction<FollowUpAsk>) => {
      state.liveConversations = state.liveConversations.map((lc) => {
        if (
          lc.conversationId === action.payload.conversationId ||
          lc.requestIds?.includes(action.payload.requestId)
        ) {
          return {
            ...lc,
            requestIds: [...(lc.requestIds || []), action.payload.requestId],
            conversation: [
              ...lc.conversation,
              {
                query: action.payload.question,
                conversationId: action.payload.conversationId,
                role: 'user',
                timestamp: action.payload.timestamp,
                playbook: action.payload.playbook,
                tool_events: null,
              },
              {
                conversationId: action.payload.conversationId,
                requestId: action.payload.requestId,
                timestamp: action.payload.timestamp,
                role: 'system',
                data: {
                  ...(action.payload.mode === 'expert' && {
                    plan: {
                      id: '',
                      conversation_id: '',
                      steps: [],
                    },
                  }),
                  isFinished: false,
                  tool_events: action.payload.tool_events,
                },
              },
            ],
          }
        }
        return lc
      })

      state.state.streamStatus = StreamStatus.Thinking
    },
    streamingResponse: (state, action: PayloadAction<DataStreamedResponse>) => {
      switch (action.payload.event_type) {
        case 'stream-start':
          // Note: Side-effects like saveIdentifier should be handled in middleware or action creators
          saveIdentifier(
            action.payload.requestId,
            action.payload.conversation_id,
            action.payload.timestamp
          )

          state.list.forEach((li) => {
            if (li.requestId === action.payload.requestId) {
              li.conversationId = action.payload.conversation_id
            }
          })

          state.liveConversations.forEach((lc) => {
            if (lc.requestIds?.includes(action.payload.requestId)) {
              lc.conversationId = action.payload.conversation_id
              lc.conversation.forEach((c) => {
                if (c.requestId === action.payload.requestId) {
                  c.conversationId = action.payload.conversation_id
                }
              })
            }
          })

          state.lastResponseTimestamp = action.payload.timestamp
          break
        case 'agent-response':
          let skipAgentResponse = shouldSkipMessage(state, action, DEBOUNCE_MS)
          if (skipAgentResponse) return

          state.lastResponseTimestamp = action.payload.timestamp

          state.liveConversations.forEach((lc) => {
            if (
              lc.conversationId === action.payload.conversation_id ||
              lc.requestIds?.includes(action.payload.requestId)
            ) {
              lc.conversationId = action.payload.conversation_id
              lc.conversation = lc.conversation.map((c) => {
                if (c.role === 'system' && !c.data.isFinished) {
                  const merged = mergeIncomingExpertStreamWithSystemMessage(
                    c,
                    action.payload as ResponseExpertModeStream
                  )
                  return merged
                }
                return c
              })
            }
          })

          if (action.payload.is_finished) {
            state.lastResponseTimestamp = null
          }
          break
        case 'stream-end':
          state.liveConversations.forEach((lc) => {
            if (
              lc.conversationId === action.payload.conversation_id ||
              lc.requestIds?.includes(action.payload.requestId)
            ) {
              lc.conversation = lc.conversation.map((c) => {
                if (c.role === 'system' && !c.data.isFinished) {
                  const merged = mergeIncomingStreamWithSystemMessage(
                    c,
                    action.payload as ResponseChatStream
                  )
                  return merged
                }
                return c
              })
            }
          })
          state.lastResponseTimestamp = null
          break
        default:
          const skip = shouldSkipMessage(state, action, DEBOUNCE_MS)
          if (skip) return

          state.lastResponseTimestamp = action.payload.timestamp

          state.liveConversations.forEach((lc) => {
            if (
              lc.conversationId === action.payload.conversation_id ||
              lc.requestIds?.includes(action.payload.requestId)
            ) {
              lc.conversation = lc.conversation.map((c) => {
                if (c.role === 'system' && !c.data.isFinished) {
                  const merged = mergeIncomingStreamWithSystemMessage(
                    c,
                    action.payload as ResponseChatStream
                  )
                  return merged
                }
                return c
              })
            }
          })
          break
      }
    },
    fetchConversation: (state, action: PayloadAction<ResponseChatDetails>) => {
      const conversationId = action.payload.id
      if (!conversationId) {
        console.error(
          `[desia-web-app]: conversationId not found following FETCH_CONVERSATION`
        )
        return
      }
      // Replace existing conversation if it exists
      state.liveConversations = state.liveConversations.filter(
        (lc) => lc.conversationId !== action.payload.id
      )
      state.liveConversations.push({
        requestIds: [],
        conversationId: action.payload.id,
        conversation: mapperConversation(action.payload),
      })
    },
    fetchThreads: (state, action: PayloadAction<ResponseChatList[] | null>) => {
      if (!action.payload) {
        return
      }
      state.list = action.payload.map(mapperChatList)
    },
    chatDelete: (state, action: PayloadAction<{ conversationId: string }>) => {
      state.list = state.list.filter(
        (v) => v.conversationId !== action.payload.conversationId
      )
    },
    chatRename: (
      state,
      action: PayloadAction<{ conversationId: string; title: string }>
    ) => {
      const copy = [...state.list]
      const index = copy.findIndex(
        (v) => v.conversationId === action.payload.conversationId
      )
      if (index >= 0) {
        copy[index].query = action.payload.title
        state.list = copy
      }
    },
    setStreamStatusWithMessage: (
      state,
      action: PayloadAction<{
        message: ChatMessage | null
        sourceConnectors: SourceConnector[]
      }>
    ) => {
      const { message, sourceConnectors } = action.payload

      if (!message) {
        state.state.streamStatus = StreamStatus.Ready
        return
      }

      if (message.role !== 'system') {
        const hasInternalSearch = sourceConnectors.some(
          (v) => v.id === 'internal-search'
        )
        const hasWebSearch = sourceConnectors.some((v) => v.id === 'web-search')

        if (hasInternalSearch && hasWebSearch) {
          state.state.streamStatus = StreamStatus.SearchingWebAndInternalDocs
        } else if (hasInternalSearch) {
          state.state.streamStatus = StreamStatus.SearchingInternalDocs
        } else if (hasWebSearch) {
          state.state.streamStatus = StreamStatus.SearchingWeb
        } else {
          state.state.streamStatus = StreamStatus.Searching
        }
        return
      }

      if (message.data.isFinished) {
        state.state.streamStatus = StreamStatus.Ready
        return
      }

      if (message.data.citations) {
        state.state.streamStatus = StreamStatus.ApplyingCitations
        return
      }

      if (message.data.text || message.data.documents) {
        state.state.streamStatus = StreamStatus.Answering
        return
      }

      state.state.streamStatus = StreamStatus.Unexpected
    },
    setStreamStatus: (
      state,
      action: PayloadAction<{ status: StreamStatus }>
    ) => {
      state.state.streamStatus = action.payload.status
    },
    removeLastMessage: (
      state,
      action: PayloadAction<{ conversationId: string }>
    ) => {
      const conversationIndex = state.liveConversations.findIndex(
        (v) => v.conversationId === action.payload.conversationId
      )

      state.liveConversations[conversationIndex].conversation =
        state.liveConversations[conversationIndex].conversation.slice(0, -2)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(stopConversation.pending, (state) => {
        state.stopConversationStatus = QueryStatus.FETCHING
      })
      .addCase(stopConversation.fulfilled, (state) => {
        state.stopConversationStatus = QueryStatus.SUCCEEDED
      })
      .addCase(stopConversation.rejected, (state, action) => {
        state.stopConversationStatus = QueryStatus.ERROR_FETCHING
        handleError(action.error)
      })
  },
})

function mapperChatList(data: ResponseChatList): ChatSummary {
  return {
    conversationId: data.id,
    query: data.title,
    created_at: getTimestamp(data.created_at),
    updated_at: getTimestamp(data.updated_at),
    dossierId: data.dossier_id,
  }
}

function checkSystemMessageFinished(s: MessageAgentChatBot) {
  if (s.is_active) return true
  if (s.citations.length > 0) return true
  if (
    timeDifference(new Date(s.updated_at), new Date()) >
    STREAM_RESPONSE_TIMEOUT_MS
  )
    return true
  return false
}

function mapperConversation(data: ResponseChatDetails): ChatMessage[] {
  const conversationId = data.id
  const sortedBasedOnTime = data.messages.sort(
    (a, b) => getTimestamp(a.created_at) - getTimestamp(b.created_at)
  )

  return sortedBasedOnTime.reduce((acc, cur, index) => {
    const d = cur
    if (d.agent === 'USER') {
      const userMessage: UserMessage = {
        query: d.text,
        conversationId: d.id,
        timestamp: getTimestamp(d.created_at),
        role: 'user',
        playbook: d.playbook,
        tool_events: d.tool_events,
      }
      return [...acc, userMessage]
    }
    if (d.agent === 'CHATBOT') {
      const systemMessage: SystemMessage = {
        conversationId,
        timestamp: getTimestamp(d.created_at),
        role: 'system',
        data: {
          citations: d.citations,
          documents: d.documents.map((d) => {
            return {
              ...d,
              document_link: d.document_link || '',
            }
          }),
          text: d.text,
          isFinished: checkSystemMessageFinished(d),
          // todo: update this with source ticket DES-1115
          followUpQuestions:
            index === data.messages.length - 1
              ? data.follow_up_questions
              : undefined,
          tool_events: d.tool_events,
        },
      }
      const plan = (data.plans || []).find(
        (p) => p.conversation_id === conversationId
      )
      if (plan) {
        systemMessage.data.plan = plan
      }
      return [...acc, systemMessage]
    }
    return acc
  }, [] as ChatMessage[])
}

function shouldSkipMessage(
  state: WritableDraft<AssistantStore>,
  action: PayloadAction<DataStreamedResponse>,
  debounceMS: number
) {
  const isTextGeneration = action.payload.event_type === 'text-generation'
  const insufficientTimePassed =
    action.payload.timestamp - (state.lastResponseTimestamp || 0) <
    (debounceMS)

  if (isTextGeneration && insufficientTimePassed) return true
  return false
}

export const actions = assistantSlice.actions
export default assistantSlice.reducer
