import { useNavigate, useSearchParams } from 'react-router-dom'
import { TypographyH3 } from '../ui/Typography'
import { useCallback, useEffect, useState } from 'react'
import { AskDesiaLink } from './AskDesiaLink'
import { SearchResults } from './SearchResults'
import { DedupedSearchQueryItem, ResponseSearchQuery } from '@/types/types'
import { groupByFile } from '@/utils/search'
import { Searchbar } from '../Searchbar'
import { SearchSourceSelector } from './SearchSourceSelector'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import { getSearchConnectors } from './utils'
import { fetchAllSearchResults } from './searchThunk'
import { isFulfilled } from '@reduxjs/toolkit'
import { useDebounce } from '@/hooks/useDebounce'

export function ResourceSearchPage({
  loading,
  error,
  data,
  handleSearch: onExecuteSearch,
}: {
  loading: boolean
  error: boolean
  data: ResponseSearchQuery | null
  handleSearch: (q: string) => void
}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchQuery = searchParams.get('q')

  const navigate = useNavigate()

  function handleSearch(nextSearchQuery: string) {
    if (nextSearchQuery === '') {
      return
    }
    setSearchParams({ q: nextSearchQuery })
  }

  function handleAskDesia() {
    navigate(`/assistant/ask?q=${searchQuery}&trigger=true&mode=simple`)
  }

  useEffect(() => {
    if (searchQuery) {
      onExecuteSearch(searchQuery)
    }
  }, [searchQuery, onExecuteSearch])

  useEffect(() => {
    document.title = 'Desia AI - Search results'
  }, [])

  const dedupedResults: DedupedSearchQueryItem[] = groupByFile(data || [])

  return (
    <div className="relative flex flex-col gap-2 -mx-4 mobile:mx-0 tablet:mx-auto mt-14 sm:mt-0 max-w-[83.75rem] !h-screen !overflow-hidden">
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-6">
          <AskDesiaLink query={searchQuery} handleClick={handleAskDesia} />

          <div className="text-center">
            <TypographyH3>Search results</TypographyH3>
          </div>

          <div className="mx-auto max-w-full w-[calc(100%-4.5rem)] sm:w-[30rem]">
            <Searchbar
              type="search-result"
              autoFocus={true}
              initialQuery={searchQuery || undefined}
              handleSearch={handleSearch}
            />
          </div>
        </div>

        <div className="flex flex-col gap-6">
          <SearchSourceSelector />
          <div className="flex flex-col gap-6">
            <SearchResults
              loading={loading}
              error={error}
              data={dedupedResults}
              query={searchQuery || ''}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export function ResourceSearchPageContainer() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [results, setResults] = useState<ResponseSearchQuery | null>(null)

  const connectors = useSelector((state: RootState) => state.search.connectors)

  const debouncedConnectors = useDebounce(connectors, 200)

  const dispatch = useDispatch<AppDispatch>()

  const handleSearch = useCallback(
    async (q: string) => {
      setLoading(true)

      const result = await dispatch(
        fetchAllSearchResults({
          query: q,
          semantic_highlights: 'generative',
          sources: getSearchConnectors(debouncedConnectors),
        })
      )
      
      if (isFulfilled(fetchAllSearchResults)(result)) {
        setResults(result.payload)
        setError(false)
      } else {
        setResults([])
        setError(true)
      }

      setLoading(false)
    },
    [debouncedConnectors, dispatch]
  )

  return (
    <ResourceSearchPage
      loading={loading}
      error={error}
      data={results}
      handleSearch={handleSearch}
    />
  )
}
