import { z } from 'zod'
import { Input } from '../ui/input'
import { TypographyBody, TypographyH4 } from '../ui/Typography'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form, FormControl, FormField, FormItem, FormMessage } from '../ui/form'
import { Textarea } from '../ui/textarea'
import { Button } from '../ui/button'
import Divider from '../ui/divider'
import { ChevronUp, Plus } from 'lucide-react'
import { RefObject, useContext, useEffect, useRef, useState } from 'react'

import {
  DocgenTemplate,
  DocgenTemplateRequest,
  Section,
  TemplateSection,
} from '@/types/types'
import { TemplateSectionList } from './TemplateSectionList'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import { createTemplate, fetchTemplate, updateTemplate } from './docGenThunk'
import { addTemplate, removeTemplate, replaceTemplate } from './docGenSlice'
import { convertTemplateTitleToName, removeInitialHyphens } from '@/utils/utils'
import { isFulfilled } from '@reduxjs/toolkit'
import ChevronDown from '@/assets/ChevronDown'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import { ListInput } from '../ListInput/ListInput'
import Audience from '@/assets/Audience'
import { ToastContext } from '@/contexts/ToastContext'
import { LocalStorageKey } from '@/constants'

export const DocGenTemplateForm = ({
  selectedTemplate,
  templates,
  formRef,
  disabled = false,
  createDuplicate = false,
  templateCustomization = false,
  onSave,
  onEdit,
  onError,
}: {
  selectedTemplate: DocgenTemplate | null
  templates: DocgenTemplate[]
  formRef: RefObject<HTMLFormElement>
  disabled?: boolean
  createDuplicate?: boolean
  templateCustomization?: boolean
  onSave: (template: DocgenTemplate) => void
  onEdit?: () => void
  onError?: () => void
}) => {
  const templateCategories = useSelector(
    (state: RootState) => state.docGen.templateCategories
  )

  const { showToast } = useContext(ToastContext)

  const templateFormSchema = z.object({
    name: z
      .string()
      .min(1, 'Please enter a name')
      .refine(
        (name) =>
          !templates.find((v) => v.title === name && !selectedTemplate?.id),
        (name) => ({
          message: `${name} already exists, please enter a different name.`,
        })
      ),
    category_id: z.string().min(1, 'Please select a category'),
    description: z.string().min(1, 'Please enter a description'),
    target_audience: z.string(),
    audience_characteristics: z.string(),
    style_guidelines: z.string(),
  })

  const form = useForm<z.infer<typeof templateFormSchema>>({
    resolver: zodResolver(templateFormSchema),
    defaultValues: {
      name: selectedTemplate?.title || '',
      category_id: selectedTemplate?.category_id || 'no-category',
      description: selectedTemplate?.description || '',
      target_audience: selectedTemplate?.target_audience || '',
      audience_characteristics:
        selectedTemplate?.audience_characteristics?.join('\n') || '',
      style_guidelines: selectedTemplate?.style_guidelines?.join('\n') || '',
    },
    disabled: disabled,
  })

  const [sections, setSections] = useState<TemplateSection[]>([])
  const [showAudienceRequirements, setShowAudienceRequirements] =
    useState(false)
  const [selectedIndexPath, setSelectedIndexPath] = useState<number[] | null>(
    null
  )

  const scrollRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch<AppDispatch>()

  const formValues = form.getValues()

  const onSubmit = async (values: z.infer<typeof templateFormSchema>) => {
    if (selectedTemplate?.id && !selectedTemplate.file_id && !createDuplicate) {
      const updatedTemplate: DocgenTemplate = {
        ...selectedTemplate,
        name: templateCustomization ? selectedTemplate.name : convertTemplateTitleToName(values.name),
        title: values.name,
        description: values.description,
        target_audience: values.target_audience,
        audience_characteristics: values.audience_characteristics.split('\n'),
        style_guidelines: values.style_guidelines.split('\n'),
        sections: mapTemplateSectionsToSections(sections),
        category_id:
          values.category_id === 'no-category' ? null : values.category_id,
      }

      await dispatch(updateTemplate(updatedTemplate))

      if (selectedTemplate) {
        dispatch(replaceTemplate(updatedTemplate))
      } else {
        dispatch(addTemplate(updatedTemplate))
      }
      onSave(updatedTemplate)
    } else {
      const templateName = createDuplicate
        ? `${values.name} ${crypto.randomUUID().split('-')[1]}`
        : values.name
      const newTemplate: DocgenTemplateRequest = {
        name: convertTemplateTitleToName(templateName),
        title: values.name,
        description: values.description,
        target_audience: values.target_audience,
        audience_characteristics: values.audience_characteristics.split('\n'),
        style_guidelines: values.style_guidelines.split('\n'),
        sections: mapTemplateSectionsToSections(sections),
        category_id:
          values.category_id === 'no-category' ? null : values.category_id,
        hidden: createDuplicate,
        copy_of: selectedTemplate?.id || null,
      }

      const result = await dispatch(createTemplate(newTemplate))

      if (isFulfilled(createTemplate)(result)) {
        const payload = result.payload as DocgenTemplate
        if (selectedTemplate?.file_id) {
          dispatch(removeTemplate(selectedTemplate))
          const templateFileIds = JSON.parse(
            localStorage.getItem(LocalStorageKey.TEMPLATE_FILE_IDS) || '{}'
          )
          templateFileIds[payload.id] = selectedTemplate.file_id
          localStorage.setItem(
            LocalStorageKey.TEMPLATE_FILE_IDS,
            JSON.stringify(templateFileIds)
          )
        }

        if (!createDuplicate) {
          dispatch(addTemplate(payload))

          showToast({
            variant: 'success',
            description: `${payload.title} template created`,
            dismissable: true,
          })
          onSave(payload)

        } else {
          onSave({
            ...payload,
            copy_of: selectedTemplate?.id
          })
        }
      } else {
        onError?.()
      }
    }
  }

  const mapSectionsToTemplateSections = (sections: Section[]) => {
    const mappedSections: TemplateSection[] = sections.map((section, index) => {
      return {
        id: index,
        title: section.title,
        description: section.description,
        semantic_guidelines: section.semantic_guidelines,
        style_guidelines: section.style_guidelines,
        content_types: section.content_types,
        expanded: false,
        subsections: (section.subsections || []).map((subsection, index) => {
          return {
            id: index,
            title: subsection.title,
            description: subsection.description,
            semantic_guidelines: subsection.semantic_guidelines,
            style_guidelines: subsection.style_guidelines,
            content_types: subsection.content_types,
            subsections: (subsection.subsections || []).map(
              (subsection, index) => {
                return {
                  id: index,
                  title: subsection.title,
                  description: subsection.description,
                  subsections: [],
                  semantic_guidelines: subsection.semantic_guidelines,
                  style_guidelines: subsection.style_guidelines,
                  content_types: subsection.content_types,
                }
              }
            ),
          }
        }),
      } as TemplateSection
    })

    return mappedSections
  }

  const mapTemplateSectionsToSections = (sections: TemplateSection[]) => {
    const mappedSections: Section[] = sections.map((section) => {
      return {
        name: convertTemplateTitleToName(section.title),
        title: section.title,
        description: section.description || '',
        semantic_guidelines: section.semantic_guidelines,
        style_guidelines: section.style_guidelines,
        content_types: section.content_types,
        subsections: (section.subsections || []).map((subsection) => {
          return {
            name: convertTemplateTitleToName(subsection.title),
            title: subsection.title,
            description: subsection.description,
            semantic_guidelines: subsection.semantic_guidelines,
            style_guidelines: subsection.style_guidelines,
            content_types: subsection.content_types,
            subsections: (subsection.subsections || []).map((subsection) => {
              return {
                name: convertTemplateTitleToName(subsection.title),
                title: subsection.title,
                description: subsection.description,
                semantic_guidelines: subsection.semantic_guidelines,
                style_guidelines: subsection.style_guidelines,
                content_types: subsection.content_types,
              }
            }),
          }
        }),
      } as Section
    })

    return mappedSections
  }

  const fetchSelectedTemplate = async () => {
    if (selectedTemplate) {
      if (selectedTemplate.file_id) {
        const mappedSections: TemplateSection[] = mapSectionsToTemplateSections(
          selectedTemplate.sections || []
        )

        setSections(mappedSections)
      } else if (selectedTemplate.id) {
        const result = await dispatch(fetchTemplate(selectedTemplate.id))
        if (isFulfilled(fetchTemplate)(result)) {
          const payload = result.payload as DocgenTemplate

          const mappedSections: TemplateSection[] =
            mapSectionsToTemplateSections(payload.sections || [])

          setSections(mappedSections)
        }
      } else {
        const mappedSections: TemplateSection[] = mapSectionsToTemplateSections(
          selectedTemplate.sections || []
        )

        setSections(mappedSections)
      }
    }
  }

  useEffect(() => {
    if (selectedTemplate) {
      form.setValue('name', selectedTemplate.title || '')
      form.setValue('description', selectedTemplate.description || '')
      form.setValue('target_audience', selectedTemplate.target_audience || '')
      form.setValue(
        'audience_characteristics',
        removeInitialHyphens(
          selectedTemplate.audience_characteristics?.join('\n') || ''
        )
      )
      form.setValue(
        'style_guidelines',
        removeInitialHyphens(
          selectedTemplate.style_guidelines?.join('\n') || ''
        )
      )
      form.setValue(
        'category_id',
        selectedTemplate.category_id || 'no-category'
      )
      fetchSelectedTemplate()
    }
  }, [form, selectedTemplate])

  useEffect(() => {
    if (selectedTemplate) {
      const updatedTemplate: DocgenTemplate = {
        ...selectedTemplate,
        description: formValues.description,
        target_audience: formValues.target_audience,
        audience_characteristics:
          formValues.audience_characteristics.split('\n'),
        style_guidelines: formValues.style_guidelines.split('\n'),
        category_id: formValues.category_id,
      }

      if (
        JSON.stringify(updatedTemplate) !== JSON.stringify(selectedTemplate)
      ) {
        onEdit?.()
      }
    }
  }, [formValues, selectedTemplate, sections])

  return (
    <div ref={scrollRef} className="flex flex-col gap-10 overflow-x-visible">
      <Form {...form}>
        <form
          ref={formRef}
          onSubmit={form.handleSubmit(onSubmit)}
          className="space-y-2"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="mx-auto space-y-8 overflow-x-visible ">
            {!templateCustomization && (
              <>
                <FormField
                  control={form.control}
                  name="category_id"
                  render={({ field }) => (
                    <FormItem className="space-y-2">
                      <div className="flex flex-col gap-0">
                        <TypographyBody isStrong>
                          Template category
                        </TypographyBody>
                      </div>
                      <FormMessage className="w-fit min-w-[20rem]" />
                      <FormControl>
                        <Select
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <SelectTrigger className="w-[20rem]" {...field}>
                            <SelectValue placeholder="Select category" />
                          </SelectTrigger>
                          <SelectContent>
                            {templateCategories.data.map((category, index) => {
                              return (
                                <SelectItem
                                  key={`category-${index}`}
                                  value={category.id || `category-${index}`}
                                >
                                  {category.title}
                                </SelectItem>
                              )
                            })}

                            <SelectItem
                              key={`category-${templateCategories.data.length}`}
                              value={'no-category'}
                            >
                              No category
                            </SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem className="space-y-2">
                      <div className="flex flex-col gap-0">
                        <TypographyBody isStrong>Template name</TypographyBody>
                      </div>
                      <FormMessage className="w-fit min-w-[22.5rem]" />
                      <FormControl>
                        <Input
                          {...field}
                          className="w-[22.5rem]"
                          placeholder="e.g. Investment proposal"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </>
            )}
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem className="space-y-2">
                  <div className="flex flex-col gap-0">
                    <TypographyBody isStrong>
                      Template description
                    </TypographyBody>
                  </div>
                  <FormMessage />
                  <FormControl>
                    <Textarea
                      {...field}
                      placeholder="e.g. Template for seed round investment memos, focusing on key deal terms, company overview, market opportunity, and investment thesis"
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="style_guidelines"
              render={() => (
                <FormItem className="space-y-2">
                  <div className="flex flex-col gap-0">
                    <TypographyBody isStrong>Style guidelines</TypographyBody>
                  </div>
                  <FormMessage />
                  <FormControl>
                    <ListInput
                      values={form.getValues('style_guidelines').split('\n')}
                      onChange={(v) => {
                        form.setValue('style_guidelines', v.join('\n'))
                      }}
                      disabled={disabled}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <Button
              variant="tertiary"
              className="ml-auto"
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                setShowAudienceRequirements(!showAudienceRequirements)
              }}
            >
              <div className="flex gap-2 items-center">
                <Audience />

                <TypographyBody isStrong className="text-system-primary">
                  Audience settings (Optional)
                </TypographyBody>
                {showAudienceRequirements ? (
                  <ChevronUp className="size-6 shrink-0 stroke-[1.5px]" />
                ) : (
                  <ChevronDown className="size-6 shrink-0 stroke-[1.5px]" />
                )}
              </div>
            </Button>
            {showAudienceRequirements && (
              <>
                <FormField
                  control={form.control}
                  name="target_audience"
                  render={({ field }) => (
                    <FormItem className="space-y-2 w-full">
                      <div className="flex flex-col gap-0">
                        <TypographyBody isStrong>
                          Target audience
                        </TypographyBody>
                      </div>
                      <FormMessage />
                      <FormControl>
                        <Input
                          {...field}
                          className="min-w-full"
                          placeholder="e.g. Investment proposal"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="audience_characteristics"
                  render={() => (
                    <FormItem className="space-y-2">
                      <div className="flex flex-col gap-0">
                        <TypographyBody isStrong>
                          Audience requirements
                        </TypographyBody>
                      </div>
                      <FormMessage />
                      <FormControl>
                        <ListInput
                          values={form
                            .getValues('audience_characteristics')
                            .split('\n')}
                          onChange={(v) => {
                            form.setValue(
                              'audience_characteristics',
                              v.join('\n')
                            )
                          }}
                          disabled={disabled}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </>
            )}
          </div>
        </form>
      </Form>

      <Divider />

      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-0">
          <TypographyH4>Section</TypographyH4>
        </div>
        {!disabled && (
          <Button
            variant="secondary"
            className="w-fit"
            onClick={() => {
              const id =
                sections.length > 0
                  ? Math.max(...(sections || []).map((v) => v.id)) + 1
                  : 0

              setSections([
                {
                  id: id,
                  title: '',
                  description: '',
                  expanded: false,
                },
                ...sections,
              ])
              onEdit?.()
              setSelectedIndexPath([0])
            }}
          >
            <div className="flex gap-2 items-center w-fit">
              <Plus className="size-6 shrink-0 stroke-[1.5px]" />
              Add section
            </div>
          </Button>
        )}
        <TemplateSectionList
          disabled={disabled}
          sections={sections}
          setSections={(v) => {
            setSections(v)
            onEdit?.()
          }}
          selectedIndexPath={selectedIndexPath}
          onEditorClose={() => setSelectedIndexPath(null)}
          onEditorOpen={(v) => setSelectedIndexPath(v)}
        />
      </div>
    </div>
  )
}
