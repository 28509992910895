import { SourceConnector, SourceSettings, ToolEvents } from '@/types/types'
import { checkUserFlag } from './utils'

export function getAskTools(s: SourceSettings) {
  const toolsEnabled = checkUserFlag('assistant: tools')
  if (!toolsEnabled) {
    return {}
  }

  const tools = [{ name: 'python_interpreter' }, { name: 'calculator' }]

  if (s.webSearch) {
    tools.push({ name: 'web_search' })
  }
  if (s.internalSearch) {
    tools.push({ name: 'internal_search' })
  }
  if (s.teamsSearch) {
    tools.push({ name: 'teams_search' })
  }
  if (s.outlookSearch) {
    tools.push({ name: 'outlook_search' })
  }
  if (s.companiesHouseSearch) {
    tools.push({ name: 'companies_house_search' })
  }

  return {
    tools,
  }
}

export function getAskConnectors(s: SourceSettings) {
  const connectorsV2: SourceConnector[] = []

  if (s.webSearch) {
    connectorsV2.push({ id: 'web-search' })
  }
  if (s.internalSearch) {
    connectorsV2.push({ id: 'internal-search' })
  }
  if (s.teamsSearch) {
    connectorsV2.push({ id: 'teams-search' })
  }
  if (s.outlookSearch) {
    connectorsV2.push({ id: 'outlook-search' })
  }
  if (s.financialDataSearch) {
    connectorsV2.push({ id: 'financial-data-tool' })
  }
  if (s.companiesHouseSearch) {
    connectorsV2.push({ id: 'companies-house-search' })
  }
  if (s.filingsSearch) {
    connectorsV2.push({ id: 'filings-search' })
  }
  if (s.transcriptsSearch) {
    connectorsV2.push({ id: 'transcripts-search' })
  }

  return connectorsV2
}

export function getAskToolEvents(s: SourceSettings): ToolEvents {
  const tools: ToolEvents = {}

  if (s.webSearch) {
    const id = crypto.randomUUID().split('-')[1]
    tools[id] = {
      artifacts: [],
      params: { search_query: '', thinking_message: '' },
      results_returned: 0,
      status: 'in-progress',
      thinking_message: null,
      tool_id: 'web_search_tool',
      tool_name: 'web_search_tool',
      uuid: id,
      placeholder: true,
    }
  }
  if (s.internalSearch) {
    const id = crypto.randomUUID().split('-')[1]
    tools[id] = {
      artifacts: [],
      params: { search_query: '', thinking_message: '' },
      results_returned: 0,
      status: 'in-progress',
      thinking_message: null,
      tool_id: 'internal_search_tool',
      tool_name: 'internal_search_tool',
      uuid: crypto.randomUUID().split('-')[1],
      placeholder: true,
    }
  }
  if (s.teamsSearch) {
    const id = crypto.randomUUID().split('-')[1]
    tools[id] = {
      artifacts: [],
      params: { search_query: '', thinking_message: '' },
      results_returned: 0,
      status: 'in-progress',
      thinking_message: null,
      tool_id: 'msft_teams_search_tool',
      tool_name: 'msft_teams_search_tool',
      uuid: crypto.randomUUID().split('-')[1],
      placeholder: true,
    }
  }
  if (s.outlookSearch) {
    const id = crypto.randomUUID().split('-')[1]
    tools[id] = {
      artifacts: [],
      params: { search_query: '', thinking_message: '' },
      results_returned: 0,
      status: 'in-progress',
      thinking_message: null,
      tool_id: 'msft_outlook_search_tool',
      tool_name: 'msft_outlook_search_tool',
      uuid: crypto.randomUUID().split('-')[1],
      placeholder: true,
    }
  }
  if (s.companiesHouseSearch) {
    const id = crypto.randomUUID().split('-')[1]
    tools[id] = {
      artifacts: [],
      params: { search_query: '', thinking_message: '' },
      results_returned: 0,
      status: 'in-progress',
      thinking_message: null,
      tool_id: 'companies_house_search',
      tool_name: 'companies_house_search',
      uuid: crypto.randomUUID().split('-')[1],
      placeholder: true,
    }
  }
  if (s.financialDataSearch) {
    const id = crypto.randomUUID().split('-')[1]
    tools[id] = {
      artifacts: [],
      params: { search_query: '', thinking_message: '' },
      results_returned: 0,
      status: 'in-progress',
      thinking_message: null,
      tool_id: 'financial_data_tool',
      tool_name: 'financial_data_tool',
      uuid: crypto.randomUUID().split('-')[1],
      placeholder: true,
    }
  }
  if (s.filingsSearch) {
    const id = crypto.randomUUID().split('-')[1]
    tools[id] = {
      artifacts: [],
      params: { search_query: '', thinking_message: '' },
      results_returned: 0,
      status: 'in-progress',
      thinking_message: null,
      tool_id: 'filings_search',
      tool_name: 'filings_search',
      uuid: crypto.randomUUID().split('-')[1],
      placeholder: true,
    }
  }
  if (s.transcriptsSearch) {
    const id = crypto.randomUUID().split('-')[1]
    tools[id] = {
      artifacts: [],
      params: { search_query: '', thinking_message: '' },
      results_returned: 0,
      status: 'in-progress',
      thinking_message: null,
      tool_id: 'transcripts_search',
      tool_name: 'transcripts_search',
      uuid: crypto.randomUUID().split('-')[1],
      placeholder: true,
    }
  }

  if (s.companiesHouseFiles.length > 0) {
    s.companiesHouseFiles.forEach((file) => {
      const id = crypto.randomUUID().split('-')[1]
      tools[id] = {
        artifacts: [],
        params: {
          search_query: '',
          thinking_message: '',
          file_name: file.text,
        },
        results_returned: 0,
        status: 'in-progress',
        thinking_message: null,
        tool_id: 'process_file',
        tool_name: 'process_file',
        uuid: crypto.randomUUID().split('-')[1],
        placeholder: true,
      }
    })
  }

  if (s.files.length > 0) {
    const id = crypto.randomUUID().split('-')[1]
    tools[id] = {
      artifacts: [],
      params: {
        search_query: '',
        thinking_message: '',
        file_ids: [...s.files.map((v) => v.id)],
      },
      results_returned: 0,
      status: 'in-progress',
      thinking_message: null,
      tool_id: 'generate_gemini_content',
      tool_name: 'generate_gemini_content',
      uuid: crypto.randomUUID().split('-')[1],
      placeholder: true,
    }
  }

  return tools
}
