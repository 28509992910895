import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { followUpAsk, newAsk, setToolComponent } from '../docGenSlice'
import { SourceSelector } from '@/components/Assistant/SourceSelector'
import {
  ChevronLeft,
  ChevronRight,
  MessageCircleQuestionIcon,
  X,
} from 'lucide-react'
import { RootState } from '@/store/store'
import {
  formatFocusedAnalysisFiles,
  getCitationExtractResource,
  getCitationHighlights,
  getTimestamp,
  getUniqueCitationDocuments,
  sortSourceDocuments,
} from '@/utils/utils'
import shortid from 'shortid'
import {
  Citation,
  Conversation,
  DocumentPreviewType,
  DossierDetail,
  SourceDocument,
  SystemMessage,
  UserMessage,
} from '@/types/types'
import { Button } from '@/components/ui/button'
import { TypographyMuted } from '@/components/ui/Typography'
import { OutputData } from '@editorjs/editorjs'
import { convertReportToString } from '@/utils/docgen'
import { getAskTools } from '@/utils/ask'
import { UserContext } from '@/contexts/UserContext'
import Divider from '@/components/ui/divider'
import { handleError } from '@/utils/handleError'
import SystemChatMessage from '@/components/Chat/SystemChatMessage'
import UserChatMessage from '@/components/Chat/UserChatMessage'
import { Sources } from '@/components/Assistant/Sources'
import { DocumentPreviewContainer } from '@/components/Resources/DocumentPreview'

function getConversationPair({
  conversation,
  page,
}: {
  conversation: Conversation
  page: number | null
}) {
  try {
    if (!conversation.length) {
      return []
    }
    if (page === null) {
      return [
        conversation[conversation.length - 2],
        conversation[conversation.length - 1],
      ]
    }
    return [conversation[2 * page], conversation[2 * page + 1]]
  } catch (e) {
    handleError(e)
    return conversation
  }
}

function getNextPage({
  conversation,
  page,
  action,
}: {
  conversation: Conversation
  page: number | null
  action: 'next' | 'previous'
}) {
  try {
    if (!conversation.length) {
      return page
    }
    const maxPage = conversation.length / 2

    if (action === 'next') {
      // last page
      if (page === null) {
        return page
      }
      if (page === maxPage - 1) {
        return page
      }
      return page + 1
    }

    if (action === 'previous') {
      // last page
      if (page === null) {
        return maxPage - 2
      }
      // first page
      if (page === 0) {
        return page
      }
      return page - 1
    }

    return page
  } catch (e) {
    handleError(e)
    return page
  }
}

function PageToggle({
  currentPage,
  maxPage,
  handlePageToggle,
  handleClose,
}: {
  currentPage: number
  maxPage: number
  handlePageToggle: (action: 'next' | 'previous') => void
  handleClose: () => void
}) {
  if (maxPage <= 1) {
    return (
      <div className="cursor-pointer ml-auto" onClick={() => handleClose()}>
        <X className="w-6 h-6 shrink-0 stroke-[1.5px]" />
      </div>
    )
  }

  return (
    <div className="flex items-center shrink-0 ml-auto">
      <div className="flex gap-1 items-center">
        {currentPage === 1 ? (
          <div className="w-6 h-6"></div>
        ) : (
          <div
            onClick={() => {
              handlePageToggle('previous')
            }}
            className="w-fit cursor-pointer"
          >
            <ChevronLeft className="w-6 h-6 stroke-[1.5px]" />
          </div>
        )}

        <TypographyMuted>
          {currentPage} of {maxPage}
        </TypographyMuted>

        {currentPage === null || currentPage === maxPage ? (
          <div className="w-6 h-6"></div>
        ) : (
          <div
            onClick={() => {
              handlePageToggle('next')
            }}
            className="w-fit cursor-pointer"
          >
            <ChevronRight className="w-6 h-6 stroke-[1.5px]" />
          </div>
        )}
      </div>

      <div className="cursor-pointer ml-auto" onClick={() => handleClose()}>
        <X className="w-6 h-6 shrink-0 stroke-[1.5px]" />
      </div>
    </div>
  )
}

export function AskPanelTool({
  reportId,
  data,
  dossierDetail,
  addToDocument,
}: {
  reportId: string
  data: OutputData
  dossierDetail?: DossierDetail
  addToDocument: (systemMessage: SystemMessage) => void
}) {
  const { settings } = useContext(UserContext)

  const dispatch = useDispatch()
  const [message, setMessage] = useState('')
  const [page, setPage] = useState<number | null>(null)
  const [openedCitation, setOpenedCitation] = useState<Citation | null>(null)
  const [selectedSource, setSelectedSource] = useState<SourceDocument | null>(
    null
  )
  const [selectedExtractIndex, setSelectedExtractIndex] = useState<{
    [id: string]: number
  }>({})
  const [uniqueCitationDocuments, setUniqueCitationDocuments] = useState<
    SourceDocument[]
  >([])

  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const sourceContainerRef = useRef<HTMLDivElement>(null)
  const panelContainerRef = useRef<HTMLDivElement>(null)

  const conversationByReportId = useSelector(
    (state: RootState) => state.docGen.ask.byReportId
  )
  const conversation = useMemo(
    () => conversationByReportId[reportId]?.response?.conversation || [],
    [conversationByReportId, reportId]
  )
  const isFollowUp = conversation.length > 0

  const messagePair = getConversationPair({
    conversation: conversation,
    page,
  })
  const currentSystemMessage = messagePair.find(
    (m) => m.role === 'system'
  ) as SystemMessage

  const isSourceSelected =
    settings.assistant.sources.report.internalSearch ||
    settings.assistant.sources.report.webSearch ||
    settings.assistant.sources.report.outlookSearch ||
    settings.assistant.sources.report.teamsSearch

  const panelContainerWidth =
    panelContainerRef.current?.getBoundingClientRect().width || 0
  const panelContainerTop =
    panelContainerRef.current?.getBoundingClientRect().top || 0
  const panelContainerRight =
    panelContainerRef.current?.getBoundingClientRect().right || 0

  const openedCitationHighlights = useMemo(() => {
    return getCitationHighlights(openedCitation, selectedSource)
  }, [openedCitation, selectedSource])

  const openedCitationResource = useMemo(() => {
    return getCitationExtractResource(openedCitationHighlights, selectedSource)
  }, [selectedSource, openedCitationHighlights])

  function handlePageToggle(action: 'next' | 'previous') {
    const nextPage = getNextPage({
      conversation: conversation || [],
      page,
      action,
    })
    setPage(nextPage)
  }

  function handleClose() {
    setMessage('')
    dispatch(setToolComponent(null))
    setOpenedCitation(null)
    setSelectedSource(null)
    setUniqueCitationDocuments([])
  }

  const handleAsk = (message: string) => {
    const requestId = shortid()
    const reportContent = convertReportToString(data)
    setOpenedCitation(null)
    setSelectedSource(null)
    setUniqueCitationDocuments([])

    const fileIds = formatFocusedAnalysisFiles([
      ...(settings.assistant.sources.report.files.map((v) => v.id) || []),
      ...(settings.assistant.sources.report.companiesHouseFiles || []),
    ])

    const baseParams = {
      message,
      context: reportContent,
      reportId,
      focusedAnalysis: settings.assistant.sources.report.focusedAnalysis,
      fileIDs: settings.assistant.sources.report.focusedAnalysis ? fileIds : [],
    }
    if (isFollowUp) {
      dispatch(
        followUpAsk({
          requestId,
          timestamp: getTimestamp(),
          params: {
            conversationId:
              conversationByReportId[reportId].response.conversationId,
            ...baseParams,
            ...getAskTools(settings.assistant.sources.report),
          },
        })
      )
    } else {
      dispatch(
        newAsk({
          requestId,
          timestamp: getTimestamp(),
          params: {
            ...baseParams,
            ...getAskTools(settings.assistant.sources.report),
          },
        })
      )
    }
  }

  const maxPage = conversation.length / 2
  const currentPage = page === null ? maxPage : page + 1

  useEffect(() => {
    if (page === null) return
    if (page < maxPage) {
      setPage(null)
    }
  }, [conversation.length])

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '0px'
      const scrollHeight = textareaRef.current.scrollHeight
      textareaRef.current.style.height = scrollHeight + 'px'
    }
  }, [message])

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '24px'
    }
  }, [])

  const handleCitationSelection = (citation: Citation | null) => {
    const message = conversation
      .filter((v): v is SystemMessage => Boolean(v))
      .find((v) => {
        return v.data?.citations?.find((v) => {
          return (
            JSON.stringify({ ...citation, highlights: [] }) ===
            JSON.stringify({ ...v, highlights: [] })
          )
        })
      })

    const documents = sortSourceDocuments(
      getUniqueCitationDocuments(citation, message?.data.documents || []),
      citation || undefined
    )
    setUniqueCitationDocuments(documents)
    setOpenedCitation(citation)
    if (documents.length > 0) {
      setSelectedSource(documents[0])
    } else {
      setSelectedSource(null)
    }
  }

  const handleSourceClick = (source: SourceDocument) => {
    setSelectedSource(source)
  }

  return (
    <div
      className={'flex w-full'}
      onKeyDown={(evt) => {
        if (evt.code === 'Escape') {
          handleClose()
        }
      }}
    >
      <div ref={panelContainerRef} className="w-full">
        <div className="flex flex-col bg-system-surface border border-system-border-regular rounded-lg max-h-[calc(100vh-5rem-2rem-3rem-8rem)] px-3 py-3.5 pl-4 w-full">
          <div className={`overflow-y-auto`}>
            {messagePair.map((chatMessage, idx) => {
              try {
                if (chatMessage.role === 'user') {
                  return (
                    <div
                      key={`user_${idx}`}
                      className="flex gap-2 items-start w-full"
                    >
                      <UserChatMessage message={chatMessage} compact={true} />

                      <div className="mt-0.5 shrink-0 ml-auto">
                        <PageToggle
                          currentPage={currentPage}
                          maxPage={maxPage}
                          handlePageToggle={(action) => {
                            handlePageToggle(action)
                          }}
                          handleClose={handleClose}
                        />
                      </div>
                    </div>
                  )
                }
                if (chatMessage.role == 'system') {
                  const question = (conversation[idx - 1] as UserMessage).query
                  return (
                    <SystemChatMessage
                      key={`system_${idx}`}
                      message={chatMessage}
                      compact={true}
                      onFollowUpQuestionClick={(v) => handleAsk(v)}
                      onRetry={() => {
                        const userMessage = conversation[idx - 1] as UserMessage

                        if (userMessage) {
                          handleAsk(userMessage.query)
                        }
                      }}
                      canRetry={conversation.length - 1 === idx}
                      showFollowUpQuestions={true}
                      question={question}
                      sourceType="report"
                      openedCitation={openedCitation}
                      onCitationOpen={handleCitationSelection}
                    />
                  )
                }
                return <></>
              } catch (e) {
                handleError(e)
                return <></>
              }
            })}
          </div>

          {messagePair.length > 0 && <Divider />}

          <div
            className={`flex flex-col gap-4 w-full items-start ${messagePair.length > 0 ? 'mt-4' : ''}`}
          >
            <div className="flex gap-4 w-full border-b border-system-border-light pb-4">
              <MessageCircleQuestionIcon className="w-6 h-6 shrink-0 stroke-[1.5px] mt-0.5" />
              <textarea
                className="max-h-[20rem] h-fit !w-full font-body placeholder:!text-system-placeholder !resize-none focus:outline-none bg-transparent mt-0.5"
                ref={textareaRef}
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && e.shiftKey !== true) {
                    e.preventDefault()

                    if (!isSourceSelected) return
                    handleAsk(message)
                    setMessage('')
                  }
                }}
                placeholder="What do you need to know?"
                autoFocus
              />
            </div>
            <div className="w-full">
              <SourceSelector
                dossierDetail={dossierDetail}
                sourceType="report"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <Button
            variant={'secondary'}
            className={`mt-2 ${currentSystemMessage?.data?.isFinished !== true ? 'opacity-0' : 'opacity-1'}`}
            size={'sm'}
            onClick={() => {
              if (currentSystemMessage) {
                addToDocument(currentSystemMessage)
                handleClose()
              }
            }}
            disabled={currentSystemMessage?.data?.isFinished !== true}
          >
            Add to report
          </Button>
        </div>
      </div>

      <div
        ref={sourceContainerRef}
        className={`${uniqueCitationDocuments.length > 0 || selectedSource ? 'w-0' : 'w-0'} relative transition-width ease-in-out duration-300`}
      >
        <div
          className={`absolute top-0 w-[31.25rem] border border-system-border-regular px-4 py-3 rounded-lg backdrop-blur-[55px] laptop:w-[40rem] max-w-[calc(100vw-15rem-44.375rem+3rem)] overflow-y-auto ease-in-out duration-300 ${selectedSource || uniqueCitationDocuments.length === 0 ? 'hidden' : ''}`}
          style={{
            right: `${panelContainerWidth + 12}px`,
            maxHeight: `${window.innerHeight - panelContainerTop - 24}px`,
          }}
        >
          <Sources
            documents={uniqueCitationDocuments}
            showTabs={false}
            onSourceClick={handleSourceClick}
            previewable={true}
          />
        </div>

        {selectedSource && openedCitationResource && (
          <div
            className="fixed w-[31.25rem] laptop:w-[40rem]"
            style={{
              right: `${panelContainerWidth + 12 + window.innerWidth - panelContainerRight}px`,
              maxHeight: `${window.innerHeight - panelContainerTop - 24}px`,
              top: `${panelContainerTop}px`,
            }}
          >
            <DocumentPreviewContainer
              key={`document-preview-container-${selectedSource.document_id}`}
              type={DocumentPreviewType.REPORT}
              resource={openedCitationResource}
              selectedExtractIndex={
                selectedExtractIndex[selectedSource.document_id] || 0
              }
              setSelectedExtractIndex={(index) => {
                setSelectedExtractIndex({
                  ...selectedExtractIndex,
                  [selectedSource.document_id]: index,
                })
              }}
              sources={uniqueCitationDocuments}
              selectedSource={selectedSource}
              initialWidth={
                window.innerWidth > 1280 ? 640 - 32 - 48 : 500 - 32 - 48
              }
              onClose={() => {
                setSelectedSource(null)
                setOpenedCitation(null)
                setUniqueCitationDocuments([])
              }}
              setSelectedSource={(v) => setSelectedSource(v)}
            />
          </div>
        )}
      </div>
    </div>
  )
}
