import { HelpGuides } from '@/types/types'

export const Guides: HelpGuides = {
  heading: 'Set up integrations in Desia',
  subHeading:
    'To begin the integration, your M365 admin must complete tasks 1-3 first.\nOnce those are done, each individual team member will need to enable Teams and Outlook settings (tasks 4-5) on their own account.',
  guides: {
    microsoftIntegration: {
      title: 'Set up Microsoft integration (Admin)',
      videoUrls: [
        'https://storage.googleapis.com/desia-public-assets/Desia%20-%20Microsoft%20integration.mp4',
      ],
      content: [
        {
          steps: [
            'Click the 3 dots at the top-right of the Home page next to your user-name',
            'Click Integrations and then select Microsoft 365.',
            'Follow the prompts to log in with the Microsoft admin account.',
            'Accept the Permission Request.',
          ],
        },
      ],
    },
    oneDriveIntegration: {
      title: 'Add OneDrive files/folders (Admin)',
      videoUrls: [
        'https://storage.googleapis.com/desia-public-assets/Desia%20-%20OneDrive%20Folder%20Integration.mov',
      ],
      content: [
        {
          outline: 'Connect the folders',
          steps: [
            'Click the 3 dots at the top-right of the Home page next to your user-name',
            'Click Integrations and then select Microsoft 365.',
            'Click Add files beside OneDrive connected files.',
            'Copy the folder links directly from your OneDrive and paste them in the dedicated box. (Simply use your OneDrive account name e.g. name@onmicrosoft365.com to add all files, or select the file or folder you wish to connect, then create and copy the share link).',
            'Click Add Items.',
            'You can always add more files/folders or remove them at any point in time.',
          ],
        },
        {
          outline: 'Confirm available folders',
          steps: [
            'From any page, click Library from the top banner',
            'When in Library, in Show files from select OneDrive to verify the successful integration',
            'OneDrive has a sync time of approximately ~5 minutes so new files added to your OneDrive folder will show after few minutes',
          ],
        },
      ],
    },
    sharePointIntegration: {
      title: 'Add SharePoint files/folders (Admin)',
      videoUrls: [
        'https://storage.googleapis.com/desia-public-assets/Desia%20-%20Sharepoint%20Folder%20Integration.mov',
      ],
      content: [
        {
          outline: 'Connect the folders',
          steps: [
            'Click the 3 dots at the top-right of the Home page next to your user-name',
            'Click Integrations and then select Microsoft 365.',
            'Click Add files beside SharePoint connected files.',
            'Copy the folder links directly from your SharePoint and paste them in the dedicated box. (Simply use your SharePoint account name e.g. name@onmicrosoft365.com to add all files, or select the file or folder you wish to connect, then create and copy the share link).',
            'Click Add Items.',
            'You can always add more files/folders or remove them at any point in time.',
          ],
        },
        {
          outline: 'Confirm available folders',
          steps: [
            'From any page, click Library from the top banner',
            'When in Library, in Show files from select SharePoint to verify the successful integration',
            'SharePoint has a sync time of approximately ~5 minutes so new files added to your SharePoint folder will show after few minutes',
          ],
        },
      ],
    },
    outlookIntegration: {
      title: 'Enable Outlook (User)',
      videoUrls: [
        'https://storage.googleapis.com/desia-public-assets/Desia%20-%20Outlook%20connection.mov',
      ],
      content: [
        {
          steps: [
            'Click the 3 dots at the top-right of the Home page next to your user-name and then press Settings.',
            'On the left bar of the pop-up window, select Connected apps and then press Connect on the right.',
            'Select Proceed.',
            'A new black window should quickly appear and disappear and then Outlook should show as Connected.',
            'When in Ask Desia, you should now see a new source type called Communications with the option to select Outlook as source for Ask questions',
          ],
        },
      ],
    },
    teamsIntegration: {
      title: 'Enable Teams (User)',
      videoUrls: [
        'https://storage.googleapis.com/desia-public-assets/Desia%20-%20Teams%20connection.mov',
      ],
      content: [
        {
          steps: [
            'Click the 3 dots at the top-right of the Home page next to your user-name and then press Settings.',
            'On the left bar of the pop-up window, select Connected apps and then press Connect on the right.',
            'Select Proceed.',
            'A new black window should quickly appear and disappear and then Teams should show as Connected.',
            'When in Ask Desia, you should now see a new source type called Communications with the option to select Teams as source for Ask questions',
          ],
        },
      ],
    },
  },
} as const
