import InfoCircleFill from "@/assets/InfoCircleFill";
import { Alert } from "./ui/alert";
import { TypographyBody } from "./ui/Typography";
import CheckmarkCircleFill from "@/assets/CheckmarkCircleFill";
import InfoTriangleFill from "@/assets/InfoTriangleFill";
import CloseCircleFill from "@/assets/CloseCircleFill";
import { Button } from "./ui/button";
import { X } from "lucide-react";
import { ReactNode } from "react";

export interface CustomAlertProps {
    title?: string,
    description?: string,
    buttonLabel?: string,
    variant?: "info" | "success" | "warning" | "error",
    dismissable?: boolean,
    descriptionComponent?: ReactNode,
    onClick?: () => void,
    onCloseClick?: () => void,
    onButtonClick?: () => void, className?: string
    toastId?: string
}

export function CustomAlert(props: CustomAlertProps) {
    const variant = props.variant || 'info'
    const style = `${props.className} w-full`
    const icon = () => {
        switch (props.variant) {
            case "info": return <InfoCircleFill className="w-6 h-6 shrink-0" />
            case "success": return <CheckmarkCircleFill className="w-6 h-6 shrink-0" />
            case "warning": return <InfoTriangleFill className="w-6 h-6 shrink-0" />
            case "error": return <CloseCircleFill className="w-6 h-6 shrink-0" />
            default: return <InfoCircleFill className="w-6 h-6 shrink-0" />
        }
    }
    return (
        <Alert variant={variant} className={style} onClick={() => props.onClick?.()}>
            <div className="flex gap-3 item">
                <div className="mt-0.5">
                    {icon()}
                </div>
                <div className="flex flex-col gap-2 flex-grow">
                    <div className={`flex ${(props.dismissable && !props.title) ? 'flex-row gap-3 items-center' : 'flex-col gap-1'}`}>
                        {props.title && (
                            <TypographyBody isStrong={true} className="overflow-hidden text-ellipsis text-system-primary">
                                {props.title}
                            </TypographyBody>
                        )}
                        {props.descriptionComponent || props.description && (
                            <TypographyBody className={`whitespace-pre-wrap ${props.title ? "text-system-body" : "text-system-primary"} overflow-hidden text-ellipsis`}>
                                {props.description}
                            </TypographyBody>
                        )}
                        {props.buttonLabel && (
                            <Button variant='inline' onClick={() => props.onButtonClick?.()}>
                                {props.buttonLabel}
                            </Button>
                        )}
                    </div>
                </div>

                {props.dismissable && (
                    <div className="cursor-pointer mt-0.5" onClick={() => props.onCloseClick?.()}>
                        <X className="w-6 h-6 stroke-interactive" />
                    </div>
                )}
            </div>
        </Alert>
    )
}