import {
  compareDesc,
  differenceInCalendarWeeks,
  format,
  formatDate,
  getMonth,
  getWeek,
  getYear,
  isValid,
  parse,
  parseISO,
} from 'date-fns'
import { Dispatch, SetStateAction } from 'react'
import {
  LOGIN_PATH,
  USER_FEATURE_FLAG_PREFIX,
  USER_SETTINGS_KEY,
  WEB_SERVER_ENDPOINT,
  LocalStorageKey,
  THEME_MIN_AMOUNT,
  THEME_MAX_AMOUNT,
} from '../constants'
import {
  ExpertModeDocument,
  HasDateProperty,
  FileStructure,
  ResAppUser,
  ResponseDocument,
  SourceDocument,
  UserFeatureFlag,
  UserSettings,
  SearchQueryExtract,
  TemplateSection,
  TemplateSubsection,
  Citation,
  IntegrationCode,
  DocgenSession,
  DocgenSessionStatus,
  DocgenStep,
  ResponseDocGenReport,
  DESIA_EVENT,
  SourceDocumentMetadata,
  SourceDocumentType,
  DatabaseConnector,
  DedupedSearchQueryItem,
  Theme,
  ToolEvent,
} from '../types/types'
import { NavigateFunction } from 'react-router-dom'
import { IFeatureFlagContext } from '@/contexts/FeatureFlagContext'
import { handleError } from './handleError'
import { filterDocumentsByCited, getGlobalUniqueDocuments } from './components'
import { tertiaryStyle } from '@/components/ui/button'
import { currencies } from '@/currencies'
import { getChartColor } from '@/components/Charts/utils'

export function getTimestamp(d?: string) {
  if (d) {
    return new Date(d).getTime()
  }
  return new Date().getTime()
}

export function shortString(str: string, maxLen: number) {
  if (str.length > maxLen) {
    return str.substring(0, maxLen - 3) + '...'
  }
  return str
}

export function getMessageId({
  conversationId,
  created_at,
  requestId,
}: {
  conversationId?: string
  created_at: number
  requestId?: string
}) {
  return `${conversationId || requestId}_${created_at}`
}

export function getFileLinkV2(file: ResponseDocument) {
  if (file.document_source === 'user_upload') {
    if (file.document_storage_class === 'gcs') {
      // signed url
      return `${WEB_SERVER_ENDPOINT}/api/document/download/${file.document_id}/`
    }
    if (file.document_storage_class === 'local') {
      // old file links
      // will be deprecated / removed in future
      return `${WEB_SERVER_ENDPOINT}/api/document/content/${file.document_id}/${file.document_name}`
    }
    console.error('Unknown file storage class given user_upload', file)
  }
  if (file.document_source === 'integration') {
    // link to external file e.g. sharepoint
    return file.document_secure_shared_link
  }
  console.error('Unknown file storage class', file)
}

export function timeDifference(start: Date, finish: Date) {
  const t1 = start.getTime()
  const t2 = finish.getTime()
  return t2 - t1
}

export function exceededDuration(
  start: Date,
  finish: Date,
  maxDuration: number
) {
  const td = timeDifference(start, finish)
  return td > maxDuration
}

export function checkWebLink(url: string | undefined) {
  const isWebLink = url?.includes('http')
  return isWebLink
}

export function getFileId(contentId: string | undefined) {
  if (contentId === undefined) return ''
  if (
    contentId.startsWith('web') ||
    contentId.startsWith('microsoft') ||
    contentId.startsWith('financial_data') ||
    contentId.startsWith('company_house')
  ) {
    return contentId
  }

  const [fileId] = contentId.split('_')
  return fileId
}

function checkIntegrationLink({
  documentSource,
  documentLink,
}: {
  documentSource?: string
  documentLink?: string
}) {
  // for newer documents, document_source is set at ingestion time
  // for older documents, document_source is not set so we infer from document_link
  if (!documentSource) {
    return (
      documentLink?.includes('sharepoint.com') ||
      documentLink?.includes('office.com')
    )
  }
  return documentSource === 'integration'
}

export function handleOpenLink({
  id,
  url,
  title,
  documentLink,
  documentSource,
  centralStoragePath,
  window,
}: {
  id: string
  url: string
  title: string
  documentLink?: string
  documentSource?: string
  centralStoragePath?: string
  window: Window
}) {
  console.log('handleOpenLink', {
    id,
    url,
    title,
    documentLink,
    documentSource,
    centralStoragePath,
  })
  const isIntegrationLink = checkIntegrationLink({
    documentLink,
    documentSource,
  })
  if (isIntegrationLink) {
    window.open(documentLink, '_blank')
    return
  }

  const isWebLink = checkWebLink(url)
  // url points to either a web link or a file
  if (isWebLink && !centralStoragePath) {
    window.open(url, '_blank')
    return
  }

  const fileId = getFileId(id)
  const fileUrl = `${WEB_SERVER_ENDPOINT}/api/document/download/${fileId}/${centralStoragePath ? `?use_central_storage=true&central_storage_path=${centralStoragePath}` : ''}`
  window.open(fileUrl, '_blank')
}

export function handleLogin() {
  window.location.href = `${WEB_SERVER_ENDPOINT}${LOGIN_PATH}`
}

export function handleLogout(navigate: NavigateFunction) {
  navigate('/signout')
}

export function plural(text: string, count: number) {
  if (count === 1) return text
  return `${text}s`
}

export function getIdentifierKey(requestId: string) {
  return `request_id__${requestId}`
}

/**
 * Store mapping between requestId <> conversationId
 * to allow transition from new question (page refresh) temp urls
 * to perma urls
 */
export function saveIdentifier(
  requestId: string,
  conversationId: string,
  timestamp: number
) {
  if (!requestId.startsWith('new_ask')) return
  try {
    const identifiers = {
      key: getIdentifierKey(requestId),
      value: JSON.stringify({
        requestId: requestId,
        conversationId: conversationId,
        timestamp: timestamp,
      }),
    }
    localStorage.setItem(identifiers.key, identifiers.value)
  } catch (e) {
    handleError(e)
  }
}

export function clearIdentifier(requestId: string) {
  try {
    const key = getIdentifierKey(requestId)
    localStorage.removeItem(key)
  } catch (e) {
    handleError(e)
  }
}

export function getIdentifier(requestId: string) {
  try {
    const key = getIdentifierKey(requestId)
    const identifiers = localStorage.getItem(key)
    if (identifiers) {
      const data = JSON.parse(identifiers)
      return data
    }
  } catch (e) {
    handleError(e)
  }
}

export function normaliseDocumentId(id: string) {
  if (id.includes('web') || id.startsWith('financial_data')) {
    return {
      isWeb: true,
      isFile: false,
      id: id,
    }
  }

  const fileId = getFileId(id)
  return {
    isWeb: false,
    isFile: true,
    id: fileId,
  }
}

function getUserFlagKey(flag: UserFeatureFlag) {
  return `${USER_FEATURE_FLAG_PREFIX}_${flag}`
}

export function checkUserFlag(flag: UserFeatureFlag) {
  try {
    const key = getUserFlagKey(flag)
    const result = localStorage.getItem(key)
    if (result === 'true') {
      return true
    }
    return false
  } catch (e) {
    handleError(e)
    return false
  }
}

export function checkIfUserFlagExists(flag: UserFeatureFlag) {
  try {
    const key = getUserFlagKey(flag)
    const result = localStorage.getItem(key)
    if (result) {
      return true
    }
    return false
  } catch (e) {
    handleError(e)
    return false
  }
}

export function setUserFlag(flag: UserFeatureFlag, isEnabled: boolean) {
  try {
    const key = getUserFlagKey(flag)
    localStorage.setItem(key, `${isEnabled}`)
  } catch (e) {
    handleError(e)
  }
}

// todo: move server side when we want to persist beyond single device
export function saveUserSettings(s: UserSettings) {
  try {
    const str = JSON.stringify(s)
    sessionStorage.setItem(USER_SETTINGS_KEY, str)
  } catch (e) {
    handleError(e)
    return false
  }
}

export function getUserSettings() {
  try {
    const value = sessionStorage.getItem(USER_SETTINGS_KEY)
    if (!value) return null
    return JSON.parse(value)
  } catch (e) {
    handleError(e)
    return false
  }
}

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined
}

export function getIconSrc(domain: string, size?: number) {
  return `https://www.google.com/s2/favicons?domain=${domain}&sz=${size || 256}`
}

export function mapPlannerDocumentToSourceDocument(
  pd: ExpertModeDocument
): SourceDocument {
  return {
    document_id: pd.id,
    doc_metadata: pd.doc_metadata,
    title: pd.title,
    url: pd.url,
    text: pd.snippet, // todo: snippet or title?
  }
}

export function checkPathMatch(location: string, menuItemPath: string) {
  if (menuItemPath.toLowerCase().includes(location.toLocaleLowerCase())) {
    return true
  }
  return false
}

export function capitalise(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export function getLocale() {
  return 'en-GB'
}

export function checkDesiaUser(user: ResAppUser | null) {
  if (!user) return false
  try {
    const email = user.email
    if (email.endsWith('@desia.ai')) {
      return true
    }
    return false
  } catch (e) {
    handleError(e)
    return false
  }
}

export function friendlyOrgName(orgId: string | undefined = '') {
  try {
    const [_, name] = orgId.split('__')

    if (name) return capitalise(name)
    return orgId
  } catch (e) {
    handleError(e)
    return orgId
  }
}

// implementation from: https://phuoc.ng/collection/html-dom/get-or-set-the-cursor-position-in-a-content-editable-element/
// had some issues with the caret position

export function getCaretPosition(node: Node) {
  const selection = window.getSelection()
  if (selection && selection.rangeCount > 0) {
    const range = selection.getRangeAt(0)
    const preCaretRange = range.cloneRange()
    preCaretRange.selectNodeContents(node)
    preCaretRange.setEnd(range.endContainer, range.endOffset)
    const rangeText = preCaretRange.toString()
    let brCount = (rangeText.match(/\n/g) || []).length

    return preCaretRange.toString().length + brCount
  }
  return 0
}

const createRange = (targetPosition: number, node: Node) => {
  let range = document.createRange()
  range.selectNode(node)
  range.setStart(node, 0)

  let pos = 0
  const stack = [node]
  while (stack.length > 0) {
    const current = stack.pop() as Node

    if (current?.nodeType === Node.TEXT_NODE) {
      const len = current.textContent?.length ?? 0
      if (pos + len >= targetPosition) {
        range.setEnd(current, targetPosition - pos)
        range.collapse()
        return range
      }
      pos += len
    } else if (current.childNodes && current.childNodes.length > 0) {
      for (let i = current.childNodes.length - 1; i >= 0; i--) {
        stack.push(current.childNodes[i])
      }
    }
  }

  range.setEnd(node, node.childNodes.length)
  return range
}

export function setCaretPosition(position: number, node: Node) {
  const range = createRange(position, node)
  const selection = window.getSelection()
  selection?.removeAllRanges()
  selection?.addRange(range)
}

// modified from this: https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export function formatBytes(bytes: number) {
  if (bytes === 0 || bytes < 0) return '0 Bytes'

  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  const formattedValue = parseFloat((bytes / Math.pow(k, i)).toFixed(2))

  return `${formattedValue} ${sizes[i]}`
}

export const getIntegrationName = (integration_code_name: string): string => {
  switch (integration_code_name) {
    case IntegrationCode.ONEDRIVE:
      return 'OneDrive'
    case IntegrationCode.SHAREPOINT:
      return 'SharePoint'
    case IntegrationCode.TEAMS:
      return 'Teams'
    case IntegrationCode.OUTLOOK:
      return 'Outlook'
    default:
      return 'Integration'
  }
}

export const getHostname = (url: string) => {
  try {
    const { hostname } = new URL(url)
    return hostname
  } catch (e) {
    return ''
  }
}

export const getDomain = (hostname: string) => {
  const replacedDomain = hostname.replaceAll('www.', '')
  return replacedDomain
}

export const groupItemsPerWeek = <T extends Record<string, any>>(
  items: T[],
  dateKey: HasDateProperty<T>
): { year: number; weekNumber: number; items: T[] }[] => {
  const datesByWeek: { [key: string]: T[] } = items.reduce(
    (acc, item) => {
      const dateValue = item[dateKey] as string | number | null
      const parsedDate =
        typeof dateValue === 'number'
          ? new Date(dateValue)
          : parseISO(dateValue || '')
      const year = getYear(parsedDate)
      const weekNumber = getWeek(parsedDate)
      const yearWeekKey = `${year}-${weekNumber}`
      if (!acc[yearWeekKey]) {
        acc[yearWeekKey] = []
      }
      acc[yearWeekKey].push(item)
      return acc
    },
    {} as { [key: string]: T[] }
  )

  Object.keys(datesByWeek).forEach((yearWeekKey) => {
    datesByWeek[yearWeekKey].sort((a, b) =>
      compareDesc(
        typeof a[dateKey] === 'number'
          ? new Date(a[dateKey])
          : parseISO(a[dateKey]),
        typeof b[dateKey] === 'number'
          ? new Date(b[dateKey])
          : parseISO(b[dateKey])
      )
    )
  })

  const sortedWeeks: { year: number; weekNumber: number; items: T[] }[] =
    Object.keys(datesByWeek)
      .map((yearWeekKey) => {
        const [year, weekNumber] = yearWeekKey.split('-').map(Number)
        return {
          year,
          weekNumber,
          items: datesByWeek[yearWeekKey],
        }
      })
      .sort((a, b) => {
        if (b.year === a.year) {
          return b.weekNumber - a.weekNumber
        }
        return b.year - a.year
      })

  return sortedWeeks
}

export const groupItemsPerMonth = <T extends Record<string, any>>(
  items: T[],
  dateKey: HasDateProperty<T>
): { year: number; monthNumber: number; items: T[] }[] => {
  const datesByMonth: { [key: string]: T[] } = items.reduce(
    (acc, item) => {
      const dateValue = item[dateKey] as string | number | null
      const parsedDate =
        typeof dateValue === 'number'
          ? new Date(dateValue)
          : parseISO(dateValue || '')
      const year = getYear(parsedDate)
      const monthNumber = getMonth(parsedDate) // 0-based month index
      const yearMonthKey = `${year}-${monthNumber}`
      if (!acc[yearMonthKey]) {
        acc[yearMonthKey] = []
      }
      acc[yearMonthKey].push(item)
      return acc
    },
    {} as { [key: string]: T[] }
  )

  Object.keys(datesByMonth).forEach((yearMonthKey) => {
    datesByMonth[yearMonthKey].sort((a, b) =>
      compareDesc(
        typeof a[dateKey] === 'number'
          ? new Date(a[dateKey])
          : parseISO(a[dateKey]),
        typeof b[dateKey] === 'number'
          ? new Date(b[dateKey])
          : parseISO(b[dateKey])
      )
    )
  })

  const sortedMonths: { year: number; monthNumber: number; items: T[] }[] =
    Object.keys(datesByMonth)
      .map((yearMonthKey) => {
        const [year, monthNumber] = yearMonthKey.split('-').map(Number)
        return {
          year,
          monthNumber,
          items: datesByMonth[yearMonthKey],
        }
      })
      .sort((a, b) => {
        if (b.year === a.year) {
          return b.monthNumber - a.monthNumber
        }
        return b.year - a.year
      })

  return sortedMonths
}

export const groupItemsPerMonthAndWeek = <T extends Record<string, any>>(
  items: T[],
  dateKey: HasDateProperty<T>
): {
  year?: number
  weekNumber?: number
  monthNumber?: number
  items: T[]
}[] => {
  const sortedByMonth = groupItemsPerMonth(items, dateKey)

  const firstMonth = sortedByMonth[0]

  const firstMonthByWeeks = groupItemsPerWeek(firstMonth?.items || [], dateKey)

  return [...firstMonthByWeeks, ...sortedByMonth.slice(1)]
}

export function getDirectoryFromDocument(
  d: ResponseDocument,
  organizationName: string
) {
  if (d.document_is_part_of_desia_library) {
    return 'Desia library'
  }

  if (d.document_source === 'integration') {
    return getIntegrationName(
      d.document_source_details?.integration_code_name || ''
    )
  }

  if (d.document_visibility === 'private') {
    return 'Private'
  }

  return organizationName
}

export function getCreatedAt(d: ResponseDocument | undefined): Date | null {
  // manual upload: d.created_at_desia
  // uploaded via integration: d?.document_source_details?.integration_created_at
  // fallback (document created at) d.document_created_at
  const date =
    d?.created_at_desia ||
    d?.document_source_details?.integration_created_at ||
    d?.document_created_at
  if (!date) return null
  return new Date(date)
}

export function getUpdatedAt(d: ResponseDocument | undefined): Date | null {
  const date = d?.document_updated_at || d?.updated_at_desia
  if (!date) return null
  return new Date(date)
}

export const convertUUIDToNumber = (uuid: string) => {
  const truncated = uuid.split('-')[0]
  return parseInt(truncated, 16)
}

export const toggleElement = (
  element: FileStructure,
  elements: FileStructure[],
  setElements?: Dispatch<SetStateAction<FileStructure[]>>
) => {
  const allSelected = element.is_included && !element.is_excluded
  const partialSelected = element.child_is_included

  const updatedElements = elements.map((v) => {
    let updatedElement = v
    let updatedChildElement = v

    if (allSelected) {
      updatedElement = {
        ...v,
        is_included: false,
        child_is_included: false,
        is_excluded: true,
        child_is_excluded: false,
      }
      updatedChildElement = {
        ...v,
        is_included: false,
        child_is_included: false,
        is_excluded: true,
        child_is_excluded: false,
      }
    } else if (partialSelected) {
      updatedElement = {
        ...v,
        is_included: true,
        child_is_included: false,
        is_excluded: false,
        child_is_excluded: false,
      }
      updatedChildElement = {
        ...v,
        is_included: true,
        child_is_included: false,
        is_excluded: false,
        child_is_excluded: false,
      }
    } else {
      updatedElement = {
        ...v,
        is_included: true,
        child_is_included: false,
        is_excluded: false,
        child_is_excluded: false,
      }
      updatedChildElement = {
        ...v,
        is_included: true,
        child_is_included: false,
        is_excluded: false,
        child_is_excluded: false,
      }
    }

    if (
      element.children_element_internal_ids?.includes(v.internal_element_id)
    ) {
      return updatedChildElement
    } else if (element.internal_element_id === v.internal_element_id) {
      return updatedElement
    }

    return v
  })

  setElements?.(() => updatedElements)

  updateParentElements(element, elements, setElements)
}

export const updateParentElements = (
  element: FileStructure,
  elements: FileStructure[],
  setElements?: Dispatch<SetStateAction<FileStructure[]>>
) => {
  const parentElement = elements.find(
    (v) => v.internal_element_id === element.parent_element_internal_ids.at(-1)
  )

  if (!parentElement) return

  setElements?.((elements) => {
    const level = elements.filter(
      (v) =>
        v.parent_element_internal_ids.at(-1) ===
        element.parent_element_internal_ids.at(-1)
    )

    let updatedParentElement = elements.find(
      (v) => v.internal_element_id === parentElement?.internal_element_id
    )

    if (!updatedParentElement) return []

    if (level.every((v) => v.is_included)) {
      updatedParentElement = {
        ...updatedParentElement,
        is_included: true,
        child_is_included: false,
        is_excluded: false,
        child_is_excluded: false,
      }
    } else if (level.some((v) => v.is_included || v.child_is_included)) {
      updatedParentElement = {
        ...updatedParentElement,
        is_included: false,
        child_is_included: true,
        is_excluded: false,
        child_is_excluded: true,
      }
    } else {
      updatedParentElement = {
        ...updatedParentElement,
        is_included: false,
        child_is_included: false,
        is_excluded: false,
        child_is_excluded: false,
      }
    }

    return elements.map((v) =>
      v.internal_element_id === updatedParentElement.internal_element_id
        ? updatedParentElement
        : v
    )
  })

  updateParentElements(parentElement, elements, setElements)
}

export const checkIntegrationFlag = (
  ff: IFeatureFlagContext,
  user: ResAppUser | null
) => {
  const isAdmin = user?.user_roles.includes('app_admin')
  return (
    (ff.checkFlag('integration: manage') &&
      user?.app_metadata.organization_id === 'org__desia') ||
    isAdmin
  )
}

export const formatNumberString = (v: string) => {
  const currencyValues = Object.values(currencies)
  const currencyCodes = Object.keys(currencies)
  const single = currencyValues.map((v) => v.majorSingle.toLowerCase())
  const plural = currencyValues.map((v) => v.majorPlural.toLowerCase())

  const currencyCodesPattern = `\\b(${[...currencyCodes, ...single, ...plural].join('|')})\\b`

  const regex = new RegExp(`${currencyCodesPattern}`, 'gi')
  const replacedText = v
    .replaceAll(regex, '')
    .replaceAll(
      /[,.%()*\-+\n]|[\p{Sc}]|thousand|million|billion|trillion|to|tn|bn|mln|t/giu,
      ''
    )
    .replaceAll(/\s+/g, '')
    .replaceAll(/k|m|b|t/giu, '')
    .trim()

  return replacedText
}

export const checkIfStringHasContent = (string: string) => {
  return /\S/.test(string)
}

export const removeDelimiterFromString = (string: string) => {
  if (string.includes(',')) {
    return `"${string}"`
  }

  return string
}

export const highlightWordFromString = (
  string: string,
  word: string,
  matchFullWords: boolean = true
) => {
  if (!word) return string

  const emPattern = /<em>(.*?)<\/em>/g
  const emMatches: string[] = []
  let replacedString = string.replace(emPattern, (match) => {
    emMatches.push(match)
    return `__EM__${emMatches.length - 1}__`
  })

  const escapedWord = word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

  const regex = matchFullWords
    ? new RegExp(`\\b(${escapedWord})\\b`, 'gi')
    : new RegExp(`(${escapedWord})`, 'gi')

  replacedString = replacedString.replace(regex, (match) => {
    return `<em>${match}</em>`
  })

  replacedString = replacedString.replace(/__EM__(\d+)__/g, (_, index) => {
    return emMatches[parseInt(index, 10)]
  })

  replacedString = replacedString.replaceAll('</em> <em>', '</em>&nbsp;<em>')

  return replacedString
}

export function getUniqueDocumentIds(inputIds: string[]) {
  try {
    const documentIds = inputIds.map((d) => {
      if (d.startsWith('web-search') || d.startsWith('microsoft')) {
        return d
      }
      const [id] = d.split('_')
      return id
    })
    const uniqueDocumentIds = Array.from(new Set(documentIds))
    return uniqueDocumentIds
  } catch (e) {
    handleError(e)
    return []
  }
}

// DocGenCitations are converted to Citation first
export const mapSourceHighlights = (
  citation: Citation | null,
  highlights: {
    file_id: string
    highlight: string
    score?: number
    ranking_score?: number
    retrieval_score?: number
    page_number?: number | null
  }[]
) => {
  return highlights.map((v) => {
    return {
      text: citation?.text || '',
      highlight: {
        'highlight-3': v.highlight,
      },
      page_number: v.page_number,
    } as SearchQueryExtract
  })
}

export const filterDuplicateHighlights = (documents: SourceDocument[]) => {
  const text = new Set()

  const filteredDocs = documents.filter((v) => {
    if (!v.text) return true
    if (text.has(v.text)) {
      return false
    } else {
      text.add(v.text)
      return true
    }
  })

  return filteredDocs
}

export const convertToBullets = (input: string) => {
  return input.replace(/^-\s/gm, '• ')
}

export const convertToHyphens = (input: string) => {
  return input.replace(/^•\s/gm, '- ')
}

export const removeInitialHyphens = (input: string) => {
  return input.replace(/^-\s/gm, '')
}

export const convertTemplateTitleToName = (text: string) => {
  return text.toLowerCase().replaceAll(' ', '_')
}

export const getAllSectionTitles = (
  sections: TemplateSection[] | TemplateSubsection[]
): string[] => {
  return sections.reduce((acc: string[], item) => {
    const titles: string[] = [item.title]
    if (item.subsections && item.subsections.length > 0) {
      titles.push(...getAllSectionTitles(item.subsections))
    }
    return acc.concat(titles)
  }, [])
}

export const countAllSections = (
  sections: TemplateSection[] | TemplateSubsection[]
): number => {
  return sections.reduce((acc: number, item) => {
    let count = 1
    if (item.subsections && item.subsections.length > 0) {
      count += countAllSections(item.subsections)
    }
    return acc + count
  }, 0)
}

export const convertThemesToString = (themes: string[]) => {
  return themes.map((v) => `• ${v}`).join('\n')
}

export const formatWeekLabel = (year: number, weekNumber: number): string => {
  const today = new Date()
  const currentYear = getYear(today)
  const currentWeek = getWeek(today)

  const thisWeekStartDate = new Date(currentYear, 0, 1 + (currentWeek - 1) * 7)
  const targetWeekStartDate = new Date(year, 0, 1 + (weekNumber - 1) * 7)

  const weekDifference = differenceInCalendarWeeks(
    thisWeekStartDate,
    targetWeekStartDate
  )

  if (weekDifference === 0) {
    return 'This week'
  } else if (weekDifference === 1) {
    return 'Last week'
  } else if (weekDifference === -1) {
    return 'Next week'
  } else {
    return `${Math.abs(weekDifference)} weeks ago`
  }
}

export const getCitationDocuments = (
  citation: Citation,
  documents: SourceDocument[]
) => {
  return documents.filter((d) => {
    const uniqueIds = getUniqueDocumentIds(citation.document_ids)
    try {
      let id = ''
      if (d.document_id.includes('python')) {
        return false
      }

      if (d.document_id.startsWith('web-search')) {
        id = d.document_id
      } else if (d.document_id.startsWith('microsoft')) {
        id = d.document_id
      } else {
        id = d.document_id.split('_')[0]
      }

      return uniqueIds.includes(id)
    } catch (e) {
      handleError(e)
      return false
    }
  })
}

export const getUniqueCitationDocuments = (
  citation: Citation | null,
  documents: SourceDocument[]
) => {
  const filteredDocuments = filterDocumentsByCited(
    documents,
    citation ? [citation] : []
  )

  const nonChartDocuments = getGlobalUniqueDocuments(
    filteredDocuments,
    false
  ).filter(
    (v) => !v.document_id.includes('chart') && !v.document_id.includes('table')
  )
  const filteredHighlights = filterDuplicateHighlights(nonChartDocuments)
  return filteredHighlights
}

export const getCitationHighlights = (
  citation: Citation | null,
  source: SourceDocument | null
) => {
  const selectedHighlights = citation?.highlights?.filter((v) => {
    if (!v.file_id) return false
    return (
      getFileId(v.file_id) === getFileId(source?.document_id || '') ||
      v.file_id.startsWith('http')
    )
  })
  const mappedHighlights = mapSourceHighlights(
    citation,
    selectedHighlights || []
  )

  return mappedHighlights
}

export const getCitationExtractResource = (
  extracts: SearchQueryExtract[],
  source: SourceDocument | null
) => {
  if (!source) return null
  return {
    extracts: extracts,
    document_type_friendly: source.doc_metadata?.document_type_friendly || '',
    id: source.document_id,
    title: source.title,
    text: source.text,
    url: source.url || '',
    document_link: source.doc_metadata?.external_link,
    doc_metadata: source.doc_metadata,
  }
}

export const documentArrayToMap = (
  documents?: SourceDocument[]
): Record<string, SourceDocument> => {
  if (!documents) return {}

  const documentMap: Record<string, SourceDocument> = {}

  documents.forEach((doc) => {
    documentMap[doc.document_id] = doc
  })

  return documentMap
}

/**
 * Creates an array of SourceDocument that consists of only relevant documents in a citation
 *
 * @param {Citation} citation A citation that contains an array of document_ids
 * @param {Record<string, SourceDocument>} documentMap a pre-computed document map using document_id as the key
 *
 * @returns {SourceDocument[]} All relevant documents in a citation
 */
export const getRelevantDocuments = (
  citation: Citation,
  documentMap: Record<string, SourceDocument>
): SourceDocument[] => {
  const relevantDocuments: SourceDocument[] = []

  citation.document_ids.forEach((docId) => {
    const document = documentMap[docId]
    if (document) {
      relevantDocuments.push(document)
    }
  })

  return relevantDocuments
}

/**
 * Simulates a queue to shift citations for a block in the block text editor
 *
 * @param {string} text A body of text that might contain a citation
 * @param {Citation[]} queue An array of Citations that will be treated as a queue
 *
 * @returns {Citation[]} Relevant citations inside a body of text
 */
export const shiftCitationsToBlock = (
  text: string,
  queue: Citation[]
): Citation[] => {
  return queue.filter((citation, index) => {
    if (text.includes(citation.text)) {
      queue.splice(index, 1)
      return true
    }
    return false
  })
}

export const applyInlineStyles = (styles: string[], element: Element) => {
  if (element.nodeType !== Node.ELEMENT_NODE) return

  const computedStyles = window.getComputedStyle(element)
  const inlineStyles = styles
    .map((prop) => `${prop}: ${computedStyles.getPropertyValue(prop)};`)
    .join(' ')

  element.setAttribute('style', inlineStyles)
  Array.from(element.children).forEach((v) => applyInlineStyles(styles, v))
}

export const matchSearchQuery = (text: string, query: string) => {
  const splitText = query.split(' ')
  return splitText.some((v) => text.toLowerCase().includes(v.toLowerCase()))
}

export const getDocgenSessionUrl = (session: DocgenSession): string => {
  switch (session.status) {
    case DocgenSessionStatus.CREATED:
    case DocgenSessionStatus.CREATING:
      return `/docgen/${session.id}?${new URLSearchParams({ step: DocgenStep.DRAFT })}`
    case DocgenSessionStatus.THEME_GENERATED:
    case DocgenSessionStatus.THEME_GENERATING:
    case DocgenSessionStatus.DRAFT_ERROR:
      return `/docgen/${session.id}?${new URLSearchParams({ step: DocgenStep.OUTLINE })}`
    case DocgenSessionStatus.THEME_ERROR:
      return `/docgen/${session.id}?${new URLSearchParams({ step: DocgenStep.TOPIC })}`
    case DocgenSessionStatus.TEMPLATE_CREATED:
    case null:
      return `/docgen/${session.id}?${new URLSearchParams({ step: DocgenStep.CUSTOMIZATION })}`
    default:
      return `/docgen/${session.id}`
  }
}

export const replaceHTMLHighlight = (text: string, highlight: string) => {
  if (!highlight) return text
  return highlight
    .trim()
    .replaceAll(/[.*+?^=!:${}()|\[\]\/\\]/g, '\\$&')
    .split(/\s+/)
    .reduce(
      (acc, _, i, arr) =>
        acc.replace(
          new RegExp(arr.slice(i, i + 5).join(' '), 'gi'),
          (match) =>
            `<span class='bg-[linear-gradient(0deg,_var(--citation-highlight),_var(--citation-highlight))] bg-no-repeat bg-[length:100%_90%]'>${match}</span>`
        ),
      text
    )
}

export const replaceMicrosoftHighlight = (text: string) => {
  const highlightedText = text
    .replaceAll('<c0>', '<Highlight>')
    .replaceAll('</c0>', '</Highlight>')
  const urlText = highlightedText.replaceAll(
    /(https?:\/\/[^\s]+)/g,
    (url) =>
      `<a href="${url}" target="_blank" rel="noopener noreferrer" className='cursor-pointer underline ${tertiaryStyle} !text-system-body'>${url}</a>`
  )
  return urlText
}

export function getSectionByIndexes(
  tree: TemplateSection[] | TemplateSubsection[],
  indexes: number[]
): TemplateSection | TemplateSubsection | null {
  let currentNode: TemplateSection | TemplateSubsection | null = null

  for (let i = 0; i < indexes.length; i++) {
    if (currentNode === null) {
      currentNode = tree[indexes[i]]
      continue
    }

    if (!currentNode.subsections) break
    const node: TemplateSection | TemplateSubsection | null =
      currentNode.subsections[indexes[i]]
    if (node) {
      currentNode = node
    } else {
      break
    }
  }

  return currentNode
}

export function checkIfDocGenSession(
  v: ResponseDocGenReport | DocgenSession
): v is DocgenSession {
  return 'template' in v
}
export const replacePlaybookVariable = (text: string, variable?: string) => {
  const replacedText = text.replaceAll(
    /\$\{(.*?)\}/g,
    variable ? `<Badge>${variable}</Badge>` : '<Badge>$1</Badge>'
  )
  return replacedText.replaceAll(/\\n/g, '<br/>')
}

export const checkIfSourceDocumentIsWeb = (document: SourceDocument) => {
  return document.document_id.startsWith('web')
}

export const checkIfSourceDocumentIsFinancialData = (
  document: SourceDocument
) => {
  return document.document_id.startsWith('financial_data')
}

export const checkIfSourceDocumentIsOutlook = (document: SourceDocument) => {
  return (
    document.document_id.startsWith('microsoft_outlook') ||
    document.doc_metadata?.document_source === 'outlook'
  )
}

export const checkIfSourceDocumentIsTeams = (document: SourceDocument) => {
  return (
    document.document_id.startsWith('teams') ||
    document.doc_metadata?.document_source === 'teams'
  )
}

export const sortSourceDocuments = (
  documents: SourceDocument[],
  citation?: Citation
) => {
  return documents
    .sort((a, b) => {
      if (!citation) return 0
      const indexA = citation.document_ids.indexOf(a.document_id)
      const indexB = citation.document_ids.indexOf(b.document_id)

      return indexA - indexB
    })
    .sort((a, b) => {
      const sourceTypeA = checkSourceDocumentType(a.document_id, a.doc_metadata)
      const sourceTypeB = checkSourceDocumentType(b.document_id, b.doc_metadata)

      const aIsWeb = sourceTypeA === SourceDocumentType.WEB
      const bIsWeb = sourceTypeB === SourceDocumentType.WEB

      // deprioritize web
      if (aIsWeb && !bIsWeb) return 1
      if (!aIsWeb && bIsWeb) return -1

      const aIsDatabase =
        sourceTypeA === SourceDocumentType.FINANCIAL_DATA ||
        sourceTypeA === SourceDocumentType.COMPANIES_HOUSE ||
        sourceTypeA === SourceDocumentType.FILINGS ||
        sourceTypeA === SourceDocumentType.TRANSCRIPTS
      const bIsDatabase =
        sourceTypeB === SourceDocumentType.FINANCIAL_DATA ||
        sourceTypeB === SourceDocumentType.COMPANIES_HOUSE ||
        sourceTypeB === SourceDocumentType.FILINGS ||
        sourceTypeB === SourceDocumentType.TRANSCRIPTS

      // deprioritize database
      if (aIsDatabase && !bIsDatabase) return 1
      if (!aIsDatabase && bIsDatabase) return -1

      const aIsCommunication =
        sourceTypeA === SourceDocumentType.TEAMS ||
        sourceTypeA === SourceDocumentType.OUTLOOK
      const bIsCommunication =
        sourceTypeB === SourceDocumentType.TEAMS ||
        sourceTypeB === SourceDocumentType.OUTLOOK

      // deprioritize communication
      if (aIsCommunication && !bIsCommunication) return 1
      if (!aIsCommunication && bIsCommunication) return -1

      return 0
    })
}

export function getSettingsSnapshotKey(conversationId: string) {
  return `${LocalStorageKey.SETTINGS_SNAPSHOT}__${conversationId}`
}

export function clearSettingsSnapshot(
  event: DESIA_EVENT,
  conversationId: string
) {
  try {
    const key = getSettingsSnapshotKey(`${event}_${conversationId}`)
    localStorage.removeItem(key)
  } catch (e) {
    handleError(e)
  }
}

export const getSettingsSnapshot = ({
  event,
  conversationId,
}: {
  event: DESIA_EVENT
  conversationId: string
}): { event: DESIA_EVENT; settings: UserSettings['settings'] } | undefined => {
  try {
    const key = getSettingsSnapshotKey(`${event}_${conversationId}`)
    const snapshot = localStorage.getItem(key)
    if (snapshot) {
      const parsedSnapshot = JSON.parse(snapshot)
      return parsedSnapshot satisfies {
        event: DESIA_EVENT
        settings: UserSettings['settings']
      }
    }
  } catch (e) {
    handleError(e)
  }
}

export const withSettingsSnapshot = ({
  callbackFn,
  event,
  conversationId,
  settings,
}: {
  callbackFn?: Function
  event: DESIA_EVENT
  conversationId: string
  settings: UserSettings['settings']
}) => {
  if (![DESIA_EVENT.CHAT_ASK].includes(event)) return

  try {
    const key = getSettingsSnapshotKey(`${event}_${conversationId}`)

    localStorage.setItem(
      key,
      JSON.stringify({
        event: event,
        settings: settings,
      })
    )

    if (callbackFn) {
      callbackFn()
    }
  } catch (e) {
    handleError(e)
  }
}

export const convertHeadersToBold = (paragraph: string) => {
  return paragraph.replace(/^(#{2,6})\s+(.+)$/gm, (_1, _2, content: string) => {
    return `**${content}**\n` // newline since headers to body strong
  })
}
// checking different fields helps with the inconsistencies across search results
export const checkSourceDocumentType = (
  id: string,
  metadata?: SourceDocumentMetadata
) => {
  if (id.startsWith('web')) {
    return SourceDocumentType.WEB
  }

  if (
    id.startsWith('financial_data') ||
    metadata?.source === 'financial_data'
  ) {
    return SourceDocumentType.FINANCIAL_DATA
  }

  if (
    id.startsWith('company_house') ||
    metadata?.source === 'company_house' ||
    metadata?.label === 'company_house' ||
    metadata?.integration_name === 'data-provider-gov.uk-companyhouse' ||
    metadata?.label === 'data-provider-gov.uk-companyhouse'
  ) {
    return SourceDocumentType.COMPANIES_HOUSE
  }

  // its transcripts from search, but transcript from ask
  if (
    metadata?.filing_category &&
    metadata.filing_category !== 'transcripts' &&
    metadata.filing_category !== 'transcript'
  ) {
    return SourceDocumentType.FILINGS
  }

  if (
    metadata?.filing_category === 'transcripts' ||
    metadata?.filing_category === 'transcript'
  ) {
    return SourceDocumentType.TRANSCRIPTS
  }

  if (
    id.startsWith('microsoft_outlook') ||
    metadata?.document_source === 'outlook' ||
    metadata?.label === 'msft-outlook_search'
  ) {
    return SourceDocumentType.OUTLOOK
  }

  if (
    id.startsWith('microsoft_teams') ||
    metadata?.document_source === 'teams' ||
    metadata?.label === 'msft-teams_search'
  ) {
    return SourceDocumentType.TEAMS
  }

  if (
    metadata?.document_source_details?.integration_code_name ===
    IntegrationCode.ONEDRIVE
  ) {
    return SourceDocumentType.ONEDRIVE
  }

  if (
    metadata?.document_source_details?.integration_code_name ===
    IntegrationCode.SHAREPOINT
  ) {
    return SourceDocumentType.SHAREPOINT
  }

  if (metadata?.document_is_part_of_desia_library) {
    return SourceDocumentType.DESIA_LIBRARY
  }
  return SourceDocumentType.LIBRARY
}

export const toPascalCase = (sentence: string) => {
  return sentence
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}
export const formatPreviewHighlight = (
  highlight:
    | {
        highlight?: string
        page_number: number | null | undefined
        text: string
      }
    | undefined,
  maxDecimalPlaces: number = 1
) => {
  const replacedText = highlight?.highlight
    ?.replaceAll('\\n', '\n')
    .replaceAll(
      new RegExp(
        formatDisplayNumber(highlight.text, maxDecimalPlaces).replace(
          /[.*+?^=!:${}()|\[\]\/\\]/g,
          '\\$&'
        ),
        'gi'
      ),
      (match) => `<Highlight>${match}</Highlight>`
    )
  return replacedText || ''
}

export const getDatabaseName = (database: DatabaseConnector | string) => {
  switch (database) {
    case DatabaseConnector.COMPANIES_HOUSE:
      return 'Companies House'
    case DatabaseConnector.FINANCIAL_DATA:
      return 'Financial data'
    case DatabaseConnector.FILINGS:
      return 'Global companies filings'
    case DatabaseConnector.TRANSCRIPTS:
      return 'Earning call transcripts'
    default:
      return null
  }
}

export const reformatTableDate = (dateStr: string) => {
  let parsedDate

  if (/^\d{4}-\d{2}$/.test(dateStr)) {
    parsedDate = parse(dateStr, 'yyyy-MM', new Date())
    return format(parsedDate, 'MMMM yyyy')
  } else if (/^\d{2}-\d{4}$/.test(dateStr)) {
    parsedDate = parse(dateStr, 'MM-yyyy', new Date())
    return format(parsedDate, 'MMMM yyyy')
  } else if (/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
    parsedDate = parse(dateStr, 'yyyy-MM-dd', new Date())
    return format(parsedDate, 'd MMMM yyyy')
  } else if (/^\d{2}-\d{2}-\d{4}$/.test(dateStr)) {
    parsedDate = parse(dateStr, 'd-MM-yyyy', new Date())
    return format(parsedDate, 'd MMMM yyyy')
  }

  return dateStr
}

export const embedAdditionalContexts = (
  message: string,
  additionalContexts: string[]
): string => {
  return additionalContexts
    .map((context) => {
      return `<userdefinedcontext>\n${context}\n</userdefinedcontext>`
    })
    .join('\n\n')
    .concat(`\n\n${message}`)
}

export const sanitizeInput = (input: string, truncateEnd?: number) => {
  const sanitized = input
    .replace(/<script[\s\S]*?>[\s\S]*?<\/script>/gi, '')
    .replace(/<.*?on\w+=.*?>/gi, '')
    .replace(/<\?php.*?\?>/gi, '')
    .replace(/<\?[^>]*\?>/gi, '') // short php tags
    .trim()

  if (truncateEnd && truncateEnd > 0) {
    return `${sanitized.slice(0, truncateEnd)}...(truncated)`
  }

  return sanitized.trim()
}

export const processAdditionalContexts = (
  message: string
): {
  message: string
  additionalContexts: string[]
} => {
  const additionalContextRegex =
    /<userdefinedcontext>([\s\S]*?)<\/userdefinedcontext>/g

  const userDefinedContexts = []
  let match
  while ((match = additionalContextRegex.exec(message)) !== null) {
    userDefinedContexts.push(match[1].trim())
  }

  const sanitizedMessage = message.replace(additionalContextRegex, '').trim()

  return {
    message: sanitizedMessage,
    additionalContexts: userDefinedContexts,
  }
}

export const removeXMLTags = (message: string): string => {
  return message.replace(/<[^>]+>/g, '').trim()
}

export const aggregateArrays = <T>(...args: T[][]) => {
  return (
    args.reduce((acc, v) => {
      if (v.length <= 0) return acc
      acc.push(...v)

      return acc
    }, []) ?? []
  )
}

export const groupSearchResultByCompany = (data: DedupedSearchQueryItem[]) => {
  const result: { [company: string]: DedupedSearchQueryItem[] } = {}

  data.forEach((v) => {
    if (
      !result[v.doc_metadata?.element_name || v.title || '']?.some(
        (f) => f.id === v.id
      )
    ) {
      result[v.doc_metadata?.element_name || v.title || ''] = [
        ...(result[v.doc_metadata?.element_name || v.title || ''] || []),
        v as DedupedSearchQueryItem,
      ]
    }
  })

  return result
}

export const parseFilingDate = (date: string) => {
  const formats = [
    'dd/MM/yyyy',
    'MM/dd/yyyy',
    'yyyy-MM-dd',
    'dd-MM-yyyy',
    'yyyy/MM/dd',
    'yyyy-MM-dd HH:mm:ss',
    `yyyy-MM-dd'T'HH:mm:ss`,
  ]
  for (const formatString of formats) {
    const parsedDate = parse(date, formatString, new Date())
    if (isValid(parsedDate)) {
      return formatDate(parsedDate, 'd MMMM yyyy')
    }
  }

  return date
}

export const formatFocusedAnalysisFiles = (
  files: (string | DedupedSearchQueryItem)[]
) => {
  const result: {
    file_id: string
    use_central_storage: boolean
    central_storage_path: string | null
  }[] = []

  files.forEach((file) => {
    if (typeof file === 'string') {
      result.push({
        file_id: file,
        use_central_storage: false,
        central_storage_path: null,
      })
    } else {
      result.push({
        file_id: file.id,
        use_central_storage: true,
        central_storage_path: file.document_link || null,
      })
    }
  })

  return result
}

export const getOrganizationName = (organization_id: string | undefined) => {
  return capitalise(organization_id?.split('org__')[1] || '')
}

export const isUploadFileEnabled = (sourceType: string) => {
  return sourceType === 'ask' || sourceType === 'dossier'
}

export const getDesiaDefaultTheme = (): Theme => {
  const colors = Array.from({ length: THEME_MIN_AMOUNT }, (_, i) =>
    getChartColor(i)
  )
  const texts = colors.map((color) => getLabelColor(color))

  return {
    colors,
    texts,
  }
}

const calculateLuminance = (hex: string): number => {
  const rgb = hex
    .replace(/^#/, '')
    .match(/.{2}/g)
    ?.map((x) => parseInt(x, 16))
  if (!rgb) return 0

  const [r, g, b] = rgb

  const a = [r, g, b].map((v) => {
    const normalized = v / 255
    return normalized <= 0.03928
      ? normalized / 12.92
      : Math.pow((normalized + 0.055) / 1.055, 2.4)
  })

  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
}

const calculateContrast = (color1: string, color2: string): number => {
  const luminance1 = calculateLuminance(color1)
  const luminance2 = calculateLuminance(color2)

  const lighter = Math.max(luminance1, luminance2)
  const darker = Math.min(luminance1, luminance2)

  return (lighter + 0.05) / (darker + 0.05)
}

export const getLabelColor = (swatchColor: string) => {
  const contrastWithWhite = calculateContrast(swatchColor, '#FAFAFA')
  const contrastWithBlack = calculateContrast(swatchColor, '#0A0A0A')

  return contrastWithWhite > contrastWithBlack ? '#FAFAFA' : '#0A0A0A'
}

export const getExtendedTheme = (theme: Theme): Theme => {
  const isShouldExtend = theme.colors.length < THEME_MAX_AMOUNT

  const extendedColors = isShouldExtend
    ? [
        ...theme.colors,
        ...Array.from(
          { length: THEME_MAX_AMOUNT - theme.colors.length },
          (_, i) => getChartColor(theme.colors.length + i)
        ),
      ]
    : theme.colors

  const extendedTexts = isShouldExtend
    ? [
        ...theme.texts,
        ...Array.from(
          { length: THEME_MAX_AMOUNT - theme.texts.length },
          (_, i) => getLabelColor(extendedColors[theme.texts.length + i])
        ),
      ]
    : theme.texts

  return {
    colors: extendedColors,
    texts: extendedTexts,
  }
}

export const sortToolEvents = (tools: ToolEvent[]) => {
  const toolOrder = [
    'process_file',
    'generate_gemini_content',

    'web_search_tool',
    'internal_search_tool',
    'msft_teams_search_tool',
    'msft_outlook_search_tool',
    'companies_house_search',
    'financial_data_tool',
    'filings_search',
    'transcripts_search',

    'company_search',
    'people_search',

    'generate_table_tool',
    'generate_graph_tool',
  ]

  const sortedTools = tools.sort((a, b) => {
    const indexA = toolOrder.indexOf(a.tool_name)
    const indexB = toolOrder.indexOf(b.tool_name)

    return indexA - indexB
  })

  return sortedTools
}

export function isValidUrl(url: string) {
  try {
    new URL(url)
    return true
  } catch (_) {
    return false
  }
}

export const formatDisplayNumber = (
  text: string | number,
  maxDecimalPlaces: number = 1
): string => {
  const numberFormat = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: maxDecimalPlaces,
    minimumFractionDigits: 0,
  })

  const str = String(text) // enforce type from formatter

  const isNegative = str.startsWith('(') && str.endsWith(')')

  const cleanedInput = isNegative ? str.slice(1, -1) : str

  // parseFloat is not a consistent way to check for numeric
  // 10b -> is a number
  // x10b -> NaN
  const isValidNumber = /^-?\d+(\.\d+)?$/.test(cleanedInput)

  if (!isValidNumber) {
    return str
  }

  const num = parseFloat(cleanedInput)

  let formattedNum: string

  if (num >= 1_000_000_000_000 || num <= -1_000_000_000_000) {
    formattedNum = `${numberFormat.format(num / 1_000_000_000_000)}t`
  } else if (num >= 1_000_000_000 || num <= -1_000_000_000) {
    formattedNum = `${numberFormat.format(num / 1_000_000_000)}b`
  } else if (num >= 1_000_000 || num <= -1_000_000) {
    formattedNum = `${numberFormat.format(num / 1_000_000)}m`
  } else if (num >= 10_000 || num <= -10_000) {
    formattedNum = `${numberFormat.format(num / 1_000)}k`
  } else {
    formattedNum = numberFormat.format(num)
  }

  return isNegative ? `(${formattedNum})` : formattedNum
}

export const getDisplayNumberMaxDecimalPlaces = (title: string) => {
  const regex = new RegExp(/open|high|low|close/giu)

  return title.match(regex) ? 2 : 1
}
