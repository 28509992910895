import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog";
import { Button } from "../ui/button";

export function IntegrationGreenlistingConfirmDialog({ open, onConfirm, onCancel }: { open?: boolean, onConfirm?: () => void, onCancel?: () => void }) {
    function handleNavigate() {
        onConfirm?.()
    }

    return (
        <Dialog open={open}>
            <DialogContent className="max-w-[32.5rem] min-w-[32.5rem]" onInteractOutside={() => onCancel?.()}>
                <DialogHeader>
                    <DialogTitle onClose={() => onCancel?.()}>You are about to leave and discard selection</DialogTitle>
                    <DialogDescription>
                        Leaving the page will discard selection.
                    </DialogDescription>
                </DialogHeader>
                <DialogFooter className="mt-2">
                    <DialogClose asChild>
                        <Button variant="secondary" onClick={handleNavigate}>Leave and discard selection</Button>
                    </DialogClose>
                    <DialogClose asChild>
                        <Button onClick={() => onCancel?.()}>
                            Stay on page
                        </Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
