import { TypographyBody } from '../ui/Typography'
import ColorPicker, { Color } from '@rc-component/color-picker'
import { useState, useRef, useEffect } from 'react'
import './colorpicker.css'
import { X } from 'lucide-react'
import { Button } from '../ui/button'
import { Skeleton } from '../ui/skeleton'
import { THEME_MIN_AMOUNT } from '@/constants'

export const ColorSwatchSkeleton = () => {
  return (
    <div className="box-border flex gap-2.5 bg-system-secondary border border-system-border-regular min-w-[11.3125rem] max-w-[11.3125rem] max-h-[4.75rem] p-3 pb-2 rounded-xl relative">
      <Skeleton className="w-12 h-12" />
      <div className="flex flex-col gap-4 ">
        <Skeleton className="w-20 h-4" />
        <Skeleton className="w-20 h-4" />
      </div>
    </div>
  )
}

export const ColorSwatch = ({
  value,
  setValue,
  isPicker,
  colorIndex,
  onDelete,
}: {
  value: string
  setValue?: (color: Color) => void
  isPicker: boolean
  colorIndex: number
  onDelete?: () => void
}) => {
  const regex = /^#(([0-9a-fA-F]{2}){3}|([0-9a-fA-F]){3})$/
  const [isActive, setIsActive] = useState<boolean>(false)

  const [color, setColor] = useState<Color>(new Color(value))
  const [colorStr, setColorStr] = useState<string>(value)

  const inputRef = useRef<HTMLInputElement | null>(null)
  const swatchRef = useRef<HTMLDivElement | null>(null)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = event.target.value
    setColorStr(newColor)

    if (regex.test(newColor)) {
      setColor(new Color(newColor))
    }
  }

  const handleFixedChange = () => {
    if (color && regex.test(color.toHexString())) {
      setValue?.(color)
    }
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        swatchRef.current &&
        !swatchRef.current.contains(event.target as Node)
      ) {
        setIsActive(false)
      }
    }

    if (isPicker) {
      document.addEventListener('click', handleClickOutside)
    }

    return () => {
      if (isPicker) {
        document.removeEventListener('click', handleClickOutside)
      }
    }
  }, [])

  useEffect(() => {
    if (isActive && inputRef.current) {
      inputRef.current.focus()
    }
  }, [isActive])

  const handleSwatchClick = () => {
    if (isPicker) {
      setIsActive(true)
    }
  }

  return (
    <div
      ref={swatchRef}
      className={`${isPicker && 'cursor-pointer'} ${isActive ? '!ring !ring-[3px] !ring-system-primary !bg-system-secondary ' : ''} hover:bg-system-hover focus:!ring-[2px] focus:!ring-system-primary focus:bg-system-hover box-border flex gap-2.5 bg-system-secondary border border-[1px] border-system-border-regular min-w-[11.3125rem] max-w-[11.3125rem] max-h-[4.75rem] p-3 pb-2 rounded-xl relative`}
      onClick={handleSwatchClick}
    >
      <div
        className="w-12 h-12 rounded-lg"
        style={{ backgroundColor: value }}
      ></div>

      <div className="flex flex-col">
        <TypographyBody className="!font-body-strong text-system-primary">
          Color {colorIndex}
        </TypographyBody>

        {isPicker ? (
          isActive ? (
            <input
              ref={inputRef}
              type="text"
              value={colorStr}
              onChange={handleInputChange}
              onBlur={handleFixedChange}
              className="uppercase box-border border border-system-border-regular border-[1px] rounded-md !font-body text-system-body ring-0 px-1 p-0 m-0 focus:outline-0 w-24 bg-system-surface-light"
              maxLength={9}
              placeholder={value}
            />
          ) : (
            <TypographyBody
              className="!font-body text-system-body uppercase "
              onClick={() => setIsActive(true)}
            >
              {value}
            </TypographyBody>
          )
        ) : (
          <TypographyBody className="!font-body text-system-body uppercase ">
            {value}
          </TypographyBody>
        )}
      </div>
      {isPicker && onDelete && colorIndex > THEME_MIN_AMOUNT && (
        <Button
          variant="tertiary"
          className="absolute right-2.5 top-2.5 p-0"
          onClick={(e) => {
            e.stopPropagation()
            onDelete()
          }}
        >
          <X className="!size-5" />
        </Button>
      )}
      {isActive && isPicker && (
        <div className="absolute top-20 left-0 z-10">
          <ColorPicker
            value={color}
            onChange={(color: Color) => {
              setColor(color)
              setColorStr(color.toHexString())
            }}
            onChangeComplete={(value: Color) => {
              setValue?.(value)
              setColorStr(value.toHexString())
            }}
          />
        </div>
      )}
    </div>
  )
}
