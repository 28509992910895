import { TypographyBody } from '../ui/Typography'
import { X } from 'lucide-react'
import { Fragment } from 'react/jsx-runtime'
import { Button } from '../ui/button'
import { getFileIcon } from '@/utils/components'

const AdditionalContextCard = ({
  text,
  onDelete,
}: {
  text: string
  onDelete: () => void
}) => {
  return (
    <div className="bg-system-secondary cursor-pointer border border-system-border-light rounded-md p-2 flex items-center justify-start gap-2 max-w-[28.625rem] shrink-0">
      <Fragment>
        {getFileIcon('text', '!size-6')}
        <TypographyBody className="text-system-body w-full line-clamp-1 text-system-body whitespace-pre-wrap">
          {text}
        </TypographyBody>
        <Button variant="tertiary" onClick={onDelete}>
          <X className="size-6 shrink-0 stroke-interactive" />
        </Button>
      </Fragment>
    </div>
  )
}

export default AdditionalContextCard
