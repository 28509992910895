import { PlaybookConnectors, SourceSettings } from '@/types/types'

export const convertPlaybookConnectors = (
  connectors: PlaybookConnectors,
  allowFileAnalysis: boolean
) => {
  const result: string[] = []

  if (connectors.internalSearch) {
    result.push('Library')
  }

  if (connectors.webSearch) {
    result.push('Web')
  }

  if (connectors.teamsSearch) {
    result.push('Teams')
  }

  if (connectors.outlookSearch) {
    result.push('Outlook')
  }

  if (connectors.financialDataSearch) {
    result.push('Financial Data')
  }

  if (connectors.filingsSearch) {
    result.push('Filings')
  }

  if (connectors.transcriptsSearch) {
    result.push('Transcripts')
  }

  if (connectors.companiesHouseSearch) {
    result.push('Companies House')
  }

  if (allowFileAnalysis) {
    result.push('Selected Files')
  }

  return result
}

export const convertConnectorsToPlaybookConnectors = (
  settings: SourceSettings
) => {
  return {
    connectors: {
      webSearch: settings.webSearch,
      internalSearch: settings.internalSearch,
      teamsSearch: settings.teamsSearch,
      outlookSearch: settings.outlookSearch,
      companiesHouseSearch: settings.companiesHouseSearch,
      filingsSearch: settings.filingsSearch,
      transcriptsSearch: settings.transcriptsSearch,
      financialDataSearch: settings.financialDataSearch,
    } as PlaybookConnectors,
    allowFileAnalysis:
      settings.companiesHouseFiles.length > 0 ||
      settings.files.length > 0 ||
      settings.uploadedFiles?.length > 0,
  }
}

export const cleanSourceSet = (sources: Set<string>) => {
  if (sources.has('Library') || sources.has('Selected Files')) {
    sources.add('Sharepoint')
    sources.add('OneDrive')
  }

  if (sources.has('Selected Files')) {
    sources.add('Companies House')
    sources.add('Filings')
    sources.add('Transcripts')
  }

  if (sources.has('Library') && sources.has('Selected Files')) {
    sources.delete('Library')
  }

  return sources
}
