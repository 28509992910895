import {
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import {
  cloneElement,
  isValidElement,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { fetchPlaybooks } from './playbookThunk'
import {
  Conversation,
  DedupedSearchQueryItem,
  DESIA_EVENT,
  DocumentSelectorDialogType,
  Playbook,
  QueryStatus,
  RequestAssistantAsk,
  ResponseChatStream,
  SourceType,
  WebSocketRequestWrapper,
} from '@/types/types'
import { Button } from '../ui/button'
import {
  formatFocusedAnalysisFiles,
  getTimestamp,
  isUploadFileEnabled,
} from '@/utils/utils'
import { Badge } from '../ui/badge'
import { UserContext } from '@/contexts/UserContext'
import ArrowRight from '@/assets/ArrowRight'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form, FormControl, FormField, FormItem, FormMessage } from '../ui/form'
import { Input } from '../ui/input'
import DocumentSelectorDialog from '../Assistant/DocumentSelectorDialog'
import shortid from 'shortid'
import { actions as assistantActions } from '../Assistant/assistantSlice'
import { useNavigate } from 'react-router-dom'
import { getAskConnectors, getAskToolEvents, getAskTools } from '@/utils/ask'
import { useSocketQuery } from '@/hooks/useSocketQuery'
import { TypographyBody, TypographyLabel } from '../ui/Typography'
import { PlaybookOptionRadio } from './PlaybookOptionRadio'
import { PlaybookOptionHeader } from './PlaybookOptionHeader'

export const PlaybookWrapper = ({
  conversation,
  conversationId,
  sourceType,
  children,
  onAsk,
  onBack,
}: {
  conversation?: Conversation
  conversationId?: string
  sourceType: SourceType
  children: ReactElement<{
    handleClick?: (playbook: Playbook) => void
  }>
  onAsk?: () => void
  onBack?: () => void
}) => {
  const playbooks = useSelector((state: RootState) => state.playbook.playbooks)
  const { settings, updateSettings } = useContext(UserContext)

  const [selectedFiles, setSelectedFiles] = useState<
    { title: string; id: string }[]
  >([])
  const [selectedCompaniesHouseFiles, setSelectedCompaniesHouseFiles] =
    useState<DedupedSearchQueryItem[]>([])
  const [selectedVariablePlaybook, setSelectedVariablePlaybook] =
    useState<Playbook | null>(null)
  const [showPromptDialog, setShowPromptDialog] = useState(false)
  const [showDocumentSelectorDialog, setShowDocumentSelectorDialog] =
    useState(false)
  const [selectedOption, setSelectedOption] = useState<string | null>(null)

  const selectedVariablePlaybookItem = selectedOption
    ? selectedVariablePlaybook?.items.find((v) => v.id === selectedOption)
    : selectedVariablePlaybook?.items.at(0)

  const requestId = conversationId
    ? conversationId || 'requestId'
    : `new_ask__${shortid()}`

  const { executeQuery } = useSocketQuery({
    event: DESIA_EVENT.CHAT_ASK,
    request: {
      requestId: requestId,
      timestamp: getTimestamp(),
      params: {},
    },
    options: {
      manual: true,
      callback: (response) => {
        const res = response.data as ResponseChatStream // fixme
        dispatch(
          assistantActions.streamingResponse({
            ...res,
            requestId: response.requestId,
            timestamp: response.timestamp,
          })
        )
      },
    },
  })

  const formSchema = z.object({
    variable: z
      .string()
      .min(
        1,
        `Please enter ${selectedVariablePlaybookItem?.variables || ''} name`
      ),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      variable: '',
    },
  })

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const formRef = useRef<HTMLFormElement>(null)

  const handleAskQuery = (
    playbookId: string,
    prompt: string,
    connectors: string[],
    variables: string[],
    files: { title: string; id: string }[],
    companiesHouseFiles: DedupedSearchQueryItem[]
  ) => {
    const uploadedFiles = settings.assistant.sources[sourceType].uploadedFiles

    const updatedSettings = {
      settings: {
        ...settings,
        assistant: {
          ...settings.assistant,
          sources: {
            ...settings.assistant.sources,
            [sourceType]: {
              ...settings.assistant.sources.ask,
              internalSearch: connectors.includes('Library'),
              webSearch: connectors.includes('Web'),
              teamsSearch: connectors.includes('Teams'),
              outlookSearch: connectors.includes('Outlook'),
              focusedAnalysis: connectors.includes('Selected Files'),
              companiesHouseSearch: connectors.includes('Companies House'),
              financialDataSearch: connectors.includes('Financial Data'),
              filingsSearch: connectors.includes('Filings'),
              transcriptsSearch: connectors.includes('Transcripts'),
              uploadedFiles:
                connectors.includes('Selected Files') &&
                isUploadFileEnabled(sourceType)
                  ? uploadedFiles
                  : [],
              files: files,
              companiesHouseFiles: companiesHouseFiles,
            },
          },
        },
      },
    }

    updateSettings(updatedSettings)

    if (!conversationId) {
      dispatch(
        assistantActions.newAsk({
          requestId,
          question: prompt,
          timestamp: getTimestamp(),
          mode: updatedSettings.settings.assistant.mode,
          playbook: {
            playbook_id: playbookId,
            playbook_vars: variables,
          },
          tool_events: getAskToolEvents(
            updatedSettings.settings.assistant.sources[sourceType]
          ),
        })
      )
      const encodedQuestion = encodeURIComponent(prompt)

      navigate(
        `/assistant/ask/new/?request_id=${requestId}&q=${encodedQuestion}&mode=${updatedSettings.settings.assistant.mode}&trigger=true&playbook=${JSON.stringify({ playbook_id: playbookId, playbook_vars: variables })}`
      )
    } else {
      const timestamp = getTimestamp()
      dispatch(
        assistantActions.followUpAsk({
          conversationId,
          requestId: `${requestId}_followup_${conversation?.length || 0}`,
          question: prompt,
          timestamp,
          mode: updatedSettings.settings.assistant.mode,
          playbook: {
            playbook_id: playbookId,
            playbook_vars: variables,
          },
          tool_events: getAskToolEvents(
            updatedSettings.settings.assistant.sources[sourceType]
          ),
        })
      )
      const fileIds = [
        ...formatFocusedAnalysisFiles(files.map((v) => v.id) || []),
        ...formatFocusedAnalysisFiles(
          updatedSettings.settings.assistant.sources[
            sourceType
          ].uploadedFiles.map((v) => v.id)
        ),
      ] //fixme

      const request: WebSocketRequestWrapper<RequestAssistantAsk> = {
        requestId,
        timestamp,
        params: {
          message: prompt,
          conversationId,
          connectorsV2: getAskConnectors(
            updatedSettings.settings.assistant.sources[sourceType]
          ),
          model: updatedSettings.settings.assistant.parameters?.model,
          seed: updatedSettings.settings.assistant.parameters?.seed,
          temperature:
            updatedSettings.settings.assistant.parameters?.temperature,
          systemPrompt:
            updatedSettings.settings.assistant.parameters?.systemPrompt,
          mode: updatedSettings.settings.assistant.mode,
          focusedAnalysis:
            updatedSettings.settings.assistant.sources[sourceType]
              .focusedAnalysis,
          fileIDs: updatedSettings.settings.assistant.sources[sourceType]
            .focusedAnalysis
            ? fileIds
            : [],
          ...getAskTools(
            updatedSettings.settings.assistant.sources[sourceType]
          ),
          playbook: {
            playbook_id: playbookId,
            playbook_vars: variables,
          },
        },
      }

      executeQuery({
        event: DESIA_EVENT.CHAT_ASK,
        request,
      })

      onAsk?.()
    }
  }

  const handleClick = (playbook: Playbook) => {
    const firstItem = playbook.items.at(0)
    const connectors = firstItem?.connectors || []
    const variables = firstItem?.variables || ''

    if (connectors.includes('Selected Files')) {
      setSelectedVariablePlaybook(playbook)
      setShowDocumentSelectorDialog(true)
    } else if (!variables && playbook.items.length === 1 && firstItem) {
      handleAskQuery(
        firstItem.id,
        firstItem.prompt,
        firstItem.connectors,
        [],
        selectedFiles,
        selectedCompaniesHouseFiles
      )
      handleReset()
    } else {
      setSelectedVariablePlaybook(playbook)
      setShowPromptDialog(true)
    }
  }

  const handleDocumentSubmit = (
    files: { title: string; id: string }[],
    companiesHouseFiles: DedupedSearchQueryItem[]
  ) => {
    if (!selectedVariablePlaybook) return
    if (!selectedVariablePlaybookItem) return
    setSelectedFiles(files)
    setSelectedCompaniesHouseFiles(companiesHouseFiles)

    if (selectedVariablePlaybookItem.variables) {
      setShowPromptDialog(true)
      return
    }
    handleAskQuery(
      selectedVariablePlaybookItem.id,
      selectedVariablePlaybookItem.prompt,
      selectedVariablePlaybookItem.connectors,
      [],
      files,
      companiesHouseFiles
    )

    handleReset()
  }

  const handleReset = useCallback(() => {
    setSelectedFiles([])
    setSelectedCompaniesHouseFiles([])
    setSelectedVariablePlaybook(null)
    setShowDocumentSelectorDialog(false)
    setShowPromptDialog(false)
    setSelectedOption(null)
    form.reset()
  }, [form])

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    if (!selectedVariablePlaybook) return
    if (!selectedVariablePlaybookItem) return

    const replacedPrompt = selectedVariablePlaybookItem.prompt.replaceAll(
      /\$\{(.*?)\}/g,
      values.variable
    )

    handleAskQuery(
      selectedVariablePlaybookItem.id,
      replacedPrompt,
      selectedVariablePlaybookItem.connectors,
      [values.variable],
      selectedFiles,
      selectedCompaniesHouseFiles
    )
    handleReset()
  }

  useEffect(() => {
    if (playbooks.status === QueryStatus.INITIALISED) {
      dispatch(fetchPlaybooks())
    }
  }, [dispatch, playbooks.status])

  useEffect(() => {
    if (!showPromptDialog && !showDocumentSelectorDialog) {
      handleReset()
    }
  }, [showPromptDialog, showDocumentSelectorDialog, handleReset])

  useEffect(() => {
    if (selectedVariablePlaybook) {
      setSelectedOption(selectedVariablePlaybook.items.at(0)?.id || null)
    }
  }, [selectedVariablePlaybook])

  return (
    <>
      {isValidElement(children) &&
        cloneElement(children, {
          handleClick,
        })}

      <Dialog open={showPromptDialog} onOpenChange={setShowPromptDialog}>
        <DialogContent className="!max-w-[36.25rem] px-6 py-4">
          <DialogHeader>
            <DialogTitle className="border-b border-system-border-light pb-4 w-full">
              <PlaybookOptionHeader
                playbook={selectedVariablePlaybook || undefined}
                onBack={() => {
                  onBack?.()
                  handleReset()
                }}
              />
            </DialogTitle>
          </DialogHeader>
          <DialogBody>
            <div className="flex flex-col gap-4">
              {(selectedVariablePlaybook?.items || []).length > 1 && (
                <PlaybookOptionRadio
                  playbook={selectedVariablePlaybook || undefined}
                  value={selectedOption || undefined}
                  onValueChange={(v) => setSelectedOption(v)}
                />
              )}

              {selectedVariablePlaybookItem?.variables && (
                <Form {...form}>
                  <form
                    ref={formRef}
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="space-y-2"
                  >
                    <div className="space-y-8 overflow-x-visible px-1 py-1 mx-auto">
                      <FormField
                        control={form.control}
                        name="variable"
                        render={({ field }) => (
                          <FormItem className="space-y-2">
                            <div className="flex flex-col gap-0">
                              <TypographyBody isStrong>
                                Define prompt focus
                              </TypographyBody>
                              <TypographyLabel>
                                {`Enter the name of the ${selectedVariablePlaybookItem?.variables || ''}`}
                              </TypographyLabel>
                            </div>
                            <FormMessage />
                            <FormControl>
                              <Input
                                {...field}
                                placeholder={
                                  selectedVariablePlaybookItem?.placeholder ||
                                  ''
                                }
                                prefixComponent={
                                  <Badge variant="purple">
                                    {selectedVariablePlaybookItem?.variables ||
                                      ''}
                                  </Badge>
                                }
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                    </div>
                  </form>
                </Form>
              )}
            </div>
          </DialogBody>
          <DialogFooter>
            <DialogClose asChild>
              <Button variant="secondary">Cancel</Button>
            </DialogClose>

            <Button
              onClick={() => {
                formRef.current?.requestSubmit()
              }}
            >
              <div className="flex gap-2 items-center">
                Ask
                <ArrowRight />
              </div>
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <DocumentSelectorDialog
        sourceType={'ask'}
        open={showDocumentSelectorDialog}
        setOpen={(v) => {
          setShowDocumentSelectorDialog(v)
        }}
        onSave={(v, c) => {
          handleDocumentSubmit(v, c)
        }}
        type={DocumentSelectorDialogType.PLAYBOOK}
        playbook={selectedVariablePlaybook || undefined}
        onPlaybookBack={() => {
          onBack?.()
          handleReset()
        }}
        playbookOption={selectedOption || undefined}
        onPlaybookOptionChange={(v) => setSelectedOption(v)}
      />
    </>
  )
}
