import { TypographyBody } from '../ui/Typography'
import { X } from 'lucide-react'
import Divider from '../ui/divider'
import { ResponseMarkdown } from './ResponseMarkdown'
import { getFileIcon } from '@/utils/components'
import { Button } from '../ui/button'

const AdditionalContextPreview = ({
  additionalContext,
  onClose,
}: {
  additionalContext: string
  onClose: () => void
}) => {
  return (
    <div className="flex flex-col gap-3 max-h-[calc(100vh-7.75rem)] border border-system-border-regular rounded-lg p-3 bg-system-secondary w-full">
      <div className="flex gap-2 items-center relative min-h-[2.75rem]">
        {getFileIcon('text', '!size-7')}
        <TypographyBody
          isStrong
          className="text-system-primary w-full line-clamp-1 text-system-body whitespace-pre-wrap"
        >
          {additionalContext}
        </TypographyBody>

        <div className="items-start">
          <Button variant="tertiary" onClick={onClose}>
            <X className="!size-6 shrink-0 stroke-interactive" />
          </Button>
        </div>
      </div>

      <Divider />

      <TypographyBody className="text-system-body">
        Copy pasted content
      </TypographyBody>

      <div className="w-full pt-2 p-6 bg-system-surface-light rounded-[12px] overflow-y-auto">
        <ResponseMarkdown
          text={additionalContext}
          documents={[]}
          citations={[]}
          isTable={false}
          openedCitation={null}
        />
      </div>
    </div>
  )
}

export default AdditionalContextPreview
