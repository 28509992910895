import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'

import {
  Dispatch,
  lazy,
  SetStateAction,
  Suspense,
  useContext,
  useEffect,
  useState,
} from 'react'

import { DESIA_EVENT, FileStructure, ResponseDocument } from '@/types/types'

import { Skeleton } from '../ui/skeleton'
import { ErrorMessage } from '../ErrorMessage'
import { DeleteResourceDialog } from '../Resources/DeleteResourceDialog'
import { toggleElement, withSettingsSnapshot } from '@/utils/utils'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '@/contexts/UserContext'

const FileRow = lazy(() => import('./FileRow'))

export interface FileTableProps {
  elements: FileStructure[]
  shownElements: FileStructure[]
  resources?: ResponseDocument[]
  setElements?: Dispatch<SetStateAction<FileStructure[]>>
  showHeader?: boolean
  showCheckbox?: boolean
  showRadio?: boolean
  showDossierDropdown?: boolean
  showLibraryDropdown?: boolean
  showFileSize?: boolean
  showSourceInsteadOfDirectory?: boolean
  disableInvalidFocusedAnalysisFileTypes?: boolean
  loading?: boolean
  error?: boolean
  openFileOnClick?: boolean
  onRemove?: ({ id, title }: { id: string; title: string }) => void
  onFileClick?: (document: ResponseDocument) => void
}

export const FileTable = ({
  elements,
  shownElements,
  resources,
  setElements,
  showHeader,
  showCheckbox = false,
  showRadio = false,
  showDossierDropdown = false,
  showLibraryDropdown = false,
  showFileSize = true,
  showSourceInsteadOfDirectory = false,
  disableInvalidFocusedAnalysisFileTypes = false,
  loading,
  error,
  openFileOnClick = true,
  onRemove,
  onFileClick,
}: FileTableProps) => {
  const navigate = useNavigate()
  const { settings, updateSettings } = useContext(UserContext)

  const [openedFolders, setOpenedFolders] = useState<number[]>([])
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [fileToDelete, setFileToDelete] = useState<ResponseDocument | null>(
    null
  )

  const rootElements =
    elements?.filter((v) => v.parent_element_internal_ids.length === 0) || []
  const showDropdown = showDossierDropdown || showLibraryDropdown

  const handleAnalyseFile = ({ id, title }: { id: string; title: string }) => {
    const updatedSettings = {
      ...settings,
      assistant: {
        ...settings.assistant,
        sources: {
          ...settings.assistant.sources,
          ask: {
            companiesHouseSearch: false,
            financialDataSearch: false,
            outlookSearch: false,
            teamsSearch: false,
            webSearch: false,
            files: [{ id, title }],
            focusedAnalysis: true,
            internalSearch: true,
            filingsSearch: false,
            transcriptsSearch: false,
            companiesHouseFiles: [],
            uploadedFiles: [],
          },
        },
      },
    }
    updateSettings({
      settings: updatedSettings,
    })
    withSettingsSnapshot({
      event: DESIA_EVENT.CHAT_ASK,
      conversationId: 'home',
      settings: updatedSettings,
    })
    navigate('/assistant/ask/')
  }
  const toggleOpenFolder = (id: number) => {
    let updatedOpenedFolders: number[] = []

    if (openedFolders.includes(id)) {
      updatedOpenedFolders = openedFolders.filter((v) => v !== id)
    } else {
      updatedOpenedFolders = [...openedFolders, id]
    }

    setOpenedFolders(updatedOpenedFolders)
    localStorage.setItem('opened_folders', JSON.stringify(updatedOpenedFolders))
  }

  const handleToggleElement = (element: FileStructure) => {
    if (showRadio) {
      const unselectedElements = elements.map((v) => {
        return { ...v, is_included: false }
      })
      toggleElement(element, unselectedElements, setElements)
    } else {
      toggleElement(element, elements, setElements)
    }
  }

  useEffect(() => {
    const openedFoldersString = localStorage.getItem('opened_folders')

    if (!openedFoldersString) return

    const parsedOpenedFolders = JSON.parse(openedFoldersString)

    setOpenedFolders(parsedOpenedFolders)
  }, [])

  return (
    <>
      {!loading && !error && (
        <Table>
          {showHeader && (
            <TableHeader>
              <TableRow>
                {(showCheckbox || showRadio) && (
                  <TableHead className="font-body-strong text-system-primary w-[2.875rem]"></TableHead>
                )}
                <TableHead className="font-body-strong text-system-primary w-[calc(100%-9.625rem-7.5rem-10rem+1px)] shrink">
                  File name
                </TableHead>

                <TableHead className="font-body-strong text-system-primary w-[15%] whitespace-nowrap shrink-0">
                  {showSourceInsteadOfDirectory ? 'Source' : 'Directory'}
                </TableHead>
                {showFileSize && (
                  <TableHead className="font-body-strong text-system-primary w-[8%] whitespace-nowrap shrink-0">
                    File size
                  </TableHead>
                )}
                <TableHead className="font-body-strong text-system-primary w-[15%] whitespace-nowrap shrink-0">
                  Updated on
                </TableHead>
                {showDropdown && (
                  <TableHead className="font-body-strong text-system-primary w-[3.5rem] shrink-0"></TableHead>
                )}
              </TableRow>
            </TableHeader>
          )}

          <TableBody>
            {rootElements.map((d) => (
              <Suspense
                key={`suspense-${d.document_id || d.internal_element_id}`}
              >
                <FileRow
                  key={d.document_id || d.internal_element_id}
                  elements={elements}
                  shownElements={shownElements}
                  resources={resources || []}
                  file={d}
                  level={0}
                  openedFolders={openedFolders}
                  toggleOpenFolder={toggleOpenFolder}
                  toggleElement={handleToggleElement}
                  setFileToDelete={(v) => {
                    setFileToDelete(v)
                    setShowDeleteDialog(true)
                  }}
                  showCheckbox={showCheckbox || false}
                  showRadio={showRadio}
                  showDossierDropdown={showDossierDropdown}
                  showLibraryDropdown={showLibraryDropdown}
                  showFileSize={showFileSize}
                  disableInvalidFocusedAnalysisFileTypes={
                    disableInvalidFocusedAnalysisFileTypes
                  }
                  openFileOnClick={openFileOnClick}
                  onAnalyse={handleAnalyseFile}
                  onRemove={onRemove}
                  onFileClick={onFileClick}
                />
              </Suspense>
            ))}
          </TableBody>
        </Table>
      )}

      {loading && (
        <div>
          <Skeleton className="h-10 m-2" />
          <Skeleton className="h-10 m-2" />
          <Skeleton className="h-10 m-2" />
          <Skeleton className="h-10 m-2" />
          <Skeleton className="h-10 m-2" />
        </div>
      )}

      {!loading && error && (
        <ErrorMessage message="We failed to list resources, please try again shortly" />
      )}

      <DeleteResourceDialog
        resource={fileToDelete}
        open={showDeleteDialog}
        setOpen={(v) => setShowDeleteDialog(v)}
      />
    </>
  )
}
