import { Citation, SourceDocument } from '@/types/types'
import { handleError } from '@/utils/handleError'
import { getUniqueCitationDocuments, sortSourceDocuments } from '@/utils/utils'
import { memo, useCallback, useMemo, useRef } from 'react'
import { PreviewSourceIcon } from './PreviewSourceIcon'

export const CitationTag = memo(
  ({
    citation,
    citations,
    documents,
    scrollToCitation = false,
    openedCitation,
    onCitationOpen,
  }: {
    citation: string
    citations: Citation[]
    documents: SourceDocument[]
    scrollToCitation?: boolean
    openedCitation?: Citation | null
    onCitationOpen?: (citation: Citation | null, scrollTop: number) => void
  }) => {
    const uuid = useRef(crypto.randomUUID().split('-')[0]).current

    try {
      const ref = useRef<HTMLSpanElement>(null)
      const parsedCitation: Citation = useMemo(
        () => JSON.parse(citation),
        [citation]
      )

      const uniqueCitationDocuments = useMemo(() => {
        return getUniqueCitationDocuments(parsedCitation, documents || [])
      }, [parsedCitation, documents])

      const sortedCitationDocuments = useMemo(() => {
        return sortSourceDocuments(uniqueCitationDocuments, parsedCitation)
      }, [uniqueCitationDocuments, parsedCitation])

      const citationWithHighlights = citations.find(
        (v) =>
          JSON.stringify({ ...parsedCitation, highlights: [] }) ===
          JSON.stringify({ ...v, highlights: [] })
      )

      const isSelected =
        JSON.stringify({ ...parsedCitation, highlights: [] }) ===
        JSON.stringify({ ...openedCitation, highlights: [] })

      const handleClick = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
          e.stopPropagation()

          if (isSelected) {
            onCitationOpen?.(null, 0)
          } else {
            if (scrollToCitation) {
              ref.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }

            if (citationWithHighlights) {
              const parentContainer = ref.current?.closest(
                '.ask-full-width-scrollable-container'
              ) as HTMLDivElement

              const rect = ref.current?.getBoundingClientRect()

              if (rect) {
                const parentScrollTop: number =
                  parentContainer?.getBoundingClientRect().top ?? 0
                const invertedParentScrollTop = -parentScrollTop
                const yPosition = rect.top + invertedParentScrollTop

                if (openedCitation) {
                  onCitationOpen?.(citationWithHighlights, yPosition || 0)
                } else {
                  requestAnimationFrame(() => {
                    onCitationOpen?.(citationWithHighlights, yPosition || 0)
                  })
                }
              }
            }
          }
        },
        [
          citationWithHighlights,
          isSelected,
          onCitationOpen,
          openedCitation,
          scrollToCitation,
        ]
      )

      if (sortedCitationDocuments.length === 0) return null

      return (
        <span
          className={`px-[0.5625rem] rounded-full w-fit inner-border ${isSelected ? 'bg-system-border-light inner-border-system-body' : 'bg-system-secondary inner-border-system-regular '} hover:bg-system-hover hover:inner-border-system-placeholder focus:inner-border-2 focus:inner-border-system-primary inline-flex items-center gap-1.5 h-[1.375rem] translate-y-[0.1rem] cursor-pointer`}
          key={`citation-${uuid}`}
          onClick={handleClick}
          ref={ref}
        >
          <span className="inline-flex items-center gap-0.5">
            {sortedCitationDocuments.slice(0, 2).map((doc, index) => (
              <PreviewSourceIcon
                key={`citation-tag-${uuid}-${index}`}
                document={doc}
              />
            ))}
          </span>
          {sortedCitationDocuments.length > 1 && (
            <span className="font-label text-system-body">
              {sortedCitationDocuments.length}
            </span>
          )}
        </span>
      )
    } catch (e) {
      handleError(e)
      return
    }
  }
)

export const DocgenCitationTag = ({
  documents = [],
  selected,
  onClick,
}: {
  documents: SourceDocument[] | null
  selected: boolean
  onClick: () => void
}) => {
  const uuid = useRef(crypto.randomUUID().split('-')[0]).current

  try {
    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation()
      onClick()
    }

    if (!documents || documents.length === 0) return null

    return (
      <span
        className={`px-[0.5625rem] rounded-full w-fit inner-border ${selected ? 'bg-system-border-light inner-border-system-body' : 'bg-system-secondary inner-border-system-regular '} hover:bg-system-hover hover:inner-border-system-placeholder focus:inner-border-2 focus:inner-border-system-primary inline-flex items-center gap-1.5 h-[1.375rem] translate-y-[0px] cursor-pointer`}
        key={`citation-${uuid}`}
        onClick={handleClick}
      >
        <span className="inline-flex items-center gap-0.5">
          {documents.slice(0, 2).map((doc, index) => (
            <PreviewSourceIcon
              key={`citation-tag-${uuid}-${index}`}
              document={doc}
            />
          ))}
        </span>
        {documents.length > 1 && (
          <span className="font-label text-system-body">
            {documents.length}
          </span>
        )}
      </span>
    )
  } catch (e) {
    handleError(e)
    return
  }
}
