import {
  ChatMessage,
  DESIA_EVENT,
  RequestAssistantAsk,
  ResponseChatStream,
  SourceDocument,
  SourceDocumentType,
  SourceType,
  UserMessage,
  WebSocketRequestWrapper,
} from '@/types/types'
import { checkSourceDocumentType, getTimestamp } from '@/utils/utils'
import { Button } from '../ui/button'
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Sources } from './Sources'
import Stack from '@/assets/Stack'
import { getGlobalUniqueDocuments } from '@/utils/components'
import { TypographyBody } from '../ui/Typography'
import { RotateCcw } from 'lucide-react'
import { memo, useContext, useEffect, useState } from 'react'
import shortid from 'shortid'
import { UserContext } from '@/contexts/UserContext'
import { getAskToolEvents, getAskTools } from '@/utils/ask'
import { useSocketQuery } from '@/hooks/useSocketQuery'
import { getLiveConversationById } from './utils'
import { useSelector, useDispatch } from 'react-redux'
import type { RootState, AppDispatch } from '../../store/store'
import { actions as assistantActions } from './assistantSlice'
import { CustomAlert } from '../CustomAlert'
import { deleteLastMessage } from './assistantThunk'
import { PreviewSourceIcon } from './PreviewSourceIcon'

interface Props {
  documents: SourceDocument[]
  compact?: boolean
  maxIcons?: number
  loading?: boolean
  className?: string
  message?: ChatMessage
  sourceType: SourceType
  canRetry?: boolean
}
interface ViewSourcesProps {
  documents: SourceDocument[]
  compact?: boolean
  message?: ChatMessage
  loading?: boolean
  sourceType: SourceType
  canRetry?: boolean
}

export function ViewSources({
  documents,
  compact,
  message,
  loading,
  sourceType,
  canRetry = true,
}: ViewSourcesProps) {
  const [open, setOpen] = useState(false)

  const assistantStore = useSelector((state: RootState) => state.assistant)
  const dispatch = useDispatch<AppDispatch>()
  const { settings } = useContext(UserContext)

  const filteredDocuments = getGlobalUniqueDocuments(documents, false)

  const [selectedDocuments, setSelectedDocuments] = useState(filteredDocuments)
  const [showError, setShowError] = useState(false)

  const requestId = message?.requestId || `new_ask_${shortid()}`

  const conversation = getLiveConversationById({
    store: assistantStore,
    conversationId: message?.conversationId || '',
    requestId,
  })

  const { executeQuery, state: queryState } = useSocketQuery({
    event: DESIA_EVENT.CHAT_ASK,
    request: {
      requestId: requestId,
      timestamp: getTimestamp(),
      params: {},
    },
    options: {
      manual: true,
      callback: (response) => {
        const res = response.data as ResponseChatStream // fixme
        dispatch(
          assistantActions.streamingResponse({
            ...res,
            requestId: response.requestId,
            timestamp: response.timestamp,
          })
        )
      },
    },
  })

  const isLoading = loading || queryState.loading

  function handleSubmit() {
    if (selectedDocuments.length === 0) {
      setShowError(true)
      return
    } else {
      setShowError(false)
    }

    const systemMessageIndex = conversation.findIndex((v) => v === message)
    const userMessage = conversation[systemMessageIndex - 1] as UserMessage
    const timestamp = getTimestamp()
    const query = userMessage.query || ''

    const conversationId = conversation.at(-1)?.conversationId

    if (!conversationId) return

    if (conversation[0]?.conversationId === message?.conversationId) {
      dispatch(assistantActions.removeLastMessage({ conversationId }))
      dispatch(deleteLastMessage(conversationId))
    }

    dispatch(
      assistantActions.followUpAsk({
        conversationId: conversationId,
        requestId: `${requestId}_followup_${conversation.length}`,
        question: query,
        timestamp,
        mode: 'simple',
        tool_events: getAskToolEvents(settings.assistant.sources[sourceType]),
      })
    )

    const request: WebSocketRequestWrapper<RequestAssistantAsk> = {
      requestId,
      timestamp,
      params: {
        message: query,
        mode: 'simple',
        regeneration: true,
        documents: selectedDocuments,
        conversationId: conversationId,
        ...getAskTools(settings.assistant.sources[sourceType]),
      },
    }
    executeQuery({
      event: DESIA_EVENT.CHAT_ASK,
      request,
    })

    setOpen(false)
  }

  useEffect(() => {
    setSelectedDocuments(filteredDocuments)
  }, [loading])

  useEffect(() => {
    setSelectedDocuments(filteredDocuments)
  }, [open])

  return (
    <Dialog open={open} onOpenChange={(v) => setOpen(v)}>
      <DialogTrigger asChild>
        <Button variant={'tertiary'} className="flex gap-2">
          {compact ? (
            <Stack className="h-6 w-6 !stroke-link" />
          ) : (
            <>
              <Stack className="h-6 w-6" />

              <span className="font-body-strong text-system-primary">
                View sources
              </span>
            </>
          )}
        </Button>
      </DialogTrigger>
      <DialogContent className="!w-[calc(100vw-3rem)] tablet:!max-w-[46.875rem] sm:!max-w-[46.875rem] py-8 flex flex-col gap-10 top-6 mobile:top-20 translate-y-0">
        <DialogHeader>
          <DialogTitle>Sources</DialogTitle>
          <DialogDescription className="text-system-body font-body">
            View or select which sources to look through for the response
          </DialogDescription>
        </DialogHeader>
        <DialogBody>
          <div className="grid grid-cols-1">
            <Sources
              documents={filteredDocuments}
              showTabs={true}
              selectable={sourceType !== 'report' && canRetry}
              selectedDocuments={selectedDocuments}
              setSelectedDocuments={setSelectedDocuments}
            />
          </div>
        </DialogBody>
        {selectedDocuments.length !== filteredDocuments.length && canRetry && (
          <DialogFooter>
            <div className="flex flex-col gap-3 w-full">
              {showError && (
                <CustomAlert
                  variant="error"
                  title="You cannot retry with no sources selected"
                  description="Make sure at least one source is selected before continuing"
                />
              )}

              <div className="w-fit ml-auto">
                <Button disabled={isLoading}>
                  <div className="flex gap-2">
                    <RotateCcw className="size-6 shrink-0 stroke-[1.5px]" />

                    <TypographyBody
                      className="flex mobile:!hidden"
                      onClick={() => handleSubmit()}
                    >
                      Retry response
                    </TypographyBody>

                    <TypographyBody
                      className="hidden mobile:!flex"
                      onClick={() => handleSubmit()}
                    >
                      Retry response with selected sources
                    </TypographyBody>
                  </div>
                </Button>
              </div>
            </div>
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  )
}

export const PreviewSources = memo(
  ({ documents, compact, loading, message, className, sourceType, canRetry = true }: Props) => {
    if (documents.length === 0)
      return <div className="w-full m-h-40px my-2"></div>

    const nonChartDocuments = getGlobalUniqueDocuments(documents, false).filter(
      (v) =>
        !v.document_id.includes('chart') && !v.document_id.includes('table')
    )
    const webpages = nonChartDocuments.filter((d) => {
      const sourceType = checkSourceDocumentType(d.document_id, d.doc_metadata)
      return sourceType === SourceDocumentType.WEB
    })

    const communicationDocuments = nonChartDocuments.filter((d) => {
      const sourceType = checkSourceDocumentType(d.document_id, d.doc_metadata)
      return (
        sourceType === SourceDocumentType.OUTLOOK ||
        sourceType === SourceDocumentType.TEAMS
      )
    })
    const internalDocuments = nonChartDocuments.filter((d) => {
      const sourceType = checkSourceDocumentType(d.document_id, d.doc_metadata)
      return (
        sourceType === SourceDocumentType.LIBRARY ||
        sourceType === SourceDocumentType.DESIA_LIBRARY ||
        sourceType === SourceDocumentType.SHAREPOINT ||
        sourceType === SourceDocumentType.ONEDRIVE
      )
    })

    const databaseDocuments = nonChartDocuments.filter((d) => {
      const sourceType = checkSourceDocumentType(d.document_id, d.doc_metadata)
      return (
        sourceType === SourceDocumentType.FINANCIAL_DATA ||
        sourceType === SourceDocumentType.COMPANIES_HOUSE ||
        sourceType === SourceDocumentType.FILINGS ||
        sourceType === SourceDocumentType.TRANSCRIPTS
      )
    })

    return (
      <div className={`$w-full ${className || ''}`}>
        <div className="flex flex-wrap gap-4 justify-between items-center">
          <div className="flex gap-6 items-center">
            {internalDocuments.length > 0 && (
              <div className="flex gap-2 items-center">
                <span className="font-label text-system-body">Library</span>

                <div className="flex gap-1">
                  {internalDocuments.slice(0, 2).map((v, i) => (
                    <PreviewSourceIcon
                      key={`internal-icon-${i}`}
                      document={v}
                    />
                  ))}
                </div>
                <span className="font-label-strong text-system-primary">
                  {internalDocuments.length}
                </span>
              </div>
            )}
            {communicationDocuments.length > 0 && (
              <div className="flex gap-2 items-center">
                <span className="font-label text-system-body">
                  Communications
                </span>

                <div className="flex gap-1">
                  {communicationDocuments.slice(0, 2).map((v, i) => (
                    <PreviewSourceIcon
                      key={`communication-icon-${i}`}
                      document={v}
                    />
                  ))}
                </div>
                <span className="font-label-strong text-system-primary">
                  {communicationDocuments.length}
                </span>
              </div>
            )}
            {databaseDocuments.length > 0 && (
              <div className="flex gap-2 items-center">
                <span className="font-label text-system-body">Database</span>

                <div className="flex gap-1">
                  {databaseDocuments.slice(0, 2).map((v, i) => (
                    <PreviewSourceIcon
                      key={`database-icon-${i}`}
                      document={v}
                    />
                  ))}
                </div>
                <span className="font-label-strong text-system-primary">
                  {databaseDocuments.length}
                </span>
              </div>
            )}
            {webpages.length > 0 && (
              <div className="flex gap-2 items-center">
                <span className="font-label text-system-body">Web</span>

                <div className="flex gap-1">
                  {webpages.slice(0, 2).map((v, i) => {
                    return (
                      <PreviewSourceIcon
                        key={`webpage-icon-${i}`}
                        document={v}
                      />
                    )
                  })}
                </div>
                <span className="font-label-strong text-system-primary">
                  {webpages.length}
                </span>
              </div>
            )}
          </div>
          <ViewSources
            documents={nonChartDocuments}
            compact={compact}
            message={message}
            loading={loading}
            sourceType={sourceType}
            canRetry={canRetry}
          />
        </div>
      </div>
    )
  }
)
