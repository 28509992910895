import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog'
import { TypographyBody } from '../ui/Typography'
import { Button, secondaryStyle } from '../ui/button'
import Divider from '../ui/divider'
import { FileUp, PencilLine } from 'lucide-react'
import ArrowRight from '@/assets/ArrowRight'
import { Link } from 'react-router-dom'

export const DocGenCreateTemplateDialog = ({
  open,
  setOpen,
  onFileSelectClick,
}: {
  open: boolean
  setOpen: (open: boolean) => void
  onFileSelectClick: () => void
}) => {
  return (
    <Dialog open={open} onOpenChange={(v) => setOpen(v)}>
      <DialogContent className="min-w-[40rem] max-w-[40rem]">
        <DialogHeader>
          <DialogTitle>Create template from file</DialogTitle>
          <DialogDescription>
            The selected file will affect the outline but not the content of
            further reports
          </DialogDescription>
        </DialogHeader>

        <DialogBody>
          <div className="flex flex-col gap-8">
            <Button
              variant="secondary"
              className="w-fit"
              onClick={onFileSelectClick}
            >
              <div className="flex gap-2 items-center">
                <FileUp className="size-6 shrink-0 stroke-interactive" />
                Select file
              </div>
            </Button>

            <div className="flex flex-col gap-6">
              <div className="flex gap-4">
                <Divider />
                <TypographyBody className="text-system-primary">
                  OR
                </TypographyBody>
                <Divider />
              </div>

              <Link to={`/template/new`}>
                <div
                  className={`flex gap-4 items-center rounded-lg cursor-pointer ${secondaryStyle}`}
                >
                  <PencilLine className="size-6 shrink-0 stroke-interactive" />

                  <div className="flex flex-col gap-1 text-left w-full">
                    <TypographyBody isStrong={true}>
                      Create custom style
                    </TypographyBody>

                    <TypographyBody className="text-system-body">
                      Define your preferred document outline from scratch
                    </TypographyBody>
                  </div>

                  <ArrowRight className="size-6 shrink-0 stroke-interactive" />
                </div>
              </Link>
            </div>
          </div>
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}
