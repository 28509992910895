import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ProgressTracker } from './ProgressTracker'
import { useEffect, useState } from 'react'
import { DocGenTemplate } from './DocGenTemplate'
import { DocGenTopic } from './DocGenTopic'
import { DocGenOutline } from './DocGenOutline'
import { DocGenDraft } from './DocGenDraft'
import { Button } from '../ui/button'
import { ArrowLeft } from 'lucide-react'
import { DocgenStep, DocgenTemplate, QueryStatus } from '@/types/types'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import { fetchSession, fetchSessions, fetchTemplate } from './docGenThunk'
import { isFulfilled } from '@reduxjs/toolkit'
import { setSessionInUse } from './docGenSlice'
import { DocGenCustomization } from './DocGenCustomization'

export const DocGenPage = () => {
  const { session_id } = useParams()

  const docGenStore = useSelector((state: RootState) => state.docGen)

  const [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('step')

  const [template, setTemplate] = useState<DocgenTemplate | null>(null)
  const session = docGenStore.sessionInUse
  const [topic, setTopic] = useState<string | null>(null)

  const pages = [
    DocgenStep.TEMPLATE,
    DocgenStep.CUSTOMIZATION,
    DocgenStep.TOPIC,
    DocgenStep.OUTLINE,
    DocgenStep.DRAFT,
  ]

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const handleNextClick = () => {
    switch (page) {
      case DocgenStep.TEMPLATE:
        setSearchParams({ step: DocgenStep.CUSTOMIZATION })
        break
      case DocgenStep.CUSTOMIZATION:
        setSearchParams({ step: DocgenStep.TOPIC })
        break
      case DocgenStep.TOPIC:
        setSearchParams({ step: DocgenStep.OUTLINE })
        break
      case DocgenStep.OUTLINE:
        setSearchParams({ step: DocgenStep.DRAFT })
        break
      case DocgenStep.DRAFT:
        break
    }
  }

  const handlePrevClick = () => {
    switch (page) {
      case DocgenStep.TEMPLATE:
        navigate(-1)
        break
      case DocgenStep.CUSTOMIZATION:
        setSearchParams({ step: DocgenStep.TEMPLATE })
        break
      case DocgenStep.TOPIC:
        setSearchParams({ step: DocgenStep.CUSTOMIZATION })
        break
      case DocgenStep.OUTLINE:
        setSearchParams({ step: DocgenStep.TOPIC })
        break
      case DocgenStep.DRAFT:
        setSearchParams({ step: DocgenStep.OUTLINE })
        break
    }
  }

  const handleNavigateToTopic = () => {
    setSearchParams({ step: DocgenStep.TOPIC })
  }

  const refreshSession = async (sessionId: string) => {
    if (session !== null && session.id === sessionId) return
    if (session && session.id !== sessionId) {
      dispatch(setSessionInUse(null))
    }

    const result = await dispatch(
      fetchSession({
        sessionId,
        refetch: true,
      })
    )
    if (isFulfilled(fetchSession)(result)) {
      dispatch(setSessionInUse(result.payload))
      setTopic(result.payload.topic)
    }
  }

  const refetchHiddenTemplate = async (id: string) => {
    const templateResult = await dispatch(fetchTemplate(id))

    if (isFulfilled(fetchTemplate)(templateResult)) {
      setTemplate(templateResult.payload)
    }
  }

  useEffect(() => {
    if (session_id) {
      if (docGenStore.sessions.status === QueryStatus.SUCCEEDED) {
        const foundSession =
          docGenStore.sessions.data.find((v) => v.id === session_id) || null

        if (foundSession) {
          const foundTemplate =
            docGenStore.templates.find(
              (v) => v.id === foundSession.template?.id
            ) || null
          setTopic(foundSession.topic || '')
          refreshSession(session_id)
          if (foundTemplate) {
            setTemplate(foundTemplate)
          } else {
            if (foundSession.template?.id) {
              refetchHiddenTemplate(foundSession.template.id)
            }
          }
        } else {
          refreshSession(session_id)
          console.error(`Session with ID ${session_id} not found.`)
        }
      } else {
        console.log(`Unexpected session status: ${docGenStore.sessions.status}`)
      }
    }
  }, [session_id, docGenStore.sessions, docGenStore.templates])

  return (
    <div className="relative flex flex-col gap-6 sm:!-mt-10 !-mb-10 md:!-mb-20 mx-[5.5rem]">
      <Button
        variant="tertiary"
        onClick={() => navigate('/reports')}
        className="fixed top-[4.75rem] left-[7.5rem]"
      >
        {page === DocgenStep.TEMPLATE ? (
          <div className="flex gap-2 items-center">Cancel</div>
        ) : (
          <div className="flex gap-2 items-center">
            <ArrowLeft className="size-6 shrink-0 stroke-[1.5px]" />
            Reports
          </div>
        )}
      </Button>

      {page !== DocgenStep.DRAFT && (
        <div className="py-5 w-fit mx-auto">
          <ProgressTracker
            labels={pages.slice(0, 4)}
            completedLabels={pages.slice(
              0,
              pages.findIndex((v) => v === page)
            )}
            currentLabels={[page || DocgenStep.TEMPLATE]}
          />
        </div>
      )}
      {/* TODO: change all these components to load "session" from the state instead of passing it via prop. this will lead to less prop-drilling */}
      {page === DocgenStep.TEMPLATE && (
        <DocGenTemplate
          onNextClick={handleNextClick}
          onSubmit={(v) => setTemplate(v)}
        />
      )}
      {page === DocgenStep.CUSTOMIZATION && (
        <DocGenCustomization
          template={template}
          onNextClick={handleNextClick}
          onPrevClick={handlePrevClick}
          onSubmit={(v) => setTemplate(v)}
          onSessionCreate={(v) => dispatch(setSessionInUse(v))}
        />
      )}
      {page === DocgenStep.TOPIC && (
        <DocGenTopic
          session={session}
          template={template}
          onNextClick={handleNextClick}
          onPrevClick={handlePrevClick}
          onSessionCreate={(v) => dispatch(setSessionInUse(v))}
          onTopicChange={(v) => setTopic(v)}
          onNavigateToTopic={handleNavigateToTopic}
        />
      )}
      {page === DocgenStep.OUTLINE && (
        <DocGenOutline
          template={template}
          topic={topic}
          onNextClick={handleNextClick}
        />
      )}
      {page === DocgenStep.DRAFT && (
        <DocGenDraft session={session} template={template} topic={topic} />
      )}
    </div>
  )
}

export const DocGenContainer = () => {
  const docGenStore = useSelector((state: RootState) => state.docGen)

  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    if (docGenStore.sessions.status === QueryStatus.INITIALISED) {
      dispatch(fetchSessions())
    }
  }, [dispatch, docGenStore.sessions.status])

  return <DocGenPage />
}
