import { Citation, DocgenCitation } from '../types/types'
import { handleError } from './handleError'
import { checkIfStringHasContent } from './utils'

export const chartRegex =
  /!\[((?:[^\[\]]|\[[^\[\]]*\])*)\]\(((?:[^()]|\([^()]*\))*)\)/g

export const embedCharts = ({ text }: { text: string }) => {
  return text.replaceAll(chartRegex, (_, _title, graphName) => {
    // Remove any leading/trailing whitespace from graphName
    const cleanGraphName: string = graphName.trim()
    if (cleanGraphName.startsWith('chart')) {
      return `<Chart id={${cleanGraphName}} />`
    } else {
      return `<Table id={${cleanGraphName}} />`
    }
  })
}

export const getChartIds = (text: string): string[] => {
  const graphNames: string[] = []
  let match

  while ((match = chartRegex.exec(text)) !== null) {
    const graphName = match[2].trim()
    if (graphName) {
      graphNames.push(graphName)
    }
  }

  return graphNames
}

export const embedCitationsV4 = (text: string, citations: DocgenCitation[]) => {
  text = text.replace(/]\s*\[/g, '][')

  const regex = /\[\d+\]/g
  let matches = [...text.matchAll(regex)].map((match) => ({
    fullMatch: match[0],
    number: match[0].slice(1, -1),
    index: match.index,
  }))

  matches = matches.filter((m) =>
    citations.some((v) => JSON.stringify(v.citation_uuid) === m.number)
  )

  if (matches.length === 0) return text

  let result = ''
  let lastIndex = 0
  let currentGroup: number[] = []

  for (let i = 0; i < matches.length; i++) {
    const { fullMatch, number, index } = matches[i]
    if (
      currentGroup.length > 0 &&
      matches[i - 1].index + matches[i - 1].fullMatch.length === index
    ) {
      currentGroup.push(parseInt(number))
    } else {
      if (currentGroup.length > 0) {
        result += ` <DocGenCitationTag id={[${currentGroup.join(', ')}]} />`
        currentGroup = []
      }

      result += text.slice(lastIndex, index)
      currentGroup.push(parseInt(number))
    }

    lastIndex = index + fullMatch.length
  }

  if (currentGroup.length > 0) {
    result += ` <DocGenCitationTag id={[${currentGroup.join(', ')}]} />`
  }

  result += text.slice(lastIndex)

  return result
}

export const getCitationIds = (text: string): number[] => {
  if (!text) return []

  const regex = /\[\d+\]/g
  const matches = text.match(regex)

  const uniqueMatches = Array.from(new Set(matches)).sort((a, b) => {
    const numberAInsideBrackets = a.slice(1, a.length - 1)
    const numberBInsideBrackets = b.slice(1, b.length - 1)

    return parseInt(numberAInsideBrackets) - parseInt(numberBInsideBrackets)
  })

  return uniqueMatches.map((match) =>
    parseInt(match.slice(1, match.length - 1))
  )
}

export function embedCitationsV5(props: {
  text: string
  citations: Citation[]
  isComplete: boolean
  isChartOrTable: boolean
}): string {
  const { text, citations } = props
  try {
    if (!checkIfStringHasContent(text)) return text

    const citationsCopy = [...citations]
    const input = text
    let pointer = 0
    const orderedCitations = citationsCopy.sort(
      (a, b) => Number(a.end) - Number(b.end)
    )
    // show dot cursor iff answer is still streaming
    // n.b. citations are loaded after answer streamed
    const cursor = `<Cursor show={${props.isComplete === false && !citations.length}} />`
    if (!text) return `${cursor}`
    if (!citations || citations.length === 0) return `${text}${cursor}`

    const chartIds = text.match(chartRegex)
    let mutableResult = input
    for (let i = 0; i < orderedCitations.length; i++) {
      const citation = orderedCitations[i]
      if (chartIds?.find((v) => v.includes(citation.text))) {
        continue
      }
      if (citation.chart_table_id && !props.isChartOrTable) continue
      if (citation.document_ids.length === 0) continue
      if (citation.end < 0) continue
      // highlights aren't parsing correctly
      const post = `&nbsp;<CitationTag citation={${JSON.stringify({ ...citation, highlights: [] })}}/>`
      const left = mutableResult.slice(0, pointer + citation.end)
      const right = mutableResult.slice(pointer + citation.end)
      mutableResult = `${left}${post}${right}`
      pointer += post.length
    }
    // adding <span> prefix breaks tables in some cases
    const result = `${mutableResult}${cursor}`
    // ``` markdown isn't rendered correctly so we have to replace it here
    const formattedResult = result.replace(/```([^`]*)```/g, '<pre>$1</pre>')
    return formattedResult
  } catch (e) {
    handleError(e)
    return text
  }
}

export const embedTableCitation = ({
  text,
  citation,
}: {
  text: string
  citation?: Citation
}) => {
  if (!citation) return text
  const embeddedText = `<NumberCitation citation={${JSON.stringify({ ...citation, highlights: [] })}}>${text}</NumberCitation>`

  return embeddedText
}

export const embedWordFade = (text: string, isFinished: boolean) => {
  const lastText = text.slice(-40, -30)
  const lastText1 = text.slice(-30, -20)
  const lastText2 = text.slice(-20, -10)
  const lastText3 = text.slice(-10)

  const returnedText =
    text.slice(0, -40) +
    `<w index={${8}}>${lastText.replaceAll('*', '').replaceAll(' ', '&nbsp;')}</w>` +
    `<w index={${6}}>${lastText1.replaceAll('*', '').replaceAll(' ', '&nbsp;')}</w>` +
    `<w index={${4}}>${lastText2.replaceAll('*', '').replaceAll(' ', '&nbsp;')}</w>` +
    `<w index={${2}}>${lastText3.replaceAll('*', '').replaceAll(' ', '&nbsp;')}</w>`

  return returnedText

  if (isFinished) return text
  const splitText = text.split(' ')

  const mappedText = splitText.map((v, index) => {
    if (index > splitText.length - 10) {
      return `&nbsp;<w index={${splitText.length - index}}'>${v.replaceAll('*', '')}</w>`
    }

    return v
  })

  return (
    mappedText.slice(0, -10).join(' ') + ' ' + mappedText.slice(-10).join('')
  )
}
