import { format, isValid } from 'date-fns'
import { Color } from '@/constants'
import { Citation, ResponseChartData } from '@/types/types'
import {
  CHART_MEDIUM_WORDS_LENGTH,
  CHART_SHORT_WORDS_LENGTH,
} from '@/constants'

const LOW_CONTRAST_COLOR_INDEX: ReadonlySet<number> = new Set([0, 2])
const X_LABEL_CATEGORY_TRUNCATION_THRESHOLD = 5 as const

export const isLowContrast = (configColorIndex: number): boolean =>
  LOW_CONTRAST_COLOR_INDEX.has(configColorIndex)

export const formatChartDate = (dateString: string | string[]) => {
  const joinedDate = Array.isArray(dateString)
    ? dateString.join(' ')
    : dateString
  const date = new Date(joinedDate)

  const valid = isValid(date)

  if (valid && joinedDate.includes('-')) {
    const currentDate = new Date()
    const formattedDate =
      currentDate.getFullYear() === date.getFullYear()
        ? format(date, 'd MMM')
        : format(date, 'd MMM yyyy')
    return formattedDate
  }

  return dateString
}

export const formatTruncateDataLabel = ({
  label,
  maxChar = 5,
  categoryAmount,
}: {
  label: string
  maxChar?: number
  categoryAmount?: number
}) => {
  if (categoryAmount && categoryAmount <= X_LABEL_CATEGORY_TRUNCATION_THRESHOLD)
    return label

  if (label.length <= maxChar || maxChar <= 3) {
    return label
  }

  const visibleChars = maxChar - 3
  const truncated = label.slice(0, visibleChars) + '...'

  return truncated
}

export const pxToCh = (width: number, fontSize: number) => {
  const charCount = width / fontSize

  return Math.ceil(charCount)
}

export const getChartCitation = (
  data: number | string,
  citations: Citation[],
  dataPointIndex: number,
  seriesIndex?: number
): Citation | undefined => {
  if (!data) return undefined

  try {
    return citations.find(
      (v) =>
        JSON.stringify(parseFloat(v.text)) === JSON.stringify(data) &&
        v.index_x === dataPointIndex &&
        (seriesIndex === undefined || v.index_y === seriesIndex)
    )
  } catch {
    console.error('Citations are unavailable')
  }
}

export const getIsDataPointSelected = (
  data: number | string,
  openedCitation: Citation | null | undefined,
  dataPointIndex: number,
  seriesIndex?: number
): boolean => {
  if (!openedCitation) return false

  try {
    return (
      JSON.stringify(parseFloat(openedCitation.text)) ===
        JSON.stringify(data) &&
      openedCitation.index_x === dataPointIndex &&
      openedCitation.index_y === seriesIndex
    )
  } catch {
    console.error('Citations are unavailable')
    return false
  }
}

export const getChartColor = (index: number): string => {
  const colors = [
    Color['system-chart-1'],
    Color['system-chart-2'],
    Color['system-chart-3'],
    Color['system-chart-4'],
    Color['system-chart-5'],
    Color['system-chart-6'],
    Color['system-chart-7'],
    Color['system-chart-8'],
    Color['system-chart-9'],
    Color['system-chart-10'],
  ]

  return colors[index % colors.length]
}

export const getSeries = (data: ResponseChartData): ApexAxisChartSeries => {
  return data.values.map((v, idx) => {
    return {
      name: v,
      data: data.data.map((v) => v.value[idx] ?? 0),
    }
  })
}

export const getCategories = (data: ResponseChartData): string[][] => {
  return data.data.map((v) => {
    const words = v.label.split(' ')
    const formattedWords: string[] = []
    let i = 0
    while (i < words.length) {
      if (words[i].length > CHART_SHORT_WORDS_LENGTH) {
        formattedWords.push(words[i])
        i++
      } else {
        if (
          i + 1 < words.length &&
          words[i + 1].length <= CHART_MEDIUM_WORDS_LENGTH
        ) {
          formattedWords.push(`${words[i]} ${words[i + 1]}`)
          i += 2
        } else {
          formattedWords.push(words[i])
          i++
        }
      }
    }

    return formattedWords
  })
}

export const redrawMarkers = () => {
  const markerElements = document.querySelectorAll(
    '.apexcharts-legend-marker'
  ) as NodeListOf<HTMLElement>

  markerElements.forEach((marker) => {
    marker.style.width = '0.75rem'
    marker.style.height = '0.75rem'
    marker.style.marginRight = '2px'
    marker.style.borderRadius = '2px'
    marker.style.overflow = 'hidden'
    marker.style.minWidth = '0.75rem'
    marker.style.minHeight = '0.75rem'
  })
}
