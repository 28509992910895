import { ResponseDocument } from '@/types/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ImpromptuUploadState {
  temporaryDocuments: { [documentId: string]: Partial<ResponseDocument> }
  uploadQueue: {
    name: string
    type: string
  }[]
}

const initialState: ImpromptuUploadState = {
  temporaryDocuments: {},
  uploadQueue: [],
}

export const impromptuUploadSlice = createSlice({
  name: 'impromptuUpload',
  initialState,
  reducers: {
    removeTemporaryDocument(state, action: PayloadAction<string>) {
      const documentId = action.payload
      delete state.temporaryDocuments[documentId]
    },
    addTemporaryDocuments(
      state,
      action: PayloadAction<(Partial<ResponseDocument> | null)[]>
    ) {
      action.payload.forEach((document) => {
        if (document && document.document_id) {
          state.temporaryDocuments[document.document_id] = document
        }
      })
    },
    setTemporaryDocuments(
      state,
      action: PayloadAction<{ [documentId: string]: Partial<ResponseDocument> }>
    ) {
      state.temporaryDocuments = action.payload
    },
    clearTemporaryDocuments(state) {
      state.temporaryDocuments = {}
    },
    addToUploadQueue(
      state,
      action: PayloadAction<
        {
          name: string
          type: string
        }[]
      >
    ) {
      state.uploadQueue.push(...action.payload)
    },
    clearUploadQueue(state) {
      state.uploadQueue = []
    },
    resetUploadState(state) {
      state.temporaryDocuments = {}
      state.uploadQueue = []
    },
  },
})

export const actions = impromptuUploadSlice.actions
export default impromptuUploadSlice.reducer
