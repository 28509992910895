import {
  IntegrationCode,
  SourceDocument,
  SourceDocumentType,
} from '@/types/types'
import {
  getDatabaseIcon,
  getFileIcon,
  getIntegrationIcon,
} from '@/utils/components'
import {
  checkSourceDocumentType,
  getDatabaseName,
  getIconSrc,
  getIntegrationName,
} from '@/utils/utils'
import { CustomTooltip } from '../CustomTooltip'
import { TypographyLabel } from '../ui/Typography'
import { useRef } from 'react'
import { createPortal } from 'react-dom'

export const PreviewSourceIcon = ({
  document: d,
  size = 14,
  isSelected = false,
  iconContainerClassName,
  onClick,
}: {
  document: SourceDocument
  size?: number
  isSelected?: boolean
  iconContainerClassName?: string
  onClick?: () => void
}) => {
  const sourceDocumentType = checkSourceDocumentType(
    d.document_id,
    d.doc_metadata
  )
  const isWeb = sourceDocumentType === SourceDocumentType.WEB
  const isCommunication =
    sourceDocumentType === SourceDocumentType.OUTLOOK ||
    sourceDocumentType === SourceDocumentType.TEAMS

  const isDatabase =
    sourceDocumentType === SourceDocumentType.FINANCIAL_DATA ||
    sourceDocumentType === SourceDocumentType.COMPANIES_HOUSE ||
    sourceDocumentType === SourceDocumentType.FILINGS ||
    sourceDocumentType === SourceDocumentType.TRANSCRIPTS

  const iconContainerStyle = `border border-system-border-light ${size > 14 ? 'rounded-sm' : 'rounded-[3px]'} flex justify-center items-center ${isSelected ? 'ring-1 ring-ring ring-system-body !border-system-body' : ''} ${onClick ? 'cursor-pointer' : ''} ${iconContainerClassName} ${sourceDocumentType === SourceDocumentType.COMPANIES_HOUSE ? '!bg-black' : 'bg-system-secondary'}`
  const iconStyle = `!shrink-0 ${size > 14 ? '!w-4 !max-h-4 !min-w-4' : 'max-w-full max-h-full'}`
  const uuid = useRef(crypto.randomUUID().split('-')[0]).current
  try {
    if (isDatabase) {
      return (
        <>
          <span
            style={{ width: `${size}px`, height: `${size}px` }}
            className={`relative ${iconContainerStyle}`}
            onClick={onClick}
            data-tooltip-id={`database-preview-source-icon-tooltip-${uuid}`}
          >
            {getDatabaseIcon(sourceDocumentType, `${iconStyle} rounded-[2px]`)}
          </span>
          {createPortal(
            <CustomTooltip
              id={`database-preview-source-icon-tooltip-${uuid}`}
              className="!py-1 !px-3 !rounded-sm !inner-border-0"
              largeArrow={false}
            >
              <TypographyLabel className="text-system-primary">
                {getDatabaseName(sourceDocumentType)}
              </TypographyLabel>
            </CustomTooltip>,
            document.body
          )}
        </>
      )
    }

    if (isCommunication) {
      const isOutlook = sourceDocumentType === SourceDocumentType.OUTLOOK
      return (
        <>
          <span
            style={{ width: `${size}px`, height: `${size}px` }}
            className={`relative ${iconContainerStyle} p-0.5`}
            onClick={onClick}
            data-tooltip-id={`communication-preview-source-icon-tooltip-${uuid}`}
          >
            {getIntegrationIcon(
              isOutlook ? IntegrationCode.OUTLOOK : IntegrationCode.TEAMS,
              true,
              iconStyle
            )}
          </span>
          {createPortal(
            <CustomTooltip
              id={`communication-preview-source-icon-tooltip-${uuid}`}
              className="!py-1 !px-3 !rounded-sm !inner-border-0"
              largeArrow={false}
            >
              <TypographyLabel className="text-system-primary">
                {getIntegrationName(
                  d.doc_metadata?.document_source_details?.integration_code_name
                )}
              </TypographyLabel>
            </CustomTooltip>,
            document.body
          )}
        </>
      )
    }

    if (isWeb) {
      const { hostname } = new URL(d.url || '')
      const src = getIconSrc(hostname)
      return (
        <>
          <span
            key={`favicon-${hostname}-web`}
            onClick={onClick}
            data-tooltip-id={`web-preview-source-icon-tooltip-${uuid}`}
          >
            <img
              src={src}
              style={{ width: `${size}px`, height: `${size}px` }}
              className={iconContainerStyle}
            />
          </span>
          {createPortal(
            <CustomTooltip
              id={`web-preview-source-icon-tooltip-${uuid}`}
              className="!py-1 !px-3 !rounded-sm !inner-border-0"
              largeArrow={false}
            >
              <TypographyLabel className="text-system-primary">
                {hostname.replace('www.', '')}
              </TypographyLabel>
            </CustomTooltip>,
            document.body
          )}
        </>
      )
    }

    const isIntegration =
      sourceDocumentType === SourceDocumentType.SHAREPOINT ||
      sourceDocumentType === SourceDocumentType.ONEDRIVE

    return (
      <>
        <span
          style={{ width: `${size}px`, height: `${size}px` }}
          className={`relative ${iconContainerStyle}`}
          onClick={onClick}
          data-tooltip-id={`file-preview-source-icon-tooltip-${uuid}`}
        >
          {!isIntegration &&
            getFileIcon(
              d.doc_metadata?.document_type_friendly ||
                d.title.split('.').at(-1) ||
                '',
              iconStyle
            )}

          {isIntegration &&
            getIntegrationIcon(
              d.doc_metadata?.document_source_details?.integration_code_name ||
                '',
              true,
              iconStyle
            )}
        </span>
        {createPortal(
          <CustomTooltip
            id={`file-preview-source-icon-tooltip-${uuid}`}
            className="!py-1 !px-3 !rounded-sm !inner-border-0"
            largeArrow={false}
          >
            <TypographyLabel className="text-system-primary">
              {d.title}
            </TypographyLabel>
          </CustomTooltip>,
          document.body
        )}
      </>
    )
  } catch {
    return null
  }
}
