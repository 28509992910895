import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { useRef, useState } from 'react'
import { DialogClose } from '@radix-ui/react-dialog'
import {
  ASYNC_STATUS,
  FileResource,
  ResourceVisibility,
  ResponseSignedUrlUpload,
  ResponseSignedUrlsUpload,
  UploadableFile,
} from '@/types/types'
import { MAX_UPLOAD_FILES, WEB_SERVER_ENDPOINT } from '@/constants'
import { Badge } from '../ui/badge'
import { TypographyBody, TypographyLabel } from '../ui/Typography'
import { FileUp, X } from 'lucide-react'
import { RadioGroup, RadioGroupItem } from '../ui/radio'
import { getFileIcon } from '@/utils/components'
import { plural } from '@/utils/utils'
import { useContext } from 'react'
import { ToastContext } from '@/contexts/ToastContext'
import { handleError } from '@/utils/handleError'

function SelectedFile({
  file,
  onRemove,
}: {
  file: UploadableFile
  onRemove: () => void
}) {
  const fileExists = file.signedUpload?.exists

  return (
    <div className="flex gap-1 p-3 border border-system-border-light rounded-sm">
      <div className="flex gap-2 items-center mr-auto">
        {getFileIcon(file.file.type, '!size-6')}

        <TypographyLabel
          className={`text-system-body ${fileExists ? 'max-w-[13.75rem]' : 'max-w-[22.5rem]'} text-ellipsis overflow-hidden whitespace-nowrap`}
        >
          {file.file.name}
        </TypographyLabel>
      </div>
      {fileExists ? (
        <Badge variant="blue" className="mr-1 whitespace-nowrap">
          Possible duplicate
        </Badge>
      ) : (
        ''
      )}
      <Button variant="tertiary" className="" onClick={() => onRemove()}>
        <X className="size-6 shrink-0 stroke-[1.5px]" />
      </Button>
    </div>
  )
}

export function MultiFileUpload({
  onSubmit,
}: {
  onSubmit: (
    uploadedFiles: UploadableFile[],
    visibility: ResourceVisibility
  ) => void
}) {
  const [showDialog, setShowDialog] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState<UploadableFile[]>([])
  const [dragOver, setDragOver] = useState(false)
  const [visibility, setVisibility] =
    useState<ResourceVisibility>('organization')
  const { showToast } = useContext(ToastContext)

  const containerRef = useRef<HTMLDivElement | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)

  async function getSignedUrls(
    resources: FileResource[]
  ): Promise<ResponseSignedUrlsUpload> {
    const res = await fetch(
      `${WEB_SERVER_ENDPOINT}/api/document/upload-signed-urls`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          resources: resources,
        }),
        credentials: 'include',
      }
    )
    if (!res.ok) {
      throw new Error("Couldn't upload files")
    }
    return await res.json()
  }

  async function uploadSignedFile(
    file: File,
    signedUpload: ResponseSignedUrlUpload
  ): Promise<string> {
    const res = await fetch(signedUpload.signed_url, {
      method: 'put',
      headers: {
        ...signedUpload.headers,
        'Content-Type': 'application/octet-stream',
      },
      body: file,
    })
    if (!res.ok) {
      throw new Error('failed to upload file')
    }
    return await res.text()
  }

  async function handleFileChange(files: FileList | null) {
    if (!files) return
    if (files.length + selectedFiles.length > MAX_UPLOAD_FILES) {
      alert(`You can only upload ${MAX_UPLOAD_FILES} files at a time`)
      return
    }

    const uploadableFiles = Array.from(files).map((file) => ({
      file,
      signedUpload: null,
      upload: { status: ASYNC_STATUS.idle },
    }))
    const result = await Promise.all(uploadableFiles)
    const filteredFiles = result.filter(
      (
        v
      ): v is {
        file: File
        signedUpload: null
        upload: {
          status: ASYNC_STATUS
        }
      } => Boolean(v)
    )

    const newFiles = filteredFiles.map((v) => v.file)
    const oldFiles = selectedFiles.filter(
      (v) =>
        !newFiles.find((f) => f.size === v.file.size && f.name === v.file.name)
    )
    setSelectedFiles([...oldFiles, ...filteredFiles])
  }

  function updateFileStatus(file: File, status: ASYNC_STATUS) {
    setSelectedFiles((prev) => {
      if (!prev) return prev
      return prev.map((f) => {
        if (f.file.name === file.name) {
          return { ...f, upload: { status } }
        }
        return f
      })
    })
  }

  async function uploadFiles() {
    if (!selectedFiles) return
    toggleDialog()

    let updatedFiles

    try {
      const fileResources = selectedFiles.map((file) => {
        return {
          resource_name: file.file.name,
          resource_visibility: visibility,
          resource_overwrite: true,
          resource_shared_with_user_ids: [],
        } as FileResource
      })

      const response = await getSignedUrls(fileResources)
      updatedFiles = selectedFiles.map((file) => {
        const signedUpload =
          response.signed_urls.find((s) => s.file_name === file.file.name) ||
          null
        return { ...file, signedUpload }
      })
      setSelectedFiles(updatedFiles)
    } catch (error) {
      showToast({
        variant: 'error',
        description: 'Failed to upload files',
        dismissable: true,
      })
      return
    }

    const uploadPromises = updatedFiles.map(async (s) => {
      if (!s.signedUpload) {
        return true
      }
      updateFileStatus(s.file, ASYNC_STATUS.loading)
      try {
        await uploadSignedFile(s.file, s.signedUpload)
        updateFileStatus(s.file, ASYNC_STATUS.success)
        return true
      } catch (error) {
        handleError(error)
        updateFileStatus(s.file, ASYNC_STATUS.error)
        return false
      }
    })

    const results = await Promise.all(uploadPromises)

    onSubmit(updatedFiles, visibility)

    // If all uploads were successful, clear the selected files
    if (results.every((result) => result === true)) {
      setSelectedFiles([])
    } else {
      showToast({
        variant: 'error',
        description: 'Failed to upload files',
        dismissable: true,
      })
    }
  }

  function toggleDialog() {
    if (showDialog) {
      // reset state
      setSelectedFiles([])
    }
    setShowDialog((prev) => !prev)
  }

  const computedSelectedFiles = selectedFiles || []
  const uploadDisabled = computedSelectedFiles.length === 0
  const countFiles = computedSelectedFiles.length
  const ctaLabel = `Upload files ${countFiles > 0 ? `(${countFiles})` : ''}`
  return (
    <div
      ref={containerRef}
      style={{
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%2394A3B8FF' stroke-width='3' stroke-dasharray='8%2c 16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
      }}
      className={`flex flex-col gap-6 ${dragOver ? 'bg-system-hover' : 'bg-system-surface'} p-6 border-system-placeholder rounded-[12px]`}
      onDragLeave={(e) => {
        e.preventDefault()
        e.stopPropagation()

        const rect = containerRef.current?.getBoundingClientRect()
        if (!rect) return
        if (
          e.clientX < rect.left ||
          e.clientX > rect.right ||
          e.clientY < rect.top ||
          e.clientY > rect.bottom
        ) {
          setDragOver(false)
        }
      }}
      onDragOver={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setDragOver(true)
      }}
      onDrop={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setDragOver(false)
        setShowDialog(true)
        handleFileChange(e.dataTransfer.files)
      }}
    >
      <div className="flex flex-col gap-2 text-center">
        <TypographyBody isStrong={true}>
          Enhance the platform with internal knowledge
        </TypographyBody>

        <TypographyLabel className="text-system-body whitespace-pre-wrap">
          {`To expand and take advantage of Desia's capabilities,\ndrag and drop files here or use the button below`}
        </TypographyLabel>
      </div>

      <div className="w-fit mx-auto">
        <Dialog open={showDialog} onOpenChange={toggleDialog}>
          <DialogTrigger asChild>
            <Button>
              <div className="flex gap-2">
                <FileUp className="w-6 h-6 shrink-0 stroke-[1.5px]" />
                Upload files
              </div>
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[32.5rem] overflow-x-hidden">
            <DialogHeader>
              <DialogTitle>Upload files</DialogTitle>
              <DialogDescription className="text-system-body whitespace-pre-wrap">
                {`Supported file types include .pdf, .csv, .txt, .docx, .pptx, .xlsx.`}
              </DialogDescription>
            </DialogHeader>
            <DialogBody className="flex flex-col gap-10 mt-8">
              <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-4">
                  <label htmlFor="file-upload">
                    <Button
                      variant="secondary"
                      onClick={() => inputRef.current?.click()}
                    >
                      Add file(s)
                    </Button>
                  </label>

                  <TypographyBody className="text-system-body">
                    {computedSelectedFiles.length > 0
                      ? `${computedSelectedFiles.length} ${plural('file', computedSelectedFiles.length)} selected`
                      : 'No file added yet'}
                  </TypographyBody>
                </div>
                <div
                  className={`max-h-[calc(100vh-37.5rem)] laptop:max-h-[22.5rem] overflow-y-auto flex flex-col gap-2`}
                >
                  {computedSelectedFiles.map((s) => (
                    <SelectedFile
                      key={s.file.name}
                      file={s}
                      onRemove={() => {
                        setSelectedFiles([
                          ...selectedFiles.filter((v) => v !== s),
                        ])
                      }}
                    />
                  ))}
                </div>
              </div>
              <div className="flex flex-col gap-3">
                <TypographyBody isStrong={true}>
                  File access type
                </TypographyBody>

                <RadioGroup
                  value={visibility}
                  onValueChange={(e) => setVisibility(e as ResourceVisibility)}
                >
                  <RadioGroupItem
                    checked={visibility === 'organization'}
                    value="organization"
                    id="r1"
                    label="Shared"
                    description="People in your organisation will be able to view and use these files"
                  />
                  <RadioGroupItem
                    checked={visibility === 'private'}
                    value="private"
                    id="r1"
                    label="Private"
                    description="Only you will be able to view and use these files"
                  />
                </RadioGroup>
              </div>
            </DialogBody>
            <DialogFooter className="flex flex-row justify-end gap-2 mt-2">
              <DialogClose>
                <Button variant={'secondary'}>Cancel</Button>
              </DialogClose>
              <Button
                type="submit"
                onClick={uploadFiles}
                disabled={uploadDisabled}
              >
                {ctaLabel}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
      <input
        ref={inputRef}
        id="file-upload"
        type="file"
        name="file"
        onChange={(e) => handleFileChange(e.currentTarget.files)}
        className="hidden"
        multiple={true}
      />
    </div>
  )
}
