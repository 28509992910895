import { z } from 'zod'
import { Button } from '../ui/button'
import {
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog'
import { Input } from '../ui/input'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useRef, useState } from 'react'
import { Form, FormControl, FormField, FormItem, FormMessage } from '../ui/form'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import { createTemplateCategory, updateTemplateCategory } from './docGenThunk'
import { isFulfilled } from '@reduxjs/toolkit'
import { addTemplateCategory, replaceTemplateCategory } from './docGenSlice'
import { TemplateCategory } from '@/types/types'
import { Loader2 } from 'lucide-react'

export const DocGenCreateCategoryDialog = ({
  category,
  open,
  setOpen,
}: {
  category?: TemplateCategory
  open: boolean
  setOpen: (open: boolean) => void
}) => {
  const templateCategories = useSelector(
    (state: RootState) => state.docGen.templateCategories
  )

  const [loading, setLoading] = useState(false)

  const formSchema = z.object({
    title: z
      .string()
      .min(1, 'Please enter a title')
      .refine(
        (name) => !templateCategories.data.find((v) => v.title === name),
        () => ({
          message: `There is already a category with the same name. Please choose a different one.`,
        })
      )
      .refine(
        (name) => name.toLowerCase() !== 'Desia templates'.toLowerCase(),
        () => ({
          message: `There is already a category with the same name. Please choose a different one.`,
        })
      ),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: category?.title || '',
    },
  })

  const formRef = useRef<HTMLFormElement>(null)

  const dispatch = useDispatch<AppDispatch>()

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    if (category?.id) {
      setLoading(true)
      const result = await dispatch(
        updateTemplateCategory({
          ...category,
          title: values.title,
          name: values.title,
          description: category.description,
        })
      )
      setLoading(false)
      if (isFulfilled(updateTemplateCategory)(result)) {
        dispatch(replaceTemplateCategory(result.payload))

        setOpen(false)
      }
    } else {
      setLoading(true)
      const result = await dispatch(
        createTemplateCategory({
          title: values.title,
          name: values.title,
          description: '',
        })
      )
      setLoading(false)
      if (isFulfilled(createTemplateCategory)(result)) {
        dispatch(addTemplateCategory(result.payload))

        setOpen(false)
      }
    }
  }

  useEffect(() => {
    if (category) {
      form.setValue('title', category.title)
    }
  }, [category, form])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="!max-w-[26.25rem]">
        <DialogHeader>
          <DialogTitle>
            {category ? 'Rename category' : 'Add category'}
          </DialogTitle>
        </DialogHeader>
        <DialogBody>
          <Form {...form}>
            <form
              ref={formRef}
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-2 overflow-y-auto p-1"
            >
              <div>
                <FormField
                  control={form.control}
                  name="title"
                  render={({ field }) => (
                    <FormItem>
                      <FormMessage />
                      <FormControl>
                        <Input
                          {...field}
                          placeholder="e.g. Financial due dilligence"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
            </form>
          </Form>
        </DialogBody>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">Discard</Button>
          </DialogClose>
          <Button onClick={() => formRef.current?.requestSubmit()}>
            <div className='flex gap-2 items-center'>
            {category ? 'Confirm and rename' : 'Confirm and add'}

            {loading && <Loader2 className='size-6 shrink-0 stroke-interactive animate-spin'/>}
            </div>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
