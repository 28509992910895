import { Citation, SourceDocument } from '../../types/types'
import { Skeleton } from '@/components/ui/skeleton'
import { embedCharts, embedCitationsV5 } from '../../utils/embedCitations'
import { ResponsiveContainer } from './ResponsiveContainer'
import { memo, ReactNode, useCallback } from 'react'
import { ResponseMarkdown } from './ResponseMarkdown'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import { updateOpenedCitation } from '../DocGen/docGenSlice'
import { CitationTag } from './CitationTag'
import { NumberCitation } from './NumberCitation'
import { useAnimatedText } from './useAnimatedText'

type Props = {
  isLoading: boolean
  text: string
  isComplete: boolean
  documents: SourceDocument[]
  citations: Citation[]
  compact?: boolean
  scrollToCitation?: boolean
  legacyCitation?: boolean
  openedCitation?: Citation | null
  onCitationOpen?: (citation: Citation | null, scrollTop: number) => void
  isExpand?: boolean
  isShouldShift?: boolean
}

interface AnswerBlockProps {
  text: string
  citations: Citation[]
  documents: SourceDocument[]
  isComplete: boolean
  compact?: boolean
  scrollToCitation?: boolean
  openedCitation?: Citation | null
  onCitationOpen?: (citation: Citation | null, scrollTop: number) => void
  isExpand?: boolean
  isShouldShift?: boolean
}

interface CitedTextProps {
  text: string
  citations: Citation[]
  documents: SourceDocument[]
  isComplete: boolean
  scrollToCitation?: boolean
  openedCitation?: Citation | null
  onCitationOpen?: (citation: Citation | null, scrollTop: number) => void
  isExpand?: boolean
  isShouldShift?: boolean
}

export function LoadingBlocks() {
  return (
    <ResponsiveContainer>
      <Skeleton className="w-4/6 h-[1.25rem] my-2" />
      <Skeleton className="w-full h-[1.25rem] my-2" />
      <Skeleton className="w-full h-[1.25rem] my-2" />
      <Skeleton className="w-5/6 h-[1.25rem] my-2" />
    </ResponsiveContainer>
  )
}

const CitedText = memo(
  ({
    text,
    citations,
    documents,
    isComplete,
    scrollToCitation = false,
    openedCitation = null,
    onCitationOpen,
    isExpand,
    isShouldShift
  }: CitedTextProps) => {
    const documentStore = useSelector((state: RootState) => state.document)

    const animatedText = useAnimatedText(text)

    const embeddedCitations = embedCitationsV5({
      text: isComplete ? text : animatedText,
      citations,
      isComplete,
      isChartOrTable: false,
    })
    const embeddedCharts = embedCharts({ text: embeddedCitations })

    return (
      <ResponseMarkdown
        text={embeddedCharts}
        documents={documents}
        citations={citations}
        overrides={{
          DocGenCitationTag: {
            component: ({ id }: { id: number }) => {
              const citation = citations.find(
                // @ts-expect-error without the parseInt, its treated as string
                (v) => v.citation_uuid === parseInt(id)
              )

              const document =
                documentStore.files.find((v) =>
                  citation?.document_ids.includes(v.document_id)
                ) || null

              if (!citation || !document) return null

              const sourceDocument = {
                document_id: document.document_id,
                doc_metadata: { ...document },
                title: document.document_name,
                url: document.document_secure_shared_link,
                text: document.document_name,
              }
              if (!citation) return null
              return (
                <CitationTag
                  citation={JSON.stringify(citation)}
                  citations={citations}
                  documents={[sourceDocument]}
                  openedCitation={openedCitation}
                  scrollToCitation={false}
                  onCitationOpen={onCitationOpen}
                />
              )
            },
          },
          CitationTag: {
            component: ({ citation }: { citation: string }) => {
              return (
                <CitationTag
                  citation={citation}
                  citations={citations}
                  documents={documents}
                  scrollToCitation={scrollToCitation || false}
                  openedCitation={openedCitation}
                  onCitationOpen={onCitationOpen}
                />
              )
            },
          },
          w: {
            component: ({
              children,
              index,
            }: {
              children: ReactNode
              index: string
            }) => {
              const parsedIndex = parseInt(index)
              return (
                <span
                  className="opacity-0 transition-opacity duration-500 whitespace-pre-wrap"
                  style={{ opacity: `${parsedIndex * 10}%` }}
                >
                  {children}
                </span>
              )
            },
          },
          NumberCitation: {
            component: ({
              children,
              citation,
            }: {
              children: ReactNode
              citation: string
            }) => {
              return (
                <NumberCitation
                  children={children}
                  citation={citation}
                  citations={citations}
                  documents={documents}
                  scrollToCitation={scrollToCitation || false}
                  openedCitation={openedCitation}
                  onCitationOpen={onCitationOpen}
                />
              )
            },
          },
        }}
        isTable={false}
        openedCitation={openedCitation || null}
        onCitationOpen={onCitationOpen}
        isExpand={isExpand}
        isShouldShift={isShouldShift}
      />
    )
  }
)

const AnswerBlock = memo(
  ({
    text,
    citations,
    documents,
    isComplete,
    compact,
    scrollToCitation,
    openedCitation,
    onCitationOpen,
    isExpand,
    isShouldShift
  }: AnswerBlockProps) => {
    return (
      <ResponsiveContainer>
        <div
          className={`text-system-body leading-7 ${compact ? '!font-label' : '!font-body'}`}
        >
          <span className="">
            <CitedText
              text={text}
              citations={citations}
              documents={documents}
              isComplete={isComplete}
              openedCitation={openedCitation}
              onCitationOpen={onCitationOpen}
              scrollToCitation={scrollToCitation}
              isExpand={isExpand}
              isShouldShift={isShouldShift}
            />
          </span>
        </div>
      </ResponsiveContainer>
    )
  }
)

export const FinalAnswer = memo(
  ({
    isLoading,
    isComplete,
    text,
    citations,
    documents,
    compact,
    scrollToCitation,
    legacyCitation,
    openedCitation,
    onCitationOpen,
    isExpand,
    isShouldShift
  }: Props) => {
    const reportOpenedCitation = useSelector(
      (state: RootState) => state.docGen.openedCitation
    )

    const dispatch = useDispatch<AppDispatch>()

    const onLegacyCitationOpen = useCallback((citation: Citation | null) => {
      dispatch(updateOpenedCitation(citation))
    }, [])

    if (isLoading) {
      return <LoadingBlocks />
    }

    return (
      <>
        <AnswerBlock
          text={text}
          citations={citations}
          documents={documents}
          isComplete={isComplete}
          compact={compact}
          openedCitation={
            legacyCitation ? reportOpenedCitation : openedCitation
          }
          onCitationOpen={
            legacyCitation ? onLegacyCitationOpen : onCitationOpen
          }
          scrollToCitation={scrollToCitation}
          isExpand={isExpand}
          isShouldShift={isShouldShift}
        />
      </>
    )
  }
)
