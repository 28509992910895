'use client'

import * as React from 'react'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { Circle } from 'lucide-react'
import { cn } from '@/shadcn/utils'
import { TypographyBody, TypographyLabel } from './Typography'

export interface RadioGroupItemProps
  extends React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> {
  label?: string
}

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn('grid gap-4', className)}
      {...props}
      ref={ref}
    />
  )
})
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  RadioGroupItemProps & {
    description?: string
  }
>(({ className, label, description, ...props }, ref) => {
  const radioId = React.useRef(crypto.randomUUID())

  return (
    <div className="flex gap-3 items-start">
      <RadioGroupPrimitive.Item
        id={radioId.current}
        ref={ref}
        className={cn(
          'aspect-square h-5 w-5 my-0.5 bg-white rounded-full border-[1.25px] border-system-border-regular text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
          className
        )}
        {...props}
      >
        <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
          <Circle className="h-2.5 w-2.5 fill-system-primary text-system-primary" />
        </RadioGroupPrimitive.Indicator>
      </RadioGroupPrimitive.Item>

      <div className="flex flex-col gap-0">
        {label && (
          <label htmlFor={radioId.current} className="cursor-pointer">
            <TypographyBody className="whitespace-pre-wrap">
              {label}
            </TypographyBody>
          </label>
        )}

        {description && (
          <div>
            <TypographyLabel className="whitespace-pre-wrap text-system-body">
              {description}
            </TypographyLabel>
          </div>
        )}
      </div>
    </div>
  )
})
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName

export { RadioGroup, RadioGroupItem }
