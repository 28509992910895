import { useNavigate } from 'react-router-dom'
import { ReactNode, useContext, useEffect } from 'react'
import { UserContext } from '@/contexts/UserContext'
import {
  ASYNC_STATUS,
  DESIA_EVENT,
  ResponseChatList,
  UserFeatureFlag,
} from '@/types/types'
import { checkIfUserFlagExists, getTimestamp, setUserFlag } from '@/utils/utils'
import { Main } from './Main'
import { useSocketQuery } from '@/hooks/useSocketQuery'
import { Toast } from '../ui/toast'
import { Navbar } from './Navbar'
import { Sidebar } from './Sidebar'
import { LayoutContext } from '@/contexts/LayoutContext'
import { userFeatureFlags } from '@/constants'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState, AppDispatch } from '../../store/store'
import { fetchDossiers } from '../Dossier/dossierSlice'
import { actions as assistantActions } from '../Assistant/assistantSlice'
import { LoadingAnimation } from '../LoadingAnimation/LoadingAnimation'
import { fetchThemePreferences } from '../Theme/themeThunks'

type LayoutContainerProps = {
  component: ReactNode
  tall?: boolean
  scrollable?: boolean
  noPadding?: boolean
}

type LayoutProps = {
  component: ReactNode
  email: string
  tall?: boolean
  scrollable?: boolean
  noPadding?: boolean
}

export function LayoutContainer({
  component,
  tall,
  scrollable,
  noPadding,
}: LayoutContainerProps) {
  const dispatch = useDispatch<AppDispatch>()
  const assistantStore = useSelector((state: RootState) => state.assistant)
  const dossiers = useSelector((state: RootState) => state.dossier.dossiers)
  const { user } = useContext(UserContext)
  const { state, executeQuery } = useSocketQuery({
    event: DESIA_EVENT.CHAT_LIST,
    request: {
      requestId: 'list',
      timestamp: getTimestamp(),
      params: {},
    },
    options: {
      manual: true,
      cacheTimeoutMs: 60_000,
      callback: (response) => {
        dispatch(
          assistantActions.fetchThreads(response.data as ResponseChatList[])
        )
      },
    },
  })

  useEffect(() => {
    if (user && assistantStore.list.length === 0 && !state.error) {
      executeQuery({
        event: DESIA_EVENT.CHAT_LIST,
        request: {
          userId: user?.sub,
          requestId: 'list',
          timestamp: getTimestamp(),
          params: {},
        },
      })
    }
  }, [user])

  useEffect(() => {
    if (dossiers.status === ASYNC_STATUS.idle) {
      dispatch(fetchDossiers())
    }
  }, [dossiers.status, dispatch])

  useEffect(() => {
    dispatch(fetchThemePreferences())
  }, [dispatch])

  return (
    <Layout
      component={component}
      email={user?.email || '...'}
      tall={tall}
      scrollable={scrollable}
      noPadding={noPadding}
    />
  )
}

export function Layout({
  component,
  tall,
  scrollable,
  noPadding,
}: LayoutProps) {
  const layoutContext = useContext(LayoutContext)

  const navigate = useNavigate()

  const sidebarStyle = `${layoutContext.showSidebar ? 'translate-x-0' : '-translate-x-full'} bg-system-surface z-[20] border-r flex h-screen flex-col gap-10 fixed overflow-y-auto px-8 pt-6 pb-12 max-w-[20rem] transition-all`

  useEffect(() => {
    // redirect user to a url after refresh, current use case is redirect to integration loading structure if the user refreshes the page
    const urlLock = localStorage.getItem(`url_lock`)

    if (urlLock) {
      navigate(urlLock)
    }
  }, [])

  useEffect(() => {
    const toggledByDefault: UserFeatureFlag[] = [
      'docgen: ask',
      'docgen: entity extraction',
      'docgen: factCheck primary entity context',
      'docgen: factCheck regen answer',
      'assistant: tools',
    ]

    userFeatureFlags.forEach((v) => {
      if (!checkIfUserFlagExists(v) && toggledByDefault.includes(v)) {
        setUserFlag(v, true)
      }
    })
  }, [])

  return (
    <div
      id="main-layout"
      className="flex w-full h-screen relative overflow-hidden"
    >
      <div className="flex flex-col flex-grow">
        <Navbar />
        <Main
          noPadding={noPadding}
          component={component}
          tall={tall}
          scrollable={scrollable}
        />
      </div>
      {layoutContext.showSidebar && (
        <div
          className="fixed top-0 left-0 z-[19] bg-black/[8%] backdrop-blur-sm w-full h-full"
          onClick={() => layoutContext.toggleShowSidebar(false)}
        ></div>
      )}

      <div className={sidebarStyle}>
        <Sidebar />
      </div>

      <Toast id="global" />
      <LoadingAnimation />
    </div>
  )
}
