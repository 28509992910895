import { ASYNC_STATUS, Integration, IntegrationStatus } from '@/types/types'
import { TypographyBody, TypographyH3 } from '../ui/Typography'
import { AppDispatch, RootState } from '@/store/store'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useContext, useEffect } from 'react'
import { fetchIntegrations, fetchIntegrationStatus } from './integrationSlice'
import { checkIntegrationFlag } from '@/utils/utils'
import { FeatureFlagContext } from '@/contexts/FeatureFlagContext'
import { UserContext } from '@/contexts/UserContext'
import { Plus } from 'lucide-react'
import { MicrosftIntegrationCard } from './MicrosoftIntegrationCard'
import { isFulfilled } from '@reduxjs/toolkit'
import Divider from '../ui/divider'
import { Button } from '../ui/button'
import { Link } from 'react-router-dom'
import { ColorSwatch } from '../Theme/ColorSwatch'

export interface IntergrationPageProps {
  integrations: Integration[]
  integrationStatus: {
    [id: number]: {
      data: IntegrationStatus | null
      status: 'idle' | 'loading' | 'error' | 'success'
    }
  }
}

export const IntegrationPage = () => {
  const { theme } = useSelector((state: RootState) => state.theme)
  return (
    <div className="flex flex-col  w-full laptop:w-[70rem] mx-auto min-h-[calc(100vh-3.125rem-7.5rem)] gap-10">
      <div className="text-center">
        <TypographyH3>Admin settings</TypographyH3>
      </div>

      <section className="flex flex-col w-full gap-10">
        <div>
          <TypographyBody isStrong className="text-system-primary">
            Integrations
          </TypographyBody>
          <TypographyBody className="font-label text-system-body">
            Connect your essential business systems to streamline your workflow
          </TypographyBody>
        </div>

        <div className="grid grid-cols-2 tablet:grid-cols-3 laptop:grid-cols-4 gap-6 w-fit">
          <MicrosftIntegrationCard />

          <div
            style={{
              backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23C8D3DFFF' stroke-width='3' stroke-dasharray='4%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
            }}
            className="flex flex-col gap-2 p-3 items-center justify-center size-[13.75rem] rounded-[16px] bg-system-surface-light"
          >
            <Plus className="size-6 shrink-0 stroke-[1.5px] stroke-system-body" />
            <TypographyBody isStrong className="text-system-body">
              More coming soon...
            </TypographyBody>
          </div>
        </div>
      </section>

      <section className="gap-6 flex flex-col w-full h-fit">
        <Divider />
        <div>
          <TypographyBody isStrong className="text-system-primary">
            Theme settings
          </TypographyBody>

          <TypographyBody className="font-label text-system-body">
            Customize charts according to your company guidelines
          </TypographyBody>
        </div>

        <div className="flex w-full gap-4 flex-wrap">
          {theme.colors.map((color, idx) => {
            return (
              <ColorSwatch
                value={color}
                key={`swatch-${idx}`}
                colorIndex={idx + 1}
                isPicker={false}
              />
            )
          })}
        </div>

        <Link to="/integrations/themes">
          <Button variant="secondary" className="w-fit">
            Preview and edit colors
          </Button>
        </Link>
      </section>
    </div>
  )
}

export const IntegrationPageContainer = () => {
  const integrations = useSelector(
    (state: RootState) => state.integration.integrations
  )

  const ff = useContext(FeatureFlagContext)
  const { user } = useContext(UserContext)

  const integrationFlag = checkIntegrationFlag(ff, user)

  const dispatch = useDispatch<AppDispatch>()

  const fetchStatus = useCallback(
    (integrations: Integration[]) => {
      integrations.forEach((integration) => {
        if (integration.integration_is_enabled) {
          dispatch(fetchIntegrationStatus(integration.integration_id))
        }
      })
    },
    [dispatch]
  )

  const refreshIntegrations = useCallback(async () => {
    const result = await dispatch(fetchIntegrations())
    if (isFulfilled(fetchIntegrations)(result)) {
      fetchStatus(result.payload)
    }
  }, [dispatch, fetchStatus])

  useEffect(() => {
    if (integrations.status === ASYNC_STATUS.idle) {
      refreshIntegrations()
    } else {
      fetchStatus(integrations.data || [])
    }
  }, [fetchStatus, integrations.data, refreshIntegrations, integrations.status])

  useEffect(() => {
    const intervalId = setInterval(
      () => fetchStatus(integrations.data || []),
      10000
    )

    return () => clearInterval(intervalId)
  }, [integrations.data, fetchStatus])

  useEffect(() => {
    document.title = 'Desia AI - Integrations'
  }, [])

  if (!integrationFlag) return null

  return <IntegrationPage />
}
