import { TABLE_CONFIG } from '@/constants'
import { renderToStaticMarkup } from 'react-dom/server'
import { ResponseTable } from '@/components/Table/ResponseTable'
import {
  applyInlineStyles,
  formatDisplayNumber,
  formatNumberString,
  reformatTableDate,
} from './utils'
import { cn } from '@/shadcn/utils'
import { Citation, TableType } from '@/types/types'
import { embedCitationsV5, embedTableCitation } from './embedCitations'

export const parseHeaderForDates = (header: string): boolean => {
  const trimmedHeader = header.trim().toLowerCase()

  const isYear = /^(year|years|yr|yrs|annually|annual|per\s?year)$/.test(
    trimmedHeader
  )
  const isQuarter = /^(quarter|qtr|q|quarters|quarterly)$/.test(trimmedHeader)

  const isSemester = /^(semester|sem|half-year|h1|h2|half)$/.test(trimmedHeader)
  const isWeek = /^(week|weeks|wk|wks|weekly|per\s?week)$/.test(trimmedHeader)

  const isMonth = /^(month|months|mo|mos|monthly)$/.test(trimmedHeader)

  const isDay = /^(day|days|daily|date|dates)$/.test(trimmedHeader)

  const isOtherDateFormats = ['period', 'time'].some((sub) =>
    trimmedHeader.includes(sub)
  )

  return (
    isYear ||
    isQuarter ||
    isSemester ||
    isWeek ||
    isMonth ||
    isDay ||
    isOtherDateFormats
  )
}

export const calculateCellWidth = ({
  texts,
  headerCell,
}: {
  texts: string[]
  headerCell: HTMLTableCellElement | null
}): number => {
  const textLengths = texts.map((f) => f.length)
  const averageLength =
    textLengths.length > 0
      ? textLengths.reduce((a, b) => a + b, 0) / textLengths.length
      : 0

  const averageWidth = Math.min(
    averageLength * TABLE_CONFIG.ESTIMATED_FONT_WIDTH,
    TABLE_CONFIG.MAX_CELL_WIDTH
  )

  // if header takes up more than 2 lines, prioritize header
  const usedWidth =
    (texts[0].length * TABLE_CONFIG.ESTIMATED_FONT_WIDTH) / averageWidth > 2
      ? (texts[0].length * TABLE_CONFIG.ESTIMATED_FONT_WIDTH) / 2 // prioritize header divide into two
      : averageWidth // use avg width

  const adjustedWidth =
    (headerCell?.offsetHeight ?? 0 > TABLE_CONFIG.MAX_CELL_HEIGHT)
      ? Math.max(
          usedWidth * TABLE_CONFIG.RESIZE_FACTOR,
          TABLE_CONFIG.MIN_CELL_WIDTH
        )
      : usedWidth

  return Math.min(
    adjustedWidth + TABLE_CONFIG.PADDING,
    TABLE_CONFIG.MAX_CELL_WIDTH
  )
}

export const copyResponse = async ({
  id,
  data,
}: {
  id: string
  data: string
}) => {
  const compiledText = (
    <>
      <ResponseTable
        key={`response-table-container-${id}`}
        id={id}
        data={data}
        documents={[]}
        citations={[]}
        openedCitation={null}
        showCopyButton={false}
        isFormatted={false}
        type={TableType.DEFAULT}
      />
    </>
  )

  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = renderToStaticMarkup(compiledText)
  document.body.appendChild(tempDiv)

  applyInlineStyles(['font-weight'], tempDiv)

  const styledText = tempDiv.innerHTML

  document.body.removeChild(tempDiv)

  await navigator.clipboard.write([
    new ClipboardItem({
      'text/html': new Blob([styledText], { type: 'text/html' }),
    }),
  ])
}

export const getTableResponsiveRules = ({
  tableWidth,
  isExpand,
  isShouldShift,
}: {
  tableWidth: number
  isExpand: boolean
  isShouldShift: boolean
}) => {
  const isMoreThanThreshold = tableWidth > TABLE_CONFIG.SCROLL_THRESHOLD
  return cn('flex relative w-full mt-8', {
    // table width rules
    'justify-start mr-[50vw] w-fit': isExpand && isMoreThanThreshold,
    'justify-center': isExpand && !isMoreThanThreshold,
    'w-full': isExpand && isShouldShift && window.outerWidth >= 2560,

    // window width rules
    'ml-[6vw]':
      isExpand &&
      isMoreThanThreshold &&
      window.outerWidth >= 0 &&
      window.outerWidth <= 1800,
    'ml-[8vw] mr-[50vw]':
      isExpand &&
      isMoreThanThreshold &&
      window.outerWidth > 1800 &&
      window.outerWidth <= 2000,
    'ml-[15vw] mr-[40vw]':
      isExpand &&
      isMoreThanThreshold &&
      window.outerWidth > 2000 &&
      window.outerWidth <= 2100,
    'ml-[16vw] mr-[40vw]':
      isExpand &&
      isMoreThanThreshold &&
      window.outerWidth > 2100 &&
      window.outerWidth <= 2200,
    'ml-[17vw] mr-[40vw]':
      isExpand &&
      isMoreThanThreshold &&
      window.outerWidth > 2200 &&
      window.outerWidth <= 2300,
    'ml-[18vw] mr-[40vw]':
      isExpand &&
      isMoreThanThreshold &&
      window.outerWidth > 2300 &&
      window.outerWidth <= 2400,
    'ml-[19vw] mr-[35vw]':
      isExpand &&
      isMoreThanThreshold &&
      window.outerWidth > 2400 &&
      window.outerWidth <= 2560,
    'mr-[30vw]': isExpand && isMoreThanThreshold && window.outerWidth > 2560,

    // openedCitation rules
    'w-fit ml-[6vw]':
      isExpand &&
      isShouldShift &&
      window.outerWidth >= 0 &&
      window.outerWidth <= 1800,
    'w-fit ml-[8vw]':
      isExpand &&
      isShouldShift &&
      window.outerWidth > 1800 &&
      window.outerWidth <= 2000,
    'w-fit ml-[15vw]':
      isExpand &&
      isShouldShift &&
      window.outerWidth > 2000 &&
      window.outerWidth <= 2100,
    'w-fit ml-[16vw]':
      isExpand &&
      isShouldShift &&
      window.outerWidth > 2100 &&
      window.outerWidth <= 2200,
    'w-fit ml-[17vw]':
      isExpand &&
      isShouldShift &&
      window.outerWidth > 2200 &&
      window.outerWidth <= 2300,
    'w-fit ml-[18vw]':
      isExpand &&
      isShouldShift &&
      window.outerWidth > 2300 &&
      window.outerWidth <= 2400,
    'w-fit ml-[19vw]':
      isExpand &&
      isShouldShift &&
      window.outerWidth > 2400 &&
      window.outerWidth <= 2560,
  })
}

export const getTableCellData = ({
  value,
  citations,
  x,
  y,
  isFormatted = true,
  maxDecimalPlaces,
  addNotAvailabletext,
}: {
  value: string | number
  citations: Citation[]
  x: number
  y: number
  isFormatted: boolean
  maxDecimalPlaces: number
  addNotAvailabletext: boolean
}): {
  embeddedText: string
  relevantCitations: Citation[]
} => {
  const str = typeof value === 'number' ? `${value}` : reformatTableDate(value) //fixme

  const formattedStr = isFormatted
    ? formatDisplayNumber(str, maxDecimalPlaces)
    : str

  const relevantCitations = citations.filter(
    (citation) => citation.index_x === x && citation.index_y === y + 1
  )

  const isNumberCell = /^\d+$/.test(formatNumberString(formattedStr))

  const embeddedText = isNumberCell
    ? embedTableCitation({
        text: formattedStr || (addNotAvailabletext ? 'N/A' : ''),
        citation: relevantCitations[0],
      })
    : embedCitationsV5({
        text: formattedStr || (addNotAvailabletext ? 'N/A' : ''),
        citations: relevantCitations,
        isComplete: true,
        isChartOrTable: true,
      }).replaceAll('‎', '')

  return {
    relevantCitations,
    embeddedText,
  }
}
