import { CircleCheck, Waypoints } from 'lucide-react'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../ui/accordion'
import { TypographyBody, TypographyLabel } from '../ui/Typography'
import Divider from '../ui/divider'
import { SourceDocument, ToolEvent, ToolEvents } from '@/types/types'
import { useEffect, useMemo, useState } from 'react'
import { Badge } from '../ui/badge'
import { PreviewSourceIcon } from '../Assistant/PreviewSourceIcon'
import IconThinkingStepsTable from '@/assets/icon-thinking-steps-table.png'
import IconThinkingStepsChart from '@/assets/icon-thinking-steps-chart.png'
import { formatDate } from 'date-fns'
import { SourceIcon } from '../Source'
import { motion, AnimatePresence } from 'framer-motion'
import { sortToolEvents } from '@/utils/utils'

const getToolName = (tool: ToolEvent) => {
  switch (tool.tool_name) {
    case 'company_search':
      return 'Company research'
    case 'internal_search_tool':
      return 'Internal data research'
    case 'financial_data_tool':
      return 'Financial data research'
    case 'web_search_tool':
      return 'Web research'
    case 'generate_table_tool':
    case 'generate_graph_tool':
      return 'Charts & tables generation'
    case 'companies_house_search':
      return 'Company House research'
    case 'msft_outlook_search_tool':
      return 'Outlook research'
    case 'msft_teams_search_tool':
      return 'Teams research'
    case 'people_search':
      return 'People research'
    case 'generate_gemini_content':
      return tool.params.thinking_message || 'Financials data extraction'
    case 'process_file':
      return 'File analysis'
    case 'transcripts_search':
      return 'Transcripts data research'
    case 'filings_search':
      return 'Filings data research'
    default:
      return tool.tool_name
  }
}

const formatFinancialDataDate = (date: string | undefined) => {
  if (!date) return
  return formatDate(date, 'd MMM yyyy')
}

const getBadgeText = (step: ToolEvent) => {
  switch (step.tool_name) {
    case 'company_search':
      return step.params.company_name || ''
    case 'internal_search_tool':
      return step.params.search_query
    case 'financial_data_tool':
      return `${step.params.symbol} from ${formatFinancialDataDate(step.params.from_date || '')} to ${formatFinancialDataDate(step.params.to_date || '')}`
    case 'web_search_tool':
      return step.params.search_query
    case 'companies_house_search':
    case 'filings_search':
    case 'transcripts_search':
      return step.params.search_query
    case 'generate_table_tool':
    case 'generate_graph_tool':
      return 'Charts & tables generation'
    case 'people_search':
      return `${step.params.first_name || ''} ${step.params.last_name || ''}`
    case 'msft_outlook_search_tool':
    case 'msft_teams_search_tool':
      return step.params.KQL_search_query
    default:
      step.params.search_query || ''
  }
}

const groupThinkingSteps = (steps: ToolEvent[]) => {
  const result: { [tool_name: string]: ToolEvent[] } = {}

  steps.forEach((v) => {
    result[v.tool_name] = [...(result[v.tool_name] || []), v]
  })
  return result
}

const ThinkingStepItem = ({
  group,
  steps,
  documents,
  showDivider,
  isComplete,
  expandedSteps,
  setExpandedSteps,
}: {
  group: string
  steps: ToolEvent[]
  documents: SourceDocument[]
  isComplete: boolean
  showDivider: boolean
  expandedSteps: string[]
  setExpandedSteps: (steps: string[]) => void
}) => {
  // @ts-expect-error
  const artifacts = steps.map((v) => v.artifacts).flatMap((v) => typeof v.at(0) === 'string' ? v : v.map((v) => v.file_id))
  // for generate_gemini_content
  const fileIds = steps.map((v) => v.params.file_ids || []).flatMap((v) => v)
  // for process_files
  const processFileIds = steps.map((v) => {
    if (Array.isArray(v.artifacts)) {
      return v.artifacts.map(artifact => 
        typeof artifact === 'string' ? artifact : artifact.file_id
      );
    }
    return []
  }).flatMap((v) => v)
  const nonPlaceholderSteps = steps.filter((v) => !v.placeholder)

  const completed =
    (nonPlaceholderSteps.every((v) => v.status === 'completed') &&
    nonPlaceholderSteps.length > 0) || isComplete

  const hideLoadingAnimation = completed

  return (
    <>
      <AccordionTrigger
        onClick={() => {
          if (expandedSteps.includes(group)) {
            setExpandedSteps(expandedSteps.filter((v) => v !== group))
          } else {
            setExpandedSteps([...expandedSteps, group])
          }
        }}
      >
        <div className="flex gap-2 pl-1 items-start overflow-x-auto hide-scrollbar">
          {completed ? (
            <CircleCheck className="size-5 my-0.5 shrink-0 stroke-interactive stroke-[#058556]" />
          ) : (
            <div className="flex size-5 my-0.5 min-w-5 items-center justify-center">
              <div className="size-2 shrink-0 rounded-full border border-system-primary mx-auto"></div>
            </div>
          )}

          <div className="flex gap-2 items-center w-full flex-wrap">
            <TypographyBody isStrong className="text-system-primary text-left shrink-0">
              {getToolName(steps[0])}
            </TypographyBody>

            {artifacts.filter((artifact) => {
              const document = documents.find((v) => v.document_id === artifact)

              return Boolean(document && document.url)
            }).length > 0 && (
              <TypographyLabel className="text-system-body">in</TypographyLabel>
            )}

            {artifacts.map((artifact, index) => {
              const document = documents.find((v) => v.document_id === artifact)

              let opacity = 100

              if (artifacts.length > 3) {
                if (index === artifacts.length - 1) {
                  opacity = 10
                }

                if (index === artifacts.length - 2) {
                  opacity = 40
                }

                if (index === artifacts.length - 3) {
                  opacity = 60
                }
              }

              if (!document || !document.url) return null
              return (
                <div
                  style={{
                    opacity: `${opacity}%`,
                  }}
                  key={`icon-step-${artifact}-${index}`}
                  className="min-h-4 min-w-4"
                >
                  <PreviewSourceIcon document={document} />
                </div>
              )
            })}
          </div>
        </div>
      </AccordionTrigger>
      <AccordionContent>
        <div className="flex flex-col gap-0">
          <div className="flex gap-1.5 items-center pl-8 mt-2 overflow-x-auto hide-scrollbar">
            {steps[0].tool_name === 'generate_table_tool' && (
              <>
                <TypographyLabel className="text-system-body">
                  Formatting
                </TypographyLabel>

                {steps.map((step, index) => {
                  return (
                    <div
                      key={`thinking-steps-table-${index}-${step.tool_id}`}
                      className="flex gap-1.5 px-3 py-1.5 items-center border border-system-border-light rounded-sm"
                    >
                      <img src={IconThinkingStepsTable} className="size-5" />

                      <TypographyLabel className="text-system-body whitespace-nowrap">
                        {step.params.table_title || ''}
                      </TypographyLabel>
                    </div>
                  )
                })}
              </>
            )}

            {steps[0].tool_name === 'generate_graph_tool' && (
              <>
                {!hideLoadingAnimation && (
                  <TypographyLabel
                    className={`text-system-body ${hideLoadingAnimation ? '' : 'animate-docGenPulse'}`}
                  >
                    {hideLoadingAnimation ? 'Plotting' : 'Plotting...'}
                  </TypographyLabel>
                )}

                {steps.map((step, index) => {
                  return (
                    <div
                      key={`thinking-steps-chart-${index}-${step.tool_id}`}
                      className="flex gap-1.5 px-3 py-1.5 items-center border border-system-border-light rounded-sm"
                    >
                      <img src={IconThinkingStepsChart} className="size-5" />

                      <TypographyLabel className="text-system-body whitespace-nowrap">
                        {step.params.graph_title || ''}
                      </TypographyLabel>
                    </div>
                  )
                })}
              </>
            )}

            {steps[0].tool_name === 'generate_gemini_content' && (
              <>
                {!hideLoadingAnimation && (
                  <TypographyLabel
                    className={`text-system-body ${hideLoadingAnimation ? '' : 'animate-docGenPulse'}`}
                  >
                    {hideLoadingAnimation ? 'Analyzing' : 'Analyzing...'}
                  </TypographyLabel>
                )}
                {fileIds.map((fileId, index) => {
                  const document = documents.find(
                    (v) => v.document_id === fileId
                  )

                  if (!document) return null
                  return (
                    <div
                      key={`file-badge-${index}-${fileId}`}
                      className="flex gap-1.5 px-3 py-1.5 items-center border border-system-border-light rounded-sm"
                    >
                      <SourceIcon
                        id={document.document_id}
                        title={document.title}
                        text={document.text}
                        url={document.url || ''}
                        metadata={document.doc_metadata}
                      />
                      <TypographyLabel className="text-system-body whitespace-nowrap line-clamp-1">
                        {document.title}
                      </TypographyLabel>
                    </div>
                  )
                })}
              </>
            )}

            {steps[0].tool_name === 'process_file' && (
              <>
                {!hideLoadingAnimation && (
                  <TypographyLabel
                    className={`text-system-body ${hideLoadingAnimation ? '' : 'animate-docGenPulse'}`}
                  >
                    {hideLoadingAnimation ? 'Analyzing' : 'Analyzing...'}
                  </TypographyLabel>
                )}
                {processFileIds.map((fileId, index) => {
                  const document = documents.find(
                    (v) => v.document_id === fileId
                  ) 

                  if (!document) return null
                  return (
                    <div
                      key={`file-badge-${index}-${fileId}`}
                      className="flex gap-1.5 px-3 py-1.5 items-center border border-system-border-light rounded-sm"
                    >
                      <SourceIcon
                        id={document.document_id}
                        title={document.title}
                        text={document.text}
                        url={document.url || ''}
                        metadata={document.doc_metadata}
                      />
                      <TypographyLabel className="text-system-body whitespace-nowrap line-clamp-1">
                        {document.title}
                      </TypographyLabel>
                    </div>
                  )
                })}
              </>
            )}

            {steps[0].tool_name !== 'generate_table_tool' &&
              steps[0].tool_name !== 'generate_graph_tool' &&
              steps[0].tool_name !== 'generate_gemini_content' &&
              steps[0].tool_name !== 'process_file' && (
                <>
                  {!hideLoadingAnimation && (
                    <TypographyLabel
                      className={`text-system-body ${hideLoadingAnimation ? '' : 'animate-docGenPulse'}`}
                    >
                      {hideLoadingAnimation ? 'Researching' : 'Researching...'}
                    </TypographyLabel>
                  )}

                  <AnimatePresence initial={false}>
                    {steps.map((step, index) => {
                      if (!step.thinking_message) return null
                      if (!getBadgeText(step)) return null
                      return (
                        <motion.div
                          key={step.tool_id}
                          layout
                          transition={{ duration: 0.2, type: 'keyframes' }}
                        >
                          <Badge
                            variant="gray"
                            className="whitespace-nowrap"
                            key={`research-badge-${index}-${step.tool_name}`}
                          >
                            {getBadgeText(step)}
                          </Badge>
                        </motion.div>
                      )
                    })}
                  </AnimatePresence>
                </>
              )}
          </div>
          {showDivider && (
            <div className="w-px bg-system-border-regular h-6 ml-3"></div>
          )}
        </div>
      </AccordionContent>
    </>
  )
}

export const ThinkingSteps = ({
  steps,
  documents,
  isComplete,
  hasResponse,
}: {
  steps: ToolEvents
  documents: SourceDocument[]
  isComplete: boolean
  hasResponse: boolean
}) => {
  const [expanded, setExpanded] = useState(false)
  const [expandedSteps, setExpandedSteps] = useState<string[]>([])
  const [canExpand, setCanExpand] = useState(true)

  const stepValues = useMemo(
    () => sortToolEvents(Object.values(steps)),
    [steps]
  )
  const groupedSteps = useMemo(
    () => groupThinkingSteps(stepValues),
    [stepValues]
  )
  const allCompleted = hasResponse

  const filteredGroupedSteps = useMemo(
    () =>
      Object.entries(groupedSteps).filter((v) => v[0] !== 'generate_citations'),
    [groupedSteps]
  )

  const showTrigger = (allCompleted && canExpand) || isComplete || !expanded

  useEffect(() => {
    if (isComplete) {
      return
    }

    if (!allCompleted) {
      setExpanded(true)
      setCanExpand(false)
    } else {
      setTimeout(() => {
        setCanExpand(true)
        setExpandedSteps([])
      }, 0)

      setTimeout(() => {
        setCanExpand(true)
        setExpanded(false)
      }, 200)
    }
  }, [allCompleted, isComplete])

  useEffect(() => {
    if (!allCompleted) {
      setExpandedSteps(Object.keys(groupedSteps))
    }
  }, [groupedSteps, allCompleted])

  return (
    <div className="p-4 bg-system-surface border border-system-border-regular rounded-lg mt-6">
      <Accordion type="single" value={expanded ? 'open' : ''}>
        <AccordionItem value="open" className="border-none">
          {showTrigger && (
            <AccordionTrigger onClick={() => setExpanded(!expanded)}>
              <div className="flex gap-2 items-center">
                <Waypoints className="size-6 shrink-0 stroke-interactive" />
                <TypographyBody isStrong>Show research</TypographyBody>
              </div>
            </AccordionTrigger>
          )}
          <AccordionContent>
            <div className={`flex flex-col gap-3 ${showTrigger ? 'mt-3' : ''}`}>
              {showTrigger && <Divider />}

              <div className="flex flex-col gap-3 mt-2">
                {filteredGroupedSteps.map((group, index) => {
                  const steps = group[1]

                  return (
                    <Accordion
                      type="multiple"
                      value={expandedSteps}
                      key={`accordion-${group[0]}`}
                    >
                      <AccordionItem value={group[0]} className="border-none">
                        <ThinkingStepItem
                          group={group[0]}
                          steps={steps}
                          documents={documents}
                          isComplete={isComplete}
                          expandedSteps={expandedSteps}
                          setExpandedSteps={setExpandedSteps}
                          showDivider={
                            index !== filteredGroupedSteps.length - 1
                          }
                        />
                      </AccordionItem>
                    </Accordion>
                  )
                })}
              </div>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  )
}
