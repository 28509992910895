import { TypographyH4, TypographyLabel } from "../ui/Typography";

import { ReactNode } from "react";

export type QuestionProps = {
    message: string;
    compact?: boolean;
    component?: ReactNode;
}

export function Question({ message, compact }: QuestionProps) {
    const replacedMessage = message.replaceAll(/\\n/g, '\n')
    return (
        compact ?
        <TypographyLabel className="!font-body-strong">
            {replacedMessage}
        </TypographyLabel>
        :
        <TypographyH4 className="whitespace-pre-wrap">
            {replacedMessage}
        </TypographyH4>
    )
}
