import { Playbook } from '@/types/types'
import { RadioGroup, RadioGroupItem } from '../ui/radio'
import { TypographyH4 } from '../ui/Typography'

export const PlaybookOptionRadio = ({
  playbook,
  value,
  onValueChange,
}: {
  playbook?: Playbook
  value?: string
  onValueChange: (value: string) => void
}) => {
  return (
    <div className="flex flex-col gap-4 pb-4 border-b border-system-border-light px-1">
      <TypographyH4>{playbook?.items.at(0)?.option_title || ''}</TypographyH4>

      <RadioGroup
        className="flex flex-row gap-8 flex-wrap"
        value={value}
        onValueChange={(v) => onValueChange(v)}
      >
        {playbook?.items.map((v) => {
          return (
            <RadioGroupItem
              key={`playbook-radio-${v.id}`}
              value={v.id}
              label={v.option}
            />
          )
        })}
      </RadioGroup>
    </div>
  )
}
