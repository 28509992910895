import { useState } from 'react'
import { TypographyBody } from '../ui/Typography'
import { Button } from '../ui/button'
import { ChevronDown, ChevronUp, Sparkles } from 'lucide-react'
import { Badge } from '../ui/badge'
import { DocgenThemeSection } from '@/types/types'
import { ListInput } from '../ListInput/ListInput'

export const DocGenThemeCard = ({
  section,
  onSectionUpdate,
}: {
  section: DocgenThemeSection
  onSectionUpdate: (section: DocgenThemeSection) => void
}) => {
  const [expanded, setExpanded] = useState(false)
  const [themes, setThemes] = useState(section.description)
  const [editingThemes, setEditingThemes] = useState(false)
  const contentExpands = themes.length > 3

  return (
    <div className="relative flex flex-col gap-4 pt-3 pb-6 border-t border-system-border-light">
      <div className="flex gap-3">
        <TypographyBody isStrong className="w-full">
          {section.title}
        </TypographyBody>
      </div>
      <div
        className={`flex flex-col gap-2 relative bg-system-secondary rounded-md border border-system-border-light px-2 py-3 ${contentExpands && !expanded ? 'max-h-[13.125rem] overflow-hidden' : ''}`}
      >
        <Badge variant="white" rounded={false} className="w-fit">
          <div className="flex gap-2 items-center w-fit">
            <Sparkles className="size-4 shrink-0 stroke-interactive" />
            Themes
          </div>
        </Badge>
        <div className="[&>div]:border-none [&>div]:p-0">
          <ListInput
            values={themes}
            onChange={(v) => {
              setThemes(v)
              onSectionUpdate({
                ...section,
                description: v,
              })
            }}
            onEditFocus={setEditingThemes}
          />
        </div>
        {!expanded && contentExpands && !editingThemes && (
          <div className="absolute bottom-0 left-0 w-full h-[5rem] bg-gradient-to-t from-system-surface to-transparent"></div>
        )}
        {contentExpands &&
          !editingThemes &&
          (!expanded ? (
            <Button
              className="absolute bottom-2 right-2"
              variant="tertiary"
              onClick={() => setExpanded(true)}
            >
              <div className="flex gap-2 items-center">
                Show more
                <ChevronDown className="size-6 shrink-0 stroke-[1.5px]" />
              </div>
            </Button>
          ) : (
            <Button
              className="absolute bottom-2 right-2"
              variant="tertiary"
              onClick={() => setExpanded(false)}
            >
              <div className="flex gap-2 items-center">
                Show less
                <ChevronUp className="size-6 shrink-0 stroke-[1.5px]" />
              </div>
            </Button>
          ))}
      </div>
    </div>
  )
}
