import { OutputData } from '@editorjs/editorjs'
import { TypographyBody } from '../ui/Typography'
import WordLogo from '@/assets/Word.svg'
import { Button } from '../ui/button'
import { memo, useCallback } from 'react'
import { DocumentExporter } from '@/utils/DocumentExporter'
import { RootState } from '@/store/store'
import { useSelector } from 'react-redux'
import ArrowRight from '@/assets/ArrowRight'
type ExportToWordProps = {
  isButton?: boolean
  report?: OutputData
  reportId?: string
  reportTitle?: string
  templateTitle?: string
}

const ExportToWord = memo(
  ({
    isButton = false,
    reportId,
    reportTitle,
    templateTitle,
    report,
  }: ExportToWordProps) => {
    const reports = useSelector((state: RootState) => state.docGen.reports)
    const reportMetadata = reports.data?.find((v) => v.id === reportId)

    const reportToExport =
      report ??
      (reportId &&
        useSelector(
          (state: RootState) =>
            state.docGen.editReportById[reportId]?.client?.report
        ))

    const handleExportToWord = useCallback(async () => {
      if (!reportToExport) {
        return
      }

      const exporter = new DocumentExporter({
        report: reportToExport,
        reportMetadata: reportMetadata,
        reportTitle: reportTitle,
        templateTitle: templateTitle,
      })
      const blob = await exporter.generateWordDocx()
      const documentName = exporter.getDocumentName()

      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `${documentName}.docx`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    }, [reportToExport, reportMetadata?.title])

    return (
      <Button
        onClick={handleExportToWord}
        variant="tertiary"
        className={`${isButton ? 'w-full' : '!border-none'} !m-0 flex gap-2`}
      >
        <img
          className="aspect-square w-6 h-6"
          src={WordLogo}
          alt="WordLogo"
        />
        <TypographyBody className="!font-body-strong">
          Export to Word
        </TypographyBody>

        {isButton && <ArrowRight className="ml-auto" />}
      </Button>
    )
  }
)

export default ExportToWord
