import * as React from 'react'

import { cn } from '@/shadcn/utils'
import { SearchIcon, X } from 'lucide-react'
import { TypographyBody } from './Typography'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  prefixComponent?: React.ReactNode
  suffixComponent?: React.ReactNode
  isSearch?: boolean
  isCloseVisible?: boolean
  onCloseClick?: () => void
  onEnter?: () => void
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      label,
      prefixComponent,
      suffixComponent,
      isSearch,
      isCloseVisible,
      onCloseClick,
      onEnter,
      ...props
    },
    ref
  ) => {
    const [prefixWidth, setPrefixWidth] = React.useState(0)
    const [suffixWidth, setSuffixWidth] = React.useState(0)

    const prefixRef = React.useRef<HTMLDivElement>(null)
    const suffixRef = React.useRef<HTMLDivElement>(null)

    const style = `flex h-10 w-full rounded-sm border border-system-border-regular bg-system-secondary ${isSearch ? 'px-[2.375rem] py-2' : 'px-3 py-2'} font-body text-system-primary ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-system-placeholder focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed`

    React.useEffect(() => {
      setPrefixWidth(prefixRef.current?.getBoundingClientRect().width || 0)
    }, [prefixComponent])

    React.useEffect(() => {
      setSuffixWidth(suffixRef.current?.getBoundingClientRect().width || 0)
    }, [suffixComponent])

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        onEnter?.()
      }
    }

    return (
      <div className="flex flex-col gap-1">
        {label && <TypographyBody isStrong={true}>{label}</TypographyBody>}

        <div className="relative">
          {isSearch && (
            <SearchIcon className="absolute left-3 top-[50%] -translate-y-[50%] h-[1.125rem] w-[1.125rem] stroke-[2px] stroke-system-primary" />
          )}

          {prefixComponent && (
            <div
              ref={prefixRef}
              className="absolute left-3 top-[50%] -translate-y-[50%] h-fit stroke-[2px] stroke-system-placeholder"
            >
              {prefixComponent}
            </div>
          )}

          <input
            type={type}
            className={cn(style, className)}
            // prefix width + left padding + gap between prefix and placeholder
            style={{
              paddingLeft: prefixComponent
                ? `${prefixWidth + 12 + 8}px`
                : undefined,
              paddingRight: suffixComponent
                ? `${suffixWidth + 12 + 8}px`
                : undefined,
            }}
            ref={ref}
            onKeyDown={handleKeyDown}
            {...props}
          />

          {suffixComponent && (
            <div
              ref={suffixRef}
              className="absolute right-3 top-[50%] -translate-y-[50%] h-fit stroke-[2px] stroke-system-placeholder"
            >
              {suffixComponent}
            </div>
          )}

          {isSearch && isCloseVisible && (
            <div className="cursor-pointer" onClick={() => onCloseClick?.()}>
              <X className="absolute right-3 top-[50%] -translate-y-[50%] h-6 w-6 stroke-[1.5px] stroke-system-primary" />
            </div>
          )}
        </div>
      </div>
    )
  }
)
Input.displayName = 'Input'

export { Input }
