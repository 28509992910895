import { MessageCircleMore } from 'lucide-react'
import { TypographyBody, TypographyH4 } from './ui/Typography'
import Divider from './ui/divider'
import { CustomAlert } from './CustomAlert'
import { ChatSummary, Dossier } from '@/types/types'
import { Thread } from './Threads/Thread'
import { Skeleton } from './ui/skeleton'
import { Button } from './ui/button'
import MessageCircleQuestion from '@/assets/MessageCircleQuestion'
import { useNavigate } from 'react-router-dom'
import { memo, useEffect, useMemo, useState } from 'react'
import ChevronDown from '@/assets/ChevronDown'
import { formatWeekLabel, groupItemsPerWeek } from '@/utils/utils'
import { Input } from './ui/input'

export interface ChatListProps {
  threads: ChatSummary[]
  loading: boolean
  error: string | null
  type: 'chat' | 'dossier' | 'past-chat'
  showSearchBar?: boolean
  dossier?: Dossier
  onDropdownOpen?: (open: boolean) => void
}

export const ChatList = memo(
  ({
    threads,
    loading,
    error,
    type,
    showSearchBar = false,
    dossier,
    onDropdownOpen,
  }: ChatListProps) => {
    const [shownThreadsCount, setShownThreadsCount] = useState(5)
    const [searchText, setSearchText] = useState('')

    const navigate = useNavigate()

    const filteredThreads = useMemo(
      () =>
        threads.filter((v) => {
          const splitText = searchText.split(' ')
          return splitText.some((s) =>
            v.query.toLowerCase().includes(s.toLowerCase())
          )
        }),
      [threads, searchText]
    )

    const shownThreads = filteredThreads.slice(0, shownThreadsCount)

    const groupedThreads = useMemo(() => {
      return groupItemsPerWeek(shownThreads, 'updated_at')
    }, [shownThreads])

    useEffect(() => {
      setShownThreadsCount(type === 'past-chat' ? filteredThreads.length : window.innerWidth > 1024 ? 8 : 5)
    }, [filteredThreads, type])

    return (
      <div className="flex flex-col gap-4">
        {type !== 'past-chat' && (
          <>
            <div className="flex flex-col gap-4 w-full">
              <div className="flex flex-wrap gap-y-4 gap-2 pl-3 items-end w-full">
                <div className="flex gap-2 items-center">
                  <MessageCircleMore className="h-6 w-6 shrink-0 stroke-interactive" />

                  <TypographyH4>
                    {type === 'dossier' ? 'Chats' : 'Recent chats'}
                  </TypographyH4>
                </div>
                {showSearchBar && (
                  <div className="ml-auto">
                    <Input
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      isCloseVisible={Boolean(searchText)}
                      onCloseClick={() => setSearchText('')}
                      className="w-[20rem]"
                      placeholder="Search by chat title"
                      isSearch
                    />
                  </div>
                )}
              </div>

              <Divider />
            </div>
          </>
        )}

        <div
          className={`flex flex-col ${type === 'dossier' ? 'gap-4 tablet:gap-8' : 'gap-8'}`}
        >
          {error && (
            <CustomAlert
              variant="error"
              description="We could not load chats at this time. Please try again later."
            />
          )}

          {!loading &&
            groupedThreads.map((group, idx) => {
              const threads = group.items

              const weekText = formatWeekLabel(
                group.year || 0,
                group.weekNumber || 0
              )

              return (
                <div
                  key={`thread-group-${idx}`}
                  className="flex flex-col gap-4 pb-2"
                >
                  <TypographyBody isStrong={true} className="px-3 pt-3">
                    {weekText}
                  </TypographyBody>

                  <div
                    className={`grid grid-cols-1 ${type === 'past-chat' ? '!grid-cols-1' : dossier ? 'mobile:!grid-cols-2 tablet:!grid-cols-1' : 'sm:!grid-cols-2'} gap-4`}
                  >
                    {threads.map((thread, index) => {
                      return (
                        <Thread
                          key={`thread-${thread.conversationId}-${index}`}
                          thread={thread}
                          type={type}
                          onDropdownOpen={onDropdownOpen}
                        />
                      )
                    })}
                  </div>
                </div>
              )
            })}

          {loading && (
            <div className="flex flex-col gap-4 pb-2">
              <div
                className={`grid grid-cols-1 ${type === 'chat' ? 'sm:grid-cols-2' : ''}gap-4`}
              >
                <Skeleton className="w-full h-10" />
                <Skeleton className="w-full h-10" />
                <Skeleton className="w-full h-10" />
                <Skeleton className="w-full h-10" />
                <Skeleton className="w-full h-10" />
                <Skeleton className="w-full h-10" />
              </div>
            </div>
          )}

          {Object.keys(groupedThreads).length === 0 &&
            type === 'dossier' &&
            dossier &&
            !error && (
              <div className="flex flex-col gap-4 mx-auto mt-2">
                <TypographyBody className="text-system-body">
                  There are no threads yet.
                </TypographyBody>

                <Button
                  variant="secondary"
                  className="w-fit mx-auto"
                  onClick={() =>
                    navigate(`/dossier/${dossier.id}/assistant/ask`)
                  }
                >
                  <div className="flex gap-2">
                    <MessageCircleQuestion className="size-6 !stroke-link" />
                    Ask Desia
                  </div>
                </Button>
              </div>
            )}

          {filteredThreads.length > 5 &&
            type === 'dossier' &&
            shownThreadsCount < filteredThreads.length && (
              <div className="mx-auto">
                <Button
                  variant="tertiary"
                  onClick={() => setShownThreadsCount(shownThreadsCount + 5)}
                >
                  <div className="flex gap-2">
                    Show more chats
                    <ChevronDown />
                  </div>
                </Button>
              </div>
            )}

          {filteredThreads.length > 5 &&
            type === 'chat' &&
            shownThreadsCount < filteredThreads.length && (
              <div className="mx-auto">
                <Button
                  variant="tertiary"
                  onClick={() => setShownThreadsCount(shownThreadsCount + 20)}
                >
                  <div className="flex gap-2">
                    View older chats
                    <ChevronDown />
                  </div>
                </Button>
              </div>
            )}
        </div>
      </div>
    )
  }
)

