import { DocgenTemplate, QueryStatus, TemplateCategory } from '@/types/types'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../ui/accordion'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import { useEffect, useMemo, useState } from 'react'
import { TemplateCard } from './TemplateCard'
import { fetchTemplateCategories, fetchTemplates } from './docGenThunk'
import { Button } from '../ui/button'
import { EllipsisVertical } from 'lucide-react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import { DocGenCreateCategoryDialog } from './DocGenCreateCategoryDialog'
import { DocGenDeleteCategoryDialog } from './DocGenDeleteCategoryDialog'

export const TemplateList = ({
  selectedTemplate,
  templates,
  onTemplateClick,
}: {
  selectedTemplate?: DocgenTemplate | null
  templates: DocgenTemplate[]
  onTemplateClick?: (template: DocgenTemplate) => void
}) => {
  const templateCategories = useSelector(
    (state: RootState) => state.docGen.templateCategories.data
  )
  const templateListStatus = useSelector(
    (state: RootState) => state.docGen.templateListStatus
  )

  const [openedAccordions, setOpenedAccordions] = useState<string[]>([])
  const [showCategoryDialog, setShowCategoryDialog] =
    useState<TemplateCategory | null>(null)
  const [showDeleteCategoryDialog, setShowDeleteCategoryDialog] =
    useState<TemplateCategory | null>(null)

  const noCategoryTemplates = useMemo(
    () =>
      templates.filter((v) => !v.category_id && v.template_type !== 'system'),
    [templates]
  )

  const systemTemplates = useMemo(
    () => templates.filter((v) => v.template_type === 'system'),
    [templates]
  )

  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(fetchTemplateCategories())
  }, [dispatch])

  useEffect(() => {
    if (templateListStatus === QueryStatus.INITIALISED) {
      dispatch(fetchTemplates())
    }
  }, [templateListStatus, dispatch])

  useEffect(() => {
    setOpenedAccordions([
      ...templateCategories.map((_, index) => `category-${index}`),
      `category-${templateCategories.length}`,
      'category-system',
    ])
  }, [templateCategories])

  return (
    <>
      <div className="flex flex-col gap-6 w-full">
        <Accordion
          type="multiple"
          className="flex flex-col gap-2"
          value={openedAccordions}
          onValueChange={(v) => setOpenedAccordions(v)}
        >
          <AccordionItem
            value={`category-system`}
            className="border-none"
            key={`accordion-theme-system`}
          >
            <AccordionTrigger className="text-left p-4 border-t border-system-border-regular">
              Desia templates
            </AccordionTrigger>
            <AccordionContent>
              <div className="grid grid-cols-3 gap-4 pb-6">
                {systemTemplates.map((template) => {
                  return (
                    <TemplateCard
                      template={template}
                      key={`template-card-${template.id}`}
                      onClick={() => onTemplateClick?.(template)}
                      selected={selectedTemplate === template}
                    />
                  )
                })}
              </div>
            </AccordionContent>
          </AccordionItem>

          {templateCategories?.map((category, index) => {
            const filteredTemplates = templates.filter(
              (v) =>
                v.category_id === category.id && v.template_type !== 'system'
            )
            return (
              <AccordionItem
                value={`category-${index}`}
                className="border-none"
                key={`accordion-theme-${index}`}
              >
                <AccordionTrigger className="text-left p-4 border-t border-system-border-regular">
                  <div className="flex gap-2 items-center w-full pr-2">
                    {category.title}

                    <DropdownMenu>
                      <DropdownMenuTrigger className="items-center" asChild>
                        <Button variant="tertiary" className="ml-auto">
                          <EllipsisVertical className="size-6 shrink-0 stroke-interactive" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent
                        align="end"
                        className="min-w-[7.5rem]"
                      >
                        <DropdownMenuItem
                          onClick={(e) => {
                            e.stopPropagation()
                            setShowCategoryDialog(category)
                          }}
                        >
                          Rename
                        </DropdownMenuItem>

                        <DropdownMenuItem
                          variant="tertiary-destructive"
                          onClick={(e) => {
                            e.stopPropagation()
                            setShowDeleteCategoryDialog(category)
                          }}
                        >
                          Remove
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <div className="grid grid-cols-3 gap-4 pb-6">
                    {filteredTemplates.map((template) => {
                      return (
                        <TemplateCard
                          template={template}
                          key={`template-card-${template.id}`}
                          onClick={() => onTemplateClick?.(template)}
                          selected={selectedTemplate === template}
                        />
                      )
                    })}
                  </div>
                </AccordionContent>
              </AccordionItem>
            )
          })}
          {templateCategories.length > 0 && noCategoryTemplates.length > 0 && (
            <AccordionItem
              value={`category-${templateCategories.length}`}
              className="border-none"
              key={`accordion-theme-${templateCategories.length}`}
            >
              <AccordionTrigger className="text-left p-4 border-t border-system-border-regular">
                No category
              </AccordionTrigger>
              <AccordionContent>
                <div className="grid grid-cols-3 gap-4">
                  {noCategoryTemplates.map((template) => {
                    return (
                      <TemplateCard
                        template={template}
                        key={`template-card-${template.id}`}
                        onClick={() => onTemplateClick?.(template)}
                        selected={selectedTemplate === template}
                      />
                    )
                  })}
                </div>
              </AccordionContent>
            </AccordionItem>
          )}
        </Accordion>

        {templateCategories.length === 0 && noCategoryTemplates.length > 0 && (
          <div className="grid grid-cols-3 gap-4">
            {noCategoryTemplates.map((template) => {
              return (
                <TemplateCard
                  template={template}
                  key={`template-card-${template.id}`}
                  onClick={() => onTemplateClick?.(template)}
                  selected={selectedTemplate === template}
                />
              )
            })}
          </div>
        )}
      </div>

      <DocGenCreateCategoryDialog
        category={showCategoryDialog || undefined}
        setOpen={(v) => {
          if (!v) {
            setShowCategoryDialog(null)
          }
        }}
        open={Boolean(showCategoryDialog)}
      />

      <DocGenDeleteCategoryDialog
        category={showDeleteCategoryDialog || undefined}
        setOpen={(v) => {
          if (!v) {
            setShowDeleteCategoryDialog(null)
          }
        }}
        open={Boolean(showDeleteCategoryDialog)}
      />
    </>
  )
}
