import { IntegrationCode, UserIntegration } from '@/types/types'
import { getIntegrationIcon } from '@/utils/components'
import { getIntegrationName } from '@/utils/utils'
import { TypographyBody, TypographyLabel } from '../ui/Typography'
import { Button } from '../ui/button'
import ExternalLink from '@/assets/ExternalLink'
import { ToastContext } from '@/contexts/ToastContext'
import { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store/store'
import { isFulfilled } from '@reduxjs/toolkit'
import {
  disconnectUserIntegration,
  fetchUserIntegrations,
} from './integrationThunk'
import { UserContext } from '@/contexts/UserContext'

export const IntegrationSettingsCard = ({
  integration,
  onClick,
}: {
  integration: UserIntegration
  onClick: () => void
}) => {
  const { showToast } = useContext(ToastContext)
  const { settings, updateSettings } = useContext(UserContext)

  const dispatch = useDispatch<AppDispatch>()

  const getDescriptionText = (integrationCode: string) => {
    switch (integrationCode) {
      case IntegrationCode.OUTLOOK:
        return 'Emails including attachment files'
      case IntegrationCode.TEAMS:
        return 'Messages and call transcripts'
    }
  }

  const handleDisconnect = async () => {
    const result = await dispatch(
      disconnectUserIntegration(integration.integration_id)
    )

    if (isFulfilled(disconnectUserIntegration)(result)) {
      dispatch(fetchUserIntegrations())

      showToast(
        {
          variant: 'info',
          description: `${getIntegrationName(integration.integration_code_name)} has been disconnected`,
          toastId: 'settings-dialog',
          dismissable: true,
        },
        5000
      )

      const sources = settings.assistant.sources

      if (integration.integration_code_name === IntegrationCode.TEAMS) {
        Object.keys(sources).forEach(
          // @ts-expect-error
          (sourceType: 'dossier' | 'report') => {
            sources[sourceType] = {
              ...sources[sourceType],
              teamsSearch: false,
            }
          },
          (sources.ask = {
            ...sources.ask,
            teamsSearch: false,
          })
        )
      } else if (
        integration.integration_code_name === IntegrationCode.OUTLOOK
      ) {
        Object.keys(sources).forEach(
          // @ts-expect-error
          (sourceType: 'dossier' | 'report') => {
            sources[sourceType] = {
              ...sources[sourceType],
              teamsSearch: false,
            }
          },
          (sources.ask = {
            ...sources.ask,
            teamsSearch: false,
          })
        )
      }

      const updatedSettings = {
        settings: {
          ...settings,
          assistant: {
            ...settings.assistant,
            sources: sources,
          },
        },
      }

      updateSettings(updatedSettings)
    }
  }

  return (
    <div className="flex gap-4 items-center p-4 rounded-lg border border-system-border-light bg-system-surface-light">
      <div className="flex items-center justify-center size-8 min-w-8 min-h-8 shrink-0 border border-system-border-light rounded-sm bg-system-secondary">
        {getIntegrationIcon(
          integration.integration_code_name,
          true,
          'w-5 shrink-0'
        )}
      </div>

      <div className="flex flex-col gap-1 w-full">
        <TypographyBody isStrong>
          {getIntegrationName(integration.integration_code_name)}
        </TypographyBody>

        <TypographyLabel className="text-system-body">
          {getDescriptionText(integration.integration_code_name)}
        </TypographyLabel>
      </div>

      {integration.integration_enabled_by_user ? (
        <Button variant="secondary" onClick={handleDisconnect}>
          Disconnect
        </Button>
      ) : (
        <Button
          onClick={onClick}
          disabled={!integration.integration_enabled_in_organization}
        >
          <div className="flex gap-2 items-center">
            Connect
            <ExternalLink />
          </div>
        </Button>
      )}
    </div>
  )
}
