import { ChartConfig } from '../ui/chart'
import { ResponseChartData, ResponseChartProps } from '@/types/types'
import { memo, useMemo, useRef } from 'react'
import { CustomAlert } from '../CustomAlert'
import { ResponseLineChart } from './ResponseLineChart'
import { ResponseBarChart } from './ResponseBarChart'
import { ResponseAreaChart } from './ResponseAreaChart'
import { ResponsePieChart } from './ResponsePieChart'
import { ApexOptions } from 'apexcharts'
import { renderToStaticMarkup } from 'react-dom/server'
import {
  CHART_TIMESERIES_THRESHOLD,
  Color,
  THEME_MAX_AMOUNT,
} from '@/constants'
import { ResponseStackedBarChart } from './ResponseStackedBarChart'
import { formatDisplayNumber, getExtendedTheme } from '@/utils/utils'
import { ChartTooltip } from './ChartTooltip'
import {
  formatChartDate,
  getCategories,
  getSeries,
  redrawMarkers,
} from './utils'
import ResponseHorizontalBarChart from './ResponseHorizontalBarChart'
import ResponseMixedChart from './ResponseMixedChart'
import ResponseTimeseriesChart from './ResponseTimeseriesChart'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'

export const ResponseChart = memo(
  ({
    id,
    data,
    citations,
    documents,
    compact,
    openedCitation,
    onCitationOpen,
    isFormatted = true,
  }: ResponseChartProps) => {
    const { theme } = useSelector((state: RootState) => state.theme)
    const seriesIndex = useRef<number>(0)
    const extendedTheme = useMemo(() => getExtendedTheme(theme), [theme])

    try {
      const parsedData: ResponseChartData = JSON.parse(data)

      const chartCitations = useMemo(() => {
        return citations.filter((v) => v.chart_table_id === id)
      }, [citations])

      const categories: string[][] = useMemo(() => {
        return getCategories(parsedData)
      }, [parsedData])

      const series: ApexAxisChartSeries = useMemo(() => {
        return getSeries(parsedData)
      }, [parsedData])

      const options: ApexOptions = {
        chart: {
          id: parsedData.title,
          zoom: {
            enabled: false,
          },
          fontFamily: 'Inter, sans-serif',
          height: '400px',
          parentHeightOffset: 0,
          toolbar: {
            show: false,
            export: {
              csv: {
                filename: parsedData.title,
              },
            },
          },
          events: {
            mounted: (chart) => {
              chart.windowResizeHandler()
              redrawMarkers()
            },
            updated: () => {
              redrawMarkers()
            },
            dataPointMouseEnter: function (event, _2, opts) {
              seriesIndex.current = opts.seriesIndex
              event.target.style.cursor = 'pointer'
            },
          },
          animations: {
            enabled: false,
          },
          selection: {
            enabled: false,
          },
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: 'top',
          horizontalAlign: 'left',
          floating: false,
          fontSize: '14px',
          fontFamily: 'Inter, sans-serif',
          fontWeight: 400,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          customLegendItems: [],
          offsetX: -36,
          offsetY: 0,
          labels: {
            colors: Color['system-body'],
            useSeriesColors: false,
          },
          markers: {
            size: 7,
            shape: 'square',
            strokeWidth: 0,
            offsetX: -2,
            offsetY: 0,
          },
          itemMargin: {
            horizontal: 8,
            vertical: 4,
          },
          onItemClick: {
            toggleDataSeries: false,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },
        xaxis: {
          categories: categories,
          labels: {
            formatter: (val: string) => {
              return formatChartDate(val)
            },
            style: {
              fontSize: '14px',
              fontFamily: 'Inter, sans-serif',
              fontWeight: 400,
              colors: Color['system-body'],
            },
            trim: true,
            rotate: 0,
            show: true,
            hideOverlappingLabels: false,
          },
          axisBorder: {
            color: Color['system-border-light'],
          },
          axisTicks: {
            color: Color['system-border-light'],
          },
          crosshairs: {
            stroke: {
              color: Color['system-border-light'],
              dashArray: 8,
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          labels: {
            formatter: (val: number) => {
              return isFormatted ? formatDisplayNumber(val) : `${val}`
            },
            style: {
              fontSize: '14px',
              fontFamily: 'Inter, sans-serif',
              fontWeight: 400,
              colors: Color['system-body'],
            },
          },
          axisBorder: {
            color: Color['system-border-light'],
          },
          tickAmount: 3,
        },
        grid: {
          borderColor: Color['system-border-light'],
        },
        markers: {
          size: 0,
          strokeWidth: 0,
          hover: {
            size: 4,
          },
        },
        dataLabels: {
          enabled: false,
          style: {
            fontSize: `14px`,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 400,
            colors: extendedTheme.colors,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 2,
            columnWidth: '80%',
            borderRadiusApplication: 'end',
          },
        },
        colors: extendedTheme.colors,
        fill: {
          opacity: 1,
        },
        tooltip: {
          shared: false,
          intersect: false,
          custom: (props) => {
            return renderToStaticMarkup(
              <ChartTooltip
                id={id}
                categories={categories}
                series={series}
                // https://apexcharts.com/docs/options/chart/events/
                seriesIndex={
                  ['line', 'area'].includes(parsedData.chartType.toLowerCase())
                    ? props.seriesIndex
                    : seriesIndex.current!
                }
                dataPointIndex={props.dataPointIndex}
                openedCitation={openedCitation}
                citations={citations}
              />
            )
          },
        },
        states: {
          normal: {
            filter: {
              type: 'none',
              value: 0,
            },
          },
          hover: {
            filter: {
              type: 'darken',
              value: 0.8,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: 'darken',
              value: 0.4,
            },
          },
        },
      }

      const chartType = parsedData.chartType.toLowerCase()

      if (chartType === 'stackedbar') {
        return (
          <ResponseStackedBarChart
            id={id}
            parsedData={parsedData}
            series={series}
            options={options}
            compact={compact}
            citations={chartCitations}
            documents={documents}
            openedCitation={openedCitation}
            onCitationOpen={onCitationOpen}
            categories={categories}
            isFormatted={isFormatted}
          />
        )
      }

      if (chartType === 'bar') {
        return (
          <ResponseBarChart
            id={id}
            parsedData={parsedData}
            series={series}
            options={options}
            compact={compact}
            citations={chartCitations}
            documents={documents}
            openedCitation={openedCitation}
            onCitationOpen={onCitationOpen}
            categories={categories}
            isFormatted={isFormatted}
          />
        )
      }

      if (chartType === 'line') {
        const xAxisIsDate = !isNaN(new Date(parsedData.data[0].label).getTime())
        if (series[0].data.length > CHART_TIMESERIES_THRESHOLD && xAxisIsDate) {
          return (
            <ResponseTimeseriesChart
              id={id}
              parsedData={parsedData}
              series={series}
              options={options}
              compact={compact}
              citations={chartCitations}
              documents={documents}
              openedCitation={openedCitation}
              onCitationOpen={onCitationOpen}
              categories={categories}
              isFormatted={isFormatted}
            />
          )
        }
        return (
          <ResponseLineChart
            id={id}
            parsedData={parsedData}
            series={series}
            options={options}
            compact={compact}
            citations={chartCitations}
            documents={documents}
            openedCitation={openedCitation}
            onCitationOpen={onCitationOpen}
            categories={categories}
            isFormatted={isFormatted}
          />
        )
      }

      if (chartType === 'area') {
        return (
          <ResponseAreaChart
            id={id}
            parsedData={parsedData}
            series={series}
            options={options}
            compact={compact}
            citations={chartCitations}
            documents={documents}
            openedCitation={openedCitation}
            onCitationOpen={onCitationOpen}
            categories={categories}
            isFormatted={isFormatted}
          />
        )
      }

      if (chartType === 'horizontalbar') {
        return (
          <ResponseHorizontalBarChart
            id={id}
            parsedData={parsedData}
            series={series}
            options={options}
            compact={compact}
            citations={chartCitations}
            documents={documents}
            openedCitation={openedCitation}
            onCitationOpen={onCitationOpen}
            categories={categories}
            isFormatted={isFormatted}
          />
        )
      }

      if (chartType === 'timeseries') {
        return (
          <ResponseTimeseriesChart
            id={id}
            parsedData={parsedData}
            series={series}
            options={options}
            compact={compact}
            citations={chartCitations}
            documents={documents}
            openedCitation={openedCitation}
            onCitationOpen={onCitationOpen}
            categories={categories}
            isFormatted={isFormatted}
          />
        )
      }

      if (chartType === 'mixed') {
        const mixedSeries: ApexAxisChartSeries = parsedData.values.map(
          (v: string, idx: number) => {
            const seriesType = parsedData.type?.[idx] ?? 'column'

            return {
              name: v,
              type: ['bar', 'column'].includes(seriesType) ? 'column' : 'line',
              data: parsedData.data.map((d) => d.value[idx] ?? 0),
            }
          }
        )

        return (
          <ResponseMixedChart
            id={id}
            parsedData={parsedData}
            series={mixedSeries}
            options={options}
            compact={compact}
            citations={chartCitations}
            documents={documents}
            openedCitation={openedCitation}
            onCitationOpen={onCitationOpen}
            categories={categories}
            isFormatted={isFormatted}
          />
        )
      }

      if (parsedData.chartType.toLowerCase() === 'pie') {
        const clamp = Math.max(THEME_MAX_AMOUNT, theme.colors.length)

        const parsedPieDataValues = parsedData.data.map((v, idx) => {
          const data: { [label: string]: string | number } = {
            [parsedData.label]: v.label,
            fill: extendedTheme.colors[idx % clamp],
          }

          v.value.forEach((v, idx) => {
            data[parsedData.values[idx]] = v
          })

          return data
        })

        const pieParsedChartConfig: {
          [label: string]: {
            label: string
            color: string
          }
        } = parsedData.data.reduce(
          (a, b, idx) => {
            const newData = {
              ...a,
              [b.label]: {
                label: b.label,
                color: extendedTheme.colors[idx % clamp],
              },
            }

            return newData
          },
          parsedData.values.reduce((a, b) => {
            const newData = { ...a, [b]: { label: b } }

            return newData
          }, {})
        ) satisfies ChartConfig

        const pieSeries: ApexAxisChartSeries = parsedData.data.map(() => {
          return {
            name: parsedData.values[0],
            data: Array(parsedData.data.length)
              .fill(0)
              .map((_, dataIndex) => parsedData.data[dataIndex].value[0]),
          }
        })

        return (
          <ResponsePieChart
            id={id}
            parsedChartConfig={pieParsedChartConfig}
            parsedData={parsedData}
            parsedDataValues={parsedPieDataValues}
            citations={chartCitations}
            documents={documents}
            openedCitation={openedCitation}
            onCitationOpen={onCitationOpen}
            categories={categories}
            series={pieSeries}
            isFormatted={isFormatted}
          />
        )
      }

      throw new Error('chart type is invalid')
    } catch {
      return <ResponseChartError />
    }
  }
)

export const ResponseChartError = () => {
  return (
    <div className="flex flex-col gap-4 p-4 mt-10">
      <CustomAlert
        variant="error"
        title="We could not load the chart at this time"
        description="We have notified the team and will be fixing the issue soon."
      />
    </div>
  )
}
