import { ArrowRight } from "lucide-react";
import { TypographyBody } from "../ui/Typography";
import MessageCircleQuestion from "@/assets/MessageCircleQuestion";

export function AskDesiaLink({ query, handleClick }: { query: string | null, handleClick: () => void }) {
    if (typeof query !== "string") {
        return null;
    }
    return (
        <div className="fixed right-4 md:right-[7.5rem] top-[4.25rem] z-[20] rounded-full shadow-ask-desia bg-gradient-to-br from-[#80EED4] to-[#717DEA] max-w-[calc(100%-2rem)] sm:max-w-[26.25rem]" onClick={handleClick}>
            <div className="flex gap-3 py-2 px-4 bg-system-secondary m-px rounded-full">
                <MessageCircleQuestion className="size-6 stroke-link shrink-0" />
                <TypographyBody isStrong={true} className="w-fit whitespace-nowrap overflow-hidden text-ellipsis">
                    {`Ask Desia: "${query}"`}
                </TypographyBody>
                <ArrowRight className="size-6 stroke-[1.5px] shrink-0" />
            </div>
        </div>
    )
}