import {
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog'
import { TypographyBody } from '../ui/Typography'
import { Button } from '../ui/button'
import { CustomAlert } from '../CustomAlert'

const AskUploadFileRemoveDialog = ({
  open,
  onConfirm,
  onCancel,
}: {
  open?: boolean
  onConfirm?: () => void
  onCancel?: () => void
}) => {
  return (
    <Dialog open={open}>
      <DialogContent
        className="tablet:min-w-[42.125rem] flex flex-col gap-8"
        onInteractOutside={() => onCancel?.()}
      >
        <DialogHeader>
          <DialogTitle onClose={() => onCancel?.()}>
            Are you sure you want to deselect all uploads?
          </DialogTitle>
          <DialogDescription>
            <TypographyBody>
              Deselecting uploads will let you select library or Selected files
              as sources again
            </TypographyBody>
          </DialogDescription>
        </DialogHeader>
        <DialogBody>
          <CustomAlert
            variant="info"
            description="This action does not stop the uploaded files from being saved to the Library"
            dismissable={false}
          />
        </DialogBody>
        <DialogFooter className="mt-2">
          <DialogClose asChild>
            <Button onClick={() => onCancel?.()} variant="secondary">
              Keep uploads
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button onClick={() => onConfirm?.()}>Deselect uploads</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default AskUploadFileRemoveDialog
