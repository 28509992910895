import { ResponseDocGenReport } from '@/types/types'
import { OutputData } from '@editorjs/editorjs'
import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  HeadingLevel,
  ExternalHyperlink,
  ParagraphChild,
  Table,
  TableCell,
  TableRow,
  VerticalAlign,
  WidthType,
  ImageRun,
} from 'docx'
import { toPng } from 'html-to-image'
import { embedCharts } from './embedCitations'
import { parseHeaderForDates } from './table'

type MarkdownType =
  | 'p'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'li'
  | 'strong'
  | 'pre'
  | 'Chart'
  | 'a'
  | 'Table'

type Segment = {
  text: string
  bold: boolean
  italics: boolean
  link?: string
}
type Text = {
  segments: Segment[]
  type: MarkdownType
  reference?: string
  blockIndex: number
}

const emphasisRegex = /\*\*[^*]+\*\*/g
const hierarchyRegex = /^(####\s|###\s|##\s|#\s|^[*-] )/
const tableRegex = /<Table id={([^}]+)} \/>/
const chartRegex = /<Chart id={([^}]+)} \/>/

export class DocumentExporter {
  private report: OutputData
  private reportMetadata?: ResponseDocGenReport
  private reportTitle?: string
  private templateTitle?: string
  private documentName: string | null

  constructor({
    report,
    reportTitle,
    templateTitle,
    reportMetadata,
  }: {
    report: OutputData
    reportTitle?: string
    templateTitle?: string
    reportMetadata?: ResponseDocGenReport
  }) {
    this.report = report
    this.reportTitle = reportTitle
    this.templateTitle = templateTitle
    this.reportMetadata = reportMetadata
    this.documentName = 'Untitled'
  }

  private cleanText = (text: string): string => {
    text = text.replace(/<\/?[^>]+(>|$)/g, '')

    text = text.replace(/&nbsp;/g, ' ')
    text = text.replace(/&#x27;/g, "'")
    text = text.replace(/&amp;/g, '&')
    text = text.replace(/&quot;/g, '"')
    text = text.replace(/&lt;/g, '<')
    text = text.replace(/&gt;/g, '>')
    text = text.replace(/&#(\d+);/g, (_, dec) =>
      String.fromCharCode(parseInt(dec, 10))
    )

    text = text.replace(/\u200B/g, '')
    text = text.replace(/\u200C/g, '')
    text = text.replace(/\u200D/g, '')

    if (text.startsWith('>')) {
      text = text.substring(1).trimStart()
    }
    return text.trim()
  }

  private parseReport(): Text[] {
    if (!this.report || !this.report.blocks) {
      throw new Error('Invalid report structure')
    }

    const parsedBlocks: Text[] = []
    this.report.blocks.forEach((block, index) => {
      const text: string = embedCharts({ text: block.data.text as string })

      if (block.type === 'FinalAnswer') {
        const lines = text
          .split('\n')
          .map((line) => line.trim())
          .filter((line) => line.length > 0)

        lines.forEach((line) => {
          let type: MarkdownType = 'p'
          let segments: Segment[] = []
          let bold = false

          let match: RegExpExecArray | null

          if (tableRegex.test(line)) {
            const tableId = line.match(tableRegex)![1]
            type = 'Table'
            parsedBlocks.push({
              segments: [{ text: line, italics: false, bold: false }],
              type: type,
              reference: tableId,
              blockIndex: index,
            })
            return
          }

          if (chartRegex.test(line)) {
            const chartId = line.match(chartRegex)![1]
            type = 'Chart'
            parsedBlocks.push({
              segments: [{ text: line, italics: false, bold: false }],
              type: type,
              reference: chartId,
              blockIndex: index,
            })
            return
          }

          if (/^#### /.test(line)) {
            type = 'h4'
            bold = true
          } else if (/^### /.test(line)) {
            type = 'h3'
            bold = true
          } else if (/^## /.test(line)) {
            type = 'h2'
            bold = true
          } else if (/^# /.test(line)) {
            type = 'h1'
            bold = true
          } else if (/^[*-] /.test(line)) {
            type = 'li'
          } else if (line.trim().length > 0) {
            type = 'p'
          }

          const cleanedText = this.cleanText(
            line.replace(hierarchyRegex, '').trim()
          )
          let lastIndex = 0

          while ((match = emphasisRegex.exec(cleanedText)) !== null) {
            let textContent = match[0]

            if (match.index > lastIndex) {
              let nonBoldText = cleanedText.slice(lastIndex, match.index)
              segments.push({
                text: nonBoldText,
                bold: false,
                italics: false,
              })
            }

            textContent = textContent.replace(/\*\*/g, '')

            segments.push({
              text: textContent,
              bold: true,
              italics: false,
            })

            lastIndex = emphasisRegex.lastIndex
          }

          if (lastIndex < cleanedText.length) {
            let remainingText = cleanedText.slice(lastIndex)
            segments.push({
              text: remainingText,
              bold,
              italics: false,
            })
          }
          if (segments.length === 0) {
            segments.push({
              text: cleanedText,
              bold: false,
              italics: false,
            })
          }

          parsedBlocks.push({
            segments,
            type,
            blockIndex: index,
          })
        })
      } else if (block.type === 'NewParagraph') {
        parsedBlocks.push({
          segments: [{ text, bold: false, italics: false }],
          type: 'p',
          blockIndex: index,
        })
      }
    })

    return parsedBlocks
  }

  private async preprocessChartImages(
    texts: Text[]
  ): Promise<{ [id: string]: { buffer: string; aspectRatio: number } }> {
    const charts = texts.filter((text) => text.type === 'Chart')
    const chartImages: {
      [id: string]: { buffer: string; aspectRatio: number }
    } = {}

    for (const chart of charts) {
      if (!chart.reference) {
        continue
      }

      const chartHtml = document.getElementById(
        `chart-container-${chart.reference}`
      )
      const height = chartHtml?.getBoundingClientRect().height ?? 0
      const width = chartHtml?.getBoundingClientRect().width ?? 0

      const aspect = width && height && height > 0 ? height / width : 1

      if (chartHtml) {
        try {
          const dataUrl = await toPng(chartHtml, {
            cacheBust: true,
            backgroundColor: 'white',
            skipFonts: true,
          })

          chartImages[chart.reference] = {
            buffer: dataUrl,
            aspectRatio: aspect,
          }
        } catch {}
      }
    }

    return chartImages
  }

  public async generateWordDocx(): Promise<Blob> {
    const texts = this.parseReport()
    const preprocessedChartImages = await this.preprocessChartImages(texts)

    let overrideTitle = 'Untitled'
    if (this.templateTitle && this.reportTitle) {
      overrideTitle = `${this.reportTitle} - ${this.templateTitle}`
    } else if (this.reportTitle) {
      overrideTitle = this.reportTitle
    } else if (this.reportMetadata?.title) {
      overrideTitle = this.reportMetadata?.title
    }

    this.documentName = overrideTitle

    const doc = new Document({
      ...docConfig,
      creator: `desia_${this.reportMetadata?.id}`,
      title: this.documentName,
      sections: [
        {
          properties: {},
          children: texts.flatMap((block, index) => {
            const pad =
              index > 0 &&
              !['h1', 'h2', 'h3', 'h4'].includes(texts[index - 1].type)

            if (block.type === 'Table') {
              try {
                if (!block.reference) {
                  throw new Error('Failed to export table to Word')
                }

                const data = this.report.blocks[
                  block.blockIndex
                ].data.documents.filter(
                  (doc: any) => doc.document_id === block.reference
                )

                if (data.length === 0) {
                  throw new Error('Failed to export table to Word')
                }

                const parsedData: {
                  headers: string[]
                  [id: string]: any
                } = JSON.parse(data[0].text)[0]

                const isDateFirst = parseHeaderForDates(parsedData.headers[0])
                const { headers, ...withoutHeaders } = parsedData

                const transposed: string[][] = Object.keys(withoutHeaders).map(
                  (key) => [key, ...withoutHeaders[key]]
                )

                const xHeaders = isDateFirst ? transposed[0] : headers

                const rowCount = isDateFirst
                  ? transposed.length
                  : parsedData[parsedData.headers[0]].length
                const rowIndexOffset = isDateFirst ? 1 : 0

                const headerRows = new TableRow({
                  tableHeader: true,
                  children: xHeaders.map(
                    (header: string) =>
                      new TableCell({
                        children: [new Paragraph(header)],
                        verticalAlign: VerticalAlign.BOTTOM,
                      })
                  ),
                })

                const content = [
                  ...Array(rowCount - rowIndexOffset).keys(),
                ].map((index) => {
                  return new TableRow({
                    children: xHeaders.map((header, headerIndex) => {
                      const cellData = isDateFirst
                        ? transposed[index + rowIndexOffset][headerIndex]
                        : parsedData[header][index]

                      return new TableCell({
                        children: [new Paragraph(cellData)],
                        verticalAlign: VerticalAlign.BOTTOM,
                      })
                    }),
                  })
                })

                return [
                  ...(pad ? [new Paragraph({ text: '' })] : []),
                  new Table({
                    width: {
                      size: 100,
                      type: WidthType.PERCENTAGE,
                    },
                    margins: {
                      top: 40,
                      bottom: 40,
                      right: 40,
                      left: 40,
                    },
                    // https://github.com/dolanmiu/docx/issues/349
                    // google docs table issue: total page width is 9638 DXA for A4 portrait
                    columnWidths: [3213, 3213, 3212],
                    rows: [headerRows, ...content],
                  }),
                  new Paragraph({}),
                ]
              } catch (e: any) {
                return new Paragraph('Failed to export table to Word')
              }
            }

            if (block.type === 'Chart') {
              try {
                if (!block.reference) {
                  throw new Error('Failed to export chart to word')
                }

                const chart = preprocessedChartImages[block.reference]
                if (!chart?.buffer) {
                  throw new Error('Failed to export chart to word')
                }

                const image = new ImageRun({
                  type: 'png',
                  data: chart.buffer,
                  transformation: {
                    width: 620,
                    height: 620 * chart.aspectRatio,
                  },
                  altText: {
                    title: block.reference,
                    description: block.reference,
                    name: block.reference,
                  },
                })
                return [
                  ...(pad ? [new Paragraph({ text: '' })] : []),
                  new Paragraph({
                    children: [image],
                    // alignment: AlignmentType.CENTER,
                  }),
                  new Paragraph({}),
                ]
              } catch (e: any) {
                return new Paragraph('Failed to export table to Word')
              }
            }

            const paragraphChildren: ParagraphChild[] = block.segments.map(
              (segment) => {
                return !segment.link
                  ? new TextRun(segment)
                  : new ExternalHyperlink({
                      children: [
                        new TextRun({
                          ...segment,
                          style: 'Hyperlink',
                        }),
                      ],
                      link: segment.link as string,
                    })
              }
            )

            switch (block.type) {
              case 'h1':
                return [
                  ...(pad ? [new Paragraph({ text: '' })] : []),
                  new Paragraph({
                    heading: HeadingLevel.HEADING_1,
                    children: paragraphChildren,
                  }),
                ]

              case 'h2':
                return [
                  ...(pad ? [new Paragraph({ text: '' })] : []),
                  new Paragraph({
                    heading: HeadingLevel.HEADING_2,
                    children: paragraphChildren,
                  }),
                ]

              case 'h3':
                return [
                  ...(pad ? [new Paragraph({ text: '' })] : []),
                  new Paragraph({
                    heading: HeadingLevel.HEADING_3,
                    children: paragraphChildren,
                  }),
                ]

              case 'h4':
                return [
                  ...(pad ? [new Paragraph({ text: '' })] : []),
                  new Paragraph({
                    heading: HeadingLevel.HEADING_4,
                    children: paragraphChildren,
                  }),
                ]

              case 'li':
                return new Paragraph({
                  children: paragraphChildren,
                  bullet: {
                    //todo: how to determine levels of bullet points?
                    level: 0,
                  },
                })

              case 'p':
                return new Paragraph({
                  spacing: {
                    line: 280,
                  },
                  children: paragraphChildren,
                })

              case 'a':
                return new Paragraph({
                  children: paragraphChildren,
                })
              default:
                return new Paragraph({
                  children: paragraphChildren,
                })
            }
          }),
        },
      ],
    })

    return Packer.toBlob(doc)
  }

  public getDocumentName() {
    return this.documentName
  }
}

const docConfig = {
  styles: {
    default: {
      heading1: {
        run: {
          size: 24,
          bold: true,
          italics: false,
          color: '000000',
        },
        paragraph: {
          spacing: {
            before: 120,
            after: 200,
          },
        },
      },
      heading2: {
        run: {
          size: 24,
          bold: true,
          italics: false,
          color: '000000',
        },
        paragraph: {
          spacing: {
            before: 120,
            after: 200,
          },
        },
      },
      heading3: {
        run: {
          size: 24,
          bold: true,
          italics: false,
          color: '000000',
        },
        paragraph: {
          spacing: {
            before: 120,
            after: 200,
          },
        },
      },
      heading4: {
        run: {
          size: 24,
          bold: true,
          italics: false,
          color: '000000',
        },
        paragraph: {
          spacing: {
            before: 120,
            after: 200,
          },
        },
      },
      listParagraph: {
        run: {},
        paragraph: {
          spacing: {
            before: 60,
            after: 60,
          },
        },
      },
      document: {
        run: {
          size: 20,
          font: 'Arial',
        },
        paragraph: {
          spacing: {
            line: 240,
          },
        },
      },
    },
  },
} as const
