import { ReactNode, useEffect, useState } from 'react'
import { TypographyBody } from './ui/Typography'
import {
  OptionDropdownMenu,
  OptionDropdownMenuContent,
  OptionDropdownMenuTrigger,
} from './ui/option-dropdown-menu'
import { ChevronDown, ChevronUp } from 'lucide-react'

export const StateChip = ({
  label,
  active,
  leftIcon,
  rightIcon,
  dropdownContent,
  openDropdown,
  onDropdownOpen,
  onClick,
}: {
  label: string
  active: boolean
  leftIcon?: ReactNode
  rightIcon?: ReactNode
  dropdownContent?: ReactNode
  openDropdown?: boolean
  onDropdownOpen?: (open: boolean) => void
  onClick?: () => void
}) => {
  const [open, setOpen] = useState(false)

  const handleOpenChange = (open: boolean) => {
    setOpen(open)
    onDropdownOpen?.(open)
  }

  useEffect(() => {
    if (openDropdown !== undefined) {
      setOpen(openDropdown)
    }
  }, [openDropdown])

  return (
    <div
      onClick={onClick}
      className={`inline-flex items-center rounded-full font-label px-2 py-px transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-0 h-fit w-fit ${active ? 'bg-misc-blue-bg text-misc-blue-graphic [&_svg]:stroke-misc-blue-graphic' : 'bg-system-hover hover:bg-system-border-light [&_svg]:stroke-system-body'} ${onClick ? 'cursor-pointer' : ''}`}
    >
      <div className="flex gap-2 items-center">
        <div
          className={`size-1.5 shrink-0 rounded-full ${active ? 'bg-misc-blue-graphic' : 'border border-system-body'}`}
        ></div>

        <div className="flex gap-1 items-center">
          {leftIcon}

          <TypographyBody
            className={active ? 'text-misc-blue-graphic' : 'text-system-body'}
          >
            {label}
          </TypographyBody>

          {dropdownContent ? (
            <OptionDropdownMenu open={open} onOpenChange={handleOpenChange}>
              <OptionDropdownMenuTrigger>
                {open ? (
                  <ChevronUp className="size-6 shrink-0 stroke-interactive" />
                ) : (
                  <ChevronDown className="size-6 shrink-0 stroke-interactive" />
                )}
              </OptionDropdownMenuTrigger>

              <OptionDropdownMenuContent
                className="w-fit px-2 py-2 min-w-[12.5rem]"
                align="end"
              >
                {dropdownContent}
              </OptionDropdownMenuContent>
            </OptionDropdownMenu>
          ) : (
            <>{rightIcon}</>
          )}
        </div>
      </div>
    </div>
  )
}
