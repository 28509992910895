import { useContext, useEffect } from 'react'
import shortid from 'shortid'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  getSettingsSnapshot,
  getTimestamp,
  withSettingsSnapshot,
} from '../../utils/utils'
import { NewAsk } from './NewAsk'
import { AssistantAskMode, DESIA_EVENT } from '../../types/types'
import { useSelector, useDispatch } from 'react-redux'
import type { RootState, AppDispatch } from '../../store/store'
import { actions as assistantActions } from './assistantSlice'
import { UserContext } from '@/contexts/UserContext'
import { getAskToolEvents } from '@/utils/ask'

export function Ask() {
  const { settings, updateSettings } = useContext(UserContext)
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { dossier_id } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const initialQuery = searchParams.get('q')
  const initialMode = searchParams.get('mode') as AssistantAskMode
  const trigger = searchParams.get('trigger')

  const dossiers = useSelector((state: RootState) => state.dossier.dossiers)
  const dossier = dossier_id
    ? dossiers.data?.find((v) => v.id === dossier_id)
    : undefined

  function handleSubmit({
    message,
    mode,
  }: {
    message: string
    mode: AssistantAskMode
  }) {
    const requestId = `new_ask__${shortid()}`
    dispatch(
      assistantActions.newAsk({
        requestId,
        question: message,
        timestamp: getTimestamp(),
        mode,
        tool_events: getAskToolEvents(
          dossier
            ? settings.assistant.sources.dossier
            : settings.assistant.sources.ask
        ),
      })
    )
    const encodedQuestion = encodeURIComponent(message)

    if (dossier_id) {
      navigate(
        `/dossier/${dossier_id}/assistant/ask/new/?request_id=${requestId}&q=${encodedQuestion}&mode=${mode}&trigger=true`
      )
    } else {
      navigate(
        `/assistant/ask/new/?request_id=${requestId}&q=${encodedQuestion}&mode=${mode}&trigger=true`
      )
    }
  }

  useEffect(() => {
    if (trigger === 'true' && initialQuery && initialMode) {
      setSearchParams((next) => {
        next.delete('trigger')
        return next
      })
      handleSubmit({ message: initialQuery, mode: initialMode })
    }

    const snapshot = getSettingsSnapshot({
      event: DESIA_EVENT.CHAT_ASK,
      conversationId: 'home',
    })

    if (snapshot && snapshot.settings) {
      updateSettings({
        settings: {
          ...settings,
          assistant: {
            ...settings.assistant,
            sources: {
              ...settings.assistant.sources,
              ask: {
                ...snapshot.settings.assistant.sources.ask,
              },
            },
          },
        },
      })
    }
  }, [])

  useEffect(() => {
    withSettingsSnapshot({
      event: DESIA_EVENT.CHAT_ASK,
      conversationId: 'home',
      settings: settings,
    })
  }, [settings])

  const expertModeAvailable = false
  const hasSourceSelector = true

  return (
    <NewAsk
      dossier={dossier}
      expertModeAvailable={expertModeAvailable}
      hasSourceSelector={hasSourceSelector}
      handleSubmit={handleSubmit}
    />
  )
}
