import { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import { Button } from '../ui/button'
import { EllipsisVertical } from 'lucide-react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store/store'
import { requestDeleteReport } from '../DocGen/docGenSlice'
import { getTimestamp } from '@/utils/utils'
import { useLocation, useNavigate } from 'react-router-dom'
import ExportToWord from './ExportToWord'

export const ReportDropdownMenu = ({
  reportId,
  reportTitle,
}: {
  reportId: string
  reportTitle: string
}) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [deletedReport, setDeletedReport] = useState<string | null>(null)

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const location = useLocation()

  const deleteReportHandler = () => {
    setDeletedReport(reportId)
    setShowDeleteDialog(true)
  }

  const deleteReport = () => {
    if (!deletedReport) return

    dispatch(
      requestDeleteReport({
        requestId: 'docgen:delete_report',
        params: {
          report_id: reportId,
        },
        timestamp: getTimestamp(),
      })
    )

    if (location.pathname.includes('/report/')) {
      navigate(-1)
    }
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger className="flex items-center" asChild>
          <Button
            variant="tertiary"
            className="child-hover"
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
            }}
          >
            <EllipsisVertical className="w-6 h-6 shrink-0 stroke-[1.5px]" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          <DropdownMenuItem variant="tertiary">
            <ExportToWord reportId={reportId} reportTitle={reportTitle} />
          </DropdownMenuItem>
          <DropdownMenuItem
            variant="tertiary-destructive"
            onClick={(e) => {
              e.stopPropagation()
              deleteReportHandler()
            }}
          >
            Delete report
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Dialog
        open={showDeleteDialog}
        onOpenChange={(v) => setShowDeleteDialog(v)}
      >
        <DialogContent
          className="w-[31.5rem]"
          onClick={(e) => e.stopPropagation()}
        >
          <DialogHeader>
            <DialogTitle className="max-w-[calc(31.5rem-3rem)]">{`You are about to delete report ${reportTitle || 'Untitled'}`}</DialogTitle>
            <DialogDescription>This action cannot be undone.</DialogDescription>
          </DialogHeader>
          <DialogFooter className="mt-2">
            <Button
              variant="secondary-destructive"
              onClick={(e) => {
                e.stopPropagation()
                setShowDeleteDialog(false)
                deleteReport()
              }}
            >
              Delete report
            </Button>

            <Button
              onClick={(e) => {
                e.stopPropagation()
                setShowDeleteDialog(false)
                setDeletedReport(null)
              }}
            >
              Keep report
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  )
}
