import { SourceDocument, TableType } from '@/types/types'
import { SourceContent, SourceHeader } from '../Source'
import Markdown from 'markdown-to-jsx'
import { ReactNode } from 'react'
import { ResponseTable } from '../Table/ResponseTable'

export const WebSourceList = ({ sources }: { sources: SourceDocument[] }) => {
  return (
    <div className="flex flex-col gap-0 overflow-y-auto">
      {sources.map((source) => {
        const hasTableData = source.doc_metadata?.table_json
        return (
          <div
            key={`web-source-item-${source.document_id}`}
            className="flex flex-col gap-3 py-4 px-2 border-b border-system-border-regular last:border-none"
          >
            <SourceHeader
              id={source.document_id}
              url={source.url || ''}
              title={source.title || ''}
              text={source.text}
              metadata={
                typeof source.doc_metadata?.doc_metadata === 'string'
                  ? JSON.parse(source.doc_metadata?.doc_metadata)
                  : source.doc_metadata
              }
            />

            <SourceContent
              id={source.document_id}
              url={source.url || ''}
              title={source.title || ''}
              text={source.text}
              metadata={
                typeof source.doc_metadata?.doc_metadata === 'string'
                  ? JSON.parse(source.doc_metadata?.doc_metadata)
                  : source.doc_metadata
              }
              isSearchResult={true}
            />
            {!hasTableData && (
              <div className="p-4 bg-system-surface-light rounded-lg text-system-body">
                <Markdown
                  options={{
                    overrides: {
                      Highlight: {
                        component: ({ children }: { children: ReactNode }) => (
                          <span className="bg-[linear-gradient(0deg,_var(--citation-highlight),_var(--citation-highlight))] bg-no-repeat bg-[length:100%_90%]">
                            {children}
                          </span>
                        ),
                      },
                    },
                    forceBlock: true,
                    wrapper: 'span',
                  }}
                >
                  {source.text}
                </Markdown>
              </div>
            )}

            {hasTableData && (
              <ResponseTable
                key={`response-table-container-${source.document_id}`}
                id={source.document_id}
                data={JSON.stringify(source.doc_metadata?.table_json)}
                type={TableType.FINANCIAL_DATA}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}
