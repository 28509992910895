import { DocgenTemplate } from "@/types/types";
import { Button } from "../ui/button";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog";
import { useDispatch } from "react-redux";
import { AppDispatch } from "@/store/store";
import { deleteTemplate } from "./docGenThunk";
import { removeTemplate } from "./docGenSlice";

export const DocGenTemplateDeleteDialog = ({ template, open, setOpen }: { template: DocgenTemplate, open: boolean, setOpen: (open: boolean) => void }) => {
    const dispatch = useDispatch<AppDispatch>();

    const handleDelete = async (template: DocgenTemplate) => {
        await dispatch(deleteTemplate(template.id))
        dispatch(removeTemplate(template))
        setOpen(false)
    }

    return (
        <Dialog open={open} onOpenChange={(v) => setOpen(v)}>
            <DialogContent className="max-w-full sm:max-w-[31.5rem]">
                <DialogHeader>
                    <DialogTitle className="w-full">
                        <span className="font-h4 max-w-[25rem] line-clamp-2 break-all">
                            {`You are about to delete ${template.title}`}
                        </span>
                    </DialogTitle>
                    <DialogDescription>
                        This action cannot be undone.
                    </DialogDescription>
                </DialogHeader>
                <DialogFooter className="mt-2">
                    <Button variant="secondary-destructive" onClick={() => handleDelete(template)}>Delete template</Button>
                    <DialogClose asChild>
                        <Button>
                            Keep template
                        </Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}