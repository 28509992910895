import { debounce } from 'lodash'
import { ASYNC_STATUS } from '@/types/types'
import { useState, useCallback, useEffect } from 'react'
import { WEB_SERVER_ENDPOINT } from '@/constants'

export const useDomainSearch = () => {
  const [query, setQuery] = useState('')
  const [searchResults, setSearchResults] = useState<string[]>([])
  const [searchStatus, setSearchStatus] = useState<ASYNC_STATUS>(
    ASYNC_STATUS.idle
  )

  const debouncedSearch = useCallback(
    debounce(async (searchQuery) => {
      if (searchQuery.trim()) {
        setSearchStatus(ASYNC_STATUS.loading)
        try {
          const res = await fetch(`${WEB_SERVER_ENDPOINT}/api/search/domain`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ query: searchQuery }),
          })

          if (!res.ok) {
            throw new Error('Failed to fetch results')
          }

          const data = await res.json()
          setSearchResults(data)
          setSearchStatus(ASYNC_STATUS.success)
        } catch (error) {
          setSearchStatus(ASYNC_STATUS.error)
        }
      } else {
        setSearchResults([])
        setSearchStatus(ASYNC_STATUS.idle)
      }
    }, 400),
    []
  )

  const handleSearchChange = (value: string) => {
    setQuery(value)
    debouncedSearch(value)
  }

  useEffect(() => {
    return () => {
      debouncedSearch.cancel()
      setSearchResults([])
    }
  }, [debouncedSearch])

  return {
    query,
    searchResults,
    searchStatus,
    handleSearchChange,
  }
}
