import { ASK_UPLOAD_VISIBILITY, MAX_UPLOAD_FILES_ASK_BAR } from '@/constants'
import { FileUp } from 'lucide-react'
import { UserContext } from '@/contexts/UserContext'
import { useFileUpload } from '@/hooks/useFileUpload'
import { AppDispatch, RootState } from '@/store/store'
import {
  ASYNC_STATUS,
  DOCUMENT_STATUS,
  ResponseDocument,
  SourceSettingsFileMetadata,
  SourceType,
  UploadableFile,
} from '@/types/types'
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions as documentActions } from '@/components/Document/documentSlice'
import { TypographyBody } from '../ui/Typography'
import { ProcessingFileRowStandalone } from '../Resources/ProcessingFiles'
import { ToastContext } from '@/contexts/ToastContext'
import { Button } from '../ui/button'
import { actions as impromptuUploadActions } from '../Document/impromptuUploadSlice'
import AskUploadFileWarningDialog from './AskUploadFileWarningDialog'

type DropzoneProps = {
  isGlobal?: boolean
  sourceType: SourceType
}
export const FILE_DROPZONE_INFO_MESSAGE =
  'You can use uploaded files in Ask now, and they will be available in the Library shortly.'

const FileDropzone = memo(
  ({ isGlobal = false, sourceType = 'ask' }: DropzoneProps) => {
    const [localUploadQueue, setLocalUploadQueue] = useState<UploadableFile[]>(
      []
    )

    const [isDragging, setIsDragging] = useState<boolean>(false)
    const [isExceedingLimit, setIsExceedingLimit] = useState<boolean>(false)

    const containerRef = useRef<HTMLDivElement | null>(null)
    const inputRef = useRef<HTMLInputElement | null>(null)

    const dispatch = useDispatch<AppDispatch>()

    const { settings, updateSettings } = useContext(UserContext)
    const { showToast } = useContext(ToastContext)

    const { processingFiles, files: resources } = useSelector(
      (state: RootState) => state.document
    )
    const { temporaryDocuments, uploadQueue } = useSelector(
      (state: RootState) => state.impromptuUpload
    )

    const resourceMap = useMemo(() => {
      return new Map(resources.map((r) => [r.document_id, r]))
    }, [resources])

    const { uploadFiles } = useFileUpload({
      onSubmit: (uploadedFiles, visibility) => {
        const res = uploadedFiles.reduce<{
          skipped: Partial<ResponseDocument>[]
          process: Partial<ResponseDocument>[]
        }>(
          (acc, file) => {
            const id = file.signedUpload?.headers[
              'x-goog-meta-document_id'
            ] as string

            if (temporaryDocuments[id]) {
              return acc
            }

            if (file.skipUpload) {
              const existingFile = resourceMap.get(id)

              if (existingFile) {
                acc.skipped.push({
                  document_id: existingFile.document_id,
                  document_name: existingFile.document_name,
                  document_type_friendly: existingFile.document_type_friendly,
                  document_processing_status: DOCUMENT_STATUS.AVAILABLE,
                  document_is_ready_to_use:
                    existingFile.document_is_ready_to_use,
                  document_visibility: existingFile.document_visibility,
                })
              }

              return acc
            }

            acc.process.push({
              document_id: id,
              document_name: file.file.name,
              document_type_friendly: file.file.type,
              document_processing_status: DOCUMENT_STATUS.UPLOADING,
              document_is_ready_to_use: false,
              document_visibility: visibility,
            })

            return acc
          },
          { skipped: [], process: [] }
        )

        const allFiles: Partial<ResponseDocument>[] = [
          ...res.skipped,
          ...res.process,
        ]

        const newProcessingFiles: Record<string, ResponseDocument> = {
          ...processingFiles,
          ...Object.fromEntries(
            res.process.map((file) => [file.document_id, file])
          ),
        }

        if (isGlobal) {
          showToast(
            {
              variant: 'info',
              description: FILE_DROPZONE_INFO_MESSAGE,
              dismissable: false,
            },
            1_000 * 3
          )
        }

        dispatch(documentActions.setProcessingFiles(newProcessingFiles))
        updateSettings({
          settings: {
            ...settings,
            assistant: {
              ...settings.assistant,
              sources: {
                ...settings.assistant.sources,
                [sourceType]: {
                  ...settings.assistant.sources[sourceType],
                  focusedAnalysis: true,
                  internalSearch: true,
                  uploadedFiles: [
                    ...settings.assistant.sources[sourceType].uploadedFiles,
                    ...allFiles.map((f) => ({
                      id: f.document_id!,
                      title: f.document_name!,
                    })),
                  ],
                  files: [],
                },
              },
            },
          },
        })
      },
      preferredVisibility: ASK_UPLOAD_VISIBILITY,
    })

    const uploadedFilesArray = useMemo(() => {
      return Object.values(temporaryDocuments)
    }, [temporaryDocuments])

    const totalFilesLength = uploadedFilesArray.length + uploadQueue.length

    const handleResetFiles = () => {
      dispatch(impromptuUploadActions.resetUploadState())
      setLocalUploadQueue([])
    }

    const handleDeselectUpload = (id: string) => {
      const updatedFiles = Object.values(temporaryDocuments).filter(
        (file) => file.document_id !== id
      )

      dispatch(impromptuUploadActions.removeTemporaryDocument(id))

      updateSettings({
        settings: {
          ...settings,
          assistant: {
            ...settings.assistant,
            sources: {
              ...settings.assistant.sources,
              [sourceType]: {
                ...settings.assistant.sources[sourceType],
                focusedAnalysis: updatedFiles.length > 0,
                ...(updatedFiles.length > 0 && {
                  internalSearch: updatedFiles.length > 0,
                }),
                uploadedFiles: updatedFiles.map((f) => ({
                  title: f.document_name!,
                  id: f.document_id!,
                })),
                files: [],
              },
            },
          },
        },
      })
    }

    const handleFileChange = useCallback(
      (eventFiles: FileList) => {
        const uploadableFiles = Array.from(eventFiles).map((file) => ({
          file,
          signedUpload: null,
          upload: { status: ASYNC_STATUS.idle },
        }))

        const exceedingLimit =
          uploadableFiles.length + totalFilesLength > MAX_UPLOAD_FILES_ASK_BAR

        if (exceedingLimit) {
          setIsExceedingLimit(true)
        } else {
          setLocalUploadQueue(uploadableFiles)
          dispatch(
            impromptuUploadActions.addToUploadQueue(
              uploadableFiles.map((f) => ({
                name: f.file.name,
                type: f.file.type,
              }))
            )
          )
        }

        setIsDragging(false)
      },
      [settings, totalFilesLength]
    )

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      const eventFiles = e.target?.files
      if (!eventFiles) return

      handleFileChange(eventFiles)
      e.target.value = ''
    }

    const handleDrop = (e: DragEvent) => {
      e.preventDefault()
      e.stopPropagation()

      const eventFiles = e.dataTransfer?.files
      if (!eventFiles) return

      handleFileChange(eventFiles)
    }

    const handleDragOver = (e: DragEvent) => {
      e.preventDefault()
      setIsDragging(true)
    }

    const handleDragLeave = () => {
      setIsDragging(false)
    }

    useEffect(() => {
      const layout = isGlobal
        ? document.getElementById('main-layout')
        : containerRef.current

      if (layout) {
        layout.addEventListener('dragover', handleDragOver)
        layout.addEventListener('dragleave', handleDragLeave)
        layout.addEventListener('drop', handleDrop)
      }

      return () => {
        if (layout) {
          layout.removeEventListener('dragover', handleDragOver)
          layout.removeEventListener('dragleave', handleDragLeave)
          layout.removeEventListener('drop', handleDrop)
        }
      }
    }, [isGlobal, containerRef, handleDrop])

    useEffect(() => {
      const uploadFilesHandler = async () => {
        await uploadFiles(localUploadQueue)
        dispatch(impromptuUploadActions.clearUploadQueue())
        setLocalUploadQueue([])
      }

      if (localUploadQueue.length > 0) {
        uploadFilesHandler()
      }
    }, [localUploadQueue, uploadedFilesArray])

    useEffect(() => {
      if (!resourceMap || resourceMap.size === 0) return

      const storedFiles: SourceSettingsFileMetadata[] =
        settings.assistant.sources[sourceType].uploadedFiles

      const mergedFiles: { [documentId: string]: Partial<ResponseDocument> } =
        storedFiles.reduce(
          (
            acc: { [key: string]: Partial<ResponseDocument> },
            file: SourceSettingsFileMetadata
          ) => {
            const foundDocumentFromEndpoint = resourceMap.get(file.id)
            const foundDocumentIsProcessing = processingFiles[file.id]

            const usedDocument =
              foundDocumentFromEndpoint ?? foundDocumentIsProcessing ?? file

            acc[file.id] = {
              document_id: usedDocument.document_id,
              document_name: usedDocument.document_name,
              document_is_ready_to_use: false,
              document_processing_status: DOCUMENT_STATUS.UPLOADING,
              document_type_friendly: usedDocument.document_type_friendly,
              document_visibility: usedDocument.document_visibility,
            }

            return acc
          },
          {}
        )

      dispatch(impromptuUploadActions.setTemporaryDocuments(mergedFiles))
    }, [resourceMap, processingFiles, settings, sourceType])

    useEffect(() => {
      return () => {
        if (isGlobal) {
          handleResetFiles()
        }
      }
    }, [isGlobal])

    if (isGlobal) {
      return (
        <>
          {isDragging && (
            <div
              className="rounded-[12px] w-full h-[5.5rem] flex justify-center items-center bg-system-hover"
              style={{
                backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%2394A3B8FF' stroke-width='3' stroke-dasharray='8%2c 16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
              }}
            >
              <TypographyBody className="text-system-body">
                Drag and drop files here to upload
              </TypographyBody>
            </div>
          )}

          {!!totalFilesLength && (
            <div
              className={`w-full h-fit grid grid-cols-3 justify-start gap-3 pb-2`}
            >
              {!!uploadedFilesArray.length && (
                <>
                  {uploadedFilesArray.map((file) => {
                    const documentMetadata = {
                      documentId: file.document_id!,
                      documentName: file.document_name!,
                      documentVisibility: file.document_visibility!,
                      documentType: file.document_type_friendly!,
                    }
                    return (
                      <ProcessingFileRowStandalone
                        key={`processing-file-row=${documentMetadata.documentId}`}
                        documentMetadata={documentMetadata}
                        isSuccess={true}
                        onRemove={() => {
                          handleDeselectUpload(documentMetadata.documentId)
                        }}
                        onDeselect={() => {
                          handleDeselectUpload(documentMetadata.documentId)
                        }}
                      />
                    )
                  })}
                </>
              )}
              {!!uploadQueue.length && (
                <>
                  {uploadQueue.map((file: any, idx: number) => {
                    const documentMetadata = {
                      documentId: `queued-upload-file-${idx.toString()}`,
                      documentName: file.name,
                      documentVisibility: ASK_UPLOAD_VISIBILITY,
                      documentType: file.type,
                    }
                    return (
                      <ProcessingFileRowStandalone
                        key={`processing-file-row=${documentMetadata.documentId}`}
                        documentMetadata={documentMetadata}
                        isLoading={true}
                      />
                    )
                  })}
                </>
              )}
            </div>
          )}

          {isDragging && (
            <div className="left-0 top-0 fixed w-screen h-screen z-[1000] dropzone"></div>
          )}

          <AskUploadFileWarningDialog
            open={isExceedingLimit}
            onCancel={() => {
              setIsExceedingLimit(false)
            }}
          />
        </>
      )
    }

    if (!isGlobal) {
      return (
        <>
          <div className="flex flex-col gap-4">
            {!!totalFilesLength && (
              <div
                className={`w-full h-fit grid grid-cols-3 justify-start gap-3 pb-2`}
              >
                {!!uploadedFilesArray.length && (
                  <>
                    {uploadedFilesArray.map((file) => {
                      const documentMetadata = {
                        documentId: file.document_id!,
                        documentName: file.document_name!,
                        documentVisibility: file.document_visibility!,
                        documentType: file.document_type_friendly!,
                      }
                      return (
                        <ProcessingFileRowStandalone
                          key={`processing-file-row=${documentMetadata.documentId}`}
                          documentMetadata={documentMetadata}
                          isSuccess={true}
                          onDeselect={() => {
                            handleDeselectUpload(documentMetadata.documentId)
                          }}
                        />
                      )
                    })}
                  </>
                )}
                {!!uploadQueue.length && (
                  <>
                    {uploadQueue.map((file: any, idx: number) => {
                      const documentMetadata = {
                        documentId: idx.toString(),
                        documentName: file.name,
                        documentVisibility: 'private',
                        documentType: file.type,
                      }
                      return (
                        <ProcessingFileRowStandalone
                          key={`processing-file-row=${documentMetadata.documentId}`}
                          documentMetadata={documentMetadata}
                          isLoading={true}
                        />
                      )
                    })}
                  </>
                )}
              </div>
            )}
            <div
              ref={containerRef}
              className={`flex gap-2.5 rounded-[12px] w-full h-[5.5rem] flex justify-center items-center ${isDragging ? 'bg-system-hover' : 'bg-system-surface'}`}
              style={{
                backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%2394A3B8FF' stroke-width='3' stroke-dasharray='8%2c 16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
              }}
            >
              {!isDragging && (
                <Button
                  variant="secondary"
                  onClick={() => inputRef.current?.click()}
                  disabled={false}
                >
                  <FileUp className="w-6 h-6 shrink-0 stroke-[1.5px]" />
                  Upload Files
                </Button>
              )}

              <TypographyBody className="text-system-body">
                {isDragging
                  ? 'Drag and drop files here to upload'
                  : 'or drag and drop files here'}
              </TypographyBody>
            </div>
          </div>
          <AskUploadFileWarningDialog
            open={isExceedingLimit}
            onCancel={() => {
              setIsExceedingLimit(false)
            }}
          />
          <input
            ref={inputRef}
            id="file-upload"
            type="file"
            name="file"
            className="hidden"
            multiple={true}
            onChange={(e) => handleInput(e)}
          />
        </>
      )
    }
  }
)

export default FileDropzone
