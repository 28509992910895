import { ApexChartsResponseChartProps } from '@/types/types'
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { ChartHeader } from './ChartHeader'
import { useEffect, useRef, useState } from 'react'
import {
  formatTruncateDataLabel,
  pxToCh,
  getChartCitation,
  getChartColor,
} from './utils'
import { getLabelColor, formatDisplayNumber } from '@/utils/utils'
import { ChartTooltip } from './ChartTooltip'
import { cn } from '@/shadcn/utils'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import { THEME_MAX_AMOUNT } from '@/constants'

export const ResponseStackedBarChart = ({
  id,
  parsedData,
  series,
  options,
  compact,
  citations,
  documents,
  openedCitation,
  onCitationOpen,
  categories,
  className,
  isFormatted,
  isExportEnabled = true,
}: ApexChartsResponseChartProps) => {
  const { theme } = useSelector((state: RootState) => state.theme)

  const ref = useRef<HTMLDivElement>(null)
  const containerId = crypto.randomUUID()
  const barAmount = parsedData.data.length
  const stackAmount = series.length
  const [maxChar, setMaxChar] = useState<number>(5)

  const fontSize = 10

  const redrawSvg = () => {
    const parentElement = document.getElementById(`container-${containerId}`)
    const seriesElements = parentElement?.querySelectorAll(
      `.apexcharts-bar-series.apexcharts-plot-series .apexcharts-series`
    )
    const seriesDataLength =
      seriesElements?.[0]?.querySelectorAll('.apexcharts-bar-area').length || 0

    for (let i = 0; i < seriesDataLength; i++) {
      const lastElements: Element[] = []

      seriesElements?.forEach((serie) => {
        const bar = serie.querySelectorAll('.apexcharts-bar-area')[i]
        if (bar && bar.getAttribute('barHeight') !== '0') {
          lastElements.push(bar)
        }
      })

      const lastElement = lastElements[lastElements.length - 1]
      if (lastElement) {
        lastElement.setAttribute(
          'clip-path',
          'inset(0% 0% -11% 0% round 2px 2px 0 0)'
        )
      }
    }
  }

  const barOptions: ApexOptions = {
    ...options,
    chart: {
      ...options.chart,
      type: 'bar',
      stacked: true,
      events: {
        ...options.chart?.events,
        mounted: (chart) => {
          options.chart?.events?.mounted?.(chart) // fixme: investigate later
          redrawSvg()
          if (
            openedCitation &&
            openedCitation.chart_table_id === id &&
            !openedCitation.isChartHeaderCitation
          ) {
            chart.toggleDataPointSelection(
              openedCitation.index_y,
              openedCitation.index_x
            )
          }
        },
        updated: (chart) => {
          options.chart?.events?.updated?.(chart)
          redrawSvg()
        },
        dataPointSelection: (_, chartContext, config) => {
          const citation = getChartCitation(
            chartContext.w.globals.series[config.seriesIndex][
              config.dataPointIndex
            ],
            citations,
            config.dataPointIndex,
            config.seriesIndex
          )

          if (citation) {
            onCitationOpen?.(citation, 0)
          }
        },
      },
    },
    xaxis: {
      ...options.xaxis,
    },
    yaxis: {
      show: false,
    },
    stroke: {
      width: 0,
    },
    dataLabels: {
      enabled: stackAmount > 1,
      formatter: (val: string | number) => {
        return formatTruncateDataLabel({
          label: isFormatted ? formatDisplayNumber(val) : `${val}`,
          maxChar: maxChar,
          categoryAmount: barAmount,
        })
      },
      style: {
        fontSize: `14px`,
        fontFamily: 'Inter, sans-serif',
        fontWeight: 350,
        colors: [
          function ({ seriesIndex }: { seriesIndex: number; w: any }) {
            if (theme.texts.length < THEME_MAX_AMOUNT) {
              if (seriesIndex >= theme.texts.length) {
                return getLabelColor(getChartColor(seriesIndex))
              } else {
                return theme.texts[seriesIndex]
              }
            }

            return theme.texts[seriesIndex % theme.texts.length]
          },
        ],
      },
      textAnchor: 'middle',
      offsetY: 2,
    },
    plotOptions: {
      ...options.plotOptions,
      bar: {
        ...options.plotOptions?.bar,
        borderRadius: 0,
        columnWidth: barAmount <= 5 ? '50%' : '80%',
        dataLabels: {
          ...options.plotOptions?.bar?.dataLabels,
          total: {
            formatter: (val: string) => {
              return formatTruncateDataLabel({
                label: formatDisplayNumber(val),
                maxChar: maxChar,
                categoryAmount: barAmount,
              })
            },
            enabled: true,
            style: {
              fontSize: '14px',
              fontFamily: 'Inter, sans-serif',
              fontWeight: 400,
            },
            offsetY: -4,
          },
        },
      },
    },
  }

  useEffect(() => {
    const parentElement = document.getElementById(`chart-${id}`)

    const barElements: NodeListOf<HTMLDivElement> =
      parentElement?.querySelectorAll(
        `.apexcharts-bar-area`
      ) as NodeListOf<HTMLDivElement>

    if (barElements && barElements.length > 0) {
      const barWidth = barElements[0].getAttribute('barWidth')
      if (barWidth) {
        const barWidthInt = parseInt(barWidth)
        const calcMaxChar = pxToCh(barWidthInt, fontSize)
        setMaxChar(calcMaxChar)
      }
    }
  }, [id])

  return (
    <div className={cn(`p-4 ${compact ? 'mt-8' : 'mt-10'}`, className)}>
      <div
        id={`chart-container-${id}`}
        className={`flex flex-col gap-4 relative group`}
      >
        <ChartHeader
          chartRef={ref}
          parsedData={parsedData}
          compact={compact}
          citations={citations}
          documents={documents}
          openedCitation={openedCitation}
          onCitationOpen={onCitationOpen}
          isExportEnabled={isExportEnabled}
        />

        {openedCitation?.chart_table_id === id &&
          !openedCitation.isChartHeaderCitation && (
            <ChartTooltip
              id={id}
              categories={categories}
              series={series}
              dataPointIndex={openedCitation!.index_x!}
              seriesIndex={openedCitation!.index_y!}
              citations={citations}
              openedCitation={openedCitation}
              withShadow={false}
              onClose={() => onCitationOpen?.(null, 0)}
              showOpenedCitation
            />
          )}

        <div
          id={`container-${containerId}`}
          className="relative w-full h-[18.75rem]"
        >
          <div
            className={`absolute max-w-full ${barAmount > 15 ? 'w-fit' : 'w-full'} max-w-[calc(100vw-2rem)] tablet:max-w-[calc(100vw-4rem)] laptop:max-w-[calc(100vw-11.25rem)] tablet:left-[50%] tablet:-translate-x-[50%] overflow-x-auto laptop:overflow-x-visible overflow-y-visible h-[18.75rem]`}
          >
            <div
              ref={ref}
              id={`chart-${id}`}
              className={`${barAmount > 30 ? 'w-[75rem]' : barAmount > 15 ? 'w-[62.5rem]' : 'w-full'} h-full [&_.apexcharts-tooltip]:!border-none [&_.apexcharts-tooltip]:!rounded-sm [&_.apexcharts-tooltip]:!bg-system-secondary`}
            >
              <Chart
                options={barOptions}
                series={series}
                type="bar"
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
