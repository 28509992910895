import { ResponseChartData } from '@/types/types'

export const generateMockChartData = (
  chartType: ResponseChartData['chartType'],
  title: string,
  amount?: number
): ResponseChartData => {
  if (chartType === 'stackedbar') {
    return {
      data: [
        { label: '2020', value: [80, 80, 80, 80, 80] },
        { label: '2021', value: [160, 160, 160, 200, 200] },
        { label: '2023', value: [100, 100, 100, 100, 120] },
        { label: '2024', value: [100, 100, 80, 200, 190] },
        { label: '2025', value: [80, 100, 100, 140, 45] },
      ],
      label: 'Year',
      values: ['1', '2', '3', '4', '5'],
      chartType: chartType,
      title: title,
    }
  }

  if (chartType === 'bar') {
    return {
      data: [
        { label: '2020', value: [1100, 510, 510] },
        { label: '2021', value: [1900, 1350, 1350] },
        { label: '2023', value: [1499, 1300, 1300] },
        { label: '2024', value: [400, 1350, 1350] },
      ],
      label: 'Year',
      values: ['1', '2', '3'],
      chartType: chartType,
      title: title,
    }
  }

  if (chartType === 'line') {
    return {
      data: [
        { label: '2020', value: [1800, 510, 2300] },
        { label: '2021', value: [1900, 980, 1350] },
        { label: '2023', value: [1499, 200, 1300] },
        { label: '2024', value: [400, 1120, 1350] },
      ],
      label: 'Year',
      values: ['1', '2', '3'],
      chartType: chartType,
      title: title,
    }
  }

  if (chartType === 'pie') {
    const mockData: { label: string; value: number[] }[] = []
    const iter = amount ?? 5

    for (let idx = 0; idx < iter; idx++) {
      mockData.push({
        label: `Segment ${idx + 1}`,
        value: [Math.floor(Math.random() * 100) + 100],
      })
    }

    return {
      data: mockData,
      label: 'Segments',
      values: ['1'],
      chartType: chartType,
      title: title,
    }
  }
  return {
    data: [
      { label: '2020', value: [1100, 510, 510] },
      { label: '2021', value: [1900, 1350, 1350] },
      { label: '2023', value: [1499, 1300, 1300] },
      { label: '2024', value: [400, 1350, 1350] },
    ],
    label: 'Year',
    values: ['1', '2', '3'],
    chartType: chartType,
    title: title,
  }
}
