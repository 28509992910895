import { Link } from 'react-router-dom'
import { Button } from '../ui/button'
import { ArrowLeft, Loader2, PlusIcon, RotateCcw } from 'lucide-react'
import { TypographyBody, TypographyH3 } from '../ui/Typography'
import { ColorSwatch } from './ColorSwatch'
import Divider from '../ui/divider'
import { ResponseStackedBarChart } from '../Charts/ResponseStackedBarChart'
import { generateMockChartData } from './mockChartData'
import {
  formatChartDate,
  getCategories,
  getChartColor,
  getSeries,
  redrawMarkers,
} from '../Charts/utils'
import { ApexOptions } from 'apexcharts'
import { Color as ColorRc } from '@rc-component/color-picker'
import {
  getLabelColor,
  formatDisplayNumber,
  getDesiaDefaultTheme,
  getExtendedTheme,
} from '@/utils/utils'
import { ResponseBarChart } from '../Charts/ResponseBarChart'
import { ResponseLineChart } from '../Charts/ResponseLineChart'
import Checkmark from '@/assets/Checkmark'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchThemePreferences, updateThemePreferences } from './themeThunks'
import { QueryStatus, Theme } from '@/types/types'
import { AppDispatch, RootState } from '@/store/store'
import { Color, THEME_MAX_AMOUNT, THEME_MIN_AMOUNT } from '@/constants'
import { ResponsePieChart } from '../Charts/ResponsePieChart'
import { ChartConfig } from '../ui/chart'

export const ThemeSettingsPage = () => {
  const dispatch = useDispatch<AppDispatch>()

  const { MockBarChart, MockLineChart, MockStackedBarChart, MockPieChart } =
    useMockCharts()

  const { theme, fetchStatus, updateStatus } = useSelector(
    (state: RootState) => state.theme
  )

  const [isUpdated, setIsUpdated] = useState<boolean>(false)
  const [colors, setColors] = useState<string[]>(theme.colors)

  const updateLoading = updateStatus === QueryStatus.FETCHING
  const fetchLoading = fetchStatus === QueryStatus.FETCHING

  const handleSaveChanges = async () => {
    if (updateLoading || fetchLoading) return

    const updatedTheme: Theme = {
      colors,
      texts: colors.map((c) => getLabelColor(c)),
    }

    await dispatch(updateThemePreferences(updatedTheme))
    await dispatch(fetchThemePreferences())
    setIsUpdated(false)
  }

  const handleDiscardChanges = () => {
    setIsUpdated(false)
    setColors(theme.colors)
  }

  const handleResetToDefault = () => {
    setIsUpdated(true)

    const defaultColors = getDesiaDefaultTheme()
    setColors(defaultColors.colors)
  }

  const handleAddColor = () => {
    setIsUpdated(true)

    setColors((prevColors) => [...prevColors, getChartColor(colors.length)])
  }

  const handleRemoveColor = (idx: number) => {
    setIsUpdated(true)

    setColors((prevColors) => prevColors.filter((_, index) => index !== idx))
  }

  useEffect(() => {
    setColors(theme.colors)
  }, [theme.colors])

  return (
    <div className="flex flex-col gap-0 mx-[5.5rem] ">
      <Link to={`/integrations`}>
        <Button variant="tertiary">
          <div className="flex gap-2 items-center">
            <ArrowLeft className="size-6 shrink-0 stroke-[1.5px]" />
            Integrations
          </div>
        </Button>
      </Link>
      <div className="w-full items-center justify-center flex">
        <div className="flex flex-col gap-10 max-w-[75.375rem]">
          <section className="flex flex-col gap-10 mx-auto w-full">
            <div className="flex flex-col gap-2 items-center">
              <TypographyH3 className="text-system-primary">
                Theme settings
              </TypographyH3>
              <TypographyBody className="font-label text-system-body">
                Customize charts according to your company guidelines
              </TypographyBody>
            </div>
          </section>
          <section>
            <div className="flex flex-col w-full gap-6 flex-wrap justify-center items-center">
              <div className="flex w-full gap-4 flex-wrap justify-center items-center px-6">
                {colors.map((color, idx) => (
                  <ColorSwatch
                    key={`swatch-${idx}`}
                    value={color}
                    setValue={(newColor: ColorRc) => {
                      if (!isUpdated) setIsUpdated(true)
                      setColors((prevColors) => {
                        const newColors = [...prevColors]
                        newColors[idx] = newColor.toHexString()
                        return newColors
                      })
                    }}
                    colorIndex={idx + 1}
                    onDelete={
                      idx >= THEME_MIN_AMOUNT
                        ? () => {
                            handleRemoveColor(idx)
                          }
                        : undefined
                    }
                    isPicker
                  />
                ))}

                <button
                  onClick={handleAddColor}
                  className="w-[4.75rem] h-[4.75rem] justify-center items-center flex border border-[1px] rounded-lg bg-system-secondary border-system-border-regular"
                >
                  <PlusIcon />
                </button>
              </div>
              <Button
                variant="tertiary"
                className="flex gap-2"
                onClick={handleResetToDefault}
              >
                <RotateCcw />
                <TypographyBody>Reset to Desia default colors</TypographyBody>
              </Button>
              {isUpdated && (
                <div className="flex gap-2 w-full justify-center items-center">
                  <Button variant="secondary" onClick={handleDiscardChanges}>
                    <div className="flex gap-2 items-center">Discard</div>
                  </Button>

                  <Button
                    onClick={handleSaveChanges}
                    disabled={fetchLoading || updateLoading}
                  >
                    <div className="flex gap-2 items-center">
                      {fetchLoading || updateLoading ? (
                        <Loader2 className="size-6 shrink-0 stroke-interactive animate-spin" />
                      ) : (
                        <Checkmark className="size-6 shrink-0 stroke-interactive" />
                      )}
                      Save changes
                    </div>
                  </Button>
                </div>
              )}
            </div>
          </section>
          <section className="flex flex-col gap-6 items-center justify-center w-full">
            <Divider />
            <div className="flex flex-col w-full gap-3">
              <TypographyBody className="font-label text-system-body ">
                Preview
              </TypographyBody>
              <div className="grid grid-cols-2 gap-8">
                <div className="h-full bg-[#ffffff] border border-[1px] rounded-lg border-system-border-light">
                  {MockStackedBarChart}
                </div>
                <div className="h-full bg-[#ffffff] border border-[1px] rounded-lg border-system-border-light">
                  {MockPieChart}
                </div>
                <div className="h-full bg-[#ffffff] border border-[1px] rounded-lg border-system-border-light">
                  {MockBarChart}
                </div>
                <div className="h-full bg-[#ffffff] border border-[1px] rounded-lg border-system-border-light">
                  {MockLineChart}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

const useMockCharts = () => {
  const { theme } = useSelector((state: RootState) => state.theme)
  const extendedTheme = getExtendedTheme(theme)

  const baseOptions: ApexOptions = {
    chart: {
      id: 'mock',
      zoom: {
        enabled: false,
      },
      fontFamily: 'Inter, sans-serif',
      height: '400px',
      parentHeightOffset: 0,
      toolbar: {
        show: false,
      },
      events: {
        mounted: () => {
          redrawMarkers()
        },
        updated: () => {
          redrawMarkers()
        },
      },
      animations: {
        enabled: false,
      },
      selection: {
        enabled: false,
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: 'top',
      horizontalAlign: 'left',
      floating: false,
      fontSize: '14px',
      fontFamily: 'Inter, sans-serif',
      fontWeight: 400,
      inverseOrder: false,
      width: undefined,
      height: undefined,
      customLegendItems: [],
      offsetX: -36,
      offsetY: 0,
      labels: {
        colors: Color['system-body'],
        useSeriesColors: false,
      },
      markers: {
        size: 7,
        shape: 'square',
        strokeWidth: 0,
        offsetX: -2,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 8,
        vertical: 4,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    xaxis: {
      categories: [],
      labels: {
        formatter: (val: string) => {
          return formatChartDate(val)
        },
        style: {
          fontSize: '14px',
          fontFamily: 'Inter, sans-serif',
          fontWeight: 400,
          colors: Color['system-body'],
        },
        trim: true,
        rotate: 0,
        show: true,
        hideOverlappingLabels: false,
      },
      axisBorder: {
        color: Color['system-border-light'],
      },
      axisTicks: {
        color: Color['system-border-light'],
      },
      crosshairs: {
        stroke: {
          color: Color['system-border-light'],
          dashArray: 8,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        formatter: (val: number) => {
          return formatDisplayNumber(`${val}`)
        },
        style: {
          fontSize: '14px',
          fontFamily: 'Inter, sans-serif',
          fontWeight: 400,
          colors: Color['system-body'],
        },
      },
      axisBorder: {
        color: Color['system-border-light'],
      },
      tickAmount: 3,
    },
    grid: {
      borderColor: Color['system-border-light'],
    },
    markers: {
      size: 0,
      strokeWidth: 0,
      hover: {
        size: 4,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        columnWidth: '80%',
        borderRadiusApplication: 'end',
      },
    },
    colors: extendedTheme.colors,
    fill: {
      opacity: 1,
    },
    tooltip: {
      enabled: false,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
        },
      },
      active: {
        filter: {
          type: 'none',
        },
      },
    },
  }

  const MockStackedBarChart = useMemo(() => {
    const parsedData = generateMockChartData(
      'stackedbar',
      'Bar chart - stacked'
    )
    const series = getSeries(parsedData)
    const categories = getCategories(parsedData)

    const stackedBarOpts = {
      ...baseOptions,
      chart: {
        ...baseOptions.chart,
        id: 'mock-stacked-bar-chart',
      },
      xaxis: {
        ...baseOptions.xaxis,
        categories: categories,
      },
    }

    return (
      <ResponseStackedBarChart
        className="!mt-0"
        id="mock-stacked-bar-chart"
        parsedData={parsedData}
        series={series}
        options={stackedBarOpts}
        compact={true}
        citations={[]}
        documents={[]}
        openedCitation={null}
        categories={categories}
        isExportEnabled={false}
      />
    )
  }, [theme])

  const MockBarChart = useMemo(() => {
    const parsedData = generateMockChartData('bar', 'Bar Chart - Multiple')
    const series = getSeries(parsedData)
    const categories = getCategories(parsedData)

    const barOpts = {
      ...baseOptions,
      chart: {
        ...baseOptions.chart,
        id: 'mock-bar-chart',
      },
      xaxis: {
        ...baseOptions.xaxis,
        categories: categories,
      },
    }
    return (
      <ResponseBarChart
        className="!mt-0"
        id="mock-bar-chart"
        parsedData={parsedData}
        series={series}
        options={barOpts}
        compact={true}
        citations={[]}
        documents={[]}
        openedCitation={null}
        categories={categories}
        isExportEnabled={false}
      />
    )
  }, [theme])

  const MockLineChart = useMemo(() => {
    const parsedData = generateMockChartData('line', 'Line chart')
    const series = getSeries(parsedData)
    const categories = getCategories(parsedData)

    const lineOpts = {
      ...baseOptions,
      chart: {
        ...baseOptions.chart,
        id: 'mock-line-chart',
      },
      xaxis: {
        ...baseOptions.xaxis,
        categories: categories,
      },
    }
    return (
      <ResponseLineChart
        className="!mt-0"
        id="mock-line-chart"
        parsedData={parsedData}
        series={series}
        options={lineOpts}
        compact={true}
        citations={[]}
        documents={[]}
        openedCitation={null}
        categories={categories}
        isExportEnabled={false}
      />
    )
  }, [theme])

  const MockPieChart = useMemo(() => {
    const parsedData = generateMockChartData(
      'pie',
      'Pie chart',
      theme.colors.length
    )
    const categories = getCategories(parsedData)
    const clamp = Math.max(THEME_MAX_AMOUNT, theme.colors.length)

    const parsedPieDataValues = parsedData.data.map((v, idx) => {
      const data: { [label: string]: string | number } = {
        [parsedData.label]: v.label,
        fill: extendedTheme.colors[idx % clamp],
      }

      v.value.forEach((v, idx) => {
        data[parsedData.values[idx]] = v
      })

      return data
    })

    const pieParsedChartConfig: {
      [label: string]: {
        label: string
        color: string
      }
    } = parsedData.data.reduce(
      (a, b, idx) => {
        const newData = {
          ...a,
          [b.label]: {
            label: b.label,
            color: extendedTheme.colors[idx % clamp],
          },
        }

        return newData
      },
      parsedData.values.reduce((a, b) => {
        const newData = { ...a, [b]: { label: b } }

        return newData
      }, {})
    ) satisfies ChartConfig

    const pieSeries: ApexAxisChartSeries = parsedData.data.map(() => {
      return {
        name: parsedData.values[0],
        data: Array(parsedData.data.length)
          .fill(0)
          .map((_, dataIndex) => parsedData.data[dataIndex].value[0]),
      }
    })

    return (
      <ResponsePieChart
        className="!mt-0"
        id="mock-pie-chart"
        parsedChartConfig={pieParsedChartConfig}
        parsedData={parsedData}
        parsedDataValues={parsedPieDataValues}
        citations={[]}
        documents={[]}
        categories={categories}
        series={pieSeries}
        isExportEnabled={false}
      />
    )
  }, [theme])

  return {
    MockStackedBarChart,
    MockBarChart,
    MockLineChart,
    MockPieChart,
  }
}
