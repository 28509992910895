import { Loader2 } from 'lucide-react'
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog'
import { TypographyBody } from '../ui/Typography'
import { CustomAlert } from '../CustomAlert'
import { Button } from '../ui/button'

export const DocGenLoadingTemplateDialog = ({
  error,
  open,
  setOpen,
  onRetry,
}: {
  error: boolean
  open: boolean
  setOpen: (open: boolean) => void
  onRetry: () => void
}) => {
  return (
    <Dialog open={open} onOpenChange={(v) => setOpen(v)}>
      <DialogContent className="w-[30.8125rem] rounded-lg">
        <DialogHeader className={`${error ? '' : '!text-center'} hidden`}>
          <DialogTitle asChild className="hidden">
            <span>Generating template from selected file</span>
          </DialogTitle>
        </DialogHeader>
        <DialogBody className="flex flex-col gap-4 items-center justify-center">
          <div className="flex gap-4 items-start">
            {!error && (
              <Loader2 className="size-6 shrink-0 stroke-[1.5px] animate-spin mt-0.5" />
            )}
            <div className="flex flex-col gap-1">
              <TypographyBody isStrong>
                {error
                  ? ' Failed to generate template'
                  : 'Generating template from selected file'}
              </TypographyBody>

              {!error && (
                <TypographyBody className="text-system-body">
                  This can take up to a minute, depending on the file size
                </TypographyBody>
              )}
            </div>
          </div>

          {error && <CustomAlert variant="error" description="Please retry" />}
        </DialogBody>
        {error && (
          <DialogFooter>
            <Button onClick={onRetry}>Retry</Button>
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  )
}
