'use client'

import * as React from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import { Check, Minus } from 'lucide-react'

import { cn } from '@/shadcn/utils'
import { TypographyBody } from './Typography'

interface CheckboxProps
  extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  partial?: boolean
  label?: string | React.ReactNode
  containerclassname?: string // react warns when using camelCase custom props and sugests keeping them lowercase
}

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ className, ...props }, ref) => (
  <div className={cn('flex gap-2 items-start', props.containerclassname)}>
    <CheckboxPrimitive.Root
      ref={ref}
      className={cn(
        'peer h-5 w-5 shrink-0 rounded-[2.5px] my-0.5 bg-system-secondary border-[1.25px] border-system-border-regular ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed data-[state=checked]:bg-primary data-[state=checked]:border-0 data-[state=checked]:text-primary-foreground',
        className
      )}
      {...props}
    >
      <CheckboxPrimitive.Indicator
        className={cn('flex items-center justify-center text-current')}
      >
        {props.partial ? (
          <Minus className="h-3 w-3 stroke-[3.5px]" />
        ) : (
          <Check className="h-3 w-3 stroke-[3.5px]" />
        )}
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
    {typeof props.label === 'string' && (
      <TypographyBody className="shrink-0 whitespace-pre-wrap overflow-hidden text-ellipsis max-w-[calc(100%-1.25rem)]">
        {props.label}
      </TypographyBody>
    )}

    {typeof props.label !== 'string' && props.label && props.label}
  </div>
))
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
