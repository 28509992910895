import { Cell, Pie, PieChart } from 'recharts'
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '../ui/chart'
import { TypographyBody, TypographyH3, TypographyLabel } from '../ui/Typography'
import { formatChartDate, getChartCitation } from './utils'
import { Citation, ResponseChartData, SourceDocument } from '@/types/types'
import { ChartHeader } from './ChartHeader'
import { useRef } from 'react'
import { ChartTooltip as CustomChartTooltip } from './ChartTooltip'
import { cn } from '@/shadcn/utils'
import { formatDisplayNumber, getLabelColor } from '@/utils/utils'

const CustomizedPieChartLabel = (props: any, outsideLabel: string) => {
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    fill,
    index,
    percent,
    value,
  } = props

  const ODD = index % 2 === 0
  const sin = Math.sin(midAngle * RADIAN)
  const cos = Math.cos(midAngle * RADIAN)

  const startX = cx + outerRadius * cos
  const startY = cy + outerRadius * -sin
  const middleY = cy + (outerRadius + 20 * Math.abs(sin)) * -sin
  let endX = startX + (cos >= 0 ? 1 : -1) * 25
  let textAnchor = cos >= 0 ? 'start' : 'end'

  const mirrorNeeded =
    midAngle > -270 && midAngle < -210 && percent < 0.04 && ODD
  if (mirrorNeeded) {
    endX = startX + outerRadius * -cos * 2 + 100
    textAnchor = 'start'
  }

  const radiusForInside = innerRadius
    ? innerRadius + (outerRadius - innerRadius) / 2
    : outerRadius * 0.7
  const insideX = cx + radiusForInside * cos
  const insideY = cy - radiusForInside * sin

  return (
    <g>
      <path
        d={`M${startX},${startY}L${startX},${middleY}L${endX},${middleY}`}
        fill="none"
        stroke={fill}
        strokeWidth={1}
      />
      <text
        x={endX + (cos >= 0 || mirrorNeeded ? 1 : -1) * 5}
        y={middleY}
        dominantBaseline="central"
        textAnchor={textAnchor}
        className="text-system-body"
        style={{
          fontSize: '14px',
          fontFamily: 'Arial',
        }}
      >
        {outsideLabel}
      </text>

      <text
        x={insideX}
        y={insideY}
        fill={getLabelColor(fill)}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: '14px',
          fontFamily: 'Arial',
          pointerEvents: 'none',
        }}
      >
        {formatDisplayNumber(value)}
      </text>
    </g>
  )
}

export const ResponsePieChart = ({
  id,
  parsedData,
  parsedDataValues,
  parsedChartConfig,
  compact,
  citations,
  documents,
  openedCitation,
  onCitationOpen,
  categories,
  series,
  className,
  isExportEnabled = true,
}: {
  id: string
  parsedData: ResponseChartData
  parsedDataValues: {
    [label: string]: string | number
  }[]
  parsedChartConfig: ChartConfig
  compact?: boolean
  citations: Citation[]
  documents: SourceDocument[]
  openedCitation?: Citation | null
  onCitationOpen?: (citation: Citation | null, scrollTop: number) => void
  categories: string[][]
  series: ApexAxisChartSeries
  className?: string
  isFormatted?: boolean
  isExportEnabled?: boolean
}) => {
  const ref = useRef<HTMLDivElement>(null)

  const total = parsedData.data
    .map((v) => v.value)
    .flat()
    .reduce((a, b) => a + b, 0)

  return (
    <div
      className={cn(
        `flex flex-col gap-4 p-4 ${compact ? 'mt-8' : 'mt-10'} relative group`,
        className
      )}
    >
      <ChartHeader
        chartRef={ref}
        parsedData={parsedData}
        compact={compact}
        citations={citations}
        documents={documents}
        openedCitation={openedCitation}
        onCitationOpen={onCitationOpen}
        isExportEnabled={isExportEnabled}
      />
      {openedCitation?.chart_table_id === id &&
        !openedCitation.isChartHeaderCitation && (
          <CustomChartTooltip
            id={id}
            categories={categories}
            series={series}
            dataPointIndex={openedCitation!.index_x!}
            seriesIndex={openedCitation!.index_y!}
            citations={citations}
            openedCitation={openedCitation}
            withShadow={false}
            onClose={() => onCitationOpen?.(null, 0)}
            showOpenedCitation
            isPieChart
          />
        )}

      <div>
        <div className="flex gap-x-4 flex-wrap">
          {parsedDataValues.map((value, idx) => (
            <div
              key={`pie-value-${idx}`}
              className="gap-x-1 flex items-center jusitfy-center"
            >
              <div
                className="w-3 h-3 rounded-[2px]"
                style={{
                  backgroundColor: value.fill as string,
                }}
              />

              <TypographyBody className="!text-[0.875rem] text-system-body max-w-[12.5rem] text-ellipsis overflow-hidden !leading-[1.25rem]">
                {parsedData.data[idx].label}
              </TypographyBody>
            </div>
          ))}
        </div>
        <div className="flex flex-col z-[0] gap-0 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-25%] text-center">
          <TypographyH3 className="text-system-primary">
            {total.toLocaleString()}
          </TypographyH3>
          <TypographyLabel className="text-system-body max-w-[12.5rem] text-ellipsis overflow-hidden">
            {parsedData.values[0]}
          </TypographyLabel>
        </div>
        <ChartContainer
          ref={ref}
          id={`chart-container-${id}`}
          config={parsedChartConfig}
          className="min-h-[12.5rem] w-full p-4"
        >
          <PieChart accessibilityLayer data={parsedDataValues}>
            {citations.length > 0 && (
              <ChartTooltip
                content={
                  <ChartTooltipContent
                    citations={citations}
                    openedCitation={openedCitation}
                    labelFormatter={(label) => {
                      return formatChartDate(label)
                    }}
                  />
                }
              />
            )}

            <Pie
              data={parsedDataValues}
              dataKey={parsedData.values[0]}
              nameKey={parsedData.label}
              isAnimationActive={false}
              onClick={(data, index, e) => {
                e.stopPropagation()

                const citation = getChartCitation(
                  data[parsedData.values[0]],
                  citations,
                  index
                )

                if (citation) {
                  onCitationOpen?.(citation, 0)
                }
              }}
              label={({ payload, ...props }) => {
                return CustomizedPieChartLabel(props, payload[parsedData.label])
              }}
              labelLine={false}
              startAngle={90}
              endAngle={-270}
              cx="50%"
              cy="50%"
            >
              {parsedDataValues.map((entry, index) => {
                const citation = getChartCitation(
                  entry[parsedData.values[0]],
                  citations,
                  index
                )
                const isSelected =
                  openedCitation &&
                  citation &&
                  JSON.stringify(openedCitation) === JSON.stringify(citation)

                return (
                  <Cell
                    key={`cell-${index}`}
                    className={`hover:cursor-pointer ${isSelected ? 'brightness-[0.7]' : 'hover:brightness-[0.9]'} `}
                  />
                )
              })}
            </Pie>
          </PieChart>
        </ChartContainer>
      </div>
    </div>
  )
}
