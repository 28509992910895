import { Draggable } from '@hello-pangea/dnd'
import { createPortal } from 'react-dom'
import { Button } from '../ui/button'
import { GripVertical, X } from 'lucide-react'
import { TypographyBody } from '../ui/Typography'
import { useRef, useState } from 'react'

export const ListInputDraggable = ({
  values,
  item,
  index,
  disabled,
  onChange,
}: {
  values: string[]
  item: string
  index: number
  disabled: boolean
  onChange: (values: string[]) => void
}) => {
  const [hovered, setHovered] = useState(false)
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null)

  const handleMouseEnter = () => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current)
    }
    debounceTimeout.current = setTimeout(() => {
      setHovered(true)
    }, 200)
  }

  const handleMouseLeave = () => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current)
    }
    setHovered(false)
  }

  return (
    <Draggable draggableId={`draggable-${index}-${item}`} index={index}>
      {(provided, snapshot) =>
        snapshot.isDragging ? (
          createPortal(
            <div
              className={`flex gap-2 px-3 min-h-[2.25rem] ${hovered ? 'hover:pl-0' : ''} py-1 border-b border-system-border-light last:border-none group`}
              key={`list-input-${index}-${item}`}
              ref={provided.innerRef}
              {...provided.draggableProps}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {!disabled && hovered && (
                <Button
                  variant="tertiary"
                  {...provided.dragHandleProps}
                  className="h-fit"
                >
                  <GripVertical className="size-6 shrink-0 stroke-[1.5px]" />
                </Button>
              )}

              <TypographyBody className="text-system-primary">
                {item}
              </TypographyBody>
              {!disabled && hovered && (
                <Button
                  variant="tertiary"
                  className="h-fit"
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    onChange([
                      ...values.slice(0, index),
                      ...values.slice(index + 1),
                    ])
                  }}
                >
                  <X className="size-6 shrink-0 stroke-[1.5px]" />
                </Button>
              )}
            </div>,
            document.body
          )
        ) : (
          <div
            className={`flex gap-2 px-3 min-h-[2.25rem] ${hovered ? 'hover:pl-0' : ''} py-1 border-b border-system-border-light last:border-none group`}
            key={`list-input-${index}-${item}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {!disabled && hovered && (
              <Button
                variant="tertiary"
                {...provided.dragHandleProps}
                className="h-fit"
              >
                <GripVertical className="size-6 shrink-0 stroke-[1.5px]" />
              </Button>
            )}
            <TypographyBody className="text-system-primary w-full">
              {item}
            </TypographyBody>
            {!disabled && hovered && (
              <Button
                variant="tertiary"
                className="h-fit"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  onChange([
                    ...values.slice(0, index),
                    ...values.slice(index + 1),
                  ])
                }}
              >
                <X className="size-6 shrink-0 stroke-[1.5px]" />
              </Button>
            )}
          </div>
        )
      }
    </Draggable>
  )
}
