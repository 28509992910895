import { ApexChartsResponseChartProps } from '@/types/types'
import Chart from 'react-apexcharts'
import ApexCharts, { ApexOptions } from 'apexcharts'
import { useEffect, useRef, useState } from 'react'
import { ChartHeader } from './ChartHeader'
import { ChartTooltip } from './ChartTooltip'
import { formatChartDate, getChartCitation } from './utils'
import { Color } from '@/constants'

export const ResponseTimeseriesChart = ({
  id,
  parsedData,
  series,
  options,
  compact,
  citations,
  documents,
  openedCitation,
  onCitationOpen,
  categories,
  isExportEnabled = true,
}: ApexChartsResponseChartProps) => {
  const [isPrimaryChartRendered, setIsPrimaryChartRendered] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const lineOptions: ApexOptions = {
    ...options,
    stroke: {
      width: 2,
      curve: 'straight',
    },

    chart: {
      ...options.chart,
      id: parsedData.title,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      type: 'line',
      events: {
        ...options.chart?.events,
        dataPointMouseEnter: undefined,
        mounted: (chart) => {
          if (openedCitation) {
            chart.toggleDataPointSelection(0, 0)
          }
          setIsPrimaryChartRendered(true)
        },
        mouseMove: function (_1, chartContext, _3) {
          chartContext.el.style.cursor = 'pointer'
        },
        mouseLeave: function (_1, chartContext, _3) {
          chartContext.el.style.cursor = 'default'
        },
        markerClick: (_, chartContext, config) => {
          const citation = getChartCitation(
            chartContext.w.globals.series[config.seriesIndex][
              config.dataPointIndex
            ],
            citations,
            config.dataPointIndex,
            config.seriesIndex
          )

          if (citation) {
            onCitationOpen?.(citation, 0)
          }
        },
      },
    },

    tooltip: {
      ...options.tooltip,
      shared: true,
    },
    xaxis: {
      ...options.xaxis,
      labels: {
        ...options.xaxis?.labels,
        offsetY: 0,
        trim: false,
      },
      tickAmount: 4,
    },
  }

  const brushChartOptions: ApexOptions = {
    ...options,
    chart: {
      id: `${parsedData.title}-brushchart`,
      type: 'area',
      brush: {
        target: parsedData.title,
        enabled: true,
      },
      selection: {
        enabled: true,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    yaxis: {
      show: false,
    },
    xaxis: {
      ...options.xaxis,
      labels: {
        formatter: (val: string) => {
          return formatChartDate(val)
        },
        style: {
          fontSize: '14px',
          fontFamily: 'Inter, sans-serif',
          fontWeight: 400,
          colors: Color['system-body'],
        },
        trim: false,
        show: true,
        hideOverlappingLabels: false,
        rotate: -45,
        offsetX: 20,
        offsetY: 8,
      },
      tickAmount: 5,
    },
  }

  useEffect(() => {
    //https://github.com/apexcharts/apexcharts.js/issues/2315
    //@ts-expect-error
    window.ApexCharts = ApexCharts
  }, [isPrimaryChartRendered])

  return (
    <div className={`p-4 ${compact ? 'mt-8' : 'mt-10'}`}>
      <div
        className={`flex flex-col gap-4 relative group`}
        id={`chart-container-${id}`}
      >
        <ChartHeader
          chartRef={ref}
          parsedData={parsedData}
          compact={compact}
          citations={citations}
          documents={documents}
          openedCitation={openedCitation}
          onCitationOpen={onCitationOpen}
          isExportEnabled={isExportEnabled}
        />

        {openedCitation?.chart_table_id === id &&
          !openedCitation.isChartHeaderCitation && (
            <ChartTooltip
              id={id}
              categories={categories}
              series={series}
              dataPointIndex={openedCitation!.index_x!}
              seriesIndex={openedCitation!.index_y!}
              citations={citations}
              openedCitation={openedCitation}
              withShadow={false}
              onClose={() => onCitationOpen?.(null, 0)}
              showOpenedCitation
            />
          )}
        <div
          ref={ref}
          id={`chart-${id}`}
          className="[&_.apexcharts-tooltip]:!border-none [&_.apexcharts-tooltip]:!rounded-sm [&_.apexcharts-tooltip]:!bg-system-secondary"
        >
          <Chart options={lineOptions} series={series} type="line" />
          {isPrimaryChartRendered && (
            <Chart
              height={200}
              options={brushChartOptions}
              series={series}
              type="line"
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ResponseTimeseriesChart
