import { Button } from '../ui/button'
import { Switch } from '../ui/switch'
import {
  ClipboardEvent,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  AssistantAskMode,
  DossierDetail,
  QueryStatus,
  SourceType,
  StreamStatus,
} from '../../types/types'
import { SourceSelector } from './SourceSelector'
import { TypographyBody } from '../ui/Typography'
import LightbulbHead from '@/assets/LightbulbHead'
import Close from '@/assets/Close'
import { useLocation } from 'react-router-dom'
import { CustomTooltip } from '../CustomTooltip'
import ArrowRight from '@/assets/ArrowRight'
import { UserContext } from '@/contexts/UserContext'
import texture from '../../assets/bg-texture.png'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, type RootState } from '../../store/store'
import { StopCircle } from 'lucide-react'
import { stopConversation } from './assistantThunk'
import {
  convertToBullets,
  embedAdditionalContexts,
  isUploadFileEnabled,
  sanitizeInput,
} from '@/utils/utils'
import AdditionalContextCard from './AdditionalContextCard'
import { MAX_CONTEXT_WINDOW } from '@/constants'
import { ToastContext } from '@/contexts/ToastContext'
import Divider from '../ui/divider'
import FileDropzone from './FileDropzone'
import { Toast } from '../ui/toast'

const MIN_CHAR = 1200

export type ChatBoxProps = {
  expertModeAvailable: boolean
  handleSubmit: (args: { message: string; mode: AssistantAskMode }) => void
  initialMessage?: string
  additionalControls?: boolean
  isConversation: boolean
  dossierDetail?: DossierDetail
  conversationId?: string
  isFinished?: boolean
  canStop: boolean
  sourceType: SourceType
  enableDragAndDrop?: boolean
}

export function ChatBox({
  expertModeAvailable,
  handleSubmit,
  initialMessage,
  additionalControls,
  isConversation,
  dossierDetail,
  conversationId,
  isFinished,
  canStop,
  sourceType,
  enableDragAndDrop = false,
}: ChatBoxProps) {
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const [message, setMessage] = useState(initialMessage || '')
  const [additionalContexts, setAdditionalContexts] = useState<string[]>([])

  const [isStopping, setIsStopping] = useState(false)

  const dispatch = useDispatch<AppDispatch>()
  const { showToast } = useContext(ToastContext)

  const assistantStore = useSelector((state: RootState) => state.assistant)
  const { uploadQueue, temporaryDocuments } = useSelector(
    (state: RootState) => state.impromptuUpload
  )

  const { settings, updateSettings } = useContext(UserContext)

  const sources = settings.assistant.sources[sourceType]

  const selectedFiles = sources.files

  const isSourceSelected =
    sources.internalSearch ||
    sources.webSearch ||
    sources.outlookSearch ||
    sources.teamsSearch ||
    sources.financialDataSearch ||
    sources.companiesHouseSearch ||
    sources.companiesHouseFiles.length > 0 ||
    sources.filingsSearch ||
    sources.transcriptsSearch ||
    (isUploadFileEnabled(sourceType) && sources.uploadedFiles) ||
    sources.files.length > 0

  const isMessageEmpty = message.trim().length === 0

  const preprocessMessage = (
    message: string,
    additionalContexts: string[]
  ): string => {
    let totalCharAmount = 0
    const sanitizedAdditionalContexts: string[] = []

    let isOverflowingContextWindow = totalCharAmount >= MAX_CONTEXT_WINDOW

    additionalContexts.forEach((context) => {
      if (isOverflowingContextWindow) return

      const truncateEnd =
        totalCharAmount + context.length >= MAX_CONTEXT_WINDOW
          ? Math.abs(context.length - (totalCharAmount - MAX_CONTEXT_WINDOW))
          : undefined

      const sanitized = sanitizeInput(context, truncateEnd)
      if (sanitized.length > 0) {
        sanitizedAdditionalContexts.push(sanitized)
      }

      totalCharAmount += sanitized.length
      isOverflowingContextWindow = totalCharAmount >= MAX_CONTEXT_WINDOW
    })

    const sanitizedMessage = sanitizeInput(message)

    return embedAdditionalContexts(
      sanitizedMessage,
      sanitizedAdditionalContexts
    )
  }

  const sendMessage = useCallback(() => {
    if (
      !isSourceSelected ||
      (isMessageEmpty && !(additionalContexts.length > 0)) ||
      !!uploadQueue.length
    )
      return

    const sanitizedMessage = preprocessMessage(message, additionalContexts)

    handleSubmit({ message: sanitizedMessage, mode: settings.assistant.mode })

    setMessage('')
    setAdditionalContexts([])
  }, [message, additionalContexts, isSourceSelected, settings.assistant.mode])

  const toggleExpertMode = () => {
    updateSettings({
      settings: {
        ...settings,
        assistant: {
          ...settings.assistant,
          mode: settings.assistant.mode === 'simple' ? 'expert' : 'simple',
          sources: {
            ...settings.assistant.sources,
            [sourceType]:
              settings.assistant.mode === 'simple'
                ? {
                    ...settings.assistant.sources[sourceType],
                    internalSearch: true,
                    webSearch: true,
                    focusedAnalysis:
                      settings.assistant.mode === 'simple'
                        ? false
                        : settings.assistant.sources[sourceType]
                            .focusedAnalysis,
                  }
                : { ...settings.assistant.sources[sourceType] },
          },
        },
      },
    })
  }

  const stopMessage = async () => {
    if (!conversationId) return
    setIsStopping(true)
    await dispatch(stopConversation(conversationId))
  }

  const handlePasteAdditionalContext = (e: ClipboardEvent) => {
    const clipboardText = e.clipboardData?.getData('text')?.trim()

    if (!clipboardText) return

    let totalChar: number = clipboardText.length

    additionalContexts.forEach((v: string) => (totalChar += v.length))

    if (totalChar > MAX_CONTEXT_WINDOW) {
      e.preventDefault()
      showToast(
        {
          toastId: 'additional-context',
          variant: 'warning',
          description: `The message was too long and will be truncated, please submit something shorter.`,
          dismissable: true,
        },
        1_000 * 10,
        true
      )
    }

    if (clipboardText.length > MIN_CHAR) {
      e.preventDefault()
      setAdditionalContexts((prev) => [...prev, clipboardText])
    }
  }

  const handleDeleteAdditionalContext = (index: number) => {
    setAdditionalContexts((prev) => prev.filter((_, idx) => idx !== index))
  }

  const containerClass = `${isConversation ? `pb-6 bg-system-surface flex` : ''} mx-auto w-full`

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '0px'
      const scrollHeight = textareaRef.current.scrollHeight
      textareaRef.current.style.height = scrollHeight + 'px'
    }
  }, [message])

  useEffect(() => {
    if (
      assistantStore.stopConversationStatus === QueryStatus.SUCCEEDED &&
      isFinished
    ) {
      setIsStopping(false)
    }
  }, [assistantStore.stopConversationStatus, isFinished])

  return (
    <>
      <div
        className={containerClass}
        style={{ backgroundImage: `url(${texture})` }}
      >
        <div className="chatbox-container flex flex-col gap-3 p-3 pl-4 w-full flex-grow items-center bg-system-secondary border border-system-border-regular rounded-lg">
          <Fragment>
            {enableDragAndDrop && (
              <FileDropzone sourceType={sourceType} isGlobal />
            )}

            {enableDragAndDrop &&
              !!(
                uploadQueue.length || Object.keys(temporaryDocuments).length
              ) &&
              !!additionalContexts.length && <Divider />}

            {!!additionalContexts.length && (
              <div
                className={`w-full h-fit grid grid-cols-3 justify-start gap-3 pb-2`}
              >
                {additionalContexts.map((ac, idx) => (
                  <AdditionalContextCard
                    key={`additional_context_${idx}`}
                    text={ac}
                    onDelete={() => handleDeleteAdditionalContext(idx)}
                  />
                ))}
              </div>
            )}
          </Fragment>

          <section className="flex flex-1 max-h-[20rem] w-full">
            <textarea
              onPaste={(e: ClipboardEvent) => {
                handlePasteAdditionalContext(e)
              }}
              className="max-h-[20rem] w-full overflow-auto shadow-none outline-none h-fit font-body placeholder:!text-system-placeholder !resize-none"
              ref={textareaRef}
              value={convertToBullets(message)}
              onChange={(e) => {
                setMessage(e.target.value)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && e.shiftKey !== true) {
                  e.preventDefault()

                  // todo: handle processing states
                  if (assistantStore.state.streamStatus === 'Ready') {
                    sendMessage()
                  }
                }
              }}
              placeholder="What do you need to know?"
            />
          </section>

          <section className="flex items-center gap-4 mobile:gap-6 w-full">
            {additionalControls && (
              <SourceSelector
                dossierDetail={dossierDetail}
                sourceType={sourceType}
              />
            )}
            <div className="flex gap-4 ml-auto">
              {expertModeAvailable && selectedFiles.length === 0 && (
                <ExpertModeSwitch
                  checked={settings.assistant.mode === 'expert'}
                  handleCheckedChange={() => toggleExpertMode()}
                />
              )}

              {assistantStore.state.streamStatus === StreamStatus.Ready ||
              isStopping ||
              !canStop ? (
                <Button
                  onClick={sendMessage}
                  disabled={
                    !isSourceSelected ||
                    (isStopping && canStop) ||
                    assistantStore.state.streamStatus !== StreamStatus.Ready ||
                    !!uploadQueue.length
                  }
                  className="w-[5.25rem]"
                >
                  <div className="flex gap-2 items-center">
                    Ask
                    <ArrowRight className="h-6 w-6 shrink-0 stroke-system-secondary" />
                  </div>
                </Button>
              ) : (
                <Button onClick={stopMessage} className="w-[5.25rem]">
                  <div className="flex gap-2 items-center">
                    <StopCircle className="size-6 shrink-0 stroke-interactive" />
                    Stop
                  </div>
                </Button>
              )}
            </div>
          </section>
        </div>
      </div>
      <div className="fixed top-0 right-0 w-full">
        <Toast id="additional-context" />
      </div>
    </>
  )
}

export function ExpertModeSwitch({
  handleCheckedChange,
  checked,
}: {
  handleCheckedChange: () => void
  checked: boolean
}) {
  const [showTooltip, setShowTooltip] = useState(false)
  const location = useLocation()

  useEffect(() => {
    const hasShown = localStorage.getItem('expert_mode_tooltip_shown')

    if (hasShown !== 'true') {
      setShowTooltip(true)
    }
  }, [])

  const updateHasShown = () => {
    localStorage.setItem('expert_mode_tooltip_shown', 'true')
  }

  return (
    <>
      <div
        data-tooltip-id="expert-mode-tooltip"
        data-tooltip-place="top"
        className="rounded-md hover:bg-[#F0F4FA] p-2"
      >
        <div className="flex items-center space-x-2">
          <Switch
            id="airplane-mode"
            onCheckedChange={handleCheckedChange}
            checked={checked}
            label="Expert mode"
            containerclassname="text-system-body"
            labelclassname="!whitespace-nowrap"
          />
        </div>
      </div>
      {location.pathname === '/assistant/ask' && (
        <CustomTooltip
          id="expert-mode-tooltip"
          clickable={true}
          isOpen={showTooltip}
          setIsOpen={(v) => setShowTooltip(v)}
          afterShow={() => updateHasShown()}
          children={
            <div className="max-w-[25rem] flex flex-col gap-2 px-1">
              <div className="flex flex-col gap-1">
                <div className="flex">
                  <LightbulbHead className="h-10 w-10 shrink-0" />
                  <div
                    className="ml-auto cursor-pointer"
                    onClick={() => setShowTooltip(false)}
                  >
                    <Close className="w-6 h-6 shrink-0" />
                  </div>
                </div>
                <TypographyBody isStrong={true} className="text-system-primary">
                  Try out the new expert mode
                </TypographyBody>
              </div>
              <TypographyBody className="!text-system-body">
                Activating Expert mode will provide you with more accurate
                answers, though you might have to wait a bit longer
              </TypographyBody>
            </div>
          }
        />
      )}
    </>
  )
}
