import { DocgenSession, DocgenTemplate } from '@/types/types'
import {
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog'
import { getOrganizationName } from '@/utils/utils'
import { useContext, useState } from 'react'
import { UserContext } from '@/contexts/UserContext'
import { Button } from '../ui/button'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store/store'
import { updateSession } from './docGenThunk'
import { isFulfilled } from '@reduxjs/toolkit'
import { Loader2 } from 'lucide-react'
import { CustomAlert } from '../CustomAlert'

export const DocGenVisibilityDialog = ({
  open,
  setOpen,
  session,
  template,
  onVisibilityUpdate,
}: {
  open: boolean
  setOpen: (open: boolean) => void
  session: DocgenSession | null
  template: DocgenTemplate | null
  onVisibilityUpdate: () => void
}) => {
  const { user } = useContext(UserContext)

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const dispatch = useDispatch<AppDispatch>()
  const isPrivate = session?.visibility === 'private'

  const handleVisibilityUpdate = async () => {
    if (!session) return
    setLoading(true)
    const result = await dispatch(
      updateSession({
        id: session.id,
        visibility: isPrivate ? 'organization' : 'private',
      })
    )
    setLoading(false)
    if (isFulfilled(updateSession)(result)) {
      setOpen(false)
      setError(false)
      onVisibilityUpdate()
      dispatch(updateSession(result.payload))
    } else {
      setError(true)
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {`Change ${session?.topic || ''} ${template?.title || ''} visibility to ${isPrivate ? getOrganizationName(user?.app_metadata?.organization_id) : 'Private'}`}
          </DialogTitle>
        </DialogHeader>
        {error && (
          <DialogBody>
            <CustomAlert
              variant="error"
              description="Failed to update visibility. Please try again."
            />
          </DialogBody>
        )}

        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>

          <Button onClick={handleVisibilityUpdate}>
            <div className="flex gap-2 items-center">
              Confirm and change
              {loading && <Loader2 className="size-6 shrink-0 animate-spin" />}
            </div>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
