import InfoCircle from '@/assets/InfoCircle'
import { Button } from '../ui/button'
import { Card, CardContent } from '../ui/card'
import { TypographyLabel } from '../ui/Typography'
import { Playbook } from '@/types/types'
import { getPlaybookCardIcon } from '@/utils/components'
import { CustomTooltip } from '../CustomTooltip'

export const SuggestionCard = ({
  playbook,
  handleClick,
}: {
  playbook: Playbook
  handleClick?: (playbook: Playbook) => void
}) => {
  return (
    <Card
      className={`cursor-pointer !rounded-lg`}
      onClick={() => handleClick?.(playbook)}
    >
      <CardContent className="flex sm:flex-col items-center gap-2 p-3 h-[3.5rem] w-full">
        <div className="flex gap-2 h-full w-full items-center">
          {getPlaybookCardIcon(playbook.items.at(0)?.category || '')}

          <TypographyLabel className="text-system-body w-full line-clamp-2">
            {playbook.title}
          </TypographyLabel>

          <Button
            variant="tertiary"
            data-tooltip-id={`playbook-card-${playbook?.items.at(0)?.id || ''}-tooltip`}
            data-tooltip-place="top"
            className="size-4"
          >
            <InfoCircle className="size-4 shrink-0 stroke-[1.5px] !stroke-system-placeholder" />
          </Button>

          <CustomTooltip
            id={`playbook-card-${playbook?.items.at(0)?.id || ''}-tooltip`}
            className="!py-1 !px-3 !rounded-sm"
            largeArrow={false}
          >
            <TypographyLabel className="text-system-body whitespace-pre-wrap max-w-[20rem] text-center">
              {playbook?.items.at(0)?.tooltip || ''}
            </TypographyLabel>
          </CustomTooltip>
        </div>
      </CardContent>
    </Card>
  )
}
