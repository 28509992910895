import { Fragment } from "react/jsx-runtime"
import { Button, ButtonProps } from "./ui/button"

interface ActionMenuProps {
    buttons?: ButtonProps[]
    className?: string
}

export const ActionMenu = ({ buttons, className }: ActionMenuProps) => {
    const style = `${className} flex gap-2 m-0 bg-system-secondary rounded-md border border-system-border-light w-fit py-1 px-4 shadow-soft`
    return (
        <div className={style}>
            {buttons?.map((button, index) => (
                <Fragment key={`action-menu-button-${index}`}>
                    <Button {...button} >

                    </Button>

                    {index !== buttons.length - 1 && (
                        <div className="w-px h-4 bg-system-border-light my-auto"></div>
                    )}
                </Fragment>
            ))}
        </div>
    )
}