import { ChevronUp, Loader2, LockKeyhole, UsersRound, X } from 'lucide-react'
import { TypographyBody, TypographyLabel } from '../ui/Typography'
import ChevronDown from '@/assets/ChevronDown'
import { useState } from 'react'
import { Button } from '../ui/button'
import { getFileIcon } from '@/utils/components'
import { CustomTooltip } from '../CustomTooltip'
import { ResponseDocument } from '@/types/types'
import CheckmarkCircleFill from '@/assets/CheckmarkCircleFill'
import CloseCircleFill from '@/assets/CloseCircleFill'
import { plural } from '@/utils/utils'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store/store'
import { deleteDocument } from '../Document/documentThunk'
import { actions as documentActions } from '../Document/documentSlice'

export const ProcessingFiles = ({
  files,
  onClose,
}: {
  files: ResponseDocument[]
  onClose: () => void
}) => {
  const [isOpen, setOpen] = useState(false)

  const dispatch = useDispatch<AppDispatch>()

  const processedFiles = files.filter(
    (v) =>
      v.document_is_ready_to_use || v.document_processing_status === 'failed'
  )
  const allProcessed = processedFiles.length === files.length
  const failedFiles = files.filter(
    (v) => v.document_processing_status === 'failed'
  )

  const handleRemove = (file: ResponseDocument) => {
    dispatch(deleteDocument(file))
    dispatch(documentActions.deleteFile(file))
  }

  const handleClose = () => {
    failedFiles.forEach((file) => {
      dispatch(documentActions.deleteFile(file))
    })

    onClose()
  }

  return (
    <div
      className={`fixed right-6 top-[4.75rem] max-w-[calc(100%-3.5rem)] w-full mobile:w-[29.125rem] px-6 py-4 bg-system-surface border border-system-border-regular rounded-[12px] ${isOpen ? 'shadow-outline' : ''}`}
    >
      <div className="flex flex-col gap-6">
        <div className="flex gap-8 items-center w-full">
          <div className="flex flex-col gap-1">
            <div className="flex gap-2 items-center">
              {!allProcessed && (
                <Loader2 className="size-4 shrink-0 animate-spin" />
              )}

              <TypographyBody isStrong={true} className="text-system-primary">
                {`${allProcessed ? 'Upload complete' : `Uploading ${plural('file', files.length)}`} ${failedFiles.length > 0 && allProcessed ? `(${failedFiles.length} failed)` : `(${processedFiles.length}/${files.length})`}`}
              </TypographyBody>
            </div>
          </div>

          <div className="flex gap-4 ml-auto">
            {!isOpen && (
              <Button variant="tertiary" onClick={() => setOpen(true)}>
                <ChevronDown />
              </Button>
            )}

            {isOpen && (
              <Button variant="tertiary" onClick={() => setOpen(false)}>
                <ChevronUp className="size-6 shrink-0 stroke-[1.5px] ml-auto" />
              </Button>
            )}

            {allProcessed && (
              <Button variant="tertiary" onClick={() => handleClose()}>
                <X className="size-6 shrink-0 stroke-[1.5px]" />
              </Button>
            )}
          </div>
        </div>

        {isOpen && (
          <div className="flex flex-col gap-2 max-h-[25rem] overflow-y-auto px-6 -mx-6">
            {files.map((file) => (
              <ProcessingFileRow
                key={`processing-file-row=${file.document_id}`}
                file={file}
                onRemove={handleRemove}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export const ProcessingFileRow = ({
  file,
  onRemove,
}: {
  file: ResponseDocument
  onRemove: (file: ResponseDocument) => void
}) => {
  const isLoading =
    !file.document_is_ready_to_use &&
    file.document_processing_status !== 'failed'
  const success = file.document_is_ready_to_use
  const failed =
    !file.document_is_ready_to_use &&
    file.document_processing_status === 'failed'

  return (
    <div className="p-3 pr-4 flex gap-2 rounded-sm border border-system-border-regular items-center">
      {getFileIcon(
        file.document_type_friendly,
        `!size-6 ${isLoading && 'opacity-40'}`
      )}

      <TypographyLabel
        className={`text-system-body ${isLoading && 'opacity-50'} ${failed && '!text-messaging-negative-text'} whitespace-nowrap text-ellipsis overflow-hidden`}
      >
        {file.document_name}
      </TypographyLabel>

      <div className={`ml-auto pl-4 flex gap-6 items-center`}>
        {failed && (
          <Button
            variant="inline"
            className="text-link"
            onClick={() => onRemove(file)}
          >
            Remove
          </Button>
        )}

        {file.document_visibility === 'private' ? (
          <LockKeyhole
            data-tooltip-id={`processing-file-private-${file.document_id}`}
            className="size-4 shrink-0 stroke-[1.5px] stroke-system-body"
          />
        ) : (
          <UsersRound
            data-tooltip-id={`processing-file-shared-${file.document_id}`}
            className="size-4 shrink-0 stroke-[1.5px] stroke-system-body"
          />
        )}

        {isLoading && <Loader2 className="size-5 shrink-0 animate-spin" />}

        {!isLoading && success && !failed && <CheckmarkCircleFill />}

        {!isLoading && !success && failed && <CloseCircleFill />}
      </div>

      <CustomTooltip
        id={`processing-file-private-${file.document_id}`}
        className="!py-1 !px-3 !rounded-sm"
        largeArrow={false}
      >
        <TypographyLabel className="text-system-primary">
          Private
        </TypographyLabel>
      </CustomTooltip>

      <CustomTooltip
        id={`processing-file-shared-${file.document_id}`}
        className="!py-1 !px-3 !rounded-sm"
        largeArrow={false}
      >
        <TypographyLabel className="text-system-primary">
          Shared
        </TypographyLabel>
      </CustomTooltip>

      <CustomTooltip
        id={`processing-file-shared-${file.document_id}`}
        className="!py-1 !px-3 !rounded-sm"
        largeArrow={false}
      >
        <TypographyLabel className="text-system-primary">
          Shared
        </TypographyLabel>
      </CustomTooltip>
    </div>
  )
}

export const ProcessingFileRowStandalone = ({
  documentMetadata,
  onRemove,
  onDeselect,
  isLoading,
  isSuccess,
  error,
}: {
  documentMetadata: {
    documentName: string
    documentType: string
    documentId: string
    documentVisibility?: string
  }
  onRemove?: (documentId: string) => void
  onDeselect?: () => void
  isLoading?: boolean
  isSuccess?: boolean
  error?: string
}) => {
  return (
    <div className="p-2 flex gap-2 rounded-sm border border-system-border-regular items-center">
      {documentMetadata.documentType
        ? getFileIcon(
            documentMetadata.documentType,
            `!size-6 ${isLoading && 'opacity-40'}`
          )
        : null}

      <div
        className="w-fit flex overflow-hidden cursor-pointer"
        data-tooltip-id={`processing-file-document-name-${documentMetadata.documentId}`}
      >
        <TypographyLabel
          className={`text-system-body ${isLoading && 'opacity-50'} ${error && '!text-messaging-negative-text'} whitespace-nowrap text-ellipsis overflow-hidden`}
        >
          {documentMetadata.documentName}
        </TypographyLabel>
      </div>

      <div className={`ml-auto pl-4 flex gap-2 items-center`}>
        {error && (
          <Button
            variant="inline"
            className="text-link"
            onClick={() => onRemove?.(documentMetadata.documentId)}
          >
            Remove
          </Button>
        )}

        {documentMetadata.documentVisibility === 'private' ? (
          <LockKeyhole
            data-tooltip-id={`processing-file-private-${documentMetadata.documentId}`}
            className="size-4 shrink-0 stroke-[1.5px] stroke-system-body"
          />
        ) : (
          <UsersRound
            data-tooltip-id={`processing-file-shared-${documentMetadata.documentId}`}
            className="size-4 shrink-0 stroke-[1.5px] stroke-system-body"
          />
        )}

        {isLoading && <Loader2 className="size-5 shrink-0 animate-spin" />}

        {!isLoading && isSuccess && !error && (
          <Button
            variant="tertiary"
            onClick={() => {
              onDeselect?.()
            }}
          >
            <X className="size-6 shrink-0 stroke-[1.5px]" />
          </Button>
        )}

        {!isLoading && !isSuccess && error && (
          <CloseCircleFill className="size-5" />
        )}
      </div>

      <CustomTooltip
        id={`processing-file-private-${documentMetadata.documentId}`}
        className="!py-1 !px-3 !rounded-sm"
        largeArrow={false}
      >
        <TypographyLabel className="text-system-primary">
          Private
        </TypographyLabel>
      </CustomTooltip>

      <CustomTooltip
        id={`processing-file-document-name-${documentMetadata.documentId}`}
        className="!py-1 !px-3 !rounded-sm"
        largeArrow={false}
      >
        <TypographyLabel className="text-system-primary">
          {documentMetadata.documentName}
        </TypographyLabel>
      </CustomTooltip>
    </div>
  )
}
