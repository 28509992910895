import { useDispatch } from 'react-redux'
import { Button } from '../ui/button'
import {
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import { DocgenTemplate, TemplateCategory } from '@/types/types'
import { useState } from 'react'
import { AppDispatch } from '@/store/store'
import { updateTemplate } from './docGenThunk'
import { isFulfilled } from '@reduxjs/toolkit'
import { replaceTemplate } from './docGenSlice'
import { Loader2 } from 'lucide-react'

export const DocGenMoveTemplateDialog = ({
  open,
  setOpen,
  template,
  categories,
}: {
  open: boolean
  setOpen: (open: boolean) => void
  template: DocgenTemplate
  categories: TemplateCategory[]
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(
    categories[0]?.id || null
  )
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const handleSubmit = async () => {
    setLoading(true)
    const result = await dispatch(
      updateTemplate({
        ...template,
        category_id: selectedCategory || null,
      })
    )
    setLoading(false)
    if (isFulfilled(updateTemplate)(result)) {
      dispatch(replaceTemplate(result.payload))

      setOpen(false)
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="!max-w-[26.25rem]">
        <DialogHeader>
          <DialogTitle>Move to category</DialogTitle>
        </DialogHeader>
        <DialogBody>
          <Select
            onValueChange={(v) => {
              if (v) {
                setSelectedCategory(v)
              } else {
                setSelectedCategory(null)
              }
            }}
            value={selectedCategory || undefined}
          >
            <SelectTrigger className="max-w-[calc(100%-0.5rem)] [@media(min-width:670px)]:max-w-[22.5rem] m-1">
              <SelectValue />
            </SelectTrigger>
            <SelectContent>
              {categories.map((category, index) => {
                return (
                  <SelectItem
                    key={`category-${index}`}
                    value={category.id || `category-${index}`}
                  >
                    {category.title}
                  </SelectItem>
                )
              })}
            </SelectContent>
          </Select>
        </DialogBody>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button onClick={handleSubmit}>
            <div className="flex gap-2 items-center">
              {loading && (
                <Loader2 className="size-6 shrink-0 stroke-interactive animate-spin" />
              )}
              Confirm and move
            </div>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
