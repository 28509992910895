import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table'
import { ReactNode, useCallback, useMemo, useRef, useState } from 'react'
import { formatDisplayNumber, getDisplayNumberMaxDecimalPlaces, reformatTableDate } from '@/utils/utils'
import { ResponseMarkdown } from '../Assistant/ResponseMarkdown'
import { TypographyP } from '../ui/Typography'
import { FinancialDataTableProps } from '@/types/types'
import { calculateCellWidth } from '@/utils/table'

export const FinancialDataTable = ({
  id,
  data,
  highlightedText,
}: FinancialDataTableProps) => {
  const tableRef = useRef<HTMLTableElement>(null)
  const headerRefs = useRef<(HTMLTableCellElement | null)[]>([])
  const [isMeasured, setIsMeasured] = useState(false)

  const parsedData: {
    headers: string[]
    [key: string]: any[]
  } = JSON.parse(data)[0]

  const { headers } = parsedData

  const rowCount = parsedData[parsedData.headers[0]].length

  const columnWidths = useMemo(() => {
    return headers.map((header, index) => {
      const texts = [header, ...parsedData[header]]
      return calculateCellWidth({
        texts: texts.flat(),
        headerCell: headerRefs.current[index],
      })
    })
  }, [headers, parsedData, isMeasured])

  const setHeaderRef = useCallback(
    (index: number) => (el: HTMLTableCellElement | null) => {
      if (el == null) return
      headerRefs.current[index] = el
      if (!isMeasured) setIsMeasured(true)
    },
    [isMeasured]
  )

  return (
    <>
      <div
        className={`flex flex-col items-start w-full min-w-full overflow-x-auto border border-system-border-regular rounded-lg`}
      >
        <Table
          ref={tableRef}
          className="rounded-lg [&>div]:rounded-lg"
          scrollable={true}
        >
          <TableHeader>
            <TableRow>
              {headers.map((header, index) => {
                const columnWidth = columnWidths[index]
                return (
                  <TableHead
                    key={`table-${id}-header-${index}`}
                    ref={setHeaderRef(index)}
                    className={`!bg-system-primary !text-system-secondary  ${index === 0 ? 'rounded-tl-lg' : index === headers.length - 1 ? 'rounded-tr-lg' : ''} px-6 py-2 text-system-primary [&_p]:!font-body font-body border-b border-system-border-regular ${index === 0 ? 'text-left' : 'text-right'}`}
                    style={{
                      minWidth: columnWidth,
                      width: columnWidth,
                      maxWidth: columnWidth,
                    }}
                  >
                    {reformatTableDate(header)}
                  </TableHead>
                )
              })}
            </TableRow>
          </TableHeader>

          <TableBody>
            {[...Array(rowCount).keys()].map((index) => {
              return (
                <TableRow key={`table-${id}-row-${index}`}>
                  {parsedData.headers.map((header, headerIndex) => {
                    const text: string = parsedData[header][index]
                    const columnWidth = columnWidths[headerIndex]

                    const isNumber = !isNaN(parseFloat(text))
                    const formattedText = isNumber
                      ? formatDisplayNumber(text, getDisplayNumberMaxDecimalPlaces(parsedData[parsedData.headers[0]][index]))
                      : reformatTableDate(text)

                    let embeddedText =
                      headerIndex === 0
                        ? reformatTableDate(text)
                            .replaceAll(/([a-z])([A-Z])/g, '$1 $2')
                            .replace(/^./, (str) => str.toUpperCase())
                        : formattedText

                    if (highlightedText) {
                      embeddedText = embeddedText.replaceAll(
                        highlightedText,
                        `<Highlight>${highlightedText}</Highlight>`
                      )
                    }

                    return (
                      <TableCell
                        key={`table-${id}-cell-${headerIndex}-${index}`}
                        id={`table-${id}-cell-${headerIndex}-${index}`}
                        className={`${headerIndex === 0 ? '!bg-system-surface-light ' : '!bg-system-secondary'} px-6 py-2 ${index === rowCount - 1 ? 'rounded-b-lg' : ''} text-system-primary [&_p]:!font-body !font-body shrink-0 ${headerIndex === 0 ? 'text-left' : 'text-right'}`}
                        style={{
                          minWidth: columnWidth,
                          width: columnWidth,
                          maxWidth: columnWidth,
                        }}
                      >
                        <ResponseMarkdown
                          text={embeddedText}
                          documents={[]}
                          citations={[]}
                          openedCitation={null}
                          isTable={false}
                          overrides={{
                            a: {
                              component: ({
                                href,
                                children,
                              }: {
                                href: string
                                children: ReactNode
                              }) => {
                                return (
                                  <a
                                    href={href}
                                    className={`cursor-pointer underline !text-system-body break-words`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {children}
                                  </a>
                                )
                              },
                            },
                            p: {
                              component: ({
                                children,
                              }: {
                                children: ReactNode
                              }) => (
                                <>
                                  <TypographyP
                                    className={`mb-2 mt-6 first:!mt-0 font-body break-words`}
                                  >
                                    {children}
                                  </TypographyP>
                                </>
                              ),
                            },
                            Highlight: {
                              component: ({
                                children,
                              }: {
                                children: ReactNode
                              }) => (
                                <span className="bg-[linear-gradient(0deg,_var(--citation-highlight),_var(--citation-highlight))] bg-no-repeat bg-[length:100%_90%]">
                                  {children}
                                </span>
                              ),
                            },
                          }}
                        />
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </div>
    </>
  )
}
