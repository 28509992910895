import { ReactNode } from "react";
import { Tooltip } from "react-tooltip";
import './CustomTooltip.css'

interface TooltipProps {
    id?: string
    anchorSelect?: string
    classNameArrow?: string
    className?: string
    largeArrow?: boolean
    clickable?: boolean
    openOnClick?: boolean
    isOpen?: boolean
    followCaret?: boolean
    setIsOpen?: (v: boolean) => void,
    afterShow?: () => void
    children?: ReactNode
    onRender?: ((render: {
        content: string | null;
        activeAnchor: HTMLElement | null;
    }) => ReactNode)
}

export const CustomTooltip = ({ id, anchorSelect, classNameArrow, largeArrow, className, clickable, openOnClick, isOpen, followCaret, setIsOpen, afterShow, children, onRender }: TooltipProps) => {
    const style = `${largeArrow === false ? '' : 'tooltip-large-arrow'} !bg-system-secondary !rounded-lg !text-system-primary !m-0 !p-4  shadow-soft !z-[30] max-w-[calc(100vw-2rem)] ${className}`

    // Using a state here disables the undo button for some reason
    const selection = window.getSelection()
    const caretPosition = (selection?.rangeCount || 0) > 0 ? selection?.getRangeAt(0).getBoundingClientRect() : undefined

    return (
        <Tooltip
            id={id}
            anchorSelect={anchorSelect}
            clickable={clickable}
            openOnClick={openOnClick}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            afterShow={afterShow}
            className={style}
            classNameArrow={classNameArrow}
            border={`1px solid var(--system-border-regular)`}
            opacity="1"
            render={onRender}
            position={followCaret ? { x: caretPosition?.x || 0, y: caretPosition?.y || 0 } : undefined}
            offset={followCaret ? 24 : undefined}
            positionStrategy="fixed"
            globalCloseEvents={{ 'scroll': true, 'clickOutsideAnchor': true }}
        >
            {!onRender && (
                children
            )}
        </Tooltip>
    )
}
