import {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
  memo,
  useMemo,
} from 'react'
import { ErrorMessage } from '../ErrorMessage'
import { useBlocker } from 'react-router-dom'
import {
  AssistantAskMode,
  ChatMessage,
  Dossier,
  QueryState,
  SystemMessage,
  UserMessage,
  StreamStatus,
  DossierDetail,
  Citation,
  SourceDocument,
  SourceSettings,
  DocumentPreviewType,
  QueryStatus,
  AssistantStore,
  Playbook,
} from '../../types/types'
import { ChatBox } from './ChatBox'
import { UserContext } from '@/contexts/UserContext'
import Divider from '../ui/divider'
import { WarnOnNavigate } from '../WarnOnNavigate'
import { DossierBreadcrumb } from '../Dossier/DossierBreadcrumb'
import { TypographyBody } from '../ui/Typography'
import texture from '../../assets/bg-texture.png'
import { useSelector, useDispatch } from 'react-redux'
import type { RootState, AppDispatch } from '../../store/store'
import { actions as assistantActions } from './assistantSlice'
import { deleteLastMessage } from './assistantThunk'
import UserChatMessage from '../Chat/UserChatMessage'
import { SystemChatMessageContainer } from '../Chat/SystemChatMessage'
import {
  getCitationExtractResource,
  getCitationHighlights,
  getUniqueCitationDocuments,
  sortSourceDocuments,
} from '@/utils/utils'
import { Sources } from './Sources'
import { DocumentPreviewContainer } from '../Resources/DocumentPreview'
import { PastChatsContainer } from './PastChats'
import { getAskConnectors } from '@/utils/ask'
import { Button } from '../ui/button'
import { Check, Loader2, NotebookPen } from 'lucide-react'
import { PlaybookDialog } from '../Playbook/PlaybookDialog'
import { PlaybookWrapper } from '../Playbook/PlaybookWrapper'
import { PlaybookChatPreview } from './PlaybookChatPreview'
import AdditionalContextPreview from './AdditionalContextPreview'

interface IProps {
  handleAsk: (args: {
    message: string
    mode: AssistantAskMode
    playbookId?: string
    playbookVars?: string[]
  }) => void
  conversation: ChatMessage[]
  queryState: QueryState
  sources: SourceSettings
  dossier?: Dossier
  dossierDetail?: DossierDetail
  title?: string
}

const checkIsConversationStale = (
  lastMessage: ChatMessage | null,
  queryStateData: QueryState['data'],
  stopConversationStatus: AssistantStore['stopConversationStatus']
): boolean => {
  if (!lastMessage) {
    return true
  }
  const isNoResponse = queryStateData == null
  const isLastMessageUser = lastMessage.role === 'user'
  const isStoppingConvoError =
    stopConversationStatus === QueryStatus.ERROR_FETCHING

  return (isNoResponse && isLastMessageUser) || isStoppingConvoError
}

export const Conversation = memo(
  ({
    handleAsk,
    conversation,
    queryState,
    sources,
    dossier,
    dossierDetail,
    title,
  }: IProps) => {
    const { settings, updateSettings } = useContext(UserContext)
    const [hasRendered, setHasRendered] = useState(false)
    const [shouldResetBlocker, setShouldResetBlocker] = useState(true)
    const [prevScrollY, setPrevScrollY] = useState(0)
    const [shouldShowBreadcrumb, setShouldShowBreadcrumb] = useState(true)
    const [isInitialScroll, setIsInitialScroll] = useState(true)
    const [openedCitation, setOpenedCitation] = useState<Citation | null>(null)
    const [openedCitationScrollTop, setOpenedCitationScrollTop] = useState(0)
    const [selectedSource, setSelectedSource] = useState<SourceDocument | null>(
      null
    )
    const [selectedExtractIndex, setSelectedExtractIndex] = useState<{
      [id: string]: number
    }>({})
    const [dossierBreadcrumbPosition, setDossierBreadcrumbPosition] =
      useState<DOMRect | null>(null)
    const [showPlaybookDialog, setShowPlaybookDialog] = useState(false)
    const [selectedPlaybook, setSelectedPlaybook] = useState<{
      playbook: Playbook
      playbookId: string
      variables: string[]
    } | null>(null)
    const [uniqueCitationDocuments, setUniqueCitationDocuments] = useState<
      SourceDocument[]
    >([])

    const [selectedAdditionalContext, setSelectedAdditionalContext] = useState<
      string | null
    >(null)
    const [staleConversations, setStaleConversations] = useState<number[]>([])
    const [loadedCitations, setLoadedCitations] = useState(false)
    const [prevGeneratingCitations, setPrevGeneratingCitations] =
      useState(false)

    const assistantStore = useSelector((state: RootState) => state.assistant)
    const streamStatus = useSelector(
      (state: RootState) => state.assistant.state.streamStatus
    )

    const dispatch = useDispatch<AppDispatch>()

    const blocker = useBlocker(
      ({ currentLocation, nextLocation }) =>
        assistantStore.state.streamStatus !== 'Ready' &&
        conversation.at(-1)?.role === 'system' &&
        currentLocation.pathname !== nextLocation.pathname
    )

    const conversationBottomMarginRef = useRef<HTMLDivElement>(null)
    const conversationBottomRef = useRef<HTMLDivElement>(null)
    const dossierBreadcrumbRef = useRef<HTMLDivElement>(null)
    const conversationContainerRef = useRef<HTMLDivElement>(null)

    const lastMessage =
      conversation.length > 0 ? conversation[conversation.length - 1] : null
    const lastSystemMessage = [...conversation]
      .reverse()
      .find((v) => v.role === 'system') as SystemMessage

    const isGeneratingCitations = useMemo(
      () =>
        Boolean(
          (lastSystemMessage?.data?.tool_events &&
            Object.values(lastSystemMessage?.data.tool_events || {}).find(
              (v) =>
                v.tool_name === 'generate_citations' && v.status !== 'completed'
            )) ||
            false
        ),
      [lastSystemMessage]
    )

    useEffect(() => {
      const latestConversationIndex = conversation.length - 1
      if (!staleConversations.includes(latestConversationIndex)) {
        const isStale = checkIsConversationStale(
          lastMessage,
          queryState.data,
          assistantStore.stopConversationStatus
        )

        if (isStale) {
          setStaleConversations((prev) => [...prev, latestConversationIndex])
        }
      }
    }, [lastMessage, queryState.data, assistantStore.stopConversationStatus])

    const isBusy =
      assistantStore.state.streamStatus !== 'Ready' &&
      !queryState.error &&
      !(staleConversations.length > 0)

    const sourceType = dossier ? 'dossier' : 'ask'

    const openedCitationHighlights = useMemo(() => {
      return getCitationHighlights(openedCitation, selectedSource)
    }, [openedCitation, selectedSource])

    const openedCitationResource = useMemo(() => {
      return getCitationExtractResource(
        openedCitationHighlights,
        selectedSource
      )
    }, [selectedSource, openedCitationHighlights])

    useEffect(() => {
      if (conversation.length > 0 && !isBusy && !hasRendered) {
        conversationBottomRef.current?.scrollIntoView({
          behavior: 'instant',
          block: 'end',
        })

        setIsInitialScroll(false)
      } else if (conversation.length > 0) {
        setHasRendered(true)
      }
    }, [conversation])

    useEffect(() => {
      setHasRendered(false)
    }, [])

    useEffect(() => {
      const handleScroll = () => {
        const mainContainer = document.querySelector('.main-container-new')
        const scrollTop = mainContainer?.scrollTop || 0

        if (!isInitialScroll) {
          if (scrollTop > prevScrollY) {
            setShouldShowBreadcrumb(false)
          } else {
            setShouldShowBreadcrumb(true)
          }
        }
        setPrevScrollY(mainContainer?.scrollTop || 0)
      }

      window.addEventListener('scroll', handleScroll, { capture: true })

      return () => {
        window.removeEventListener('scroll', handleScroll, { capture: true })
      }
    }, [prevScrollY, isInitialScroll])

    useEffect(() => {
      const lastUserMessage = [...conversation]
        .reverse()
        .find((v) => v.role === 'system') as SystemMessage

      let mode = settings.assistant.mode

      if (lastUserMessage?.data.plan) {
        mode = 'expert'
      } else {
        mode = 'simple'
      }

      updateSettings({
        settings: {
          ...settings,
          assistant: {
            ...settings.assistant,
            mode: mode,
          },
        },
      })
    }, [conversation])

    useEffect(() => {
      if (queryState.data) {
        dispatch(
          assistantActions.setStreamStatusWithMessage({
            message: lastMessage,
            sourceConnectors: getAskConnectors(
              settings.assistant.sources[sourceType]
            ),
          })
        )
      }
    }, [queryState.data, lastMessage, sources])

    useEffect(() => {
      const updateDossierBreadcrumbPosition = () => {
        if (dossierBreadcrumbRef.current) {
          setDossierBreadcrumbPosition(
            dossierBreadcrumbRef.current.getBoundingClientRect()
          )
        }
      }

      updateDossierBreadcrumbPosition()

      window.addEventListener('resize', updateDossierBreadcrumbPosition)

      return () => {
        window.removeEventListener('resize', updateDossierBreadcrumbPosition)
      }
    }, [dossierBreadcrumbRef])

    useEffect(() => {
      if (!isGeneratingCitations && prevGeneratingCitations) {
        setLoadedCitations(true)

        setTimeout(() => setLoadedCitations(false), 1000)
      }

      setPrevGeneratingCitations(isGeneratingCitations)
    }, [loadedCitations, isGeneratingCitations, prevGeneratingCitations])

    const expertModeAvailable = false

    const chatboxContainerStyle = `flex flex-col gap-2 w-[calc(100%-4rem)] sm:w-full fixed bottom-0 left-0 right-0 sm:left-auto sm:right-auto max-w-[calc(100%-4rem)] md:max-w-[48rem] mx-8 sm:mx-0 z-[3] pointer-events-none`

    const scrollToBottomMargin = () => {
      setTimeout(() => {
        conversationBottomMarginRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }, 200)
    }

    const handleRetry = useCallback(
      (
        message: UserMessage | undefined,
        idx: number,
        playbookId?: string,
        playbookVars?: string[]
      ) => {
        if (!message) return
        scrollToBottomMargin()
        const userMessage = conversation[idx - 1] as UserMessage

        if (userMessage) {
          const conversationId = conversation.at(-1)?.conversationId

          if (!conversationId) return
          dispatch(assistantActions.removeLastMessage({ conversationId }))
          dispatch(deleteLastMessage(conversationId))

          handleAsk({
            message: message.query,
            mode: settings.assistant.mode,
            playbookId,
            playbookVars,
          })
        }
      },
      [handleAsk, dispatch, conversation, settings.assistant.mode]
    )

    const handleFollowUpQuestionClick = useCallback(
      (q: string) => {
        if (streamStatus !== StreamStatus.Ready) return
        scrollToBottomMargin()
        handleAsk({ message: q, mode: settings.assistant.mode })
      },
      [handleAsk, settings.assistant.mode, streamStatus]
    )

    const handleCitationSelection = (
      citation: Citation | null,
      scrollTop: number
    ) => {
      const message = conversation
        .filter((v): v is SystemMessage => Boolean(v))
        .find((v) => {
          return v.data?.citations?.find((v) => {
            return (
              JSON.stringify({ ...v, highlights: [] }) ===
              JSON.stringify({ ...citation, highlights: [] })
            )
          })
        })

      const allDocuments = conversation
        .filter((v): v is SystemMessage => Boolean(v))
        .map((v) => v.data?.documents || [])
        .flatMap((v) => v)

      const documents = sortSourceDocuments(
        getUniqueCitationDocuments(
          citation,
          citation?.isChartHeaderCitation !== undefined
            ? allDocuments
            : message?.data.documents || []
        ),
        citation || undefined
      )

      const adjustedScrollTop = scrollTop
      setOpenedCitationScrollTop(adjustedScrollTop)
      setOpenedCitation(citation)
      setUniqueCitationDocuments(documents)
      setSelectedPlaybook(null)
      setSelectedAdditionalContext(null)

      if (documents.length > 0) {
        setSelectedSource(documents[0])
      } else {
        setSelectedSource(null)
      }
    }

    const handleSourceClick = (source: SourceDocument) => {
      setSelectedSource(source)
    }

    const handleBackgroundClick = () => {
      setOpenedCitation(null)
      setSelectedSource(null)
      setUniqueCitationDocuments([])
      setSelectedPlaybook(null)
      setSelectedAdditionalContext(null)
    }

    const handleSelectedExtractChange = useCallback(
      (index: number) => {
        if (!selectedSource) return
        setSelectedExtractIndex({
          ...selectedExtractIndex,
          [selectedSource.document_id]: index,
        })
      },
      [selectedSource, selectedExtractIndex]
    )

    const isShouldShift = useMemo(() => {
      return Boolean(selectedSource || selectedPlaybook || openedCitation)
    }, [selectedSource, selectedPlaybook, openedCitation])

    const messageContainsOpenedCitationEnum: boolean[] = useMemo(() => {
      return conversation.map((chatMessage) => {
        if (chatMessage.role !== 'system') return false

        return Boolean(
          openedCitation &&
            chatMessage?.data?.citations?.find((v: Citation) => {
              return (
                JSON.stringify({ ...openedCitation, highlights: [] }) ===
                JSON.stringify({ ...v, highlights: [] })
              )
            })
        )
      })
    }, [openedCitation, conversation])

    return (
      <>
        <div
          ref={conversationContainerRef}
          className={`relative flex gap-4 justify-center ask-full-width-scrollable-container overflow-x-hidden`}
          onClick={handleBackgroundClick}
        >
          <PastChatsContainer />

          <div className={`max-w-[48rem] md:w-[48rem] w-fit`}>
            {dossier && shouldShowBreadcrumb && prevScrollY !== 0 && (
              <div
                className={`fixed top-[3.25rem] bg-system-surface z-[2]`}
                style={{
                  backgroundImage: `url(${texture})`,
                  width: dossierBreadcrumbPosition?.width,
                  left: dossierBreadcrumbPosition?.left,
                }}
              >
                <DossierBreadcrumb
                  dossier={dossier}
                  component={
                    <TypographyBody
                      isStrong={true}
                      className="whitespace-nowrap text-ellipsis overflow-hidden max-w-[25rem]"
                    >
                      {title || 'New chat'}
                    </TypographyBody>
                  }
                />
              </div>
            )}

            {dossier && (
              <div
                ref={dossierBreadcrumbRef}
                className="bg-system-surface w-full z-[2] -mt-2.5"
                style={{ backgroundImage: `url(${texture})` }}
              >
                <DossierBreadcrumb
                  dossier={dossier}
                  component={
                    <TypographyBody
                      isStrong={true}
                      className="whitespace-nowrap text-ellipsis overflow-hidden max-w-[25rem]"
                    >
                      {title || 'New chat'}
                    </TypographyBody>
                  }
                />
              </div>
            )}

            <div className={`${dossier ? 'pt-7' : ''} max-w-[45rem] mx-auto`}>
              {conversation.map((chatMessage, idx) => {
                if (chatMessage.role === 'user') {
                  const isNoResponse =
                    idx + 1 < conversation.length &&
                    conversation[idx + 1].role !== 'system'

                  return (
                    <div
                      key={`user__${idx}`}
                      id={`user__${idx}`}
                      className="flex flex-col gap-4"
                    >
                      <UserChatMessage
                        message={chatMessage}
                        onPlaybookClick={(v) => {
                          setSelectedPlaybook(v)
                          setOpenedCitation(null)
                          setSelectedSource(null)
                          setSelectedAdditionalContext(null)
                        }}
                        onAdditionalContextClick={(ac) => {
                          setSelectedAdditionalContext(ac)
                          setUniqueCitationDocuments([])
                          setSelectedPlaybook(null)
                          setOpenedCitation(null)
                          setSelectedSource(null)
                        }}
                      />
                      {(staleConversations.includes(idx) || isNoResponse) && (
                        <>
                          <ErrorMessage message="We could not load the response. Please retry or ask a new question." />
                          {idx !== conversation.length - 1 && (
                            <div className="mb-8">
                              <Divider />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )
                }
                if (chatMessage.role == 'system') {
                  const question = conversation[idx - 1] as UserMessage
                  const messageCitation = messageContainsOpenedCitationEnum[idx]
                    ? openedCitation
                    : null

                  const lastMessageDiv = document.getElementById(
                    `user__${idx - 1}`
                  )

                  const lastMessageHeight =
                    lastMessageDiv?.getBoundingClientRect().height

                  return (
                    // 100vh - 5rem (container bottom padding) - 9rem (chatbox height) - 3.25rem (navbar height) - 1.5rem
                    <div
                      style={{
                        minHeight:
                          idx === conversation.length - 1
                            ? `calc(100vh - 5rem - 9rem - 3.25rem${lastMessageHeight ? ` - ${lastMessageHeight}px` : ''} - 1.5rem)`
                            : undefined,
                      }}
                      key={`system_${idx}`}
                    >
                      <SystemChatMessageContainer
                        message={chatMessage}
                        onFollowUpQuestionClick={handleFollowUpQuestionClick}
                        onRetry={() =>
                          handleRetry(
                            question,
                            idx,
                            question.playbook?.playbook_id,
                            question.playbook?.playbook_vars
                          )
                        }
                        showFollowUpQuestions={true}
                        canRetry={conversation.length - 1 === idx}
                        question={question?.query || ''}
                        sourceType={sourceType}
                        openedCitation={messageCitation}
                        onCitationOpen={handleCitationSelection}
                        isExpand
                        isShouldShift={isShouldShift}
                      />
                      {idx !== conversation.length - 1 && (
                        <div className="mt-6 mb-8">
                          <Divider />
                        </div>
                      )}
                    </div>
                  )
                }
                return null
              })}

              {queryState.error && (
                <ErrorMessage
                  message={`We failed to stream the response: ${queryState.error}. Try asking again`}
                />
              )}
            </div>
            <div
              className={`h-[9rem] w-full`}
              ref={conversationBottomRef}
            ></div>

            <div
              className={`h-px w-full`}
              ref={conversationBottomMarginRef}
            ></div>

            <div className={chatboxContainerStyle}>
              <div className="flex">
                <div className="flex w-fit shadow-soft-layover pointer-events-auto">
                  <Button
                    variant="secondary"
                    onClick={() => setShowPlaybookDialog(true)}
                  >
                    <div className="flex gap-2 items-center">
                      <NotebookPen className="size-6 shrink-0 stroke-interactive" />
                      Open Playbook
                    </div>
                  </Button>
                </div>

                {(isGeneratingCitations || loadedCitations) && (
                  <div className="flex gap-3 h-[2.125rem] items-center bg-system-surface border border-system-border-regular rounded-md px-4 ml-auto mt-auto">
                    {loadedCitations ? (
                      <Check className="size-4 shrink-0 stroke-interactive" />
                    ) : (
                      <Loader2 className="size-4 shrink-0 animate-spin" />
                    )}
                    <TypographyBody className="text-system-primary">
                      {loadedCitations
                        ? 'Citations ready'
                        : 'Loading citations...'}
                    </TypographyBody>
                  </div>
                )}
              </div>
              <div className="pointer-events-auto">
                <ChatBox
                  enableDragAndDrop
                  expertModeAvailable={expertModeAvailable}
                  initialMessage=""
                  handleSubmit={(v) => {
                    scrollToBottomMargin()
                    setTimeout(() => {
                      handleAsk(v)
                    }, 100)
                  }}
                  isConversation={true}
                  additionalControls={true}
                  conversationId={lastMessage?.conversationId}
                  isFinished={!isBusy}
                  canStop={true}
                  sourceType={dossier || dossierDetail ? 'dossier' : 'ask'}
                  dossierDetail={dossierDetail}
                />
              </div>
            </div>
          </div>

          <div
            className={`${selectedAdditionalContext || uniqueCitationDocuments.length > 0 || selectedSource || selectedPlaybook ? 'w-[45rem] monitor:w-[calc(100vw-15rem-1rem-48rem)] document-preview:w-[calc((48rem/2-(50vw-50rem-7.5rem))*2+1rem)]' : 'w-0'} max-w-[50rem] relative transition-width ease-in-out duration-300`}
          >
            {selectedPlaybook && (
              <div className="fixed right-[7.5rem] top-[5.75rem] transition-width ease-in-out duration-300 w-[45rem] monitor:w-[calc(100vw-15rem-1rem-48rem)] max-w-[50rem]">
                <PlaybookChatPreview
                  playbookId={selectedPlaybook.playbookId}
                  playbook={selectedPlaybook.playbook}
                  variables={selectedPlaybook.variables}
                  onClose={() => setSelectedPlaybook(null)}
                />
              </div>
            )}

            {selectedAdditionalContext && (
              <div
                onClick={(e) => {
                  e.stopPropagation()
                }}
                className="fixed right-[7.5rem] top-[5.75rem] transition-width ease-in-out duration-300 w-[45rem] monitor:w-[calc(100vw-15rem-1rem-48rem)]"
              >
                <AdditionalContextPreview
                  additionalContext={selectedAdditionalContext}
                  onClose={() => {
                    setSelectedAdditionalContext(null)
                  }}
                />
              </div>
            )}

            {uniqueCitationDocuments.length > 0 && (
              <div
                className={`absolute left-0 top-0 w-[45rem] monitor:w-[calc(100vw-15rem-1rem-48rem)] max-w-[40rem] ease-in-out duration-300 ${selectedSource ? 'hidden' : ''}`}
                style={{ marginTop: `${openedCitationScrollTop}px` }}
              >
                <Sources
                  documents={uniqueCitationDocuments}
                  showTabs={false}
                  onSourceClick={handleSourceClick}
                  previewable={true}
                />
              </div>
            )}
            {selectedSource && openedCitationResource && (
              <div className="fixed right-[7.5rem] top-[5.75rem] w-[45rem] monitor:w-[calc(100vw-15rem-1rem-48rem)] max-w-[50rem]">
                <DocumentPreviewContainer
                  key={`document-preview-container-${selectedSource.document_id}`}
                  type={DocumentPreviewType.ASK}
                  resource={openedCitationResource}
                  selectedExtractIndex={
                    selectedExtractIndex[selectedSource.document_id] || 0
                  }
                  setSelectedExtractIndex={handleSelectedExtractChange}
                  sources={uniqueCitationDocuments}
                  selectedSource={selectedSource}
                  initialWidth={
                    window.innerWidth > 1600
                      ? Math.min(
                          window.innerWidth - 240 - 16 - 750 - 32 - 48,
                          800 - 32 - 48
                        )
                      : 630 - 32 - 48
                  }
                  onClose={() => {
                    setSelectedSource(null)
                    setOpenedCitation(null)
                    setUniqueCitationDocuments([])
                  }}
                  setSelectedSource={(v) => setSelectedSource(v)}
                />
              </div>
            )}
          </div>
        </div>
        <WarnOnNavigate
          open={blocker.state === 'blocked'}
          onConfirm={() => {
            setShouldResetBlocker(false)
            blocker?.proceed?.()
            dispatch(
              assistantActions.setStreamStatus({ status: StreamStatus.Ready })
            )
            // fixes "router allows one blocker at a time" error
            setTimeout(() => {
              blocker?.reset?.()
            }, 500)
          }}
          onCancel={() => {
            setTimeout(() => {
              if (shouldResetBlocker) {
                blocker?.reset?.()
              }

              setShouldResetBlocker(true)
            }, 1)
          }}
        />

        <PlaybookWrapper
          sourceType={dossier ? 'dossier' : 'ask'}
          conversation={conversation}
          conversationId={conversation.at(-1)?.conversationId}
          onAsk={() => {
            scrollToBottomMargin()
          }}
          onBack={() => setShowPlaybookDialog(true)}
        >
          <PlaybookDialog
            open={showPlaybookDialog}
            setOpen={setShowPlaybookDialog}
          />
        </PlaybookWrapper>
      </>
    )
  }
)
