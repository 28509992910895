export const WEB_SERVER_ENDPOINT = import.meta.env.VITE_WEB_SERVER_ENDPOINT
export const DEBUG_MODE = import.meta.env.VITE_DEBUG_MODE
export const LOGIN_PATH = import.meta.env.VITE_LOGIN_PATH
export const LOGOUT_PATH = import.meta.env.VITE_LOGOUT_PATH
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
export const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT
export const DISABLE_SENTRY = import.meta.env.VITE_DISABLE_SENTRY // for local dev

// user controlled feature flags
export const USER_FEATURE_FLAG_PREFIX = 'user_flag'
export const userFeatureFlags = [
  'assistant: model parameters',
  'docgen: ask',
  'files: legacy upload',
  'docgen: entity extraction',
  'docgen: factCheck primary entity context',
  'docgen: factCheck regen answer',
  'assistant: tools',
  'integration: manage',
  'debug: force show integration connectors',
] as const

export const DEFAULT_CACHE_TIMEOUT_MS = 1_000 * 60 * 5 // 5minutes

export const STREAM_RESPONSE_TIMEOUT_MS = 1_000 * 30
export const MESSAGE_ASSUMED_EXPIRED_MS = 1_000 * 60
export const STREAM_RESPONSE_DEBOUNCE_MS = 250

export const LOCAL_STORAGE_FILES_KEY = 'temporary_uploaded_files:resource'

export const ASK_UPLOAD_VISIBILITY = 'private'
export const USER_SETTINGS_KEY = 'user_settings'
export const MAX_ICONS_INLINE_SOURCES = 5
export const MAX_UPLOAD_FILES = 20
export const MAX_UPLOAD_FILES_ASK_BAR = 15
export const MAX_CONTEXT_WINDOW = 5000

export enum Color {
  'system-primary' = '#0F172A',
  'system-secondary' = '#FFFFFF',
  'system-border-regular' = '#C8D3DF',
  'system-border-light' = '#E5ECF4',
  'messaging-negative-background' = '#FEECE2',
  'messaging-negative-text' = '#B50021',
  'system-chart-1' = '#00271B',
  'system-chart-2' = '#235500',
  'system-chart-3' = '#73BB22',
  'system-chart-4' = '#777C76',
  'system-chart-5' = '#C8C8C8',
  'system-chart-6' = '#FFDC00',
  'system-chart-7' = '#FF6201',
  'system-chart-8' = '#E0E0E0',
  'system-chart-9' = '#224B83',
  'system-chart-10' = '#9834B0',
  'system-body' = '#3E526E',
}

export const reactPDFOptions = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
  cMapPacked: true,
}

export enum LocalStorageKey {
  CONNECTING_INTEGRATION = 'connecting_integration',
  SEARCH_CONNECTORS = 'search_connectors',
  SETTINGS_SNAPSHOT = 'persistance',
  STREAM_RESPONSE_DEBOUNCE_MS = 'stream_response_debounce_ms',
  TEMPLATE_FILE_IDS = 'template_file_ids',
  REPORTS_FILTERS = 'reports_filters',
}

export const validFocusedAnalysisFilesTypes = [
  'pdf',
  'application/pdf',
  'application/x-javascript',
  'text',
  'text/javascript',
  'text/plain',
  'text/html',
  'text/css',
  'markdown',
  'text/md',
  'csv',
  'text/csv',
  'text/xml',
  'text/rtf',
  'image',
  'image/png',
  'image/jpeg',
  'image/webp',
  'image/heic',
  'image/heif',
  'word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'spreadsheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
]

export const CHART_SHORT_WORDS_LENGTH = 4 as const
export const CHART_MEDIUM_WORDS_LENGTH = 7 as const
export const CHART_TIMESERIES_THRESHOLD = 10 as const
export const THEME_MIN_AMOUNT = 5 as const
export const THEME_MAX_AMOUNT = 10 as const

export const TABLE_CONFIG = {
  RESIZE_FACTOR: 0.5,
  PADDING: 48,
  ESTIMATED_FONT_WIDTH: 14,
  MAX_CELL_HEIGHT: 90,
  MIN_CELL_WIDTH: 120,
  MAX_CELL_WIDTH: 1200,
  MARGIN_TOP: 36,
  SCROLL_THRESHOLD: 1440,
}

export const REM = 14 as const
