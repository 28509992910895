import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '../../store/store'
import { THEME_MIN_AMOUNT, WEB_SERVER_ENDPOINT } from '../../constants'
import { Theme } from '@/types/types'

export const fetchThemePreferences = createAsyncThunk<
  Theme,
  void,
  { state: RootState }
>('theme/fetchPreferences', async (_, { rejectWithValue }) => {
  try {
    const res = await fetch(`${WEB_SERVER_ENDPOINT}/api/org/preferences`, {
      method: 'GET',
      credentials: 'include',
    })
    if (!res.ok) {
      throw new Error("Couldn't fetch preferences")
    }
    const data = await res.json()

    if (
      data &&
      Array.isArray(data.colors) &&
      Array.isArray(data.texts) &&
      data.colors.length >= THEME_MIN_AMOUNT &&
      data.texts.length >= THEME_MIN_AMOUNT
    ) {
      return data
    }

    return rejectWithValue('Invalid theme data received')
  } catch (error) {
    return rejectWithValue('Failed to fetch preferences')
  }
})

export const updateThemePreferences = createAsyncThunk<
  void,
  Theme,
  { state: RootState }
>('theme/updatePreferences', async (theme, { rejectWithValue }) => {
  try {
    const res = await fetch(`${WEB_SERVER_ENDPOINT}/api/org/preferences`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(theme),
    })
    if (!res.ok) {
      throw new Error("Couldn't update preferences")
    }
  } catch (error) {
    return rejectWithValue('Failed to update preferences')
  }
})
