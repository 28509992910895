import { DedupedSearchQuery, DedupedSearchQueryItem, ResponseSearchQuery } from "../types/types";
import { getFileId } from "./utils";

export function getOverlayedHighlights(passage: string, highlights: string[]) {
    let output = passage;
    
    for (let index = 0; index < highlights.length; index++) {
        const highlight = highlights[index];
        // strip highlight of emphasis markup
        const strippedHighlight = highlight.replaceAll("<em>", "").replaceAll("</em>", "");
        // replace passage with emphasised highlights
        output = output.replace(strippedHighlight, highlight);
    }
    return output;
}

export function groupByFile(searchResults: ResponseSearchQuery): DedupedSearchQueryItem[] {
    const grouped = searchResults.reduce((acc, cur) => {
        const fileId = getFileId(cur.id);
        let existingResult = acc.find(x => getFileId(x.id) === fileId);
        if (!existingResult) {
            return [...acc, {
                ...cur,
                extracts: [
                    {
                        text: cur.text,
                        highlight: cur.highlight,
                        page_number: cur.page_number
                    }
                ]
            }];
        }

        return acc.map(x => {
            if (x.id !== existingResult!.id) {
                return x;
            }
            return {
                ...x,
                extracts: [
                    ...x.extracts,
                    {
                        text: cur.text,
                        highlight: cur.highlight,
                        page_number: cur.page_number
                    }
                ]
            }
        });
    }, [] as DedupedSearchQuery);
    return grouped;
}
