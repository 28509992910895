import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchThemePreferences, updateThemePreferences } from './themeThunks'
import { QueryStatus, Theme } from '@/types/types'
import { THEME_MIN_AMOUNT } from '@/constants'
import { getDesiaDefaultTheme } from '@/utils/utils'

interface ThemeState {
  theme: Theme
  fetchStatus: QueryStatus
  updateStatus: QueryStatus
}

const initialState: ThemeState = {
  theme: {
    colors: [],
    texts: [],
  },
  fetchStatus: QueryStatus.INITIALISED,
  updateStatus: QueryStatus.INITIALISED,
}

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchThemePreferences.pending, (state) => {
        state.fetchStatus = QueryStatus.FETCHING
      })
      .addCase(
        fetchThemePreferences.fulfilled,
        (state, action: PayloadAction<Theme>) => {
          state.fetchStatus = QueryStatus.SUCCEEDED
          if (
            action.payload &&
            Array.isArray(action.payload.colors) &&
            action.payload.colors.length >= THEME_MIN_AMOUNT
          ) {
            state.theme = action.payload
          } else {
            const defaultTheme = getDesiaDefaultTheme()
            state.theme = defaultTheme
          }
        }
      )
      .addCase(fetchThemePreferences.rejected, (state) => {
        state.fetchStatus = QueryStatus.ERROR_FETCHING
        const defaultTheme = getDesiaDefaultTheme()
        state.theme = defaultTheme
      })
      .addCase(updateThemePreferences.pending, (state) => {
        state.updateStatus = QueryStatus.FETCHING
      })
      .addCase(updateThemePreferences.fulfilled, (state) => {
        state.updateStatus = QueryStatus.SUCCEEDED
      })
      .addCase(updateThemePreferences.rejected, (state) => {
        state.updateStatus = QueryStatus.ERROR_FETCHING
      })
  },
})

export default themeSlice.reducer
