import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import EditorJS, { OutputBlockData, OutputData } from '@editorjs/editorjs'
import { RootState, store } from '@/store/store'
import {
  checkUserFlag,
  getCitationExtractResource,
  getCitationHighlights,
  getTimestamp,
  getUniqueCitationDocuments,
  shiftCitationsToBlock,
  sortSourceDocuments,
} from '@/utils/utils'
import {
  setSelectedText,
  setToolComponent,
  setCursorTool,
  setToolCursor,
  updateOpenedCitation,
} from './docGenSlice'
import { useDebounce } from '@/hooks/useDebounce'
import { Picker } from './PanelTools/Picker'
import { FactCheckTool } from './InlineTools/FactCheckTool'
import { ResearchTool } from './InlineTools/ResearchTool'
import { ToolPanel } from './ToolPanel'
import { FinalAnswerBlock } from './BlockTools/FinalAnswer'
import {
  Citation,
  DocumentPreviewType,
  EditReport,
  ExtendableCitedBlock,
  ResponseEntityExtraction,
  SourceDocument,
  SystemMessage,
} from '@/types/types'
import { PreviewSources } from '../Assistant/PreviewSources'
import {
  filterDocumentsByCited,
  getGlobalUniqueDocuments,
} from '@/utils/components'
import { LayoutContext } from '@/contexts/LayoutContext'
import { useWindowSize } from '@/hooks/useWindowSize'
import texture from '../../assets/bg-texture.png'
import { Button } from '../ui/button'
import { AskDesiaTool } from './BlockTools/AskDesiaTool'
import { ParagraphTool } from './BlockTools/ParagraphTool'
import { TypographyLabel } from '../ui/Typography'
import { Loader2 } from 'lucide-react'
import Checkmark from '@/assets/Checkmark'
import { formatDistanceToNowStrict } from 'date-fns'
import { ReportDropdownMenu } from '../Reports/ReportDropdownMenu'
import { DossierBreadcrumb } from '../Dossier/DossierBreadcrumb'
import { CustomTooltip } from '../CustomTooltip'
import { createPortal } from 'react-dom'
import { renderToStaticMarkup } from 'react-dom/server'
import MessageCircleQuestionAccent from '@/assets/MessageCircleQuestionAccent.png'
import { handleError } from '@/utils/handleError'
import { Sources } from '../Assistant/Sources'
import { DocumentPreviewContainer } from '../Resources/DocumentPreview'

function getAggregateSources(blocks: OutputBlockData[]) {
  let citations: Citation[] = []
  let documents: SourceDocument[] = []
  blocks.forEach((b) => {
    if (b.type === 'FinalAnswer') {
      citations.push(...b.data.citations)
      documents.push(...b.data.documents)
    }
  })
  const citedDocuments = filterDocumentsByCited(documents, citations)
  const dedupedCitedDocuments = getGlobalUniqueDocuments(citedDocuments)
  return { citations, documents, citedDocuments: dedupedCitedDocuments }
}

function parseResponseToBlocks(
  text?: string,
  citations?: Citation[],
  documents?: SourceDocument[]
): ExtendableCitedBlock[] {
  const paragraphs = text?.split('\n')

  if (!paragraphs) return []

  const citationsQueue = [...(citations || [])]

  const blocks: ExtendableCitedBlock[] = paragraphs.map((paragraph) => {
    const relevantCitations = shiftCitationsToBlock(paragraph, citationsQueue)

    return {
      text: paragraph,
      citations: relevantCitations,
      documents: documents,
    }
  })

  return blocks
}

function Editor({
  data,
  instance,
  setInstance,
  cleanup,
  handleChange,
  onBlur,
}: {
  data: OutputData
  instance: EditorJS | undefined
  setInstance: (instance: EditorJS) => void
  cleanup: () => void
  handleChange: (data: OutputData) => void
  onBlur: () => void
}) {
  const dispatch = useDispatch()
  const initEditorCalled = useRef(false)
  const initEditor = () => {
    initEditorCalled.current = true

    const editor = new EditorJS({
      holder: 'editorjs',
      onReady: () => {
        setInstance(editor)
        console.log('Editor -> instance', instance)

        const toolbarDiv = document.querySelector('.ce-toolbar__actions')
        if (toolbarDiv && !toolbarDiv.querySelector('.toolbar-menu-icon')) {
          const button = document.createElement('button')
          button.classList.add('size-6')
          button.classList.add('[&_svg]:!stroke-link')
          button.innerHTML = renderToStaticMarkup(
            <Button variant="icon" className="!size-6 rounded-[2px]">
              <img src={MessageCircleQuestionAccent} />
            </Button>
          )
          button.onclick = () => {
            dispatch(setCursorTool(null))
            dispatch(setToolComponent('ask'))
          }
          toolbarDiv.prepend(button)
        }
      },
      autofocus: true,
      data,
      onChange: async () => {
        let content = await editor.saver.save()
        console.info('onChange event content', content)
        handleChange(content)
      },
      inlineToolbar: ['FactCheck', 'Research', 'link', 'bold', 'italic'],
      defaultBlock: 'NewParagraph',
      tools: {
        AskDesia: {
          // @ts-expect-error
          class: AskDesiaTool,
          config: {
            onCreate: () => {
              dispatch(setCursorTool(null))
              dispatch(setToolComponent('ask'))
            },
          },
        },
        NewParagraph: {
          // @ts-expect-error
          class: ParagraphTool,
          inlineToolbar: true,
          config: {
            onSlash: () => {
              dispatch(setCursorTool(null))
              dispatch(setToolComponent('ask'))
            },
          },
        },
        FinalAnswer: {
          // @ts-expect-error
          class: FinalAnswerBlock,
          inlineToolbar: true,
        },
        FactCheck: {
          class: FactCheckTool,
          inlineToolbar: true,
          config: {
            handlers: {
              showPanel: () => {
                store.dispatch(setToolComponent('factCheck'))
              },
              setSelectedText: (text: string) => {
                store.dispatch(setSelectedText(text))
              },
            },
          },
        },
        Research: {
          class: ResearchTool,
          inlineToolbar: true,
          config: {
            handlers: {
              showPanel: () => {
                store.dispatch(setToolComponent('research'))
              },
              setSelectedText: (text: string) => {
                store.dispatch(setSelectedText(text))
              },
            },
          },
        },
      },
    })
  }

  useEffect(() => {
    if (!initEditorCalled.current) {
      initEditor()
    }

    return () => {
      cleanup()
    }
  }, [])

  return (
    <div
      id="editorjs"
      onBlur={() => onBlur()}
      className="py-10 h-[calc(100vh-9.375rem)] text-system-body selection:bg-highlight-selected"
    ></div>
  )
}

function checkUnsavedChanges(editReport: EditReport) {
  try {
    const { lastSave, server, client } = editReport
    if (lastSave?.loading) return false
    if (!server.data) return false
    // title changed
    if (server.data.title !== client.title) return true
    // report blocks changed
    const clientReportContent = JSON.stringify(client.report)
    // handle edge case of empty report - client needs to satisfy OutputData type
    if (server.data.content === '' && clientReportContent === '{"blocks":[]}')
      return false
    if (server.data.content !== clientReportContent) return true
    return false
  } catch (e) {
    handleError(e)
    return false
  }
}

function SaveControl({
  editReport,
  handleSave,
  handleExtract,
  className,
}: {
  editReport: EditReport | null
  handleSave: () => void
  handleExtract?: () => void
  className?: string
}) {
  const [success, setSuccess] = useState(false)
  const error = editReport?.lastSave?.error
  const loading = editReport?.lastSave?.loading

  useEffect(() => {
    if (!loading) {
      setSuccess(!!editReport?.lastSave && !error && !loading)

      setTimeout(() => {
        setSuccess(false)
      }, 2000)
    }
  }, [loading])

  if (!editReport) return null

  const changesPending = checkUnsavedChanges(editReport)

  if (!changesPending && !loading && !success) return null

  let buttonLabel = ''
  if (loading) {
    buttonLabel = 'Saving'
  } else if (error) {
    buttonLabel = 'Failed to save'
  } else if (success) {
    buttonLabel = 'Saved'
  } else {
    buttonLabel = 'Save'
  }

  const hasEntities =
    (editReport?.client?.extractedEntities?.entities || []).length > 0
  const extractedEntities = editReport?.client?.extractedEntities
  return (
    <div className={`${className} z-[2] flex gap-2 items-center`}>
      {changesPending && (
        <TypographyLabel className="text-system-body">
          Unsaved changes
        </TypographyLabel>
      )}

      <Button variant="primary" onClick={handleSave}>
        <div className="flex gap-2 items-center">
          {loading && (
            <Loader2 className="h-5 w-5 animate-spin flex-shrink-0" />
          )}
          {success && <Checkmark className="h-6 w-6 shrink-0" />}
          {buttonLabel}
        </div>
      </Button>

      {checkUserFlag('docgen: entity extraction') &&
        typeof handleExtract === 'function' && (
          <>
            {createPortal(
              <CustomTooltip
                id="extract-entities"
                className="!py-1 !px-3 !rounded-sm"
                largeArrow={false}
              >
                <div className="py-1 ml-3">
                  {hasEntities && (
                    <>
                      <ul>
                        {extractedEntities &&
                          extractedEntities.entities.map((r) => {
                            return (
                              <li className={r.isMainEntity ? 'font-bold' : ''}>
                                {JSON.stringify(r)}
                              </li>
                            )
                          })}
                      </ul>

                      <div>
                        Entities extracted{' '}
                        {formatDistanceToNowStrict(
                          new Date(extractedEntities!.extractionDateTime),
                          { addSuffix: true }
                        )}
                      </div>
                    </>
                  )}

                  {!hasEntities && (
                    <span>
                      Click to generate entities for this report to help aid
                      factCheck
                    </span>
                  )}
                </div>
              </CustomTooltip>,
              document.body
            )}
          </>
        )}
    </div>
  )
}

export function ReportWriter({
  reportId,
  title,
  report,
  extractedEntities,
  dossierId,
  handleTitleChange,
  handleReportChange,
  handleSave,
  handleExtract,
}: {
  reportId: string
  title: string
  report: OutputData
  extractedEntities?: ResponseEntityExtraction
  dossierId?: string
  handleTitleChange: (title: string) => void
  handleReportChange: (report: OutputData) => void
  handleSave: () => void
  handleExtract?: () => void
}) {
  const dispatch = useDispatch()
  const editorInstance = useRef<EditorJS>()
  const layoutContext = useContext(LayoutContext)
  const size = useWindowSize()

  const panelTool = useSelector((state: RootState) => state.docGen.panelTool)
  const cursorTool = useSelector((state: RootState) => state.docGen.cursorTool)
  const selectedText = useSelector(
    (state: RootState) => state.docGen.selectedText
  )
  const editReport = useSelector(
    (state: RootState) => state.docGen.editReportById[reportId]
  )
  const dossiers = useSelector((state: RootState) => state.dossier.dossiers)
  const dossierDetails = useSelector(
    (state: RootState) => state.dossier.dossierDetails
  )
  const openedCitation = useSelector(
    (state: RootState) => state.docGen.openedCitation
  )
  const dossier = dossiers.data?.find((v) => v.id === dossierId)
  const dossierDetail =
    (dossierId && dossierDetails[dossierId]?.data) || undefined

  const panelToolOpen = panelTool.componentType !== null
  const cursorToolOpen = cursorTool.componentType !== null

  const [timestampLastInteraction, setTimestampLastInteraction] =
    useState(getTimestamp())
  const [lastFocusedBlockIndex, setLastFocusedBlockIndex] = useState<
    number | undefined
  >(undefined)
  const [selectedSource, setSelectedSource] = useState<SourceDocument | null>(
    null
  )
  const [selectedExtractIndex, setSelectedExtractIndex] = useState<{
    [id: string]: number
  }>({})

  const debouncedValue = useDebounce(timestampLastInteraction, 1000)
  const { citedDocuments } = getAggregateSources(report.blocks)
  const titleInputRef = useRef<HTMLInputElement>(null)
  const sourceContainerRef = useRef<HTMLDivElement>(null)

  const toolPanelWidth = Math.min(
    size.width - 900 - 32 - (layoutContext.showSidebar ? 330 : 0),
    434
  )

  const sourceContainerWidth =
    sourceContainerRef.current?.getBoundingClientRect().width || 0
  const sourceContainerRight =
    sourceContainerRef.current?.getBoundingClientRect().right || 0

  const openedCitationHighlights = useMemo(() => {
    return getCitationHighlights(openedCitation, selectedSource)
  }, [openedCitation, selectedSource])

  const openedCitationResource = useMemo(() => {
    return getCitationExtractResource(openedCitationHighlights, selectedSource)
  }, [selectedSource, openedCitationHighlights])

  const uniqueCitationDocuments = useMemo(() => {
    const allDocuments = citedDocuments
    const documents = sortSourceDocuments(
      getUniqueCitationDocuments(openedCitation, allDocuments),
      openedCitation || undefined
    )
    return documents
  }, [citedDocuments, openedCitation])

  function addAssistantResponse(systemMessage: SystemMessage) {
    const lastIndex = lastFocusedBlockIndex
    let index: number | undefined = undefined
    if (lastIndex) {
      if (report.blocks[lastIndex].data.text) {
        index = lastIndex + 1
      } else {
        index = lastIndex
      }
    }
    const isContainingChart = systemMessage.data.citations?.[0]?.chart_table_id

    if (isContainingChart) {
      editorInstance.current?.blocks.insert(
        'FinalAnswer',
        {
          text: systemMessage.data.text,
          citations: systemMessage.data.citations,
          documents: systemMessage.data.documents,
        },
        {},
        index,
        true
      )
    } else {
      const blocks: ExtendableCitedBlock[] = parseResponseToBlocks(
        systemMessage.data.text,
        systemMessage.data.citations,
        systemMessage.data.documents
      )
      blocks.forEach((block, i) => {
        editorInstance.current?.blocks.insert(
          'FinalAnswer',
          block,
          {},
          (index ?? 0) + i,
          true
        )
      })
    }
  }

  function addAssistantResponseInline(
    partialSystemMessage: {
      text: string
      citations: Citation[]
      documents: SourceDocument[]
    },
    replaceHighlight: boolean
  ) {
    if (replaceHighlight) {
      const currentBlockIndex =
        editorInstance.current?.blocks.getCurrentBlockIndex()
      if (currentBlockIndex === undefined) {
        return
      }

      const currentBlock =
        editorInstance.current?.blocks.getBlockByIndex(currentBlockIndex)
      if (currentBlock === undefined) {
        return
      }

      const text = report.blocks[currentBlockIndex].data.text
      const splitText = text.split(selectedText)

      const prefixText = splitText[0]
      const postfixText = splitText[1]

      const citations = partialSystemMessage.citations.map((citation) => {
        const mutatedCitation: Citation = {
          text: citation.text,
          start: citation.start + prefixText.length,
          end: citation.end + prefixText.length,
          document_ids: citation.document_ids,
        }
        return mutatedCitation
      })

      const blocks: ExtendableCitedBlock[] = parseResponseToBlocks(
        `${prefixText} ${partialSystemMessage.text} ${postfixText}`,
        citations,
        partialSystemMessage.documents
      )

      blocks.forEach((block) => {
        editorInstance.current?.blocks.insert(
          'FinalAnswer',
          block,
          {},
          undefined,
          true,
          true
        )
      })
    } else {
      const blocks: ExtendableCitedBlock[] = parseResponseToBlocks(
        partialSystemMessage.text,
        partialSystemMessage.citations,
        partialSystemMessage.documents
      )

      blocks.forEach((block) => {
        editorInstance.current?.blocks.insert(
          'FinalAnswer',
          block,
          {},
          undefined,
          true
        )
      })
    }
  }

  const handleSourceClick = (source: SourceDocument) => {
    setSelectedSource(source)
  }

  const handleBackgroundClick = () => {
    dispatch(updateOpenedCitation(null))
    setSelectedSource(null)
  }

  const handleSetSelectedExtractIndex = useCallback(
    (index: number) => {
      if (!selectedSource) return
      setSelectedExtractIndex({
        ...selectedExtractIndex,
        [selectedSource.document_id]: index,
      })
    },
    [selectedSource, selectedExtractIndex]
  )

  useEffect(() => {
    const selectedText = window.getSelection()?.toString()
    if (!cursorToolOpen && !panelToolOpen && !selectedText) {
      dispatch(setCursorTool('ask'))
    }
  }, [debouncedValue])

  useEffect(() => {
    layoutContext.toggleShowSidebar(false)
  }, [])

  useEffect(() => {
    if (openedCitation) {
      setSelectedSource(uniqueCitationDocuments[0])
    }
  }, [openedCitation])

  useEffect(() => {
    const target = titleInputRef.current

    if (!target) return
    target.style.width = '16px'
    target.style.width = `${target.scrollWidth}px`
  }, [title])

  return (
    <div
      className="w-full h-[calc(100vh-7.5rem)] overflow-visible sm:-mt-4"
      onClick={handleBackgroundClick}
    >
      <div className={`flex justify-center ${panelToolOpen ? 'w-full' : ''}`}>
        <div
          className={`shrink-0 flex flex-grow justify-center group transition-all ease-in-out duration-500 mx-auto`}
        >
          <div
            className={`shrink-0 flex-grow w-max bg-white/20 max-w-[56.25rem] relative overflow-hidden`}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
              setTimestampLastInteraction(getTimestamp())
              if (cursorToolOpen) {
                dispatch(setCursorTool(null))
              }
              const activeSelection = window.getSelection()?.toString()
              if (activeSelection !== selectedText) {
                dispatch(setSelectedText(activeSelection || ''))
              }
              if (e.key === '/') {
                const currentBlock =
                  editorInstance.current?.blocks.getBlockByIndex(
                    editorInstance.current.blocks.getCurrentBlockIndex()
                  )
                currentBlock?.call('checkText')
              }
            }}
            onClick={() => {
              const activeSelection = window.getSelection()?.toString()
              if (activeSelection !== selectedText) {
                dispatch(setSelectedText(activeSelection || ''))
              }
            }}
            onMouseMove={(evt) => {
              setTimestampLastInteraction(getTimestamp())
              if (!panelToolOpen) {
                dispatch(setToolCursor({ x: evt.pageX, y: evt.pageY }))
              }
              if (cursorToolOpen) {
                dispatch(setCursorTool(null))
              }
            }}
          >
            <div className="relative mb-1">
              {dossier ? (
                <>
                  <div className="inset-x-0 mx-auto w-fit">
                    <DossierBreadcrumb
                      dossier={dossier}
                      component={
                        <div className="flex gap-2">
                          <input
                            ref={titleInputRef}
                            className={`focus:outline-none font-body-strong !bg-transparent ${!title ? '!w-[8.4375rem]' : ''} max-w-[31.25rem]`}
                            value={title}
                            onChange={(e) => {
                              handleTitleChange(e.target.value)
                            }}
                            placeholder="Untitled document"
                          />

                          <ReportDropdownMenu
                            reportId={reportId}
                            reportTitle={title}
                          />
                        </div>
                      }
                    />
                  </div>
                </>
              ) : (
                <div className="flex gap-2 items-center px-2 py-3 h-fit mx-auto w-fit">
                  <input
                    style={{ backgroundImage: `url(${texture})` }}
                    className="bg-blue-200 max-w-[56.25rem] text-center border-none font-body-strong focus-visible:ring-0 focus:outline-none text-system-primary"
                    value={title}
                    onChange={(e) => {
                      handleTitleChange(e.target.value)
                    }}
                    placeholder="Untitled document"
                  />
                  <ReportDropdownMenu reportId={reportId} reportTitle={title} />
                </div>
              )}
            </div>
            <div className="bg-system-secondary border border-system-border-light rounded-lg overflow-auto">
              <div className="sticky top-0 z-[2]">
                <PreviewSources
                  documents={citedDocuments}
                  loading={false}
                  compact={false}
                  className="pt-8 px-10 pb-4 bg-system-secondary rounded-t-lg"
                  sourceType="report"
                />
              </div>
              <div>
                <Editor
                  data={report}
                  instance={editorInstance.current}
                  setInstance={(instance: EditorJS) => {
                    editorInstance.current = instance
                  }}
                  cleanup={() => {
                    editorInstance?.current?.destroy()
                    editorInstance.current = undefined
                  }}
                  handleChange={(data: OutputData) => {
                    handleReportChange(data)
                  }}
                  onBlur={() => {
                    setLastFocusedBlockIndex(
                      editorInstance.current?.blocks.getCurrentBlockIndex()
                    )
                  }}
                />
              </div>
            </div>
          </div>

          <div
            style={{ width: panelToolOpen ? `${toolPanelWidth}px` : '0px' }}
            className="transition-width ease-in-out duration-300 z-[3]"
          >
            {panelToolOpen && (
              <div
                style={{ width: panelToolOpen ? `${toolPanelWidth}px` : '0px' }}
                className={`z-10 shrink flex-grow`}
              >
                <ToolPanel
                  show={panelToolOpen}
                  hide={() => {
                    dispatch(setToolComponent(null))
                  }}
                  offset={panelTool.cursor.y}
                  component={
                    <Picker
                      reportId={reportId}
                      data={report}
                      componentType={panelTool.componentType}
                      extractedEntities={extractedEntities}
                      dossierDetail={dossierDetail}
                      addToDocument={addAssistantResponse}
                      addToDocumentInline={addAssistantResponseInline}
                    />
                  }
                />
              </div>
            )}
          </div>

          <div
            ref={sourceContainerRef}
            className={`${uniqueCitationDocuments.length > 0 || selectedSource ? 'w-[31.25rem] laptop:w-[40rem] max-w-[calc(100vw-15rem-44.375rem-1.5rem)]' : 'w-0'} relative transition-width ease-in-out duration-300`}
          >
            <div
              className={`absolute -left-20 top-0 w-[31.25rem] laptop:w-[40rem] max-w-[calc(100vw-15rem-44.375rem+3rem)] max-h-[calc(100vh-14rem-1.5rem)] overflow-y-auto ease-in-out duration-300 ${selectedSource ? 'hidden' : ''}`}
              style={{ marginTop: `10rem` }}
            >
              <Sources
                documents={uniqueCitationDocuments}
                showTabs={false}
                onSourceClick={handleSourceClick}
                previewable={true}
              />
            </div>

            {selectedSource && openedCitationResource && (
              <div
                className="fixed right-[3.25rem] top-[14rem]"
                style={{
                  width: `${sourceContainerWidth + window.innerWidth - sourceContainerRight - 52 + 80}px`,
                }}
              >
                <DocumentPreviewContainer
                  key={`document-preview-container-${selectedSource.document_id}`}
                  type={DocumentPreviewType.REPORT}
                  resource={openedCitationResource}
                  selectedExtractIndex={
                    selectedExtractIndex[selectedSource.document_id] || 0
                  }
                  setSelectedExtractIndex={handleSetSelectedExtractIndex}
                  sources={uniqueCitationDocuments}
                  selectedSource={selectedSource}
                  initialWidth={
                    sourceContainerWidth +
                    window.innerWidth -
                    sourceContainerRight -
                    52 +
                    80 -
                    32 -
                    48
                  }
                  onClose={handleBackgroundClick}
                  setSelectedSource={(v) => setSelectedSource(v)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <SaveControl
        className="fixed top-20 lg:right-[7.5rem] sm:right-[3.75rem]"
        editReport={editReport}
        handleSave={handleSave}
        handleExtract={handleExtract}
      />
    </div>
  )
}
