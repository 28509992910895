import { SearchConnectors } from '@/types/types'

export const getSearchConnectors = (searchConnectors: SearchConnectors) => {
  const connectors: {
    capiq?: string[]
    internal?: string[]
    company_house?: string[]
  } = {}

  if (searchConnectors.internalSearch) {
    connectors.internal = []
  }

  if (searchConnectors.outlookSearch) {
    connectors.internal = [...(connectors.internal || []), 'msft-outlook']
  }

  if (searchConnectors.teamsSearch) {
    connectors.internal = [...(connectors.internal || []), 'msft-teams']
  }

  if (searchConnectors.companiesHouseSearch) {
    connectors.company_house = [...(connectors.company_house || []), 'filings']
  }

  if (searchConnectors.filingsSearch) {
    connectors.capiq = [...(connectors.capiq || []), 'filings']
  }

  if (searchConnectors.transcriptsSearch) {
    connectors.capiq = [...(connectors.capiq || []), 'transcripts']
  }

  return connectors
}
