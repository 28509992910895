import { ArrowLeft, CircleHelp, Loader2, Plus, X } from 'lucide-react'
import { Button } from '../ui/button'
import { Link, useBlocker, useNavigate } from 'react-router-dom'
import { TypographyBody, TypographyH3, TypographyLabel } from '../ui/Typography'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form, FormControl, FormField, FormItem, FormMessage } from '../ui/form'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Textarea } from '../ui/textarea'
import { getIntegrationName, isValidUrl, plural } from '@/utils/utils'
import {
  Integration,
  IntegrationCode,
  IntegrationGreenlistedStructure,
} from '@/types/types'
import { CustomTooltip } from '../CustomTooltip'
import { IntegrationWalkthroughDialog } from './IntegrationWalkthroughDialog'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store/store'
import {
  fetchGreenlistingStructures,
  greenlistingApprove,
  greenlistingCleanup,
  greenlistingDelete,
  greenlistingUpsert,
} from './integrationThunk'
import { isFulfilled } from '@reduxjs/toolkit'
import Divider from '../ui/divider'
import { getFileIcon } from '@/utils/components'
import { IntegrationGreenlistingConfirmDialog } from './IntegrationGreenlistingConfirmDialog'
import { fetchIntegrationStatus } from './integrationSlice'

export const IntegrationGreenlisting = ({
  integration,
  isManage,
}: {
  integration: Integration
  isManage?: boolean
}) => {
  const [showWalkthroughDialog, setShowWalkthroughDialog] = useState(false)
  const [elements, setElements] = useState<IntegrationGreenlistedStructure[]>(
    []
  )
  const [initialElements, setInitialElements] = useState<
    IntegrationGreenlistedStructure[]
  >([])
  const [showInput, setShowInput] = useState(isManage ? false : true)
  const [loading, setLoading] = useState(false)
  const [shouldBlock, setShouldBlock] = useState(isManage ? false : true)
  const [shouldResetBlocker, setShouldResetBlocker] = useState(true)

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      shouldBlock && currentLocation.pathname !== nextLocation.pathname
  )

  const formSchema = z.object({
    links: z.string().min(1, 'Please enter some links'),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      links: '',
    },
  })

  const formValues = form.watch()

  const formRef = useRef<HTMLFormElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const links = values.links.split('\n')

    setLoading(true)

    const result = await dispatch(
      greenlistingUpsert({
        integration_id: integration.integration_id,
        element_raw_ids: links,
      })
    )

    setLoading(false)

    if (isFulfilled(greenlistingUpsert)(result)) {
      const payload = result.payload
      const validElements = payload.filter((v) => v.is_valid)
      const invalidElements = payload.filter((v) => !v.is_valid)
      const uniqueElements = validElements.filter(
        (v) => !elements.some((e) => v.element_raw_id === e.element_raw_id)
      )

      setElements([...elements, ...uniqueElements])
      setShouldBlock(true)

      if (invalidElements.length > 0) {
        const invalidLinks = invalidElements.map((v) => v.element_raw_id)

        form.setValue('links', invalidLinks.join('\n'))
        form.setError('links', {
          message:
            'We could not find any items associated with the following links.\nPlease correct or remove the items to continue.',
        })
      } else {
        form.reset()
        setShowInput(false)
      }
    }
  }

  const handleRemoveElement = (element: IntegrationGreenlistedStructure) => {
    setElements(
      elements.filter((v) => v.greenlisted_id !== element.greenlisted_id)
    )

    if (element.greenlisted_id) {
      dispatch(
        greenlistingDelete({
          integration_id: integration.integration_id,
          greenlisted_ids: [element.greenlisted_id],
        })
      )
    }
  }

  const handleCleanup = () => {
    dispatch(greenlistingCleanup(integration.integration_id))
  }

  const handleDiscard = () => {
    elements.forEach((element) => {
      handleRemoveElement(element)
    })
  }

  const handleApprove = async () => {
    const ids = elements
      .map((v) => v.greenlisted_id)
      .filter((v): v is number => Boolean(v))

    await dispatch(
      greenlistingApprove({
        integration_id: integration.integration_id,
        greenlisted_ids: ids,
      })
    )

    dispatch(fetchIntegrationStatus(integration.integration_id))

    navigate('/integrations/microsoft')
  }

  const getStructures = useCallback(async () => {
    const result = await dispatch(
      fetchGreenlistingStructures(integration.integration_id)
    )

    if (isFulfilled(fetchGreenlistingStructures)(result)) {
      setElements(result.payload)
      setInitialElements(result.payload)
    }
  }, [dispatch, integration.integration_id])

  const getElementName = (element: IntegrationGreenlistedStructure) => {
    const path = isValidUrl(element.element_path || '')
      ? decodeURIComponent(element.element_path || '')
      : element.element_path || ''

    const splitPath = path?.split('/')
    const url = new URL(path)
    const fileName = url.searchParams.get('file') || splitPath.at(-1)

    switch (element.element_type) {
      case 'folder':
        return (splitPath?.slice(-2) || []).join('/')
      case 'file':
        return fileName
      default:
        return (splitPath?.slice(-1) || []).join('/')
    }
  }

  useEffect(() => {
    getStructures()
  }, [getStructures])

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '0px'
      const scrollHeight = textareaRef.current.scrollHeight
      textareaRef.current.style.height = Math.max(120, scrollHeight) + 'px'
    }
  }, [formValues])

  useEffect(() => {
    if (elements.length === 0 && !isManage) {
      setShowInput(true)
    }
  }, [elements, isManage])

  return (
    <>
      <div className="flex flex-col gap-0">
        <Link to={'/integrations/microsoft'}>
          <Button variant="tertiary">
            <div className="flex gap-2 items-center">
              <ArrowLeft className="size-6 shrink-0 stroke-[1.5px]" />
              Microsoft 365
            </div>
          </Button>
        </Link>
        <div className="flex flex-col gap-12 w-[35rem] mx-auto">
          <div className="flex flex-col gap-2 text-center">
            <TypographyH3>
              {isManage
                ? `Manage ${getIntegrationName(integration.integration_code_name)} connected files`
                : 'Add which files/folders to connect'}
            </TypographyH3>

            <TypographyBody className="text-system-body">
              {`File access permissions in our platform will mirror those in ${getIntegrationName(integration.integration_code_name)}`}
            </TypographyBody>
          </div>
          <div className="flex flex-col gap-8">
            {showInput ? (
              <Form {...form}>
                <form
                  ref={formRef}
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="space-y-3"
                >
                  <div className="mx-auto space-y-8 overflow-x-visible">
                    <FormField
                      control={form.control}
                      name="links"
                      render={({ field }) => (
                        <FormItem className="space-y-2">
                          <div className="flex gap-2 items-end">
                            <div className="flex flex-col gap-0 w-full">
                              <TypographyBody isStrong>
                                Files or folders links
                              </TypographyBody>
                              <TypographyLabel className="text-system-body">
                                Enter or paste links, one per line. Adding a
                                folder will include all its content.
                              </TypographyLabel>
                            </div>

                            <Button
                              variant="tertiary"
                              data-tooltip-id="integration-greenlisting-help"
                              className="h-fit"
                              type="button"
                              onClick={() => setShowWalkthroughDialog(true)}
                            >
                              <CircleHelp className="size-6 shrink-0 stroke-[1.5px]" />
                            </Button>
                          </div>
                          <FormMessage className="whitespace-pre-wrap" />
                          <FormControl>
                            <Textarea
                              {...field}
                              ref={textareaRef}
                              className="break-all"
                              onChange={(e) => {
                                form.setValue('links', e.target.value)
                                form.clearErrors('links')
                              }}
                              placeholder={
                                integration.integration_code_name ===
                                IntegrationCode.ONEDRIVE
                                  ? 'e.g. https://drv.ms/f/c/6e5abb/EgXEVcWWN7Xcu_AQ?e=LLskd'
                                  : 'e.g. https://domain.sharepoint.com/:f:/s/Technology/....'
                              }
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>

                  {formValues.links && (
                    <div className="w-fit mx-auto">
                      <Button variant="secondary" type="submit">
                        <div className="flex gap-2 items-center">
                          Add items
                          {loading && (
                            <Loader2 className="size-6 shrink-0 stroke-[1.5px] animate-spin" />
                          )}
                        </div>
                      </Button>
                    </div>
                  )}
                </form>
              </Form>
            ) : (
              <Button
                variant="secondary"
                className="w-fit"
                onClick={() => setShowInput(true)}
              >
                <div className="flex gap-2 items-center">
                  <Plus className="size-6 shrink-0 stroke-[1.5px]" />
                  Add items
                </div>
              </Button>
            )}

            {elements.length > 0 && (
              <>
                <Divider />

                <div className="flex flex-col gap-4">
                  <TypographyBody className="text-system-body">
                    {`${elements.length} ${plural('item', elements.length)} ${isManage ? 'connected' : 'to connect:'}`}
                  </TypographyBody>
                  <div className="flex flex-col gap-2">
                    {elements.map((element) => {
                      const path = isValidUrl(element.element_path || '')
                        ? decodeURIComponent(element.element_path || '')
                        : element.element_path || ''
                      const url = new URL(path)
                      const fileName =
                        url.searchParams.get('file') || path.split('/').at(-1)
                      const fileExtension = fileName?.split('.').at(-1) || ''

                      return (
                        <div
                          key={`greenlisted-element-${element.greenlisted_id}`}
                          className="flex gap-2 items-center p-3 bg-system-secondary border border-system-border-light rounded-sm"
                        >
                          {getFileIcon(
                            element.element_type === 'file'
                              ? fileExtension
                              : element.element_type || '',
                            '!size-6 shrink-0'
                          )}

                          <TypographyBody className="w-full line-clamp-1 break-all">
                            {getElementName(element)}
                          </TypographyBody>

                          <Button
                            variant="tertiary"
                            size="fit"
                            onClick={() => handleRemoveElement(element)}
                          >
                            <X className="size-6 shrink-0 stroke-[1.5px]" />
                          </Button>
                        </div>
                      )
                    })}
                  </div>
                </div>

                {(!isManage || initialElements !== elements) && (
                  <div className="flex gap-2 mx-auto">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setShouldBlock(false)
                        handleDiscard()
                      }}
                    >
                      Discard changes
                    </Button>

                    <Button
                      onClick={() => {
                        setShouldBlock(false)
                        handleApprove()
                      }}
                    >
                      <div className="flex gap-2 items-center">
                        {isManage ? 'Confirm changes' : 'Finish and connect'}
                      </div>
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <CustomTooltip
        id="integration-greenlisting-help"
        className="!py-1 !px-3 !rounded-sm"
        largeArrow={false}
      >
        <TypographyLabel className="text-system-body">
          {`How to get ${getIntegrationName(integration.integration_code_name)} links`}
        </TypographyLabel>
      </CustomTooltip>

      <IntegrationWalkthroughDialog
        open={showWalkthroughDialog}
        setOpen={setShowWalkthroughDialog}
        integrationCode={integration.integration_code_name}
      />

      <IntegrationGreenlistingConfirmDialog
        open={blocker.state === 'blocked'}
        onConfirm={() => {
          setShouldResetBlocker(false)
          handleCleanup()

          blocker?.proceed?.()

          // fixes "router allows one blocker at a time" error
          setTimeout(() => {
            blocker?.reset?.()
          }, 500)
        }}
        onCancel={() => {
          setTimeout(() => {
            if (shouldResetBlocker) {
              blocker?.reset?.()
            }

            setShouldResetBlocker(true)
          }, 1)
        }}
      />
    </>
  )
}
