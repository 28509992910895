import { Plus, X } from 'lucide-react'
import { Button } from '../ui/button'
import { useEffect, useState } from 'react'
import Checkmark from '@/assets/Checkmark'
import {
  DragDropContext,
  Droppable,
  DropResult,
} from '@hello-pangea/dnd'
import { ListInputDraggable } from './ListInputDraggable'

export const ListInput = ({
  values,
  placeholder,
  disabled = false,
  onChange,
  onEditFocus,
}: {
  values: string[]
  placeholder?: string
  disabled?: boolean
  onChange: (values: string[]) => void
  onEditFocus?: (focused: boolean) => void
}) => {
  const [showNewField, setShowNewField] = useState(false)
  const [newFieldText, setNewFieldText] = useState('')

  const nonEmptyValues = values.filter((v) => v)

  const reorder = (list: string[], startIndex: number, endIndex: number) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const items = reorder(values, result.source.index, result.destination.index)

    onChange(items)
  }

  useEffect(() => {
    onEditFocus?.(showNewField)
  }, [showNewField])

  return (
    <div className="flex flex-col gap-2 bg-system-secondary border border-system-border-regular rounded-md p-2">
      {nonEmptyValues.length > 0 && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" isDropDisabled={disabled}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={`${snapshot.isDraggingOver ? 'pointer-events-none' : ''}`}
              >
                <div className="flex flex-col gap-2">
                  {values.map((item, index) => (
                    <ListInputDraggable
                      key={`list-input-${index}-${item}`}
                      values={values}
                      item={item}
                      index={index}
                      disabled={disabled}
                      onChange={onChange}
                    />
                  ))}
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {showNewField && (
        <div className="flex gap-4 px-3 py-1.5 bg-system-surface-light border border-system-border-light rounded-sm">
          <input
            value={newFieldText}
            onChange={(e) => setNewFieldText(e.target.value)}
            className="font-body focus:outline-none placeholder:text-system-placeholder bg-transparent w-full"
            placeholder={
              placeholder ||
              'e.g. Include: Instrument type (e.g., Seed Preferred Shares)'
            }
          />
          <div className="flex gap-2">
            <Button
              variant="tertiary"
              onClick={() => {
                setShowNewField(false)
                setNewFieldText('')
              }}
            >
              <X className="size-6 shrink-0 stroke-interactive" />
            </Button>

            <Button
              variant="tertiary"
              onClick={() => {
                onChange([...values, newFieldText])

                setShowNewField(false)
                setNewFieldText('')
              }}
            >
              <Checkmark className="size-6 shrink-0 stroke-interactive" />
            </Button>
          </div>
        </div>
      )}
      {!showNewField && !disabled && (
        <div className="flex px-2 pb-1 pt-2 items-center">
          <Button
            variant="tertiary"
            onClick={(e) => {
              e.stopPropagation()
              setShowNewField(true)
            }}
          >
            <div className="flex gap-2 items-center">
              <Plus className="size-6 shrink-0 stroke-interactive" />
              Add
            </div>
          </Button>
        </div>
      )}
    </div>
  )
}
