import { MessageCircleMore } from 'lucide-react'
import { TypographyBody } from '../ui/Typography'
import { ChatList } from '../ChatList'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import { memo, useEffect, useMemo, useRef, useState } from 'react'

export const PastChats = memo(() => {
  const [shouldStayOpen, setShouldStayOpen] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const assistantStore = useSelector((state: RootState) => state.assistant)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect()
        const isWithin =
          e.clientX >= rect.left &&
          e.clientX <= rect.right &&
          e.clientY >= rect.top &&
          e.clientY <= rect.bottom

        if (!isWithin && !dropdownOpen) {
          setShouldStayOpen(false)
        }
      }
    }

    window.addEventListener('mousemove', handleMouseMove)

    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
    }
  }, [dropdownOpen])

  return (
    <div
      className={`fixed top-[3.75rem] group z-[5] left-0 ${shouldStayOpen ? 'pointer-events-auto' : 'pointer-events-none'}`}
      ref={containerRef}
    >
      <div
        className={`flex gap-0 transition-transform duration-200 ease-in-out ${shouldStayOpen ? 'translate-x-0' : '-translate-x-[calc(100%-11.3rem)]'}`}
      >
        <div
          className={`flex flex-col gap-0 border-r border-system-border-regular bg-system-surface max-w-[25rem] w-[25rem]`}
          onMouseEnter={() => setShouldStayOpen(true)}
        >
          <div className="p-6 overflow-y-auto h-[calc(100vh-3.75rem)]">
            <ChatList
              threads={assistantStore.list}
              loading={assistantStore.list.length === 0}
              error={null}
              type="past-chat"
              onDropdownOpen={(v) => {
                setDropdownOpen(v)
              }}
            />
          </div>
        </div>
        <div
          onMouseEnter={() => setShouldStayOpen(true)}
          className={`flex !pointer-events-auto -rotate-90 -translate-x-[4.2rem] mt-[6.125rem] gap-2 h-fit`}
        >
          <div className="flex gap-2 mb-auto pt-3 pb-2 px-6 h-fit rounded-b-lg bg-system-secondary group-hover:border-t-0 border border-system-border-regular">
            <MessageCircleMore className="size-6 shrink-0 stroke-[1.5px]" />

            <TypographyBody isStrong={true}>Recent chats</TypographyBody>
          </div>
        </div>
        <div
          onMouseEnter={() => setShouldStayOpen(true)}
          className="absolute top-[2.25rem] left-[25rem] h-[11rem] w-[calc(((100vw-46.875rem)/6))] pointer-events-auto"
        ></div>
      </div>
    </div>
  )
})

export const PastChatsContainer = () => {
  const pastChats = useMemo(() => {
    return <PastChats />
  }, [])
  return pastChats
}
