import { useContext } from "react"
import { CustomAlert } from "../CustomAlert"
import { ToastContext } from "@/contexts/ToastContext"

export const Toast = ({ id }: { id: string }) => {
    const toastContext = useContext(ToastContext)

    return (
        <div className={`absolute top-[4.75rem] right-0 pr-4 transition-transform duration-200 ease-in-out z-[40] ${toastContext.isToastShown ? 'translate-x-0' : 'translate-x-full'}`}>
            {(toastContext.shownToast?.toastId === id || (id === 'global' && !toastContext.shownToast?.toastId)) && (
                <CustomAlert
                    className="max-w-[40rem] [&_p]:max-w-[35rem] overflow-hidden text-ellipsis"
                    {...{
                        ...toastContext.shownToast,
                        onCloseClick: () => {
                            toastContext.shownToast?.onCloseClick?.()
                            toastContext.closeToast()
                        }
                    }}
                />
            )}
        </div>
    )
}

export const DialogToast = ({ id }: { id: string }) => {
    const toastContext = useContext(ToastContext)

    return (
        <div className={`absolute top-3 left-[50%] -translate-x-[50%] transition-transform duration-200 ease-in-out z-[40] ${toastContext.isToastShown ? 'translate-y-0' : '-translate-y-[calc(100%+0.75rem)]'}`}>
            {(toastContext.shownToast?.toastId === id || (id === 'global' && !toastContext.shownToast?.toastId)) && (
                <CustomAlert
                    className="max-w-[40rem] [&_p]:max-w-[35rem] overflow-hidden text-ellipsis"
                    {...{
                        ...toastContext.shownToast,
                        onCloseClick: () => {
                            toastContext.shownToast?.onCloseClick?.()
                            toastContext.closeToast()
                        }
                    }}
                />
            )}
        </div>
    )
}