import { useCallback, useContext, useEffect } from 'react'
import {
  formatFocusedAnalysisFiles,
  getSettingsSnapshot,
  getTimestamp,
  isUploadFileEnabled,
  withSettingsSnapshot,
} from '../../utils/utils'
import { useSocketQuery } from '../../hooks/useSocketQuery'
import {
  AssistantAskMode,
  DESIA_EVENT,
  RequestAssistantAsk,
  ResponseChatDetails,
  ResponseChatStream,
  WebSocketRequestWrapper,
  SourceType,
} from '../../types/types'
import { useNavigate, useParams } from 'react-router-dom'
import { Conversation } from './Conversation'
import { UserContext } from '../../contexts/UserContext'
import { ErrorMessage } from '../ErrorMessage'
import { getAskConnectors, getAskToolEvents, getAskTools } from '@/utils/ask'
import { Skeleton } from '../ui/skeleton'
import { ResponsiveContainer } from './ResponsiveContainer'
import type { RootState, AppDispatch } from '../../store/store'
import { useSelector, useDispatch } from 'react-redux'
import { actions as assistantActions } from './assistantSlice'
import { getLiveConversationById } from './utils'
import { fetchDossierDetail } from '../Dossier/dossierSlice'
import { generateConversationTitle } from './assistantThunk'
import { isFulfilled } from '@reduxjs/toolkit'

function ConversationNotFound() {
  const navigate = useNavigate()
  return (
    <div>
      Conversation not found...
      <button
        onClick={() => {
          navigate('/assistant/ask')
        }}
      >
        Ask new question
      </button>
    </div>
  )
}

function ConversationLoadFailed({ message }: { message: string }) {
  return (
    <div className="w-fit mx-auto">
      <ErrorMessage
        message={`We failed to load that conversation (${message}). Our engineers have been informed. Try again later. `}
      />
    </div>
  )
}

export function ExistingConversation() {
  const dossiers = useSelector((state: RootState) => state.dossier.dossiers)
  const dossierDetails = useSelector(
    (state: RootState) => state.dossier.dossierDetails
  )
  const assistantStore = useSelector((state: RootState) => state.assistant)
  const dispatch = useDispatch<AppDispatch>()

  const { user, settings, updateSettings } = useContext(UserContext)
  const { conversation_id, dossier_id } = useParams()

  const requestId = conversation_id || 'requestId' // fixme
  const currentConversation = assistantStore.list.find(
    (b) => b.conversationId === conversation_id
  )
  const dossierConversation = dossierDetails[
    dossier_id || ''
  ]?.data?.conversations.find((v) => v.id === conversation_id)
  const dossier = dossiers.data?.find((v) => v.id === dossier_id)
  const sourceType: SourceType = dossier ? 'dossier' : 'ask'

  const { executeQuery, state: queryState } = useSocketQuery({
    event: DESIA_EVENT.CHAT_ASK,
    request: {
      requestId: requestId,
      timestamp: getTimestamp(),
      params: {},
    },
    options: {
      manual: true,
      callback: (response) => {
        const res = response.data as ResponseChatStream // fixme
        dispatch(
          assistantActions.streamingResponse({
            ...res,
            requestId: response.requestId,
            timestamp: response.timestamp,
          })
        )

        if (res.event_type === 'stream-end') {
          handleGenerateTitle()
        }
      },
    },
  })

  // todo: error handling
  const { executeQuery: fetchConversation, state: chatDetailsState } =
    useSocketQuery({
      event: DESIA_EVENT.CHAT_DETAILS,
      request: {
        requestId: requestId,
        timestamp: getTimestamp(),
        params: {},
        conversationId: conversation_id, // todo: type as WebSocketRequest and place params in obj
      },
      options: {
        manual: true,
        cacheTimeoutMs: 1_000 * 60,
        callback: (res) => {
          if (!res.error && res.data.messages) {
            dispatch(
              assistantActions.fetchConversation(
                res.data as ResponseChatDetails
              )
            )
          }
        },
      },
    })

  const conversation = getLiveConversationById({
    store: assistantStore,
    conversationId: conversation_id || '',
    requestId,
  })

  const handleAsk = useCallback(
    ({
      message,
      mode,
      playbookId,
      playbookVars,
    }: {
      message: string
      mode: AssistantAskMode
      playbookId?: string
      playbookVars?: string[]
    }) => {
      const timestamp = getTimestamp()
      dispatch(
        assistantActions.followUpAsk({
          conversationId: conversation_id!, // fixme
          requestId: `${requestId}_followup_${conversation.length}`,
          question: message,
          timestamp,
          mode,
          playbook: playbookId
            ? {
                playbook_id: playbookId,
                playbook_vars: playbookVars || [],
              }
            : undefined,
          tool_events: getAskToolEvents(settings.assistant.sources[sourceType]),
        })
      )

      const fileIds = formatFocusedAnalysisFiles([
        ...(settings.assistant.sources[sourceType].files.map((v) => v.id) ||
          []),
        ...(settings.assistant.sources[sourceType].companiesHouseFiles || []),
        ...((isUploadFileEnabled(sourceType) &&
          settings.assistant.sources[sourceType].uploadedFiles.map(
            (v) => v.id
          )) ||
          []),
      ])

      const request: WebSocketRequestWrapper<RequestAssistantAsk> = {
        requestId: `${requestId}_followup_${conversation.length}`,
        timestamp,
        params: {
          message: message,
          conversationId: conversation_id,
          connectorsV2: getAskConnectors(
            settings.assistant.sources[sourceType]
          ),
          model: settings.assistant.parameters?.model,
          seed: settings.assistant.parameters?.seed,
          temperature: settings.assistant.parameters?.temperature,
          systemPrompt: settings.assistant.parameters?.systemPrompt,
          mode: mode,
          focusedAnalysis:
            settings.assistant.sources[sourceType].focusedAnalysis,
          fileIDs: settings.assistant.sources[sourceType].focusedAnalysis
            ? fileIds
            : [],
          ...getAskTools(settings.assistant.sources[sourceType]),
          playbook: playbookId
            ? {
                playbook_id: playbookId,
                playbook_vars: playbookVars || [],
              }
            : undefined,
          exclude_domains: settings.assistant.sources[sourceType].webSearch
            ? user?.preferences?.[sourceType]?.excluded_websites
            : undefined,
        },
      }

      withSettingsSnapshot({
        event: DESIA_EVENT.CHAT_ASK,
        conversationId: conversation_id!,
        settings: settings,
        callbackFn: () => {
          executeQuery({
            event: DESIA_EVENT.CHAT_ASK,
            request,
          })
        },
      })
    },
    [
      conversation,
      requestId,
      settings.assistant.parameters,
      settings.assistant.sources,
      sourceType,
      dispatch,
      executeQuery,
    ]
  )

  const handleGenerateTitle = useCallback(async () => {
    if (!conversation_id) return
    const result = await dispatch(generateConversationTitle(conversation_id))

    if (isFulfilled(generateConversationTitle)(result)) {
      dispatch(
        assistantActions.chatRename({
          conversationId: result.payload.conversationId,
          title: result.payload.title,
        })
      )
    }
  }, [conversation_id, dispatch])

  useEffect(() => {
    if (conversation_id) {
      fetchConversation({
        event: DESIA_EVENT.CHAT_DETAILS,
        request: {
          requestId: conversation_id,
          timestamp: new Date().getTime(),
          conversationId: conversation_id,
          params: {},
        },
      })

      const snapshot = getSettingsSnapshot({
        event: DESIA_EVENT.CHAT_ASK,
        conversationId: conversation_id,
      })

      if (snapshot && snapshot.settings) {
        updateSettings({
          settings: {
            ...settings,
            assistant: {
              ...settings.assistant,
              sources: {
                ...settings.assistant.sources,
                [sourceType]: {
                  ...snapshot.settings.assistant.sources[sourceType],
                },
              },
            },
          },
        })
      }
    }
  }, [conversation_id])

  useEffect(() => {
    if (!dossier) return
    dispatch(fetchDossierDetail(dossier.id))
  }, [dossier, dispatch])

  useEffect(() => {
    document.title = 'Desia AI - Ask'
  }, [])

  if (!conversation_id) {
    return <ConversationNotFound />
  }

  if (chatDetailsState.error) {
    return <ConversationLoadFailed message={chatDetailsState.error} />
  }

  if (chatDetailsState.loading) {
    return (
      <ResponsiveContainer>
        <div className="max-w-[46.875rem] mx-auto">
          <Skeleton className="w-full h-[1.875rem] my-2" />

          <Skeleton className="w-4/6 h-[1.25rem] my-2" />
          <Skeleton className="w-full h-[1.25rem] my-2" />
          <Skeleton className="w-full h-[1.25rem] my-2" />
          <Skeleton className="w-5/6 h-[1.25rem] my-2" />
        </div>
      </ResponsiveContainer>
    )
  }

  return (
    <Conversation
      handleAsk={handleAsk}
      conversation={conversation}
      queryState={queryState}
      sources={settings.assistant.sources[sourceType]}
      dossier={dossier}
      dossierDetail={dossierDetails[dossier?.id || '']?.data || undefined}
      title={currentConversation?.query || dossierConversation?.title}
    />
  )
}
