import { Button } from "../ui/button"
import { ASYNC_STATUS, Integration, IntegrationCode, IntegrationStatus } from "@/types/types"
import { useNavigate } from "react-router-dom"
import { Progress } from "../ui/progress"
import { TypographyBody, TypographyH4, TypographyLabel } from "../ui/Typography"
import CheckmarkCircleFill from "@/assets/CheckmarkCircleFill"
import CloseCircleFill from "@/assets/CloseCircleFill"
import { getIntegrationName, plural } from "@/utils/utils"
import { getIntegrationIcon } from "@/utils/components"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "@/store/store"
import { cancelSynchronization, disableCancelUndoChanges } from "./integrationSlice"
import { Loader2 } from "lucide-react"

export interface IntegrationCardProps {
    integration: Integration
    detail: IntegrationStatus | null
}

export const IntegrationCard = ({ integration, detail }: IntegrationCardProps) => {
    const cancelSynchronizations = useSelector((state: RootState) => state.integration.cancelSynchronization)

    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()

    const processingCount = (detail?.synchronization_details.available_count || 0) + (detail?.synchronization_details.failed_count || 0)
    const isProcessing = integration.integration_has_setup && detail?.synchronization_details.files_to_synchronize_count && processingCount !== detail?.synchronization_details.files_to_synchronize_count
    const hasError = integration.integration_has_setup && (detail?.synchronization_details.failed_count || 0) > 0
    const cancelSyncStatus = detail && cancelSynchronizations[detail?.integration_details.integration_id]?.status

    const showManageButton = integration.integration_code_name === IntegrationCode.ONEDRIVE || integration.integration_code_name === IntegrationCode.SHAREPOINT

    const cancelSync = async () => {
        dispatch(disableCancelUndoChanges(integration.integration_id))

        dispatch(cancelSynchronization({
            integration_id: integration.integration_id
        }))
    }

    const getStatusText = () => {
        switch (integration.integration_code_name) {
            case IntegrationCode.ONEDRIVE:
            case IntegrationCode.SHAREPOINT: return (detail?.synchronization_details.available_count || 0) > 0 ? `${detail?.synchronization_details.available_count || 0} ${plural('file', detail?.synchronization_details.available_count || 0)} connected` : `No files connected yet`
            case IntegrationCode.TEAMS: return 'Ask users to enable Teams in Settings > Connected apps'
            case IntegrationCode.OUTLOOK: return 'Ask users to enable Outlook in Settings > Connected apps'
            default: return ''
        }
    }

    return (
        <div className="h-[8rem] w-full bg-system-surface-light border border-system-border-light rounded-[16px]">
            <div className="flex gap-4 px-6 h-full items-center">
                <div className="size-8 shrink-0 flex items-center justify-center border border-system-border-light rounded-sm bg-system-secondary">
                    {getIntegrationIcon(integration.integration_code_name, true, 'w-5')}
                </div>

                <div className="flex flex-col gap-0 w-full">
                    <div className="flex flex-col gap-0 overflow-hidden">
                        <TypographyH4 className="line-clamp-1 break-all">
                            {getIntegrationName(integration.integration_code_name) + (showManageButton ? ' connected files' : '')}
                        </TypographyH4>

                        {!hasError && !isProcessing && (
                            <TypographyBody className="text-system-body mt-1 line-clamp-1 break-all">
                                {getStatusText()}
                            </TypographyBody>
                        )}

                        {isProcessing && (
                            <div className="flex flex-col gap-2 w-full mt-4">
                                <div className="flex flex-col gap-3">
                                    <Progress value={processingCount / (detail?.synchronization_details.files_to_synchronize_count || 0) * 100} />

                                    <TypographyLabel className="text-system-body">
                                        {`Updating ${processingCount} / ${detail?.synchronization_details.files_to_synchronize_count} files`}
                                    </TypographyLabel>
                                </div>
                            </div>
                        )}

                        {hasError && !isProcessing && (
                            <div className="flex gap-6 items-center mt-2">
                                <div className="flex gap-2">
                                    <CheckmarkCircleFill className="w-6 h-6 shrink-0" />
                                    <TypographyBody className="line-clamp-1 break-all">
                                        {`${detail?.synchronization_details.available_count || 0} ${plural('file', detail?.synchronization_details.available_count || 0)} processed`}
                                    </TypographyBody>
                                </div>
                                <div className="flex gap-2">
                                    <CloseCircleFill className="w-6 h-6 shrink-0" />
                                    <TypographyBody className="line-clamp-1 break-all">
                                        {`${detail?.synchronization_details.failed_count || 0} ${plural('file', detail?.synchronization_details.available_count || 0)} failed to process`}
                                    </TypographyBody>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {cancelSyncStatus === ASYNC_STATUS.loading && (
                    <div className="flex gap-2">
                        <Loader2 className="size-6 shrink-0 animate-spin" />

                        <TypographyBody className="text-system-body">
                            Cancelling file changes...
                        </TypographyBody>
                    </div>
                )}

                {detail && detail.cancel_undo_changes_available && cancelSyncStatus !== ASYNC_STATUS.loading && (
                    <Button variant='secondary' onClick={() => cancelSync()}>
                        Cancel changes
                    </Button>
                )}

                {!isProcessing && showManageButton && cancelSyncStatus !== ASYNC_STATUS.loading && (
                    <Button variant='secondary' className="ml-6" onClick={() => {
                        if ((detail?.synchronization_details.available_count || 0) > 0) {
                            navigate(`/integrations/microsoft/${integration.integration_code_name}`)
                        } else {
                            navigate(`/integrations/microsoft/setup/${integration.integration_code_name}`)
                        }
                    }}>
                        {(detail?.synchronization_details.available_count || 0) > 0 ? 'Manage' : 'Add files'}
                    </Button>
                )}
            </div>
        </div>
    )
}