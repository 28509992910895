import { CustomAlert } from '../CustomAlert'

export const ResponseTableError = () => {
  return (
    <div className="flex flex-col gap-4 p-4 mt-10">
      <CustomAlert
        variant="error"
        title="We could not load the table at this time"
        description="We have notified the team and will be fixing the issue soon."
      />
    </div>
  )
}
