import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { Socket } from "socket.io-client";
import { ErrorMessage } from "../components/ErrorMessage";
import { SocketStatus, socketConnection } from "@/utils/SocketConnection";
import { useDispatch } from "react-redux";
import { connected } from "@/features/userSlice";
import { ToastContext } from "./ToastContext";
import logo from "../assets/logo.svg";
import { Button } from "@/components/ui/button";

type WebSocketContext = {
    socket: Socket | null,
    status: SocketStatus,
}

export const WebSocketContext = createContext<WebSocketContext>({ socket: null, status: 'connecting' });

function WebSocketProvider(props: { children: ReactNode }) {
    const [status, setStatus] = useState<SocketStatus>(socketConnection.status);
    const [remainingReconnectAttempts, setRemainingReconnectAttempts] = useState<number>(socketConnection.reconnectAttemptsRemaining);

    const dispatch = useDispatch();
    const { showToast } = useContext(ToastContext);

    function handleRefresh() {
        window.location.reload();
    }

    useEffect(() => {
        if (status === 'connected') {
            dispatch(connected());
            showToast({
                variant: 'success',
                description: 'Connected to Desia',
                dismissable: true
            });
            return;
        }
        if (status === 'reconnecting') {
            showToast({
                variant: 'warning',
                description: `Reconnecting to Desia (attempt ${socketConnection.maxReconnectAttempts - remainingReconnectAttempts + 1} / ${socketConnection.maxReconnectAttempts})`,
                dismissable: false,
            }, 1_000 * 60);
            return;
        }

    }, [status, remainingReconnectAttempts]);

    useEffect(() => {
        if (['error', 'disconnected', 'idle'].includes(status)) {
            socketConnection.subscribeToStatusChange(setStatus);
            socketConnection.subscribeToReconnectAttemptsRemaining(setRemainingReconnectAttempts);
            socketConnection.connect();
        }
    }, []);

    if (status === 'connecting' || status === 'idle') {
        return <></>;
    }

    if (status === 'error') {
        return (
            <div className="flex flex-col justify-center items-center h-[80vh]">
                <div className="flex flex-col justify-center items-center">
                    <img className="w-[22.5rem] pb-4" src={logo} />
                    <ErrorMessage message={
                        <div className="items-center">
                            <p>We failed to connect you to our server. Our engineers have been informed.</p>
                            <p><Button size={"sm"} variant={"inline"} onClick={handleRefresh}>Refresh</Button> your page to reconnect.</p>
                        </div>} className="py-4 justify-center" />

                </div>
            </div>
        )
    }

    if (status === 'disconnected') {
        return (
            <div className="flex flex-col justify-center items-center h-[80vh]">
                <div className="flex flex-col justify-center items-center">
                    <img className="w-[22.5rem] pb-4" src={logo} />
                    <ErrorMessage message={
                        <div className="items-center">
                            <p>We could not reconnect you to our server. Please wait several seconds and try again. </p>
                            <p><Button size={"sm"} variant={"inline"} onClick={handleRefresh}>Refresh</Button> your page to reconnect when you're ready</p>
                        </div>} className="py-4 justify-center" />

                </div>
            </div>
        )
    }

    return (
        <WebSocketContext.Provider value={{
            socket: socketConnection.socket,
            status: status
        }}>
            {props.children}
        </WebSocketContext.Provider>
    )
}

export {
    WebSocketProvider
}
