import { Search, X } from "lucide-react"
import { useEffect, useState } from "react"
import { SpotlightSearchDialog } from "./Search/SpotlightSearchDialog"

export type SearchbarType = 'navbar' | 'sidebar' | 'search-result'

export const Searchbar = ({ type, initialQuery, autoFocus, handleSearch }: { type: SearchbarType, initialQuery?: string, autoFocus?: boolean, handleSearch: (query: string) => void }) => {
    const [searchQuery, setSearchQuery] = useState(initialQuery || '')
    const [showSpotlightSearchDialog, setShowSpotlightSearchDialog] = useState(false)

    useEffect(() => {
        if (initialQuery) {
            setSearchQuery(initialQuery)
        }
    }, [initialQuery])

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleSearch(searchQuery);

            if (type !== 'search-result') {
                resetSearch()
            }
        }
    }

    const resetSearch = () => {
        setSearchQuery('')
    }

    const CloseButton = () => (
        <X className="w-5 h-5 shrink-0 stroke-interactive absolute right-3 top-2 cursor-pointer" onClick={() => resetSearch()} />
    )

    const style = `relative flex gap-2 flex-grow max-w-[30rem] py-2 px-3 rounded-md border border-system-border-regular ${type === 'search-result' ? 'bg-system-secondary' : 'bg-system-surface-light'}`

    if (type === 'navbar' || type === 'sidebar') return (
        <>
            <div className={`relative flex gap-2 items-center flex-grow max-w-[15rem] py-1 px-2 rounded-full border border-system-border-regular bg-system-surface-light`}>
                <Search className="w-5 h-5 shrink-0 stroke-interactive" />
                <input
                    placeholder="Search across Desia..."
                    className="font-label placeholder:text-system-placeholder bg-transparent w-full focus:outline-none mr-7"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={handleKeyDown}
                    autoFocus={autoFocus}
                    onClick={(e) => {
                        e.preventDefault()
                        setShowSpotlightSearchDialog(true)
                    }}
                />
                {searchQuery && (
                    <CloseButton />
                )}
            </div>

            <SpotlightSearchDialog
                open={showSpotlightSearchDialog}
                setOpen={setShowSpotlightSearchDialog}
            />
        </>
    )

    return (
        <>
            <div className={style}>
                <Search className="w-5 h-5 shrink-0 stroke-interactive" />
                <input
                    placeholder="Search across Desia..."
                    className="font-label hidden [@media(min-width:1170px)]:!flex placeholder:text-system-placeholder bg-transparent w-full focus:outline-none mr-7"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={handleKeyDown}
                    autoFocus={autoFocus}
                />
                <input
                    placeholder="Search across Desia"
                    className="font-label flex [@media(min-width:1170px)]:!hidden placeholder:text-system-placeholder bg-transparent w-full focus:outline-none mr-7"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    onKeyDown={handleKeyDown}
                    autoFocus={autoFocus}
                />
                {searchQuery && (
                    <CloseButton />
                )}
            </div>

            <SpotlightSearchDialog
                open={showSpotlightSearchDialog}
                setOpen={setShowSpotlightSearchDialog}
            />
        </>
    )
}