import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Playbook, QueryStatus } from '../../types/types'
import { handleError } from '@/utils/handleError'
import { fetchPlaybooks } from './playbookThunk'

interface DocumentState {
  playbooks: {
    data: Playbook[]
    status: QueryStatus
  }
}

const initialState: DocumentState = {
  playbooks: {
    data: [],
    status: QueryStatus.INITIALISED,
  },
}
export const playbookSlice = createSlice({
  name: 'playbook',
  initialState,
  reducers: {
    addPlaybook: (state, action: PayloadAction<Playbook>) => {
      state.playbooks.data = [...state.playbooks.data, action.payload]
    },
    removePlaybook: (state, action: PayloadAction<Playbook>) => {
      state.playbooks.data = [...state.playbooks.data].filter(
        (v) => JSON.stringify(v) !== JSON.stringify(action.payload)
      )
    },
    replacePlaybook: (state, action: PayloadAction<Playbook>) => {
      const copy = [...state.playbooks.data]
      const index = copy.findIndex((v) => {
        return (
          JSON.stringify(v.items.map((v) => v.id)) ===
          JSON.stringify(action.payload.items.map((v) => v.id))
        )
      })
      copy[index] = action.payload
      state.playbooks.data = copy
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlaybooks.pending, (state) => {
        state.playbooks.status = QueryStatus.FETCHING
      })
      .addCase(fetchPlaybooks.fulfilled, (state, action) => {
        state.playbooks.status = QueryStatus.SUCCEEDED
        state.playbooks.data = action.payload
      })
      .addCase(fetchPlaybooks.rejected, (state, action) => {
        state.playbooks.status = QueryStatus.ERROR_FETCHING
        handleError(action.error)
      })
  },
})

export const playbookActions = playbookSlice.actions
export default playbookSlice.reducer
