import { TemplateSection, TemplateSubsection } from '@/types/types'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from '@hello-pangea/dnd'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { TemplateSectionRow } from './TemplateSectionRow'
import { CustomAlert } from '../CustomAlert'

export const TemplateSectionList = ({
  sections,
  selectedIndexPath,
  disabled = false,
  setSections,
  onEditorOpen,
  onEditorClose,
}: {
  sections: TemplateSection[]
  selectedIndexPath: number[] | null
  disabled?: boolean
  setSections: (sections: TemplateSection[]) => void
  onEditorOpen: (indexPath: number[]) => void
  onEditorClose: () => void
}) => {
  const [removedSection, setRemovedSection] = useState<
    TemplateSection | TemplateSubsection | null
  >(null)
  const [addedSection, setAddedSection] = useState<
    TemplateSection | TemplateSubsection | null
  >(null)
  const [updatedSection, setUpdatedSection] = useState<
    TemplateSection | TemplateSubsection | null
  >(null)
  const [removedIndexes, setRemovedIndexes] = useState<number[]>([])
  const reorder = (
    list: TemplateSection[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const items = reorder(
      sections,
      result.source.index,
      result.destination.index
    )

    setSections(items)
  }

  const handleSectionUpdate = (section: TemplateSection) => {
    const index = sections.findIndex((v) => v.id === section.id)
    const copy = [...sections]
    copy[index] = section
    setSections(copy)
  }

  const handleRemove = ({
    section,
    subsection,
    indexes = [],
  }: {
    section?: TemplateSection
    subsection?: TemplateSubsection
    indexes?: number[]
  }) => {
    setRemovedSection(section || subsection || null)
    setRemovedIndexes(indexes)
    const index = indexes.at(-1)

    if (index === undefined) return

    if (indexes.length === 1) {
      setSections([...sections.slice(0, index), ...sections.slice(index + 1)])
    } else if (indexes.length === 2) {
      const parentIndex = indexes[0]
      const copy = [...sections]
      copy[parentIndex] = {
        ...copy[parentIndex],
        subsections: [
          ...(copy[parentIndex].subsections?.slice(0, index) || []),
          ...(copy[parentIndex].subsections?.slice(index + 1) || []),
        ],
      }
      setSections(copy)
    } else if (indexes.length === 3) {
      const parentIndex = indexes[0]
      const copy = [...sections]

      const subsections = copy[parentIndex].subsections || []

      const subsectionsCopy = [...subsections]

      subsectionsCopy[indexes[2]] = {
        ...subsections[indexes[2]],
        subsections: [
          ...(subsections[indexes[2]].subsections?.slice(0, index) || []),
          ...(subsections[indexes[2]].subsections?.slice(index + 1) || []),
        ],
      }

      copy[parentIndex] = {
        ...copy[parentIndex],
        subsections: subsectionsCopy,
      }

      setSections(copy)
    }
  }

  const undoRemove = () => {
    if (removedIndexes.length === 1) {
      setSections([removedSection as TemplateSection, ...sections])
    } else if (removedIndexes.length === 2) {
      const parentIndex = removedIndexes[0]
      const copy = [...sections]
      copy[parentIndex] = {
        ...copy[parentIndex],
        subsections: [
          removedSection as TemplateSubsection,
          ...(copy[parentIndex].subsections || []),
        ],
      }
      setSections(copy)
    } else if (removedIndexes.length === 3) {
      const parentIndex = removedIndexes[0]
      const copy = [...sections]

      const subsections = copy[parentIndex].subsections || []

      const subsectionsCopy = [...subsections]

      subsectionsCopy[removedIndexes[2]] = {
        ...subsections[removedIndexes[2]],
        subsections: [
          removedSection as TemplateSubsection,
          ...(subsections[removedIndexes[2]].subsections || []),
        ],
      }

      copy[parentIndex] = {
        ...copy[parentIndex],
        subsections: subsectionsCopy,
      }

      setSections(copy)
    }

    setRemovedIndexes([])
    setRemovedSection(null)
  }

  const handleUpdate = (section: TemplateSection | TemplateSubsection) => {
    setUpdatedSection(section)
  }

  const handleAdded = (section: TemplateSection | TemplateSubsection) => {
    setAddedSection(section)
  }

  return (
    <div className="flex flex-col gap-3">
      {removedSection && (
        <CustomAlert
          variant="info"
          description={`'${removedSection.title}' removed`}
          dismissable
          onCloseClick={() => setRemovedSection(null)}
          buttonLabel="Undo"
          onButtonClick={() => {
            undoRemove()
          }}
        />
      )}

      {updatedSection && (
        <CustomAlert
          variant="success"
          description={`'${updatedSection.title}' updated`}
          dismissable
          onCloseClick={() => setUpdatedSection(null)}
        />
      )}

      {addedSection && (
        <CustomAlert
          variant="success"
          description={`'${addedSection.title}' added`}
          dismissable
          onCloseClick={() => setAddedSection(null)}
        />
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={`${snapshot.isDraggingOver ? 'pointer-events-none' : ''} pb-4`}
            >
              {sections.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={`draggable-${item.id}`}
                  index={index}
                >
                  {(provided, snapshot) =>
                    snapshot.isDragging ? (
                      createPortal(
                        <TemplateSectionRow
                          section={item}
                          sections={sections}
                          indexes={[index]}
                          selectedIndexPath={selectedIndexPath}
                          provided={provided}
                          disabled={disabled}
                          onSectionUpdate={handleSectionUpdate}
                          onRemove={handleRemove}
                          onAdd={handleAdded}
                          onUpdate={handleUpdate}
                          onEditorOpen={onEditorOpen}
                          onEditorClose={onEditorClose}
                        />,
                        document.body
                      )
                    ) : (
                      <TemplateSectionRow
                        section={item}
                        sections={sections}
                        indexes={[index]}
                        selectedIndexPath={selectedIndexPath}
                        provided={provided}
                        disabled={disabled}
                        onSectionUpdate={handleSectionUpdate}
                        onRemove={handleRemove}
                        onAdd={handleAdded}
                        onUpdate={handleUpdate}
                        onEditorOpen={onEditorOpen}
                        onEditorClose={onEditorClose}
                      />
                    )
                  }
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}
