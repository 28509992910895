import { ApexChartsResponseChartProps } from '@/types/types'
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { ChartHeader } from './ChartHeader'
import { useEffect, useRef, useState } from 'react'
import { formatTruncateDataLabel, pxToCh, getChartCitation } from './utils'
import { formatDisplayNumber } from '@/utils/utils'
import { ChartTooltip } from './ChartTooltip'
import { cn } from '@/shadcn/utils'
import { Color } from '@/constants'

export const ResponseBarChart = ({
  id,
  parsedData,
  series,
  options,
  compact,
  citations,
  documents,
  openedCitation,
  onCitationOpen,
  categories,
  className,
  isFormatted,
  isExportEnabled = true,
}: ApexChartsResponseChartProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [maxChar, setMaxChar] = useState<number>(5)

  const barAmount = series.reduce((a, b) => a + b.data.length, 0)
  const dataPointAmount = series.length
  const isSingleDatapoint = dataPointAmount === 1

  const fontSize = 10

  const barOptions: ApexOptions = {
    ...options,
    chart: {
      ...options.chart,
      type: 'bar',
      events: {
        ...options.chart?.events,
        mounted: (chart) => {
          if (
            openedCitation &&
            openedCitation.chart_table_id === id &&
            !openedCitation.isChartHeaderCitation
          ) {
            chart.toggleDataPointSelection(
              openedCitation.index_y,
              openedCitation.index_x
            )
          }
        },
        dataPointSelection: (_, chartContext, config) => {
          const citation = getChartCitation(
            chartContext.w.globals.series[config.seriesIndex][
              config.dataPointIndex
            ],
            citations,
            config.dataPointIndex,
            config.seriesIndex
          )

          if (citation) {
            onCitationOpen?.(citation, 0)
          }
        },
      },
    },
    yaxis: {
      show: false,
    },
    dataLabels: {
      ...options.dataLabels,
      enabled: true,
      formatter: (val: string | number) => {
        return formatTruncateDataLabel({
          label: isFormatted ? formatDisplayNumber(val) : `${val}`,
          maxChar: maxChar,
          categoryAmount: barAmount,
        })
      },
      offsetY: -20,
      background: {
        enabled: isSingleDatapoint,
        foreColor: '#fff',
        padding: 4,
        borderRadius: 2,
        borderWidth: 0,
        dropShadow: {
          enabled: false,
        },
      },
      style: {
        ...options.dataLabels?.style,
        ...(!isSingleDatapoint && {
          fontSize: `14px`,
          fontFamily: 'Inter, sans-serif',
          fontWeight: 400,
          colors: [Color['system-body']],
        }),
      },
    },
    stroke: {
      width: 0,
      // colors: ['#FAFAFA'],
    },
    plotOptions: {
      ...options.plotOptions,
      bar: {
        ...options.plotOptions?.bar,
        columnWidth: barAmount <= 5 ? '50%' : '90%',
        dataLabels: {
          ...options.plotOptions?.bar?.dataLabels,
          position: 'top',
          total: {
            enabled: true,
            formatter: (val: string) => {
              return formatTruncateDataLabel({
                label: formatDisplayNumber(val) as string,
                maxChar: maxChar,
                categoryAmount: barAmount,
              })
            },
            style: {
              fontSize: `${fontSize}px`,
              fontFamily: 'Inter, sans-serif',
              fontWeight: 400,
            },
            offsetY: -4,
          },
        },
      },
    },
  }

  useEffect(() => {
    const parentElement = document.getElementById(`chart-container-${id}`)

    const barElements: NodeListOf<HTMLDivElement> =
      parentElement?.querySelectorAll(
        `.apexcharts-bar-area`
      ) as NodeListOf<HTMLDivElement>

    if (barElements && barElements.length > 0) {
      const barWidth = barElements[0].getBoundingClientRect().width
      const width = isSingleDatapoint ? barWidth : barWidth / dataPointAmount
      if (width) {
        const calcMaxChar = pxToCh(width, fontSize)
        setMaxChar(calcMaxChar)
      }
    }
  }, [id, isSingleDatapoint])

  return (
    <div className={cn(`p-4 ${compact ? 'mt-8' : 'mt-10'}`, className)}>
      <div
        id={`chart-container-${id}`}
        className={`flex flex-col gap-4 relative group`}
      >
        <ChartHeader
          chartRef={ref}
          parsedData={parsedData}
          compact={compact}
          citations={citations}
          documents={documents}
          openedCitation={openedCitation}
          onCitationOpen={onCitationOpen}
          isExportEnabled={isExportEnabled}
        />

        {openedCitation?.chart_table_id === id &&
          !openedCitation.isChartHeaderCitation && (
            <ChartTooltip
              id={id}
              categories={categories}
              series={series}
              dataPointIndex={openedCitation!.index_x!}
              seriesIndex={openedCitation!.index_y!}
              citations={citations}
              openedCitation={openedCitation}
              withShadow={false}
              onClose={() => onCitationOpen?.(null, 0)}
              showOpenedCitation
            />
          )}

        <div className="relative w-full h-[18.75rem]">
          <div
            className={`absolute max-w-full ${barAmount > 15 ? 'w-fit' : 'w-full'} max-w-[calc(100vw-2rem)] tablet:max-w-[calc(100vw-4rem)] laptop:max-w-[calc(100vw-11.25rem)] tablet:left-[50%] tablet:-translate-x-[50%] overflow-x-auto laptop:overflow-x-visible overflow-y-visible h-[18.75rem]`}
          >
            <div
              ref={ref}
              id={`chart-${id}`}
              className={`${barAmount > 30 ? 'w-[80rem]' : barAmount > 15 ? 'w-[70rem]' : 'w-full'} h-full [&_.apexcharts-tooltip]:!border-none [&_.apexcharts-tooltip]:!rounded-sm [&_.apexcharts-tooltip]:!bg-system-secondary`}
            >
              <Chart
                options={barOptions}
                series={series}
                type="bar"
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
