import { Send } from "lucide-react";
import { Dialog, DialogBody, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../components/ui/dialog";
import { Button } from "./ui/button";
import { useContext, useState } from "react";
import { TypographyBody } from "./ui/Typography";
import { Textarea } from "./ui/textarea";
import { Checkbox } from "./ui/checkbox";
import { SystemMessage } from "@/types/types";
import * as Sentry from "@sentry/react";
import { UserContext } from "@/contexts/UserContext";
import { ErrorMessage } from "./ErrorMessage";
import { ToastContext } from "@/contexts/ToastContext";

export const FeedbackDialog = ({ open, setOpen, message, question }: { open: boolean, setOpen: (open: boolean) => void, message: SystemMessage, question: string }) => {
    const [selectedTags, setSelectedTags] = useState<string[]>([])
    const [messageText, setMessageText] = useState<string>('')
    const [shareData, setShareData] = useState(true)
    const [showError, setShowError] = useState(false)

    const { user } = useContext(UserContext)
    const { showToast } = useContext(ToastContext)

    const tags = ['Factually incorrect', 'Too slow', 'Out of date', 'Irrelevant', 'Bug', 'Other']

    const sendFeedback = () => {
        if (selectedTags.length === 0) {
            setShowError(true)
            return
        } else {
            setShowError(false)
        }

        const tags: Record<string, any> = {
            dataConsent: shareData,
            reason_factually_incorrect: selectedTags.includes('Factually incorrect'),
            reason_too_slow: selectedTags.includes('Too slow'),
            reason_out_of_date: selectedTags.includes('Out of date'),
            reason_irrelevant: selectedTags.includes('Irrelevant'),
            reason_bug: selectedTags.includes('Bug'),
            reason_other: selectedTags.includes('Other'),
            is_positive: false
        }

        Sentry.captureFeedback(
            {
                message: messageText,
                email: user?.email
            },
            {
                captureContext: {
                    tags: tags
                },
                includeReplay: false,
                attachments: shareData ? [
                    {
                        filename: `${message.conversationId}.txt`,
                        data: `${question} \n\n\n ${(message.data.text || '')}`
                    }
                ] : []
            },
        )

        showToast({
            variant: 'success',
            description: 'Feedback sent. Thank you for your feedback!',
            dismissable: true
        })

        setOpen(false)
    }

    const handleFilterChange = (filterName: string) => {
        if (selectedTags.includes(filterName)) {
            setSelectedTags(selectedTags.filter((v) => v !== filterName))
        } else {
            setSelectedTags([...selectedTags, filterName])
        }
    }

    return (
        <Dialog open={open} onOpenChange={(v) => { setOpen(v); setMessageText('') }}>
            <DialogContent className="max-w-[calc(100vw-3rem)] laptop:!max-w-[45rem]" onClick={(e) => e.stopPropagation()}>
                <DialogHeader>
                    <DialogTitle>Give us feedback</DialogTitle>
                    <DialogDescription>Your feedback will help us improve the platform</DialogDescription>
                </DialogHeader>
                <DialogBody className="flex flex-col gap-10 mt-10 mb-2">
                    <div className="flex flex-col gap-2">
                        <TypographyBody isStrong={true}>
                            What went wrong?
                        </TypographyBody>

                        {showError && (
                            <div className="mb-1">
                                <ErrorMessage message="Please select a reason to continue" />
                            </div>
                        )}

                        <div className="flex gap-2 flex-wrap">
                            {tags.map((tag) => {
                                return (
                                    <Button
                                        key={tag}
                                        variant={selectedTags.includes(tag) ? 'tag-selected' : 'tag'}
                                        className="whitespace-nowrap h-[1.75rem] !font-body"
                                        onClick={() => {
                                            handleFilterChange(tag)
                                        }}
                                    >
                                        {tag}
                                    </Button>
                                )
                            })}
                        </div>
                    </div>

                    <div className="flex flex-col gap-2">
                        <TypographyBody isStrong={true}>
                            How can we make it better (optional)?
                        </TypographyBody>

                        <Textarea
                            value={messageText}
                            onChange={(e) => setMessageText(e.target.value)}
                            className="border-system-border-regular"
                        />
                    </div>

                    <Checkbox
                        checked={shareData}
                        onCheckedChange={() => setShareData(!shareData)}
                        label="Share question & response content with us so we can resolve the issue faster"
                    />
                </DialogBody>

                <DialogFooter className="mt-4">
                    <Button variant='secondary'
                        onClick={(e) => {
                            e.stopPropagation()
                            setOpen(false)
                        }}>
                        Cancel
                    </Button>

                    <Button onClick={(e) => {
                        e.stopPropagation()
                        sendFeedback()
                    }}>
                        <div className="flex gap-2 items-center">
                            <Send className="size-6 shrink-0 stroke-interactive" />

                            Send feedback
                        </div>

                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}