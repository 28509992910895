import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/shadcn/utils'

const badgeVariants = cva(
  'inline-flex items-center rounded-full font-label px-3 py-px transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 h-fit bg-badge-blue-bg text-badge-blue-graphic',
  {
    variants: {
      variant: {
        blue: 'bg-badge-blue-bg text-badge-blue-graphic',
        green: 'bg-badge-green-bg text-badge-green-graphic',
        red: 'bg-badge-red-bg text-badge-red-graphic',
        orange: 'bg-badge-orange-bg text-badge-orange-graphic',
        purple: 'bg-[#F2ECFD] text-[#6B21A8]',
        teal: 'bg-badge-teal-bg text-badge-teal-graphic',
        white:
          'bg-system-secondary border border-system-border-light text-system-body',
        gray: 'bg-system-hover text-system-body',
      },
      rounded: {
        true: 'rounded-full',
        false: 'rounded-sm',
      },
    },
    defaultVariants: {
      variant: 'blue',
      rounded: true,
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {
  rounded?: boolean
}

function Badge({ className, variant, rounded = true, ...props }: BadgeProps) {
  return (
    <div
      className={cn(badgeVariants({ variant, rounded }), className)}
      {...props}
    />
  )
}

export { Badge, badgeVariants }
