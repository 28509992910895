import ChevronDown from '@/assets/ChevronDown'
import { TypographyH4, TypographyLabel } from '../ui/Typography'
import {
  AlignLeft,
  ChevronUp,
  EllipsisVertical,
  GripVertical,
  SlidersVertical,
} from 'lucide-react'
import { TemplateSection, TemplateSubsection } from '@/types/types'
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
  DropResult,
} from '@hello-pangea/dnd'
import { Button } from '../ui/button'
import { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { TemplateSubsectionRow } from './TemplateSubsectionRow'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import { countAllSections, getSectionByIndexes } from '@/utils/utils'
import { CustomTooltip } from '../CustomTooltip'
import { TemplateSectionEditor } from './TemplateSectionEditor'
import { Dialog, DialogBody, DialogContent } from '../ui/dialog'

export const TemplateSectionRow = ({
  section,
  sections,
  indexes,
  selectedIndexPath,
  provided,
  disabled = false,
  onSectionUpdate,
  onRemove,
  onUpdate,
  onAdd,
  onEditorOpen,
  onEditorClose,
}: {
  section: TemplateSection
  sections: TemplateSection[]
  indexes: number[]
  selectedIndexPath: number[] | null
  provided: DraggableProvided
  disabled: boolean
  onSectionUpdate: (section: TemplateSection) => void
  onRemove: ({
    section,
    subsection,
    indexes,
  }: {
    section?: TemplateSection
    subsection?: TemplateSubsection
    indexes?: number[]
  }) => void
  onUpdate: (section: TemplateSection | TemplateSubsection) => void
  onAdd: (section: TemplateSection | TemplateSubsection) => void
  onEditorOpen: (indexPath: number[]) => void
  onEditorClose: () => void
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const sectionCount = useMemo(() => countAllSections([section]) - 1, [section])

  const selectedSection = useMemo(() => {
    if (selectedIndexPath && selectedIndexPath.at(0) === indexes.at(0)) {
      const selectedSection = getSectionByIndexes(sections, selectedIndexPath)
      return selectedSection
    }
  }, [sections, selectedIndexPath, indexes])

  const parentIndexPath = selectedIndexPath?.slice(0, -1) || []
  const parentSection =
    parentIndexPath.length > 0
      ? getSectionByIndexes(sections, parentIndexPath)
      : undefined

  const reorder = (
    list: TemplateSubsection[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const subsections = section.subsections
    if (!subsections) return

    const items = reorder(
      subsections,
      result.source.index,
      result.destination.index
    )

    onSectionUpdate({
      ...section,
      subsections: items,
    })
  }

  const handleSubsectionUpdate = (subsection: TemplateSubsection) => {
    const subsections = section.subsections
    const index = subsections?.findIndex((v) => subsection.id === v.id)
    if (index === undefined || !subsections) return
    const copy = [...subsections]

    copy[index] = subsection
    onSectionUpdate({
      ...section,
      subsections: copy,
    })
  }

  const handleAddNewSubsection = () => {
    const id =
      (section.subsections || []).length > 0
        ? Math.max(...(section.subsections || []).map((v) => v.id)) + 1
        : 0
    onSectionUpdate({
      ...section,
      expanded: true,
      subsections: [
        {
          id: id,
          title: '',
        },
        ...(section.subsections || []),
      ],
    })

    onEditorOpen([...indexes, 0])
  }

  return (
    <>
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        className={`group relative flex gap-3 px-4 py-3 items-center bg-system-secondary border border-system-border-light rounded-[12px] mt-3 hover:shadow-section`}
      >
        <div className="flex flex-col gap-2 w-full">
          <div className="flex gap-3 w-full pb-px">
            <Button
              variant="tertiary"
              {...provided.dragHandleProps}
              className={`h-fit hidden group-hover:!flex`}
            >
              <GripVertical className="size-6 shrink-0 stroke-[1.5px]" />
            </Button>

            <div className="flex gap-3 w-full">
              <div className="flex gap-6 w-full items-center">
                <div className="flex flex-col gap-2 w-full max-w-[85%] mr-auto">
                  <TypographyH4 className="w-full">
                    {section.title}
                  </TypographyH4>

                  {section.description && (
                    <TypographyLabel className="text-system-body text-left whitespace-pre-wrap">
                      {section.description}
                    </TypographyLabel>
                  )}
                </div>
                {!dropdownOpen && sectionCount > 0 && (
                  <div className="gap-1.5 flex items-center group-hover:!hidden">
                    <TypographyLabel className="font-label-strong text-system-primary">
                      {sectionCount}
                    </TypographyLabel>

                    <AlignLeft className="size-6 shrink-0 stroke-interactive stroke-system-placeholder" />
                  </div>
                )}

                <Button
                  variant="tertiary"
                  className={`${dropdownOpen ? '' : 'hidden'} group-hover:!block h-fit`}
                  data-tooltip-id={`section-edit-${indexes}`}
                  onClick={() => onEditorOpen(indexes)}
                >
                  <SlidersVertical
                    className={`size-6 shrink-0 stroke-[1.5px]`}
                  />
                </Button>

                <CustomTooltip
                  id={`section-edit-${indexes}`}
                  className="!py-1 !px-3 !rounded-sm"
                  largeArrow={false}
                >
                  <TypographyLabel className="text-system-primary">
                    View section configuration
                  </TypographyLabel>
                </CustomTooltip>

                {!disabled && (
                  <DropdownMenu
                    onOpenChange={(v) => {
                      if (v) {
                        setDropdownOpen(v)
                      } else {
                        setTimeout(() => {
                          setDropdownOpen(v)
                        }, 100)
                      }
                    }}
                  >
                    <DropdownMenuTrigger className="flex items-center" asChild>
                      <Button
                        variant="tertiary"
                        className={`${dropdownOpen ? '' : 'hidden'} group-hover:!block h-fit`}
                      >
                        <EllipsisVertical
                          className={`size-6 shrink-0 stroke-[1.5px]`}
                        />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end" className="w-[12rem]">
                      <DropdownMenuItem
                        variant="tertiary"
                        onClick={(e) => {
                          e.stopPropagation()
                          handleAddNewSubsection()
                        }}
                      >
                        Add subsection
                      </DropdownMenuItem>

                      <DropdownMenuItem
                        variant="tertiary-destructive"
                        onClick={(e) => {
                          e.stopPropagation()
                          onRemove({ section: section, indexes })
                        }}
                      >
                        Remove
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
                {(section.subsections?.length || 0) > 0 && (
                  <Button
                    variant="tertiary"
                    className={`h-fit ${dropdownOpen ? '' : 'hidden'} group-hover:!block`}
                    onClick={() =>
                      onSectionUpdate({
                        ...section,
                        expanded: !section.expanded,
                      })
                    }
                  >
                    {section.expanded ? (
                      <ChevronUp className="size-6 shrink-0 stroke-[1.5px]" />
                    ) : (
                      <ChevronDown />
                    )}
                  </Button>
                )}
              </div>
            </div>
          </div>

          {section.expanded && (section.subsections || []).length > 0 && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={`${snapshot.isDraggingOver ? 'pointer-events-none' : ''}`}
                  >
                    {section.subsections?.map((item, index) => (
                      <Draggable
                        key={`${item.id}-${index}`}
                        draggableId={`draggable-${item.id}`}
                        index={index}
                      >
                        {(provided, snapshot) =>
                          snapshot.isDragging ? (
                            createPortal(
                              <TemplateSubsectionRow
                                section={item}
                                sections={sections}
                                subsection={item}
                                indexes={[...indexes, index]}
                                provided={provided}
                                snapshot={snapshot}
                                onSubsectionUpdate={handleSubsectionUpdate}
                                onRemove={onRemove}
                                onEdit={(v) => {
                                  onEditorOpen(v)
                                }}
                                disabled={disabled}
                              />,
                              document.body
                            )
                          ) : (
                            <TemplateSubsectionRow
                              section={item}
                              sections={sections}
                              subsection={item}
                              indexes={[...indexes, index]}
                              provided={provided}
                              snapshot={snapshot}
                              onSubsectionUpdate={handleSubsectionUpdate}
                              onRemove={onRemove}
                              onEdit={(v) => {
                                onEditorOpen(v)
                              }}
                              disabled={disabled}
                            />
                          )
                        }
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </div>
      </div>

      {selectedSection && selectedIndexPath && (
        <Dialog
          open={Boolean(selectedSection && selectedIndexPath)}
          onOpenChange={() => onEditorClose()}
        >
          <DialogContent className="min-w-[50rem] w-[50rem]">
            <DialogBody className="px-1">
              <TemplateSectionEditor
                disabled={disabled}
                section={selectedSection}
                sections={sections}
                parentSection={parentSection || undefined}
                onClose={() => {
                  if (!selectedSection.title) {
                    onRemove({
                      // @ts-expect-error
                      section: selectedSection,
                      subsection: selectedSection,
                      indexes: selectedIndexPath,
                    })
                  }
                  onEditorClose()
                }}
                onSave={(updatedSection) => {
                  if (selectedIndexPath.length === 1) {
                    onSectionUpdate(updatedSection as TemplateSection)
                  }

                  if (selectedIndexPath.length === 2) {
                    handleSubsectionUpdate(updatedSection)
                  }

                  if (selectedIndexPath.length === 3) {
                    if (!parentSection) return
                    const subsections = parentSection.subsections

                    if (!subsections) return

                    const copy = [...(parentSection.subsections || [])]

                    if (indexes.at(-1) === undefined) return

                    copy[indexes.at(-1) || 0] = updatedSection
                    handleSubsectionUpdate({
                      ...parentSection,
                      subsections: copy,
                    })
                  }

                  onEditorClose()
                  if (selectedSection.title) {
                    onUpdate(updatedSection)
                  } else {
                    onAdd(updatedSection)
                  }
                }}
              />
            </DialogBody>
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}
