import { Link, useNavigate } from "react-router-dom"
import { TypographyBody } from "../ui/Typography"
import { UserMenu } from "./UserMenu"
import logo from "../../assets/logo.svg";
import texture from '../../assets/bg-texture.png'
import Menu from "@/assets/Menu";
import { useContext } from "react";
import { LayoutContext } from "@/contexts/LayoutContext";
import { Searchbar } from "../Searchbar";
import { BookOpen } from "lucide-react";

export const Navbar = () => {
    const layoutContext = useContext(LayoutContext)

    const navigate = useNavigate()

    const handleSearch = (query: string) => {
        navigate(`/search?q=${query}`);
    }

    return (
        <>
            <header className="w-full z-[3] hidden navbar:!flex gap-12 items-center h-[3.25rem] shrink-0 bg-system-surface mx-auto border-b border-system-border-light">
                <div className="flex flex-grow gap-12 lg:mx-[7.5rem] sm:mx-[3.75rem]">
                    <Link
                        to="/home"
                    >
                        <img className="w-[6.5rem] h-[2rem]" src={logo} />
                    </Link>

                    <div className="flex justify-between flex-grow gap-8">
                        <div className="flex gap-8 flex-grow">
                            <Link
                                to="/dossiers"
                                className={`flex items-center gap-2 transition-all text-system-primary shrink-0`}
                            >
                                <TypographyBody isStrong={true}>
                                    Dossiers
                                </TypographyBody>
                            </Link>

                            <Link
                                to="/assistant/ask/"
                                className={`flex items-center gap-2 transition-all text-system-primary shrink-0`}
                            >
                                <TypographyBody isStrong={true}>
                                    Ask Desia
                                </TypographyBody>
                            </Link>

                            <Link
                                to="/reports"
                                className={`flex items-center gap-2 transition-all text-system-primary shrink-0`}
                            >
                                <TypographyBody isStrong={true}>
                                    Reports
                                </TypographyBody>
                            </Link>

                            <Link
                                to="/library"
                                className={`flex items-center gap-2 transition-all text-system-primary shrink-0`}
                            >
                                <TypographyBody isStrong={true}>
                                    Library
                                </TypographyBody>
                            </Link>
                        </div>
                        <Link
                            to="/guide/integrations"
                            className="flex items-center gap-2 transition-all text-system-primary shrink-0">
                            <BookOpen className="size-6 shrink-0 stroke-interactive stroke-system-placeholder" />
                            <TypographyBody isStrong={true}>
                                    Help guides
                            </TypographyBody>
                        </Link>
                    </div>
                   
                    <Searchbar type='navbar' handleSearch={handleSearch} />

                    <UserMenu showMenu={true} />
                </div>
            </header>
            <header className="flex z-[3] navbar:hidden w-full h-[3.75rem] shrink-0" style={{ backgroundImage: `url(${texture})` }}>
                <div className="flex items-center gap-8 p-4">
                    <div onClick={() => layoutContext?.toggleShowSidebar(!layoutContext.showSidebar)}>
                        <Menu />
                    </div>
                    <Link
                        to="/home"
                    >
                        <img className="w-[6rem] h-[2rem]" src={logo} />
                    </Link>
                </div>
            </header>
        </>
    )
}
