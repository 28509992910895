import { ArrowLeft, PenToolIcon } from 'lucide-react'
import { Button } from '../ui/button'
import { TypographyH3 } from '../ui/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import { useEffect, useMemo, useState } from 'react'
import {
  requestCreateReport,
  requestReports,
  resetCreateReportData,
} from '../DocGen/docGenSlice'
import { getTimestamp } from '@/utils/utils'
import { QueryStatus, ResponseDocGenReport } from '@/types/types'
import { ErrorMessage } from '../ErrorMessage'
import { ReportList } from './ReportList'
import { Input } from '../ui/input'
import { Link, useNavigate } from 'react-router-dom'
import {
  fetchSessions,
  fetchTemplates,
  fetchThemes,
} from '../DocGen/docGenThunk'
import { Badge } from '../ui/badge'

export interface MockReport {
  title: string
  description: string
}

// todo: implement pagination
export function EditorPage({
  reports,
  error,
  loading,
  createReportLoading,
  createReportError,
  handleCreateReport,
}: {
  reports: ResponseDocGenReport[]
  loading: boolean
  error: any
  createReportLoading?: boolean
  createReportError?: string | null
  handleCreateReport: () => void
}) {
  const [searchText, setSearchText] = useState('')

  const filteredReports = useMemo(
    () =>
      reports.filter((v) => {
        if (!searchText) {
          return true
        }
        const splitText = searchText.toLowerCase().split(' ')
        return (
          splitText.some((text) => v.title.toLowerCase().includes(text)) &&
          v.content !== ''
        )
      }),
    [searchText, reports]
  )

  return (
    <>
      <div className="flex flex-col gap-10 mt-10 sm:mt-0 tablet:max-w-[59.75rem] mx-auto">
        <div className="flex flex-col gap-6">
          <div className="flex gap-4 items-center text-center mx-auto">
            <TypographyH3>Editor reports</TypographyH3>

            <Badge variant="purple">Beta</Badge>
          </div>

          <div className={`flex flex-wrap justify-center gap-2 mx-auto w-full`}>
            <Input
              className="w-[20rem]"
              placeholder="Search by report name"
              isSearch={true}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value)
              }}
              isCloseVisible={!!searchText}
              onCloseClick={() => setSearchText('')}
            />

            <Button
              disabled={createReportLoading}
              onClick={handleCreateReport}
              variant="secondary"
              className="w-fit"
            >
              <div className="flex gap-2 items-center">
                <PenToolIcon className="size-6 shrink-0 stroke-interactive" />
                Editor: Write report
              </div>
            </Button>
          </div>
        </div>

        <div className="flex flex-col gap-6">
          {createReportError && (
            <ErrorMessage message="We failed to create a new report, try again shortly" />
          )}

          <ReportList
            reports={filteredReports}
            loading={loading}
            error={error}
            type="report"
          />
        </div>
      </div>
      <Link to={'/reports'}>
        <Button variant="tertiary" className="absolute top-[5.25rem] left-[7.5rem]">
          <div className="flex gap-2 items-center">
            <ArrowLeft className="size-6 shrink-0 stroke-interactive" />
            Reports
          </div>
        </Button>
      </Link>
    </>
  )
}

export function EditorPageContainer() {
  const reports = useSelector((state: RootState) => state.docGen.reports)
  const createReport = useSelector(
    (state: RootState) => state.docGen.createReport
  )
  const templateListStatus = useSelector(
    (state: RootState) => state.docGen.templateListStatus
  )

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const createReportHandler = async () => {
    dispatch(
      requestCreateReport({
        requestId: 'docgen:create_report',
        params: {},
        timestamp: getTimestamp(),
      })
    )
  }

  useEffect(() => {
    dispatch(
      requestReports({
        requestId: 'docgen:list_reports_20_0',
        params: {
          limit: 20,
          offset: 0,
        },
        timestamp: getTimestamp(),
      })
    )

    dispatch(fetchSessions())
    dispatch(fetchThemes())
  }, [dispatch])

  useEffect(() => {
    if (createReport.data) {
      navigate(`/report/${createReport.data.id}`)

      dispatch(resetCreateReportData())
    }
  }, [createReport.data, dispatch, navigate])

  useEffect(() => {
    if (templateListStatus === QueryStatus.INITIALISED) {
      dispatch(fetchTemplates())
    }
  }, [templateListStatus, dispatch])

  useEffect(() => {
    document.title = 'Desia AI - Reports'
  }, [])

  return (
    <>
      <EditorPage
        reports={reports.data || []}
        loading={reports.loading}
        error={reports.error}
        createReportLoading={createReport.loading}
        createReportError={createReport.error}
        handleCreateReport={createReportHandler}
      />
    </>
  )
}
