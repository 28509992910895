import {
  ArrowLeft,
  ChevronDown,
  ChevronUp,
  Globe,
  LibraryBig,
  NotebookTabs,
  Plus,
} from 'lucide-react'
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog'
import { TypographyBody, TypographyLabel } from '../ui/Typography'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs'
import texture from '@/assets/bg-texture.png'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import { useEffect, useMemo, useState } from 'react'
import { fetchPlaybooks } from './playbookThunk'
import { PlaybookCard } from './PlaybookCard'
import {
  DatabaseConnector,
  IntegrationCode,
  Playbook,
  QueryStatus,
} from '@/types/types'
import { Button } from '../ui/button'
import Divider from '../ui/divider'
import { ResponseMarkdown } from '../Assistant/ResponseMarkdown'
import {
  getDatabaseIcon,
  getIntegrationIcon,
  getPlaybookCardIcon,
} from '@/utils/components'
import { replacePlaybookVariable } from '@/utils/utils'
import { Badge } from '../ui/badge'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../ui/select'
import { Input } from '../ui/input'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../ui/accordion'
import InfoCircle from '@/assets/InfoCircle'
import { CustomTooltip } from '../CustomTooltip'
import { PlaybookCreateDialog } from './PlaybookCreateDialog'
import { cleanSourceSet } from './playbookUtils'

export const PlaybookDialog = ({
  open,
  setOpen,
  handleClick,
}: {
  open: boolean
  setOpen: (open: boolean) => void
  handleClick?: (playbook: Playbook) => void
}) => {
  const playbooks = useSelector((state: RootState) => state.playbook.playbooks)

  const [searchText, setSearchText] = useState<string>('')
  const [selectedTab, setSelectedTab] = useState<string | null>(null)
  const [selectedPlaybook, setSelectedPlaybook] = useState<Playbook | null>(
    null
  )
  const [openedPlaybookPrompt, setOpenedPlaybookPrompt] = useState<string[]>([])

  const [showCreateDialog, setShowCreateDialog] = useState(false)
  const [createDialogPlaybook, setCreateDialogPlaybook] =
    useState<Playbook | null>(null)

  const categories = useMemo(() => {
    return [
      ...Array.from(
        new Set(
          playbooks.data
            .flatMap((v) => v.items)
            .map((v) => v.category)
            .filter((v) => Boolean(v))
        )
      ),
    ]
  }, [playbooks])

  const filteredPlaybooks = useMemo(
    () =>
      playbooks.data.filter((p) => {
        const splitText = searchText.split(' ')
        return splitText.some((v) =>
          p.title.toLowerCase().includes(v.toLowerCase())
        )
      }),
    [playbooks.data, searchText]
  )

  const groupedFilteredPlaybooks: { [category: string]: Playbook[] } = useMemo(
    () =>
      filteredPlaybooks.reduce(
        (groups: { [category: string]: Playbook[] }, item) => {
          if (!item.items[0] || !item.items[0].category) {
            return groups
          }
          if (!groups[item.items[0].category]) {
            groups[item.items[0].category] = []
          }
          groups[item.items[0].category].push(item)
          return groups
        },
        {}
      ),
    [filteredPlaybooks]
  )

  const customPlaybooks = useMemo(
    () =>
      playbooks.data.filter((p) => {
        return p.items.every((v) => !v.category)
      }),
    [playbooks.data]
  )

  const customPlaybookSources = useMemo(() => {
    const sources = cleanSourceSet(
      new Set(
        customPlaybooks.flatMap((v) => v.items.flatMap((v) => v.connectors))
      )
    )

    return sources
  }, [customPlaybooks])

  const filteredCustomPlaybooks = useMemo(
    () =>
      customPlaybooks.filter((p) => {
        const splitText = searchText.split(' ')
        return splitText.some((v) =>
          p.title.toLowerCase().includes(v.toLowerCase())
        )
      }),
    [customPlaybooks, searchText]
  )

  const filteredCustomPlaybookSources = useMemo(() => {
    const sources = cleanSourceSet(
      new Set(
        filteredCustomPlaybooks.flatMap((v) =>
          v.items.flatMap((v) => v.connectors)
        )
      )
    )

    return sources
  }, [filteredCustomPlaybooks])

  const dispatch = useDispatch<AppDispatch>()

  const handlePreview = (playbook: Playbook) => {
    setSelectedPlaybook(playbook)
    setSearchText('')
  }

  const handleReset = () => {
    setSelectedPlaybook(null)
    setSearchText('')
  }

  const getSourceIcon = (source: string) => {
    switch (source) {
      case 'Web':
        return <Globe className="size-4 shrink-0 stroke-visual" />
      case 'Library':
      case 'Selected Files':
        return <LibraryBig className="size-4 shrink-0 stroke-visual" />
      case 'Teams':
        return getIntegrationIcon(
          IntegrationCode.TEAMS,
          true,
          'min-w-4 max-w-4 max-h-4 shrink-0'
        )
      case 'Outlook':
        return getIntegrationIcon(
          IntegrationCode.OUTLOOK,
          true,
          'min-w-4 max-w-4 max-h-4 shrink-0'
        )
      case 'Financial Data':
        return getDatabaseIcon(
          DatabaseConnector.FINANCIAL_DATA,
          'size-4 shrink-0'
        )
      case 'Companies House':
        return getDatabaseIcon(
          DatabaseConnector.COMPANIES_HOUSE,
          'rounded-[3px]'
        )
      case 'Filings':
        return getDatabaseIcon(DatabaseConnector.FILINGS, 'rounded-[3px]')
      case 'Transcripts':
        return getDatabaseIcon(DatabaseConnector.TRANSCRIPTS, 'rounded-[3px]')
      case 'Sharepoint':
        return getIntegrationIcon(
          IntegrationCode.SHAREPOINT,
          true,
          'min-w-4 max-h-4 shrink-0'
        )
      case 'OneDrive':
        return getIntegrationIcon(
          IntegrationCode.ONEDRIVE,
          true,
          'min-w-4 max-h-4 shrink-0'
        )
    }
  }

  useEffect(() => {
    if (playbooks.status === QueryStatus.INITIALISED) {
      dispatch(fetchPlaybooks())
    }
  }, [dispatch, playbooks.status])

  useEffect(() => {
    if (selectedTab === null) {
      setSelectedTab(categories.at(0) || null)
    }
  }, [categories, selectedTab])

  useEffect(() => {
    if (!open) {
      handleReset()
    }
  }, [open])

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent
          className="min-h-[calc(100vh-10rem)] h-[calc(100vh-10rem)] min-w-[calc(100vw-5rem)] laptop:min-w-[85rem] monitor:min-w-[105rem] !shadow-none"
          style={{ backgroundImage: `url(${texture})` }}
        >
          <DialogHeader
            className={`${selectedPlaybook ? '[@media(min-width:670px)]:pb-0' : '[@media(min-width:670px)]:pb-10'} gap-6`}
          >
            <DialogTitle className="relative">
              <div className="flex gap-2 items-start">
                <NotebookTabs className="size-6 shrink-0 stroke-[1.5px] stroke-system-body" />

                <TypographyBody className="text-system-body">
                  Playbook
                </TypographyBody>
              </div>

              <div className="absolute -top-2 left-[50%] -translate-x-[50%] hidden [@media(min-width:670px)]:!flex">
                <Input
                  className="w-[18.75rem] tablet:w-[22.5rem]"
                  placeholder="Search through playbook..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  isSearch
                  isCloseVisible={searchText.length > 0}
                  onCloseClick={() => setSearchText('')}
                />
              </div>
            </DialogTitle>

            <div className="block [@media(min-width:670px)]:!hidden mx-1">
              <Input
                placeholder="Search through playbook..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                isSearch
                isCloseVisible={searchText.length > 0}
                onCloseClick={() => setSearchText('')}
              />
            </div>
          </DialogHeader>
          <DialogBody>
            {!selectedPlaybook && !searchText && (
              <Tabs
                className="tablet:flex gap-10"
                value={selectedTab || undefined}
                onValueChange={(v) => setSelectedTab(v)}
              >
                <TabsList
                  vertical
                  className="min-w-[20rem] max-w-[30rem] hidden tablet:!flex"
                >
                  {categories.map((category, index) => {
                    return (
                      <TabsTrigger
                        key={`playbook-tab-${index}`}
                        vertical
                        value={category || 'Custom Prompts'}
                        className="line-clamp-2 !whitespace-pre-wrap pr-2"
                      >
                        {category}
                      </TabsTrigger>
                    )
                  })}

                  <TabsTrigger
                    key={`playbook-tab-custom`}
                    vertical
                    value={'Custom Prompts'}
                    className="line-clamp-2 !whitespace-pre-wrap pr-2"
                  >
                    Custom Prompts
                  </TabsTrigger>
                </TabsList>

                <div className="flex flex-col gap-6 tablet:!hidden pb-6">
                  <Select
                    onValueChange={(v) => setSelectedTab(v)}
                    value={selectedTab || undefined}
                  >
                    <SelectTrigger className="max-w-[calc(100%-0.5rem)] [@media(min-width:670px)]:max-w-[22.5rem] m-1">
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      {categories.map((category, index) => {
                        if (!category) return
                        return (
                          <SelectItem
                            key={`playbook-tab-${index}`}
                            value={category}
                          >
                            {category}
                          </SelectItem>
                        )
                      })}
                    </SelectContent>
                  </Select>

                  <Divider />
                </div>

                {categories.map((category, index) => {
                  if (!category) return
                  const categoryPlaybooks =
                    playbooks.data?.filter(
                      (v) => v.items.at(0)?.category === category
                    ) || []
                  const sources = cleanSourceSet(
                    new Set(
                      categoryPlaybooks.flatMap((v) =>
                        v.items.flatMap((v) => v.connectors)
                      )
                    )
                  )

                  return (
                    <TabsContent
                      key={`playbook-content-${index}`}
                      value={category}
                      className="w-full"
                    >
                      <div className="flex flex-col gap-6 w-full">
                        <div className="flex gap-2 items-center w-full">
                          {sources.size > 0 && (
                            <TypographyBody className="text-system-body">
                              Sources:
                            </TypographyBody>
                          )}
                          {Array.from(sources).map((source, index) => {
                            return (
                              <div
                                key={`playbook-source-${index}`}
                                className="flex items-center justify-center size-6 bg-system-secondary border border-system-border-light rounded-[3px]"
                              >
                                {getSourceIcon(source)}
                              </div>
                            )
                          })}
                        </div>

                        <div className="grid grid-cols-1 mobile:grid-cols-2 monitor:grid-cols-3 gap-4 w-full">
                          {categoryPlaybooks.map((playbook, index) => {
                            return (
                              <PlaybookCard
                                playbook={playbook}
                                key={`playbook-card-${index}`}
                                onClick={() => {
                                  handleClick?.(playbook)
                                  setOpen(false)
                                }}
                                onPreview={() => handlePreview(playbook)}
                                onEdit={() => {
                                  setCreateDialogPlaybook(playbook)
                                  setOpen(false)
                                }}
                              />
                            )
                          })}
                        </div>
                      </div>
                    </TabsContent>
                  )
                })}

                <TabsContent
                  key={`playbook-content-custom`}
                  value={'Custom Prompts'}
                  className="w-full"
                >
                  <div className="flex flex-col gap-6 w-full">
                    {customPlaybookSources.size > 0 && (
                      <div className="flex gap-2 items-center w-full">
                        <TypographyBody className="text-system-body">
                          Sources:
                        </TypographyBody>

                        {Array.from(customPlaybookSources).map(
                          (source, index) => {
                            return (
                              <div
                                key={`playbook-source-${index}`}
                                className="flex items-center justify-center size-6 bg-system-secondary border border-system-border-light rounded-[3px]"
                              >
                                {getSourceIcon(source)}
                              </div>
                            )
                          }
                        )}
                      </div>
                    )}
                    <Button
                      variant="secondary"
                      className="w-fit"
                      onClick={() => {
                        setOpen(false)
                        setShowCreateDialog(true)
                      }}
                    >
                      <div className="flex gap-2 items-center">
                        <Plus className="size-6 shrink-0 stroke-interactive" />
                        New Playbook prompt
                      </div>
                    </Button>

                    <div className="grid grid-cols-1 mobile:grid-cols-2 monitor:grid-cols-3 gap-4 w-full">
                      {customPlaybooks.map((playbook, index) => {
                        return (
                          <PlaybookCard
                            playbook={playbook}
                            key={`playbook-card-${index}`}
                            onClick={() => {
                              handleClick?.(playbook)
                              setOpen(false)
                            }}
                            onPreview={() => handlePreview(playbook)}
                            onEdit={() => {
                              setCreateDialogPlaybook(playbook)
                              setOpen(false)
                            }}
                          />
                        )
                      })}
                    </div>
                  </div>
                </TabsContent>
              </Tabs>
            )}

            {searchText && (
              <div className="flex flex-col gap-10">
                {Object.entries(groupedFilteredPlaybooks).map(
                  (group, index) => {
                    const categoryPlaybooks =
                      playbooks.data?.filter(
                        (v) => v.items.at(0)?.category === group[0]
                      ) || []
                    const sources = cleanSourceSet(
                      new Set(
                        categoryPlaybooks.flatMap((v) =>
                          v.items.flatMap((v) => v.connectors)
                        )
                      )
                    )

                    return (
                      <div
                        className="flex flex-col gap-6"
                        key={`playbook-content-${index}`}
                      >
                        <div className="flex gap-2 items-center">
                          {sources.size > 0 && (
                            <TypographyBody className="text-system-body">
                              Sources:
                            </TypographyBody>
                          )}

                          {Array.from(sources).map((source, index) => {
                            return (
                              <div
                                key={`playbook-source-${index}`}
                                className="flex items-center justify-center size-6 bg-system-secondary border border-system-border-light rounded-[3px]"
                              >
                                {getSourceIcon(source)}
                              </div>
                            )
                          })}
                        </div>

                        <div className="grid grid-cols-1 mobile:grid-cols-2 gap-4 w-full">
                          {categoryPlaybooks.map((playbook, index) => {
                            return (
                              <PlaybookCard
                                playbook={playbook}
                                key={`playbook-card-${index}`}
                                onClick={() => {
                                  handleClick?.(playbook)
                                  setOpen(false)
                                }}
                                onPreview={() => handlePreview(playbook)}
                                onEdit={() => {
                                  setCreateDialogPlaybook(playbook)
                                  setOpen(false)
                                }}
                              />
                            )
                          })}
                        </div>
                      </div>
                    )
                  }
                )}

                {filteredCustomPlaybooks.length > 0 && (
                  <div
                    className="flex flex-col gap-6"
                    key={`playbook-content-custom`}
                  >
                    <div className="flex gap-2 items-center">
                      {filteredCustomPlaybookSources.size > 0 && (
                        <TypographyBody className="text-system-body">
                          Sources:
                        </TypographyBody>
                      )}

                      {Array.from(filteredCustomPlaybookSources).map(
                        (source, index) => {
                          return (
                            <div
                              key={`playbook-source-${index}`}
                              className="flex items-center justify-center size-6 bg-system-secondary border border-system-border-light rounded-[3px]"
                            >
                              {getSourceIcon(source)}
                            </div>
                          )
                        }
                      )}
                    </div>

                    <div className="grid grid-cols-1 mobile:grid-cols-2 gap-4 w-full">
                      {filteredCustomPlaybooks.map((playbook, index) => {
                        return (
                          <PlaybookCard
                            playbook={playbook}
                            key={`playbook-card-${index}`}
                            onClick={() => {
                              handleClick?.(playbook)
                              setOpen(false)
                            }}
                            onPreview={() => handlePreview(playbook)}
                            onEdit={() => {
                              setCreateDialogPlaybook(playbook)
                              setOpen(false)
                            }}
                          />
                        )
                      })}
                    </div>
                  </div>
                )}
              </div>
            )}

            {selectedPlaybook && !searchText && (
              <div className="flex flex-col gap-6 mt-2">
                <Button variant="tertiary" onClick={handleReset}>
                  <div className="flex gap-2 items-center">
                    <ArrowLeft className="size-6 shrink-0 stroke-[1.5px]" />
                    Back
                  </div>
                </Button>

                <div className="flex flex-col gap-3 mx-10">
                  <div className="flex gap-2 items-center">
                    {getPlaybookCardIcon(
                      selectedPlaybook.items.at(0)?.category || ''
                    )}

                    <TypographyBody isStrong className="line-clamp-1 break-all">
                      {selectedPlaybook.title}
                    </TypographyBody>

                    <Button
                      variant="tertiary"
                      data-tooltip-id={`playbook-prompt-${selectedPlaybook.items.at(0)?.id || ''}-tooltip`}
                      data-tooltip-place="top"
                      className="size-4"
                    >
                      <InfoCircle className="size-4 shrink-0 stroke-[1.5px] !stroke-system-placeholder" />
                    </Button>

                    <CustomTooltip
                      id={`playbook-prompt-${selectedPlaybook.items.at(0)?.id || ''}-tooltip`}
                      className="!py-1 !px-3 !rounded-sm"
                      largeArrow={false}
                    >
                      <TypographyLabel className="text-system-body whitespace-pre-wrap max-w-[20rem] text-center">
                        {selectedPlaybook.items.at(0)?.tooltip || ''}
                      </TypographyLabel>
                    </CustomTooltip>

                    <Button
                      className="ml-auto"
                      onClick={() => {
                        handleClick?.(selectedPlaybook)
                        setOpen(false)
                      }}
                    >
                      Use prompt
                    </Button>
                  </div>

                  <Divider />

                  {selectedPlaybook.items.length > 1 && (
                    <Accordion
                      type="multiple"
                      className="flex flex-col gap-2"
                      value={openedPlaybookPrompt}
                      onValueChange={(v) => setOpenedPlaybookPrompt(v)}
                    >
                      {selectedPlaybook.items.map((item) => {
                        return (
                          <AccordionItem
                            value={item.id}
                            className="flex flex-col gap-2 border-none"
                          >
                            <AccordionTrigger showChevron={false}>
                              <div className="flex gap-3 items-center pt-3">
                                {openedPlaybookPrompt.includes(item.id) ? (
                                  <ChevronUp className="size-6 shrink-0 stroke-interactive" />
                                ) : (
                                  <ChevronDown className="size-6 shrink-0 stroke-interactive" />
                                )}

                                <TypographyBody isStrong>
                                  {item.option || ''}
                                </TypographyBody>
                              </div>
                            </AccordionTrigger>
                            <AccordionContent>
                              <div className="flex flex-col gap-2 ml-9">
                                <TypographyBody className="text-system-body">
                                  Prompt details
                                </TypographyBody>

                                <div className="p-6 bg-system-secondary border border-system-border-light rounded-[12px] text-system-body">
                                  <ResponseMarkdown
                                    text={replacePlaybookVariable(item.prompt)}
                                    documents={[]}
                                    citations={[]}
                                    overrides={{
                                      Badge: {
                                        component: ({
                                          children,
                                        }: {
                                          children: string
                                        }) => (
                                          <Badge variant="purple">
                                            {children}
                                          </Badge>
                                        ),
                                      },
                                    }}
                                    isTable={false}
                                    openedCitation={null}
                                  />
                                </div>
                              </div>
                            </AccordionContent>
                          </AccordionItem>
                        )
                      })}
                    </Accordion>
                  )}

                  {selectedPlaybook.items.length === 1 && (
                    <div className="flex flex-col gap-2 ml-9">
                      <TypographyBody className="text-system-body">
                        Prompt details
                      </TypographyBody>

                      <div className="p-6 bg-system-secondary border border-system-border-light rounded-[12px] text-system-body">
                        <ResponseMarkdown
                          text={replacePlaybookVariable(
                            selectedPlaybook.items.at(0)?.prompt || ''
                          )}
                          documents={[]}
                          citations={[]}
                          overrides={{
                            Badge: {
                              component: ({
                                children,
                              }: {
                                children: string
                              }) => <Badge variant="purple">{children}</Badge>,
                            },
                          }}
                          isTable={false}
                          openedCitation={null}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </DialogBody>
        </DialogContent>
      </Dialog>

      <PlaybookCreateDialog
        open={showCreateDialog || Boolean(createDialogPlaybook)}
        setOpen={(v) => {
          if (!v) {
            setShowCreateDialog(false)
            setCreateDialogPlaybook(null)
            setOpen(true)
          }
        }}
        playbook={createDialogPlaybook || undefined}
      />
    </>
  )
}
