import {
  AssistantAskMode,
  DESIA_EVENT,
  Dossier,
  QueryStatus,
  ResponseChatList,
} from '../../types/types'
import { TypographyBody, TypographyH3, TypographyLabel } from '../ui/Typography'
import { ChatBox } from './ChatBox'
import { SuggestionCard } from './SuggestionCard'
import { useEffect, useRef, useState } from 'react'
import { useSocketQuery } from '@/hooks/useSocketQuery'
import { getTimestamp } from '@/utils/utils'
import { ChatList } from '../ChatList'
import { DossierBreadcrumb } from '../Dossier/DossierBreadcrumb'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import { fetchDossierDetail } from '../Dossier/dossierSlice'
import ImgNewAsk from '../../assets/img-new-ask.png'
import { actions as assistantActions } from './assistantSlice'
import { Button } from '../ui/button'
import { NotebookTabs } from 'lucide-react'
import { PlaybookDialog } from '../Playbook/PlaybookDialog'
import { PlaybookWrapper } from '../Playbook/PlaybookWrapper'
import { fetchPlaybooks } from '../Playbook/playbookThunk'

interface Props {
  dossier?: Dossier
  expertModeAvailable: boolean
  hasSourceSelector: boolean
  handleSubmit: ({
    message,
  }: {
    message: string
    mode: AssistantAskMode
  }) => void
}

export function NewAsk({ dossier, expertModeAvailable, handleSubmit }: Props) {
  const dossierDetails = useSelector(
    (state: RootState) => state.dossier.dossierDetails
  )
  const assistantStore = useSelector((state: RootState) => state.assistant)
  const playbooks = useSelector((state: RootState) => state.playbook.playbooks)

  const [showPlaybookDialog, setShowPlaybookDialog] = useState(false)

  const dispatch = useDispatch<AppDispatch>()
  const {
    state: { error, loading },
  } = useSocketQuery({
    event: DESIA_EVENT.CHAT_LIST,
    request: {
      requestId: 'list',
      timestamp: getTimestamp(),
      params: {},
    },
    options: {
      manual: false,
      cacheTimeoutMs: 60_000,
      callback: (response) => {
        dispatch(
          assistantActions.fetchThreads(response.data as ResponseChatList[])
        )
      },
    },
  })

  const containerRef = useRef<HTMLDivElement>(null)
  const appDispatch = useDispatch<AppDispatch>()

  const dossierDetail = dossierDetails[dossier?.id || '']?.data || undefined
  const sourceType = dossier ? 'dossier' : 'ask'

  const suggestedTitles = [
    'Financial statements data extraction',
    'Market research',
    'Recent emails summary',
  ]

  const suggestedPlaybooks = playbooks.data
    .filter((v) => {
      return suggestedTitles.includes(v.title) || v.items.at(0)?.pinned
    })
    .sort((a, b) => {
      const aPinned = a.items.at(0)?.pinned
      const bPinned = b.items.at(0)?.pinned
      if (aPinned) {
        return 1
      }

      if (bPinned) {
        return -1
      }
      return 0
    })

  useEffect(() => {
    // prevent scroll down after loading lists since it saves conversation scroll position when you navigate back
    if (!loading) {
      containerRef.current?.scrollIntoView({
        behavior: 'instant',
        block: 'start',
      })
    }
  }, [loading])

  useEffect(() => {
    if (!dossier) return
    appDispatch(fetchDossierDetail(dossier.id))
  }, [dossier])

  useEffect(() => {
    if (playbooks.status === QueryStatus.INITIALISED) {
      appDispatch(fetchPlaybooks())
    }
  }, [playbooks.status, appDispatch])

  useEffect(() => {
    document.title = 'Desia AI - Ask'
  }, [])

  return (
    <div>
      <div className="flex flex-col justify-center align-center h-full -mt-8">
        <div className="h-[1px] mb-0" ref={containerRef}></div>
        {dossier && (
          <DossierBreadcrumb
            dossier={dossier}
            component={
              <TypographyBody isStrong={true} className="whitespace-nowrap">
                New chat
              </TypographyBody>
            }
          />
        )}
        <div className="flex flex-col gap-[5.5rem] justify-center align-center w-full mt-8 mx-auto">
          <div className="flex flex-col gap-12">
            <div className="flex flex-col gap-0">
              <img
                src={ImgNewAsk}
                className="h-[10.25rem] w-[18.875rem] mx-auto"
              />

              <div className="flex flex-col sm:flex-row gap-3 sm:gap-8 items-center mx-auto -mt-4">
                <TypographyH3 className="w-[12.5rem] text-center sm:text-right">
                  Ask Desia
                </TypographyH3>
                <div className="h-8 w-[1px] bg-system-body hidden sm:!flex"></div>
                <TypographyLabel className="text-system-body text-center sm:text-left w-[12.5rem] hidden sm:!flex">
                  Level up your investment
                  <br /> research. Minutes, not hours.
                </TypographyLabel>

                <TypographyLabel className="text-system-body text-center sm:text-left flex sm:!hidden">
                  Level up your investment research.
                  <br />
                  Minutes, not hours.
                </TypographyLabel>
              </div>
            </div>

            <div className="flex flex-col gap-8 justify-center">
              <ChatBox
                enableDragAndDrop
                expertModeAvailable={expertModeAvailable}
                handleSubmit={handleSubmit}
                additionalControls={true}
                isConversation={false}
                dossierDetail={dossierDetail}
                canStop={false}
                sourceType={sourceType}
              />

              {playbooks.data.length > 0 && (
                <div className="flex flex-col gap-6">
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-5">
                    {suggestedPlaybooks.map((playbook, idx) => {
                      return (
                        <PlaybookWrapper
                          key={`${playbook.title}-${idx}`}
                          sourceType={sourceType}
                        >
                          <SuggestionCard playbook={playbook} />
                        </PlaybookWrapper>
                      )
                    })}
                  </div>

                  <Button
                    variant="tertiary"
                    className="mx-auto"
                    onClick={() => setShowPlaybookDialog(true)}
                  >
                    <div className="flex gap-2 items-center font-body-strong text-system-primary">
                      <NotebookTabs className="size-6 shrink-0 stroke-[1.5px]" />
                      View more in Playbook
                    </div>
                  </Button>
                </div>
              )}
            </div>
          </div>
          <div>
            <ChatList
              threads={assistantStore.list}
              loading={loading && assistantStore.list.length === 0}
              error={error}
              type="chat"
              showSearchBar
            />
          </div>
        </div>
      </div>

      <PlaybookWrapper
        sourceType={sourceType}
        onBack={() => setShowPlaybookDialog(true)}
      >
        <PlaybookDialog
          open={showPlaybookDialog}
          setOpen={setShowPlaybookDialog}
        />
      </PlaybookWrapper>
    </div>
  )
}
