import { LayoutPanelTop } from 'lucide-react'
import { Button } from '../ui/button'
import { TypographyBody, TypographyH4 } from '../ui/Typography'
import { RootState } from '@/store/store'
import { useSelector } from 'react-redux'
import { DocgenTemplate } from '@/types/types'
import { TemplateList } from './TemplateList'
import { Link } from 'react-router-dom'

export const DocGenTemplate = ({
  onNextClick,
  onSubmit,
}: {
  onNextClick: () => void
  onSubmit: (template: DocgenTemplate) => void
}) => {
  const docGenStore = useSelector((state: RootState) => state.docGen)

  const templates = docGenStore.templates

  const handleSubmit = (template: DocgenTemplate) => {
    setTimeout(() => {
      onNextClick()
      onSubmit(template)
    }, 200)
  }

  return (
    <>
      <div className="flex flex-col gap-[3.375rem] max-w-[79.5rem] mx-auto pb-10 w-full">
        <div className="flex flex-col gap-1 text-center mx-auto">
          <TypographyH4>Select template</TypographyH4>

          <TypographyBody className="text-system-body whitespace-pre-wrap">
            The template shapes the structure of your generated report
          </TypographyBody>
        </div>
        <div className="flex flex-col gap-4 w-full">
          <div className="flex gap-4 items-center w-full">
            <TypographyBody className="text-system-body">
              Need a new template? Create one from the Template page
            </TypographyBody>

            <Link to={'/templates'}>
              <Button variant="secondary" className="w-fit">
                <div className="flex gap-2 items-center">
                  <LayoutPanelTop className="size-6 shrink-0 stroke-interactive" />
                  Template gallery
                </div>
              </Button>
            </Link>
          </div>
          <div className="w-full">
            <TemplateList
              templates={templates}
              onTemplateClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </>
  )
}
