import { z } from 'zod'
import {
  Dialog,
  DialogBody,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog'
import Divider from '../ui/divider'
import { PlaybookOptionHeader } from './PlaybookOptionHeader'
import { useContext, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form, FormControl, FormField, FormItem, FormMessage } from '../ui/form'
import { TypographyBody, TypographyLabel } from '../ui/Typography'
import { Input } from '../ui/input'
import { Textarea } from '../ui/textarea'
import { Checkbox } from '../ui/checkbox'
import { Button } from '../ui/button'
import { Check, Loader2, Pin } from 'lucide-react'
import { PlaybookSourceSelector } from './PlaybookSourceSelector'
import { Playbook, PlaybookConnectors, SourceType } from '@/types/types'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store/store'
import { createPlaybook, updatePlaybook } from './playbookThunk'
import { isFulfilled } from '@reduxjs/toolkit'
import {
  convertConnectorsToPlaybookConnectors,
  convertPlaybookConnectors,
} from './playbookUtils'
import { UserContext } from '@/contexts/UserContext'
import { playbookActions } from './playbookSlice'
import { CustomAlert } from '../CustomAlert'

export const PlaybookCreateDialog = ({
  open,
  setOpen,
  initialPrompt,
  sourceType,
  playbook,
}: {
  open: boolean
  setOpen: (open: boolean) => void
  initialPrompt?: string
  sourceType?: SourceType
  playbook?: Playbook
}) => {
  const { settings } = useContext(UserContext)

  const [connectors, setConnectors] = useState<PlaybookConnectors>({
    webSearch: true,
    internalSearch: true,
    teamsSearch: true,
    outlookSearch: true,
    companiesHouseSearch: true,
    filingsSearch: true,
    transcriptsSearch: true,
    financialDataSearch: true,
  })

  const [pinned, setPinned] = useState(false)
  const [allowFileAnalysis, setAllowFileAnalysis] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const dispatch = useDispatch<AppDispatch>()

  const formRef = useRef<HTMLFormElement>(null)

  const formSchema = z.object({
    name: z.string().min(1, 'Please enter a name'),
    description: z.string().min(1, 'Please enter a description'),
    prompt: z.string().min(1, 'Please enter a prompt'),
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: '',
      description: '',
      prompt: '',
    },
  })

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setLoading(true)
    setError(false)

    if (playbook) {
      const firstItem = playbook.items.at(0)
      if (!firstItem) return
      const updatedItem = {
        ...firstItem,
        title: values.name,
        prompt: values.prompt,
        connectors: convertPlaybookConnectors(connectors, allowFileAnalysis),
        variables: null,
        tags: null,
        category: null,
        tooltip: values.description,
        position: 0,
        pinned: pinned
      }
      const result = await dispatch(updatePlaybook(updatedItem))

      if (isFulfilled(updatePlaybook)(result)) {
        setOpen(false)

        dispatch(
          playbookActions.replacePlaybook({
            title: values.name,
            items: [updatedItem],
          })
        )
      } else {
        setError(true)
      }
    } else {
      const result = await dispatch(
        createPlaybook({
          title: values.name,
          prompt: values.prompt,
          connectors: convertPlaybookConnectors(connectors, allowFileAnalysis),
          variables: null,
          tags: null,
          category: null,
          tooltip: values.description,
          position: 0,
          pinned: pinned
        })
      )

      if (isFulfilled(createPlaybook)(result) && result.payload.id) {
        setOpen(false)

        dispatch(
          playbookActions.addPlaybook({
            title: values.name,
            items: [
              {
                id: result.payload.id,
                title: values.name,
                prompt: values.prompt,
                connectors: convertPlaybookConnectors(
                  connectors,
                  allowFileAnalysis
                ),
                variables: null,
                tags: null,
                category: null,
                tooltip: values.description,
                position: 0,
                kind: 'user',
                created_at: null,
                updated_at: null,
                pinned: pinned
              },
            ],
          })
        )
      } else {
        setError(true)
      }
    }

    setLoading(false)
  }

  const handleReset = () => {
    setConnectors({
      webSearch: true,
      internalSearch: true,
      teamsSearch: true,
      outlookSearch: true,
      companiesHouseSearch: true,
      filingsSearch: true,
      transcriptsSearch: true,
      financialDataSearch: true,
    })

    setPinned(false)
    setAllowFileAnalysis(false)
    form.reset()
  }

  useEffect(() => {
    if (initialPrompt) {
      form.setValue('prompt', initialPrompt.trim())

      if (sourceType && settings.assistant.sources[sourceType]) {
        const { connectors, allowFileAnalysis } =
          convertConnectorsToPlaybookConnectors(
            settings.assistant.sources[sourceType]
          )

        setConnectors(connectors)
        setAllowFileAnalysis(allowFileAnalysis)
      }
      return
    } else if (playbook) {
      const firstItem = playbook.items.at(0)
      if (!firstItem) return
      form.setValue('name', firstItem.title)
      form.setValue('description', firstItem.tooltip || '')
      form.setValue('prompt', firstItem.prompt)

      setConnectors({
        webSearch: firstItem.connectors.includes('Web'),
        internalSearch: firstItem.connectors.includes('Library'),
        teamsSearch: firstItem.connectors.includes('Teams'),
        outlookSearch: firstItem.connectors.includes('Outlook'),
        companiesHouseSearch: firstItem.connectors.includes('Companies House'),
        filingsSearch: firstItem.connectors.includes('Filings'),
        transcriptsSearch: firstItem.connectors.includes('Transcripts'),
        financialDataSearch: firstItem.connectors.includes('Financial Data'),
      })
      setAllowFileAnalysis(firstItem.connectors.includes('Selected Files'))
      setPinned(firstItem.pinned || false)
    }
  }, [form, initialPrompt, settings, sourceType, playbook, open])

  useEffect(() => {
    if (!open) {
      handleReset()
    }
  }, [open])

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="px-8 py-6 mobile:min-w-[50rem] max-w-[calc(100vw-16rem)] gap-0">
        <DialogHeader>
          <div className="flex flex-col gap-4">
            <DialogTitle>
              <PlaybookOptionHeader playbook={playbook} />
            </DialogTitle>

            <Divider />
          </div>
        </DialogHeader>
        <DialogBody className="-mx-1.5 flex flex-col gap-6 pb-6 pt-6">
          <Form {...form}>
            <form
              ref={formRef}
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-2"
            >
              <div className="space-y-8 overflow-x-visible px-1.5">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem className="space-y-2">
                      <div className="flex flex-col gap-0">
                        <TypographyBody isStrong>Name</TypographyBody>
                        <TypographyLabel className="text-system-body">
                          Keep this short, you can add more information in the
                          description below.
                        </TypographyLabel>
                      </div>
                      <FormMessage />
                      <FormControl>
                        <Input
                          {...field}
                          placeholder="e.g. Document analysis"
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem className="space-y-2">
                      <div className="flex flex-col gap-0">
                        <TypographyBody isStrong>Description</TypographyBody>
                        <TypographyLabel className="text-system-body">
                          Will be visible under tooltip
                        </TypographyLabel>
                      </div>
                      <FormMessage />
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="prompt"
                  render={({ field }) => (
                    <FormItem className="space-y-2">
                      <div className="flex flex-col gap-0">
                        <TypographyBody isStrong>Prompt</TypographyBody>
                      </div>
                      <FormMessage />
                      <FormControl>
                        <Textarea {...field} className="min-h-[11.625rem]" />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
            </form>
          </Form>

          <div className="flex flex-col gap-4 px-1.5">
            <div className="flex flex-col gap-0">
              <TypographyBody isStrong>Sources</TypographyBody>
              <TypographyLabel className="text-system-body">
                You will only select source types at this stage, files can be
                selected every time you use the prompt
              </TypographyLabel>
            </div>

            <PlaybookSourceSelector
              connectors={connectors}
              setConnectors={setConnectors}
            />

            <Checkbox
              checked={allowFileAnalysis}
              onCheckedChange={() => setAllowFileAnalysis(!allowFileAnalysis)}
              label="Allow file analysis selection"
            />
          </div>
        </DialogBody>
        <DialogFooter>
          <div className="flex flex-col gap-6 w-full">
            <Divider />

            <Checkbox
              checked={pinned}
              onCheckedChange={() => setPinned(!pinned)}
              label={
                <div className="flex gap-1 items-center">
                  <Pin className="size-6 shrink-0 stroke-interactive -rotate-[30deg]" />{' '}
                  <TypographyBody className="text-system-primary">
                    Pin to Ask Desia
                  </TypographyBody>
                </div>
              }
            />

            {error && (
              <CustomAlert
                variant="error"
                description="Failed to create playbook. Please try again"
              />
            )}

            <div className="flex gap-2 ml-auto">
              <DialogClose asChild>
                <Button variant="secondary">Discard</Button>
              </DialogClose>

              <Button onClick={() => formRef.current?.requestSubmit()}>
                <div className="flex gap-2 items-center">
                  {loading ? (
                    <Loader2 className="size-6 shrink-0 stroke-interactive animate-spin" />
                  ) : (
                    <Check className="size-6 shrink-0 stroke-interactive" />
                  )}
                  Confirm and add
                </div>
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
