import { getFileIcon, getIntegrationIcon } from '@/utils/components'
import {
  capitalise,
  checkSourceDocumentType,
  getDomain,
  getHostname,
  getIconSrc,
  handleOpenLink,
  parseFilingDate,
  toPascalCase,
} from '@/utils/utils'
import { ExternalLink, Files, Mic, Paperclip, ScanSearch } from 'lucide-react'
import { TypographyLabel } from './ui/Typography'
import {
  IntegrationCode,
  SourceDocumentMetadata,
  SourceDocumentType,
} from '@/types/types'
import { MiddleTruncate } from '@re-dev/react-truncate'
import { formatDate } from 'date-fns'
import { Checkbox } from './ui/checkbox'
import AvatarPlaceholder from '@/assets/AvatarPlaceholder.svg'
import IconFinancialData from '@/assets/icon-financial-data.png'
import IconCompaniesHouse from '@/assets/IconCompaniesHouse.png'
import { CustomTooltip } from './CustomTooltip'

interface SourceProps {
  id: string
  url: string
  title?: string
  text?: string
  documentLink?: string
  showBorder?: boolean
  metadata?: SourceDocumentMetadata
  showCheckbox?: boolean
  selected?: boolean
  previewable?: boolean
  onSelect?: () => void
  onClick?: () => void
}

interface SourceContentProps extends SourceProps {
  isSearchResult?: boolean
}

interface SourceHeaderProps extends SourceProps {
  isSearchResult?: boolean
}

interface SourceIconProps extends SourceProps {
  isSearchResult?: boolean
}

export const Source = ({
  id,
  url,
  title,
  text,
  documentLink,
  showBorder,
  showCheckbox = false,
  selected,
  onSelect,
  metadata,
  previewable,
  onClick,
}: SourceProps) => {
  const style = `flex px-3 pb-2.5 pt-3  group ${showBorder ? 'border' : ''} border-system-border-regular bg-system-secondary rounded-lg cursor-pointer`

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!previewable) {
      handleOpenLink({
        id: id,
        url: url,
        title: title || '',
        documentLink: documentLink || metadata?.external_link,
        documentSource: metadata?.document_source,
        centralStoragePath: metadata?.central_storage_path,
        window,
      })
    } else {
      e.stopPropagation()
      onClick?.()
    }
  }

  return (
    <div
      key={`source_${id}`}
      className={style}
      onClick={(e) => {
        if (!showCheckbox) {
          handleClick(e)
        }
      }}
    >
      <div className={`flex gap-2 flex-grow max-w-full`}>
        {showCheckbox && (
          <div className="flex items-center">
            <Checkbox
              checked={selected}
              onClick={(e) => {
                e.stopPropagation()
                onSelect?.()
              }}
            />
          </div>
        )}

        <div
          onClick={handleClick}
          className={`flex gap-2 flex-grow max-w-full`}
        >
          <div className={`flex flex-col gap-2 flex-grow max-w-full`}>
            <SourceHeader id={id} url={url} metadata={metadata} />

            <div className="flex items-center group hover:bg-system-hover p-0.5 border-4 border-system-secondary hover:border-system-hover rounded-md active:bg-system-border-light active:border-system-border-light focus-visible:border-system-primary focus-visible:outline-none focus-visible:border-2 focus-visible:p-0.5">
              <SourceContent
                id={id}
                url={url}
                text={text}
                title={title}
                metadata={metadata}
              />

              <div className="w-6 ml-auto mr-2">
                {previewable ? (
                  <ScanSearch className="w-6 h-6 hidden shrink-0 stroke-interactive group-hover:flex" />
                ) : (
                  <ExternalLink className="w-6 h-6 hidden shrink-0 stroke-interactive group-hover:flex group-focus-visible:flex" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const SourceHeader = ({
  metadata,
  isSearchResult,
}: SourceHeaderProps) => {
  const updatedDateString =
    metadata?.company_details?.transcript_creation_date ||
    metadata?.company_details?.filing_date ||
    metadata?.document_sent_at_datetime ||
    metadata?.document_created_at_datetime ||
    metadata?.document_updated_at_desia ||
    metadata?.document_updated_at_source ||
    metadata?.document_created_at_source
  const formattedDateString =
    updatedDateString && formatDate(new Date(updatedDateString), 'd MMM yyyy')

  if (!formattedDateString) return null

  return (
    <div
      className={`flex gap-2 ${isSearchResult ? 'items-start mt-1' : 'items-center'}`}
    >
      <div
        className={`${isSearchResult ? 'mt-0.5' : ''} max-w-[calc(100%-2rem)]`}
      >
        <TypographyLabel className="text-system-body whitespace-nowrap overflow-hidden text-ellipsis">
          {formattedDateString}
        </TypographyLabel>
      </div>
    </div>
  )
}

export const SourceContent = ({
  id,
  url,
  text,
  title,
  metadata,
  isSearchResult,
}: SourceContentProps) => {
  const isThirdPartyData = metadata?.label === 'third_party_data'

  const sourceDocumentType = checkSourceDocumentType(id, metadata)
  const isDesiaLibrary = sourceDocumentType === SourceDocumentType.DESIA_LIBRARY
  const isDocument = sourceDocumentType === SourceDocumentType.LIBRARY
  const isWeb = sourceDocumentType === SourceDocumentType.WEB
  const isOutlook = sourceDocumentType === SourceDocumentType.OUTLOOK
  const isTeams = sourceDocumentType === SourceDocumentType.TEAMS
  const isIntegration =
    sourceDocumentType === SourceDocumentType.ONEDRIVE ||
    sourceDocumentType === SourceDocumentType.SHAREPOINT
  const isFinancialData =
    sourceDocumentType === SourceDocumentType.FINANCIAL_DATA
  const isCompaniesHouse =
    sourceDocumentType === SourceDocumentType.COMPANIES_HOUSE
  const isTranscripts = sourceDocumentType === SourceDocumentType.TRANSCRIPTS
  const isFilings = sourceDocumentType === SourceDocumentType.FILINGS

  const style = `${isSearchResult ? 'font-body-strong' : 'font-label-strong'} text-system-primary line-clamp-1 break-all`

  const path = metadata?.document_source_path
  const formattedPath =
    'in ' + path?.replaceAll('>', '/').split(' / ').slice(0, -1).join(' / ')
  const end = (formattedPath?.split('/').at(-1)?.length || 0) + 2

  return (
    <div
      className={`${isWeb ? 'cursor-pointer' : ''} flex gap-3 items-center w-full`}
      onClick={() => {
        if (isWeb && url?.length > 0) {
          window.open(url, '_blank')
        }
      }}
    >
      <div className={`${isOutlook || isTeams || isIntegration ? 'mb-1' : ''}`}>
        <SourceIcon id={id} url={url} title={title} metadata={metadata} />
      </div>
      <div className="flex flex-col gap-0 flex-grow max-w-[calc(100%-2rem)]">
        <div className="flex gap-3 items-center mr-2">
          {isCompaniesHouse && (
            <div
              className={style}
            >{`Full accounts made up to ${parseFilingDate(metadata?.company_details?.filing_for_date || metadata?.filing_for_date || '')}`}</div>
          )}

          {isFilings && (
            <div className={style}>
              {`${metadata?.company_details?.filing_category || metadata?.filing_category || ''} for FY ended ${parseFilingDate(metadata?.filing_for_date || metadata?.company_details?.filing_for_date || '')}`}
            </div>
          )}

          {isTranscripts && (
            <div className={style}>
              {metadata?.transcript_description ||
                metadata?.company_details?.transcript_description ||
                ''}
            </div>
          )}

          {!isCompaniesHouse && !isTranscripts && !isFilings && (
            <div className={style}>
              {isThirdPartyData ? text : title || text}
            </div>
          )}

          {(metadata?.attachment_count || 0) > 0 && (
            <Paperclip className="size-5 shrink-0 stroke-interactive stroke-system-body" />
          )}
        </div>

        {isDesiaLibrary && (
          <TypographyLabel className="text-system-body line-clamp-1 break-all">
            Desia library
          </TypographyLabel>
        )}

        {isWeb && (
          <TypographyLabel className="text-system-body line-clamp-1 break-all">
            {getDomain(getHostname(url))}
          </TypographyLabel>
        )}

        {path && isDocument && !isDesiaLibrary && (
          <>
            <MiddleTruncate
              end={end}
              className="w-full flex-grow font-label text-system-body hidden sm:!flex"
            >
              {formattedPath}
            </MiddleTruncate>
            <TypographyLabel className="w-full flex-grow font-label text-system-body flex sm:!hidden !line-clamp-1">
              {formattedPath}
            </TypographyLabel>
          </>
        )}

        {isDocument && !path && !isDesiaLibrary && (
          <TypographyLabel className="text-system-body whitespace-nowrap overflow-hidden text-ellipsis">
            {capitalise(
              metadata?.document_visibility === 'organization'
                ? 'shared'
                : metadata?.document_visibility || ''
            )}
          </TypographyLabel>
        )}

        {isOutlook && (
          <TypographyLabel className="text-system-body line-clamp-1 break-all">
            {metadata?.sender?.email_address}
          </TypographyLabel>
        )}

        {isTeams && (
          <TypographyLabel className="text-system-body line-clamp-1 break-all">
            {metadata?.sender?.name}
          </TypographyLabel>
        )}

        {isFinancialData && (
          <TypographyLabel className="text-system-body line-clamp-1 break-all">
            Financial data
          </TypographyLabel>
        )}

        {isCompaniesHouse && (
          <TypographyLabel className="text-system-body line-clamp-1 break-all">
            {toPascalCase(
              metadata?.company_details?.company_details?.element_name ||
                metadata?.title ||
                ''
            )}{' '}
            · Companies House UK
          </TypographyLabel>
        )}

        {isFilings && (
          <TypographyLabel className="text-system-body line-clamp-1 break-all">
            {toPascalCase(
              metadata?.company_details?.company_details?.element_name ||
                metadata?.title ||
                ''
            )}{' '}
            · Filings
          </TypographyLabel>
        )}

        {isTranscripts && (
          <TypographyLabel className="text-system-body line-clamp-1 break-all">
            {toPascalCase(
              metadata?.company_details?.company_details?.element_name ||
                metadata?.title ||
                ''
            )}{' '}
            · Transcripts
          </TypographyLabel>
        )}
      </div>
    </div>
  )
}

export const SourceIcon = ({ id, url, title, metadata }: SourceIconProps) => {
  const sourceDocumentType = checkSourceDocumentType(id, metadata)
  const isDocument = sourceDocumentType === SourceDocumentType.LIBRARY
  const isOutlook = sourceDocumentType === SourceDocumentType.OUTLOOK
  const isTeams = sourceDocumentType === SourceDocumentType.TEAMS
  const isOneDrive = sourceDocumentType === SourceDocumentType.ONEDRIVE
  const isSharepoint = sourceDocumentType === SourceDocumentType.SHAREPOINT
  const isFinancialData =
    sourceDocumentType === SourceDocumentType.FINANCIAL_DATA
  const isCompaniesHouse =
    sourceDocumentType === SourceDocumentType.COMPANIES_HOUSE
  const isFilings = sourceDocumentType === SourceDocumentType.FILINGS
  const isTranscripts = sourceDocumentType === SourceDocumentType.TRANSCRIPTS
  const isWeb = sourceDocumentType === SourceDocumentType.WEB

  const showFileIcon =
    isDocument || (!isOutlook && !isTeams && !isFinancialData && !isWeb)

  return (
    <div
      className={`relative size-6`}
      data-tooltip-id={`source-icon-tooltip-${id}`}
    >
      {showFileIcon &&
        getFileIcon(
          metadata?.document_type_friendly || title?.split('.').at(-1) || '',
          'w-6 h-6'
        )}

      {(isOutlook || isTeams) && (
        <img className="size-6 min-w-6 shrink-0" src={AvatarPlaceholder} />
      )}

      {(isSharepoint || isOneDrive) && metadata?.document_source_details && (
        <div
          className={`absolute -bottom-2 -right-2 flex items-center justify-center size-[1.125rem] rounded-full border-interactive border-system-border-light bg-system-secondary`}
        >
          {getIntegrationIcon(
            metadata?.document_source_details?.integration_code_name || '',
            true,
            'w-3.5 shrink-0'
          )}
        </div>
      )}

      {isOutlook && (
        <div
          className={`absolute -bottom-2 -right-2 flex items-center justify-center size-[1.125rem] rounded-full border-interactive border-system-border-light bg-system-secondary shrink-0`}
        >
          {getIntegrationIcon(IntegrationCode.OUTLOOK, true, 'w-2.5 shrink-0')}
        </div>
      )}

      {isTeams && (
        <div
          className={`absolute -bottom-2 -right-2 flex items-center justify-center size-[1.125rem] rounded-full border-interactive border-system-border-light bg-system-secondary shrink-0`}
        >
          {getIntegrationIcon(IntegrationCode.TEAMS, true, 'w-2.5 shrink-0')}
        </div>
      )}

      {isFinancialData && (
        <img
          src={IconFinancialData}
          className={`size-6 p-0.5 min-w-6 min-h-6 border-interactive border-system-border-light rounded-sm shrink-0`}
        />
      )}

      {isCompaniesHouse && (
        <img
          src={IconCompaniesHouse}
          className={`absolute -bottom-1 -right-1 size-[0.875rem] min-w-[0.875rem] min-h-[0.875rem] rounded-full shrink-0 border border-system-border-light bg-black`}
        />
      )}

      {isFilings && (
        <div className="absolute -bottom-1 -right-1 min-w-[0.875rem] min-h-[0.875rem] rounded-full border border-system-border-light bg-[#913EF7]">
          <Files className="size-3 p-[0.15625rem] shrink-0 stroke-interactive stroke-system-secondary" />
        </div>
      )}

      {isTranscripts && (
        <div className="absolute -bottom-1 -right-1 min-w-[0.875rem] min-h-[0.875rem] rounded-full border border-system-border-light bg-[#485FF1]">
          <Mic className="size-3 p-[0.15625rem] shrink-0 stroke-interactive stroke-system-secondary" />
        </div>
      )}

      {url && isWeb && (
        <img
          src={getIconSrc(getHostname(url))}
          className={`size-6 min-w-6 min-h-6 border-interactive border-system-border-light rounded-sm shrink-0`}
        />
      )}

      <CustomTooltip
        id={`source-icon-tooltip-${id}`}
        className="!py-1 !px-3 !rounded-sm !inner-border-0"
        largeArrow={false}
      >
        <TypographyLabel className="text-system-primary">
          {url && isWeb && getHostname(url).replace('www.', '')}
          {isCompaniesHouse && 'Companies House'}
          {isFilings && 'Global companies filings'}
          {isTranscripts && 'Earning call transcripts'}
          {isFinancialData && 'Financial Data'}
          {isTeams && 'Teams'}
          {isOutlook && 'Outlook'}
          {showFileIcon &&
            !isCompaniesHouse &&
            !isFilings &&
            !isTranscripts &&
            title}
        </TypographyLabel>
      </CustomTooltip>
    </div>
  )
}
