import {
  ArrowRight,
  CircleHelpIcon,
  Copy,
  Loader2,
  LockKeyhole,
  Sparkles,
  UsersRound,
} from 'lucide-react'
import { Button, tertiaryStyle } from '../ui/button'
import {
  TypographyBody,
  TypographyH3,
  TypographyH4,
  TypographyLabel,
} from '../ui/Typography'
import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  ReactNode,
} from 'react'
import { LoadingAnimation } from '../LoadingAnimation/LoadingAnimation'
import { LayoutContext } from '@/contexts/LayoutContext'
import {
  Citation,
  DocgenCitation,
  DocgenContentTheme,
  DocgenSession,
  DocgenSessionStatus,
  DocgenTemplate,
  DocumentPreviewType,
  QueryStatus,
  RequestDocGenUpdateReport,
  ResponseDocGenReport,
  ResponseDocument,
  SourceDocument,
  TableType,
  WebSocketRequestWrapper,
} from '@/types/types'
import { CustomAlert } from '../CustomAlert'
import { ResponseMarkdown } from '../Assistant/ResponseMarkdown'
import {
  embedCharts,
  embedCitationsV4,
  getCitationIds,
} from '@/utils/embedCitations'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import { fetchSession, fetchTemplates, updateSession } from './docGenThunk'
import { isFulfilled } from '@reduxjs/toolkit'
import { PreviewSources } from '../Assistant/PreviewSources'
import {
  convertHeadersToBold,
  getCitationExtractResource,
  getCitationHighlights,
  getHostname,
  getOrganizationName,
  getTimestamp,
} from '@/utils/utils'
import { DocumentPreviewContainer } from '../Resources/DocumentPreview'
import { listDocuments } from '../Document/documentThunk'
import { OutputBlockData, OutputData } from '@editorjs/editorjs'
import * as editorjs from '@editorjs/editorjs'
import {
  requestCreateReport,
  requestSaveReport,
  resetCreateReportData,
} from './docGenSlice'
import { useNavigate } from 'react-router-dom'
import { DocGenThemeDialog } from './DocGenThemeDialog'
import ExportToWord from '../Reports/ExportToWord'
import { Checkbox } from '../ui/checkbox'
import { TemplateBadge } from './TemplateBadge'
import {
  getDocGenSourceDocuments,
  getSourceDocumentPlaceholder,
  getSourceDocumentPlaceholders,
} from './utils'
import { ResponseTable } from '../Table/ResponseTable'
import { ResponseChart, ResponseChartError } from '../Charts/ResponseChart'
import { MarkdownToJSX } from 'markdown-to-jsx'
import { CitationTag, DocgenCitationTag } from '../Assistant/CitationTag'
import { Sources } from '../Assistant/Sources'
import { Badge } from '../ui/badge'
import Divider from '../ui/divider'
import Checkmark from '@/assets/Checkmark'
import { UserContext } from '@/contexts/UserContext'
import { DocGenVisibilityDialog } from './DocGenVisibilityDialog'
import { ResponseTableError } from '../Table/ResponseTableError'
import { NumberCitation } from '../Assistant/NumberCitation'
import { handleError } from '@/utils/handleError'

function removeSectionNameFromSynthesizeOutput(
  paragraph: string | undefined,
  sectionName: string
): string {
  const lines = (paragraph || '').split('\n')
  const header = lines[0].trim()

  const cleanedHeader = header.replace(/[#*\_!\[\]()\-]/g, '').trim()

  if (cleanedHeader.includes(sectionName)) {
    const newParagraph = lines.slice(1)
    while (newParagraph.length > 0 && newParagraph[0].trim() === '') {
      newParagraph.shift()
    }

    return newParagraph.join('\n')
  }

  return paragraph || ''
}

export const DocGenResponseMarkdownWrapper = ({
  embeddedText,
  documents,
  citations,
  openedCitation,
  overrides,
}: {
  embeddedText: string
  documents: SourceDocument[]
  citations: Citation[]
  openedCitation: Citation | null
  overrides: MarkdownToJSX.Overrides
}) => {
  return (
    <div className="!text-system-body">
      <ResponseMarkdown
        text={embeddedText}
        documents={documents}
        citations={citations}
        isTable={false}
        openedCitation={openedCitation}
        overrides={{
          ...overrides,
          Chart: {
            component: ({ id }: { id: string }) => {
              const document = documents.find((v) => v.document_id === id)
              if (!document) {
                console.warn('no document found for', id)
                return <ResponseChartError />
              }

              return (
                <div className="mb-3">
                  <ResponseChart
                    key={`response-chart-container-${id}`}
                    id={id}
                    data={document.text}
                    compact={true}
                    citations={citations}
                    documents={documents}
                  />
                </div>
              )
            },
          },
          Table: {
            component: ({ id }: { id: string }) => {
              const document = documents.find((v) => v.document_id === id)
              if (!document) {
                console.warn('no document found for', id)
                return <ResponseTableError />
              }
              return (
                <div className="mb-3">
                  <ResponseTable
                    key={`response-table-container-${id}`}
                    id={id}
                    data={document.text}
                    documents={documents}
                    citations={citations}
                    openedCitation={null}
                    type={TableType.DEFAULT}
                    overrides={overrides}
                  />
                </div>
              )
            },
          },
        }}
      />
    </div>
  )
}

const convertDocgenCitationsToCitations = ({
  citations,
  chart_table_id,
  index_x,
  index_y,
  text,
}: {
  citations: DocgenCitation[]
  chart_table_id?: string
  index_x?: number
  index_y?: number
  text?: string
  documents?: SourceDocument[]
}): Citation[] => {
  return citations.map((citation) => {
    return {
      text: text ?? citation.title,
      start: 0,
      end: text ? text.length : 0,
      document_ids: citation.document_id ? [citation.document_id] : [],
      highlights: citation.snippets.map((v) => {
        return {
          file_id: citation.document_id,
          highlight: v,
          page_number: citation.page_number,
        }
      }),
      chart_table_id: chart_table_id,
      index_x: index_x,
      index_y: index_y,
      citation_uuid: citation.citation_uuid,
    }
  })
}

export const DocGenDraft = ({
  session,
  template,
  topic,
}: {
  session: DocgenSession | null
  template: DocgenTemplate | null
  topic: string | null
}) => {
  const { showWelcomeAnimation, toggleShowWelcomeAnimation } =
    useContext(LayoutContext)

  const documentStore = useSelector((state: RootState) => state.document)
  const createReport = useSelector(
    (state: RootState) => state.docGen.createReport
  )
  const templateCategories = useSelector(
    (state: RootState) => state.docGen.templateCategories.data
  )

  const { user } = useContext(UserContext)

  const [loading, setLoading] = useState(
    session?.status !== DocgenSessionStatus.CREATED
  )
  const [ready, setReady] = useState(false)
  const [error, setError] = useState(false)
  const [currentSession, setCurrentSession] = useState<DocgenSession | null>(
    null
  )
  const [selectedSectionIndexes, setSelectedSectionIndexes] = useState<
    number[]
  >([])
  const [openedCitation, setOpenedCitation] = useState<Citation | null>(null)
  const [selectedSource, setSelectedSource] = useState<SourceDocument | null>(
    null
  )
  const [uniqueCitationDocuments, setUniqueCitationDocuments] = useState<
    SourceDocument[]
  >([])
  const [selectedExtractIndex, setSelectedExtractIndex] = useState<{
    [id: string]: number
  }>({})
  const [selectedSectionTheme, setSelectedSectionTheme] = useState<
    DocgenContentTheme[] | null
  >(null)
  const [isUpdatingSession, setIsUpdatingSession] = useState(false)
  const [loadingEmail, setLoadingEmail] = useState(false)
  const [copyLinkSuccess, setCopyLinkSuccess] = useState(false)
  const [showVisibilityDialog, setShowVisibilityDialog] = useState(false)

  const draftContainerRef = useRef<HTMLDivElement>(null)
  const interval = useRef<NodeJS.Timeout | null>(null)
  const sourceContainerRef = useRef<HTMLDivElement>(null)

  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()

  const draftContainerHeight =
    draftContainerRef.current?.getBoundingClientRect().height || 0

  const allCitations = Object.values(
    currentSession?.content?.knowledge_base.citations || []
  ).flatMap((v) => v)

  const documents = useMemo(() => {
    const documentMap = new Map(
      documentStore.files.map((doc) => [doc.document_id, doc])
    )
    const result: ResponseDocument[] = []
    allCitations.forEach((citation) => {
      const doc = documentMap.get(citation.document_id)
      if (doc) {
        result.push(doc)
      }
    })

    return [...new Set(result).values()]
  }, [allCitations, documentStore.files])

  const sourceDocuments = useMemo(() => {
    const documentMap = new Map(
      documentStore.files.map((doc) => [doc.document_id, doc])
    )

    const result: SourceDocument[] = []

    allCitations.forEach((citation) => {
      const doc = documentMap.get(citation.document_id)
      if (doc) {
        result.push({
          document_id: doc.document_id,
          doc_metadata: { ...doc },
          title: doc.document_name,
          url: doc.document_secure_shared_link,
          text: doc.document_name,
        })
      } else {
        if (getHostname(citation.document_id)) {
          result.push({
            document_id: `web_search-${citation.citation_uuid}`,
            title: citation.title,
            url: citation.document_id,
            text: citation.title,
          })
        } else {
          const placeholder = getSourceDocumentPlaceholder(citation)
          if (placeholder) result.push(placeholder)
        }
      }
    })

    return Array.from(
      new Map(result.map((item) => [item.document_id, item])).values()
    )
  }, [allCitations, documentStore.files])

  // fixme
  const figuresSourceDocuments = useMemo(() => {
    const sourceDocuments =
      currentSession?.content?.knowledge_base?.tree?.children?.map(
        (section) => {
          const tableDocuments: SourceDocument[] =
            section?.tables?.map((table) => {
              const { data, reference, citations, headers } = table || {}
              const transformedTable = {
                headers,
                ...data,
                citations,
              }

              return {
                document_id: reference || '',
                title: '',
                text: JSON.stringify(Array(transformedTable)),
              }
            }) || []

          const chartDocuments: SourceDocument[] =
            section?.charts?.map((chart: any) => {
              return {
                document_id: chart?.reference || '',
                title: '',
                text: JSON.stringify(chart),
              }
            }) || []

          return [...tableDocuments, ...chartDocuments]
        }
      ) || []

    return sourceDocuments
  }, [currentSession?.content?.knowledge_base?.tree?.children])

  const figuresCitations: {
    citations: Citation[]
    documents: SourceDocument[]
  }[] = useMemo(() => {
    const indexes =
      currentSession?.content?.knowledge_base?.tree?.children?.map(
        (section) => {
          if (!section?.tables) return { citations: [], documents: [] }

          const tableCitationAndDocuments: {
            citations: Citation[]
            documents: SourceDocument[]
          }[] =
            section?.tables?.flatMap((table) => {
              const { data, headers, citations: citationsMap } = table ?? {}

              if (!citationsMap) return []

              return Object.entries(citationsMap).reduce<{
                citations: Citation[]
                documents: SourceDocument[]
              }>(
                (acc, [key, column]) => {
                  const x = headers.findIndex((header) => key === header)

                  if (x === -1) return acc

                  Object.entries(column).forEach(([_y, row]) => {
                    try {
                      const y = parseInt(_y)
                      const convertedCitations =
                        convertDocgenCitationsToCitations({
                          citations: row.map((r) => {
                            return allCitations[r]
                          }),
                          chart_table_id: table.reference,
                          index_x: x,
                          index_y: y + 1, // compensate for headers only in citations index
                          text: data[headers[x]]?.[y],
                        })

                      const combinedCitation =
                        convertedCitations.length > 0
                          ? convertedCitations.reduce((acc, next) => {
                              return {
                                ...acc,
                                document_ids: [
                                  ...acc.document_ids,
                                  ...next.document_ids,
                                ],
                                highlights: [
                                  ...(acc.highlights || []),
                                  ...(next.highlights || []),
                                ],
                              }
                            })
                          : null

                      if (combinedCitation) {
                        const docs = [
                          ...new Set(combinedCitation.document_ids).values(),
                        ]
                        acc.citations.push({
                          ...combinedCitation,
                          document_ids: docs,
                        })
                        acc.documents.push(
                          ...getDocGenSourceDocuments(
                            combinedCitation,
                            sourceDocuments
                          )
                        )
                      }
                    } catch (e: any) {
                      handleError(e)
                      console.warn(
                        `docgen citations are malformed for y:${parseInt(_y) + 1} x:${x}`
                      )
                    }
                  })
                  return acc
                },
                {
                  citations: [],
                  documents: [],
                }
              )
            }) || []

          const transformed = {
            citations: tableCitationAndDocuments.flatMap(
              ({ citations }) => citations
            ),
            documents: [
              ...new Map(
                tableCitationAndDocuments.flatMap(
                  ({ documents: documentIds }) =>
                    documentIds.map((doc) => [doc.document_id, doc])
                )
              ).values(),
            ],
          }

          return transformed
        }
      ) || []

    return indexes
  }, [currentSession?.content?.knowledge_base?.tree?.children])

  const openedCitationHighlights = useMemo(() => {
    return getCitationHighlights(openedCitation, selectedSource)
  }, [openedCitation, selectedSource])

  const openedCitationResource = useMemo(() => {
    return getCitationExtractResource(openedCitationHighlights, selectedSource)
  }, [selectedSource, openedCitationHighlights])

  const showLoading =
    loading &&
    !ready &&
    session?.status !== DocgenSessionStatus.CREATED &&
    session
  const showReady = loading && ready
  const showSpinner =
    loading &&
    !ready &&
    (session?.status === DocgenSessionStatus.CREATED || !session)

  const refetchSession = useCallback(
    async (options?: { refetch?: boolean }) => {
      if (!session?.id || isUpdatingSession) return
      const result = await dispatch(
        fetchSession({
          sessionId: session.id,
          refetch: options?.refetch,
        })
      )

      if (isFulfilled(fetchSession)(result)) {
        const payload = result.payload
        setCurrentSession(payload)

        if (
          payload?.status === DocgenSessionStatus.CREATED ||
          payload.status === DocgenSessionStatus.DRAFT_ERROR
        ) {
          if (session.status !== payload.status) {
            setReady(payload.status === DocgenSessionStatus.CREATED)
          } else {
            setLoading(false)
          }

          if (payload.status === DocgenSessionStatus.DRAFT_ERROR) {
            setError(true)
            setLoading(false)
          }

          if (interval.current) {
            clearInterval(interval.current)
          }
        }
      }
    },
    [dispatch, session?.id, isUpdatingSession]
  )

  const handleSectionSelection = (indexes: number[]) => {
    setSelectedSectionIndexes(indexes)

    const element = document.getElementById(
      `draft-content-${indexes.join('-')}`
    )

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  const getBlockData = (
    text: string,
    blockIndex: number,
    preserveFormatting: boolean
  ) => {
    const citationIds = getCitationIds(text)
    const preprocessedText = preserveFormatting
      ? text
      : convertHeadersToBold(text)
    const embeddedText = embedCitationsV4(preprocessedText, allCitations)
    const sectionCitations = allCitations.filter((v) =>
      citationIds.includes(v.citation_uuid)
    )
    const citationDocuments = sourceDocuments.filter((v) =>
      sectionCitations.some((c) => c.document_id === v.document_id)
    )

    return {
      text: embeddedText,
      citations: convertDocgenCitationsToCitations({
        citations: sectionCitations,
      }),
      documents: [
        ...citationDocuments,
        ...(figuresSourceDocuments[blockIndex] ?? []),
      ], // fixme
    }
  }

  const convertTreeToBlocks = (preserveFormatting: boolean) => {
    const blocks: OutputBlockData[] = []

    currentSession?.content?.knowledge_base.tree.children.forEach(
      (section, index) => {
        blocks.push({
          id: crypto.randomUUID(),
          type: 'FinalAnswer',
          data: getBlockData(`# ${section.name}`, index, preserveFormatting),
        })

        blocks.push({
          id: crypto.randomUUID(),
          type: 'FinalAnswer',
          data: getBlockData(
            removeSectionNameFromSynthesizeOutput(
              section.synthesize_output as string,
              section.name
            ) || '',
            index,
            preserveFormatting
          ),
        })

        section.children.forEach((section) => {
          blocks.push({
            id: crypto.randomUUID(),
            type: 'FinalAnswer',
            data: getBlockData(
              `#### ${section.name}`,
              index,
              preserveFormatting
            ),
          })

          blocks.push({
            id: crypto.randomUUID(),
            type: 'FinalAnswer',
            data: getBlockData(
              section.synthesize_output || '',
              index,
              preserveFormatting
            ),
          })

          section.children.forEach((section) => {
            blocks.push({
              id: crypto.randomUUID(),
              type: 'FinalAnswer',
              data: getBlockData(
                `**${section.name}**`,
                index,
                preserveFormatting
              ),
            })

            blocks.push({
              id: crypto.randomUUID(),
              type: 'FinalAnswer',
              data: getBlockData(
                section.synthesize_output || '',
                index,
                preserveFormatting
              ),
            })
          })
        })
      }
    )

    return blocks
  }

  const handleCreateReport = async () => {
    if (createReport.loading) return
    dispatch(
      requestCreateReport({
        requestId: 'docgen:create_report',
        params: {},
        timestamp: getTimestamp(),
      })
    )
  }

  const handleUpdateReport = (report: ResponseDocGenReport) => {
    const outputData: OutputData = {
      version: editorjs.default.version,
      time: getTimestamp(),
      // dont preserve formatting so that headers will change to body strong in report writer
      blocks: convertTreeToBlocks(false),
    }

    const request: WebSocketRequestWrapper<RequestDocGenUpdateReport> = {
      requestId: report.id,
      timestamp: getTimestamp(),
      params: {
        report_id: report.id,
        title: session?.topic || '',
        content: JSON.stringify(outputData),
      },
    }

    dispatch(requestSaveReport(request))
    toggleShowWelcomeAnimation(true)

    setTimeout(() => {
      navigate(`/report/${report.id}?docgen=true`)
    }, 3000)

    dispatch(resetCreateReportData())
  }

  const handleUpdateSendEmail = async (value: boolean) => {
    if (!currentSession) return
    setIsUpdatingSession(true)
    setLoadingEmail(true)
    const updatedSession = {
      ...currentSession,
      send_email_when_ready: value,
    }
    await dispatch(updateSession(updatedSession))
    setIsUpdatingSession(false)
    setLoadingEmail(false)
    setCurrentSession({ ...currentSession, send_email_when_ready: value })
    refetchSession({ refetch: true })
  }

  useEffect(() => {
    refetchSession()

    interval.current = setInterval(
      () =>
        refetchSession({
          refetch: true,
        }),
      10000
    )

    return () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
    }
  }, [session, refetchSession])

  useEffect(() => {
    if (!template) {
      dispatch(fetchTemplates())
    }
  }, [template, dispatch])

  useEffect(() => {
    if (createReport.data) {
      handleUpdateReport(createReport.data)
    }
  }, [createReport.data])

  useEffect(() => {
    if (documentStore.fetchStatus === QueryStatus.INITIALISED) {
      dispatch(listDocuments())
    }
  }, [documentStore.fetchStatus, dispatch])

  useEffect(() => {
    if (session) {
      setLoading(session.status !== DocgenSessionStatus.CREATED)
      setCurrentSession(session)
    }
    if (session === null) {
      setLoading(true)
    }
  }, [session])

  const DocGenCitationTagWrapper = ({ id }: { id: string }) => {
    const parsedId = JSON.parse(id)
    const citations = Object.entries(
      currentSession?.content?.knowledge_base.citations || {}
    )
      .filter((v) => parsedId.includes(parseInt(v[0])))
      .map((v) => v[1])

    if (citations.length === 0) return null
    const convertedCitation: Citation[] = convertDocgenCitationsToCitations({
      citations: citations,
    })

    const combinedCitation =
      convertedCitation.length > 0
        ? convertedCitation.reduce((acc, next) => {
            return {
              ...acc,
              document_ids: [...acc.document_ids, ...next.document_ids],
              highlights: [
                ...(acc.highlights || []),
                ...(next.highlights || []),
              ],
            }
          })
        : null

    if (!combinedCitation) return null
    const filteredSourceDocuments =
      getDocGenSourceDocuments(combinedCitation, sourceDocuments) ||
      getSourceDocumentPlaceholders(combinedCitation)

    if (filteredSourceDocuments.length === 0) return null
    return (
      <DocgenCitationTag
        documents={filteredSourceDocuments}
        selected={openedCitation === combinedCitation}
        onClick={() => {
          setOpenedCitation(combinedCitation)
          setSelectedSource(filteredSourceDocuments[0])
          setUniqueCitationDocuments(filteredSourceDocuments)
        }}
      />
    )
  }

  return (
    <>
      <div
        className={`flex flex-col ${loading ? 'gap-[3.375rem]' : 'gap-6'} w-full ${!loading && !error ? 'min-h-screen' : ''} mx-auto`}
      >
        {loading && (
          <div className="flex flex-col gap-1 text-center mx-auto mt-10">
            <div className="flex gap-2 mx-auto items-center">
              <TypographyH4>{`Generating ${topic || 'topic'}`}</TypographyH4>
              {template && (
                <TemplateBadge
                  template={template}
                  categories={templateCategories}
                  sessionTemplate={currentSession?.template}
                />
              )}
            </div>
            {session && session.status !== DocgenSessionStatus.CREATED && (
              <TypographyBody className="text-system-body whitespace-pre-wrap">
                {
                  'We are cross-checking multiple data sources to ensure all data is\naccurate and up-to-date, hence this step takes a while'
                }
              </TypographyBody>
            )}
          </div>
        )}

        {showLoading && (
          <div className="flex flex-col gap-6 w-[29.625rem] mx-auto p-6 bg-system-secondary border border-system-border-regular rounded-[12px]">
            <div className="flex gap-4 items-center">
              <img
                className="size-7 animate-logo-animation"
                src={
                  'https://cdn.prod.website-files.com/66bccd21862b191477dc8806/66bce0be1491cc6deb168141_symbol.svg'
                }
              />

              <div className="flex flex-col gap-1">
                <div className="flex gap-2 items-center">
                  <TypographyBody isStrong>
                    This will take 15-20 minutes
                  </TypographyBody>
                </div>
              </div>
            </div>

            {loadingEmail && (
              <div className="flex gap-2 items-center">
                <Loader2 className="size-5 shrink-0 stroke-interactive animate-spin" />
                <TypographyBody className="text-system-primary">
                  Notify me via email when the report is ready.
                </TypographyBody>
              </div>
            )}

            {currentSession && !loadingEmail && (
              <Checkbox
                checked={currentSession.send_email_when_ready || false}
                label="Notify me via email when the report is ready."
                onCheckedChange={() => {
                  if (currentSession) {
                    handleUpdateSendEmail(
                      !(currentSession.send_email_when_ready || false)
                    )
                  }
                }}
              />
            )}
          </div>
        )}

        {showSpinner && (
          <div className="mx-auto">
            <Loader2 className="size-6 shrink-0 stroke-[1.5px] animate-spin" />
          </div>
        )}

        {showReady && (
          <div className="flex gap-3 items-center w-[29.625rem] mx-auto p-6 bg-system-secondary border border-system-border-regular rounded-[12px]">
            <Sparkles className="size-6 shrink-0 stroke-[1.5px]" />
            <div className="flex flex-col gap-1 w-full">
              <TypographyBody isStrong>Your report is ready</TypographyBody>

              <TypographyLabel>Have a look!</TypographyLabel>
            </div>

            <Button
              onClick={() => {
                setTimeout(() => {
                  setLoading(false)
                  setReady(false)
                }, 500)
                toggleShowWelcomeAnimation(true)
              }}
            >
              <div className="flex gap-2 items-center">
                View
                <ArrowRight className="size-6 shrink-0 stroke-[1.5px]" />
              </div>
            </Button>
          </div>
        )}

        {error && (
          <div className="w-fit mx-auto">
            <CustomAlert
              variant="error"
              description="We were unable to generate or display a draft."
            />
          </div>
        )}

        {!loading && !error && (
          <div className="flex flex-col">
            <div className="fixed top-[7.25rem] flex flex-col gap-1 py-4 w-full ">
              <div
                className={`flex flex-col gap-1 w-[20rem] max-w-[calc((100%-57.5625rem)/2-1.5rem)] ${selectedSource ? 'opacity-0' : 'opacity-100'} `}
              >
                {currentSession?.content?.knowledge_base.tree.children.map(
                  (section, firstIndex) => {
                    const isSelected =
                      JSON.stringify(selectedSectionIndexes) ===
                      JSON.stringify([firstIndex])
                    return (
                      <Fragment key={`draft-tree-${firstIndex}`}>
                        <div
                          className={`${tertiaryStyle} flex gap-2 px-3 py-1 w-full cursor-pointer !border-0 !border-l-[3px] ${isSelected ? '!border-l-system-primary bg-system-surface-light' : '!border-l-transparent'}`}
                          onClick={() => handleSectionSelection([firstIndex])}
                        >
                          <TypographyLabel className="text-system-placeholder">
                            {firstIndex + 1}
                          </TypographyLabel>
                          <TypographyLabel className="text-system-primary !font-label-strong">
                            {section.name}
                          </TypographyLabel>
                        </div>
                        {section.children.map((section, secondIndex) => {
                          const isSelected =
                            JSON.stringify(selectedSectionIndexes) ===
                            JSON.stringify([firstIndex, secondIndex])
                          return (
                            <Fragment
                              key={`draft-tree-${firstIndex}-${secondIndex}`}
                            >
                              <div
                                className={`${tertiaryStyle} flex gap-2 px-3 py-1 pl-[2rem] w-full cursor-pointer !border-0 !border-l-[3px] ${isSelected ? '!border-l-system-primary bg-system-surface-light' : '!border-l-transparent'}`}
                                onClick={() =>
                                  handleSectionSelection([
                                    firstIndex,
                                    secondIndex,
                                  ])
                                }
                              >
                                <TypographyLabel className="text-system-placeholder">
                                  {`${firstIndex + 1}.${secondIndex + 1}`}
                                </TypographyLabel>
                                <TypographyLabel className="text-system-primary">
                                  {section.name}
                                </TypographyLabel>
                              </div>

                              {section.children.map((section, thirdIndex) => {
                                const isSelected =
                                  JSON.stringify(selectedSectionIndexes) ===
                                  JSON.stringify([
                                    firstIndex,
                                    secondIndex,
                                    thirdIndex,
                                  ])
                                return (
                                  <Fragment
                                    key={`draft-tree-${firstIndex}-${secondIndex}-${thirdIndex}`}
                                  >
                                    <div
                                      className={`${tertiaryStyle} flex gap-2 px-3 py-1 pl-[3.25rem] w-full cursor-pointer !border-0 !border-l-[3px] ${isSelected ? '!border-l-system-primary bg-system-surface-light' : '!border-l-transparent'}`}
                                      onClick={() =>
                                        handleSectionSelection([
                                          firstIndex,
                                          secondIndex,
                                          thirdIndex,
                                        ])
                                      }
                                    >
                                      <TypographyLabel className="text-system-placeholder">
                                        {`${firstIndex + 1}.${secondIndex + 1}.${thirdIndex + 1}`}
                                      </TypographyLabel>
                                      <TypographyLabel className="text-system-primary">
                                        {section.name}
                                      </TypographyLabel>
                                    </div>
                                  </Fragment>
                                )
                              })}
                            </Fragment>
                          )
                        })}
                      </Fragment>
                    )
                  }
                )}
              </div>
              <div className="w-[57.5625rem]"></div>
              <div className="w-[calc((100%-57.5625rem)/2-0.75rem)]"></div>
            </div>

            <div
              ref={draftContainerRef}
              className={`absolute top-[1.5rem] left-[50%] -translate-x-[50%] flex flex-col gap-0 mx-auto z-[2]`}
            >
              {/* Empty div needed for sticky behaviour */}
              <div className="h-0"></div>
              <div className="flex gap-4 monitor:gap-10">
                <div className="flex flex-col gap-3 w-[57.5625rem]">
                  <div className="flex gap-2 items-center mx-auto">
                    <TypographyH4 className="line-clamp-1 break-all">
                      {topic || ''}
                    </TypographyH4>

                    {template && (
                      <TemplateBadge
                        template={template}
                        categories={templateCategories}
                        sessionTemplate={currentSession?.template}
                      />
                    )}
                  </div>
                  <div className="flex gap-0 mx-auto">
                    <PreviewSources
                      documents={sourceDocuments}
                      compact={false}
                      maxIcons={3}
                      loading={false}
                      sourceType={'ask'}
                      canRetry={false}
                    />
                  </div>

                  {currentSession?.content?.knowledge_base.tree.children.map(
                    (section, firstIndex) => {
                      const firstEmbeddedText = embedCharts({
                        text: embedCitationsV4(
                          removeSectionNameFromSynthesizeOutput(
                            section.synthesize_output as string,
                            section.name
                          ) || '',
                          allCitations
                        ),
                      })
                      const relevantDocuments = figuresSourceDocuments // fixme
                        ? figuresSourceDocuments[firstIndex]
                        : []

                      const relevantCitations = figuresCitations
                        ? figuresCitations[firstIndex].citations
                        : []

                      const relevantCitationsDocuments = figuresCitations
                        ? figuresCitations[firstIndex].documents
                        : []

                      return (
                        <div
                          key={`draft-content-${firstIndex}`}
                          className="px-[5.125rem] py-12  bg-system-secondary border border-system-border-light rounded-lg mb-4"
                        >
                          <div
                            className="flex flex-col gap-4 px-3"
                            id={`draft-content-${firstIndex}`}
                          >
                            <div className="flex gap-2 items-center">
                              <TypographyH3 className="text-system-primary">
                                {section.name}
                              </TypographyH3>
                              {(section.themes?.length || 0) > 0 && (
                                <Button
                                  variant="tertiary"
                                  className="ml-auto h-fit"
                                  onClick={() =>
                                    setSelectedSectionTheme(
                                      section.themes || []
                                    )
                                  }
                                >
                                  <CircleHelpIcon className="size-6 shrink-0 stroke-[1.5px]" />
                                </Button>
                              )}
                            </div>
                            {section.children && section.children.length > 0 ? (
                              section.children.map((child, secondIndex) => {
                                const secondEmbeddedText = embedCharts({
                                  text: embedCitationsV4(
                                    removeSectionNameFromSynthesizeOutput(
                                      child.synthesize_output as string,
                                      section.name
                                    ),
                                    allCitations
                                  ),
                                })
                                return (
                                  <Fragment
                                    key={`draft-content-${firstIndex}-${secondIndex}`}
                                  >
                                    <div
                                      className="flex flex-col gap-3 py-1.5"
                                      id={`draft-content-${firstIndex}-${secondIndex}`}
                                    >
                                      <TypographyH4 className="text-system-primary">
                                        {child.name}
                                      </TypographyH4>

                                      <div className="!text-system-body [&_p]:text-justify">
                                        <DocGenResponseMarkdownWrapper
                                          embeddedText={secondEmbeddedText}
                                          documents={relevantDocuments}
                                          citations={[]}
                                          openedCitation={null}
                                          overrides={{
                                            DocGenCitationTag: {
                                              component: ({
                                                id,
                                              }: {
                                                id: string
                                              }) => {
                                                return (
                                                  <DocGenCitationTagWrapper
                                                    id={id}
                                                  />
                                                )
                                              },
                                            },
                                          }}
                                        />
                                      </div>
                                    </div>

                                    {/* render third level children if any */}
                                    {child.children &&
                                      child.children.length > 0 &&
                                      child.children.map(
                                        (grandchild, thirdIndex) => {
                                          const thirdEmbeddedText = embedCharts(
                                            {
                                              text: embedCitationsV4(
                                                removeSectionNameFromSynthesizeOutput(
                                                  grandchild.synthesize_output as string,
                                                  section.name
                                                ),
                                                allCitations
                                              ),
                                            }
                                          )

                                          return (
                                            <Fragment
                                              key={`draft-content-${firstIndex}-${secondIndex}-${thirdIndex}`}
                                            >
                                              <div
                                                className="flex flex-col gap-2"
                                                id={`draft-content-${firstIndex}-${secondIndex}-${thirdIndex}`}
                                              >
                                                <TypographyBody
                                                  isStrong
                                                  className="text-system-primary"
                                                >
                                                  {grandchild.name}
                                                </TypographyBody>

                                                <div className="!text-system-body">
                                                  <DocGenResponseMarkdownWrapper
                                                    embeddedText={
                                                      thirdEmbeddedText
                                                    }
                                                    documents={
                                                      relevantDocuments
                                                    }
                                                    citations={[]}
                                                    openedCitation={null}
                                                    overrides={{
                                                      DocGenCitationTag: {
                                                        component: ({
                                                          id,
                                                        }: {
                                                          id: string
                                                        }) => {
                                                          return (
                                                            <DocGenCitationTagWrapper
                                                              id={id}
                                                            />
                                                          )
                                                        },
                                                      },
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </Fragment>
                                          )
                                        }
                                      )}
                                  </Fragment>
                                )
                              })
                            ) : (
                              // section has no children, render only its content
                              <div className="!text-system-body">
                                <DocGenResponseMarkdownWrapper
                                  embeddedText={firstEmbeddedText}
                                  documents={[
                                    ...relevantDocuments,
                                    ...relevantCitationsDocuments,
                                  ]}
                                  citations={relevantCitations}
                                  openedCitation={openedCitation}
                                  overrides={{
                                    DocGenCitationTag: {
                                      component: ({ id }: { id: string }) => {
                                        return (
                                          <DocGenCitationTagWrapper id={id} />
                                        )
                                      },
                                    },
                                    NumberCitation: {
                                      component: ({
                                        children,
                                        citation,
                                      }: {
                                        children: ReactNode
                                        citation: string
                                      }) => {
                                        return (
                                          <NumberCitation
                                            children={children}
                                            citation={citation}
                                            citations={relevantCitations}
                                            documents={[
                                              ...relevantDocuments,
                                              ...relevantCitationsDocuments,
                                            ]}
                                            scrollToCitation={false}
                                            openedCitation={openedCitation}
                                            onCitationOpen={(citation) => {
                                              setOpenedCitation(citation)
                                              setSelectedSource(
                                                relevantCitationsDocuments[0]
                                              )
                                              setUniqueCitationDocuments(
                                                relevantCitationsDocuments
                                              )
                                            }}
                                          />
                                        )
                                      },
                                    },
                                    CitationTag: {
                                      component: ({
                                        citation,
                                      }: {
                                        citation: string
                                      }) => {
                                        return (
                                          <CitationTag
                                            citation={citation}
                                            citations={relevantCitations}
                                            documents={[
                                              ...relevantDocuments,
                                              ...relevantCitationsDocuments,
                                            ]}
                                            scrollToCitation={false}
                                            openedCitation={openedCitation}
                                            onCitationOpen={(citation) => {
                                              const filteredRelevantCitationDocuments =
                                                relevantCitationsDocuments.filter(
                                                  (doc) =>
                                                    citation?.document_ids?.some(
                                                      (docId) =>
                                                        doc.document_id.includes(
                                                          docId
                                                        )
                                                    )
                                                )
                                              setOpenedCitation(citation)
                                              setSelectedSource(
                                                filteredRelevantCitationDocuments[0]
                                              )
                                              setUniqueCitationDocuments(
                                                filteredRelevantCitationDocuments
                                              )
                                            }}
                                          />
                                        )
                                      },
                                    },
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    }
                  )}
                </div>

                <div
                  ref={sourceContainerRef}
                  className={`${selectedSource || uniqueCitationDocuments.length > 0 ? 'w-[40rem] monitor:w-[calc(100vw-15rem-2.5rem-57.5625rem)]' : 'w-0'} transition-width ease-in-out duration-300`}
                >
                  {uniqueCitationDocuments.length > 0 && (
                    <div
                      className={`sticky right-0 top-0 w-[40rem] monitor:w-[calc(100vw-15rem-1rem-46.875rem)] max-w-[40rem] ease-in-out duration-300 ${selectedSource ? 'hidden' : ''}`}
                    >
                      <Sources
                        documents={uniqueCitationDocuments}
                        showTabs={false}
                        onSourceClick={(v) => {
                          setSelectedSource(v)
                        }}
                        previewable={true}
                      />
                    </div>
                  )}

                  {selectedSource && openedCitationResource && (
                    <div className="sticky right-0 top-0 w-[40rem] monitor:w-[calc(100vw-15rem-2.5rem-57.5625rem)]">
                      <DocumentPreviewContainer
                        key={`document-preview-container-${selectedSource.document_id}`}
                        type={DocumentPreviewType.ASK}
                        resource={openedCitationResource}
                        selectedExtractIndex={
                          selectedExtractIndex[selectedSource.document_id] || 0
                        }
                        setSelectedExtractIndex={(index) => {
                          setSelectedExtractIndex({
                            ...selectedExtractIndex,
                            [selectedSource.document_id]: index,
                          })
                        }}
                        sources={uniqueCitationDocuments}
                        selectedSource={selectedSource}
                        // - 32 (preview padding) - 48 (page padding)
                        initialWidth={
                          window.innerWidth > 1600
                            ? window.innerWidth - 240 - 40 - 921 - 32 - 48
                            : 500 - 32 - 48
                        }
                        onClose={() => {
                          setSelectedSource(null)
                          setOpenedCitation(null)
                          setUniqueCitationDocuments([])
                        }}
                        setSelectedSource={(v) => setSelectedSource(v)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div
              className=""
              style={{ height: `${draftContainerHeight - 400}px` }}
            ></div>
          </div>
        )}
      </div>

      {showWelcomeAnimation && <LoadingAnimation />}
      {!loading &&
        !error &&
        !(selectedSource && openedCitationResource) &&
        uniqueCitationDocuments.length === 0 && (
          <div className="fixed flex flex-col gap-4 max-w-[15.5rem] w-full [@media(min-width:1720px)]:max-w-[21.3125rem] px-3 py-4 bottom-7 right-7 rounded-lg border border-system-border-regular bg-system-secondary z-[3]">
            <div className="bg-system-surface-light px-3 py-[2px] rounded-sm">
              <TypographyBody className="text-system-body">
                Visibility
              </TypographyBody>
            </div>

            <div className="flex gap-2 items-center pl-2">
              {currentSession?.visibility === 'organization' && (
                <div className="flex gap-2 items-center">
                  <UsersRound className="size-6 shrink-0 stroke-interactive" />
                  {getOrganizationName(user?.app_metadata?.organization_id)}
                </div>
              )}

              {currentSession?.visibility === 'private' && (
                <div className="flex gap-2 items-center">
                  <LockKeyhole className="size-6 shrink-0 stroke-interactive" />
                  Private
                </div>
              )}

              {user?.id === currentSession?.created_by && (
                <Button
                  variant="secondary"
                  className="ml-auto"
                  onClick={() => setShowVisibilityDialog(true)}
                >
                  Change
                </Button>
              )}
            </div>
            <Button
              variant="secondary"
              onClick={() => {
                navigator.clipboard.writeText(location.href)
                setCopyLinkSuccess(true)
                setTimeout(() => setCopyLinkSuccess(false), 3000)
              }}
            >
              <div className="flex gap-2 items-center">
                {copyLinkSuccess ? (
                  <>
                    <Checkmark className="size-6 shrink-0 stroke-interactive" />
                    Copied
                  </>
                ) : (
                  <>
                    <Copy className="size-6 shrink-0 stroke-interactive" />
                    Copy share link
                  </>
                )}
              </div>
            </Button>

            <div className="bg-system-surface-light px-3 py-[2px] rounded-sm">
              <TypographyBody className="text-system-body">
                Export
              </TypographyBody>
            </div>

            <div className="flex flex-col justify-end gap-2">
              <ExportToWord
                report={{
                  version: editorjs.default.version,
                  time: getTimestamp(),
                  // preserve formatting because will be preprocessed later on in DocumentExporter
                  blocks: convertTreeToBlocks(true),
                }}
                templateTitle={session?.title}
                reportTitle={session?.topic}
                isButton
              />
              <Divider />
              <Button
                className="w-full"
                onClick={handleCreateReport}
                variant="tertiary"
              >
                <div className="flex gap-2 items-center w-full">
                  Switch to Editor
                  <Badge variant="purple">Beta</Badge>
                  <ArrowRight className="size-6 shrink stroke-[1.5px] ml-auto" />
                </div>
              </Button>
            </div>
          </div>
        )}

      <DocGenThemeDialog
        open={Boolean(selectedSectionTheme)}
        setOpen={() => {
          setSelectedSectionTheme(null)
        }}
        themes={selectedSectionTheme}
        documents={documents}
        sourceDocuments={sourceDocuments}
      />

      <DocGenVisibilityDialog
        open={showVisibilityDialog}
        setOpen={setShowVisibilityDialog}
        session={currentSession}
        template={template}
        onVisibilityUpdate={() => {
          const isPrivate = currentSession?.visibility === 'private'
          if (!currentSession) return
          setCurrentSession({
            ...currentSession,
            visibility: isPrivate ? 'organization' : 'private',
          })
        }}
      />
    </>
  )
}
