import {
  formatNumberString,
  getDisplayNumberMaxDecimalPlaces,
  reformatTableDate,
} from '@/utils/utils'
import {
  calculateCellWidth,
  copyResponse,
  getTableCellData,
  getTableResponsiveRules,
  parseHeaderForDates,
} from '@/utils/table'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/table'
import { DefaultTableProps } from '@/types/types'
import { ResponseMarkdown } from '../Assistant/ResponseMarkdown'
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Button } from '../ui/button'
import { Check, Copy } from 'lucide-react'
import { ResponseTableError } from './ResponseTableError'

export const DefaultTable = ({
  id,
  data,
  documents,
  citations,
  openedCitation,
  compact,
  overrides,
  showCopyButton = true,
  isExpand = false,
  isShouldShift = false,
  isFormatted = true,
}: DefaultTableProps) => {
  const [copySuccess, setCopySuccess] = useState(false)
  const tableRef = useRef<HTMLTableElement>(null)
  const headerRefs = useRef<(HTMLTableCellElement | null)[]>([])
  const [isMeasured, setIsMeasured] = useState<boolean>(false)
  const [tableWidth, setTableWidth] = useState<number>(0)

  try {
    const parsedData: {
      headers: string[]
      [key: string]: any[]
    } = JSON.parse(data)[0]

    const isDateFirst = parseHeaderForDates(parsedData.headers[0])

    const { headers, ...withoutHeaders } = parsedData

    const transposed: [string, string[]][] = useMemo(() => {
      return headers.map((key) => [key, withoutHeaders[key] || []])
    }, [headers, data])

    const tableCitations = citations.filter((v) => v.chart_table_id === id)

    const transformedHeaders = isDateFirst
      ? [transposed[0][0], ...transposed[0][1]]
      : headers

    const rowCount = isDateFirst
      ? Object.keys(withoutHeaders).length
      : parsedData[parsedData.headers[0]].length

    const firstRow = isDateFirst
      ? transposed[1][1]
      : parsedData[parsedData.headers[1]]

    const removedText = formatNumberString(
      `${firstRow.length > 0 ? firstRow[0] : ''}`
    )

    const isNumber = /^\d+$/.test(removedText)

    const columnWidths = useMemo(() => {
      return transformedHeaders.map((_, index) => {
        const texts = isDateFirst
          ? transposed.reduce<string[]>((a, v) => {
              a.push(index === 0 ? v[0] : v[1][index - 1])
              return a
            }, [] as string[])
          : transposed[index]

        return calculateCellWidth({
          texts: texts.flat(),
          headerCell: headerRefs.current[index],
        })
      })
    }, [transformedHeaders, isMeasured])

    const tableResponseiveRules = useMemo(() => {
      return getTableResponsiveRules({
        tableWidth,
        isExpand,
        isShouldShift,
      })
    }, [isExpand, isShouldShift, tableWidth])

    const setHeaderRef = useCallback(
      (index: number) => (el: HTMLTableCellElement | null) => {
        if (el == null || isMeasured) return
        headerRefs.current[index] = el
        if (index == transformedHeaders.length - 1 && !isMeasured) {
          setIsMeasured(true)
        }
      },
      [transformedHeaders, transposed, isMeasured]
    )

    const setSpacerHeightRef = useCallback(
      (divRef: HTMLDivElement | null) => {
        if (divRef == null) return

        const tableHeight = tableRef.current ? tableRef.current.offsetHeight : 0
        const MARGIN_TOP = 36
        divRef.style.height = `${tableHeight + MARGIN_TOP}px`
      },
      [columnWidths]
    )

    const handleCopyTable = async () => {
      await copyResponse({
        id: id,
        data: data,
      })
      setCopySuccess(true)
      setTimeout(() => {
        setCopySuccess(false)
      }, 3000)
    }

    const checkIfColumnContainsAllNumbers = (column: number) => {
      const header = parsedData.headers.at(column)
      if (!header) return false
      return [header]
        ?.flatMap((v) => parsedData[v])
        .every((v) => {
          if (!v || v === 'N/A') return true
          const number = parseFloat(formatNumberString(v))
          return !isNaN(number)
        })
    }

    const checkIfRowIsEmpty = (row: number) => {
      return parsedData.headers
        .slice(1)
        .flatMap((v) => parsedData[v][row])
        .every((v) => {
          return !v
        })
    }

    useLayoutEffect(() => {
      // needs to be before repaint or else flickering
      if (tableWidth != tableRef.current?.clientWidth) {
        setTableWidth(tableRef.current?.clientWidth ?? 0)
      }
    }, [columnWidths, tableWidth])

    useLayoutEffect(() => {
      const savedScroll = sessionStorage.getItem(`table-scroll-${id}`)
      const table = document.getElementById(`table-${id}-container`)
      if (table && savedScroll) {
        table.scrollLeft = parseInt(savedScroll, 10)
      }
    }, [id])

    useEffect(() => {
      const handleScroll = () => {
        const table = document.getElementById(`table-${id}-container`)

        if (table) {
          sessionStorage.setItem(
            `table-scroll-${id}`,
            table.scrollLeft.toString()
          )
        }
      }

      window.addEventListener('scroll', handleScroll, { capture: true })
      return () =>
        window.removeEventListener('scroll', handleScroll, { capture: true })
    }, [id])

    return (
      <>
        <div
          id={`table-${id}-container`}
          className={`${isExpand ? 'absolute w-full left-1/2 transform -translate-x-1/2' : ''}
          overflow-x-auto flex flex-col w-full items-start`}
        >
          <div className={tableResponseiveRules}>
            <Table
              ref={tableRef}
              containerClassName={`${isExpand ? '!max-w-full !w-fit !sm:w-fit min-w-[46.875rem]' : ''}`}
              className={`${isExpand ? 'min-w-[46.875rem] w-fit' : 'w-[calc(100%-0.5rem)]'} m-0.5 outline outline-1 outline-system-border-regular rounded-lg [&>div]:rounded-lg overflow-hidden`}
              scrollable={!isExpand}
            >
              <TableHeader>
                <TableRow>
                  {transformedHeaders.map((header, x) => {
                    const isRightAligned =
                      (isNumber && x !== 0) ||
                      checkIfColumnContainsAllNumbers(x)
                    const columnWidth = columnWidths[x]
                    return (
                      <TableHead
                        key={`table-${id}-header-${x}`}
                        ref={setHeaderRef(x)}
                        className={`box-border !bg-system-primary px-6 py-2 text-system-primary !text-system-secondary border-b border-system-border-regular [&_p]:!font-body !font-body ${
                          isRightAligned ? 'text-right' : 'text-left'
                        }`}
                        style={{
                          minWidth: columnWidth,
                          width: columnWidth,
                          maxWidth: columnWidth,
                        }}
                      >
                        <ResponseMarkdown
                          text={reformatTableDate(header)}
                          documents={documents}
                          citations={citations}
                          overrides={overrides}
                          compact={compact}
                          isTable={true}
                          openedCitation={openedCitation}
                        />
                      </TableHead>
                    )
                  })}
                </TableRow>
              </TableHeader>
              <TableBody>
                {isDateFirst
                  ? transposed.map((entry, y) => {
                      if (y === 0) return null

                      const yHeader = entry[0]
                      const data = entry[1]
                      return (
                        <TableRow key={`table-${id}-row-${y}`}>
                          <TableCell className="px-6 py-2 text-system-primary [&_p]:!font-body !font-body text-left font-bold !bg-system-surface-light">
                            <ResponseMarkdown
                              text={reformatTableDate(yHeader)}
                              documents={documents}
                              citations={citations}
                              overrides={overrides}
                              compact={compact}
                              isTable={true}
                              openedCitation={openedCitation}
                            />
                          </TableCell>
                          {data.map((text, x) => {
                            const isNumber =
                              /^\d+$/.test(formatNumberString(text)) ||
                              checkIfColumnContainsAllNumbers(x)
                            const isRightAligned = isNumber && y !== 0
                            const columnWidth = columnWidths[y]

                            const { embeddedText, relevantCitations } =
                              getTableCellData({
                                value: text,
                                citations: tableCitations,
                                x: y,
                                y: x,
                                isFormatted:
                                  isFormatted &&
                                  ![headers[x], parsedData[headers[0]][y]].some(
                                    // fixme
                                    (v) => {
                                      v?.toLowerCase().includes('year')
                                    }
                                  ),
                                maxDecimalPlaces:
                                  getDisplayNumberMaxDecimalPlaces(yHeader),
                                addNotAvailabletext:
                                  x !== 0 && !checkIfRowIsEmpty(y),
                              })

                            return (
                              <TableCell
                                key={`table-${id}-cell-${y}-${x}`}
                                id={`table-${id}-cell-${y}-${x}`}
                                className={`box-border px-6 py-2 text-system-primary [&_p]:!font-body !font-body  bg-system-secondary ${
                                  isRightAligned ? 'text-right' : 'text-left'
                                }`}
                                style={{
                                  minWidth: columnWidth,
                                  width: columnWidth,
                                  maxWidth: columnWidth,
                                }}
                              >
                                <ResponseMarkdown
                                  text={embeddedText}
                                  documents={documents}
                                  citations={relevantCitations}
                                  overrides={overrides}
                                  compact={compact}
                                  isTable={true}
                                  openedCitation={openedCitation}
                                />
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })
                  : [...Array(rowCount).keys()].map((y) => {
                      return (
                        <TableRow key={`table-${id}-row-${y}`}>
                          {transformedHeaders.map((header, x) => {
                            const isNumber =
                              /^\d+$/.test(
                                formatNumberString(parsedData[header][y])
                              ) || checkIfColumnContainsAllNumbers(x)

                            const isRightAligned = isNumber && x !== 0
                            const columnWidth = columnWidths[x]
                            const text: string | number = parsedData[header][y]

                            const { embeddedText, relevantCitations } =
                              getTableCellData({
                                value: text,
                                citations: tableCitations,
                                x: x,
                                y: y,
                                isFormatted:
                                  isFormatted &&
                                  ![headers[x], parsedData[headers[0]][y]].some(
                                    (v) => v.toLowerCase().includes('year')
                                  ),
                                maxDecimalPlaces:
                                  getDisplayNumberMaxDecimalPlaces(
                                    parsedData[headers[0]][y]
                                  ),
                                addNotAvailabletext:
                                  x !== 0 && !checkIfRowIsEmpty(y),
                              })

                            return (
                              <TableCell
                                key={`table-${id}-cell-${x}-${y}`}
                                id={`table-${id}-cell-${x}-${y}`}
                                className={`${x === 0 ? '!bg-system-surface-light ' : '!bg-system-secondary'} text-system-primary [&_p]:!font-body !font-body box-border px-6 py-2 ${isRightAligned ? 'text-right' : 'text-left'}`}
                                style={{
                                  minWidth: columnWidth,
                                  width: columnWidth,
                                  maxWidth: columnWidth,
                                }}
                              >
                                <ResponseMarkdown
                                  text={embeddedText}
                                  documents={documents}
                                  citations={relevantCitations}
                                  overrides={overrides}
                                  compact={compact}
                                  isTable={true}
                                  openedCitation={openedCitation}
                                />
                              </TableCell>
                            )
                          })}
                        </TableRow>
                      )
                    })}
              </TableBody>
            </Table>
          </div>
        </div>
        {isExpand && <div ref={setSpacerHeightRef} />}
        {showCopyButton && (
          <div className="ml-auto flex justify-end">
            <Button
              variant="tertiary"
              onClick={handleCopyTable}
              className="ml-auto mt-6"
            >
              <div className="flex gap-2 items-center">
                {copySuccess ? (
                  <Check className="size-6 stroke-[1.5px]" />
                ) : (
                  <Copy className="size-6 stroke-[1.5px]" />
                )}

                {copySuccess ? 'Copied' : 'Copy table'}
              </div>
            </Button>
          </div>
        )}
      </>
    )
  } catch (e: any) {
    console.warn(e.message)
    return <ResponseTableError />
  }
}
