import { Button, secondaryStyle } from '../ui/button'
import { Card } from '../ui/card'
import { TypographyLabel } from '../ui/Typography'
import { DocgenSessionStatus } from '@/types/types'
import { Link } from 'react-router-dom'
import Divider from '../ui/divider'
import DraftThumbnailImage from '@/assets/img-draft-thumbnail.png'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../ui/dropdown-menu'
import { EllipsisVertical, Sparkles } from 'lucide-react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import { deleteSession } from '../DocGen/docGenThunk'
import { removeSession } from '../DocGen/docGenSlice'
import { isFulfilled } from '@reduxjs/toolkit'
import CloseCircleFill from '@/assets/CloseCircleFill'
import { getDocgenSessionUrl } from '@/utils/utils'
import { formatDate, isToday, isYesterday } from 'date-fns'
import { TemplateBadge } from '../DocGen/TemplateBadge'

const getStatusText = (status: DocgenSessionStatus | null): string => {
  switch (status) {
    case DocgenSessionStatus.THEME_GENERATED:
      return 'Outline ready: review to continue'
    case DocgenSessionStatus.THEME_ERROR:
      return 'Outline generation failed'
    case DocgenSessionStatus.CREATING:
      return 'Draft generating...'
    case DocgenSessionStatus.CREATED:
      return 'Draft report'
    case DocgenSessionStatus.DRAFT_ERROR:
      return 'Draft generation failed'
    case DocgenSessionStatus.THEME_GENERATING:
      return 'Outline generating...'
    case DocgenSessionStatus.TEMPLATE_CREATED:
      return 'Template created'
    default:
      return ''
  }
}

export const SessionCard = ({ sessionId }: { sessionId: string }) => {
  const dispatch = useDispatch<AppDispatch>()

  const session = useSelector((state: RootState) =>
    state.docGen.sessions.data?.find((r) => r.id === sessionId)
  )

  const templates = useSelector((state: RootState) => state.docGen.templates)
  const templateCategories = useSelector(
    (state: RootState) => state.docGen.templateCategories.data
  )

  const template = templates?.find((r) => r.id === session?.template?.id)

  const handleDelete = async () => {
    if (!session?.id) return
    const result = await dispatch(deleteSession(session.id))

    if (isFulfilled(deleteSession)(result)) {
      dispatch(removeSession(session.id))
    }
  }

  if (!session) return null

  return (
    <Link
      to={getDocgenSessionUrl(session)}
      className="block no-underline text-inherit"
    >
      <Card
        className={`${secondaryStyle} p-4 shadow-none !rounded-md [&:not(:active)]:border-system-border-light [&:hover:not(:has(.child-hover:hover))]:!bg-system-hover hover:bg-system-secondary cursor-pointer`}
      >
        <div className="flex flex-col gap-6 h-full">
          <div className="flex flex-col gap-3">
            <div
              className={`flex ${session?.status === DocgenSessionStatus.THEME_GENERATED ? 'gap-1' : 'gap-2'} items-center ${session.status === DocgenSessionStatus.CREATING || session.status === DocgenSessionStatus.THEME_GENERATING ? 'animate-docGenPulse' : ''}`}
            >
              {(session?.status === DocgenSessionStatus.THEME_GENERATING ||
                session?.status === DocgenSessionStatus.CREATING) && (
                <Sparkles className="size-5 shrink-0" />
              )}

              {session?.status === DocgenSessionStatus.THEME_GENERATED && (
                <div className="size-1.5 bg-messaging-positive-graphic rounded-full mb-auto"></div>
              )}

              {(session?.status === DocgenSessionStatus.DRAFT_ERROR ||
                session?.status === DocgenSessionStatus.THEME_ERROR) && (
                <CloseCircleFill className="size-5 shrink-0" />
              )}

              {session.status === DocgenSessionStatus.CREATED ||
              session.status === null ? (
                <TypographyLabel className="text-system-body">
                  {`Draft updated ${isToday(session.updated_at || '') ? 'today' : isYesterday(session.updated_at || '') ? 'yesterday' : ` on ${formatDate(session.updated_at || '', 'd MMM')}`}`}
                </TypographyLabel>
              ) : (
                <TypographyLabel
                  className={`text-system-primary shrink-0 ${session?.status === DocgenSessionStatus.THEME_GENERATED ? '!font-label-strong' : ''} `}
                >
                  {getStatusText(session.status)}
                </TypographyLabel>
              )}
            </div>

            <Divider className="bg-system-border-light" />
            <div className="flex flex-col gap-3">
              <div>
                <TemplateBadge
                  template={template}
                  categories={templateCategories}
                  sessionTemplate={session?.template}
                />
              </div>
              <div className="flex gap-4 items-center">
                <img className="h-10" src={DraftThumbnailImage} />

                <div className="flex flex-col gap-0.5 w-[calc(100%-6.25rem)]">
                  <div className="line-clamp-2 break-all font-body-strong w-full">
                    {session.topic || session.title}
                  </div>
                </div>

                <DropdownMenu>
                  <DropdownMenuTrigger className="flex items-center" asChild>
                    <Button
                      variant="tertiary"
                      className="child-hover"
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                      }}
                    >
                      <EllipsisVertical className="w-6 h-6 shrink-0 stroke-[1.5px]" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="start">
                    <DropdownMenuItem
                      variant="tertiary-destructive"
                      onClick={(e) => {
                        e.stopPropagation()
                        handleDelete()
                      }}
                    >
                      Delete
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Link>
  )
}
