import { DocgenTemplate, TemplateCategory } from '@/types/types'
import { Badge } from '../ui/badge'
import { getTemplateColor } from './utils'
import { Sparkles } from 'lucide-react'
import { TypographyLabel } from '../ui/Typography'

export const TemplateBadge = ({
  template,
  categories,
  sessionTemplate,
}: {
  template: DocgenTemplate | null | undefined
  categories: TemplateCategory[]
  sessionTemplate?: {
    category_id: string | null
    copy_of: string | null
    id: string
    name: string
    title: string
  }
}) => {
  if (!template)
    return (
      <Badge
        variant={getTemplateColor(sessionTemplate?.category_id || undefined, categories)}
        rounded={false}
        className="shrink-0"
      >
        <div className="flex gap-2 items-center shrink-0 w-fit">
          <Sparkles className="size-4 stroke-[1.5px]" />
          <TypographyLabel className="line-clamp-1 break-all shrink-0 w-fit">
            {sessionTemplate?.title || 'Template deleted, report still available'}
          </TypographyLabel>
        </div>
      </Badge>
    )

  return (
    <Badge
      variant={getTemplateColor(template.category_id || undefined, categories)}
      rounded={false}
      className="shrink-0"
    >
      <div className="flex gap-2 items-center shrink-0 w-fit">
        <Sparkles className="size-4 stroke-[1.5px]" />
        <TypographyLabel className="line-clamp-1 break-all shrink-0 w-fit">
          {template.title || ''}
        </TypographyLabel>
      </div>
    </Badge>
  )
}
