/// <reference types="vite-plugin-svgr/client" />
import { LayoutPanelTop, PenToolIcon, Sparkles } from 'lucide-react'
import { Button } from '../ui/button'
import { TypographyBody, TypographyH3 } from '../ui/Typography'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import { useContext, useEffect, useMemo, useState } from 'react'
import { setSessionInUse } from '../DocGen/docGenSlice'
import { getOrganizationName } from '@/utils/utils'
import {
  DocgenSession,
  DocgenStep,
  DocgenTemplate,
  QueryStatus,
  TemplateCategory,
} from '@/types/types'
import { ReportList } from './ReportList'
import { Input } from '../ui/input'
import { Link, useNavigate } from 'react-router-dom'
import DocGenHomeImage from '../../assets/img-home-doc-gen.svg?react'
import { NotAvailableDialog } from '../Resources/NotAvailableDialog'
import { DocGenWalkthroughDialog } from '../DocGen/DocGenWalkthroughDialog'
import {
  fetchSessions,
  fetchTemplateCategories,
  fetchTemplates,
} from '../DocGen/docGenThunk'
import { Badge } from '../ui/badge'
import { Checkbox } from '../ui/checkbox'
import { UserContext } from '@/contexts/UserContext'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../ui/accordion'
import { LocalStorageKey } from '@/constants'

export interface MockReport {
  title: string
  description: string
}

// todo: implement pagination
export function ReportsPage({
  sessions,
  error,
  loading,
}: {
  sessions: DocgenSession[]
  loading: boolean
  error: any
}) {
  const templates = useSelector((state: RootState) => state.docGen.templates)
  const templateCategories = useSelector(
    (state: RootState) => state.docGen.templateCategories
  )

  const { user } = useContext(UserContext)

  const [searchText, setSearchText] = useState('')
  const [templateSearchText, setTemplateSearchText] = useState('')
  const [showNotAvailableDialog, setShowNotAvailableDialog] = useState(false)
  const [showDocGenWalkthrough, setShowDocGenWalkthrough] = useState(false)
  const [visibilityFilter, setVisibilityFilter] = useState([
    'organization',
    'private',
  ])
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>([])

  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()

  const filteredSessions = useMemo(
    () =>
      sessions
        .filter((v) => {
          return (
            visibilityFilter.includes(v.visibility || '') &&
            (selectedTemplates.includes(v.template?.id || '') ||
              selectedTemplates.includes(v.template?.copy_of || ''))
          )
        })
        .filter((v) => {
          if (!searchText) {
            return true
          }
          const splitText = searchText.toLowerCase().split(' ')
          return splitText.some(
            (text) =>
              v.title.toLowerCase().includes(text) ||
              v.topic.toLowerCase().includes(text)
          )
        }),
    [searchText, sessions, visibilityFilter, selectedTemplates]
  )

  const filteredCategories = useMemo(() => {
    const noCategoryTemplates = templates.filter((v) => !v.category_id)
    const categories =
      noCategoryTemplates.length > 0
        ? [
            ...templateCategories.data,
            {
              id: null,
              title: 'No category',
              name: 'No category',
              description: '',
            } as TemplateCategory,
          ]
        : templateCategories.data

    return categories.filter((v) => {
      if (!templateSearchText) return true

      const splitText = templateSearchText
        .split(' ')
        .map((v) => v.toLowerCase())

      const categoryTemplatesTitles = templates
        .filter((t) => t.category_id === v.id)
        .map((v) => v.title)

      return (
        splitText.some((s) => v.title.toLowerCase().includes(s)) ||
        categoryTemplatesTitles.some((t) =>
          splitText.some((s) => {
            return t.toLowerCase().includes(s)
          })
        )
      )
    })
  }, [templateSearchText, templates, templateCategories])

  useEffect(() => {
    const savedFilters = JSON.parse(
      localStorage.getItem(LocalStorageKey.REPORTS_FILTERS) || '{}'
    )

    setSelectedTemplates(
      !localStorage.getItem(LocalStorageKey.REPORTS_FILTERS)
        ? templates.map((v) => v.id)
        : savedFilters.selectedTemplates || []
    )
    setVisibilityFilter(
      savedFilters.visibilityFilter || ['organization', 'private']
    )
  }, [templates])

  useEffect(() => {
    localStorage.setItem(
      LocalStorageKey.REPORTS_FILTERS,
      JSON.stringify({
        selectedTemplates: selectedTemplates,
        visibilityFilter: visibilityFilter,
      })
    )
  }, [selectedTemplates, visibilityFilter])

  const systemTemplates = useMemo(
    () => templates.filter((t) => t.template_type === 'system'),
    [templates]
  )

  const filteredSystemTemplates = useMemo(
    () =>
      systemTemplates.filter((v) => {
        const splitText = templateSearchText
          .split(' ')
          .map((v) => v.toLowerCase())

        return splitText.some((s) =>
          v.title.toLowerCase().includes(s.toLowerCase())
        )
      }),
    [systemTemplates, templateSearchText]
  )

  const getIsAllSelected = (
    categoryTemplates: DocgenTemplate[],
    selectedTemplates: string[]
  ) => categoryTemplates.every((t) => selectedTemplates.includes(t.id))

  const getIsSomeSelected = (
    categoryTemplates: DocgenTemplate[],
    selectedTemplates: string[]
  ) => categoryTemplates.some((t) => selectedTemplates.includes(t.id))

  const toggleCategory = (
    categoryTemplates: DocgenTemplate[],
    selectedTemplates: string[],
    setSelectedTemplates: (list: string[]) => void
  ) => {
    const isSomeSelected = getIsSomeSelected(
      categoryTemplates,
      selectedTemplates
    )

    const newSelectedTemplates = isSomeSelected
      ? selectedTemplates.filter(
          (id) => !categoryTemplates.some((t) => t.id === id)
        )
      : [...selectedTemplates, ...categoryTemplates.map((t) => t.id)]

    setSelectedTemplates(newSelectedTemplates)
  }

  const toggleTemplate = (
    templateId: string,
    selectedTemplates: string[],
    setSelectedTemplates: (list: string[]) => void
  ) => {
    const newSelectedTemplates = selectedTemplates.includes(templateId)
      ? selectedTemplates.filter((id) => id !== templateId)
      : [...selectedTemplates, templateId]

    setSelectedTemplates(newSelectedTemplates)
  }

  return (
    <div className="flex flex-col gap-10 mt-10 sm:mt-0 mx-auto">
      <div className="flex flex-col gap-0">
        <div className="text-center mx-auto">
          <div className="flex items-center h-full mx-auto">
            <DocGenHomeImage className="w-[20rem] h-fit" />
          </div>

          <TypographyH3 className="-translate-y-[100%]">Reports</TypographyH3>
        </div>

        <div className={`flex flex-wrap justify-center gap-2 mx-auto w-full`}>
          <Input
            className="w-[20rem]"
            placeholder="Search by report name"
            isSearch={true}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value)
            }}
            isCloseVisible={!!searchText}
            onCloseClick={() => setSearchText('')}
          />

          <Button
            className="w-fit"
            onClick={() => {
              const hideDocGenWalkthrough = localStorage.getItem(
                'hide_docgen_walkthrough'
              )

              if (hideDocGenWalkthrough !== 'true') {
                setShowDocGenWalkthrough(true)
              } else {
                navigate(`/docgen?step=${DocgenStep.TEMPLATE}`)
              }
              dispatch(setSessionInUse(null))
            }}
          >
            <div className="flex gap-2">
              <Sparkles className="size-6 shrink-0 stroke-[1.5px]" />
              DocGen: Generate report
            </div>
          </Button>
        </div>
      </div>

      <div className="flex gap-8 mx-auto laptop:mx-0 laptop:ml-[calc((100%-20rem-18.75rem-36.25rem)/2-2rem)]">
        <div className="flex flex-col gap-6 w-[20rem] min-w-[20rem]">
          <div className="h-fit bg-system-surface-light h flex flex-col gap-3 py-4 px-3 border border-system-border-regular rounded-md">
            <TypographyBody className="text-system-primary">
              To view and create templates, go to
            </TypographyBody>

            <Link to={'/templates'}>
              <Button variant="secondary" className="w-fit">
                <div className="flex gap-2 items-center">
                  <LayoutPanelTop className="size-6 shrink-0 stroke-interactive" />
                  Template gallery
                </div>
              </Button>
            </Link>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <div className="bg-system-hover px-3 py-0.5 rounded-sm">
                <TypographyBody className="text-system-body">
                  Filter by
                </TypographyBody>
              </div>
              <div className="flex flex-col gap-3 pt-3 border-t border-system-border-light">
                <TypographyBody className="text-system-primary" isStrong>
                  Visibility
                </TypographyBody>

                <Checkbox
                  label="Private"
                  checked={visibilityFilter.includes('private')}
                  onCheckedChange={() => {
                    if (visibilityFilter.includes('private')) {
                      setVisibilityFilter(
                        visibilityFilter.filter((v) => v !== 'private')
                      )
                    } else {
                      setVisibilityFilter([...visibilityFilter, 'private'])
                    }
                  }}
                />
                <Checkbox
                  label={getOrganizationName(
                    user?.app_metadata?.organization_id
                  )}
                  checked={visibilityFilter.includes('organization')}
                  onCheckedChange={() => {
                    if (visibilityFilter.includes('organization')) {
                      setVisibilityFilter(
                        visibilityFilter.filter((v) => v !== 'organization')
                      )
                    } else {
                      setVisibilityFilter([...visibilityFilter, 'organization'])
                    }
                  }}
                />
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <div className="flex flex-col gap-3 pt-3 border-t border-system-border-light">
                <TypographyBody className="text-system-primary" isStrong>
                  Template
                </TypographyBody>

                <div className="flex flex-col gap-2">
                  <Input
                    placeholder="Search by template name"
                    value={templateSearchText}
                    onChange={(e) => setTemplateSearchText(e.target.value)}
                    isSearch
                    isCloseVisible={Boolean(templateSearchText)}
                    onCloseClick={() => setTemplateSearchText('')}
                  />

                  <Accordion type="multiple" className="flex flex-col gap-2">
                    {filteredSystemTemplates.length > 0 && (
                      <AccordionItem
                        key={`accordion-theme-system`}
                        value={`category-system`}
                        className="border-none pt-3"
                      >
                        <AccordionTrigger>
                          <Checkbox
                            label="Desia Templates"
                            onClick={(e) => e.stopPropagation()}
                            checked={getIsSomeSelected(
                              systemTemplates,
                              selectedTemplates
                            )}
                            partial={
                              !getIsAllSelected(
                                systemTemplates,
                                selectedTemplates
                              )
                            }
                            onCheckedChange={() =>
                              toggleCategory(
                                systemTemplates,
                                selectedTemplates,
                                setSelectedTemplates
                              )
                            }
                          />
                        </AccordionTrigger>
                        <AccordionContent>
                          <div className="flex flex-col gap-3 ml-7 pt-3">
                            {filteredSystemTemplates.map((template) => (
                              <Checkbox
                                key={`template-filter-${template.id}`}
                                label={template.title}
                                checked={selectedTemplates.includes(
                                  template.id
                                )}
                                onCheckedChange={() =>
                                  toggleTemplate(
                                    template.id,
                                    selectedTemplates,
                                    setSelectedTemplates
                                  )
                                }
                              />
                            ))}
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    )}

                    {filteredCategories?.map((category, index) => {
                      const categoryTemplates = templates
                        .filter(
                          (t) =>
                            t.category_id === category.id &&
                            t.template_type !== 'system'
                        )
                        .filter((v) => {
                          const splitText = templateSearchText
                            .split(' ')
                            .map((v) => v.toLowerCase())

                          return splitText.some((s) =>
                            v.title.toLowerCase().includes(s.toLowerCase())
                          )
                        })

                      const isAllSelected = getIsAllSelected(
                        categoryTemplates,
                        selectedTemplates
                      )
                      const isSomeSelected = getIsSomeSelected(
                        categoryTemplates,
                        selectedTemplates
                      )
                      return (
                        <AccordionItem
                          key={`accordion-theme-${index}`}
                          value={`category-${index}`}
                          className="border-none pt-3"
                        >
                          <AccordionTrigger>
                            <Checkbox
                              label={category.title}
                              onClick={(e) => e.stopPropagation()}
                              checked={isSomeSelected}
                              partial={!isAllSelected}
                              onCheckedChange={() =>
                                toggleCategory(
                                  categoryTemplates,
                                  selectedTemplates,
                                  setSelectedTemplates
                                )
                              }
                            />
                          </AccordionTrigger>
                          <AccordionContent>
                            <div className="flex flex-col gap-3 ml-7 pt-3">
                              {categoryTemplates.map((template) => (
                                <Checkbox
                                  key={`template-filter-${template.id}`}
                                  label={template.title}
                                  checked={selectedTemplates.includes(
                                    template.id
                                  )}
                                  onCheckedChange={() =>
                                    toggleTemplate(
                                      template.id,
                                      selectedTemplates,
                                      setSelectedTemplates
                                    )
                                  }
                                />
                              ))}
                            </div>
                          </AccordionContent>
                        </AccordionItem>
                      )
                    })}
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-6 tablet:max-w-[59.75rem] w-full">
          <div className={error ? '' : '-mt-3'}>
            <ReportList
              reports={filteredSessions}
              loading={loading}
              error={error}
              type="report"
            />
          </div>
        </div>
      </div>
      <NotAvailableDialog
        open={showNotAvailableDialog}
        setOpen={(v) => setShowNotAvailableDialog(v)}
        variant="docgen"
      />
      <DocGenWalkthroughDialog
        open={showDocGenWalkthrough}
        setOpen={(v) => {
          setShowDocGenWalkthrough(v)
          navigate(`/docgen?step=${DocgenStep.TEMPLATE}`)
        }}
      />

      <div className="absolute bottom-8 right-8 flex flex-col gap-3">
        <TypographyBody className="text-system-primary text-right">
          Classic reports now sit in:
        </TypographyBody>

        <Link to={`/report-editors`}>
          <Button variant="secondary">
            <div className="flex gap-2 items-center">
              <PenToolIcon className="size-6 shrink-0 stroke-interactive" />
              Editor reports
              <Badge variant="purple">Beta</Badge>
            </div>
          </Button>
        </Link>
      </div>
    </div>
  )
}

export function ReportsPageContainer() {
  const reports = useSelector((state: RootState) => state.docGen.reports)
  const sessions = useSelector((state: RootState) => state.docGen.sessions)
  const templateListStatus = useSelector(
    (state: RootState) => state.docGen.templateListStatus
  )
  const templateCategories = useSelector(
    (state: RootState) => state.docGen.templateCategories
  )

  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    dispatch(fetchSessions())
  }, [dispatch])

  useEffect(() => {
    if (templateListStatus === QueryStatus.INITIALISED) {
      dispatch(fetchTemplates())
    }
  }, [templateListStatus, dispatch])

  useEffect(() => {
    if (templateCategories.status === QueryStatus.INITIALISED) {
      dispatch(fetchTemplateCategories())
    }
  }, [templateCategories.status, dispatch])

  useEffect(() => {
    document.title = 'Desia AI - Reports'
  }, [])

  return (
    <>
      <ReportsPage
        sessions={sessions.data || []}
        loading={reports.loading}
        error={reports.error}
      />
    </>
  )
}
