import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog'
import { CustomAlert } from '../CustomAlert'

const AskUploadFileWarningDialog = ({
  open,
  onCancel,
}: {
  open?: boolean
  onCancel?: () => void
}) => {
  return (
    <Dialog open={open}>
      <DialogContent
        className="tablet:min-w-[30rem] flex flex-col gap-4"
        onInteractOutside={() => onCancel?.()}
      >
        <DialogHeader>
          <DialogTitle onClose={() => onCancel?.()}>
            Too many files selected
          </DialogTitle>
        </DialogHeader>
        <DialogBody>
          <CustomAlert
            variant="error"
            description="You cannot upload and select more than 15 files for analysis, please ensure you select less files."
            dismissable={false}
          />
        </DialogBody>
      </DialogContent>
    </Dialog>
  )
}

export default AskUploadFileWarningDialog
