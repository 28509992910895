import { ReactNode } from "react";
import texture from '../../assets/bg-texture.png'

type MainProps = {
    component: ReactNode;
    tall?: boolean;
    scrollable?: boolean;
    noPadding?: boolean
}


export function Main({ component, tall, scrollable, noPadding = false }: MainProps) {
    return (
        <main
            style={{ backgroundImage: `url(${texture})` }}
            className={`main-container-new ${noPadding ? '' : 'px-8'} w-full h-full mx-auto sm:pt-10 pb-10 md:pb-20 ${tall ? "" : "lg:pb-30"} bg-repeat bg-top ${scrollable === false ? 'overflow-hidden' : 'overflow-auto'}`}
        >
            <div
                className={`${tall ? "w-full max-w-[120rem]" : "max-w-[59.75rem]"}  mx-auto`}
            >
                {component}
            </div>
        </main>
    )
}
