import { getIntegrationName } from "@/utils/utils"
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../ui/dialog"
import { IntegrationCode } from "@/types/types"

export const IntegrationWalkthroughDialog = ({ open, setOpen, integrationCode }: { open: boolean, setOpen: (open: boolean) => void, integrationCode: string }) => {
    const oneDriveVideo = 'https://storage.googleapis.com/desia-public-assets/OneDriveWalkthrough.mov'
    const sharepointVideo = 'https://storage.googleapis.com/desia-public-assets/SharePointWalkthrough.mov'
    const videoLink = integrationCode === IntegrationCode.ONEDRIVE ? oneDriveVideo : sharepointVideo

    const getDescriptionText = () => {
        switch (integrationCode) {
            case IntegrationCode.ONEDRIVE:
                return 'Simply use your OneDrive account name e.g. name@onmicrosoft365.com to add all files, or select the file or folder you wish to connect, then create and copy the share link.'
            case IntegrationCode.SHAREPOINT:
                return 'Go to the Site’s Home page to create and copy the share link to add all files, or select the file or folder you wish to connect and create and copy the share link.'
        }
    }

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogContent className="min-w-[41.875rem] max-w-[41.875rem]">
                <DialogHeader>
                    <DialogTitle>
                        {`How to get ${getIntegrationName(integrationCode)} links`}
                    </DialogTitle>
                    <DialogDescription>
                        {getDescriptionText()}
                    </DialogDescription>
                </DialogHeader>
                <video className="rounded-[16px] max-w-full min-w-full mx-auto border border-system-border-regular mt-6" controls autoPlay muted loop src={videoLink} />
            </DialogContent>
        </Dialog>
    )
}