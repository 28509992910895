import {
  ChevronLeft,
  ChevronRight,
  Globe,
  Paperclip,
  X,
  ZoomIn,
  ZoomOut,
} from 'lucide-react'
import { Button } from '../ui/button'
import { Document, Page } from 'react-pdf'
import ExternalLink from '@/assets/ExternalLink'
import {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { TypographyBody, TypographyLabel } from '../ui/Typography'
import {
  DocumentPreviewResource,
  DocumentPreviewType,
  SourceDocument,
  SourceDocumentType,
  TableType,
} from '@/types/types'
import {
  formatPreviewHighlight,
  checkSourceDocumentType,
  getFileId,
  handleOpenLink,
  replaceMicrosoftHighlight,
  shortString,
  parseFilingDate,
} from '@/utils/utils'
import { PageCallback } from 'react-pdf/dist/esm/shared/types.js'
import { CustomAlert } from '../CustomAlert'
import {
  getTextItemWithNeighbors,
  highlightPartialText,
} from './highlightPartialText'
import { handleError } from '@/utils/handleError'
import { Mutex } from 'async-mutex'
import { reactPDFOptions } from '@/constants'

import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import { PDFWorker } from './PDFWorker'
import Divider from '../ui/divider'
import { SourceContent, SourceHeader, SourceIcon } from '../Source'
import { useDebounce } from '@/hooks/useDebounce'
import DOMPurify from 'dompurify'
import { getFileIcon } from '@/utils/components'
import Markdown from 'markdown-to-jsx'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store/store'
import documentApi from '../Document/documentApi'
import { Input } from '../ui/input'
import { PreviewSourceIcon } from '../Assistant/PreviewSourceIcon'
import { formatDate } from 'date-fns'
import { ResponseTable } from '../Table/ResponseTable'
import { WebSourceList } from './WebSourceList'

const DocumentPage = memo(
  ({
    pageNumber,
    scale,
    initialWidth,
    highlight,
    pageLoadLock,
    onLoadSuccess,
  }: {
    pageNumber: number
    scale: number
    initialWidth: number
    highlight:
      | {
          highlight: string
          page_number?: number | string | null
        }
      | undefined
    pageLoadLock: Mutex
    onLoadSuccess: (pageNumber: number) => void
  }) => {
    const [textItems, setTextItems] = useState<any>()
    const [readyToLoadTextLayer, setReadyToLoadTextLayer] =
      useState<boolean>(false)
    const [ready, setReady] = useState(false)
    const releaseRef = useRef<(() => void) | null>(null)

    // fixes span overlaps, based on this issue: https://github.com/wojtekmaj/react-pdf/issues/1848
    useEffect(() => {
      setReadyToLoadTextLayer(false)

      const acquireLock = async () => {
        const releaseLock = await pageLoadLock.acquire()

        releaseRef.current = () => {
          releaseLock()
        }

        setReadyToLoadTextLayer(true)
      }

      acquireLock()

      return () => {
        if (releaseRef.current) {
          releaseRef.current()
        }
      }
    }, [pageNumber, pageLoadLock])

    const handleTextLayerLoad = () => {
      if (releaseRef.current) {
        releaseRef.current()
        releaseRef.current = null
      }
    }

    const textRenderer = useCallback(
      (textItem: any) => {
        if (!textItems) {
          return ''
        }

        const { itemIndex, str } = textItem

        if (!highlight) return str

        const highlightPageNumber = highlight.page_number

        if (highlightPageNumber) {
          if (highlightPageNumber !== pageNumber) {
            return str
          }
        }

        const stringsToHighlight = highlight.highlight
          .split('\\n')
          .filter((v) => v)

        const splitHighlights = stringsToHighlight.flatMap((string) => {
          const splitString = string.split(' ')
          const maxWords = 10
          if (splitString.length > maxWords) {
            const half = Math.round(splitString.length / 2)

            return [
              splitString.slice(0, half).join(' '),
              splitString.slice(half).join(' '),
            ]
          }
          return [string]
        })

        const neighbourText = getTextItemWithNeighbors(textItems, itemIndex)
        const text = highlightPartialText(str, neighbourText, splitHighlights)

        return text
      },
      [textItems, highlight, pageNumber]
    )

    const onPageLoadSuccess = useCallback(
      async (page: PageCallback) => {
        try {
          onLoadSuccess(pageNumber)
          const textItems = await page.getTextContent()
          setTextItems(textItems.items)
        } catch (e) {
          handleError(e)
        }
      },
      [pageNumber, onLoadSuccess]
    )

    const handleRenderSuccess = useCallback(() => {
      setReady(true)
    }, [])

    return (
      <>
        <Page
          customTextRenderer={textRenderer}
          pageNumber={pageNumber}
          width={initialWidth}
          scale={scale}
          onLoadSuccess={onPageLoadSuccess}
          className="my-6 relative"
          renderTextLayer={readyToLoadTextLayer}
          onRenderTextLayerSuccess={handleTextLayerLoad}
          renderAnnotationLayer={false}
          loading={<></>}
          onRenderSuccess={handleRenderSuccess}
        >
          <div
            style={{ opacity: ready ? '0%' : '100%' }}
            className="flex w-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] items-center justify-center transition-opacity ease-in-out duration-200"
          >
            <img
              className="size-12 animate-logo-animation"
              src="https://cdn.prod.website-files.com/66bccd21862b191477dc8806/66bce0be1491cc6deb168141_symbol.svg"
            />
          </div>
        </Page>
      </>
    )
  }
)

export const DocumentPreview = memo(
  ({
    resource,
    initialWidth,
    selectedExtractIndex,
    setSelectedExtractIndex,
  }: {
    resource: DocumentPreviewResource
    initialWidth: number
    selectedExtractIndex: number
    setSelectedExtractIndex: (index: number) => void
  }) => {
    const [numPages, setNumPages] = useState(0)
    const [pageNumber, setPageNumber] = useState(1)
    const [inputPageNumber, setInputPageNumber] = useState(1)
    const [selectedResourceLink, setSelectedResourceLink] = useState<
      string | null
    >(null)
    const [scale, setScale] = useState(1)
    const [error, setError] = useState<
      'generic' | 'source' | 'unsupported' | null
    >(null)
    const [loading, setLoading] = useState(false)
    const [loadedPages, setLoadedPages] = useState<number[]>([])
    const [documentLoaded, setDocumentLoaded] = useState(false)
    const [initialJump, setInitialJump] = useState(true)

    const debouncedScale = useDebounce(scale, 100) as number
    const dispatch = useDispatch<AppDispatch>()
    const debouncedInputPageNumber = useDebounce(inputPageNumber, 300) as number

    const pageRefs = useRef<{ [pageNumber: number]: HTMLDivElement | null }>({})
    const docRef = useRef<HTMLDivElement>(null)
    const pageInputRef = useRef<HTMLInputElement>(null)
    const docControlsRef = useRef<HTMLDivElement>(null)

    const pageLoadLock = useMemo(() => new Mutex(), [])

    const scales = useMemo(() => [0.1, 0.5, 0.75, 1, 1.5, 2, 3], [])
    const scaleIndex = useMemo(
      () => scales.findIndex((v) => v === scale),
      [scale, scales]
    )

    const reducedHighlights = useMemo(
      () =>
        Array.from(
          new Set(
            resource.extracts
              .flatMap((v) => {
                return {
                  highlight:
                    v.highlight?.['highlight-3'] ||
                    v.highlight?.['highlight-2'] ||
                    v.highlight?.['highlight-1'] ||
                    '',
                  page_number:
                    typeof v.page_number === 'string'
                      ? parseInt(v.page_number)
                      : v.page_number,
                  text: v.text,
                }
              })
              .filter((v) => v.highlight.length > 0 || v.page_number)
          )
        ),
      [resource.extracts]
    )

    const useLargeControls =
      (docControlsRef.current?.getBoundingClientRect()?.width || 0) > 600

    const sourceType = checkSourceDocumentType(
      resource.id,
      resource.doc_metadata
    )
    const isWeb = sourceType === SourceDocumentType.WEB
    const isOutlook = sourceType === SourceDocumentType.OUTLOOK
    const isTeams = sourceType === SourceDocumentType.TEAMS
    const isFinancialData = sourceType === SourceDocumentType.FINANCIAL_DATA
    const canZoom = !isWeb && !isFinancialData && !isOutlook && !isTeams
    const hasTableData = resource.doc_metadata?.table_json
    const useCentralStorage =
      resource.doc_metadata?.use_central_storage ||
      resource.doc_metadata?.document_source === 'data_provider'

    const isNotSupported =
      resource.document_type_friendly.toLowerCase() !== 'pdf' &&
      !(resource.title || '').endsWith('.pdf')
    const highlightHasText = resource.text
    const emailReceiver =
      isOutlook && Array.isArray(resource.doc_metadata?.receiver)
        ? resource.doc_metadata.receiver.at(0)?.email_address
        : ''
    const ccEmails =
      isOutlook && Array.isArray(resource.doc_metadata?.receiver)
        ? resource.doc_metadata?.receiver?.slice(
            1,
            resource.doc_metadata.receiver.length - 1
          ) || []
        : []

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
      setNumPages(numPages)
      setDocumentLoaded(true)
      setLoading(false)
    }

    const updateLink = useCallback(async () => {
      setNumPages(0)
      setSelectedExtractIndex(0)
      setInputPageNumber(1)
      setSelectedResourceLink(null)
      setInitialJump(true)
      setLoadedPages([])

      try {
        if (!resource) return
        if (isWeb || isOutlook || isTeams || isFinancialData) {
          setError(null)
          return
        }

        if (
          resource.document_type_friendly.toLowerCase() !== 'pdf' &&
          !(resource.title || '').endsWith('.pdf')
        ) {
          setError('unsupported')
          setSelectedResourceLink(null)
          setLoading(false)
          return
        }
        setLoading(true)
        setError(null)

        const resourceId = resource.id

        const result = await dispatch(
          documentApi.endpoints.getResourceData.initiate({
            fileId: getFileId(resource.id),
            use_central_storage: useCentralStorage,
            central_storage_path:
              resource.doc_metadata?.central_storage_path || resource.url,
          })
        )

        if (resource.id === resourceId) {
          if (result.isSuccess) {
            // without the timeout, switching between the same document won't work
            setTimeout(() => {
              setSelectedResourceLink(result.data)
            }, 1)
            return
          }
        }

        setError('source')
        setLoading(false)
      } catch {
        setError('source')
        setLoading(false)
      }
    }, [isOutlook, isTeams, isWeb, isFinancialData, resource, dispatch])

    const jumpToSelectedExtract = useCallback(() => {
      const extract = reducedHighlights[selectedExtractIndex]

      if (!extract?.page_number) return

      const item = pageRefs.current[extract.page_number]
      const container = docRef.current

      if (container && item) {
        const itemOffset = item.offsetTop
        const scrollPosition =
          itemOffset - (container.clientHeight - item.clientHeight) / 2
        container.scrollTo({
          top: scrollPosition,
          behavior: 'smooth',
        })
      }
    }, [reducedHighlights, selectedExtractIndex])

    const jumpToPage = (page: number) => {
      const item = pageRefs.current[page]
      const container = docRef.current
      if (container && item) {
        const itemOffset = item.offsetTop
        const scrollPosition =
          itemOffset - (container.clientHeight - item.clientHeight) / 2
        container.scrollTo({
          top: scrollPosition,
          behavior: 'smooth',
        })
      }
    }

    const handlePageLoadSuccess = (pageNumber: number) => {
      setLoadedPages((loadedPages) =>
        Array.from(new Set([...loadedPages, pageNumber]))
      )
    }

    const handlePageLoaded = useCallback(() => {
      const firstExtract = reducedHighlights.at(0)
      if (firstExtract) {
        const pageNumber = firstExtract.page_number

        if (pageNumber) {
          if (loadedPages.includes(pageNumber)) {
            if (initialJump) {
              setSelectedExtractIndex(0)
              jumpToSelectedExtract()
              setInitialJump(false)
            }

            setLoading(false)
          }
        } else {
          if (loadedPages.length === numPages && numPages > 0) {
            setLoading(false)
          }
        }
      }
    }, [
      initialJump,
      jumpToSelectedExtract,
      loadedPages,
      numPages,
      reducedHighlights,
      setSelectedExtractIndex,
    ])

    const documentRenderer = useMemo(() => {
      try {
        return (
          <PDFWorker>
            <Document
              key={`document-${resource.id}`}
              file={selectedResourceLink}
              options={reactPDFOptions}
              onLoadSuccess={onDocumentLoadSuccess}
              className="mx-auto w-fit [&_.react-pdf\_\_Page\_\_textContent]:!mix-blend-multiply"
              loading={<></>}
              error={<></>}
              noData={<></>}
              onSourceError={() => {
                setLoading(false)
                setError('source')
              }}
              onError={() => {
                setLoading(false)
                setError('generic')
              }}
              onLoadError={() => {
                setLoading(false)
                setError('generic')
              }}
            >
              {documentLoaded &&
                Array.from({ length: numPages }).map((_, index) => {
                  return (
                    <div
                      key={`pdf-${index + 1}-${resource.id}`}
                      ref={(el) => {
                        pageRefs.current[index + 1] = el
                      }}
                    >
                      <DocumentPage
                        pageNumber={index + 1}
                        initialWidth={initialWidth}
                        scale={debouncedScale}
                        highlight={reducedHighlights[selectedExtractIndex]}
                        onLoadSuccess={handlePageLoadSuccess}
                        pageLoadLock={pageLoadLock}
                      />
                    </div>
                  )
                })}
            </Document>
          </PDFWorker>
        )
      } catch (e) {
        handleError(e)
        return <DocumentPreviewGenericError />
      }
    }, [
      selectedResourceLink,
      reducedHighlights,
      debouncedScale,
      documentLoaded,
      initialWidth,
      numPages,
      pageLoadLock,
      resource.id,
      selectedExtractIndex,
    ])

    useEffect(() => {
      const handleScroll = () => {
        if (docRef.current) {
          const { scrollTop, scrollHeight } = docRef.current
          // 24 is the y margin
          const pageHeight = scrollHeight / numPages
          const newPageNumber = Math.round(scrollTop / pageHeight) + 1

          const inputFocused = document.activeElement === pageInputRef.current
          if (newPageNumber !== pageNumber && newPageNumber <= numPages) {
            setPageNumber(newPageNumber)
            if (!inputFocused) {
              setInputPageNumber(newPageNumber)
            }
          }
        }
      }

      const wrapper = docRef.current
      if (wrapper) {
        wrapper.addEventListener('scroll', handleScroll, { capture: true })
      }

      return () => {
        if (wrapper) {
          wrapper.removeEventListener('scroll', handleScroll, { capture: true })
        }
      }
    }, [numPages, pageNumber])

    useEffect(() => {
      updateLink()
    }, [resource, updateLink])

    useEffect(() => {
      if (!initialJump) {
        jumpToSelectedExtract()
      }
    }, [selectedExtractIndex, initialJump, jumpToSelectedExtract])

    useEffect(() => {
      handlePageLoaded()
    }, [handlePageLoaded])

    useEffect(() => {
      const inputFocused = document.activeElement === pageInputRef.current
      if (inputFocused) {
        jumpToPage(debouncedInputPageNumber)
      }
    }, [debouncedInputPageNumber])

    return (
      <>
        <div
          ref={docControlsRef}
          className={`flex flex-wrap gap-4 border-system-border-regular items-center`}
        >
          <TypographyBody
            className={`text-system-body ${useLargeControls ? 'flex' : 'hidden'}`}
          >
            Preview
          </TypographyBody>

          <div
            className={`flex gap-3 items-center ${isFinancialData || isOutlook || isTeams || isWeb ? '' : `${useLargeControls ? 'mx-auto' : ''}`} w-fit`}
          >
            {(isFinancialData || isOutlook || isTeams || isWeb) && (
              <TypographyBody
                className={`text-system-body ${useLargeControls ? 'hidden' : 'flex'}`}
              >
                Preview
              </TypographyBody>
            )}
            {numPages > 0 && (
              <>
                <div className="flex gap-3 items-center">
                  <TypographyLabel className="text-system-body">
                    Page
                  </TypographyLabel>

                  <Input
                    className="w-[3.125rem] h-[2.125rem] [&::-webkit-inner-spin-button]:appearance-none"
                    ref={pageInputRef}
                    value={inputPageNumber}
                    type="number"
                    pattern="\d*"
                    min={0}
                    max={pageNumber}
                    onChange={(e) => {
                      const page = parseInt(e.target.value)

                      setInputPageNumber(page)
                    }}
                  />

                  <TypographyLabel className="text-system-body shrink-0">
                    {`of ${numPages}`}
                  </TypographyLabel>
                </div>

                <div className="h-6 w-px bg-system-border-light"></div>
              </>
            )}

            {!(isFinancialData || isOutlook || isTeams || isWeb) && (
              <>
                <div className="flex gap-2 ml-auto items-center">
                  <Button variant="tertiary" disabled={!canZoom}>
                    <ZoomOut
                      className={`size-6 shrink-0 stroke-[1.5px] ${!canZoom || !(scaleIndex > 0) ? 'opacity-15' : 'opacity-100'}`}
                      onClick={() => {
                        if (scaleIndex > 0) {
                          setScale(scales[scaleIndex - 1])
                        }

                        setInitialJump(false)
                      }}
                    />
                  </Button>

                  <TypographyLabel className="text-system-body">
                    {`${Math.round(scale * 100)}%`}
                  </TypographyLabel>

                  <Button variant="tertiary" disabled={!canZoom}>
                    <ZoomIn
                      className={`size-6 shrink-0 stroke-[1.5px] ${!canZoom || !(scaleIndex < scales.length - 1) ? 'opacity-15' : 'opacity-100'}`}
                      onClick={() => {
                        if (scaleIndex < scales.length - 1) {
                          setScale(scales[scaleIndex + 1])
                        }
                        setInitialJump(false)
                      }}
                    />
                  </Button>
                </div>

                <div className="h-6 w-px bg-system-border-light"></div>
              </>
            )}

            <Button
              variant="tertiary"
              disabled={isFinancialData}
              className="h-fit"
              onClick={() => {
                if (!resource) return

                handleOpenLink({
                  id: resource.id,
                  url: resource.url,
                  title: resource?.title || '',
                  documentLink: resource.document_link,
                  documentSource: resource.doc_metadata?.source,
                  centralStoragePath: useCentralStorage
                    ? resource.doc_metadata?.central_storage_path ||
                      resource.url
                    : undefined,
                  window,
                })
              }}
            >
              <ExternalLink
                className={`size-6 shrink-0 stroke-[1.5px] ${isFinancialData ? 'opacity-15' : ''}`}
              />
            </Button>
          </div>

          <div className="flex gap-1 items-center shrink-0 ml-auto w-fit">
            {reducedHighlights.length > 1 && !isOutlook && !isTeams && (
              <>
                <TypographyLabel
                  className={`text-system-body mr-2 ${useLargeControls ? 'flex' : 'hidden'}`}
                >
                  Results
                </TypographyLabel>

                {selectedExtractIndex > 0 && (
                  <Button
                    variant="tertiary"
                    onClick={() => {
                      setSelectedExtractIndex(selectedExtractIndex - 1)
                    }}
                  >
                    <ChevronLeft className="size-6 shrink-0 stroke-[1.5px]" />
                  </Button>
                )}

                <TypographyBody className="text-system-primary whitespace-nowrap">
                  {`${selectedExtractIndex + 1} of ${reducedHighlights.length}`}
                </TypographyBody>

                {selectedExtractIndex < reducedHighlights.length - 1 && (
                  <Button
                    variant="tertiary"
                    onClick={() => {
                      setSelectedExtractIndex(selectedExtractIndex + 1)
                    }}
                  >
                    <ChevronRight className="size-6 shrink-0 stroke-[1.5px]" />
                  </Button>
                )}
              </>
            )}
          </div>
        </div>

        <div
          ref={docRef}
          className={`relative h-[calc(100%-5.5rem+1px)] ${isWeb || isOutlook || isTeams || isFinancialData || (isNotSupported && highlightHasText) ? '' : 'bg-system-border-light '} overflow-y-auto overflow-x-auto w-full selection:bg-highlight-selected  [&_mark]:!bg-citation-highlight [&_mark]:text-transparent`}
        >
          {loading && (
            <div className="flex w-full h-[calc(100%-5.5rem)] absolute top-[2.75rem] left-0 items-center justify-center">
              <img
                className="size-12 animate-logo-animation"
                src="https://cdn.prod.website-files.com/66bccd21862b191477dc8806/66bce0be1491cc6deb168141_symbol.svg"
              />
            </div>
          )}

          {error === 'generic' && (
            <DocumentPreviewGenericError onDismiss={() => setError(null)} />
          )}
          {error === 'source' && (
            <DocumentPreviewSourceError onDismiss={() => setError(null)} />
          )}
          {error === 'unsupported' && !highlightHasText && (
            <DocumentPreviewUnsupportedTypeError
              onDismiss={() => setError(null)}
            />
          )}

          {(isWeb || isFinancialData) && !hasTableData && (
            <div className="m-2 p-6 border-l-4 h-full border-system-placeholder bg-system-surface-light font-body text-system-body whitespace-pre-wrap">
              <Markdown
                options={{
                  overrides: {
                    Highlight: {
                      component: ({ children }: { children: ReactNode }) => (
                        <span className="bg-[linear-gradient(0deg,_var(--citation-highlight),_var(--citation-highlight))] bg-no-repeat bg-[length:100%_90%]">
                          {children}
                        </span>
                      ),
                    },
                  },
                  forceBlock: true,
                  wrapper: 'span',
                }}
              >
                {`${formatPreviewHighlight(reducedHighlights[selectedExtractIndex])}`}
              </Markdown>
            </div>
          )}

          {(isWeb || isFinancialData) && hasTableData && (
            <div className="pt-4 pb-4 w-full overflow-x-hidden">
              <ResponseTable
                key={`response-table-container-${resource.id}`}
                id={resource.id}
                data={JSON.stringify(resource.doc_metadata?.table_json)}
                highlightedText={reducedHighlights[selectedExtractIndex]?.text}
                type={TableType.FINANCIAL_DATA}
              />
            </div>
          )}

          {!isWeb &&
            !isFinancialData &&
            !isTeams &&
            !isOutlook &&
            isNotSupported &&
            highlightHasText && (
              <div className="m-2 p-6 border-l-4 h-full border-system-placeholder bg-system-surface-light font-body text-system-body whitespace-pre-wrap">
                <Markdown
                  options={{
                    overrides: {
                      Highlight: {
                        component: ({ children }: { children: ReactNode }) => (
                          <span className="bg-[linear-gradient(0deg,_var(--citation-highlight),_var(--citation-highlight))] bg-no-repeat bg-[length:100%_90%]">
                            {children}
                          </span>
                        ),
                      },
                    },
                    forceBlock: true,
                    wrapper: 'span',
                  }}
                >
                  {`${formatPreviewHighlight({ highlight: resource.text, page_number: undefined, text: reducedHighlights[selectedExtractIndex]?.text })}`}
                </Markdown>
              </div>
            )}
          {(isOutlook || isTeams) && (
            <div
              className={`flex flex-col min-h-full gap-6 p-6 bg-system-surface-light font-body text-system-body whitespace-pre-wrap ${isOutlook ? 'rounded-lg' : 'border-l-4 border-system-placeholder'}`}
            >
              <Markdown
                options={{
                  overrides: {
                    Highlight: {
                      component: ({ children }: { children: ReactNode }) => (
                        <span className="bg-[linear-gradient(0deg,_var(--citation-highlight),_var(--citation-highlight))] bg-no-repeat bg-[length:100%_90%]">
                          {children}
                        </span>
                      ),
                    },
                  },
                  forceBlock: true,
                  wrapper: 'span',
                }}
              >
                {`<span className='font-body-strong text-system-primary break-all'>${replaceMicrosoftHighlight(resource.doc_metadata?.summary || '')}  </span>`}
              </Markdown>

              <Divider className="bg-system-border-regular" />

              {isOutlook && (
                <div className="flex flex-col gap-3">
                  <TypographyLabel className="text-system-body">
                    Found in:
                  </TypographyLabel>

                  <TypographyBody isStrong className="text-system-primary">
                    {resource.title || ''}
                  </TypographyBody>

                  <div className="flex flex-col gap-1">
                    <TypographyLabel className="text-system-body line-clamp-1 break-all">
                      From:{' '}
                      <span className="font-label-strong text-system-primary">
                        {resource.doc_metadata?.sender?.email_address || ''}
                      </span>
                    </TypographyLabel>

                    <TypographyLabel className="text-system-body line-clamp-1 break-all">
                      To:{' '}
                      <span className="font-label-strong text-system-primary">
                        {emailReceiver}
                      </span>
                    </TypographyLabel>

                    {ccEmails.length > 0 && (
                      <TypographyLabel className="text-system-body line-clamp-1 break-all">
                        CC:{' '}
                        <span className="font-label-strong text-system-primary">
                          {ccEmails.map((v) => v.email_address).join(', ')}
                        </span>
                      </TypographyLabel>
                    )}
                  </div>
                </div>
              )}

              {isTeams && (
                <div className="flex flex-col gap-3">
                  <TypographyLabel className="text-system-body">
                    Found in:
                  </TypographyLabel>

                  <div className="flex flex-col gap-1">
                    <TypographyLabel className="text-system-body line-clamp-1 break-all">
                      From:{' '}
                      <span className="font-label-strong text-system-primary">
                        {resource.doc_metadata?.sender?.name || ''}
                      </span>
                    </TypographyLabel>
                  </div>
                </div>
              )}

              {resource.doc_metadata?.resource_attachments?.map(
                (attachment, index) => {
                  const fileExtension = attachment.name.split('.').at(-1) || ''
                  return (
                    <div
                      key={`resource-attachment-${index}`}
                      className="flex gap-2 py-1 px-3 bg-system-secondary border border-system-border-light h-12 items-center w-fit"
                    >
                      <Paperclip className="size-6 shrink-0 stroke-[1.5px] stroke-system-body mr-1" />

                      {getFileIcon(
                        fileExtension,
                        'size-6 min-w-6 min-h-6 shrink-0'
                      )}

                      <TypographyBody className="text-system-primary line-clamp-1 break-all">
                        {shortString(attachment.name, 30)}
                      </TypographyBody>
                    </div>
                  )
                }
              )}

              <div
                className="flex flex-col gap-0"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(resource.doc_metadata?.body || ''),
                }}
              />
            </div>
          )}

          {selectedResourceLink &&
            !error &&
            !isWeb &&
            !isFinancialData &&
            !isOutlook &&
            !isTeams &&
            documentRenderer}
        </div>
      </>
    )
  }
)

export const DocumentPreviewGenericError = ({
  onDismiss,
}: {
  onDismiss?: () => void
}) => {
  return (
    <div className="p-6 w-fit mx-auto">
      <CustomAlert
        variant="error"
        description="We could not load file preview at this time. Try again later."
        dismissable={Boolean(onDismiss)}
        onCloseClick={onDismiss}
      />
    </div>
  )
}

export const DocumentPreviewSourceError = ({
  onDismiss,
}: {
  onDismiss?: () => void
}) => {
  return (
    <div className="p-6 w-fit mx-auto">
      <CustomAlert
        variant="error"
        title="We could not access the file at this time."
        description="You might not have permissions to view this file."
        dismissable={Boolean(onDismiss)}
        onCloseClick={onDismiss}
      />
    </div>
  )
}

export const DocumentPreviewUnsupportedTypeError = ({
  onDismiss,
}: {
  onDismiss?: () => void
}) => {
  return (
    <div className="p-6 w-fit mx-auto">
      <CustomAlert
        variant="info"
        title="We only support the preview of PDF files at the moment"
        description="We’re working hard to add support for more types soon!"
        dismissable={Boolean(onDismiss)}
        onCloseClick={onDismiss}
      />
    </div>
  )
}

export const DocumentPreviewContainer = ({
  resource,
  type,
  initialWidth,
  containerHeight,
  sources = [],
  selectedSource,
  selectedExtractIndex,
  setSelectedExtractIndex,
  onClose,
  setSelectedSource,
}: {
  resource: DocumentPreviewResource
  type: DocumentPreviewType
  initialWidth: number
  containerHeight?: number
  sources?: SourceDocument[]
  selectedSource?: SourceDocument
  selectedExtractIndex: number
  setSelectedExtractIndex: (index: number) => void
  setSelectedSource?: (source: SourceDocument) => void
  onClose?: () => void
}) => {
  const [showWebSourceList, setShowWebSourceList] = useState(false)

  const documentPreview = useMemo(() => {
    try {
      const sourceType = checkSourceDocumentType(
        resource.id,
        resource.doc_metadata
      )
      const isWeb = sourceType === SourceDocumentType.WEB
      const isFinancialData = sourceType === SourceDocumentType.FINANCIAL_DATA
      const isOutlook = sourceType === SourceDocumentType.OUTLOOK
      const isTeams = sourceType === SourceDocumentType.TEAMS
      const isCompaniesHouse = sourceType === SourceDocumentType.COMPANIES_HOUSE
      const isFilings = sourceType === SourceDocumentType.FILINGS
      const isTranscripts = sourceType === SourceDocumentType.TRANSCRIPTS

      const isNotSupported =
        resource.document_type_friendly.toLowerCase() !== 'pdf' &&
        !(resource.title || '').endsWith('.pdf')
      const highlightHasText = resource.text

      const webSources = sources.filter(
        (v) =>
          checkSourceDocumentType(v.document_id, v.doc_metadata) ===
          SourceDocumentType.WEB
      )
      const nonWebSources = sources.filter(
        (v) =>
          checkSourceDocumentType(v.document_id, v.doc_metadata) !==
          SourceDocumentType.WEB
      )

      if (
        [
          DocumentPreviewType.ASK,
          DocumentPreviewType.REPORT,
          DocumentPreviewType.DOCGEN,
        ].includes(type)
      ) {
        const currentExtractIndex = (nonWebSources || []).findIndex(
          (v) => v.document_id === selectedSource?.document_id
        )
        const nextExtract =
          currentExtractIndex < (nonWebSources || []).length - 1
            ? nonWebSources?.[currentExtractIndex + 1]
            : undefined
        const previousExtract =
          currentExtractIndex > 0
            ? nonWebSources?.[currentExtractIndex - 1]
            : undefined

        const getPreviewHeight = () => {
          if (type === DocumentPreviewType.ASK) {
            return 'calc(100vh - 6.25rem - 1rem)'
          }

          if (type === DocumentPreviewType.DOCGEN) {
            return `${containerHeight || 0}px`
          }

          return 'calc(100vh - 14rem - 1rem)'
        }

        return (
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              height:
                isWeb ||
                isOutlook ||
                isTeams ||
                isFinancialData ||
                (isNotSupported && highlightHasText)
                  ? undefined
                  : `${getPreviewHeight()}`,
              maxHeight:
                isWeb ||
                isOutlook ||
                isTeams ||
                isFinancialData ||
                (isNotSupported && highlightHasText)
                  ? `${getPreviewHeight()}`
                  : undefined,
            }}
            className={`flex flex-col gap-3 px-4 ${sources.length <= 1 && sources.length !== webSources.length ? 'pt-3' : ''} w-full bg-system-secondary border border-system-border-regular rounded-lg`}
          >
            {(sources.length > 1 || sources.length === webSources.length) && (
              <div className="flex gap-4 p-3 py-1 items-center bg-system-surface-light -mx-4 rounded-t-lg min-w-full">
                <div className="flex gap-2 items-center">
                  <TypographyBody className="text-system-body">
                    Sources
                  </TypographyBody>
                  <TypographyBody isStrong>
                    {sources?.length || 0}
                  </TypographyBody>
                </div>

                <div className="flex gap-2 !w-[100%] items-center overflow-x-auto py-1 pl-0.5 pr-0.5 hide-scrollbar">
                  <div
                    className="flex gap-2 items-center"
                    style={{
                      minWidth: `${nonWebSources.length * (1.5 + 0.5)}rem`,
                    }}
                  >
                    {nonWebSources?.map((source, index) => {
                      return (
                        <PreviewSourceIcon
                          document={source}
                          key={`document-preview-source-${index}`}
                          size={21}
                          isSelected={
                            source.document_id ===
                              selectedSource?.document_id && !showWebSourceList
                          }
                          onClick={() => {
                            setSelectedSource?.(source)
                            setShowWebSourceList(false)
                          }}
                          iconContainerClassName="border-system-border-regular"
                        />
                      )
                    })}
                  </div>

                  {webSources.length > 0 && (
                    <div
                      onClick={() => {
                        setShowWebSourceList(true)
                      }}
                      className={`flex gap-2 py-[0.1875rem] px-[0.375rem] items-center bg-system-secondary border border-system-border-regular rounded-[5px] cursor-pointer ${showWebSourceList ? 'ring-1 ring-system-body !border-system-body' : ''}`}
                      style={{
                        minWidth: `calc(${webSources.length * (1.5 + 0.25)}rem + 0.5rem)`,
                      }}
                    >
                      <Globe className="size-4 shrink-0 stroke-interactive text-system-body" />

                      <div className="flex gap-1 items-center">
                        {webSources?.map((source, index) => {
                          return (
                            <div
                              className="min-w-fit"
                              key={`preview-source-${index}`}
                            >
                              <PreviewSourceIcon
                                document={source}
                                key={`document-preview-source-${index}`}
                                size={21}
                                iconContainerClassName="border-system-border-regular"
                              />
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex gap-4 items-center ml-auto">
                  <Button variant="tertiary" onClick={onClose}>
                    <X className="size-6 shrink-0 stroke-interactive" />
                  </Button>
                </div>
              </div>
            )}
            {!showWebSourceList && (
              <>
                <SourceHeader
                  id={resource.id}
                  url={resource.url}
                  title={resource.title || ''}
                  text={resource.text}
                  documentLink={resource.document_link}
                  metadata={resource.doc_metadata}
                />
                {sources.length <= 1 && (
                  <Button
                    variant="tertiary"
                    onClick={onClose}
                    className="absolute top-3 right-4"
                  >
                    <X className="size-6 shrink-0 stroke-interactive" />
                  </Button>
                )}

                <div
                  className={`flex gap-2 ${sources.length === 1 ? 'pr-6' : ''}`}
                >
                  <SourceContent
                    id={resource.id}
                    url={resource.url}
                    title={resource.title || ''}
                    text={resource.text}
                    documentLink={resource.document_link}
                    metadata={resource.doc_metadata}
                    isSearchResult={true}
                  />

                  <div className="flex gap-6 ml-auto items-center">
                    {previousExtract && (
                      <div className="flex gap-2 items-center">
                        <Button
                          variant="tertiary"
                          className="h-fit"
                          onClick={() => setSelectedSource?.(previousExtract)}
                        >
                          <ChevronLeft className="size-6 shrink-0 stroke-[1.5px]" />
                        </Button>

                        <SourceIcon
                          id={previousExtract.document_id}
                          url={previousExtract.url || ''}
                          title={previousExtract.title}
                          metadata={previousExtract.doc_metadata}
                        />
                      </div>
                    )}

                    {nextExtract && (
                      <div className="flex gap-2 items-center">
                        <SourceIcon
                          id={nextExtract.document_id}
                          url={nextExtract.url || ''}
                          title={nextExtract.title}
                          metadata={nextExtract.doc_metadata}
                        />

                        <Button
                          variant="tertiary"
                          className="h-fit"
                          onClick={() => setSelectedSource?.(nextExtract)}
                        >
                          <ChevronRight className="size-6 shrink-0 stroke-[1.5px]" />
                        </Button>
                      </div>
                    )}
                  </div>
                </div>

                <Divider />
              </>
            )}
            {showWebSourceList && <WebSourceList sources={webSources} />}

            {!showWebSourceList && (
              <DocumentPreview
                resource={resource}
                initialWidth={initialWidth}
                selectedExtractIndex={selectedExtractIndex}
                setSelectedExtractIndex={setSelectedExtractIndex}
              />
            )}
          </div>
        )
      } else {
        return (
          <div className="flex flex-col py-3 px-4 gap-3 w-full max-h-[calc(100vh-15.25rem-4.25rem)] max-w-[52.25rem] bg-system-secondary border border-system-border-regular rounded-lg overflow-hidden">
            <div className={`flex flex-col gap-0 w-full justify-center`}>
              <TypographyBody
                isStrong={true}
                className="text-system-primary line-clamp-1 break-all w-full"
              >
                {isCompaniesHouse &&
                  `Full accounts made up to ${formatDate(resource.doc_metadata?.resource?.resource_metadata?.filing_for_date || '', 'dd MMMM yyyy')}`}

                {isFilings &&
                  `${resource.doc_metadata?.resource?.resource_metadata?.filing_category || resource.doc_metadata?.filing_category || ''} for FY ended ${parseFilingDate(resource.doc_metadata?.resource?.resource_metadata?.filing_for_date || resource.doc_metadata?.filing_for_date || '')}`}

                {isTranscripts &&
                  (resource.doc_metadata?.resource?.resource_metadata
                    ?.transcript_description ||
                    resource.doc_metadata?.company_details
                      ?.transcript_description ||
                    '')}

                {(isTeams || isOutlook) && resource.text}

                {!isCompaniesHouse &&
                  !isTeams &&
                  !isOutlook &&
                  !isFilings &&
                  !isTranscripts &&
                  resource.title}
              </TypographyBody>
            </div>

            <Divider />

            <DocumentPreview
              resource={resource}
              initialWidth={initialWidth}
              selectedExtractIndex={selectedExtractIndex}
              setSelectedExtractIndex={setSelectedExtractIndex}
            />
          </div>
        )
      }
    } catch (e) {
      console.error(e)
      return <DocumentPreviewGenericError />
    }
  }, [
    resource,
    selectedExtractIndex,
    setSelectedExtractIndex,
    selectedSource,
    sources,
    initialWidth,
    onClose,
    setSelectedSource,
    type,
    containerHeight,
    showWebSourceList,
  ])

  useEffect(() => {
    if (
      checkSourceDocumentType(resource.id, resource.doc_metadata) ===
      SourceDocumentType.WEB
    ) {
      setShowWebSourceList(true)
    }
  }, [resource])

  return documentPreview
}
