import { WEB_SERVER_ENDPOINT } from '@/constants'
import { RootState } from '@/store/store'
import { ResponseChatGenerateTitle } from '@/types/types'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const deleteLastMessage = createAsyncThunk<
  void,
  string,
  { state: RootState }
>(
  'assistant/delete-last-message',
  async (conversationId, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${WEB_SERVER_ENDPOINT}/api/assistant/delete-last-message/${conversationId}`,
        {
          method: 'POST',
          credentials: 'include',
        }
      )

      if (!response.ok) {
        throw new Error('Failed to delete message')
      }
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)

export const stopConversation = createAsyncThunk<
  void,
  string,
  { state: RootState }
>('assistant/stop', async (conversationId, { rejectWithValue }) => {
  try {
    const response = await fetch(
      `${WEB_SERVER_ENDPOINT}/api/assistant/${conversationId}/stop`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      }
    )

    if (!response.ok) {
      throw new Error('Failed to delete message')
    }
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message)
    }
    return rejectWithValue('An unknown error occurred')
  }
})

export const generateConversationTitle = createAsyncThunk<
  ResponseChatGenerateTitle,
  string,
  { state: RootState }
>(
  'assistant/generateConversationTitle',
  async (conversationId, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `${WEB_SERVER_ENDPOINT}/api/assistant/conversation/generate-title`,
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            conversationId: conversationId,
          }),
        }
      )

      if (!response.ok) {
        throw new Error('Failed to generate title')
      }
      const data = await response.json()
      return data
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }
      return rejectWithValue('An unknown error occurred')
    }
  }
)
