import { configureStore, combineSlices } from '@reduxjs/toolkit'
import { docGenSlice } from '../components/DocGen/docGenSlice'
import { userSlice } from '../features/userSlice'
import { integrationSlice } from '../components/Integration/integrationSlice'
import { dossierSlice } from '../components/Dossier/dossierSlice'
import { documentSlice } from '../components/Document/documentSlice'
import { assistantSlice } from '../components/Assistant/assistantSlice'
import { socketIOMiddleware } from './middleware'
import { socketConnection } from '@/utils/SocketConnection'
import documentApi from '@/components/Document/documentApi'
import { searchSlice } from '@/components/Resources/searchSlice'
import { handleError } from '@/utils/handleError'
import { LocalStorageKey } from '@/constants'
import { SearchConnectors } from '@/types/types'
import { playbookSlice } from '@/components/Playbook/playbookSlice'
import { impromptuUploadSlice } from '@/components/Document/impromptuUploadSlice'
import { themeSlice } from '@/components/Theme/themeSlice'

const rootReducer = combineSlices(
  docGenSlice,
  userSlice,
  integrationSlice,
  dossierSlice,
  documentSlice,
  assistantSlice,
  searchSlice,
  playbookSlice,
  impromptuUploadSlice,
  documentApi,
  themeSlice
)

const loadPersistedSearchConnectors = (): SearchConnectors => {
  const defaultConnectors: SearchConnectors = {
    internalSearch: true,
    teamsSearch: false,
    outlookSearch: false,
    companiesHouseSearch: true,
    filingsSearch: true,
    transcriptsSearch: true,
  }

  try {
    const serializedState = localStorage.getItem(
      LocalStorageKey.SEARCH_CONNECTORS
    )
    return serializedState ? JSON.parse(serializedState) : defaultConnectors
  } catch (e) {
    handleError(e)
    return defaultConnectors
  }
}

const preloadedState = {
  search: {
    connectors: loadPersistedSearchConnectors(),
  },
}

export const store = configureStore({
  reducer: rootReducer,
  preloadedState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(socketIOMiddleware(socketConnection))
      .concat(documentApi.middleware),
})

store.subscribe(() => {
  try {
    const searchState = store.getState().search.connectors
    localStorage.setItem(
      LocalStorageKey.SEARCH_CONNECTORS,
      JSON.stringify(searchState)
    )
  } catch (e) {
    handleError(e)
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
