import { Fragment, useEffect } from 'react'
import { TypographyBody, TypographyH3, TypographyH4 } from '../ui/Typography'
import { ErrorMessage } from '../ErrorMessage'
import Divider from '../ui/divider'
import { Guides } from './guide.constant'
import { Guide, GuideContent } from '@/types/types'

const tableOfContents = Object.keys(Guides.guides).map((key) => {
  const currentGuide = Guides.guides[key]
  return {
    title: currentGuide.title,
    key,
  }
})

const guides: Guide[] = Object.keys(Guides.guides).map((key) => {
  return Guides.guides[key]
})

const GuidePage = () => {
  try {
    return (
      <div className="mt-10 mobile:mt-0 flex flex-col w-full min-h-[calc(100vh-3.75rem-7.5rem)] items-center">
        <div className="flex flex-col gap-10 max-w-[74.125rem] desktop:min-w-[74.125rem] flex-grow-0 mx-auto items-start">
          <div className="text-center w-full">
            <TypographyH3>Help guides</TypographyH3>
          </div>
          <div className="flex flex-col gap-8 w-full">
            <section className="flex flex-col gap-4 w-full">
              <TypographyH3>{Guides.heading}</TypographyH3>
              <TypographyBody className="text-system-body whitespace-pre-wrap">
                {Guides.subHeading}
              </TypographyBody>
              <div className="flex flex-col gap-2">
                {tableOfContents.map((entry, index) => (
                  <TypographyBody
                    key={`toc-${index}`}
                    className="text-system-body whitespace-pre-wrap"
                  >
                    <span className="tabular-nums">{index + 1}.&nbsp;</span>
                    <span>{entry.title}</span>
                  </TypographyBody>
                ))}
              </div>
            </section>

            <Divider className="" />

            <section className="w-full flex flex-col gap-8">
              {guides.map((guide: Guide, guideIdx: number) => {
                return (
                  <Fragment key={`guide-${guideIdx}`}>
                    <div
                      className="flex flex-col gap-4 items-start"
                    >
                      <TypographyH3>
                        <span className="tabular-nums">
                          {guideIdx + 1}.&nbsp;
                        </span>
                        <span>{guide.title}</span>
                      </TypographyH3>
                      <div className="flex flex-col gap-8 ml-[1.75rem]">
                        {guide.videoUrls &&
                          guide.videoUrls.length > 0 &&
                          guide.videoUrls.map((videoUrl, videoIdx) => (
                            <div
                              key={`video-${guideIdx}-${videoIdx}`}
                              className="flex-grow max-w-[50rem] desktop:min-w-[50rem] aspect-auto"
                            >
                              <video
                                className="rounded-lg max-w-full min-w-full mx-auto border border-2 border-system-border-regular"
                                controls
                                autoPlay
                                muted
                                loop
                                src={videoUrl}
                              />
                            </div>
                          ))}
                        {guide.content.map(
                          (content: GuideContent, contentIdx: number) => (
                            <div
                              key={`content-${guideIdx}-${contentIdx}`}
                              className="space-y-2"
                            >
                              {content.outline && (
                                <TypographyH4>{content.outline}</TypographyH4>
                              )}
                              <div className="ml-4">
                                {content.steps.map((step, stepIdx) => (
                                  <ul
                                    key={`content-${guideIdx}-${contentIdx}-${stepIdx}`}
                                  >
                                    <li>
                                      <TypographyBody className="text-system-body whitespace-pre-wrap text-pretty">
                                        {step}
                                      </TypographyBody>
                                    </li>
                                  </ul>
                                ))}
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>

                    {guideIdx !== guides.length - 1 && <Divider />}
                  </Fragment>
                )
              })}
            </section>
          </div>
        </div>
      </div>
    )
  } catch {
    return <ErrorMessage message={`There are currently no related guides`} />
  }
}

export const GuidePageContainer = () => {
  useEffect(() => {
    document.title = 'Desia AI - Help Guides'
  }, [])

  return <GuidePage />
}
