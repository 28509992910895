import { CustomAlertProps } from '@/components/CustomAlert'
import { ReactNode, createContext, useCallback, useState } from 'react'

interface IToastContext {
  isToastShown: boolean
  shownToast: CustomAlertProps | null
  showToast: (
    alert: CustomAlertProps,
    duration?: number,
    permanent?: boolean
  ) => void
  closeToast: () => void
}
interface IProviderProps {
  children: ReactNode
}
const initialContextValue = {
  isToastShown: false,
  shownToast: {},
  showToast: () => {},
  closeToast: () => {},
} satisfies IToastContext

export const ToastContext = createContext<IToastContext>(initialContextValue)

function ToastProvider({ children }: IProviderProps) {
  const [isToastShown, setIsToastShown] = useState<boolean>(false)
  const [shownToast, setShownToast] = useState<CustomAlertProps | null>(null)

  const showToast = useCallback(
    (alert: CustomAlertProps, duration = 5_000, permanent = false) => {
      setShownToast(alert)

      setTimeout(() => {
        setIsToastShown(true)
      }, 200)

      if (!permanent) {
        setTimeout(() => {
          closeToast()
        }, duration)
      }
    },
    []
  )

  function closeToast() {
    setIsToastShown(false)

    setTimeout(() => {
      setShownToast(null)
    }, 200)
  }

  return (
    <ToastContext.Provider
      value={{
        isToastShown,
        shownToast,
        showToast,
        closeToast,
      }}
    >
      {children}
    </ToastContext.Provider>
  )
}

export { ToastProvider }
