import { ApexChartsResponseChartProps } from '@/types/types'
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { useEffect, useRef, useState } from 'react'
import { ChartHeader } from './ChartHeader'
import { ChartTooltip } from './ChartTooltip'
import { getChartCitation } from './utils'
import { cn } from '@/shadcn/utils'
import { formatDisplayNumber } from '@/utils/utils'
import { CHART_TIMESERIES_THRESHOLD } from '@/constants'

export const ResponseLineChart = ({
  id,
  parsedData,
  series,
  options,
  compact,
  citations,
  documents,
  openedCitation,
  onCitationOpen,
  categories,
  className,
  isExportEnabled = true,
}: ApexChartsResponseChartProps) => {
  const [labelsRotated, setLabelsRotated] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const seriesLength = series[0].data.length ?? 0

  const lineOptions: ApexOptions = {
    ...options,
    chart: {
      ...options.chart,
      type: 'line',
      events: {
        ...options.chart?.events,
        dataPointMouseEnter: undefined,
        mounted: (chart) => {
          if (openedCitation) {
            chart.toggleDataPointSelection(0, 0)
          }
        },
        mouseMove: function (_1, chartContext, _3) {
          chartContext.el.style.cursor = 'pointer'
        },
        mouseLeave: function (_1, chartContext, _3) {
          chartContext.el.style.cursor = 'default'
        },
        markerClick: (_, chartContext, config) => {
          const citation = getChartCitation(
            chartContext.w.globals.series[config.seriesIndex][
              config.dataPointIndex
            ],
            citations,
            config.dataPointIndex,
            config.seriesIndex
          )

          if (citation) {
            onCitationOpen?.(citation, 0)
          }
        },
      },
    },
    dataLabels: {
      ...options.dataLabels,
      enabled: seriesLength < CHART_TIMESERIES_THRESHOLD,
      formatter: (val: string) => formatDisplayNumber(val) as string,
      background: {
        enabled: true,
        foreColor: '#fff',
        padding: 4,
        borderRadius: 2,
        borderWidth: 0,
        dropShadow: {
          enabled: false,
        },
      },
      textAnchor: 'middle',
    },
    stroke: {
      width: 2,
      curve: 'straight',
    },
    tooltip: {
      ...options.tooltip,
      shared: true,
    },
    yaxis: {
      // hide labels, if false will be cropped
      show: true,
      labels: {
        formatter: () => {
          return ''
        },
      },
    },
    xaxis: {
      ...options.xaxis,
      labels: {
        ...options.xaxis?.labels,
        rotate: seriesLength > 5 ? -45 : 0,
        offsetY: labelsRotated ? 8 : 0,
        offsetX: seriesLength > 5 ? -2 : 0,
        trim: false,
      },
      tickAmount: 5,
    },
  }

  useEffect(() => {
    setTimeout(() => {
      const element = document.querySelector('.apexcharts-xaxis-label')
      if (element) {
        const style = window.getComputedStyle(element)
        const hasTransform = style.transform !== 'none'
        setLabelsRotated(hasTransform)
      }
    }, 1000)
  }, [])

  return (
    <div className={cn(`p-4 ${compact ? 'mt-8' : 'mt-10'}`, className)}>
      <div
        className={`flex flex-col gap-4 relative group`}
        id={`chart-container-${id}`}
      >
        <ChartHeader
          chartRef={ref}
          parsedData={parsedData}
          compact={compact}
          citations={citations}
          documents={documents}
          openedCitation={openedCitation}
          onCitationOpen={onCitationOpen}
          isExportEnabled={isExportEnabled}
        />

        {openedCitation?.chart_table_id === id &&
          !openedCitation.isChartHeaderCitation && (
            <ChartTooltip
              id={id}
              categories={categories}
              series={series}
              dataPointIndex={openedCitation!.index_x!}
              seriesIndex={openedCitation!.index_y!}
              citations={citations}
              openedCitation={openedCitation}
              withShadow={false}
              onClose={() => onCitationOpen?.(null, 0)}
              showOpenedCitation
            />
          )}
        <div
          ref={ref}
          id={`chart-${id}`}
          className="[&_.apexcharts-tooltip]:!border-none [&_.apexcharts-tooltip]:!rounded-sm [&_.apexcharts-tooltip]:!bg-system-secondary"
        >
          <Chart options={lineOptions} series={series} type="line" />
        </div>
      </div>
    </div>
  )
}
