import { Citation, SourceDocument } from '@/types/types'
import { handleError } from '@/utils/handleError'
import { getUniqueCitationDocuments, sortSourceDocuments } from '@/utils/utils'
import { ReactNode, useMemo, useRef } from 'react'

export const NumberCitation = ({
  children,
  citation,
  citations,
  documents,
  scrollToCitation = false,
  openedCitation,
  onCitationOpen,
}: {
  children: ReactNode
  citation: string
  citations: Citation[]
  documents: SourceDocument[]
  scrollToCitation: boolean
  openedCitation?: Citation | null
  onCitationOpen?: (citation: Citation | null, scrollTop: number) => void
}) => {
  const uuid = useRef(crypto.randomUUID().split('-')[0]).current

  try {
    const ref = useRef<HTMLSpanElement>(null)
    const parsedCitation: Citation = JSON.parse(citation)

    const uniqueCitationDocuments = useMemo(() => {
      return getUniqueCitationDocuments(parsedCitation, documents)
    }, [parsedCitation, documents])

    const sortedCitationDocuments = useMemo(() => {
      return sortSourceDocuments(uniqueCitationDocuments, parsedCitation)
    }, [uniqueCitationDocuments, parsedCitation])

    const citationWithHighlights = citations.find(
      (v) =>
        JSON.stringify({ ...parsedCitation, highlights: [] }) ===
        JSON.stringify({ ...v, highlights: [] })
    )

    const isSelected =
      JSON.stringify({ ...parsedCitation, highlights: [] }) ===
      JSON.stringify({ ...openedCitation, highlights: [] })

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation()

      if (isSelected) {
        onCitationOpen?.(null, 0)
      } else {
        if (scrollToCitation) {
          ref.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }

        if (citationWithHighlights) {
          const parentContainer = ref.current?.closest(
            '.ask-full-width-scrollable-container'
          ) as HTMLDivElement

          const rect = ref.current?.getBoundingClientRect()

          if (rect) {
            const parentScrollTop: number =
              parentContainer?.getBoundingClientRect().top ?? 0
            const invertedParentScrollTop = -parentScrollTop
            const yPosition = rect.top + invertedParentScrollTop

            if (openedCitation) {
              onCitationOpen?.(citationWithHighlights, yPosition || 0)
            } else {
              requestAnimationFrame(() => {
                onCitationOpen?.(citationWithHighlights, yPosition || 0)
              })
            }
          }
        }
      }
    }

    if (sortedCitationDocuments.length === 0) return children

    return (
      <span
        className={`p-px cursor-pointer border-b-2 border-citation-border rounded-t-sm hover:bg-system-hover hover:border-b-[3px] hover:border-citation-accent focus:bg-system-hover focus:border-2 focus:border-system-primary focus:rounded-md ${isSelected ? 'bg-citation-highlight border-b-[3px] border-citation-accent' : ''}`}
        ref={ref}
        key={`citation-${uuid}`}
        onClick={handleClick}
      >
        {children}
      </span>
    )
  } catch (e) {
    handleError(e)
    return
  }
}
